import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Col, Image, Modal, Row, message } from "antd";
import * as React from "react";
import AxiosAPI from "../../../../../../../helpers/AxiosAPI";

export const FileAttachmentNoActionButton = ({ s3Key, label, type, isImage, cb }: any) => {

  
  const [messageApi, contextHolder] = message.useMessage();

  const removeFileAttachment = (fileObject: any) => {
    //console.warn("::: fileObject :::", fileObject);

    const modalConfirmAttachmentDelete: any = Modal.confirm({
      centered: true,
      title: "Remove attachment?",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to remove the file: " + fileObject.name,
      onOk() {
        return new Promise((resolve, reject) => {
          AxiosAPI.removeFileAttachment(fileObject.s3Key)
            .then((res: any) => {
              //console.warn("::: DELETE ATTACHMENT RESULT :::", res.data);
              cb();
              modalConfirmAttachmentDelete.destroy();
              messageApi.open({
                type: "success",
                content: "Attachment removed successfully",
              });
            })
            .catch((error: any) => {
              //console.warn("::: DELETE ATTACHMENT ERROR :::", error);
            });
        }).catch(() => {
          messageApi.open({
            type: "error",
            content: "Error removing atatchment",
          });
        });
      },
      onCancel() {
        //console.log("Cancel");
      },
    });
  };

  
  return (

    <>
    
    {contextHolder}
    <Row>
    <Col span={6}>
      <a
        className="ant-upload-list-item-thumbnail"
        href={
          "https://api-live.supplyzone.ai/home/download-attachment/" +
          s3Key
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {isImage ? (
          <Image
            src={
              "https://api-live.supplyzone.ai/home/download-attachment/" + s3Key
            }
            alt={"196df3b8-c48c-4ad1-b3bf-6a4d868d2235.png"}
            className="ant-upload-list-item-image"
          />
        ) : (
          <img
            src={"/assets/images/docs/pdf.png"}
            alt={"196df3b8-c48c-4ad1-b3bf-6a4d868d2235.png"}
            className="ant-upload-list-item-image"
          />
        )}
      </a>
    </Col>
    <Col span={15}>

      <a
        target="_blank"
        rel="noopener noreferrer"
        className="ant-upload-list-item-name"
        title={"196df3b8-c48c-4ad1-b3bf-6a4d868d2235.png"}
        href={
          "https://api-live.supplyzone.ai/home/download-attachment/" +
          s3Key
        }
      >
        {label} <br />
        <small>{type.toLowerCase().replace(/_/g, " ")}</small>
      </a>
    </Col>

    <Col span={3}>
      <Button shape="circle" type="text" onClick={()=>{ removeFileAttachment({s3Key, name: label})}}><DeleteOutlined /></Button>
    </Col>
    </Row>
    
    </>
  );
};
