import { Row, Col } from "antd";
import React, { FC } from "react";
import "./profile-wizard.scss";
import { CompanySettings } from "./accounts/CompanySettings";
interface DocumentListItemProps {
  children?: React.ReactNode;
}

const CompanySettingsPage: FC<DocumentListItemProps> = ({
  children,
  ...props
}) => {

  // eslint-disable-next-line no-empty-pattern
  const { }: any = props;

  return (
    <>
      <main className="main profile-wizard">
        <div className="container pt-2">
          <div className="product-wrapper-1 mb-1">
            <div className="title-link-wrapper pb-2 mb-1">
              <h2 className="title ls-normal mb-0">Company Profile</h2>
            </div>
          </div>
          <div className="row">
            <Row>
              <Col span={24}>
                <CompanySettings></CompanySettings>
              </Col>
            </Row>
          </div>
        </div>
      </main>
    </>
  );
};

export default CompanySettingsPage;