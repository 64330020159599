import { Button, Divider, Flex, message, Modal, Space } from "antd";
import React, { useEffect, useState } from "react";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import { RedeemModal } from "./RedeemModal";

const EarnedPoints: any = ({
  payoutMethod,
  payoutNumber,
  payoutName,
}: any) => {

  const [modalOpenRedeem, setModalOpenRedeem] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [payoutPointsInitial, setPayoutPointsInitial] = useState(0);

  const [payoutPointsInitial2, setPayoutPointsInitial2] = useState(0);

  const redeemPoints: any = () => {
    setIsSaving(true);

    setTimeout(() => {
      setIsSaving(false);
      setModalOpenRedeem(false);
      (window as any).Swal.fire({
        icon: "success",
        title: "Transfer Completed",
        html: `Your mobile wallet ${payoutNumber} has been credited USD $ ${payoutPointsInitial / 100} via ${payoutMethod}. Approval code is MP202409.1382.B3623`,
        showCancelButton: false,
        confirmButtonText: "Close",
      }).then((result: any) => {
        window.location.reload();
      });
    }, 3000);
  };

  useEffect(() => {
    
    AxiosAPI.getRewardsSummary()
      .then((res: any) => {
        let _rewardsSummary: any = res.data;

        console.log("REWARDS:::rewardsSummary", _rewardsSummary);

        setPayoutPointsInitial(_rewardsSummary.totalPoints);
        setPayoutPointsInitial2(_rewardsSummary.totalPoints);
        
      })
      .catch((error: any) => { });
    
  },[])

  return (
    <>
      <div className="card mb-5">
        <div className="card-header border-0 cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Wallet Points</h3>
          </div>
        </div>
        <div className="collapse show">
          <div id="kt_account_signin_method" className="collapse show">
            <div className="card-body border-top p-9">
              <div id="kt_signin_password_edit" className={"flex-row-fluid "}>
                <h1
                  className="mt-10 mb-5 text-center"
                  style={{ fontSize: 64, fontWeight: 400 }}
                >
                  ${parseFloat(`${payoutPointsInitial2 / 100}`)}
                </h1>
                <br />
                <h3
                  className="mt-3 mb-10 text-center"
                  style={{ fontSize: 28, fontWeight: 300 }}
                >
                  {payoutPointsInitial2} Wallet Points
                </h3>
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <Button
                      type="default"
                      className="w-100"
                      block
                      size="large"
                      onClick={() => {
                        window.location.href = "/reward-zone";
                      }}
                    >
                      View Transactions
                    </Button>
                  </div>
                  <div className="col-md-6 mt-2">
                    <Button
                      type="primary"
                      className="w-100"
                      block
                      size="large"
                      onClick={() => {
                        setModalOpenRedeem(true);
                      }}
                    >
                      Reedem Points
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Redeem Points"
        centered
        open={modalOpenRedeem}
        closable
        onCancel={() => {
          setModalOpenRedeem(false);
        }}
        footer={
          <>
            <Divider orientation="center" />
            <Flex justify={"flex-end"} align={"flex-end"}>
              <Button
                size="large"
                type="default"
                onClick={() => {
                  setModalOpenRedeem(false);
                }}
                style={{ marginRight: "20px" }}
              >
                Close
              </Button>
              <Button
                size="large"
                type="primary"
                loading={isSaving}
                onClick={() => {
                  redeemPoints();
                }}
              >
                Reedem
              </Button>
            </Flex>
          </>
        }
      >
        <RedeemModal
          payoutPointsInitial={payoutPointsInitial}
          payoutMethod={payoutMethod}
          payoutNumber={payoutNumber}
          payoutName={payoutName}
          setPayoutPointsInitial={setPayoutPointsInitial}
        />
      </Modal>
    </>
  );
};

export { EarnedPoints };
