export class User{
    id:number|null = 0
    firstName: string | null = ""
    lastName:string | null =  ""
    email:string | null =  ""
    phoneNumber:string | null =  ""
    password:string | null =  ""
    userRole:string | null =  "USER"
    title:string | null =  ""
    designation:string | null =  ""
}