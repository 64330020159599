import * as React from "react";
import { BrandFilter } from "./ProductSearchResults";

export default ({ categories, priceRanges, brands, attributes, filterByAttribute }) => {
  const widgetTitleStyle = {
    fontSize: "14px",
    padding: "1.4rem 2.5rem 1.4rem 1rem",
    background: "#eee",
    textTransform: "uppercase"
  }
  let brandsArray: BrandFilter[] = []
  brands.forEach((value, key) => {
    const brandLink = (key == "No Brand") ? "null" : key
    if (brandLink != "null") {
      brandsArray.push(new BrandFilter(value, key, brandLink))
    }
  });


  return (
    <>
      <aside
        className="sidebar product-sidebar sidebar-fixed left-sidebar sticky-sidebar-wrapper"
        id="product-sidebar"
      >
        <div className="sidebar-overlay"></div>
        <a className="sidebar-close" href="#">
          <i className="close-icon"></i>
        </a>
        <a href="#" className="sidebar-toggle d-flex d-lg-none">
          <i className="fas fa-chevron-left"></i>
        </a>
        <div className="xcards sidebar-content scrollable pr-3 pl-3 pt-3 pb-3">

          <div
            className="sticky-sidebar slider-relative"
            style={{
              borderBottom: "0px none rgb(153, 153, 153)",
              width: "100%",
              border: "solid 1px #eee"
            }}
          >
            <div className="widget widget-collapsible widget-search" key={`widget-search`}>
              <h3 className="widget-title" style={widgetTitleStyle}><span>FILTER RESULTS BY</span></h3>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-12"><h3 style={{ fontSize: "14px" }} className="mt-1 mb-1">SEARCH WITHIN THESE RESULTS</h3></div>
                <div className="col-8"><input type="text" className="w-100" style={{ border: "solid 1px #ddd", height: "40px" }} /></div>
                <div className="col-4">
                  <a href="#" className="btn-product" style={{ width: "60px", borderRadius: "4px" }}>GO</a>
                </div>
              </div>
            </div>

            <div className="widget widget-collapsible widget-categories" key={`widget-categories`}>
              <h3 className="widget-title" style={widgetTitleStyle}><span>PRODUCT CATEGORIES</span></h3>
              <ul className="widget-body filter-items search-ul" style={{ padding: "1rem" }}>
                {categories.map((category: any) => (
                  <>
                    {category.productsCount > 0 && (
                      <li key={`brand-${category.id}`}>
                        <a href={`/categories/${category.id}`}>
                          {category.name}
                          <span className="text-muted p-relative" style={{ fontWeight: "500" }}> ({category.productsCount})</span>
                        </a>
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </div>

            {brandsArray.length > 0 && (

              <div className="widget widget-collapsible widget-brands" key={`widget-brands`}>
                <h3 className="widget-title" style={widgetTitleStyle}><span>BRAND</span><span className="toggle-btn"></span></h3>
                <div className="widget-body" style={{ padding: "1rem" }}>
                  <ul className="filter-items search-ul">
                    {brandsArray.map((brand: any, index: number) => (
                      <li key={`brand-${index}`}>
                        <div className="checkbox-toggle">
                          <input type="checkbox" className="custom-checkbox" id={`brand-${index}`} />
                          <label style={{ fontSize: "12px" }} htmlFor={`brand-${index}`} >
                            {brand.name}
                            <span className="text-muted p-relative" style={{ color: "#29333b" }}> ({brand.count})</span>
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {priceRanges.length > 0 && (
              <div className="widget widget-collapsible">
                <h3 className="widget-title" style={widgetTitleStyle}><span>Price</span><span className="toggle-btn"></span></h3>
                <div className="widget-body" style={{ padding: "1rem" }}>
                  <ul className="filter-items search-ul">
                    {priceRanges.map((priceRange: any, index: number) => (
                      <>
                        <li key={`price-${index}`}>
                          <div className="checkbox-toggle">
                            <input type="checkbox" className="custom-checkbox" id={`price-${index}`} />
                            <label style={{ fontSize: "12px" }} htmlFor={`price-${index}`} >
                              ${Math.ceil(parseFloat(priceRange.min)).toFixed(2)} - ${Math.ceil(parseFloat(priceRange.max)).toFixed(2)}
                            </label>
                          </div>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {attributes.map((attribute: any, attributeIndex: number) => (
              <>
              {attribute.values.length > 0 && (
              <div className={`widget widget-collapsible widget-attributes-${attributeIndex}`} key={`widget-attributes-${attributeIndex}`}>
                <h3 className="widget-title" style={widgetTitleStyle}><span>{attribute.name}</span><span className="toggle-btn"></span></h3>
                <div className="widget-body" style={{ padding: "1rem" }}>
                  <ul className="filter-items search-ul">
                    {attribute.values.map((value: any, index: number) => (
                      <>
                        {value.value.length > 0 && (
                          <li key={`attribute-${attributeIndex}-${index}`}>
                            <div className="checkbox-toggle">
                              <input type="checkbox" className="custom-checkbox" id={`attribute-${attributeIndex}-${index}`} onChange={(e) => { filterByAttribute(attribute.id, value.value) }} />
                              <label style={{ fontSize: "12px" }} htmlFor={`attribute-${attributeIndex}-${index}`} >
                                {value.value} <span className="text-muted p-relative" style={{ fontWeight: "500" }}>({value.count})</span>
                              </label>
                            </div>
                          </li>
                        )}
                      </>

                    ))}
                  </ul>
                </div>
              </div>
              )}
              </>
            ))}



          </div>

        </div>
      </aside >
    </>
  );
};
