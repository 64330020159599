import React, { useEffect, useState } from "react";

import { Button, Col, Row } from "antd";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import { PersonalDetails } from "./PersonalDetails";
import { Me } from "./model/Me";
import { PersonalAvatar } from "./PersonalAvatar";
import { ChangePassword } from "./ChangePassword";
import { IEmailPreferences, INotifications, emailPreferences, notifications } from "./components/settings/SettingsModel";

const PersonalPreferences: React.FC = () => {
    const [data, setData] = useState<IEmailPreferences>(emailPreferences)

    const updateData = (fieldsToUpdate: Partial<IEmailPreferences>) => {
        const updatedData = { ...data, ...fieldsToUpdate }
        setData(updatedData)
    }

    const [loading, setLoading] = useState(false)

    const click = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    const [notificationsData, setNotificationsData] = useState<INotifications>(notifications)

    const updateNotificationsData = (fieldsToUpdate: Partial<INotifications>) => {
        const updatedData = { ...data, ...fieldsToUpdate }
        setData(updatedData)
    }



    return (
        <>
            <div className='card mb-5 mb-xl-10 d-none'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_email_preferences'
                    aria-expanded='true'
                    aria-controls='kt_account_email_preferences'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Preferences</h3>
                    </div>
                </div>

                <div id='kt_account_email_preferences' className='collapse show'>
                    <h3 >Email Preferences</h3>
                    <form className='form'>
                        <div className='card-body border-top px-9 py-9'>
                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='email-preferences[]'
                                    defaultChecked={data.successfulPayments}
                                    onChange={() =>
                                        updateData({
                                            successfulPayments: !data.successfulPayments,
                                        })
                                    }
                                />

                                <span className='form-check-label d-flex flex-column align-items-start'>
                                    <span className='fw-bolder fs-5 mb-0'>Successful Payments</span>
                                    <span className='text-muted fs-6'>
                                        Receive a notification for every successful payment.
                                    </span>
                                </span>
                            </label>

                            <div className='separator separator-dashed my-6'></div>

                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='email-preferences[]'
                                    defaultChecked={data.payouts}
                                    onChange={() =>
                                        updateData({
                                            payouts: !data.payouts,
                                        })
                                    }
                                />

                                <span className='form-check-label d-flex flex-column align-items-start'>
                                    <span className='fw-bolder fs-5 mb-0'>Payouts</span>
                                    <span className='text-muted fs-6'>
                                        Receive a notification for every initiated payout.
                                    </span>
                                </span>
                            </label>

                            <div className='separator separator-dashed my-6'></div>

                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='email-preferences[]'
                                    defaultChecked={data.freeCollections}
                                    onChange={() =>
                                        updateData({
                                            freeCollections: !data.freeCollections,
                                        })
                                    }
                                />

                                <span className='form-check-label d-flex flex-column align-items-start'>
                                    <span className='fw-bolder fs-5 mb-0'>Fee Collection</span>
                                    <span className='text-muted fs-6'>
                                        Receive a notification each time you collect a fee from sales
                                    </span>
                                </span>
                            </label>

                            <div className='separator separator-dashed my-6'></div>

                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='email-preferences[]'
                                    defaultChecked={data.customerPaymentDispute}
                                    onChange={() =>
                                        updateData({
                                            customerPaymentDispute: !data.customerPaymentDispute,
                                        })
                                    }
                                />

                                <span className='form-check-label d-flex flex-column align-items-start'>
                                    <span className='fw-bolder fs-5 mb-0'>Customer Payment Dispute</span>
                                    <span className='text-muted fs-6'>
                                        Receive a notification if a payment is disputed by a customer and for dispute
                                        purposes.
                                    </span>
                                </span>
                            </label>

                            <div className='separator separator-dashed my-6'></div>

                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='email-preferences[]'
                                    defaultChecked={data.refundAlert}
                                    onChange={() =>
                                        updateData({
                                            refundAlert: !data.refundAlert,
                                        })
                                    }
                                />

                                <span className='form-check-label d-flex flex-column align-items-start'>
                                    <span className='fw-bolder fs-5 mb-0'>Refund Alerts</span>
                                    <span className='text-muted fs-6'>
                                        Receive a notification if a payment is stated as risk by the Finance Department.
                                    </span>
                                </span>
                            </label>

                            <div className='separator separator-dashed my-6'></div>

                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='email-preferences[]'
                                    defaultChecked={data.invoicePayments}
                                    onChange={() =>
                                        updateData({
                                            invoicePayments: !data.invoicePayments,
                                        })
                                    }
                                />

                                <span className='form-check-label d-flex flex-column align-items-start'>
                                    <span className='fw-bolder fs-5 mb-0'>Invoice Payments</span>
                                    <span className='text-muted fs-6'>
                                        Receive a notification if a customer sends an incorrect amount to pay their
                                        invoice.
                                    </span>
                                </span>
                            </label>

                            <div className='separator separator-dashed my-6'></div>

                            <label className='form-check form-check-custom form-check-solid align-items-start'>
                                <input
                                    className='form-check-input me-3'
                                    type='checkbox'
                                    name='email-preferences[]'
                                    defaultChecked={data.webhookAPIEndpoints}
                                    onChange={() =>
                                        updateData({
                                            webhookAPIEndpoints: !data.webhookAPIEndpoints,
                                        })
                                    }
                                />

                                <span className='form-check-label d-flex flex-column align-items-start'>
                                    <span className='fw-bolder fs-5 mb-0'>Webhook API Endpoints</span>
                                    <span className='text-muted fs-6'>
                                        Receive notifications for consistently failing webhook API endpoints.
                                    </span>
                                </span>
                            </label>
                        </div>

                       
                    </form>
                </div>

                <div id='kt_account_notifications' className='collapse show'>
                    <h3>Notifications</h3>
                    <form className='form'>
                        <div className='card-body border-top px-9 pt-3 pb-4'>
                            <div className='table-responsive'>
                                <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                                    <tbody className='fs-6 fw-bold'>
                                        <tr>
                                            <td className='min-w-250px fs-4 fw-bolder'>Notifications</td>
                                            <td className='w-125px'>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='kt_settings_notification_email'
                                                        defaultChecked={notificationsData.notifications.email}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                notifications: {
                                                                    phone: notificationsData.notifications.phone,
                                                                    email: !notificationsData.notifications.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label
                                                        className='form-check-label ps-2'
                                                        htmlFor='kt_settings_notification_email'
                                                    >
                                                        Email
                                                    </label>
                                                </div>
                                            </td>
                                            <td className='w-125px'>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='kt_settings_notification_phone'
                                                        defaultChecked={notificationsData.notifications.phone}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                notifications: {
                                                                    phone: !notificationsData.notifications.phone,
                                                                    email: notificationsData.notifications.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label
                                                        className='form-check-label ps-2'
                                                        htmlFor='kt_settings_notification_phone'
                                                    >
                                                        Phone
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Billing Updates</td>
                                            <td>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value='1'
                                                        id='billing1'
                                                        defaultChecked={notificationsData.billingUpdates.email}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                billingUpdates: {
                                                                    phone: notificationsData.billingUpdates.phone,
                                                                    email: !notificationsData.billingUpdates.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='billing1'></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='billing2'
                                                        defaultChecked={notificationsData.billingUpdates.phone}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                billingUpdates: {
                                                                    phone: !notificationsData.billingUpdates.phone,
                                                                    email: notificationsData.billingUpdates.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='billing2'></label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>New Team Members</td>
                                            <td>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='team1'
                                                        defaultChecked={notificationsData.newTeamMembers.email}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                newTeamMembers: {
                                                                    phone: notificationsData.newTeamMembers.phone,
                                                                    email: !notificationsData.newTeamMembers.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='team1'></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='team2'
                                                        defaultChecked={notificationsData.newTeamMembers.phone}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                newTeamMembers: {
                                                                    phone: !notificationsData.newTeamMembers.phone,
                                                                    email: notificationsData.newTeamMembers.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='team2'></label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Completed Projects</td>
                                            <td>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='project1'
                                                        defaultChecked={notificationsData.completeProjects.email}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                completeProjects: {
                                                                    phone: notificationsData.completeProjects.phone,
                                                                    email: !notificationsData.completeProjects.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='project1'></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='project2'
                                                        defaultChecked={notificationsData.completeProjects.phone}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                completeProjects: {
                                                                    phone: !notificationsData.completeProjects.phone,
                                                                    email: notificationsData.completeProjects.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='project2'></label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className='border-bottom-0'>Newsletters</td>
                                            <td className='border-bottom-0'>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='newsletter1'
                                                        defaultChecked={notificationsData.newsletters.email}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                newsletters: {
                                                                    phone: notificationsData.newsletters.phone,
                                                                    email: !notificationsData.newsletters.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='newsletter1'></label>
                                                </div>
                                            </td>
                                            <td className='border-bottom-0'>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        id='newsletter2'
                                                        defaultChecked={notificationsData.newsletters.phone}
                                                        onChange={() =>
                                                            updateNotificationsData({
                                                                newsletters: {
                                                                    phone: !notificationsData.newsletters.phone,
                                                                    email: notificationsData.newsletters.email,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <label className='form-check-label ps-2' htmlFor='newsletter2'></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'> 
                            <Button type='primary' onClick={click}  >
                                {!loading && 'Save Changes'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </Button>
                        </div>
                    </form>
                </div>



            </div>
        </>
    );
};

export { PersonalPreferences };