import React, {FC, useEffect, useState} from 'react'

import {Cascader} from 'antd'
import AxiosAPI from '../../../../helpers/AxiosAPI'

const RFQAdvancedListViewFilterBar: any = ({
                  onCategorySearch,
                  onCategory,
                  onBudget,
                  onUserType,
                  onBusinessType,
                  onPurchaseOrders,
                  categoryItemList,
                  businessTypeItemList
}:any) => {


  interface Category {
    canHaveProducts: boolean;
    icon: string;
    id: number;
    image: string;
    name: string;
    parentId: number;
    productsCount: number;
    updatedAt: string;
  }

  interface NestedCategory extends Category {
    children?: NestedCategory[];
  }
  
  const convertToNestedCategories:any = (categories: Category[]): NestedCategory[] => {
    const categoryMap: { [id: number]: NestedCategory } = {};
  
    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });
  
    const nestedCategories: NestedCategory[] = [];
  
    categories.forEach((category) => {
      if (category.parentId !== null) {
        const parentCategory = categoryMap[category.parentId];
        if (parentCategory) {
          parentCategory.children.push(categoryMap[category.id]);
        }
      } else {
        nestedCategories.push(categoryMap[category.id]);
      }
    });
  
    return nestedCategories;
  }

  const [categoryList, setCategoryList] = useState([]);

  const [selectedCategoryList, setSelectedCategoryList] = useState([]);

  const [checkedCategoryList, setCheckedCategoryList] = useState([]);

  const [checkedBusinessTypeList, setCheckedBusinessTypeList] = useState([]);

  const getCategories: Function = async () => {

    AxiosAPI.getCategoriesForDropdown()
      .then(({data: categories}:any) => {

        //console.log('CATEGORIES ********* RFQ MARKETPLACE', categories.children)

        setCategoryList(categories.children);

      })
      .catch((error:any) => {

        //console.log('ERROR FETCHING CATEGORIES', error);

      })

  }

  useEffect(() => {

    getCategories();

  }, [])

const onChange = (value: string[]) => {
  setSelectedCategoryList(value);
  onCategorySearch(value.slice(-1)[0]);
};

  const [budgetMinVal, setBudgetMinVal] = useState("");
  const [budgetMaxVal, setBudgetMaxVal] = useState("");

  useEffect(() => {

    onBudget(budgetMinVal, budgetMaxVal);

  }, [budgetMinVal, budgetMaxVal])

  return (
    <>
      <div className='sticky-sidebar slider-relative rfq xfilter'>
        <div className='widget widget-collapsible widget-attributes-0 mt-0'>
          <h4 className='widget-title'>
            <span>CATEGORIES</span>
            <span className='toggle-btn'></span>
          </h4>
          <div className='widget-body'>
            <Cascader
              options={categoryList}
              onChange={onChange}
              size='large'
              className='w-100'
              placeholder='Please select'
            />
            <br />
            <br />
            <ul className='filter-items search-ul'>
              {categoryItemList.map((categoryItem:any, catgoryItemIndex:number)=>(
                <li key={`li-${catgoryItemIndex}`} className='mt-2 mb-2'>
                  <div className='checkbox-toggle' key={`checkbox-toggle-${catgoryItemIndex}`}>
                    <label htmlFor={`input-${categoryItem.id}`} style={{fontSize: '12px'}} key={`category-label-${catgoryItemIndex}`}>
                    <input 
                    style={{opacity: 1}}
                      key={`category-item-${catgoryItemIndex}`}
                      type='checkbox'
                      className='custom-checkbox'
                      id={`input-${categoryItem.id}`}
                      checked={checkedCategoryList.includes(categoryItem.id)}
                      onChange={(e) => {

                        if (e.target.checked) {
                          const _list:any = [...checkedCategoryList, categoryItem.id];
                          onCategory(categoryItem, e.target.checked, _list); 
                          setCheckedCategoryList(_list);
                        } else {
                          const _list:any = checkedCategoryList.filter((value:number) => value !== categoryItem.id);
                          onCategory(categoryItem, e.target.checked, _list); 
                          setCheckedCategoryList(_list);
                        }

                      }}
                    />
                    
                    <div style={{marginLeft: '30px'}}>
                      <h6 style={{fontSize:"12px", fontWeight: 400, paddingTop: "5px"}}>{' '}{categoryItem.name}{' '}({categoryItem.items.length})</h6>
                    </div>
                     
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <hr className='hr-filter' />
        <div className='widget widget-collapsible'>
          <h4 className='widget-title'>
            <span>BUDGET</span>
          </h4>
          <div className='widget-body mt-2'>
            <span className='mb-2'>Estimate Value</span>
            <div className='d-flex w-100 mt-5'>
              <input type='text' placeholder='min' className='budget-input' value={budgetMinVal} onChange={(e:any)=>{setBudgetMinVal(e.target.value)}} />
              <span className='estimate-to'>&nbsp;to&nbsp;</span>
              <input type='text' placeholder='max' className='budget-input' value={budgetMaxVal} onChange={(e:any)=>{setBudgetMaxVal(e.target.value)}} />
            </div>
          </div>
        </div>
        <hr className='hr-filter' />
        <div className='widget widget-collapsible widget-attributes-0 mt-0'>
          <h4 className='widget-title'>
            <span>USER TYPE</span>
            <span className='toggle-btn'></span>
          </h4>
          <div className='widget-body'>
            <ul className='filter-items search-ul'>
              <li>
                <div className='checkbox-toggle'>
                  <input
                    type='checkbox'
                    className='custom-checkbox'
                    id='attribute-0-0'
                    value=' ORANGE'
                  />
                  <label htmlFor='attribute-0-0' style={{fontSize: '12px'}}>
                    {' '}
                    Verified
                    <span className='text-muted p-relative' style={{fontWeight: 500}}>
                      (1)
                    </span>
                  </label>
                </div>
              </li>
              <li>
                <div className='checkbox-toggle'>
                  <input
                    type='checkbox'
                    className='custom-checkbox'
                    id='attribute-0-1'
                    value='BLACK'
                  />
                  <label htmlFor='attribute-0-1' style={{fontSize: '12px'}}>
                    Self Registered{' '}
                    <span className='text-muted p-relative' style={{fontWeight: 500}}>
                      (40)
                    </span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <hr className='hr-filter' />
        <div className='widget widget-collapsible widget-attributes-0 mt-0'>
          <h4 className='widget-title'>
            <span>BUSINESS TYPE</span>
            <span className='toggle-btn'></span>
          </h4>
          <div className='widget-body'>
            <ul className='filter-items search-ul'>
              {businessTypeItemList.map((businessTypeItem:any, businessTypeItemIndex:number)=>(
                <li key={`li-${businessTypeItemIndex}`} className='mt-2 mb-2'>
                  <div className='checkbox-toggle' key={`checkbox-toggle-${businessTypeItemIndex}`}>
                    <label htmlFor={`input-${businessTypeItem.name}`} style={{fontSize: '12px'}} key={`category-label-${businessTypeItemIndex}`}>
                    <input 
                    style={{opacity: 1}}
                      key={`category-item-${businessTypeItemIndex}`}
                      type='checkbox'
                      className='custom-checkbox'
                      id={`input-${businessTypeItem.name}`}
                      checked={checkedBusinessTypeList.includes(businessTypeItem.name)}
                      onChange={(e) => {

                        if (e.target.checked) {
                          const _list:any = [...checkedBusinessTypeList, businessTypeItem.name];
                          onBusinessType(businessTypeItem, e.target.checked, _list); 
                          setCheckedBusinessTypeList(_list);
                        } else {
                          const _list:any = checkedBusinessTypeList.filter((value:string) => value !== businessTypeItem.name);
                          onBusinessType(businessTypeItem, e.target.checked, _list); 
                          setCheckedBusinessTypeList(_list);
                        }

                      }}
                    />
                    
                    <div style={{marginLeft: '30px'}}>
                      <h6 style={{fontSize:"12px", fontWeight: 400, paddingTop: "5px"}}>{' '}{businessTypeItem.name}{' '}({businessTypeItem.items.length})</h6>
                    </div>
                     
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <hr className='hr-filter' />
        <div className='widget widget-collapsible widget-attributes-0 mt-0'>
          <h4 className='widget-title'>
            <span>PURCHASE ORDERS</span>
            <span className='toggle-btn'></span>
          </h4>
          <div className='widget-body'>
            <ul className='filter-items search-ul'>
              <li>
                <div className='checkbox-toggle'>
                  <input
                    type='checkbox'
                    className='custom-checkbox'
                    id='attribute-77-0'
                    value=' ORANGE'
                  />
                  <label htmlFor='attribute-77-0' style={{fontSize: '12px'}}>
                    {' '}
                    None{' '}
                    <span className='text-muted p-relative' style={{fontWeight: 500}}>
                      (3)
                    </span>
                  </label>
                </div>
              </li>
              <li>
                <div className='checkbox-toggle'>
                  <input
                    type='checkbox'
                    className='custom-checkbox'
                    id='attribute-88-1'
                    value='BLACK'
                  />
                  <label htmlFor='attribute-88-1' style={{fontSize: '12px'}}>
                    1 - 9{' '}
                    <span className='text-muted p-relative' style={{fontWeight: 500}}>
                      (4)
                    </span>
                  </label>
                </div>
              </li>
              <li>
                <div className='checkbox-toggle'>
                  <input
                    type='checkbox'
                    className='custom-checkbox'
                    id='attribute-99-2'
                    value='BLUE'
                  />
                  <label htmlFor='attribute-99-2' style={{fontSize: '12px'}}>
                    10+{' '}
                    <span className='text-muted p-relative' style={{fontWeight: 500}}>
                      (21)
                    </span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default RFQAdvancedListViewFilterBar
