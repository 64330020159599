import React, { FC } from "react";
import { PricingOnePager2 } from "./PricingOnePager2";

const PricingPageWrapper2: FC = () => {
  return (
    <>
      <PricingOnePager2 />
    </>
  );
};

export default PricingPageWrapper2;
