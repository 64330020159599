import * as React from 'react';
import { useEffect } from 'react';
import AxiosAPI from '../../../../helpers/AxiosAPI';
import ProductsDoubleSlider8UP from '../Products/Widgets/ProductsDoubleSlider8UP';
import ProductsSlider3UP from '../Products/Widgets/ProductsSlider3UP';
import RFQFormBanner from './RFQFormBanner';
import { useDeviceDetect } from 'use-device-detect';
import ProductCategoryDrillDown from '../Products/Widgets/ProductCategoryDrillDown';
import Snippets from '../../../../helpers/Snippets';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { UserPilotHelper } from '../../../../helpers/UserPilotHelper';
import { Link } from 'react-router-dom';

export default () => {

    const { deviceData } = useDeviceDetect();

    const [productSuperCategories, setProductSuperCategories] = React.useState<any>([]);

    const [productsFeaturedMain, setProductsFeaturedMain] = React.useState<any>([]);

    const [productsFeatured, setProductsFeatured] = React.useState<any>([]);

    const [productsNewArrivals, setProductsNewArrivals] = React.useState<any>({ title: 'Related to items you\'ve viewed', products: [] });

    const [productsTopRanking, setProductsTopRanking] = React.useState<any>({ title: 'Most Popular', products: [] });

    const [productsAnalystsChoice, setProductsAnalystsChoice] = React.useState<any>({ title: 'Analyst\'s Choice', products: [] });

    const [productsLowMOQ, setProductsLowMOQ] = React.useState<any>({ title: 'Specials', products: [] });

    const [productsNew, setProductsNew] = React.useState<any>([]);
    const [superCatsLoaded, setSuperCatsLoaded] = React.useState(false)
    const [dummyArray, setDummyArray] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16])
    const loadSuperCategories = () => {

        /* FFs */
        AxiosAPI.publicApiRequest('get', '/model/group-categories/top', null)
            .then(({ data: categories }) => {
            setProductSuperCategories(categories);
            setSuperCatsLoaded(true)
        }).catch((error) => {

        });


    }

    const loadProducts = () => {

        AxiosAPI.getAllProducts().then(({ data: products }) => {

            //console.warn(":::PRODUCTS:::", products)

            if (products) {

                products = products.reverse();
                setProductsFeaturedMain(products.slice(Math.max(products.length - 1, 1)));
                setProductsFeatured(products.slice(Math.max(products.length - 4, 1)));
                setProductsNew(products.slice(3, 12));

            }

        }).catch((error) => {

        });

        //random products
        AxiosAPI.publicApiRequest('get',`/marketplace/random-products?pageSize=4`,{}).then(({ data: products }) => {
            if (products) {
                setProductsNewArrivals({ title: 'Related to items you\'ve viewed', products: products });
            }
        }).catch((error) => { });
        AxiosAPI.publicApiRequest('get',`/marketplace/random-products?pageSize=4`,{}).then(({ data: products }) => {
            if (products) {
                setProductsTopRanking({ title: 'Most Popular', products: products });
            }
        }).catch((error) => { });
        AxiosAPI.publicApiRequest('get',`/marketplace/random-products?pageSize=4`,{}).then(({ data: products }) => {
            if (products) {
                setProductsAnalystsChoice({ title: 'Analyst\'s Choice', products: products });
            }
        }).catch((error) => { });
        AxiosAPI.publicApiRequest('get',`/marketplace/random-products?pageSize=4`,{}).then(({ data: products }) => {
            if (products) {
                setProductsLowMOQ({ title: 'Specials', products: products });
            }
        }).catch((error) => { });

    }

    const onSetProductPreviewHandler = (params: any) => {

        //console.warn(":::: onSetProductPreviewHandler ::::", params);

    }

    const [catBanner, setCatBanner] = React.useState([
        {
            title: "office supplies", link: "/categories/office-supplies", color: "#eddfee",
            bg: "office-supplies.png", id: [925]
        },
        {
            title: "agriculture", link: "/categories/agriculture", color: "#dfeee3",
            bg: "agriculture.png", id: [840]
        },
        {
            title: "automotive", link: "/categories/automotive", color: "#f1e7de",
            bg: "automotive.png", id: [922, 813, 814, 815, 816, 817, 818, 819, 820, 821]
        },
        {
            title: "chemicals & minerals", link: "/categories/chemicals-and-minerals", color: "#dfeee3",
            bg: "chemicals-and-minerals.png", id: [890, 891]
        },
        {
            title: "construction", link: "/categories/construction", color: "#ffffe8",
            bg: "construction.png", id: [924, 873]
        },
        {
            title: "electrical and electronics", link: "/categories/electrical-and-electronics", color: "#f1e7de",
            bg: "electrical-and-electronics.png", id: [926, 874]
        },
        {
            title: "hardware", link: "/categories/hardware", color: "#f1e7de",
            bg: "hardware.png", id: [928]
        },
        {
            title: "health & personal care", link: "/categories/health-and-personal-care", color: "#e0e4ef",
            bg: "health-and-personal-care.png", id: [930]
        },
        {
            title: "industrial supplies", link: "/categories/industrial-supplies", color: "#dfeee3",
            bg: "industrial-supplies.png", id: [755, 929]
        },
        {
            title: "machinery & equipment", link: "/categories/machinery-and-equipment", color: "#dfeee3",
            bg: "machinery-and-equipment.png", id: [929]
        },
        {
            title: "mining", link: "/categories/mining", color: "#dfe4ee",
            bg: "mining.png", id: [1030]
        },
        {
            title: "professional services", link: "/categories/services", color: "#eddfee",
            bg: "services.png", id: [1029]
        },
    ])

    useEffect(() => {

        loadSuperCategories();

        loadProducts();

        const showFreshChatInterval = setInterval(function(){
            if( document.querySelectorAll('.fc-widget-normal').length > 0 ){
                document.querySelector('.fc-widget-normal').classList.remove('d-none')
            }
        }, 3000);
        

    }, []);

    return (

        <>

            {/*  Start of Main   */}
            <main className="main home">
                <div className="container pb-2">
                    <div className="intro-wrapper mt-4 home-cats">
                        <div className="swiper-wrapper row cols-xl-4 cols-lg-4 cols-md-4 cols-sm-2 cols-2">
                            {/*  Start of Category Classic   */}

                            {superCatsLoaded ? (
                                <>
                                    {
                                        productSuperCategories.map((category: any, index: number) => (

                                            <ProductCategoryDrillDown key={index} category={category} />

                                        ))
                                    }
                                </>
                            ) : (
                                <>
                                    {dummyArray.map((item: any, index: number) => (
                                        <div key={index} className="swiper-slide category-wrap mt-20" style={{ marginBottom: "20px" }}>
                                            <div className="category category-classic category-absolute overlay-zoom br-sm" style={{ padding: "10px",marginBottom: "20px" }}>
                                                {<Skeleton count={1} height={90} />}
                                                {<Skeleton count={1} height={32} />}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}


                            {/*  End of Category Classic   */}
                        </div>
                    </div>

                </div>
                {/*  End of Container   */}

                {/* 

            <section className="grey-section appear-animatex">
                <div className="container mb-2">
                    <div className="title-link-wrapper mb-2">
                        <h2 className="title">Featured Products List</h2>
                        <a href="/products/featured">More Products<i className="w-icon-long-arrow-right"></i></a>
                    </div>
                    <div className="row grid grid-type">
                        <ProductFeaturedMain product={productsFeaturedMain} />
                        {productsFeatured.map((product: any)=>(
                            <ProductFeatured product={product} />                        
                        ))}
                    </div>
                </div>
            </section>

            )}

            */}

                <div className="container pt-2">

                    <div className='row'>

                        <ProductsSlider3UP onSetProductPreviewHandler={onSetProductPreviewHandler} key='sliderA001' slug="new-arrivals" title={productsNewArrivals.title} products={productsNewArrivals.products} />

                        <ProductsSlider3UP onSetProductPreviewHandler={onSetProductPreviewHandler} key='sliderA002' slug="top-ranking" title={productsTopRanking.title} products={productsTopRanking.products} />

                        <ProductsSlider3UP onSetProductPreviewHandler={onSetProductPreviewHandler} key='sliderA003' slug="analyst-choice" title={productsAnalystsChoice.title} products={productsAnalystsChoice.products} />

                        <ProductsSlider3UP onSetProductPreviewHandler={onSetProductPreviewHandler} key='sliderA004' slug="low-moq" title={productsLowMOQ.title} products={productsLowMOQ.products} />

                    </div>

                    {/*  End of Swiper Container   */}


                    <div className="product-wrapper-1 appear-animatex mb-8">
                        <div className="title-link-wrapper pb-1 mb-4">
                            <h2 className="title ls-normal mb-0">New Products</h2>
                            <a href="shop-boxed-banner.html" className="font-size-normal font-weight-bold ls-25 mb-0">More
                                Products<i className="w-icon-long-arrow-right"></i></a>
                        </div>

                        {catBanner.map((category: any, categoryIndex: number) => (

                            <div className="row" key={categoryIndex}>
                                <div className="col-lg-3 col-sm-4 mb-4">
                                    <div className="banner h-100 br-sm xcards" style={
                                        {
                                            backgroundImage: `url(/assets/bg/${deviceData.isSmallerThanMD ? 'm_' : ''}${category.bg})`,
                                            //backgroundColor: "#252525",
                                            backgroundSize: "contain",
                                            backgroundPositionX: "center",
                                            backgroundPositionY: "bottom",
                                            backgroundColor: deviceData.isSmallerThanMD ? 'transparent' : category.color
                                        }}>
                                        <div className="banner-content content-top">
                                            <h3 className={`banner-title font-weight-bolder text-uppercase ls-25 ${deviceData.isSmallerThanMD ? 'text-white' : 'text-black'}`} style={{ fontSize: deviceData.isSmallerThanMD ? '3rem' : '2.25rem' }}>{category.title}</h3>
                                            <Link to={category.link}
                                                className={`btn btn-outline btn-rounded btn-sm mt-0 ${deviceData.isSmallerThanMD ? 'btn-white' : 'cat-buttons'}`}>Source Now</Link>
                                        </div>
                                    </div>
                                </div>
                                {/*  End of Banner   */}
                                <ProductsDoubleSlider8UP onSetProductPreviewHandler={onSetProductPreviewHandler} category={category} />
                            </div>

                        ))}

                    </div>
                    {/*  End of Product Wrapper 1   */}



                    <h2 className="title text-left mb-5 appear-animatex mt-10">Brands</h2>
                    <div className="swiper-container swiper-theme  brands-wrapper br-sm mb-9 appear-animatex"
                        data-swiper-options="{
                    'autoplay': {
                        'delay': 4000,
                        'disableOnInteraction': false
                    },
                    'loop': true,
                    'spaceBetween': 20,
                    'slidesPerView': 6,
                    'breakpoints': {
                        '576': {
                            'slidesPerView': 6
                        },
                        '768': {
                            'slidesPerView': 6
                        },
                        '992': {
                            'slidesPerView': 6
                        },
                        '1200': {
                            'slidesPerView': 6
                        }
                    }
                }">
                        <div className="swiper-wrapper row cols-xl-8 cols-lg-6 cols-md-4 cols-sm-3 cols-2" style={{ alignContent: 'center', alignItems: 'center' }}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((logo: any, logoIndex: number) => (
                                <div key={logoIndex} className="swiper-slide" style={{ backgroundColor: 'white', padding: '10px', width: '350px', height: '75px', margin: '5px' }} >
                                    <figure className='xbrand-logos' style={{
                                        backgroundImage: `url(/assets/logos/${logo}.jpeg)`, height: '55px !important', maxHeight: '55px !important', border: '1px solid red',
                                        backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPositionX: 'center', backgroundPositionY: 'center'
                                    }}>
                                        <img src={`/assets/logos/${logo}.jpeg`} />
                                    </figure>
                                </div>
                            ))}
                        </div>

                    </div>
                    {/*  End of Brands Wrapper   */}
                </div>
                {/*  End of Container   */}
            </main>
            {/*  End of Main  */}

        </>

    );

}
