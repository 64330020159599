import React, { FC } from "react";
import { InvitesOnePager } from "./InvitesOnePager";

const InvitesPageWrapper: FC = () => {
  return (
    <>
      <InvitesOnePager />
    </>
  );
};

export default InvitesPageWrapper;
