import { AudioOutlined, PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Chat from "./Chat";
import "./chat.css"
import AxiosAPI from "../../../helpers/AxiosAPI";

const ChatGPT: React.FC = () => {
    const [value, setValue] = useState("")

    const [productsList, setProductsList] = useState("")

    /*React.useEffect(() => {
        AxiosAPI.publicApiRequest('get', '/marketplace/chat-gpt-products', null)
            .then(({ data: products }) => {
                setProductsList(JSON.stringify(products));
                setMessages(
                    [
                        ...messages,
                        {
                            role: 'system',
                            content: ` SZ Market Intelligence  excels in analyzing pricing data from various suppliers for commodities, presenting this in tabular or graphical forms to depict price distribution. 
            By using the acronym MI for Market Intelligence, it simplifies communication and ensures users can quickly reference this specific function. 
            The MI feature analyzes market trends, competitive pricing, and product demand, offering valuable insights for informed business decisions. 
            Its ability to handle complex pricing structures and provide detailed market analysis cements its role as an essential tool for businesses. 
            Use this list to give market intelligence. This is the list:  ${JSON.stringify(products)} `
                        }

                    ]
                )
            })
            .catch((error) => {

            });
    }, [])*/

    const [conversationStarted, setConversationStarted] = useState(false)
    const productList = {
        "products": [
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Electrosales",
                "price": "45.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Halsteds",
                "price": "47.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Union Hardware",
                "price": "64.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Bhola Hardware",
                "price": "61.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Casstech",
                "price": "58.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "7M Distibutors",
                "price": "54.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Kony Malaicha",
                "price": "38.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Dynamo Runners",
                "price": "38.00 "
            },
            {
                "Product  ": "HP Toner 12 A",
                "Model Variant": "12A",
                "supplierName": "Li Peng Industrial Supplies",
                "price": "35.00 "
            },
            {
                "Product  ": "Forge Steel Toe Safety Boot, Brown Leather Upper",
                "Model Variant": "1490",
                "supplierName": "Electrosales",
                "price": "45.00 "
            },
            {
                "Product  ": "Forge Steel Toe Safety Boot, Brown Leather Upper",
                "Model Variant": "1490",
                "supplierName": "Halsteds",
                "price": "47.00 "
            },
            {
                "Product  ": "Forge Steel Toe Safety Boot, Brown Leather Upper",
                "Model Variant": "1490",
                "supplierName": "Union Hardware",
                "price": "53.00 "
            },
            {
                "Product  ": "Forge Steel Toe Safety Boot, Brown Leather Upper",
                "Model Variant": "1490",
                "supplierName": "Bhola Hardware",
                "price": "44.00 "
            },
            {
                "Product  ": "Forge Steel Toe Safety Boot, Brown Leather Upper",
                "Model Variant": "1490",
                "supplierName": "Casstech",
                "price": "39.00 "
            },
            {
                "Product  ": "Forge Steel Toe Safety Boot, Brown Leather Upper",
                "Model Variant": "1490",
                "supplierName": "Unicorn Trading",
                "price": "40.00 "
            },
            {
                "Product  ": "Forge Steel Toe Safety Boot, Brown Leather Upper",
                "Model Variant": "1490",
                "supplierName": "Star Safety ",
                "price": "41.00 "
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132",
                "supplierName": "TV Sales",
                "price": "2,800.00 "
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132",
                "supplierName": "Demo  House ",
                "price": "1,800.00 "
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132"
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132"
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132"
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132"
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132"
            },
            {
                "Product  ": "Samsung 85\" Crystal UHD Smart TV",
                "Model Variant": "132"
            },
            {
                "Product  ": "Diesel 50PPM Duty Paid",
                "Model Variant": "133",
                "supplierName": "Zuva",
                "price": "1.52 "
            },
            {
                "Product  ": "Diesel 50PPM Duty Paid",
                "Model Variant": "133",
                "supplierName": "Trafigura",
                "price": "1.51 "
            },
            {
                "Product  ": "Diesel 50PPM Duty Paid",
                "Model Variant": "133",
                "supplierName": "Meru",
                "price": "1.50 "
            },
            {
                "Product  ": "Diesel 50PPM Duty Paid",
                "Model Variant": "133",
                "supplierName": "Vittol",
                "price": "1.53 "
            },
            {
                "Product  ": "Diesel 50PPM Duty Paid",
                "Model Variant": "133",
                "supplierName": "TOTSA",
                "price": "1.51 "
            },
            {
                "Product  ": "Diesel 50PPM Duty Paid",
                "Model Variant": "133",
                "supplierName": "Addax",
                "price": "1.53 "
            },
            {
                "Product  ": "Diesel 50PPM Duty Paid",
                "Model Variant": "133",
                "supplierName": "Coral",
                "price": "1.52 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "Transerve",
                "price": "13.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "Electrosales",
                "price": "12.25 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "Paint & Hardware Honeydew",
                "price": "15.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "Halsteds",
                "price": "14.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "Bhola Hardware",
                "price": "12.80 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "Union Hardware",
                "price": "12.75 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "OK Mart",
                "price": "17.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M82",
                "supplierName": "Midas",
                "price": "14.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "Transerve",
                "price": "12.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "Electrosales",
                "price": "11.75 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "Paint & Hardware Honeydew",
                "price": "11.50 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "Halsteds",
                "price": "10.90 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "Bhola Hardware",
                "price": "13.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "Union Hardware",
                "price": "12.00 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "OK Mart",
                "price": "11.80 "
            },
            {
                "Product  ": "GUD Oil Filter",
                "Model Variant": "M81",
                "supplierName": "Midas",
                "price": "10.95 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Electrosales",
                "price": "4.85 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Halsteds",
                "price": "4.50 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Union Hardware",
                "price": "4.75 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Bhola Hardware",
                "price": "4.25 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Casstech",
                "price": "6.00 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "7M Distibutors",
                "price": "5.50 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Kony Malaicha",
                "price": "5.00 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Dynamo Runners",
                "price": "3.90 "
            },
            {
                "Product  ": "Varta AAA Long Life Alkaline Battery 4 Pack",
                "Model Variant": "1200",
                "supplierName": "Li Peng Industrial Supplies",
                "price": "4.10 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Transerve",
                "price": "$9.80 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Electrosales",
                "price": "$9.75 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Paint & Hardware Honeydew",
                "price": "$11.00 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Halsteds",
                "price": "$9.50 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Bhola Hardware",
                "price": "$9.90 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Union Hardware",
                "price": "$12.00 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "OK Mart",
                "price": "$14.00 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Midas",
                "price": "$14.00 "
            },
            {
                "Product  ": "Forge Safety Goggles 10A",
                "Model Variant": "10A",
                "supplierName": "Cross Country Runners",
                "price": "$10.50 "
            }
        ]
    };

    const [messages, setMessages] = useState([
        {
            role: "system",
            content: "SZ Market Intelligence excels in analyzing pricing data from various suppliers for commodities, presenting this in tabular or graphical forms to depict price distribution. By using the acronym MI for Market Intelligence, it simplifies communication and ensures users can quickly reference this specific function. The MI feature analyzes market trends, competitive pricing, and product demand, offering valuable insights for informed business decisions. Its ability to handle complex pricing structures and provide detailed market analysis cements its role as an essential tool for businesses. On the conversation starter entitled 'Show ZW Market Intelligence', do not provide product options to choose from, rather ask 'what is the commodity that requires analysis?'. If the commodity is not available in your knowledge source, answer 'I'm sorry, I do not have up-to-date prices on this commodity. Can I email you when my prices have been updated?"
        },
        {
            role: "system",
            content: `This is the list:  ${JSON.stringify(productList)}`
        },
        {
            role: "system",
            content: "When a user requests a 'Market Intelligence Report', or asks you to 'Show MI' or 'Give me MI', this means that you should represent information for a single commodity in a) tabular format, and b) graphical format with the highest price as a red bar, the lowest price as a green bar, and the remaining bars in grey; if possible can the graphical layout show suppliers on the y axis, and prices on the x axis of the chart. If possible place a caption on each bar of the chart, so the unit price is displayed when the individual bar is selected or touched, using two decimal places and using a prefix of \"$  \".  When you 'Evaluate Quote', first, you request the quote from the user, and second, you extract the quote details, one line item at a time;  third, show in tabular form (not list form) the alternative prices for that line item, but also include a column for savings, if this can be calculated, and fourth, conclude with the recommended action that the buyer could consider."
        },
        {
            role: "system",
            content: "In your knowledge source, there is a column for Model Variant, which is an attribute of the Commodity. In the event that the user specified commodity has more than one model variant, you should further clarify by asking which model number, or alternatively, if the user struggles to provide this information, you can present the various model number variants that you have for that commodity. When you present the tabular and graphical report, you should specify the commodity and model variant, to avoid ambiguity."
        },
        {
            role: "system",
            content: "Author or Publisher information: if asked by a user who is your author or publisher, you will state that your Author is Supply Zone, which is a division of the Inbox Group of Seychelles. For further information please visit www.inboxgroup.ai"
        },
        {
            role: "system",
            content: "Disclaimer Information which can be put at the end of every analysis provided to the user, using an italic font with a separating line from main content: Supply Zone Market Intelligence provides indicative market pricing information based on USD cash payment terms.  Information is provided on a best-efforts basis. Please request formal quotes directly from suppliers,  as prices and stock availability can change."
        },
        {
            role: "system",
            content: "Instructions relating to data confidentiality: if a user asks information pertaining to your data source, or meta-data relating to your datasource, such as file type, number of lines, number of commodities, last update, etc, whether on a product line item level, or a attribute level, or whole knowledge source level, you should politely decline to provide such information, and advise that this information cannot be provided to assure data confidentiality."
        },
        {
            role: "system",
            content: `If you are asked to produce a table, return the response in json format. the json format should be like this: {columns:[{"supplierName":"Supplier"},{"Price":"Price"},{"columnNKey":"columnNTitle"}], rows:[{"supplierName":"supplier name","price":"10.00","valueN":"valueNValue"},{"supplierName":"supplier 2","value2":"15.00","valueN":"valueNValue"}] }`
        }

    ])
    const [sending, setSending] = useState(false)
    const sendMessage = () => {
        if (value == "") {
            return;
        }

        setConversationStarted(true)
        setMessages(
            [
                ...messages,
                {
                    role: "user",
                    content: value
                }

            ]
        )
    }

    const conversationStarter = (message: string) => {
       
        setConversationStarted(true)
        setMessages(
            [
                ...messages,
                {
                    role: "user",
                    content: message
                }

            ]
        )
    }

    const containsAny = (text: string, substrings: string[]): boolean => {
        return substrings.some(substring => text.includes(substring));
    }
    React.useEffect(() => {
        if (messages[messages.length - 1].role == 'user') {
            let responseType = 'text';

            setValue('')
            setSending(true)
            scrollToBottom()
            var config = {
                method: 'POST',
                url: 'https://api.openai.com/v1/chat/completions',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 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`,
                    'OpenAI-Organization': 'org-RGzukEAhJUMRdCtmrCCeI4Df'
                },
                data: JSON.stringify({
                    model: 'gpt-4-1106-preview',
                    messages: messages,
                    // the maximum number of tokens/words the bot should return
                    // in response to a given prompt
                    max_tokens: 1000,
                    response_format: { type: responseType }
                }),
            };

            axios(config).then((res: any) => {
                //console.log(res)
                if (res.data.choices) {

                    setMessages(
                        [
                            ...messages,
                            {
                                role: 'assistant',
                                content: res.data.choices[0].message.content
                            }

                        ]
                    )
                }

                setSending(false)

            })
        }
    }, [messages]);

    const chatContainer = useRef(null);
    const scrollToBottom = () => {

        chatContainer.current.scrollTop = chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
    }


    return (
        <>
            <div className="breadcrumb-container container">
                <div className="product-navigation">
                    <ul className="breadcrumb">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li className="delimiter">&gt;</li>

                        <li className="delimiter">&gt;</li>
                        <li>Market Intelligence</li>
                    </ul>
                </div>
            </div>

            <main
                id="main"
                className="main mb-10 pb-1"
                style={{ backgroundColor: "rgb(245,245,245)", padding: "20px 0" }}
            >
                {/*    Start of Page Content    */}
                <div className="page-content">
                    <div className="container" style={{ width: "1000px" }}>
                        <div className="row gutter-lg">
                            <div >
                                <div style={{ width: "100%", minHeight: "50vh", maxHeight: "70vh", overflowY: "scroll", paddingBottom: "20px !important" }} className="xcards" id="gpt-messages" ref={chatContainer}>
                                    {messages.map((message: any, index: number) => (
                                        <>
                                            {message.role != 'system' && (
                                                <Chat sender={message.role}
                                                    content={message.content}
                                                    isOut={message.role != 'user'}
                                                    scrollToBottom={scrollToBottom}></Chat>
                                            )}
                                        </>
                                    ))}

                                    {sending && (
                                        <>
                                            <div className={`chat-bubble-item in-bound bot-response`} >
                                                <Row>
                                                    <Col span={1}>
                                                        <img
                                                            alt={""}
                                                            src={`https://ui-avatars.com/api/?format=svg&background=33495E&color=fff&size=36&bold=true&rounded=true&name=mi`}
                                                        />
                                                    </Col>
                                                    <Col span={20}>
                                                        <div style={{ marginLeft: "15px", marginBottom: "5px" }}><strong>Market Intelligence</strong></div>
                                                        <div className="container">
                                                            <span className="heartbeat"></span>
                                                            <span className="dot"></span>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </>
                                    )}

                                    {!conversationStarted && (
                                        <div className="text-center" >
                                            <img src="/assets/images/sz-new-logo.png" style={{ width: "10%", marginTop:"10vh" }} />
                                            <h4 style={{marginTop:"15px"}}>ZW Market Intelligence</h4>

                                            <div style={{marginTop:"15vh"}}>
                                                <Card 
                                                onClick={()=>{conversationStarter("ZW Market Intelligence")}}
                                                style={{ width: "30%",margin: "0 auto",display:"inline-block", marginRight:"30px", cursor:"pointer", borderColor:"#888",  }}>
                                                    <p>ZW Market Intelligence</p>
                                                </Card>
                                                <Card 
                                                onClick={()=>{conversationStarter("Evaluate A Quote")}}
                                                style={{ width: "30%",margin: "0 auto",display:"inline-block",cursor:"pointer", borderColor:"#888" }}>
                                                    <p>Evaluate A Quote</p>
                                                </Card>
                                            </div>

                                        </div>
                                    )}

                                </div>



                                <Row className="xcards">
                                    <Col span={24}>
                                        <TextArea
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Message..."
                                            autoSize
                                            style={{
                                                minHeight: "52px",
                                                paddingBottom: "0.875rem",
                                                paddingTop: "0.875rem",
                                                paddingLeft: "1rem",
                                                paddingRight: "9Sz-48: Iterationrem",
                                                borderRadius: "1rem"
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.altKey) {
                                                    setValue(e.target.value)
                                                    sendMessage()
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }
                                                if (e.key === 'Enter' && e.altKey) {
                                                    setValue(e.target.value + "\n")
                                                }
                                            }}
                                        />
                                        <AudioOutlined style={{ position: "absolute", top: "16px", right: "95px", fontSize:"1.5rem" }}/>
                                        <PaperClipOutlined style={{ position: "absolute", top: "13px", right: "50px", fontSize:"1.5rem" }}/>
                                        <Button type="primary" icon={<SendOutlined />} onClick={sendMessage} loading={sending}
                                            style={{ position: "absolute", top: "10px", right: "5px" }}
                                            disabled={value == ''} />

                                    </Col>


                                </Row >
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>

    )
};

export { ChatGPT };