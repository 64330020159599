import React, { FC } from "react";
import { RFQOnePager } from "./RFQOnePager";

const RFQPageWrapper: FC = () => {
  return (
    <>
      <RFQOnePager />
    </>
  );
};

export default RFQPageWrapper;
