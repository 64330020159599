import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Button, Flex, Tabs, Input, Select } from "antd";
import { useAppDataContext } from "../../../../data/context/AppDataContext";
import { PriceTables } from "../supplier-activation/PriceTables";
import { useParams } from "react-router-dom";

const PricingOnePager: React.FC = () => {
  const {
    isLoginModalOpen,
    setIsLoginModalOpen,
    userAccessToken,
    setUserAccessToken,
    userDisplayName,
    setUserDisplayName,
    userEmailAddress,
    setUserEmailAddress,
    userType,
    setUserType,
    userData,
    setUserData,
    userProfile,
    setUserProfile,
    isLoggedIn,
    setIsLoggedIn,
    loginUser,
    logoutUser,
    profileCounters,
    getAllDocuments,
  } = useAppDataContext();

  let { rfqId } = useParams();

  const [proceedURL, setProceedURL] = useState("");

  useEffect(() => {
    if (`${rfqId}` === "0") {
      setProceedURL("/rfq/marketplace");
    } else {
      setProceedURL("/messenger/rfqs/?rfq-id=" + rfqId);
    }
  }, [rfqId]);

  const supplierActivationComplete: any = () => {
    if (rfqId === null) {
      return;
    }

    window.location.href = proceedURL;
  };

  return (
    <>
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1 w-100">
              <Flex className="w-100" align="center" justify="space-between">
                <h2 className="title ls-normal mb-0 w-100">Pricing</h2>
                {!Number.isNaN(parseInt(rfqId)) && (
                  <Button
                    size="large"
                    type="primary"
                    onClick={supplierActivationComplete}
                  >
                    Continue
                  </Button>
                )}
              </Flex>
            </div>
            <div className="pb-2 mt-10">
              <PriceTables
                rfqId={rfqId}
                proceedURL={proceedURL}
                supplierActivationComplete={supplierActivationComplete}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export { PricingOnePager };
