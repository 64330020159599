import { Col, Row } from "antd";
import React, { FunctionComponent, useState } from "react";
import { ContactDetails } from "./model/ContactDetails";
import { ContactPerson } from "./model/ContactPerson";
import { Profile } from "./model/Profile";
import EditableLabel from "./components/EditableLabel";
import { EditOutlined } from "@ant-design/icons";
import AxiosAPI from "../../../../../helpers/AxiosAPI";

interface DocumentListItemProps {
  children?: React.ReactNode;
  profile: Profile;
}
const BusinessContactDetails: FunctionComponent<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  const { profile }: any = props;

  const [primaryContact, setPrimaryContact] = useState(new ContactDetails());
  const [primaryContactPerson, setPrimaryContactPerson] = useState(
    new ContactPerson()
  );
  const [dataChanged, setDataChanged] = useState(false);
  const [initialDataLoad, setInitialDataLoad] = useState(false);
  const [editing, setEditing] = useState(true);

  React.useEffect(() => {
    if (
      !initialDataLoad &&
      profile.primaryContact &&
      profile.primaryContactPerson
    ) {
      setPrimaryContact(profile.primaryContact);
      setPrimaryContactPerson(profile.primaryContactPerson);
      setInitialDataLoad(true);
    }
  }, [profile]);

  const saveChanges = () => {
    AxiosAPI.saveProfileData(profile).then(() => {
      setEditing(false);
      setDataChanged(false);
    });
  };

  return (
    <>
      {profile.primaryContact && profile.primaryContactPerson && (
        <div className="card mb-5">
          <div className="card-header border-0 cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Contact Details</h3>
            </div>
            {!editing && (
              <EditOutlined
                onClick={(e) => {
                  setEditing(!editing);
                }}
              />
            )}
          </div>
          <div className="collapse show">
            <div className="card-body border-top p-9">
              <Row>
                {String(primaryContactPerson.firstName).trim().length > 1 && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2">
                    <strong>First Name</strong>
                    <br />
                    {editing ? (
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        defaultValue={primaryContactPerson.firstName}
                        onChange={(e: any) => {
                          primaryContactPerson.firstName = e.target.value;
                          setDataChanged(true);
                        }}
                      ></input>
                    ) : (
                      <span>{primaryContactPerson.firstName}</span>
                    )}
                  </Col>
                )}

                {String(primaryContactPerson.lastName).trim().length > 1 && (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    className="pe-2 mb-5"
                  >
                    <strong>Last Name</strong>
                    <br />

                    {editing ? (
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        defaultValue={primaryContactPerson.lastName}
                        onChange={(e: any) => {
                          primaryContactPerson.lastName = e.target.value;
                          setDataChanged(true);
                        }}
                      ></input>
                    ) : (
                      <span>{primaryContactPerson.lastName}</span>
                    )}
                  </Col>
                )}

                {String(primaryContact.phoneNumber).trim().length > 1 && (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    className="pe-2 mb-5"
                  >
                    <strong>Phone Number</strong>
                    <br />
                    {editing ? (
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        defaultValue={primaryContact.phoneNumber}
                        onChange={(e: any) => {
                          primaryContact.phoneNumber = e.target.value;
                          setDataChanged(true);
                        }}
                      ></input>
                    ) : (
                      <span>{primaryContact.phoneNumber}</span>
                    )}
                  </Col>
                )}

                {String(primaryContact.emailAddress).trim().length > 1 && (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    className="pe-2 mb-5"
                  >
                    <strong>Email Address</strong>
                    <br />

                    {editing ? (
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        defaultValue={primaryContact.emailAddress}
                        onChange={(e: any) => {
                          primaryContact.emailAddress = e.target.value;
                          setDataChanged(true);
                        }}
                      ></input>
                    ) : (
                      <span>{primaryContact.emailAddress}</span>
                    )}
                  </Col>
                )}
              </Row>
            </div>
          </div>
          {dataChanged && (
            <div className="card-footer d-flex justify-content-end py-6 ">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => saveChanges()}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { BusinessContactDetails };
