import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Button, Flex, Tabs, Input, Row, Col, Steps, theme, Space, Skeleton, message } from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { CheckCircleFilled } from "@ant-design/icons";
import { useAppDataContext } from '../../../../data/context/AppDataContext';

const CategoryItem: any = ({
  category,
  updateCatSelection,
}: any) => {

  const { selectedCategorriesForSupplier } = useAppDataContext();

  const [isSelected, setIsSelected] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    
    updateCatSelection(category, isSelected);
      
  }, [category, isSelected]);

  const reachedMax: any = () => {
    
    messageApi.warning(
      "You have selected a maximum number of categories allowed"
    );

  }

  return (
    <>
      {contextHolder}
      <div
        onClick={() => { if (!isSelected) { selectedCategorriesForSupplier.length > 2 ? reachedMax() : setIsSelected(true) } else { setIsSelected(false) } }}
        className={`xcards category category-classic category-absolute overlay-zoom br-sm pr-1 pl-1 pt-1 pb-1 w-100 cat-item-card ${
          isSelected ? "active" : ""
        }`}
      >
        {isSelected && (
          <div className="cat-selection">
            {selectedCategorriesForSupplier.length}/3 selected
          </div>
        )}
        {/*JSON.stringify(selectedCategorriesForSupplier)*/}
        <figure className="category-media">
          <img
            alt=""
            src={`https://api-live.supplyzone.ai/home/download-attachment/${category.image}`}
            className="uniform-image"
          />
        </figure>
        <div className="category-content">
          <h4 className="category-name ls-normal">{category.label}</h4>
        </div>
        {isSelected && <CheckCircleFilled className="cat-selected-tick" />}
      </div>
    </>
  );
};
export { CategoryItem };
