import * as React from "react";
import Snippets from "../../../../helpers/Snippets";
import axios from 'axios'
import { Avatar, Badge, Button, Col, FloatButton, List, Row } from "antd";
import { CloseOutlined, MessageOutlined, NotificationOutlined } from "@ant-design/icons";
import VirtualList from 'rc-virtual-list';
import ChatThread from "./ChatThread";
import { format } from "timeago.js";
interface MessageThread {
    senderId: number,
    senderName: string,
    date: Date
    message: string
}
interface MessageHeader {
    id: number,
    subject: string,
    senderName: string,
    thread: MessageThread[],
    recipientId: number,
    read: boolean
}
export default () => {
    function getInitialsFromName(name: string): string {
        if (typeof name === "undefined") {
            return name;
        }
        name = name.replace("(", "").replace(")", "").replace(".", "");
        const names = name.split(" ");
        if (names.length === 0) {
            return name;
        }
        if (names.length === 1) {
            return names[0].substring(0, 1).toUpperCase();
        }
        const initials =
            names[0].substring(0, 1).toUpperCase() +
            names[1].substring(0, 1).toUpperCase();
        return initials;
    }
    const [threads, setThreads] = React.useState([]);
    const [showThreadsWindow, setShowThreadsWindow] = React.useState(false)
    const [showChatWindow, setShowChatWindow] = React.useState(false)
    const [threadId, setThreadId] = React.useState(0)
    const [unreadMessages, setUnreadMessages] = React.useState(0)

    const fetchMessages = () => {

        const baseURL = Snippets.urls.getAPIBaseURL();
        var config = {
            method: 'get',
            url: baseURL + '/messages/',
            headers: {
                'Authorization': 'Bearer ' + Snippets.user.getCurrentUserAccessToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        axios(config).then(({ data: messages }) => {
            setThreads(messages)
            let unread = 0;
            messages.map((message: MessageHeader) => {
                if (!message.read) {
                    unread++;
                }
            })
            setUnreadMessages(unread)
            //console.log(unread)

        }).catch((error) => { });

    }


    const formatDate = (date: Date) => {
        const now = new Date();
        try {
            const diff = Math.abs(now.getTime() - date.getTime()) / 1000;

            if (diff < 60) {
                return `${Math.floor(diff)} seconds ago`;
            } else if (diff < 3600) {
                return `${Math.floor(diff / 60)} minutes ago`;
            } else if (diff < 86400) {
                return `${Math.floor(diff / 3600)} hours ago`;
            } else {
                return `${Math.floor(diff / 86400)} days ago`;
            }
        } catch (ex) {
            return ''
        }
    }


    React.useEffect(() => {

        fetchMessages()
    }, [])

    const ContainerHeight = 400;

    const openThread = (threadId: number) => {
        setThreadId(threadId)
        setShowChatWindow(true)
        //reset counter
        fetchMessages()
    }

    return (
        <>
            <div className="instant-messenger card-with-title" style={{ display: (showThreadsWindow) ? "block" : "none" }}>

                <div className="title-link-wrapper mb-3">
                    <h2 className="title mb-1 pt-0 pb-0 thread-title-btn">
                        <span>Chat </span>
                        <Button onClick={() => { setShowThreadsWindow(false) }} className="chat-btn-close" type="text" ><CloseOutlined size={48} /></Button>
                    </h2>
                </div>
                <div >

                    <List>
                        <VirtualList
                            data={threads}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="id"
                        >
                            {(item: MessageHeader) => (
                                <List.Item key={item.id} onClick={() => { openThread(item.id) }} >

                                    <List.Item.Meta
                                        avatar={<Avatar src={`https://ui-avatars.com/api/?format=svg&background=cccccc&color=777777&size=48&bold=true&rounded=true&name=${getInitialsFromName(item.senderName)}`} />}
                                        title={
                                            <>
                                                {!item.read ? (
                                                    <Badge dot className="ms-2">{item.subject.substring(0, 25)}</Badge>
                                                ) : (
                                                    item.subject.substring(0, 25)
                                                )}
                                            </>
                                        }
                                        description={item.senderName}
                                    />
                                    <div>
                                        {format(item.thread[item.thread.length - 1].date)}
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>





                </div >

            </div>


            <FloatButton
                shape="circle"
                type="primary"

                style={{ right: 94 }}
                icon={<MessageOutlined style={{ fontSize: "20px" }} />}
                onClick={() => setShowThreadsWindow(!showThreadsWindow)}
                badge={{ count: unreadMessages, color: 'red' }}

            />
            
            <ChatThread
                threadId={threadId}
                showChatWindow={showChatWindow}
                setShowChatWindow={setShowChatWindow}
            />
        </>
    );
}