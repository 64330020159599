import * as React from "react";
import Snippets from "../../../../../helpers/Snippets";
import { Link } from "react-router-dom";

export default ({ product, onSetProductPreview }): JSX.Element => {

  const [isECommerce, setisECommerce] = React.useState<boolean>(false);

  const [priceRange, setPriceRange] = React.useState<any>('Call supplier');

  React.useEffect(() => {

    //console.warn("::::: PRODUCTS PRICES ::::::", product.prices)

    if (product.prices) {

      let _prices: any = product.prices.sort(Snippets.arrays.dynamicSort('-price'));

      let min = _prices[0].price;

      let max = _prices[_prices.length - 1].price;

      if (product.displayPrice === false) {
        setPriceRange("Call supplier")
      } else {

        try {

          if (min === max && parseFloat(max) > 0) {
            if (!min) {
              setPriceRange(`${min}`);
            } else {
              setPriceRange(`${min.toFixed(2)}`);
            }

          } else {
            if (!min) {
              setPriceRange(`${max} - ${min}`);
            } else {
              setPriceRange(`$${max.toFixed(2)} - $${min.toFixed(2)}`);
            }

          }

        } catch (e) {
          console.error(e);
        }
      }

    }

  }, []);

  return (
    <>
      {/* Start of Footer */}
      <React.Fragment>

        <div className="swiper-slide product product-simple text-center xcards mr-1 ml-1 pb-0 w-100x" onClick={onSetProductPreview({ product })}>
          <figure className="product-media transparent-border-color" style={{ borderColor: 'transparent !important', }}>
            <Link to={`/product/${Snippets.string.toSlug(product.category)}/${Snippets.string.toSlug(product.name)}/${product.id}`}>
              <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png";
              }}
                src={`https://api-live.supplyzone.ai//home/product-attachment/${product.imageUrl}`}
                className={`product-simple product-id-${product.id} ${product.imageUrl} max-height-218`}
                width="185" height="140" style={{ maxHeight: '218px !important' }} />
            </Link>
            {/* 
                    <div className="product-action-vertical">
                      <a href="#" className="btn-product-icon btn-wishlist w-icon-heart"
                        title="Add to wishlist"></a>
                      <a href="#" className="btn-product-icon btn-compare w-icon-compare"
                        title="Add to Compare"></a>
                    </div>
                    */}
            <div className="product-action XX">

              <Link to={`/product/${Snippets.string.toSlug(product.category)}/${Snippets.string.toSlug(product.name)}/${product.id}`} className="btn-product" title="Quick View">View Details</Link>
            </div>
          </figure>
          <div className="product-details pb-2 pt-0 pr-2 pl-2">
            <h4 className="product-name">
              <Link to={`/product/${Snippets.string.toSlug(product.category)}/${Snippets.string.toSlug(product.name)}/${product.id}`}>{product.name}</Link></h4>
            <div className="sold-by">
              <Link to={`/categories/${product.categoryId}`}>{product.category}</Link>
            </div>
            <div className="product-pa-wrapper">
              <div className="product-price">
                {isNaN(priceRange) ? (
                  <>
                    <ins className="new-price price-range">
                      {priceRange}
                    </ins>
                  </>
                ) : (
                  <>
                    <ins className="new-price price-range">
                      ${priceRange}
                      <span className="new-price per-unit"> / {product.unitOfMeasurement}</span>
                    </ins>
                  </>
                )}

              </div>
              {isECommerce ? (
                <div className="product-action">
                  <a href="#"
                    className="btn-cart btn-product btn btn-icon-right btn-link btn-underline">Add
                    To Cart</a>
                </div>
              ) : (
                <div className="product-action">
                  <Link to={`/product/${Snippets.string.toSlug(product.category)}/${Snippets.string.toSlug(product.name)}/${product.id}?inquire=true`}
                    className="btn-product btn btn-icon-right btn-link btn-underline btn-smaller ccc">
                    Inquire Now
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

      </React.Fragment>
      {/* End of Footer */}
    </>
  );
};
