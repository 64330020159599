/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { useAppDataContext } from "../../../data";

export default () => {

  const {
    setIsPageLoading
  } = useAppDataContext();

  React.useEffect(() => {

    setTimeout(() => {
      setIsPageLoading(false);
      window.document.getElementsByTagName("html")[0].style.backgroundImage = "url('/assets/bg/footer-blue-bg.svg')";
      window.document.getElementsByTagName("html")[0].style.backgroundSize = "auto";
      window.document.getElementsByTagName("html")[0].style.backgroundRepeat = "repeat-x";
      window.document.getElementsByTagName("html")[0].style.minHeight = "100vh";
    }, 100);

  })

  return (
    <>
      {/* Start of Footer */}
      <footer
        className="footer appear-animatex footer-dark"
        data-animation-options={{ name: "fadeIn" }}
      >


        <div className="container">

          {/*

          <div className="footer-top">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="widget widget-about">
                  <a href="demo1.html" className="logo-footer">
                    <img
                      src="/assets/images/logo-icon.png"
                      alt="logo-footer"
                      width="50%"
                    />
                  </a>
                  <div className="widget-body">
                    <p className="widget-about-title">
                      Got Question? Call us 24/7
                    </p>
                    <a href="tel:18005707777" className="widget-about-call">
                      +263 8677 101 330
                    </a>
                    <p className="widget-about-desc">
                      Register now to get updates on pronot get up icons &
                      coupons ster now toon.
                    </p>

                    <div className="social-icons social-icons-colored">
                      <a
                        href="#"
                        className="social-icon social-facebook w-icon-facebook"
                      ></a>
                      <a
                        href="#"
                        className="social-icon social-twitter w-icon-twitter"
                      ></a>
                      <a
                        href="#"
                        className="social-icon social-instagram w-icon-instagram"
                      ></a>
                      <a
                        href="#"
                        className="social-icon social-youtube w-icon-youtube"
                      ></a>
                      <a
                        href="#"
                        className="social-icon social-pinterest w-icon-pinterest"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h3 className="widget-title">Company</h3>
                  <ul className="widget-body">
                    <li>
                      <a href="about-us.html">About Us</a>
                    </li>
                    <li>
                      <a href="#">Team Member</a>
                    </li>
                    <li>
                      <a href="#">Career</a>
                    </li>
                    <li>
                      <a href="contact-us.html">Contact Us</a>
                    </li>
                    <li>
                      <a href="#">Affilate</a>
                    </li>
                    <li>
                      <a href="#">Order History</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">My Account</h4>
                  <ul className="widget-body">
                    <li>
                      <a href="#">Track My Order</a>
                    </li>
                    <li>
                      <a href="cart.html">View Cart</a>
                    </li>
                    <li>
                      <a href="login.html">Sign In</a>
                    </li>
                    <li>
                      <a href="#">Help</a>
                    </li>
                    <li>
                      <a href="wishlist.html">My Wishlist</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">Customer Service</h4>
                  <ul className="widget-body">
                    <li>
                      <a href="#">Payment Methods</a>
                    </li>
                    <li>
                      <a href="#">Money-back guarantee!</a>
                    </li>
                    <li>
                      <a href="#">Product Returns</a>
                    </li>
                    <li>
                      <a href="#">Support Center</a>
                    </li>
                    <li>
                      <a href="#">Shipping</a>
                    </li>
                    <li>
                      <a href="#">Term and Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          */}

          <div className="footer-bottom d-block" style={{ width: "100%" }}>
            <div className="footer-center">
              <span className="copyright">
                <div className=' text-white text-center' style={{ color: "#cccccc !important", textAlign: "center", display: 'block', width: '100%' }}>
                  <div>
                    &nbsp;&nbsp;&nbsp;<a href="/legal/terms">Terms of Use</a>&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;<a href="/legal/privacy">Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;<a href="/legal/security">Security Measures</a>&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;<a href="/legal/ip">IP Policy</a>&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;<a href="/legal/cookies">Cookie Policy</a>
                    <br />
                    <span className=' fw-bold me-2 mt-4'><a>Copyright &copy; {new Date().getFullYear()} Supply Zone. All rights reserved.</a></span>
                  </div>
                </div>
              </span>
            </div>
            <div className="footer-right">
            </div>
          </div>
        </div>
      </footer>
      {/* End of Footer */}
    </>
  );
};
