/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { useState } from "react";
import Snippets from "../../../../helpers/Snippets";
import axios, { Axios } from "axios";
import Skeleton from "react-loading-skeleton";
import { isMobile } from 'react-device-detect';
import AxiosAPI from "../../../../helpers/AxiosAPI";
export default () => {
  const [superCatsLoaded, setSuperCatsLoaded] = React.useState(false);

  const [productSuperCategories, setProductSuperCategories] =
    React.useState<any>([]);
  const [productSuperCategoriesChunks1, setProductSuperCategoriesChunks1] =
    React.useState<any>([]);
  const [productSuperCategoriesChunks2, setProductSuperCategoriesChunks2] =
    React.useState<any>([]);
  const [productSuperCategoriesChunks3, setProductSuperCategoriesChunks3] =
    React.useState<any>([]);

  React.useEffect(() => {
    
    AxiosAPI.publicApiRequest('get','/model/group-categories-with-children/top', null)
      .then(({ data: categories }) => {
        setProductSuperCategories(categories);
        setSuperCatsLoaded(true);

        let drawerCategories = [];

        for (let i = 0; i < categories.length; i++) {
          if (
            categories[i].children.length > 0 ||
            categories[i].productsCount > 0
          )
            drawerCategories.push(categories[i]);
        }

        const size = Math.ceil(drawerCategories.length / 3);
        setProductSuperCategoriesChunks1(drawerCategories.slice(0, size));
        setProductSuperCategoriesChunks2(drawerCategories.slice(size, size * 2));
        setProductSuperCategoriesChunks3(drawerCategories.slice(size * 2));
      })
      .catch((error: any) => { });
  }, []);
  return (
    <>
      {/* Start of Mobile Menu */}
      <div className="mobile-menu-wrapper">
        <div className="mobile-menu-overlay"></div>
        {/* End of .mobile-menu-overlay */}

        <a href="#" className="mobile-menu-close">
          <i className="close-icon"></i>
        </a>
        {/* End of .mobile-menu-close */}

        <div className="mobile-menu-container scrollable">
          <form
            action="search?category=PRODUCTS"
            method="get"
            className="input-wrapper"
          >
            <input
              type="text"
              className="form-control"
              name="search"
              autoComplete="off"
              placeholder="Search"
              required
            />
            <button className="btn btn-search" type="submit">
              <i className="w-icon-search"></i>
            </button>
          </form>
          {/* End of Search Form */}
          <div className="tab">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a href="#main-menu" className="nav-link">
                  Categories
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="main-menu">
              <ul className="mobile-menu">
                {superCatsLoaded
                  ? productSuperCategories.map(
                    (category: any, index: number) => (
                      <>
                        {index <= 7 && (
                          <li key={index} className="all-categories-li">
                            <a href={`/categories/${category.id}`}>
                              {category.name}
                            </a>
                          </li>
                        )}
                      </>
                    )
                  )
                  : [...Array(8)].map((e, i) => (
                    <li key={i}>
                      <a href="#">{<Skeleton count={1} height={20} />}</a>
                    </li>
                  ))}

                <li>
                  <a
                    href="/categories"
                    className="font-weight-bold text-uppercase ls-25"
                  >
                    View All Categories
                  </a>

                  <ul className="megamenu">
                    <li style={{ width: "33.33%" }}>
                      {productSuperCategoriesChunks1.map(
                        (category: any, index: number) => (
                          <div key={index}>
                            <h6 style={{ fontWeight: "600" }}>
                              {category.name}
                            </h6>
                            <hr className="divider" />
                            <ul>
                              {category.children.map(
                                (child: any, index2: number) => (
                                  <>
                                    {index2 < 4 && (
                                      <li key={index2}>
                                        <a
                                          href={`categories/${child.id}`}
                                          className="drawer-category"
                                          style={{
                                            padding: "5px 0px",
                                            fontSize: "1.1rem",
                                            color: "#666",
                                            fontWeight: "500",
                                            letterSpacing: "-0.007em",
                                          }}
                                        >
                                          {child.name}
                                        </a>
                                      </li>
                                    )}
                                  </>
                                )
                              )}
                            </ul>
                          </div>
                        )
                      )}
                    </li>
                    <li style={{ width: "33.33%" }}>
                      {productSuperCategoriesChunks2.map(
                        (category: any, index: number) => (
                          <div key={index}>
                            <h6 style={{ fontWeight: "600" }}>
                              {category.name}
                            </h6>
                            <hr className="divider" />
                            <ul>
                              {category.children.map(
                                (child: any, index2: number) => (
                                  <>
                                    {index2 < 4 && (
                                      <li key={index2}>
                                        <a
                                          href={`categories/${child.id}`}
                                          className="drawer-category"
                                          style={{
                                            padding: "5px 0px",
                                            fontSize: "1.1rem",
                                            color: "#666",
                                            fontWeight: "500",
                                            letterSpacing: "-0.007em",
                                          }}
                                        >
                                          {child.name}
                                        </a>
                                      </li>
                                    )}
                                  </>
                                )
                              )}
                            </ul>
                          </div>
                        )
                      )}
                    </li>
                    <li style={{ width: "33.33%" }}>
                      {productSuperCategoriesChunks3.map(
                        (category: any, index: number) => (
                          <div key={index}>
                            <h6 style={{ fontWeight: "600" }}>
                              {category.name}
                            </h6>
                            <hr className="divider" />
                            <ul>
                              {category.children.map(
                                (child: any, index2: number) => (
                                  <>
                                    {index2 < 4 && (
                                      <li key={index2}>
                                        <a
                                          href={`categories/${child.id}`}
                                          className="drawer-category"
                                          style={{
                                            padding: "5px 0px",
                                            fontSize: "1.1rem",
                                            color: "#666",
                                            fontWeight: "500",
                                            letterSpacing: "-0.007em",
                                          }}
                                        >
                                          {child.name}
                                        </a>
                                      </li>
                                    )}
                                  </>
                                )
                              )}
                            </ul>
                          </div>
                        )
                      )}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End of Mobile Menu */}
    </>
  );
};
