/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import "./search-card.scss";
import { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import Snippets from "../../../helpers/Snippets";
import { useNavigate } from "react-router-dom";

export default ({ placeholder, data, width, offset, zIndex, onSearch }) => {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentListing, setCurrentListing] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentListing(data.tabs[currentIndex].items);
  }, [data, currentIndex]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const formatCurrency2: any = (number: any) => {
    const formattedString = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
    return formattedString;
  };

  const openItemURL: any = (item: any) => {
    setShowDropdown(false);

    let url_string: string = "";

    switch (item.type) {
      case "product": {
        url_string =
          `/product/${item.subtitle}/${item.title}/${item.product.id}`.replace(
            /\s/g,
            "+"
          );

        break;
      }

      case "supplier": {
        url_string = `/supplier/${item.id}`.replace(/\s/g, "+");

        break;
      }

      case "rfq": {
        url_string = `/rfqs/create/?new&rfq_id=${item.id}`.replace(/\s/g, "+");

        break;
      }

      default: {
        url_string =
          `/product/${item.subtitle}/${item.title}/${item.product.id}`.replace(
            /\s/g,
            "+"
          );

        break;
      }
    }
    window.location.href = url_string;
  };

  const openItemSearchURL: any = (title: any) => {
    setShowDropdown(false);
    window.location.href = `/search?category=PRODUCTS&search=${title}`.replace(/\s/g, "+");
  };

  function truncateText(text: string, maxLength: number): string {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  }

  const formatCurrency3: any = (number: any) => {
    const formattedString = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
    return formattedString;
  };

  function formatCurrency(product: any, text: string = "Call supplier") {
    let price: any = text; //"$*****.**";

    if (product.prices !== null && "prices" in product) {
      let _prices: any = product.prices.sort(
        Snippets.arrays.dynamicSort("-price")
      );

      let min = _prices[0].price;

      let max = _prices[_prices.length - 1].price;

      if (product.displayPrice === false) {
        price = "Call supplier"; //"$*****.**";
      } else {
        if (min === max) {
          price = formatCurrency3(min.toFixed(2));
        } else {
          price = `${formatCurrency3(max.toFixed(2))} - ${formatCurrency3(
            min.toFixed(2)
          )}`;
        }
      }
    }

    return price;
  }

  return (
    <>
      <div className="search-card-wrapper">
        <div
          style={{
            zIndex: zIndex,
            maxWidth: width,
            width: "100%",
            right: 0, //offset
            position: "relative",
          }}
          className="AyKEed"
          ref={componentRef}
        >
          <div
            data-is-full-screen={false}
            data-is-fixed={false}
            data-left-icon={true}
            data-placeholder="What are you looking for?"
            data-show-custom-asset-options={false}
            className="M52nVb ytPNkd E6c19c"
          >
            <span className="eEMh0d" aria-hidden="true">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable={false}
                className=" NMm5M"
              >
                <path d="M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5A6.5 6.5 0 1 0 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z" />
              </svg>
            </span>
            <div
              className={`L6J0Pc ZB3Ebc nz7KN ${showDropdown ? "focused" : ""}`}
              data-close-on-blur={true}
              data-renderer="fZUdHf"
            >
              <div
                className="d1dlne"
                style={{ position: "relative" }}
                data-placeholder="What are you looking for?"
                data-activedescendants={true}
                data-expanded={true}
              >
                <Input
                  className="Ax4B8 ZAGvjd"
                  type="text"
                  autoComplete="off"
                  aria-label="What are you looking for?"
                  aria-autocomplete="list"
                  aria-haspopup="true"
                  aria-expanded="true"
                  role="combobox"
                  aria-controls="nngdp204"
                  placeholder={placeholder}
                  dir="ltr"
                  data-focusid={292}
                  onClick={() => setShowDropdown(true)}
                  onChange={(e) => onSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      openItemSearchURL(e.target?.value);
                    }
                  }}
                />
              </div>
              <div className=" tWfTvb">
                <div
                  className={`u3WVdc jBmls ${showDropdown ? "" : "d-none"}`}
                  tabIndex={-1}
                  role="listbox"
                  data-expanded="true"
                  data-childcount={5}
                  id="nngdp204"
                  style={{ maxHeight: 1062 }}
                >
                  <div className="mLoZRc">
                    <div>
                      <div>
                        <div className="q5O0Dd">
                          <div className="jsaWM">
                            <div className="SJyhnc">
                              <div
                                className="NBZP0e xbmkib"
                                data-snap-point={0}
                                data-snap-debounce-ms={250}
                                data-snap-animation-ms={300}
                                style={{
                                  paddingBottom: 20,
                                  marginBottom: "-20px",
                                }}
                              >
                                <div role="tablist" className="J3INNd vdK5ke">
                                  {data.tabs.map(
                                    (tab: any, tabIndex: number) => (
                                      <div
                                        key={tabIndex}
                                        onClick={() => {
                                          setCurrentIndex(tabIndex);
                                        }}
                                        tabIndex={tabIndex}
                                        role="tab"
                                        data-tab-id={tab.id}
                                        className={`GqNdIe ${
                                          currentIndex === tabIndex
                                            ? "GqNdIe-YySNWc"
                                            : ""
                                        } `}
                                        aria-selected={`GqNdIe ${
                                          currentIndex === tabIndex
                                            ? "true"
                                            : "false"
                                        } `}
                                      >
                                        {tab.label}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ims1Qe d-none">
                        About these suggestions {currentListing.length}.
                        <button
                          className="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ mN1ivc"
                          data-idom-class="yHy1rc eT1oJ mN1ivc"
                          aria-label="About these suggestions"
                        >
                          <div className="VfPpkd-Bz112c-Jh9lGc" />
                          <div className="VfPpkd-Bz112c-J1Ukfc-LhBDec" />
                          <span className="" aria-hidden="true">
                            <svg
                              focusable={false}
                              width={14}
                              height={14}
                              viewBox="0 0 24 24"
                              className="ppUMU NMm5M"
                            >
                              <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        style={{
                          maxHeight: 400,
                          overflowX: "hidden",
                          overflowY: "auto",
                        }}
                      >
                        {currentListing.length > 0 && (
                          <>
                            {currentListing
                              .sort((a, b) => {
                                if (a.displayPrice === false) return 1;
                                if (b.displayPrice === false) return -1;
                                return a.price - b.price;
                              })
                              .map((item: any, itemIndex: number) => (
                                <div
                                  key={itemIndex}
                                  role="option"
                                  className="MkjOTb SAq8ff"
                                  onClick={() => openItemURL(item)}
                                >
                                  <div>
                                    <div className="onRPD">
                                      <div>
                                        <div className="CrPloe">
                                          {truncateText(item.title, 50)}
                                        </div>
                                        <div>
                                          <span className="S4qG1c">
                                            <span className="Npx9Xe one-liner-text">
                                              {item.subtitle}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="ZilHG">
                                        <div className="lzO0Ed">
                                          {formatCurrency(
                                            item.product,
                                            item.rightLabel
                                          )}
                                        </div>

                                        {item.badge && (
                                          <span
                                            className="NydbP nZQ6l"
                                            data-disable-percent-toggle={true}
                                            data-multiplier-for-price-change={1}
                                            aria-label="Up by 0.96%"
                                          >
                                            <div className="zWwE1">
                                              <div
                                                className="JwB6zf"
                                                style={{ fontSize: 16 }}
                                              >
                                                <span
                                                  className="V53LMb"
                                                  aria-hidden="true"
                                                >
                                                  <svg
                                                    focusable={false}
                                                    width={16}
                                                    height={16}
                                                    viewBox="0 0 24 24"
                                                    className=" NMm5M"
                                                  >
                                                    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
                                                  </svg>
                                                </span>
                                                {item.badge}
                                              </div>
                                            </div>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </>
                        )}
                      </div>
                      {currentListing.length == 0 && (
                        <div className="no-matches">
                          <em>No matches...</em>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
