import React from "react";
import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';

const RewardZoneImageLoader: React.FC = ({s3Key, className}:any) => {
  const [loading, setLoading] = useState(true);
  const [imageItemSrc, setImageItemSrc] = useState<any>()
    const img = new Image();
  useEffect(() => {
    setLoading(true);
    img.src = `https://api-live.supplyzone.ai//home/product-attachment/${s3Key}`;
    img.onload = () => {
      setLoading(false);
      setImageItemSrc(img.src);
    };
  }, [s3Key]);

  return (
    <>
    <div className={`image-container ${className}`}>
    
    {loading && (
        <Skeleton.Image className={"redeem-skt"} active={true} />
    )}
    {!loading && (
      
        <img width={"100%"} alt="" title="" src={imageItemSrc} />
      
    )}
    </div>
    </>
  );
}

export {RewardZoneImageLoader}