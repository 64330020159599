/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import BuyerInquiryDetails from "./BuyerInquiryDetails";
import DocumentAttachments from "./DocumentAttachments";
import ChatComposer from "./ChatComposer";
import BuyerRFQDetails from "./BuyerRFQDetails";
import ChatHistory from "./ChatHistory";
import { FiPhone } from "react-icons/fi";
import Snippets from "../../../../helpers/Snippets";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Flex,
  Input,
  List,
  MenuProps,
  Modal,
  Row,
  Skeleton,
  message,
} from "antd";
import Icon, {
  StarOutlined,
  LikeOutlined,
  MessageOutlined,
  CloseOutlined,
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useAppDataContext } from "../../../../data";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import TextArea from "antd/es/input/TextArea";
import { InvitesModals } from "../invites/InvitesModals";

interface DocumentListItemProps {
  children?: React.ReactNode;
  listViewLabel: string;
  selectedRFQ: any;
  getTime: any;
  inquiryRespondingCompany: any;
  viewState: any;
  resetViewState: any;
  selectedUser: any;
  dummyChatsIsLoading: any;
  chatHistory: any;
  getInitialsFromName: any;
  chatMessageText: any;
  setChatMessageText: any;
  replyMessage: any;
  replyMessageAttachment: any;
  attachmentList: any;
  stillLoading: boolean;
  isSending: boolean;
}

const MessengerAdvancedInquiryCol3: FC<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  const {
    listViewLabel,
    selectedRFQ,
    getTime,
    inquiryRespondingCompany,
    viewState,
    resetViewState,
    selectedUser,
    dummyChatsIsLoading,
    chatHistory,
    getInitialsFromName,
    chatMessageText,
    setChatMessageText,
    replyMessage,
    replyMessageAttachment,
    attachmentList,
    stillLoading,
    listItems,
    isSending,
  }: any = props;

  const childRef = useRef(null);

  const [displayTitle, setDisplayTitle] = useState("...");

  const [userProfile, SetUserProfile] = useState({ id: 0 });

  const [themeColor, setThemeColor] = useState("");

  const { userData } = useAppDataContext();

  const [displayName, setDisplayName] = useState("...");
  const onDisplayNameHandler: any = (name: any) => {
    setDisplayName(name);
  };
  const [openRFQEdit, setOpenRFQEdit] = useState(false);

  const [currentDescription, setCurrentDescription] = useState("");
  const editRFQDetails: any = () => {
    AxiosAPI.editRFQDetails(selectedRFQ.id, currentDescription)
      .then((res: any) => {
        console.log(":::: UPDATE RFQ DETAILS ", res.data);
        messageApi.open({
          type: "success",
          content: "RFQ updated successfully!",
        });
        setOpenRFQEdit(false);
        setCurrentDescription(res.data.productDetails);
      })
      .catch((error: any) => {
        console.error(":::: UPDATE RFQ ERROR ", error);
        messageApi.open({
          type: "error",
          content: "Error updating RFQ, please try again!",
        });
        setOpenRFQEdit(false);
      });
  };

  const [recipient, setRecipient] = React.useState({
    id: 0,
    person: {
      title: "",
      designation: "",
      firstName: "",
      lastName: "",
    },
    contactDetails: {
      emailAddress: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      phoneNumber: "",
    },
    recipient: "",
  });
  const [recipientId, setRecipientId] = React.useState(0);

  useEffect(() => {
    if (typeof selectedUser === "object") {
      if ("messageThread" in selectedUser) {
        if ("supplierCompanyName" in selectedUser.messageThread) {
          setDisplayName(selectedUser.messageThread.supplierCompanyName);
        } else {
          setDisplayName(selectedUser.messageThread.senderName);
        }

        const localRecipientId =
          selectedUser.messageThread.senderId === userData?.id
            ? selectedUser.messageThread.recipientId
            : selectedUser.messageThread.senderId;
        if (localRecipientId) {
          setRecipientId(localRecipientId);
          //console.log("localRecipientId", localRecipientId)
        }
      }
    }
  }, [selectedUser]);
  const [textWrapperBottom, setTextWrapperBottom] = React.useState("35px");
  useEffect(() => {
    if (Snippets.user.getCurrentUserType() === "SELLER") {
      setThemeColor("ad1e23");
    } else {
      setThemeColor("552277");
    }

    const userProfile: any = Snippets.user.getCurrentUserProfile();

    SetUserProfile(userProfile);

    let localRecipientId = 0;

    if (Array.isArray(chatHistory)) {
      chatHistory.map((chat: any, chatIndex: number): void => {
        /*
      console.log(
        "SENDER AND RECEIVER",
        chat.senderId === userData?.id,
        chat.senderId,
        userData?.id,
      );
      */

        if (Snippets.user.getCurrentUserType() === "SELLER") {
          const localRecipientId =
            chat.senderId === userData?.id ? chat.recipientId : chat.senderId;
          if (localRecipientId) {
            setRecipientId(localRecipientId);
            //console.log("SellerlocalRecipientId", localRecipientId);
            return null;
          }
        }
      });
    }

    return () => {
      setDisplayName("");
    };
  }, [chatHistory]);

  useEffect(() => {
    if (recipientId > 0) {
      AxiosAPI.apiRequest("GET", `/marketplace/users/${recipientId}`, {}).then(
        ({ data: recipient }) => {
          setRecipient(recipient);
        }
      );
    }
  }, [recipientId]);
  const [loading, setLoading] = useState(true);

  interface IconTextProps {
    icon: typeof Icon;
    text: React.ReactNode;
  }

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber == null) return "";

    let formattedPhoneNumber = phoneNumber.replace(/^0/, "");
    formattedPhoneNumber = formattedPhoneNumber.replace("+263", "");
    return "+263" + formattedPhoneNumber;
  };
  const listData = Array.from({ length: 3 }).map((_, i) => ({
    href: "https://ant.design",
    title: `ant design part ${i + 1}`,
    avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
  }));

  const [itemsPhone, setItemsPhone] = useState([]);

  useEffect(() => {
    setItemsPhone([
      {
        key: "0",
        icon: <PhoneOutlined />,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`tel:${
              Snippets.user.getCurrentUserType() === "BUYER"
                ? recipient.contactDetails.phoneNumber
                : selectedRFQ.contactNumber
            }`}
          >
            Phone:{" "}
            {Snippets.user.getCurrentUserType() === "BUYER"
              ? recipient.contactDetails.phoneNumber
              : selectedRFQ.contactNumber}
          </a>
        ),
      },
      {
        key: "1",
        icon: <PhoneOutlined />,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://dialer.supplyzone.ai/?phoneNumber=${formatPhoneNumber(
              Snippets.user.getCurrentUserType() === "BUYER"
                ? recipient.contactDetails.phoneNumber
                : selectedRFQ.contactNumber
            )}`}
          >
            Voip:{" "}
            {Snippets.user.getCurrentUserType() === "BUYER"
              ? recipient.contactDetails.phoneNumber
              : selectedRFQ.contactNumber}
          </a>
        ),
      },
      {
        key: "2",
        icon: <WhatsAppOutlined />,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://wa.me/${
              Snippets.user.getCurrentUserType() === "BUYER"
                ? recipient.contactDetails.phoneNumber
                : selectedRFQ.contactNumber
            }?text=I want to enquire about this RFQ: ${selectedRFQ.name}`}
          >
            WhatsApp:{" "}
            {Snippets.user.getCurrentUserType() === "BUYER"
              ? recipient.contactDetails.phoneNumber
              : selectedRFQ.contactNumber}
          </a>
        ),
      },
      {
        key: "3",
        icon: <MailOutlined />,
        label: (
          <a href={`#contact-form`}>
            Email:{" "}
            {Snippets.user.getCurrentUserType() === "BUYER"
              ? recipient.contactDetails.emailAddress
              : selectedRFQ.contactEmail}
          </a>
        ),
      },
    ]);
  }, [recipient, selectedRFQ]);

  const itemsEditRFQ: MenuProps["items"] = [
    {
      key: "0",
      icon: <EditOutlined />,
      label: (
        <a
          className={`doc-folder-all`}
          href={"#"}
          onClick={() => {
            setOpenRFQEdit(true);
          }}
        >
          Edit RFQ
        </a>
      ),
    },
  ];
  const IconText: React.FC<IconTextProps> = ({ icon, text }: any) => (
    <>
      {React.createElement(icon, { style: { marginRight: 8 } })}
      {text}
    </>
  );

  const [messageApi, contextHolder] = message.useMessage();

  const key = "updatable";

  const [modal1Open, setModal1Open] = useState(false);
  const [messageText, setMessageText] = useState("");

  const [invitingSupplier, setInvitingSupplier] = useState(false);

  const [supplierName, setSupplierName] = useState("");

  const [supplierEmailAddress, setSupplierEmailAddress] = useState("");

  const inviteSupplier: any = () => {
    setModal1Open(true);
  };

  const doInviteSupplier: any = () => {
    setInvitingSupplier(true);

    /*
    console.log([
      supplierEmailAddress,
      messageText
    ])
    */

    AxiosAPI.inviteSupplier(
      "INQUIRY",
      selectedRFQ.id,
      supplierEmailAddress,
      supplierName
    )
      .then((res: any) => {
        //console.log(res.data);

        messageApi.open({
          key,
          type: res.data.success ? "success" : "error",
          content: res.data.message,
          duration: 4,
        });

        setInvitingSupplier(false);

        setModal1Open(false);
      })
      .catch((error: any) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    String(props.listViewLabel) === "RFQ"
      ? setCurrentDescription(selectedRFQ.productDetails)
      : setCurrentDescription(selectedRFQ.details);
  }, [selectedRFQ]);

  const [isDomReady, setIsDomReady] = useState(false);

  useEffect(() => {
    if (!listItems || listItems.length === 0) {
      setTimeout(() => {
        setIsDomReady(true);
      }, 5000);
    }
  }, [listItems]);

  return (
    <>
      {stillLoading ? (
        <>
          <div className="xcard card-with-title card-with-shadow">
            <div className="title-link-wrapper mb-3">
              <h2 className="title mb-0 pt-2 pb-2">Thread</h2>
            </div>
            <div className="list-view-wrapper chats">
              {isDomReady ? (
                <>
                  <br />
                  <br />
                  <Empty className="mt-5" />
                </>
              ) : (
                <>
                  <Skeleton avatar paragraph={{ rows: 0 }} />
                  <br />
                  <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={listData}
                    renderItem={(item) => (
                      <List.Item
                        key={item.title}
                        actions={
                          !loading
                            ? [
                                <IconText
                                  icon={StarOutlined}
                                  text="156"
                                  key="list-vertical-star-o"
                                />,
                                <IconText
                                  icon={LikeOutlined}
                                  text="156"
                                  key="list-vertical-like-o"
                                />,
                                <IconText
                                  icon={MessageOutlined}
                                  text="2"
                                  key="list-vertical-message"
                                />,
                              ]
                            : undefined
                        }
                        extra={
                          !loading && (
                            <img
                              width={272}
                              alt="logo"
                              src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                            />
                          )
                        }
                      >
                        <Skeleton loading={loading} active avatar>
                          <List.Item.Meta
                            avatar={<Avatar src={item.avatar} />}
                            title={<a href={item.href}>{item.title}</a>}
                            description={item.description}
                          />
                          {item.content}
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {viewState === "CHAT" && (
            <div className="xcard card-with-title card-with-shadow">
              <div className="title-link-wrapper mb-3">
                <h2 className="title mb-1 pt-0 pb-0 thread-title-btn">
                  <span>Thread</span>
                  {Snippets.user.getCurrentUserType() === "BUYER" && (
                    <Button
                      onClick={() => {
                        resetViewState();
                      }}
                      className="chat-btn-close"
                      type="text"
                    >
                      <CloseOutlined size={48} />
                    </Button>
                  )}
                </h2>
              </div>
              <div className="thread-wrapper supplier-docs">
                <Row>
                  <Col flex={"56px"}>
                    {Snippets.user.getCurrentUserType() === "BUYER" ? (
                      <img
                        style={{
                          objectFit: "cover",
                          width: "48px",
                          height: "48px",
                          borderRadius: "50px",
                        }}
                        src={
                          "primaryContactAvatar" in recipient &&
                          recipient.primaryContactAvatar
                            ? "https://api-live.supplyzone.ai/home/download-attachment/" +
                              recipient.primaryContactAvatar
                            : `https://ui-avatars.com/api/?format=svg&background=cccccc&color=777777&size=48&bold=true&rounded=true&name=${
                                recipient.person.firstName +
                                " " +
                                recipient.person.lastName
                              }`
                        }
                      />
                    ) : (
                      <img
                        style={{
                          objectFit: "cover",
                          width: "48px",
                          height: "48px",
                          borderRadius: "50px",
                        }}
                        src={`https://ui-avatars.com/api/?format=svg&background=cccccc&color=777777&size=48&bold=true&rounded=true&name=${selectedRFQ.contactName}`}
                      />
                    )}
                    {/* JSON.stringify([selectedRFQ, selectedUser, recipient]) */}
                  </Col>
                  <Col flex="auto">
                    <h6>
                      <strong>{selectedRFQ.contactName}</strong>
                    </h6>
                    <span style={{ fontSize: "12px" }}>
                      {selectedRFQ.contactEmail}
                    </span>
                  </Col>
                  <Col flex={"24px"}>
                    {selectedRFQ !== undefined &&
                      selectedRFQ.contactName !== undefined &&
                      selectedRFQ.contactName.length > 0 && (
                        <Dropdown menu={{ items: itemsPhone }} arrow>
                          <Button type="primary">
                            <i className="w-icon-envelop-closed mr-1"></i>
                            <span>
                              {" "}
                              {Snippets.user.getCurrentUserType() === "SELLER"
                                ? "Contact Buyer"
                                : "Contact Supplier"}
                            </span>
                          </Button>
                        </Dropdown>
                      )}
                  </Col>
                </Row>

                <div id="scroll-chats" className="row scroll-chats mt-2">
                  <div className="col">
                    <ChatHistory
                      dummyChatsIsLoading={dummyChatsIsLoading}
                      chatHistory={chatHistory}
                      userProfile={userProfile}
                      getInitialsFromName={getInitialsFromName}
                      onDisplayName={onDisplayNameHandler}
                    />
                  </div>
                </div>

                <div
                  className="row mt-3 messenger-text-wrapper"
                  style={{ bottom: textWrapperBottom }}
                >
                  <img
                    src="/assets/images/shadow.png"
                    alt=""
                    className="messenger-text-shadow"
                  />
                  <div className="col">
                    <ChatComposer
                      chatMessageText={chatMessageText}
                      setChatMessageText={setChatMessageText}
                      replyMessage={replyMessage}
                      replyMessageAttachment={replyMessageAttachment}
                      isSending={isSending}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewState === "RFQ" && (
            <>
              <div
                className="xcard card-with-title"
                style={{ maxHeight: "403px" }}
              >
                <div className="title-link-wrapper mb-3">
                  <h2 className="title mb-0 pt-2 pb-2">RFQ Details</h2>
                  {Snippets.user.getCurrentUserType() === "BUYER" && (
                    <>
                      <Button
                        type="primary"
                        className="btn-fl-r--10"
                        onClick={() => {
                          inviteSupplier();
                        }}
                        style={{ right: "30px" }}
                      >
                        <MailOutlined /> Invite Supplier
                      </Button>

                      <Dropdown menu={{ items: itemsEditRFQ }} arrow>
                        <MoreOutlined
                          style={{
                            right: -10,
                            top: -14,
                            position: "absolute",
                            fontSize: "32px",
                          }}
                        />
                      </Dropdown>
                    </>
                  )}
                </div>
                <div className="w-100">
                  <BuyerInquiryDetails
                    listViewLabel={listViewLabel}
                    selectedRFQ={selectedRFQ}
                    getTime={getTime}
                    inquiryRespondingCompany={inquiryRespondingCompany}
                  />
                </div>
              </div>

              <div className="xcard card-with-title mb-3">
                <div className="title-link-wrapper mb-3">
                  <h2 className="title mb-0 pt-2 pb-2">Attachment List</h2>
                </div>

                {attachmentList.length === 0 ? (
                  <Empty
                    imageStyle={{ height: 150 }}
                    description={<span>No attachments.</span>}
                  />
                ) : (
                  <div
                    className="attachment-wrapper"
                    style={{
                      height: "180px",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <Row>
                      {attachmentList.map(
                        (attachment: any, attachmentKey: number) => (
                          <Col span={12} key={attachmentKey}>
                            <DocumentAttachments
                              attachment={attachment}
                              inquiryRespondingCompany={
                                inquiryRespondingCompany
                              }
                            />
                          </Col>
                        )
                      )}
                    </Row>
                  </div>
                )}
              </div>
            </>
          )}

          {viewState === "INQ" && (
            <>
              <div
                className="xcard card-with-title"
                style={{ maxHeight: "403px" }}
              >
                <div className="title-link-wrapper mb-3">
                  <h2 className="title mb-0 pt-2 pb-2">Inquiry Details</h2>
                </div>
                <div className="w-100">
                  <BuyerInquiryDetails
                    ref={childRef}
                    listViewLabel={listViewLabel}
                    selectedRFQ={selectedRFQ}
                    getTime={getTime}
                    inquiryRespondingCompany={inquiryRespondingCompany}
                  />
                </div>
              </div>

              <div className="xcard card-with-title mb-3">
                <div className="title-link-wrapper mb-3">
                  <h2 className="title mb-0 pt-2 pb-2">Attachment List</h2>
                </div>

                {attachmentList.length === 0 ? (
                  <Empty
                    imageStyle={{ height: 150 }}
                    description={<span>No attachments.</span>}
                  />
                ) : (
                  <div
                    className="document-details-wrapper"
                    style={{
                      height: "165px",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <Row>
                      {attachmentList.map(
                        (attachment: any, attachmentKey: number) => (
                          <Col span={12} key={attachmentKey}>
                            <DocumentAttachments
                              attachment={attachment}
                              inquiryRespondingCompany={
                                inquiryRespondingCompany
                              }
                            />
                          </Col>
                        )
                      )}
                    </Row>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      <InvitesModals
        rfqId={selectedRFQ.id}
        isModalOpen={modal1Open}
        setIsModalOpen={setModal1Open}
        getListViewTrigger={() => {}}
      />

      <Modal
        title="Edit RFQ Description"
        centered
        open={openRFQEdit}
        onOk={() => editRFQDetails()}
        onCancel={() => setOpenRFQEdit(false)}
      >
        <TextArea
          rows={7}
          onChange={(e) => {
            setCurrentDescription(e.target.value);
          }}
          value={currentDescription}
        >
          {currentDescription}
        </TextArea>
      </Modal>

      {contextHolder}
    </>
  );
};

export default MessengerAdvancedInquiryCol3;
