/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import "react-gauge-ultimate/dist/index.css";
import "react-calendar/dist/Calendar.css";
import {
  Button,
  Cascader,
  Divider,
  Input,
  Modal,
  Progress,
  Select,
  Space,
  message,
} from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import Snippets from "../../../../helpers/Snippets";
import { CheckOutlined, MessageOutlined, PlusOutlined } from "@ant-design/icons";
import * as Icon from "react-feather";
import { DatePicker } from "antd";
import Donut from "./Donut";
import { useAppDataContext } from "../../../../data";
import "./create-rfq.css";
import SearchCard from "../../layout/SearchCard";
import CategorySearchCard from "../../layout/CategorySearchCard";
import { UserPilotEvents, UserPilotHelper } from "../../../../helpers/UserPilotHelper";

import type { GetProps, ProgressProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const BuyerRFQCreatePage: any = () => {
  // @ts-ignore

  const { rfq_id } = useParams<{ rfq_id: string }>();
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "PDF"];

  const [isDramaCompleted, setIsDramaCompleted] = useState(false);

  const [isDramaInProgress, setIsDramaInProgress] = useState(false);

  const [vendors, setVendors] = useState(0);

  const [vendorsStatusText, setVendorsStatusText] = useState("Initialising...");
  const [rfqSaved, setRfqSaved] = useState(false);

  const [rfqIDX, setRFQIDX] = useState("");
  const { isLoggedIn, setIsLoginModalOpen, userType } = useAppDataContext();

  const [loginView, setLoginView] = React.useState(false);

  const [userProfile, setUserProfile] = React.useState({
    id: 0,
    email: "",
    person: {
      title: "",
      firstName: "",
      lastName: "",
    },
  });

  const [contactFirstName, setContactFirstName] = React.useState("");
  const [contactLastName, setContactLastName] = React.useState("");
  const [details, setDetails] = React.useState("");

  const [emailAddress, setEmailAddress] = React.useState("");
  const [profileType, setProfileType] = React.useState("ANONYMOUS");
  const [businessType, setBusinessType] = React.useState("businessType");
  const [mfaCode, setMfaCode] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const [sendingOTPRequest, setSendingOTPRequest] = React.useState(false);

  const [ajaxBusy, setAjaxBusy] = React.useState(false);

  const [agreeToTerms, setAgreeToTerms] = React.useState(false);

  const [validationErrorEmailAddress, set_validationErrorEmailAddress] =
    React.useState(false);

  const [validationErrorOTP, set_validationErrorOTP] = React.useState(false);

  const [validationErrorPassword1, set_validationErrorPassword1] =
    React.useState(false);

  const [validationErrorPassword2, set_validationErrorPassword2] =
    React.useState(false);

  const [validationErrorPassword3, set_validationErrorPassword3] =
    React.useState(false);

  const [validationErrorTCs, set_validationErrorTCs] = React.useState(false);

  const [validationErrorTypeOfUser, set_validationErrorTypeOfUser] =
    React.useState(false);

  const [validationErrorInquiryMessage, set_validationErrorInquiryMessage] =
    React.useState(false);

  //////

  const [rfqId, setRFQId] = React.useState<any>(rfq_id || 0);
  const [rfqCreatedAt, setRFQCreatedAt] = React.useState(
    "2023-06-27T12:51:40.064Z"
  );
  const [rfqBuyerId, setRFQBuyerId] = React.useState(0);
  const [rfqContactName, setRFQContactName] = React.useState("");
  const [rfqContactEmail, setRFQContactEmail] = React.useState("");
  const [rfqProductName, setRFQProductName] = React.useState("");
  const [rfqCategory, setRFQCategory] = React.useState({
    id: 0,
    name: "",
    icon: "",
    image: "",
  });
  const [rfqProductDetails, setRFQProductDetails] = React.useState("");
  const [rfqSourcingType, setRFQSourcingType] = React.useState("BUSINESS_SERVICE");
  const [rfqSourcingTypeCustom, setRFQSourcingTypeCustom] =
    React.useState(false);
  const [rfqEstimatedOrderQuantity, setRFQEstimatedOrderQuantity] =
    React.useState({
      size: 0,
      unitOfMeasurement: "",
    });
  const [rfqPreferredUnitPrice, setRFQPreferredUnitPrice] = React.useState({
    currency: "USD",
    amount: 0,
  });
  const [rfqValidTo, setRFQValidTo] = React.useState("");
  const [rfqValidToTxt, setRFQValidToTxt] = React.useState("");
  const [rfqAttachments, setRFQAttachments] = React.useState([
    {
      id: 0,
      attachmentType: "IMAGE",
      s3Key: "",
      size: 0,
    },
  ]);

  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];

  const [valueRFQValidTo, onChangeRFQValidTo] = React.useState<any>();
  //new Date()

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const getFutureDate = (createdAt: string, validTo: string): string => {
    const createdAtDate = new Date(createdAt);
    const validToDate = new Date(validTo);
    const daysBetween = Math.ceil(
      (validToDate.getTime() - createdAtDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getTime() + daysBetween * 1000 * 60 * 60 * 24
    );
    return { daysBetween, futureDate: futureDate.toISOString() };
  };

  const setRFQValidToHandler = (value: any) => {
    console.log(":::::::::::", value);

    if (value == null) return;
    if (!value[0] && !value[1]) return;

    onChangeRFQValidTo(value);

    const date1: any = value[0].$d;
    const date2: any = value[1].$d;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const yyyy = date1.getFullYear();
    let mm = date1.getMonth() + 1; // Months start at 0!
    let dd = date1.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const _rfqValidTo: any = `${yyyy}-${mm}-${dd}T23:59:59.000Z`;

    setRFQValidTo(_rfqValidTo);
    setRFQValidToTxt(`${diffDays} days`);

    //console.log("NEW DATE", _rfqValidTo, diffDays);
  };

  const [rfqSendToMoreSuppliers, setRFQSendToMoreSuppliers] =
    React.useState(true);

  function formatFileSize(size: number) {
    var sizes = [
      " Bytes",
      " KB",
      " MB",
      " GB",
      " TB",
      " PB",
      " EB",
      " ZB",
      " YB",
    ];
    for (var i = 1; i < sizes.length; i++) {
      if (size < Math.pow(1024, i))
        return (
          Math.round((size / Math.pow(1024, i - 1)) * 100) / 100 + sizes[i - 1]
        );
    }
    return size;
  }

  const [rfqAttachment, setRFQAttachment] = useState<any>({});

  const [rfqAttachmentsSelected, setRFQAttachmentsSelected] =
    useState<boolean>(false);

  const [rfqAttachmentUploadInProgress, setRFQAttachmentUploadInProgress] =
    useState<boolean>(false);
  const [rfqAttachmentUploadCompleted, setRFQAttachmentUploadCompleted] =
    useState<boolean>(false);
  const [rfqAttachmentUploadPercentage, setRFQAttachmentUploadPercentage] =
    useState<any>(0);

  const uploadRFQAttachment = (_file: any) => {
    const reader = new FileReader();

    reader.addEventListener(
      "progress",
      (e) => {
        setRFQAttachmentUploadInProgress(true);
        setRFQAttachmentUploadCompleted(false);
        let percentage: number = (e.loaded / e.total) * 100;
        setRFQAttachmentUploadPercentage(percentage);
      },
      false
    );

    reader.addEventListener(
      "load",
      () => {
        const _uploadedFile: any = {
          isPrimary: false,
          indexKey: "RFQ_ATTACHMENT",
          id: rfqId,
          data: reader.result,
          attachmentType: "RFQ_ATTACHMENT",
          meta: {
            type: _file.type,
            size: _file.size,
            name: _file.name,
            lastModified: _file.lastModified,
          },
          s3Key: "string",
        };

        setRFQAttachment(_uploadedFile);

        setRFQAttachmentUploadInProgress(false);

        setRFQAttachmentUploadCompleted(true);

        setRFQAttachmentsSelected(true);

        console.log(":::FILE SAVE::: RFQ_ATTACHMENT", _uploadedFile);
      },
      false
    );

    try {
      reader.readAsDataURL(_file);
    } catch (e) {
      console.warn(":::Error reading file RFQ_ATTACHMENT:::", e);
    }
  };

  const [suppliersCount, setSuppliersCount] = useState(
    Snippets.numbers.random(11, 20)
  );

  const [rfqSent, setRFQSent] = useState(false);

  const openDramaNotification = () => {
    setIsDramaInProgress(true);
    setIsDramaCompleted(false);

    setVendors(0);

    setTimeout(() => {
      setVendorsStatusText("Looking for suppliers...");
    }, 10);

    setTimeout(() => {
      setVendors(suppliersCount);
    }, 100);

    setTimeout(() => {
      setVendorsStatusText(`Found ${suppliersCount} suppliers...`);
    }, 5000);

    setTimeout(() => {
      setVendorsStatusText(`Sending RFQ ...`);
    }, 7000);

    setTimeout(() => {
      setVendorsStatusText(`RFQ sent to ${suppliersCount} suppliers.`);
    }, 10000);

    setTimeout(() => {
      setIsDramaCompleted(true);
    }, 12000);

    setTimeout(() => {

      setSavingRFQ(false);
      setRfqSaved(true);
      setVendorsStatusText(`RFQ has been created successfully.`);
      //setIsDramaInProgress(false);
    }, 13000);

    setTimeout(() => {
      (window as any).location.href = "/messenger/rfqs";
    }, 20000);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const [wantsToSendRFQ, setWantsToSendRFQ] = useState(false);
  React.useEffect(() => {
    if (isLoggedIn && wantsToSendRFQ && userType == "BUYER") {
      setWantsToSendRFQ(false);
      saveRFQ();
    }
    if (isLoggedIn && wantsToSendRFQ && userType == "SELLER") {
      setWantsToSendRFQ(false);
      messageApi
        .open({
          type: "error",
          content: `You can not submit an RFQ as a seller.`,
        })
        .then(function () { });
    }
  }, [isLoggedIn]);

  React.useEffect(() => {

    setRFQValidTo(moment().add(7, 'days').format());
    setRFQValidToTxt(`7 days`);

  }, [])

  const saveRFQ: Function = async () => {
    if (!isLoggedIn) {
      setIsLoginModalOpen(true);
      setWantsToSendRFQ(true);
      return;
    }

    if (savingRFQ) {
      return;
    }
    const data = generateRFQPayload();
    setSavingRFQ(true);
    AxiosAPI.saveRFQ(data)
      .then((result: any) => {
        const response: any = result.data;

        //console.log("SAVED RFQ", response);
        UserPilotHelper.logEvent(UserPilotEvents.SEND_AN_RFQ, response);

        setRFQIDX(`RFQ100${response.id}`);

        localStorage.removeItem("homeRFQDescription");
        localStorage.removeItem("homeRFQQuantity");
        localStorage.removeItem("homeRFQUOM");

        if ("meta" in rfqAttachment) {
          AxiosAPI.saveRFQAttachment(response.id, rfqAttachment, "IMAGE")
            .then((attachmentResult: any) => {

              //console.log("SAVED ATTACHMENT", attachmentResult);

              openDramaNotification();
            })
            .catch((error: any) => {
              //console.log("SAVED ATTACHMENT ERROR", error);
              openDramaNotification();
            });
        } else {
          openDramaNotification();
        }
      })
      .catch((error: any) => {
        openDramaNotification();
        //console.log("ERROR SAVING RFQ", error, data);
      });
  };

  const [gaugePercentage, setGaugePercentage] = useState(0);

  const [segA, setSegA] = useState(0);
  const [segB, setSegB] = useState(0);
  const [segC, setSegC] = useState(0);
  const [segD, setSegD] = useState(0);
  const [segE, setSegE] = useState(0);
  const [segF, setSegF] = useState(0);
  const [segG, setSegG] = useState(0);
  const [segH, setSegH] = useState(0);

  const [rfqScoreTab, setRFQScoreTabNumber] = useState(1);

  const setRFQCategoryOptionsEvent = (cat: any) => {
    console.warn("::: CAT :::", cat);
    /*
          setRFQCategory({
            id: cat.value,
            name: cat.label,
            icon: "None",
            image: "https://img.png",
          });
        */

  };

  const [rfqUOMs, setRFQUOMs] = useState<any>([]);

  const getUOMs: Function = async () => {
    AxiosAPI.publicApiRequest("get", "/model/uoms", null)
      .then(({ data: UOMs }) => {
        //console.log("UOMs", UOMs);

        setRFQUOMs(UOMs);
      })
      .catch((error) => {
        //console.log("ERROR FETCHING UOMs", error);
      });
  };

  const generateRFQPayload = () => {
    const _rfqEstimatedOrderQuantity = rfqEstimatedOrderQuantity;

    _rfqEstimatedOrderQuantity.size = parseFloat(
      String(rfqEstimatedOrderQuantity.size)
    );

    const _rfqPreferredUnitPrice = rfqPreferredUnitPrice;

    _rfqPreferredUnitPrice.amount = parseFloat(
      String(rfqPreferredUnitPrice.amount)
    );

    const attachments: any[] = [];
    if (rfqAttachments.length > 0 && rfqAttachments[0].s3Key) {
      attachments.push(rfqAttachments[0])
    }
    return {
      id: rfqId || 0,
      createdAt: rfqCreatedAt,
      buyerId: 13,
      productName: rfqProductName,
      category: rfqCategory,
      productDetails: rfqProductDetails,
      sourcingType: rfqSourcingType,
      estimatedOrderQuantity: _rfqEstimatedOrderQuantity,
      preferredUnitPrice: _rfqPreferredUnitPrice,
      validTo: rfqValidTo,
      attachments: attachments,
      sendToMoreSuppliers: rfqSendToMoreSuppliers,
    };
  };

  const [progressStepperAddionalClass, setProgressStepperAddionalClass] =
    useState("");

  const [progressStepperMaxWidth, setProgressStepperMaxWidth] = useState("");

  const calculatedValue = useMemo(() => {
    // Do expensive calculation and return.

    let _score: number = 0;

    setSegA(0);
    setSegB(0);
    setSegC(0);
    setSegD(0);
    setSegE(0);
    setSegF(0);
    setSegG(0);
    setSegH(0);

    if (rfqProductName.toString().length > 0) {
      _score = _score + 5;
      setSegA((prevCount) => prevCount + 5);
    }
    //RFQ Name</span><span className="progress-stepper-value">{segA}/60</span></li>

    if (rfqCategory.id > 0) {
      _score = _score + 10;
      setSegB((prevCount) => prevCount + 10);
    }
    //RFQ Category</span><span className="progress-stepper-value">{segB}/28</span></li>

    if (rfqProductDetails.toString().length > 0) {
      let _descriptionScore: number = 0;

      let _descriptionCharacterLength: number = rfqProductDetails.length;

      _descriptionScore = Math.floor(
        _descriptionCharacterLength / 8.33333333333333333333
      );

      if (_descriptionScore > 60) {
        _descriptionScore = 60;
      }

      _score = _score + _descriptionScore;

      setSegC((prevCount) => prevCount + _descriptionScore);
    }
    //About Your RFQ</span><span className="progress-stepper-value">{segC}/2</span></li>

    if (rfqAttachmentsSelected) {
      _score = _score + 10;
      setSegD((prevCount) => prevCount + 10);
    }
    //Add Attachment</span><span className="progress-stepper-value">{segD}/2</span></li>

    //if (rfqSourcingType.toString().length > 0) {
    //  _score = _score + 5;
    //  setSegE((prevCount) => prevCount + 5);
    //}
    //Sourcing Type</span><span className="progress-stepper-value">{segE}/2</span></li>

    if (rfqEstimatedOrderQuantity.size > 0) {
      _score = _score + 5;
      setSegF((prevCount) => prevCount + 5);
    }
    //Estimated Order Quantity</span><span className="progress-stepper-value">{segF}/2</span></li>

    if (rfqPreferredUnitPrice.amount > 0) {
      _score = _score + 5;
      setSegG((prevCount) => prevCount + 5);
    }
    //Preferred Unit Price</span><span className="progress-stepper-value">{segG}/2</span></li>

    if (rfqValidTo.toString().length > 0 && rfqValidTo !== "--/--/----") {
      _score = _score + 5;
      setSegH((prevCount) => prevCount + 5);
    }
    //Valid To</span><span className="progress-stepper-value">{segH}/2</span></li>

    setGaugePercentage(_score / 100);

    return _score;
  }, [
    rfqProductName,
    rfqCategory,
    rfqProductDetails,
    rfqAttachmentsSelected,
    rfqSourcingType,
    rfqEstimatedOrderQuantity,
    rfqPreferredUnitPrice,
    rfqValidTo,
  ]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 150) {
      setProgressStepperAddionalClass("fixedx");
      let obj = document.getElementById("card-section-1");
      let offsetright = window.innerWidth - obj.offsetLeft - obj.offsetWidth;
      setProgressStepperMaxWidth(`${offsetright - 25}px`);
    } else {
      setProgressStepperAddionalClass("");
    }
  };

  const searchParams = new URLSearchParams(window.location.search);
  const currentRFQID = searchParams.get("rfq_id");

  const currentDate = new Date();
  const isoFormattedDate = currentDate.toISOString();

  const [defaultDates, setDefaultsDates] = useState([
    isoFormattedDate
      .substring(0, 10)
      .replace(/(\d{4})-(\d{2})-(\d{2})/, "$2--$3-$1"),
    isoFormattedDate
      .substring(0, 10)
      .replace(/(\d{4})-(\d{2})-(\d{2})/, "$2--$3-$1"),
  ]);

  React.useEffect(() => {
    if (currentRFQID && parseInt(currentRFQID) > 0) {
      AxiosAPI.publicApiRequest('get', `/marketplace/rfqs/${parseInt(currentRFQID)}`, {})
        .then((res: any) => {
          const _rfqDetails: any = res.data;

          console.log("RFQ DETAILS", _rfqDetails);

          setRFQId(0);
          setRFQCreatedAt(isoFormattedDate);
          setRFQBuyerId(_rfqDetails.createdAt);
          setRFQContactName(_rfqDetails.contactName);
          setRFQContactEmail(_rfqDetails.contactEmail);
          setRFQProductName(_rfqDetails.productName);
          setRFQCategory(_rfqDetails.category);
          onChange([_rfqDetails.category.id]);
          setRFQProductDetails(_rfqDetails.productDetails);
          setRFQSourcingType(_rfqDetails.sourcingType);
          setRFQSourcingTypeCustom(_rfqDetails.sourcingType);
          setRFQEstimatedOrderQuantity(_rfqDetails.estimatedOrderQuantity);
          setRFQPreferredUnitPrice(_rfqDetails.preferredUnitPrice);


          setRFQAttachments(_rfqDetails.attachments);
          if (_rfqDetails.attachments.length > 0) {
            setRFQAttachmentsSelected(true);
          }
          if (_rfqDetails.category.name) {
            window.document.getElementsByClassName('ZAGvjd')[1].setAttribute("placeholder", _rfqDetails.category.name)
          }

        })
        .catch((error: any) => {
          console.log("RFQ DETAILS ERROR", error);
        });
    } else {
      //console.log("RFQ=======0=======rfq_id", currentRFQID)

      if (localStorage.getItem("homeRFQDescription") != null) {
        setRFQProductName(localStorage.getItem("homeRFQDescription"));
        rfqEstimatedOrderQuantity.size = parseInt(
          localStorage.getItem("homeRFQQuantity")
        );
        rfqEstimatedOrderQuantity.unitOfMeasurement =
          localStorage.getItem("homeRFQUOM");
      }
    }
  }, [rfqUOMs]);

  React.useEffect(() => {
    getUOMs();
  }, []);

  const validateNumbersOnly: any = (e: any) => {
    //getting key code of pressed key
    var keycode = e.which ? e.which : e.keyCode;
    //comparing pressed keycodes
    if ((keycode < 48 || keycode > 57) && keycode !== 13) {
      e.preventDefault();
      return false;
    }
  };

  const [validationRFQNameFailed, setValidationRFQNameFailed] =
    React.useState(false);
  const validateRFQName: any = (value: any) => {
    if (value === "") {
      setValidationRFQNameFailed(true);
    } else {
      setValidationRFQNameFailed(false);
    }
  };

  const [validationRFQDescriptionFailed, setValidationRFQDescriptionFailed] =
    React.useState(false);
  const validateRFQDescription: any = (value: any) => {
    if (value.length < 40) {
      setValidationRFQDescriptionFailed(true);
    } else {
      setValidationRFQDescriptionFailed(false);
    }
  };

  const [validationRFQSourcingTypeFailed, setValidationRFQSourcingTypeFailed] =
    React.useState(false);
  const validateRFQSourcingType: any = (value: any) => {
    if (value === "") {
      setValidationRFQSourcingTypeFailed(true);
    } else {
      setValidationRFQSourcingTypeFailed(false);
    }
  };

  const [validationRFQOrderQtyFailed, setValidationRFQOrderQtyFailed] =
    React.useState(false);
  const validateRFQOrderQty: any = (value: any) => {
    if (value === "") {
      setValidationRFQOrderQtyFailed(true);
    } else {
      setValidationRFQOrderQtyFailed(false);
    }
  };

  // Generated by AI

  interface Category {
    id: number;
    canHaveProducts: boolean;
    icon: string;
    value: number;
    image: string;
    name: string;
    parentId: number;
    productsCount: number;
    updatedAt: string;
  }

  interface NestedCategory extends Category {
    children?: NestedCategory[];
  }

  const [categoryList, setCategoryList] = useState([]);

  const [selectedCategoryList, setSelectedCategoryList] = useState([]);

  const getCategories: Function = async () => {
    AxiosAPI.publicApiRequest("get", "/model/group-categories/top", {})
      .then(({ data: categories }) => {
        /*
        console.log(
          "CATEGORIES ********* RFQ MARKETPLACE",
          categories.children
        );
        */

        const mappedCats: any[] = [];
        categories.map((cat: any) => {

          mappedCats.push({
            label: cat.name,
            value: cat.id,
            children: [],
            productsCount: cat.productsCount
          });
        });

        setCategoryList(mappedCats);
      })
      .catch((error) => {
        //console.log("ERROR FETCHING CATEGORIES", error);
      });
  };

  const [allCategories, setAllCategories] = useState([]);
  const getAllCategories: Function = async () => {
    AxiosAPI.publicApiRequest("get", "/model/categories", {})
      .then(({ data: categories }) => {
        setAllCategories(categories);
      })
      .catch((error) => { });
  };

  const findCategoryById: any = (
    categoryId: number,
    categories: NestedCategory[]
  ): NestedCategory | undefined => {
    for (const category of categories) {
      if (category.value === categoryId) {
        return category;
      }
      if (category.children) {
        const foundCategory = findCategoryById(categoryId, category.children);
        if (foundCategory) {
          return foundCategory;
        }
      }
    }
    return undefined;
  };

  useEffect(() => {
    getCategories();
    getAllCategories();
  }, []);

  const [categorySuggestionsAvailable, setCategoryOptionsAvailable] =
    useState(false);
  const [suggestedCategories, setSuggestedCategories] = useState<Category[]>(
    []
  );
  const [categoryListOpen, setCategoryListOpen] = useState(false);

  const onChange = (value: string[]) => {
    console.log("CATEGORY:", value);
    setSelectedCategoryList(value);
    const _categoryId: any = value[value.length - 1];
    setRFQCategoryOptionsEvent(findCategoryById(_categoryId, categoryList));

    setTimeout(() => {
      console.log("Should close");
      setCategoryListOpen(false);
    }, 200);
  };

  useEffect(() => {
    if (rfqProductName.length >= 4) {
      AxiosAPI.publicApiRequest(
        "get",
        `/model/search-categories-by-product-name/${rfqProductName}`,
        {}
      )
        .then((res) => {
          setSuggestedCategories(res.data);
          setCategoryOptionsAvailable(true);
        })
        .catch(() => { });
    }
  }, [rfqProductName]);

  const suggestedCategorySelect = (categoryId: number) => {
    suggestedCategories
      .filter((sCategory) => {
        return sCategory.id == categoryId;
      })
      .map((category) => {
        setRFQCategory(category);
        console.log(category);
      });
  };
  const productAttributeValue = (val: any) => {
    return "";
  };
  const fetchAttributes = (catId: number) => {
    //lets fetch attributes
    setAttribute1({ id: 0, name: "", value: "", options: [] });
    setAttribute2({ id: 0, name: "", value: "", options: [] });
    setAttribute3({ id: 0, name: "", value: "", options: [] });
    setAttribute4({ id: 0, name: "", value: "", options: [] });
    setAttribute5({ id: 0, name: "", value: "", options: [] });
    setAttribute6({ id: 0, name: "", value: "", options: [] });
    setAttribute7({ id: 0, name: "", value: "", options: [] });
    setAttribute8({ id: 0, name: "", value: "", options: [] });
    setAttribute9({ id: 0, name: "", value: "", options: [] });
    setAttribute10({ id: 0, name: "", value: "", options: [] });

    AxiosAPI.publicApiRequest("get", `/super/category/${catId}/attributes`, {})
      .then(({ data: attributes }) => {
        for (var x = 0; x <= 9; x++) {
          if (attributes[x]) {
            var y = x + 1;
            var _options = [];
            if (attributes[x].options && attributes[x].options.length > 0)
              _options = attributes[x].options.split(",");
            switch (y) {
              case 1:
                setAttribute1({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 2:
                setAttribute2({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                console.log(attribute2);
                break;
              case 3:
                setAttribute3({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 4:
                setAttribute4({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 5:
                setAttribute5({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 6:
                setAttribute6({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 7:
                setAttribute7({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 8:
                setAttribute8({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 9:
                setAttribute9({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 10:
                setAttribute10({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
            }
          }
        }
      })
      .catch((error: any) => { });
  };
  /* attributes */
  const [attribute1, setAttribute1] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute2, setAttribute2] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute3, setAttribute3] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute4, setAttribute4] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute5, setAttribute5] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute6, setAttribute6] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute7, setAttribute7] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute8, setAttribute8] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute9, setAttribute9] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute10, setAttribute10] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });

  useEffect(() => {
    if (rfqCategory.id > 0) {
      fetchAttributes(rfqCategory.id);
    }
  }, [rfqCategory]);

  const [savingRFQ, setSavingRFQ] = useState(false)
  const onSelectedCategoryListItem: any = (item: any) => {
    console.log("SELECTED CATEGORY LIST ITEM", item);

    if ("label" in item) {
      setRFQCategory({
        id: item.value,
        name: item.label,
        icon: "",
        image: "",
      });
    } else {
      setRFQCategory({
        id: item.id,
        name: item.name,
        icon: "",
        image: "",
      });
    }
  };

  const twoColors: ProgressProps['strokeColor'] = {
    '0%': '#552278',
    '100%': '#552278',
  };

 
  return (
    <>
      <main className="main wolmart rfq">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1">
              <h2
                className="title ls-normal mb-0"
                onClick={openDramaNotification}
              >
                Create RFQ
              </h2>
              <a
                href="#"
                onClick={() => {
                  saveRFQ();
                }}
                className=" btn-primary submit-rfq-btn pl-3 pr-3 font-size-normal ls-25 mb-0 xaction-btn"
                style={{
                  textTransform: 'unset',
                  borderRadius: '0.475rem',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  padding: 'calc(0.75rem + 1px) calc(1.5rem + 1px)',
                  display: 'inline-block',
                  lineHeight: 1.5,
                }}

              >
                <Icon.Target style={{ fontSize: '10px !important', marginRight: '5px' }} ></Icon.Target>
                {!savingRFQ ? ('Submit RFQ') : ('Submitting RFQ')}
              </a>
            </div>
          </div>
          <div className="row">
            {/* begin::Form */}
            <form className="form mt-3">
              <div className="row">
                <div className="col-md-9">
                  <div className="card card-custom" id="card-section-1">
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-lg-3 col-xl-3"></div>
                        <div className="col-lg-9 col-xl-9 gray-rounded">
                          <div className="row">
                            <div className="col-lg-12 col-xl-12 gray-rounded p-4">
                              <div
                                className="row"
                                style={{
                                  background: "#f6f6f6",
                                  borderRadius: "8px",
                                  border: "1px solid #eee",
                                  padding: "5px",
                                }}
                              >
                                <div className="col-4">
                                  <div className="row">
                                    <div className="col-2 pr-0 mr-0">
                                      <span className="badge-counter pull-left mt-1">
                                        1
                                      </span>
                                    </div>
                                    <div className="col-9 pl-0 ml-0">
                                      <span className="pull-left">
                                        Submit a RFQ in just 1 minute.
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-4">
                                  <div className="row">
                                    <div className="col-2 pr-0 mr-0">
                                      <span className="badge-counter pull-left mt-1">
                                        2
                                      </span>
                                    </div>
                                    <div className="col-9 pl-0 ml-0">
                                      <span className="pull-left">
                                        Get quotations from verified suppliers.
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-4">
                                  <div className="row">
                                    <div className="col-2 pr-0 mr-0">
                                      <span className="badge-counter pull-left mt-1">
                                        3
                                      </span>
                                    </div>
                                    <div className="col-9 pl-0 ml-0">
                                      <span className="pull-left">
                                        Compare and choose the best quotation!
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row xform">
                        <label className="col-lg-3 col-form-label text-lg-end">
                          RFQ Name: <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9 col-xl-9">
                          <input
                            type="text"
                            className="form-control form-control-lgx form-control-solid"
                            id="rfqName"
                            name="rfqName"
                            value={rfqProductName}
                            onChange={(_event) =>
                              setRFQProductName(_event.target.value)
                            }
                            onBlur={(_event) => {
                              validateRFQName(_event.target.value);
                            }}
                          />
                          {validationRFQNameFailed && (
                            <div className="form-text text-danger">
                              Please enter the RFQ name.
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row  mb-10">
                        <label className="col-lg-3 col-form-label text-lg-end">
                          RFQ Category: <span className="text-danger">*</span>
                        </label>
                        <div className="rfq-category-drop-down col-lg-9 col-xl-9">
                          {categorySuggestionsAvailable ? (
                            <>
                              <CategorySearchCard
                                selectedItem={onSelectedCategoryListItem}
                                placeholder={"Select a category"}
                                data={suggestedCategories}
                                width={743}
                                zIndex={12}
                              >
                                <Space style={{ padding: "16px 24px" }}>
                                  Not what you want?
                                  <Button
                                    type="text"
                                    onClick={() => {
                                      setCategoryOptionsAvailable(false);
                                      setCategoryListOpen(true);
                                      setTimeout(function () {
                                        window.document.getElementsByClassName('ZAGvjd')[1].click()
                                      }, 200)

                                    }}
                                  >
                                    Choose from all categories
                                  </Button>
                                  <br />
                                  <br />
                                </Space>
                              </CategorySearchCard>
                            </>
                          ) : (
                            <>

                              <CategorySearchCard
                                selectedItem={onSelectedCategoryListItem}
                                placeholder={"Select a category"}
                                data={categoryList}
                                width={743}
                                zIndex={12}
                              />
                            </>
                          )}
                          <div className="form-text text-muted">&nbsp;</div>
                        </div>
                      </div>

                      {/* extra attributes */}
                      <div className="row mb-10">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-9">
                          <div className="row">
                            {attribute1.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute1.name}
                                  </label>
                                  {attribute1.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      onChange={(_event) =>
                                        setAttribute1({
                                          id: attribute1.id,
                                          name: attribute1.name,
                                          value: _event.target.value,
                                          options: attribute1.options,
                                        })
                                      }
                                      defaultValue={attribute1.value}
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute1.value}
                                      onChange={(_event) =>
                                        setAttribute1({
                                          id: attribute1.id,
                                          name: attribute1.name,
                                          value: _event.target.value,
                                          options: attribute1.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute1.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute2.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute2.name}
                                  </label>
                                  {attribute2.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      onChange={(_event) =>
                                        setAttribute2({
                                          id: attribute2.id,
                                          name: attribute2.name,
                                          value: _event.target.value,
                                          options: attribute2.options,
                                        })
                                      }
                                      defaultValue={attribute2.value}
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute2.value}
                                      onChange={(_event) =>
                                        setAttribute2({
                                          id: attribute2.id,
                                          name: attribute2.name,
                                          value: _event.target.value,
                                          options: attribute2.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute2.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="row">
                            {attribute3.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute3.name}
                                  </label>
                                  {attribute3.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute3.value}
                                      onChange={(_event) =>
                                        setAttribute3({
                                          id: attribute3.id,
                                          name: attribute3.name,
                                          value: _event.target.value,
                                          options: attribute3.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute3.value}
                                      onChange={(_event) =>
                                        setAttribute3({
                                          id: attribute3.id,
                                          name: attribute3.name,
                                          value: _event.target.value,
                                          options: attribute3.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute3.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute4.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute4.name}
                                  </label>
                                  {attribute4.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute4.value}
                                      onChange={(_event) =>
                                        setAttribute4({
                                          id: attribute4.id,
                                          name: attribute4.name,
                                          value: _event.target.value,
                                          options: attribute4.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute4.value}
                                      onChange={(_event) =>
                                        setAttribute4({
                                          id: attribute4.id,
                                          name: attribute4.name,
                                          value: _event.target.value,
                                          options: attribute4.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute4.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute5.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute5.name}
                                  </label>
                                  {attribute5.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute5.value}
                                      onChange={(_event) =>
                                        setAttribute5({
                                          id: attribute5.id,
                                          name: attribute5.name,
                                          value: _event.target.value,
                                          options: attribute5.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute5.value}
                                      onChange={(_event) =>
                                        setAttribute5({
                                          id: attribute5.id,
                                          name: attribute5.name,
                                          value: _event.target.value,
                                          options: attribute5.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute5.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute6.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute6.name}
                                  </label>
                                  {attribute6.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute6.value}
                                      onChange={(_event) =>
                                        setAttribute6({
                                          id: attribute6.id,
                                          name: attribute6.name,
                                          value: _event.target.value,
                                          options: attribute6.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute6.value}
                                      onChange={(_event) =>
                                        setAttribute6({
                                          id: attribute6.id,
                                          name: attribute6.name,
                                          value: _event.target.value,
                                          options: attribute6.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute6.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute7.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute7.name}
                                  </label>
                                  {attribute7.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute7.value}
                                      onChange={(_event) =>
                                        setAttribute7({
                                          id: attribute7.id,
                                          name: attribute7.name,
                                          value: _event.target.value,
                                          options: attribute7.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute7.value}
                                      onChange={(_event) =>
                                        setAttribute7({
                                          id: attribute7.id,
                                          name: attribute7.name,
                                          value: _event.target.value,
                                          options: attribute7.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute7.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute8.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute8.name}
                                  </label>
                                  {attribute8.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute8.value}
                                      onChange={(_event) =>
                                        setAttribute8({
                                          id: attribute8.id,
                                          name: attribute8.name,
                                          value: _event.target.value,
                                          options: attribute8.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute8.value}
                                      onChange={(_event) =>
                                        setAttribute8({
                                          id: attribute8.id,
                                          name: attribute8.name,
                                          value: _event.target.value,
                                          options: attribute8.options,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {attribute8.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute9.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute9.name}
                                  </label>
                                  {attribute9.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute9.value}
                                      onChange={(_event) =>
                                        setAttribute9({
                                          id: attribute9.id,
                                          name: attribute9.name,
                                          value: _event.target.value,
                                          options: attribute9.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute9.value}
                                      onChange={(_event) =>
                                        setAttribute9({
                                          id: attribute9.id,
                                          name: attribute9.name,
                                          value: _event.target.value,
                                          options: attribute9.options,
                                        })
                                      }
                                    >
                                      {attribute9.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                            {attribute10.id > 0 && (
                              <>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    {attribute10.name}
                                  </label>
                                  {attribute10.options.length == 0 ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      defaultValue={attribute10.value}
                                      onChange={(_event) =>
                                        setAttribute10({
                                          id: attribute10.id,
                                          name: attribute10.name,
                                          value: _event.target.value,
                                          options: attribute10.options,
                                        })
                                      }
                                    />
                                  ) : (
                                    <select
                                      className="form-select form-select-solid"
                                      value={attribute10.value}
                                      onChange={(_event) =>
                                        setAttribute10({
                                          id: attribute10.id,
                                          name: attribute10.name,
                                          value: _event.target.value,
                                          options: attribute10.options,
                                        })
                                      }
                                    >
                                      {attribute10.options.map((opt: any) => (
                                        <option value={opt}> {opt} </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* end extra attributes */}

                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label text-lg-end">
                          RFQ Description:{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9 col-xl-9">
                          <textarea
                            className="form-control form-control-lgx form-control-solid"
                            rows={5}
                            value={rfqProductDetails}
                            onChange={(_event) => {
                              setRFQProductDetails(_event.target.value);
                              validateRFQDescription(_event.target.value);
                            }}
                          />
                          <div
                            className={`form-text ${rfqProductDetails.length > 39
                              ? "text-muted"
                              : "text-danger"
                              } float-right`}
                          >
                            {rfqProductDetails.length}/500
                          </div>
                          {validationRFQDescriptionFailed && (
                            <div className="form-text text-danger">
                              Please enter the RFQ description with 40
                              characters or more.{" "}
                            </div>
                          )}
                          <div className="form-text text-muted">
                            Please indicate your detailed requirements to ensure
                            fast and efficient responses from suppliers. You may
                            include: Material, Size/Dimension, Grade/Quality
                            Standard, Packaging requirements and/or others.
                          </div>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label text-lg-end">
                          RFQ Image/Attachment:
                        </label>
                        <div className="col-lg-5 col-xl-5">
                          <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 file-uploader">
                            <FileUploader
                              label=""
                              handleChange={uploadRFQAttachment}
                              id="rfqAttachment"
                              name="rfqAttachment"
                              types={fileTypes}
                            />
                          </label>
                          <div className="form-text text-muted">
                            Upload images or files to improve supplier's
                            response rate.
                          </div>
                          <div className="form-text text-muted">
                            Size: 640x640 pixel or above;JPG/JPEG/PNG
                            format;less than 5MB. Do not use unauthorized
                            third-party brand logos and rfq images
                          </div>
                        </div>
                        {rfqAttachment &&
                          rfqAttachment.hasOwnProperty("meta") && (
                            <div className="col-lg-4 col-xl-4">
                              <div className="row">
                                <div className="col-md-4">
                                  {rfqAttachment.meta.type.includes("pdf") ? (
                                    <img
                                      src="https://lrwa.org/wp-content/uploads/2018/12/adobe-pdf-icon.png"
                                      className="no-margins ml-0 mt-0"
                                      style={{
                                        width: "100%",
                                        marginLeft: "0 !important",
                                        marginTop: "0 !important",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={rfqAttachment.data}
                                      className="no-margins ml-0 mt-0"
                                      style={{
                                        width: "100%",
                                        marginLeft: "0 !important",
                                        marginTop: "0 !important",
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-8">
                                  <div className="form-texts text-muted">
                                    File Name:{" "}
                                    <strong>{rfqAttachment.meta.name}</strong>
                                  </div>
                                  <div className="form-texts text-muted">
                                    File Type: {rfqAttachment.meta.type}
                                  </div>
                                  <div className="form-texts text-muted">
                                    Size:{" "}
                                    {formatFileSize(rfqAttachment.meta.size)}
                                  </div>
                                  <div className="form-texts text-muted">
                                    Modified:{" "}
                                    {new Date(rfqAttachment.meta.lastModified)
                                      .toLocaleDateString()
                                      .slice(0, 15)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        {(rfqAttachments.length > 0 && rfqAttachments[0].s3Key) && (
                          <div className="col-lg-4 col-xl-4">
                            <div className="row">
                              <div className="col-md-4">
                                <img
                                  src={`https://api-live.supplyzone.ai/home/download-attachment/` + rfqAttachments[0].s3Key}
                                  className="no-margins ml-0 mt-0"
                                  style={{
                                    width: "100%",
                                    marginLeft: "0 !important",
                                    marginTop: "0 !important",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mb-10 d-none">
                        <label className="col-lg-3 col-form-label text-lg-end">
                          Sourcing Type: <span className="text-danger">*</span>
                        </label>

                        <div className="col-lg-5 col-xl-5">
                          <select
                            className="form-select form-select-solid"
                            name="paymentMethod"
                            id="paymentMethod"
                            value={rfqSourcingType}
                            onBlur={(_event) => {
                              validateRFQSourcingType(_event.target.value);
                            }}
                            onChange={(_event) => {
                              setRFQSourcingTypeCustom(
                                _event.target.value === "OTHER"
                              );
                              setRFQSourcingType(_event.target.value);
                            }}
                          >
                            <option>---select sourcing type---</option>
                            <option selected value={`BUSINESS_SERVICE`}>
                              Business Service
                            </option>
                            <option value={`CUSTOMIZED_PRODUCT`}>
                              Customized Product
                            </option>
                            <option value={`NON_CUSTOMIZED_PRODUCT`}>
                              Non-customized Product
                            </option>
                            <option value={`TOTAL_SOLUTION`}>
                              Total Solution
                            </option>
                            <option value={`OTHER`}>Other</option>
                          </select>
                          {validationRFQSourcingTypeFailed && (
                            <div className="form-text text-danger">
                              Please enter the RFQ sourcing type
                            </div>
                          )}
                        </div>
                        {rfqSourcingTypeCustom && (
                          <div className="col-lg-4 col-xl-4">
                            <input
                              type="text"
                              className="form-control form-control-lgx form-control-solid"
                              value={rfqSourcingType}
                              placeholder="Enter sourcing type here"
                              onBlur={(_event) => {
                                validateRFQSourcingType(_event.target.value);
                              }}
                              onChange={(_event) => {
                                setRFQSourcingType(_event.target.value);
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className="row mb-10">
                        <div className="col-lg-3 "></div>

                        <div className="col-lg-9 col-xl-9">
                          <div className="row">
                            <div className="col-lg-3">
                              <label className="col-form-label">
                                Est. Order Qty:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="estimated-order"
                                type="text"
                                min="1"
                                placeholder="e.g. 1000"
                                onKeyPress={(e) => {
                                  return validateNumbersOnly(e);
                                }}
                                className="form-control form-control-lgx form-control-solid"
                                value={
                                  rfqEstimatedOrderQuantity.size > 0
                                    ? rfqEstimatedOrderQuantity.size
                                    : ""
                                }
                                onChange={(e) =>
                                  setRFQEstimatedOrderQuantity({
                                    ...rfqEstimatedOrderQuantity,
                                    size: parseFloat(e.target.value),
                                  })
                                }
                                onBlur={(_event) => {
                                  validateRFQOrderQty(_event.target.value);
                                }}
                              />
                              {validationRFQOrderQtyFailed && (
                                <div className="form-text text-danger">
                                  Please enter the order qty
                                </div>
                              )}
                              <div className="form-text text-muted"></div>
                            </div>
                            <div className="col-lg-4">
                              <label className="col-form-label">
                                UOM <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select form-select-solid"
                                value={
                                  rfqEstimatedOrderQuantity.unitOfMeasurement
                                }
                                onChange={(e) =>
                                  setRFQEstimatedOrderQuantity({
                                    ...rfqEstimatedOrderQuantity,
                                    unitOfMeasurement: e.target.value,
                                  })
                                }
                              >
                                <option>---select unit---</option>
                                {rfqUOMs.map((item: any, itemIndex: number) => (
                                  <option key={itemIndex} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              <div className="form-text text-muted"></div>
                            </div>
                            <div className="col-lg-2">
                              <label className="col-form-label">Currency</label>
                              <select
                                className="form-select form-select-solid"
                                value={rfqPreferredUnitPrice.currency}
                                onChange={(e) =>
                                  setRFQPreferredUnitPrice({
                                    ...rfqPreferredUnitPrice,
                                    currency: e.target.value,
                                  })
                                }
                              >
                                <option value={`USD`}>USD</option>
                                <option value={`GBP`}>GBP</option>
                                <option value={`EUR`}>EUR</option>
                                <option value={`ZWL`}>ZWL</option>
                              </select>
                              <div className="form-text text-muted"></div>
                            </div>
                            <div className="col-lg-3">
                              <label className="col-form-label">
                              Target Unit Price:
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-lgx form-control-solid"
                                id="modelNumber"
                                name="modelNumber"
                                placeholder="e.g 50"
                                value={
                                  rfqPreferredUnitPrice.amount > 0
                                    ? rfqPreferredUnitPrice.amount
                                    : ""
                                }
                                onChange={(e) =>
                                  setRFQPreferredUnitPrice({
                                    ...rfqPreferredUnitPrice,
                                    amount: parseFloat(e.target.value),
                                  })
                                }
                              />
                              <div className="form-text text-muted"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label text-lg-end">
                          Valid To: <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9 col-xl-9">
                          <div className="row">
                            <div className="col-lg-5">
                              <RangePicker
                                format="DD-MM-YYYY"
                                defaultValue={[
                                  dayjs(new Date()),
                                  dayjs(moment().add(7, 'days').format()),
                                ]}
                                minDate={dayjs(new Date())}
                                onChange={setRFQValidToHandler}
                                value={valueRFQValidTo}
                                style={{ padding: "10px 16px !important" }}
                              />
                              <div className="form-text text-muted"></div>
                            </div>
                            <div className="col-lg-3 mt-1 pt-1">{rfqValidToTxt}</div>
                            <div className="col-lg-3 mt-1 pt-1">&nbsp;</div>
                            <div className="col-lg-3 mt-1 pt-1">

                              <a
                                href="#"
                                onClick={() => {
                                  saveRFQ();
                                }}
                                className="btn-primary submit-rfq-btn pl-3 pr-3 mt-10 font-size-normal  ls-25 mb-0 save-rfq-btn"
                                style={{
                                  textTransform: 'unset',
                                  borderRadius: '0.475rem',
                                  textAlign: 'center',
                                  verticalAlign: 'middle',
                                  padding: 'calc(0.75rem + 1px) calc(1.5rem + 1px)',
                                  display: 'inline-block',
                                  lineHeight: 1.5,
                                }}
                              >
                                <Icon.Target style={{ fontSize: '10px !important', marginRight: '5px' }} ></Icon.Target>
                                Submit RFQ
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-10 d-none">
                        <label className="col-lg-3 col-form-label text-lg-end"></label>
                        <div className="col-lg-9 col-xl-9">
                          {/* begin::Checkbox */}
                          <div className="form-check form-check-custom form-check-solid mb-5">
                            {/* begin::Input */}
                            <input
                              className="form-check-input me-3"
                              type="checkbox"
                              name="declaration"
                              id="declaration"
                              checked={rfqSendToMoreSuppliers}
                              onChange={(_event) =>
                                setRFQSendToMoreSuppliers(_event.target.checked)
                              }
                            />
                            {/* end::Input */}

                            {/* begin::Label */}
                            <label className="form-check-label" htmlFor="ex1">
                              <div className="fw-semibold text-gray-800">
                                {" "}
                                I'd like to send this RFQ to more suppliers, if
                                I have not received 20 quotations within the
                                next 48 hours.
                              </div>
                            </label>
                            {/* end::Label */}
                          </div>

                          <br />
                          <br />
                          {/* end::Checkbox */}
                        </div>
                      </div>
                    </div>
                    {/* end::Body */}
                  </div>
                </div>

                <div
                  className={`col-md-3 progress-stepper-container  ${progressStepperAddionalClass}`}
                  style={{ maxWidth: progressStepperMaxWidth }}
                >
                  <div className="card mb-10 p-5">
                    <h3>RFQ Score</h3>
                    <p>
                      Provide as many details as possible about your request to
                      ensure a faster response from the right suppliers. The
                      higher the score the better responses you will get.
                    </p>
                    <Progress
                      type="dashboard"
                      strokeColor={twoColors}
                      percent={parseInt((gaugePercentage * 100).toFixed(0))}
                      size={240}
                    />

                    <ul style={{ padding: 0, margin: 0 }}>
                      <li
                        className={`progress-stepper-wrapper ${rfqProductName != "" ? "text-purple" : ""
                          }`}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> RFQ Name
                        </span>
                        <span className="progress-stepper-value">5</span>
                      </li>

                      <li
                        className={`progress-stepper-wrapper ${rfqCategory.id > 0 ? "text-purple" : ""
                          } `}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> Product Category
                        </span>
                        <span className="progress-stepper-value">10</span>
                      </li>

                      <li
                        className={`progress-stepper-wrapper ${rfqProductDetails != "" ? "text-purple" : ""
                          } `}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> About Your RFQ
                        </span>
                        <span className="progress-stepper-value">25-60</span>
                      </li>

                      <li
                        className={`progress-stepper-wrapper ${rfqAttachmentsSelected
                          ? "text-purple"
                          : ""
                          } `}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> Add Attachment
                        </span>
                        <span className="progress-stepper-value">10</span>
                      </li>

                      <li
                        className={`progress-stepper-wrapper d-none  ${rfqSourcingType != "" ? "text-purple" : ""
                          }`}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> Sourcing Type
                        </span>
                        <span className="progress-stepper-value">5</span>
                      </li>

                      <li
                        className={`progress-stepper-wrapper ${rfqEstimatedOrderQuantity.size > 0 ? "text-purple" : ""
                          }`}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> Estimated Order Quantity
                        </span>
                        <span className="progress-stepper-value">5</span>
                      </li>

                      <li
                        className={`progress-stepper-wrapper ${rfqPreferredUnitPrice.amount > 0 ? "text-purple" : ""
                          }`}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> Target Unit Price
                        </span>
                        <span className="progress-stepper-value">5</span>
                      </li>

                      <li
                        className={`progress-stepper-wrapper ${rfqValidTo != "" ? "text-purple" : ""
                          }`}
                      >
                        <span className={`progress-stepper `}>
                          <CheckOutlined /> Valid To
                        </span>
                        <span className="progress-stepper-value">5</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
            {/* end::Form */}
          </div>
        </div>
      </main>

      <Modal
        title=""
        className="drama-modal"
        centered
        open={isDramaInProgress}
        onOk={() => setIsDramaInProgress(false)}
        onCancel={() => setIsDramaInProgress(false)}
        footer={
          <>
            {rfqSaved && (
              <>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <CheckOutlined style={{ fontSize: '24px', color: '#0A5C36' }} />
                  </div>
                </div>
              </>
            )}
            <h2 className="status-text">{vendorsStatusText}</h2>
          </>
        }
      >
        <>
          <Donut completed={isDramaCompleted} vendors={vendors} />
        </>
      </Modal>

      <Modal
        title=""
        className="drama-modal"
        centered
        open={rfqSent}
        onOk={() => setRFQSent(false)}
        onCancel={() => setRFQSent(false)}
        footer={
          <>
            <h2 className="status-text">{rfqIDX} was created successfully!</h2>
          </>
        }
      >
        <>
          <div className="drama-svg">
            <iframe
              src="https://lottie.host/embed/5ea51134-466b-4141-aa5e-02762f1aa35d/NvTgkgd2SD.json"
              frameBorder={0}
              style={{ border: "none" }}
            ></iframe>
          </div>
        </>
      </Modal>
    </>
  );
};

export { BuyerRFQCreatePage };
