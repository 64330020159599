import React, {FC} from 'react' 
import MessengerAdvancedRFQListViewPage from './MessengerAdvancedRFQListViewPage'

const MessengerAdvancedInquiryPageWrapper: FC = () => {
  return (
    <>
      <MessengerAdvancedRFQListViewPage />
    </>
  )
}

export default MessengerAdvancedInquiryPageWrapper;
