import React, { useEffect, useState } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import { Badge, Button, Card, Flex, message, Popconfirm, Tag } from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { useAppDataContext } from "../../../../data/context/AppDataContext";
import Snippets from "../../../../helpers/Snippets";

const InvitesListItem: any = ({ item, isPending, getListViewTrigger }: any) => {
  const {
    isLoginModalOpen,
    setIsLoginModalOpen,
    userAccessToken,
    setUserAccessToken,
    userDisplayName,
    setUserDisplayName,
    userEmailAddress,
    setUserEmailAddress,
    userType,
    setUserType,
    userData,
    setUserData,
    userProfile,
    setUserProfile,
    isLoggedIn,
    setIsLoggedIn,
    loginUser,
    logoutUser,
    profileCounters,
    getAllDocuments,
  } = useAppDataContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [revokeConfirmationOpen, setRevokeConfirmationOpen] = useState(false);

  const [revokingEmail, setRevokingEmail] = useState(false);

  const [resendConfirmationOpen, setResendConfirmationOpen] = useState(false);

  const [resendingEmail, setResendingEmail] = useState(false);

  const getDateTime: any = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp);

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    let t = `${day} ${month} ${year}, ${hours}:${minutes}`;

    console.log(":::T:::", t);

    return t;
  };

  const revokeInvite: any = () => {
    setRevokingEmail(true);

    AxiosAPI.revokeInvite(item.id)
      .then((res: any) => {
        console.log("::REVOKED::", res.data);

        if (res.data.success) {
          messageApi.open({
            type: "success",
            content: `Invitation to '${item.email}' has been revoked successfully!`,
          });
        } else {
          messageApi.open({
            type: "success",
            content: `There was an error revoking '${item.email}'. ${res.data.message}`,
          });
        }

        setTimeout(() => {
          setRevokeConfirmationOpen(false);
        }, 500);

        setRevokingEmail(false);

        getListViewTrigger();
        
      })
      .catch((error: any) => {
        messageApi.open({
          type: "success",
          content: `There was an error revoking '${item.email}'. ${error.message}`,
        });

        setTimeout(() => {
          setRevokeConfirmationOpen(false);
        }, 500);

        setRevokingEmail(false);
      });
  };

  const resendInvite: any = () => {
    setResendingEmail(true);

    AxiosAPI.resendInvite(item.id)
      .then((res: any) => {
        console.log("::RESEND::", res.data);

        if (res.data.success) {
          messageApi.open({
            type: "success",
            content: `Invitation to '${item.email}' was resent successfully!`,
          });
        } else {
          messageApi.open({
            type: "success",
            content: `There was an error resending invite to '${item.email}'. ${res.data.message}`,
          });
        }

        setTimeout(() => {
          setResendConfirmationOpen(false);
        }, 500);

        setResendingEmail(false);

        //getListViewTrigger();

      })
      .catch((error: any) => {
        messageApi.open({
          type: "success",
          content: `There was an error resending invite to '${item.email}'. ${error.message}`,
        });

        setTimeout(() => {
          setResendConfirmationOpen(false);
        }, 500);

        setResendingEmail(false);
      });
  };

  return (
    <>
      {contextHolder}
      <div key={item.id} className="card shadow-sm mb-5 pl-2 pr-2">
        <div className="card-body d-flex-invite pr-2 pl-2 pt-2 pb-2">
          <div className="row w-100">
            <div className="col-md-1 text-center">
              <img
                src={`https://ui-avatars.com/api/?name=${item.email}&bold=true&rounded=true&background=552278&color=ffffff&format=svg`}
                width="100%"
                style={{
                  maxHeight: "46px",
                  height: "auto",
                  width: "auto",
                  maxWidth: "100%",
                }}
              />
            </div>
            <div className="col-md-5 r-list-view">
              Email: {item.email}
              <br />
              Invited by {userDisplayName}
            </div>
            <div className="col-md-4 r-list-view">
              Sent {getDateTime(item.createdAt)}
              <br />
              Status:{" "}
              <Tag>
                {isPending
                  ? item.isActive
                    ? item.status
                    : "REVOKED"
                  : item.status}
              </Tag>
            </div>
            {isPending && (
              <div className="col-md-2 r-list-view">
                <Flex
                  className="w-100"
                  gap={10}
                  style={{ float: "right" }}
                  vertical
                >
                  <Popconfirm
                    title="Resend Invite"
                    description={`Are you sure you want to resend an invitation to ${item.email}?`}
                    open={resendConfirmationOpen}
                    onConfirm={resendInvite}
                    okButtonProps={{ loading: resendingEmail }}
                    onCancel={() => setResendConfirmationOpen(false)}
                  >
                    <Button
                      className="w-100"
                      size="middle"
                      block
                      type="primary"
                      disabled={!item.isActive}
                      onClick={() => setResendConfirmationOpen(true)}
                    >
                      Resend Invite
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Revoke Invite"
                    description={`Are you sure you want to revoke an invitation to ${item.email}?`}
                    open={revokeConfirmationOpen}
                    onConfirm={revokeInvite}
                    okButtonProps={{ loading: revokingEmail }}
                    onCancel={() => setRevokeConfirmationOpen(false)}
                  >
                    <Button
                      className="w-100"
                      size="middle"
                      block
                      type="default"
                      disabled={!item.isActive}
                      onClick={() => setRevokeConfirmationOpen(true)}
                    >
                      Revoke
                    </Button>
                  </Popconfirm>
                </Flex>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export { InvitesListItem };
