import React from "react";
import { Flex, Pagination, PaginationProps } from "antd";
import { isMobile } from "react-device-detect";

const itemRender: PaginationProps["itemRender"] = (
  _,
  type,
  originalElement
) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
};

const RFQPagination: any = ({
  showingPageString,
  onShowPageChange,
  onShowSizeChange,
  currentPage,
  currentResultsTotal,
}: any) => (

  <Flex
    className="mt-0 w-100"
    gap={isMobile ? 8 : 8}
    vertical={isMobile}
    align="center" style={{justifyContent: "space-between"}}
  >
    <h5>Showing {currentResultsTotal} results</h5>
    <Pagination
      itemRender={itemRender}
      showSizeChanger
      onChange={onShowPageChange}
      onShowSizeChange={onShowSizeChange}
      current={currentPage}
      total={currentResultsTotal}
      defaultCurrent={1}
      defaultPageSize={5}
      pageSizeOptions={[5, 10, 20, 50, 100]}
    />
  </Flex>
  
);

export default RFQPagination;
