import { Button, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import AxiosAPI from "../../../../../helpers/AxiosAPI";

const RedeemModal: any = ({
  payoutPointsInitial,
  payoutMethod,
  payoutNumber,
  payoutName,
  setPayoutPointsInitial,
}: any) => {
  const [payoutAmount, setPayoutAmount] = useState(0);
  const [payoutPoints, setPayoutPoints] = useState(payoutPointsInitial);

  const refreshCalculations: any = () => {
    setPayoutAmount(payoutPoints / 100);
    setPayoutPoints(payoutPoints);
    setPayoutPointsInitial(payoutPoints);
  };

  useEffect(() => {
    refreshCalculations();
  }, [payoutPoints]);

  // Function to update amount
  const updatePayoutAmount = (newAmount: number) => {
    if (newAmount > 1) {
      setPayoutAmount(newAmount);
      setPayoutPoints(newAmount * 100);
    }
  };

  // Function to update points
  const updatePayoutPoints = (newPoints: number) => {
    if (newPoints > 1) {
      setPayoutAmount(newPoints / 100);
      setPayoutPoints(newPoints);
      setPayoutPointsInitial(newPoints);
    }

    // No need to set payoutPoints here, it will be updated by the useEffect
  };

  return (
    <>
      <form id="kt_signin_change_password" className="form" noValidate>
        <div className="row mb-1 mt-10">
          <div className="col-md-6">
            <div className="fv-row mb-0">
              <label
                htmlFor="currentpassword"
                className="form-label fs-6 fw-bolder mb-3"
              >
                Amount to redeem
              </label>
              <input
                value={payoutAmount}
                onChange={(e) => {
                  updatePayoutAmount(parseFloat(e.target.value));
                }}
                type="text"
                className="form-control form-control-lg form-control-solid "
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="fv-row mb-0">
              <label
                htmlFor="currentpassword"
                className="form-label fs-6 fw-bolder mb-3"
              >
                Points to redeem
              </label>
              <input
                value={payoutPoints}
                onChange={(e) => {
                  updatePayoutPoints(parseInt(e.target.value));
                }}
                type="text"
                max={payoutPoints}
                className="form-control form-control-lg form-control-solid "
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="fv-row mb-0">
              <label
                htmlFor="currentpassword"
                className="form-label fs-6 fw-bolder mb-3"
              >
                {payoutMethod || "Ecocash"} registered number
              </label>
              <input
                value={payoutNumber}
                type="number"
                readOnly
                className="form-control form-control-lg form-control-solid "
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="fv-row mb-0">
              <label
                htmlFor="currentpassword"
                className="form-label fs-6 fw-bolder mb-3"
              >
                {payoutMethod || "Ecocash"} registered name
              </label>
              <input
                value={payoutName}
                type="text"
                readOnly
                className="form-control form-control-lg form-control-solid "
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export { RedeemModal };
