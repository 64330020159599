import React, { useEffect, useState } from "react";

import { Col, Row } from "antd";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import { PersonalDetails } from "./PersonalDetails";
import { Me } from "./model/Me";
import { PersonalAvatar } from "./PersonalAvatar";
import { ChangePassword } from "./ChangePassword";
import { PersonalPreferences } from "./PersonalPreferencies";
import "../profile-wizard.scss";
import { EarnedPoints } from "./EarnedPoints";
import { AnalyticsSettings } from "./AnalyticsSettings";
import { PaymentDetails } from "./PaymentDetails";

const PersonalSettings: React.FC = () => {
  const [me, setMe] = useState(new Me());

  useEffect(() => {
    AxiosAPI.getUserProfile().then((res: any) => {
      setMe(res.data);
    });
  }, []);

  const [payoutMethod, setPayoutMethod] = useState("");

  const [payoutNumber, setPayoutNumber] = useState(0);

  const [payoutName, setPayoutName] = useState("");

  return (
    <>
      <Row gutter={12}>
        <Col span={24}>
          <PersonalDetails me={me}></PersonalDetails>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <EarnedPoints
            payoutMethod={payoutMethod}
            payoutNumber={payoutNumber}
            payoutName={payoutName}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <PaymentDetails
            onNumber={setPayoutNumber}
            onName={setPayoutName}
            onMethod={setPayoutMethod}
          />
        </Col>

        <Col span={24}>
          <AnalyticsSettings></AnalyticsSettings>
        </Col>

        <Col span={24}>
          <ChangePassword></ChangePassword>
        </Col>

        <Col span={24}>
          <PersonalPreferences></PersonalPreferences>
        </Col>
      </Row>
    </>
  );
};

export { PersonalSettings };
