import { FunctionComponent, useState } from "react";
import { Profile } from "../account/profile-wizard/accounts/model/Profile";
import React from "react";
import { Button, Col, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import AxiosAPI from "../../../helpers/AxiosAPI";

interface DocumentListItemProps {
    children?: React.ReactNode;
    profile: Profile;
    products: any[]
}

type LayoutType = Parameters<typeof Form>[0]['layout'];

const ContactUs: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        profile,
        products
    }: any = props;
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [messageApi] = message.useMessage();
    const [sendingMessage, setSendingMessage] = useState(false)
    const sendEmail = (values: any) => {
        const body = {
            productId: products[0].id,
            senderEmail: values.email,
            senderName: values.email,
            message: values.message
        }
        setSendingMessage(true)
        AxiosAPI.publicApiRequest('post', '/marketplace/contact-supplier', body)
            .then(res => {
                setSendingMessage(false)
                form.resetFields();
            });
    }
    return (
        <>
            <div className="card xcards mb-5">
                <div className="collapse show">
                    <div className="card-body border-top p-9">
                        <Col span={24}><strong><h4 style={{ fontWeight: 700 }}>Send your message to {profile.name}</h4></strong></Col>
                        <Col span={24}><hr /></Col>

                        <Col span={24}></Col>
                        <Col span={24}>

                            <Form
                                form={form}
                                layout={formLayout}
                                onFinish={sendEmail}
                            >

                                <Form.Item label="" name="email"
                                    rules={[{ required: true, message: 'Please enter your email' }]}
                                >
                                    <Input placeholder="Your Email" />
                                </Form.Item>
                                <Form.Item label="" name="message"
                                    rules={[{ required: true, message: 'Please enter your message' }]}
                                >
                                    <TextArea rows={4} placeholder="Your Message" />
                                </Form.Item>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        {sendingMessage && (
                                            <>
                                                <img src="/assets/loader/blue.svg" width={18} height={18} style={{ width: "18px", height: "18px", marginRight: "10px" }} />&nbsp;&nbsp;
                                            </>
                                        )}
                                        Send Now
                                    </Button>
                                </Form.Item>
                            </Form>

                        </Col>


                    </div>
                </div>
            </div>
        </>
    )
}

export { ContactUs };