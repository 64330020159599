import * as React from "react";
import { RegisterComponent } from "../layout/RegisterComponent";
import { useParams } from "react-router-dom";
import { Row, Col, message } from "antd";
import Snippets from "../../../helpers/Snippets";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  const userType = "seller"


  const [messageApi, contextHolder] = message.useMessage();
  const [inviteFromId, setInviteFromId] = React.useState('0')

  const key = 'updatable';

  const onRegisterSuccess: any = (userData: any): void => {

    messageApi.open({
      key,
      type: 'loading',
      content: 'Registration successful...',
    });

    setTimeout(() => {

      messageApi.open({
        key,
        type: 'success',
        content: 'Redirecting...',
        duration: 3,
      });

      if (window.localStorage.getItem('isInternalInvite') && window.localStorage.getItem('isInternalInvite') === "yes") {
        (window as any).location.href = `/rfq/marketplace`;
      } else {
        if (window.localStorage.getItem('inviteProfileType') && window.localStorage.getItem('inviteProfileType') === "BUYER") {
          (window as any).location.href = `/buyer/activation`;
        } else {
          (window as any).location.href = `/supplier/activation/${window.localStorage.getItem('inviteFromId')}`;
        }

      }

    }, 3000);


  }

  const onRegisterError: any = (error: any): void => {

    //console.log("::: onRegisterError :::", error);

  }

  return (
    <>
      {contextHolder}
      <Row>
        <Col flex="auto"></Col>
        <Col flex="650px">
          <div
            style={{
              border: "1px solid rgb(245, 245, 245)",
              margin: "50px 0px",
              padding: 25,
              backgroundColor: "#ffffff",
              borderRadius: 10,
              boxShadow: "2px 2px 16px rgba(125,125,125,0.5)"
            }}
          >
            <div className="title-link-wrapper mb-3">
              <h2 className="title mb-0 pt-2 pb-2">{`Register as a ${userType.toLowerCase() === "seller" ? "supplier" : "buyer"} to continue`}</h2>
            </div>
            <RegisterComponent
              onRegisterSuccess={onRegisterSuccess}
              onRegisterError={onRegisterError}
              autoLogin
              isOpened={true}
              isModal={false}
              onClose={undefined}
              showRegisterPopup={undefined}
              modalTitle={`Register as a ${userType.toLowerCase()} to continue`}
              profileType={userType}
              setInviteFromId={setInviteFromId}
            >
            </RegisterComponent>
          </div>
        </Col>
        <Col flex="auto"></Col>
      </Row>
    </>
  );
};
