import { Col, Popover, Row } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { ContactDetails } from "./model/ContactDetails";
import { ContactPerson } from "./model/ContactPerson";
import EditableLabel from "./components/EditableLabel";
import { Me } from "./model/Me";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import { Upload, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";

interface DocumentListItemProps {
    children?: React.ReactNode;
    me: Me;
}
const PersonalDetails: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        me,
    }: any = props;

    const [person, setPerson] = useState(new ContactPerson())
    const [contactDetails, setContactDetails] = useState(new ContactDetails())
    const [dataChanged, setDataChanged] = useState(false)
    const [initialDataLoad, setInitialDataLoad] = useState(false)
    const [editing, setEditing] = useState(true)

    React.useEffect(() => {
        if (!initialDataLoad && (me.person && me.contactDetails)) {
            setPerson(me.person)
            setContactDetails(me.contactDetails)
            setInitialDataLoad(true)
        }
    }, [me])

    const saveChanges = () => {
        //console.log(me)
        const payload = { 
            "id": me.id, 
            "firstName": person.firstName, 
            "lastName": person.lastName, 
            "email": contactDetails.emailAddress, 
            "phoneNumber": contactDetails.phoneNumber, 
            "password": "", 
            "userRole": me.role, 
            "title": person.title, 
            "designation": person.designation 
        }
        AxiosAPI.apiRequest('put',  '/profile/users',payload).then(({ data: users }) => {
            setDataChanged(false)

        }).catch((error) => {
            alert("Email address is already registered.")
        });
       
    }


    useEffect(() => {
      AxiosAPI.getUserProfile().then((res: any) => {
        setImageUrlAvatar(
          `http://api-live.supplyzone.ai/home/download-attachment/${res.data.avatarS3Key}`
        );
      });
    }, []);
    // - AVATAR -
    const [avatarPayload, setAvatarPayload] = React.useState({
      name: "",
      attachmentType: "IMAGE",
      size: 0,
      type: "image/png",
      data: "",
      entityId: 0,
      isPrimary: true,
      imageIndex: 0,
    });
    const [loadingAvatar, setLoadingAvatar] = useState(false);

    const [imageUrlAvatar, setImageUrlAvatar] = useState<string>(
      "https://img.freepik.com/premium-vector/account-icon-user-icon-vector-graphics_292645-552.jpg"
    );

    const restoreAvatar: any = () => {
      setImageUrlAvatar(
        "https://img.freepik.com/premium-vector/account-icon-user-icon-vector-graphics_292645-552.jpg"
      );
    };

    const propsAvatar: UploadProps = {
      beforeUpload(file) {
        setLoadingAvatar(true);
        return new Promise(() => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const imgData: string = reader.result as string;

            setImageUrlAvatar(imgData);

            const _fileData: any = reader.result;

            const _uploadedFile: any = {
              name: file.name,
              attachmentType: "IMAGE",
              size: file.size,
              type: file.type,
              data: _fileData,
              entityId: 0,
              isPrimary: true,
              imageIndex: 0,
            };

            _uploadedFile.label = file.name;

            //console.log("::: AVATAR :::", _uploadedFile);

            setAvatarPayload(_uploadedFile);

            saveAvatar(_uploadedFile);
          };
        });
      },
      listType: "picture-circle",
      showUploadList: false,
      className: "avatar-uploader",
    };
    const saveAvatar: any = (payload: any) => {
      AxiosAPI.saveAvatarAttachment(payload, 1, 2)
        .then((res: any) => {
          //console.warn("::: AVATAR UPLOAD RESULT :::", res.data);

          return;

          setImageUrlAvatar(
            "https://szbackend.eu-west-2.elasticbeanstalk.com/home/download-attachment/" +
              res.data.avatarS3Key
          );

        })
        .catch((error: any) => {
          //console.warn("::: AVATAR ERROR :::", error);
        });
    };

    const content = (
      <div>
        <ul>
          <li>The best image ratio you can use for an avatar is 1:1.</li>
          <li>Permitted file formats include JPEG, GIF and PNG.</li>
          <li>Image size must not be more than 5MB.</li>
          <li>Use the slider below to scale your image.</li>
          <li>
            Drag the image inside the canvas to determine the area to save.
          </li>
        </ul>
      </div>
    );


    return (
      <>
        {me.person && me.contactDetails && (
          <div className="card mb-5">
            <div className="card-header border-0 cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Personal Details</h3>
              </div>
              {!editing && (
                <EditOutlined
                  onClick={(e) => {
                    setEditing(!editing);
                  }}
                />
              )}
            </div>
            <div className="collapse show">
              <div className="card-body border-top p-9">
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={6}
                    lg={6}
                    xl={6}
                    className="pe-2 mb-4"
                  >
                    <>
                      <ImgCrop rotationSlider>
                        <Upload {...propsAvatar}>
                          {imageUrlAvatar ? (
                            <img
                              src={imageUrlAvatar}
                              alt=""
                              onError={restoreAvatar}
                              style={{
                                width: "100%",
                                margin: 5,
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <>
                              <div>
                                {loadingAvatar ? (
                                  <LoadingOutlined />
                                ) : (
                                  <PlusOutlined />
                                )}
                                <div style={{ marginTop: 8 }}>Upload</div>
                              </div>
                            </>
                          )}
                        </Upload>
                      </ImgCrop>
                      <Popover
                        content={content}
                        title="Avatar Image Requiments"
                      >
                        <h6 className="mt-5 mb-0">See image requirements</h6>
                      </Popover>
                    </>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={18}
                    lg={18}
                    xl={18}
                    className="pe-2 mb-4"
                  >
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        className="pe-2 mb-4"
                      >
                        <strong>First Name</strong>
                        <br />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            defaultValue={person.firstName}
                            onChange={(e: any) => {
                              person.firstName = e.target.value;
                              setDataChanged(true);
                            }}
                          ></input>
                        ) : (
                          <span>{person.firstName}</span>
                        )}
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        className="pe-2 mb-4"
                      >
                        <strong>Last Name</strong>
                        <br />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            defaultValue={person.lastName}
                            onChange={(e: any) => {
                              person.lastName = e.target.value;
                              setDataChanged(true);
                            }}
                          ></input>
                        ) : (
                          <span>{person.lastName}</span>
                        )}
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        className="pe-2 mb-4"
                      >
                        <strong>Phone Number</strong>
                        <br />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            defaultValue={contactDetails.phoneNumber}
                            onChange={(e: any) => {
                              contactDetails.phoneNumber = e.target.value;
                              setDataChanged(true);
                            }}
                          ></input>
                        ) : (
                          <span>{contactDetails.phoneNumber}</span>
                        )}
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        className="pe-2 mb-4"
                      >
                        <strong>Email Address</strong>
                        <br />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            defaultValue={contactDetails.emailAddress}
                            onChange={(e: any) => {
                              contactDetails.emailAddress = e.target.value;
                              setDataChanged(true);
                            }}
                          ></input>
                        ) : (
                          <span>{contactDetails.emailAddress}</span>
                        )}
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        className="pe-2 mb-4"
                      >
                        <strong>Designation.</strong>
                        <br />
                        {editing ? (
                          <select
                            className="form-control form-control-lg form-control-solid"
                            defaultValue={person.designation}
                            onChange={(e: any) => {
                              person.designation = e.target.value;
                              setDataChanged(true);
                            }}
                          >
                            <option value="">Select a designation</option>
                            <option value="chief_procurement_officer">
                              Chief Procurement Officer
                            </option>
                            <option value="procurement_manager">
                              Procurement Manager
                            </option>
                            <option value="buyer">Buyer</option>
                            <option value="senior_buyer">Senior Buyer</option>
                            <option value="junior_buyer">Junior Buyer</option>
                            <option value="sourcing_specialist">
                              Sourcing Specialist
                            </option>
                            <option value="category_manager">
                              Category Manager
                            </option>
                            <option value="contract_manager">
                              Contract Manager
                            </option>
                            <option value="supplier_relationship_manager">
                              Supplier Relationship Manager
                            </option>
                            <option value="inventory_manager">
                              Inventory Manager
                            </option>
                            <option value="procurement_analyst">
                              Procurement Analyst
                            </option>
                            <option value="logistics_coordinator">
                              Logistics Coordinator
                            </option>
                            <option value="other">Other</option>
                          </select>
                        ) : (
                          <span>{person.designation}</span>
                        )}
                      </Col>

                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            {dataChanged && (
              <div className="card-footer d-flex justify-content-end py-6 ">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => saveChanges()}
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        )}
      </>
    );
}

export { PersonalDetails };