/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Modal, Row, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { ChangeEvent, FunctionComponent } from "react";
import { FiPaperclip } from "react-icons/fi";
import { LoadingOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';

interface DocumentListItemProps {
    children?: React.ReactNode;
    chatMessageText: any;
    setChatMessageText: any;
    replyMessage: any;
    replyMessageAttachment: any;
    isSending: boolean;
}

const isSelectedClass: string = "chat-list box cursor-pointer relative flex items-center px-4 py-3 bg-theme-1 dark:bg-theme-1";

const isNotSelectedClass: string = "chat-list  cursor-pointer relative flex items-center px-0 py-3 mt-0 border-bottom border-gray-200";

const ChatComposer: FunctionComponent<DocumentListItemProps> = ({ children, ...props }) => {

    const { chatMessageText, setChatMessageText, replyMessage, replyMessageAttachment, isSending }: any = props;

    const [attachmentModalOpen, setAttachmentModalOpen] = React.useState(false);
    const [attachmentType, setAttachmentType] = React.useState('IMAGE')


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {

        let file: any = null;

        if (e.target.files) {
            file = e.target.files[0];
        } else {
            return;
        }

        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = function () {
            //console.log(fileReader.result);
            replyMessageAttachment(file, fileReader.result,attachmentType);
        };
        fileReader.onerror = function () {
            //console.warn(fileReader.error);
        };

        setAttachmentModalOpen(false)
    };


    return (

        <>
            <Modal
                title={"Upload an attachment"}
                open={attachmentModalOpen}
                onOk={() => setAttachmentModalOpen(false)}
                onCancel={() => setAttachmentModalOpen(false)}
                mask={false}
                footer={null}
            >

                <Row className="row row-cols-3 attach-modal" gutter={[20, 20]} >
                    <Col span={8} className="pt-2 pb-2" >
                    <div style={{ marginBottom: "10px" }}
                    onClick={(e) => { setAttachmentType("QUOTATION");(window as any).document.getElementById('attachment').click() }}>
                        <img src="/assets/images/icons/icon-5.png" alt="" style={{ width: "61px", margin:"auto" }} /><br />Quotation
                    </div>
                    </Col>
                    <Col span={8} className="pt-2 pb-2" >
                    <div style={{ marginBottom: "10px" }}
                     onClick={(e) => { setAttachmentType("PURCHASE_ORDER");(window as any).document.getElementById('attachment').click() }}>
                        <img src="/assets/images/icons/icon-1.png" alt="" style={{ width: "61px", margin:"auto" }} /><br />Purchase Order
                    </div>
                    </Col>
                    <Col span={8} className="pt-2 pb-2" >
                    <div style={{ marginBottom: "10px" }}
                    onClick={(e) => { setAttachmentType("DELIVERY_NOTE");(window as any).document.getElementById('attachment').click() }}>
                        <img src="/assets/images/icons/icon-2.png" alt="" style={{ width: "61px", margin:"auto" }} /><br />Delivery Note
                    </div>
                    </Col>
                    <Col span={8} className="pt-2 pb-2" >
                    <div style={{ marginBottom: "10px" }}
                    onClick={(e) => { setAttachmentType("RECEIPT");(window as any).document.getElementById('attachment').click() }}>
                        <img src="/assets/images/icons/icon-3.png" alt="" style={{ width: "61px", margin:"auto" }} /><br />Receipt
                    </div> 
                    </Col>
                    <Col span={8} className="pt-2 pb-2" >
                    <div style={{ marginBottom: "10px" }}
                    onClick={(e) => { setAttachmentType("GRV");(window as any).document.getElementById('attachment').click() }}>
                        <img src="/assets/images/icons/icon-4.png" alt="" style={{ width: "61px", margin:"auto" }} /><br />Goods Received Note
                    </div>
                    </Col>
                    <Col span={8} className="pt-2 pb-2" >
                    <div style={{ marginBottom: "10px" }}
                    onClick={(e) => { setAttachmentType("OTHER");(window as any).document.getElementById('attachment').click() }}>
                        <img src="/assets/images/icons/icon-6.png" alt="" style={{ width: "61px", margin:"auto" }} /><br />Other
                    </div>
                    </Col>
                </Row>

            </Modal>
            
            <Row>
                <Col span={6} push={18} className="btn-input btns-wrapper" style={{paddingRight: 0}}>
                    <div className="composer-btn-wrapper">

                    <Button type="text" shape="circle" size="large" icon={<PaperClipOutlined />} 
                        onClick={
                            (e:any) => {
                                setAttachmentModalOpen(true)
                            }
                        }
                    />

                    <Button type="primary" shape="circle" size="large" icon={ isSending ? <LoadingOutlined/> : <SendOutlined />} 
                        disabled={chatMessageText == ''}
                        onClick={
                            (e:any) => {
                                replyMessage()
                            }
                        } 
                    />

                    </div>
                </Col>
                <Col span={18} pull={6} className="text-input">

                <TextArea 
                    className="w-100 messenger-text-input"
                    rows={1} style={{ marginRight: '5px' }}
                    value={chatMessageText}
                    onChange={(e:any) => { setChatMessageText(e.target.value) }}
                    placeholder="Type your message..."
                    autoSize />
                </Col>
            </Row>

            <input type="hidden" name="base64_image" id="base64_image" />
            <input type="file" onChange={handleFileChange} id="attachment" style={{ position: 'absolute', top: '-1000px' }} />
            

        </>

    );
}

export default ChatComposer;