import { ContactDetails } from "./ContactDetails";
import { ContactPerson } from "./ContactPerson";
import { ProfileDocument } from "./Document";

export class Profile {
    id: number;
  
    name: string;
  
    profileType: string;
  
    isBusiness: boolean = false;
  
    primaryContact: ContactDetails;
  
    primaryContactPerson: ContactPerson;
  
    primaryContactAvatar: string;
  
    tertiaryContact: ContactDetails;
    tertiaryContactPerson: ContactPerson;
    paymentTerms: string;
    vatNumber: string;
    termsAndConditions: string;
  
    sectors: string[];
  
    companyRegistrationNumber: string;
    companyRegisteredCountry: string;
    businessType: string;
    businessSummary: string;
    quotationDefaultMessage: string;
    companyRegisteredAddress: string;
    companyRegisteredCity: string;
    companySize: string;
    companyRegistrationYear: number;
    isOriginalEquipmentManufacturer: boolean;
    leadTimeFrom: number;
    leadTimeTo: number;
  
    documents: ProfileDocument[];
    termOfPayments: [];
    bankDetails: [];
  
    tradingCapacity: string;
    totalCapitalisation: string;
    totalEmployees: string;
    totalAnnualSales: string;
    profileVideoUrl: string;
  
    credentialsBadge: string;
    suburb: string;
    city: string;
    industry: string;
    yearEstablished: string;
  }