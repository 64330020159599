import * as React from "react";
import Cookies from 'universal-cookie';
import AxiosAPI from "../../../../helpers/AxiosAPI";
import Snippets from "../../../../helpers/Snippets";
import axios from 'axios'
import ChatComposer from "../../account/messenger/ChatComposer";
import { Button, Col, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FiPhone } from "react-icons/fi";
import ChatHistory from "../../account/messenger/ChatHistory";
export default ({ showChatWindow, supplier, openChatWindow, isLoggedIn,profileType }: any) => {
    function getInitialsFromName(name: string): string {
        if (typeof name === "undefined") {
            return name;
        }
        name = name.replace("(", "").replace(")", "").replace(".", "");
        const names = name.split(" ");
        if (names.length === 0) {
            return name;
        }
        const initials =
            names[0].substring(0, 1).toUpperCase() +
            names[1].substring(0, 1).toUpperCase();
        return initials;
    }
    const [productSupplier, setProductSupplier] = React.useState(supplier);
    const [buyer, setBuyer] = React.useState({ id: 0 });
    const [token, setToken] = React.useState("");
    const [messageText, setMessageText] = React.useState("");
    const [isSending, setIsSending] = React.useState(false);
    const [messageHeader, setMessageHeader] = React.useState({ id: 0, subject: "Product title", thread: [] });
    const [userProfile, SetUserProfile] = React.useState({ id: 0 });
    const [dummyChatsIsLoading, setDummyChatsIsLoading] = React.useState();
    const [displayName, setDisplayName] = React.useState("...");
    const onDisplayNameHandler: any = (name: any) => {
        setDisplayName(name);
    }
    const [supplierUser,setSupplierUser] = React.useState({"id":0,"person":{"title":"","firstName":"","lastName":""}})

    const fetchMessages = (token: string) => {

        const baseURL = Snippets.urls.getAPIBaseURL();
        var config = {
            method: 'get',
            url: baseURL + '/messages/by-recipient?recipientId=' + productSupplier.id,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        axios(config).then(({ data: messageHeader }) => {
            if( messageHeader.id > 0){
                setMessageHeader(messageHeader)
            }

        }).catch((error) => { });

    }
    const sendMessages = () => {

        if (messageText == "") return
        //todo: the recipient id should be actual user and not profile id
        setIsSending(true)
        const data = { id: messageHeader.id, subject: messageHeader.subject, recipientId: supplierUser.id, message: messageText }
        const baseURL = Snippets.urls.getAPIBaseURL();
        var config = {
            method: 'post',
            url: baseURL + '/messages/',
            headers: {
                'Authorization': 'Bearer ' + Snippets.user.getCurrentUserAccessToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config).then(({ data: messageHeader }) => {
            setMessageHeader(messageHeader)
            setMessageText("")
            setIsSending(false)
        }).catch((error) => {
            setIsSending(false)
         });

    }
   

    const formatDate = (date: Date) => {
        const now = new Date();
        const diff = Math.abs(now.getTime() - date.getTime()) / 1000;

        if (diff < 60) {
            return `${Math.floor(diff)} seconds ago`;
        } else if (diff < 3600) {
            return `${Math.floor(diff / 60)} minutes ago`;
        } else if (diff < 86400) {
            return `${Math.floor(diff / 3600)} hours ago`;
        } else {
            return `${Math.floor(diff / 86400)} days ago`;
        }
    }

    const fetchUser = (supplierId: number) => {

        const baseURL = Snippets.urls.getAPIBaseURL();
        var config = {
            method: 'get',
            url: baseURL + `/marketplace/supplier/${supplierId}/users`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        axios(config).then(({ data: users }) => {
            setSupplierUser(users[0])
            
        }).catch((error) => { });

    }
    const [textWrapperBottom,setTextWrapperBottom] = React.useState("35px")
    React.useEffect(() => {
        const userProfile: any = Snippets.user.getCurrentUserProfile();
        SetUserProfile(userProfile);
        fetchUser(productSupplier.id);
        fetchMessages( Snippets.user.getCurrentUserAccessToken())
    }, [isLoggedIn])



    return (
        <>
            <div className="instant-messenger card-with-title" style={{ display: (showChatWindow && isLoggedIn && profileType === 'BUYER') ? "block" : "none" }}>

                <div className="title-link-wrapper mb-3">
                    <h2 className="title mb-1 pt-0 pb-0 thread-title-btn">
                        <span>Chat With Supplier</span>
                        <Button onClick={() => { openChatWindow() }} className="chat-btn-close" type="text" ><CloseOutlined size={48} /></Button>
                    </h2>
                </div>
                <div className="thread-wrapper supplier">
                    <Row>
                        <Col flex={"56px"}>
                            <img
                                alt={""}
                                src={`https://ui-avatars.com/api/?format=svg&background=cccccc&color=777777&size=48&bold=true&rounded=true&name=${getInitialsFromName(productSupplier.primaryContactPerson.firstName + ' ' + productSupplier.primaryContactPerson.lastName)}`}
                            />
                        </Col>
                        <Col flex="auto">
                            <h6>
                                <strong>{productSupplier.primaryContactPerson.firstName} {productSupplier.primaryContactPerson.lastName}</strong>
                            </h6>
                            <span className="message-text muted-text">
                                {productSupplier.name}
                            </span>
                        </Col>
                        <Col flex={"24px"}>
                            <a className="float-right" href="#">
                                <FiPhone size={24} />
                            </a>
                        </Col>
                    </Row>

                    <div id="scroll-chats" className="row scroll-chats mt-2">
                        <div className="col">
                            <ChatHistory
                                dummyChatsIsLoading={dummyChatsIsLoading}
                                chatHistory={messageHeader.thread}
                                userProfile={userProfile}
                                getInitialsFromName={getInitialsFromName}
                                onDisplayName={onDisplayNameHandler}
                            />
                        </div>
                    </div>

                    <div className="row mt-3 messenger-text-wrapper ddd"  style={{bottom:textWrapperBottom}}>
                        <img
                            src="/assets/images/shadow.png"
                            alt=""
                            className="messenger-text-shadow"
                        />
                        <div className="col">
                            <ChatComposer
                                chatMessageText={messageText}
                                setChatMessageText={setMessageText}
                                replyMessage={sendMessages}
                                replyMessageAttachment={sendMessages}
                                isSending={isSending}
                            />
                        </div>
                    </div>

                </div >

            </div>
        </>
    );
}