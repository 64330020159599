import * as React from "react";
import Snippets from "../../../../../helpers/Snippets";
import { Link } from "react-router-dom";

export default ({ category }) => {
    const baseURL = Snippets.konstants.BASE_URL_API;
    return (
        <>
            {/* Start of Footer */}
            <React.Fragment>

                <div className="swiper-slide category-wrap mt-0 mb-0" style={{ marginBottom: "20px" }}>
                    <div className="xcards category category-classic category-absolute overlay-zoom br-sm pr-1 pl-1 pt-1 pb-1" title={category.name}>
                        <Link to={`/categories/${category.id}`}>
                            <figure className="category-media">
                                {category.image != null ? (
                                    <img src={`${baseURL}/home/download-attachment/${category.image}`} alt="Category" />
                                ):(
                                    <img src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png`} alt="Category" />
                                )}

                            </figure>
                        </Link>
                        <div className="category-content">
                            <h4 className="category-name ls-normal">{category.name}</h4>
                            <Link to={`/categories/${category.id}`} className="btn btn-primary btn-link btn-underline">View Products</Link>
                        </div>
                    </div>
                </div>

            </React.Fragment>
            {/* End of Footer */}
        </>
    );
};
