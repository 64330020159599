import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Flex,
  List,
  MenuProps,
  message,
  Row,
  Skeleton,
  Typography,
} from "antd";
import VirtualList from "rc-virtual-list";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import Snippets from "../../../../helpers/Snippets";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  CommentOutlined,
  FilePdfOutlined,
  MailOutlined,
  PaperClipOutlined,
  PhoneOutlined,
  SyncOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  UserPilotEvents,
  UserPilotHelper,
} from "../../../../helpers/UserPilotHelper";
import { format } from "timeago.js";
import { initializeApp } from "firebase/app";
import { useAppDataContext } from "../../../../data";
import MessengerAdvancedInquiryCol3 from "../messenger/MessengerAdvancedInquiryCol3";
import ChatComposer from "../messenger/ChatComposer";
import ChatHistory from "../messenger/ChatHistory";
import DocumentAttachments from "../messenger/DocumentAttachments";
import BuyerInquiryDetails from "../messenger/BuyerInquiryDetails";
const { Title } = Typography;

const KommandListView: any = ({ currentList, listViewLabel }: any) => {
  const childRef = useRef(null);

  const [listItems, setListItems] = useState(currentList);

  const [loading, setLoading] = useState(false);

  const [loadingFirstTime, setLoadingFirstTime] = useState(true);

  const { userData } = useAppDataContext();

  //fire base config
  const firebaseConfig = {
    apiKey: "AIzaSyCQOwBBZaFOVLS1cBJS-XSWAuKwNna1PKE",
    authDomain: "supply-zone.firebaseapp.com",
    databaseURL: "https://supply-zone.firebaseio.com",
    projectId: "supply-zone",
    storageBucket: "supply-zone.appspot.com",
    messagingSenderId: "751514788423",
    appId: "1:751514788423:web:670d6b392d713e7a5bc762",
  };

  initializeApp(firebaseConfig);
  // Initialize Firebase
  const [firebaseToken, setFirebaseToken] = useState("");
  const messaging = getMessaging();

  function requestPermission() {
    //console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        //console.log('Notification permission granted.');
      }
    });
  }

  getToken(messaging, {
    vapidKey:
      "BIFDaox5IIg_JGcPY2K1SpSieMMVrSya3JKaLTK5QLclWQS73paBdTpmyKyjw12ih-VVT3ADlW7S59T3w3e2N4o",
  })
    .then((currentToken: any) => {
      if (currentToken) {
        //console.log(currentToken)
        setFirebaseToken(currentToken);
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        //console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    })
    .catch((err: any) => {
      //console.log('An error occurred while retrieving token. ', err);
      requestPermission();
      // ...
    });

  useEffect(() => {
    onMessage(messaging, (payload) => {
      //console.log('Message received. ', payload);
      if (!iJustReplied) {
        var config = {
          method: "get",
          url: Snippets.urls.getAPIBaseURL() + "/messages/" + threadId,
          headers: {
            Authorization:
              "Bearer " + Snippets.user.getCurrentUserAccessToken(),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        axios(config).then((res: any) => {
          setChatHistory(res.data.thread);
        });
      }
      setIJustReplied(false);
    });
    // ...
  });

  //end firebase things
  const [isSending, setIsSending] = useState(false);
  const [iJustReplied, setIJustReplied] = useState(false);

  const getTime: Function = (timeString: string): string => {
    if (!timeString) return timeString;
    return format(new Date(timeString), "yyyy-MMM-dd");
  };

  function getInitialsFromName(name: string): string {
    if (typeof name === "undefined") {
      return name;
    }
    name = name.replace("(", "").replace(")", "").replace(".", "");
    const names = name.split(" ");
    if (names.length === 0) {
      return name;
    }
    const initials =
      names[0].substring(0, 1).toUpperCase() +
      names[1].substring(0, 1).toUpperCase();
    return initials;
  }

  const [isListView, setisListView] = useState(true);

  const [dummyUsersIsLoading, setDummyUsersIsLoading] = useState(false);

  const [dummyChatsIsLoading, setDummyChatsIsLoading] = useState(false);

  const [dummyUsers, setDummyUsers] = useState([]);

  const [chatHistory, setChatHistory] = useState([]);

  const [universalResponseId, setUniversalResponseId] = useState(0);

  const [selectedUserIndex, setSelectedUserIndex] = useState(-1);

  const [selectedUser, setSelectedUser] = useState({
    hasOwnProperty: () => {},
    messageThread: {
      senderId: "",
      senderName: "",
      recipientId: "",
      supplierCompanyName: "",
    },
  });

  const [buyerProfile, setSellerProfile] = useState({ id: 0 });

  const [selectedRFQIndex, setSelectedRFQIndex] = useState(-1);

  const [viewState, setViewState] = useState("");

  const [userType, setUserType] = useState<any>("BUYER");

  const selectURLItemIndex: Function = (listData: any) => {
    if (listData.length === 0) return;

    let itemId: number = AxiosAPI.getQueryVariable(window, "id");

    if (!itemId) {
      itemId = AxiosAPI.getQueryVariable(window, "document-id");
    }

    if (!itemId) {
      itemId = AxiosAPI.getQueryVariable(window, "rfq-id");
    }

    if (!itemId) {
      itemId = AxiosAPI.getQueryVariable(window, "inq-id");
    }

    if (!itemId) {
      itemId = listData[0].id;
    }

    const _item: any = findObjectById(listData, itemId);

    //console.log("SELECTED-INDEX-RFQ-0", _item);

    if (!_item) {
      return (window.location.href = Snippets.urls.getMessengerURL(
        user.profile.profileType,
        listViewLabel,
        0
      ));
    }

    //

    setSelectedRFQHandler(_item.object, _item.index);
  };

  const findObjectById: any = (arr: any, id: number) => {
    //console.log(typeof arr, typeof id, id, arr);
    const index = findIndexByAttribute(arr, "id", id);
    if (index === -1) {
      return null;
    }
    return { object: arr[index], id: parseInt(`${id}`), index: index };
  };

  const findIndexByAttribute: any = (
    arr: [],
    attribute: any,
    value: number
  ): number => {
    return arr.findIndex(
      (obj) => parseInt(`${obj[attribute]}`) === parseInt(`${value}`)
    );
  };

  const getListData: Function = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    let _userType: any = Snippets.user.getCurrentUserType();

    let _viewType: any = listViewLabel;

    if (_userType === undefined) {
      //window.location.href = "/";
    }

    console.log("::: USER TYPE :::", _userType);

    if (_viewType.toUpperCase() === "RFQ") {
      if (_userType.toUpperCase() === "BUYER") {
        AxiosAPI.getUserRFQS()
          .then((res: any) => {
            let listData = res.data;
            setLoading(false);
            setLoadingFirstTime(false);
            if (typeof listData === "object") {
              listData = listData.reverse();
            }
            setListItems(listData);
            //setListItems([...listItems, ...listData]);
          })
          .catch((error: any) => {
            setLoading(false);
            setLoadingFirstTime(false);
            //console.log("::: LIST DATA FETCH ERROR :::", error);
          });
      } else {
        AxiosAPI.getSellerRFQs()
          .then((res: any) => {
            let listData = res.data;
            setLoading(false);
            setLoadingFirstTime(false);
            if (typeof listData === "object") {
              listData = listData.reverse();
            }
            setListItems(listData);
            //setListItems([...listItems, ...listData]);
          })
          .catch((error: any) => {
            setLoading(false);
            setLoadingFirstTime(false);
            //console.log("::: LIST DATA FETCH ERROR :::", error);
          });
      }
    } else {
      if (_userType !== undefined) {
        if (_userType.toUpperCase() === "BUYER") {
          AxiosAPI.getUserInquiries()
            .then((res: any) => {
              let listData = res.data;
              setLoading(false);
              setLoadingFirstTime(false);
              if (typeof listData === "object") {
                listData = listData.reverse();
              }
              setListItems(listData);
              //setListItems([...listItems, ...listData]);
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
              setLoading(false);
              setLoadingFirstTime(false);
            });
        } else {
          AxiosAPI.getSellerInquiries()
            .then((res: any) => {
              let listData = res.data;
              setLoading(false);
              setLoadingFirstTime(false);
              if (typeof listData === "object") {
                listData = listData.reverse();
              }
              setListItems(listData);
              //setListItems([...listItems, ...listData]);
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
              setLoading(false);
              setLoadingFirstTime(false);
            });
        }
      } else {
        window.location.href = "/";
      }
    }
  };

  const [threadId, setThreadId] = useState(0);

  const [chatMessageText, setChatMessageText] = useState("");

  const [inquiryIdForMessage, setINQIdForMessage] = useState(0);

  const [rfqIdForMessage, setRFQIdForMessage] = useState(0);
  const [inquiryRespondingCompany, setInquiryRespondingCompany] = useState({
    responseCompanyContactName: "",
    responseCompanyContactNumber: "",
    responseCompanyName: "",
  });

  const [sellerHasRespondedThis, setSellerHasRespondedThis] = useState(false);

  const replyMessage: Function = () => {
    setIJustReplied(true);
    setIsSending(true);
    if (userType === "SELLER") {
      if (listViewLabel === "RFQ") {
        if (!sellerHasRespondedThis) {
          AxiosAPI.replyRFQ(rfqIdForMessage, chatMessageText)
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread);
              setIsSending(false);
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            });
        } else {
          AxiosAPI.replyMessage(threadId, chatMessageText)
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread);
              setIsSending(false);
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            });
        }
      } else {
        UserPilotHelper.logEvent(UserPilotEvents.RESPOND_TO_INQUIRY, {
          id: threadId,
        });
        if (!sellerHasRespondedThis) {
          AxiosAPI.replyInquiry(
            inquiryIdForMessage,
            chatMessageText,
            selectedRFQ
          )
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread);
              setIsSending(false);
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            });
        } else {
          AxiosAPI.replyMessage(threadId, chatMessageText)
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread);
              setIsSending(false);
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            });
        }
      }
    } else {
      AxiosAPI.replyMessage(threadId, chatMessageText)
        .then((res: any) => {
          //setSelectedUser(selectedUser);
          //setSelectedUserIndex(selectedUserIndex);
          setChatMessageText("");
          //console.log("SELECTED_USER", selectedUser);
          //setSelectedUserHandler(selectedUser);
          setChatHistory(res.data.thread);
          setIsSending(false);
        })
        .catch((error: any) => {
          //console.log("::: LIST DATA FETCH ERROR :::", error);
        });
    }
  };

  useEffect(() => {
    console.log("::CONSOLE::", listItems);
  }, [listItems]);

  const loadMoreData = () => {
    getListData();
  };

  const [documentDetailsDrawerOpen, setDocumentDetailsDrawerOpen] =
    useState(false);

  const [documentAttachmentsDrawerOpen, setDocumentAttachmentsDrawerOpen] =
    useState(false);

  const [open, setOpen] = useState(false);

  const [childrenDrawer, setChildrenDrawer] = useState(false);

  const showDrawer = (listViewItem: any) => {
    console.log("LISTVIEW::", listViewItem);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showChildrenDrawer = (listViewItem: any) => {
    console.log("LISTVIEW::CHILD", listViewItem);
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const replyMessageAttachment: Function = (file: any, fileData: any) => {
    let attachmentText: string = chatMessageText;

    if (attachmentText === "") {
      attachmentText = " ";
    }

    AxiosAPI.replyMessageAttachment(threadId, attachmentText, file, fileData)
      .then((res: any) => {
        //console.log("::: REPLY ATTc MESSAGE SUCCESS :::", res.data);
        if (userType === "SELLER") {
          setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
          setChatMessageText("");
        } else {
          setSelectedUserHandler(selectedUser);
          setChatMessageText("");
        }
      })
      .catch((error: any) => {
        //console.log("::: REPLY ATTc MESSAGE ERROR :::", error);
      });
  };

  const [kommandContactListView, setKommandContactList] = useState([]);

  const [kommandContactDetails, setKommandContactDetails] = useState({
    title: "",
    subTitle: "",
    avatar: "",
  });

  const [kommandContactThread, setKommandContactThread] = useState([]);

  const getBuyerSellerRFQResponses: Function = (listItem: any) => {

    setDummyUsersIsLoading(true);

    setDummyUsers([]);

    AxiosAPI.getBuyerSellerRFQResponses(listItem.id)
      .then((res: any) => {
        
        let listData = res.data;

        if (listData.length === 0) return;

        processUserResponses(listData);

      })
      .catch((error: any) => {
        setDummyUsersIsLoading(false);
        //console.log("::: LIST DATA FETCH ERROR :::", error);
      });
  };

  const getBuyerSellerInquiryResponses: Function = (listItem: any) => {

    setDummyUsersIsLoading(true);

    setDummyUsers([]);

    AxiosAPI.getUserInquiryResponses(listItem.id)
      .then((res: any) => {

        let listData = res.data;

        if (listData.length === 0) return;

        processUserResponses(listData);

      })
      .catch((error: any) => {
        //console.log("::: LIST DATA FETCH ERROR :::", error);
        setDummyUsersIsLoading(false);
      });
  };

  const processUserResponses: any = (listData: any) => {

    console.log("::: **** PROCESS USER RESPONSE :::", listData);

    if (typeof listData === "object") {

      listData.reverse();

      listData = listData.map((listItem: any) => {

        const thread: any = listItem.messageThread.thread;

        const latestMessage: any = thread[thread.length - 1];

        console.log("::: **** LIST DATA ITEM :::", listItem);

        console.log("::: **** LATEST MESSAGE :::", latestMessage);

        const sellerPayload: any = {
          id: listItem.id,
          displayName: listItem.supplierName,
          displayTitle: listItem.supplierCompanyName,
          displayAvatar: `https://ui-avatars.com/api/?name=${listItem.supplierName}?bold=true&rounded=true&background=552278&color=ffffff&format=svg`,
          profile: {
            id: listItem.supplierId,
            name: listItem.supplierName,
            title: listItem.supplierCompanyName,
          },
          sender: {
            id: listItem.messageThread.senderId,
            name: listItem.messageThread.senderName,
            title: listItem.messageThread.senderCompany,
          },
          latestMessage: {
            text: latestMessage.message,
            timestamp: latestMessage.date,
            meta: latestMessage,
          },
          thread: thread,
          threadId: listItem.messageThread.id,
          requestForQuoteId: listItem.requestForQuoteId,
        };

        console.log("::: **** SELLER PAYLOAD :::", sellerPayload);

        const attachments: any[] = [];

        thread.map((chat: any): void => {
          if (chat.s3Key && chat.s3Key.length > 4) {
            attachments.push({
              name: chat.attachmentName,
              type: chat.attachmentType,
              s3Key: chat.s3Key,
              modified: chat.date.split("T")[0],
              senderCompany: chat.senderCompany,
            });
          }
        });

        setAttachmentList(attachments);

        console.log(
          "::: 2 LIST DATA ::: FOR SELECTED USER ::: sellerPayload : listData : attachments :::",
          sellerPayload,
          listData,
          attachments
        );

        return sellerPayload;

      });

      setDummyUsers(listData);

      setKommandContactList(listData);

      setSelectedUser(listData[0]);

      setSelectedUserIndex(0);
      
      setDummyUsersIsLoading(false);

    }

  };

  const updateFCMTokenAndThread = () => {
    if (!firebaseToken || firebaseToken == "") return;
    if (threadId == 0) return;

    var config = {
      method: "post",
      url: Snippets.urls.getAPIBaseURL() + "/messages/token",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        threadId: threadId,
        token: firebaseToken,
      },
    };
    axios(config).then((res: any) => {
      //console.log(res)
    });
  };

  useEffect(() => {
    updateFCMTokenAndThread();
  }, [threadId]);

  const getChatHistory: Function = (
    responseId: any,
    inquiryResponseId: any
  ) => {
    //console.log("::: getChatHistory :::", responseId);

    setDummyChatsIsLoading(true);

    setUniversalResponseId(responseId);

    function noChatHistory() {

      setDummyChatsIsLoading(false);

      setChatHistory([]);

    }
    
    const attachments: any = [];
    if (userType.toUpperCase() === "BUYER") {
      if (listViewLabel === "RFQ") {
        AxiosAPI.getBuyerRFQResponsesChats(responseId)
          .then((res: any) => {
            setDummyChatsIsLoading(false);

            let listData = res.data;
            //console.log("Found: ", listData)
            listData.messageThread.thread.map((chat: any): void => {
              if (chat.s3Key && chat.s3Key.length > 4) {
                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                });

                //console.log("Found: ", chat.attachmentName)
              }

              return null;
            });
            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getBuyerRFQResponsesChats :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            //console.warn("::: CHAT ERROR getBuyerRFQResponsesChats :::", error);
            noChatHistory();
            setSellerHasRespondedThis(false);
          });
      } else {
        if (inquiryResponseId === undefined)
          inquiryResponseId = inquiryIdForMessage;

        AxiosAPI.getBuyerINQResponsesChats(inquiryResponseId, responseId)
          .then((res: any) => {
            setDummyChatsIsLoading(false);

            let listData = res.data;
            listData.messageThread.thread.map((chat: any): void => {
              if (chat.s3Key && chat.s3Key.length > 4) {
                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                });
              }

              return null;
            });

            console.log("!!!!!::::", listData);

            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getBuyerINQResponsesChats :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            //console.warn("::: CHAT ERROR getBuyerINQResponsesChats :::", error);
            noChatHistory();
            setSellerHasRespondedThis(false);
          });
      }
    } else {
      if (listViewLabel === "RFQ") {
        AxiosAPI.getSellerRFQResponsesChats(responseId)
          .then((res: any) => {
            setSellerHasRespondedThis(true);
            setDummyChatsIsLoading(false);

            let listData = res.data;
            listData.messageThread.thread.map((chat: any): void => {
              if (chat.s3Key && chat.s3Key.length > 4) {
                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                });
              }

              return null;
            });
            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getSellerRFQResponsesChats :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            /*
            console.warn(
              "::: CHAT ERROR getSellerRFQResponsesChats :::",
              error
            );
            */
            noChatHistory();
            setSellerHasRespondedThis(false);
            setRFQIdForMessage(responseId);
          });
      } else {
        AxiosAPI.getSellerINQResponsesChats(responseId)
          .then((res: any) => {
            setSellerHasRespondedThis(true);
            setDummyChatsIsLoading(false);

            let listData = res.data;
            listData.messageThread.thread.map((chat: any): void => {
              if (chat.s3Key && chat.s3Key.length > 4) {
                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                });
              }

              return null;
            });
            //console.warn("::: CHAT getSellerINQResponsesChats 1 :::", listData);

            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            //console.warn("::: CHAT getSellerINQResponsesChats 2 :::", listData);

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getSellerINQResponsesChats 3 :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            /*
            console.warn(
              "::: CHAT ERROR getSellerINQResponsesChats :::",
              error
            );
            */
            noChatHistory();
            setSellerHasRespondedThis(false);
            setINQIdForMessage(responseId);
          });
      }
    }
    setAttachmentList(attachments);
  };

  const [attachmentList, setAttachmentList] = useState([]);

  const getRFQAttachments: any = (listItem: any) => {
    //console.log("GET MESSAGE THREAD", listItem);
  };

  const resetViewStateHandler: any = () => {
    setViewState(listViewLabel);
  };

  const setSelectedRFQHandler = (listItem: any, listItemIndex: number) => {
    console.log(":::::::", listItem, listItemIndex);

    listItem.unreadMessages = 0;
    setisListView(false);

    setSelectedRFQ(listItem);
    setSelectedRFQIndex(listItemIndex);

    getRFQAttachments(listItem);

    if (userType.toUpperCase() === "BUYER") {
      setViewState(listViewLabel);
      if (listViewLabel === "RFQ") {
        getBuyerSellerRFQResponses(listItem);
      } else {
        getBuyerSellerInquiryResponses(listItem);
      }
    } else {
      getChatHistory(listItem.id);
    }
  };

  const setSelectedUserHandler = (user: any) => {
    //console.log("::: SELECTED USER :::", user);

    function setupUser(user: any) {
      setSelectedUser(user);

      setINQIdForMessage(user.id);

      getChatHistory(user.inquiryHeaderId, user.id);

      setViewState("CHAT");

      setTimeout(() => {
        var objDiv = document.getElementById("kt_chat_messenger_body_scroll");

        if (typeof objDiv !== "undefined" && objDiv !== null) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      }, 2000);
    }

    if (user !== null) {
      setupUser(user);
    } else {
      AxiosAPI.getProfileData()
        .then((res: any) => {
          let profileData = res.data;

          //console.log("::: SETUP USER PROFILE :::", profileData);

          setupUser(user);
        })
        .catch((error: any) => {
          //console.log("::: SETUP USER PROFILE ERROR :::", error);
        });
    }
  };

  const [selectedRFQ, setSelectedRFQ] = useState({
    id: 0,
    createdAt: "",
    validTo: "",
    productName: "",
    category: {
      name: "",
    },
    sourcingType: "",
    rfqItems: [],
    preferredUnitPrice: {
      currency: "",
      amount: "",
    },
    productDetails: "",
    estimatedOrderQuantity: {
      size: "",
      unitOfMeasurement: "",
    },
  });

  const [userAvatar, setUserAvatar] = React.useState(
    "https://t4.ftcdn.net/jpg/03/32/59/65/360_F_332596535_lAdLhf6KzbW6PWXBWeIFTovTii1drkbT.jpg"
  );

  useEffect(() => {
    //console.log(":::::::: LOGGED iN DATA 0 :::", Snippets.user.getCurrentUserAccessToken());

    AxiosAPI.getProfileData()
      .then((res: any) => {
        //console.log(":::::::: LOGGED iN DATA 1 SUCCESS :::", res.data);

        setLoggedInUserData(res.data);

        setTimeout(() => {
          setUserType(Snippets.user.getCurrentUserType());

          getProfileData(Snippets.user.getCurrentUserType(), listViewLabel);
        }, 2000);
      })
      .catch((e: any) => {
        //console.log(":::::::: LOGGED iN DATA 1 ERROR :::", e);
      });
  }, []);

  const [loggedInUserData, setLoggedInUserData] = React.useState({
    email: "",
    person: {
      firstName: "",
      lastName: "",
      title: "",
      designation: "",
    },
    avatarS3Key: "",
  });

  const getProfileData: Function = (_userType: string, _viewType: string) => {
    AxiosAPI.getProfileData()
      .then((res: any) => {
        let profileData = res.data;

        //console.log("::: PROFILE :::", profileData);

        setLoggedInUserData(profileData);

        if ("avatarS3Key" in profileData) {
          if (profileData.avatarS3Key && profileData.avatarS3Key !== "") {
            setUserAvatar(
              "https://api-live.supplyzone.ai/home/download-attachment/" +
                profileData.avatarS3Key
            );
          }
        }

        setSellerProfile(profileData);

        getListData(`${_userType}`, `${_viewType}`);
      })
      .catch((error: any) => {
        //console.log("::: PROFILE ERROR :::", error);
      });
  };

  const [user, setUser] = React.useState({
    email: "",
    avatarS3Key: "",
    pic: "",
    profile: {
      profileType: "...",
      primaryContactPerson: {
        title: "",
        firstName: "",
        lastName: "",
        designation: "",
      },
    },
  });

  React.useEffect(() => {
    const _user: any = Snippets.user.getCurrentUserProfile();

    setUser(_user);

    //let userFullName: string = Snippets.user.getCurrentUserFullName();
  }, []);

  const [displayName, setDisplayName] = useState("...");
  const onDisplayNameHandler: any = (name: any) => {
    setDisplayName(name);
  };

  const [recipient, setRecipient] = React.useState({
    id: 0,
    person: {
      title: "",
      designation: "",
      firstName: "",
      lastName: "",
    },
    contactDetails: {
      emailAddress: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      phoneNumber: "",
    },
    recipient: "",
  });
  const [recipientId, setRecipientId] = React.useState<any>(0);

  useEffect(() => {
    if (typeof selectedUser === "object") {
      if ("messageThread" in selectedUser) {
        if ("supplierCompanyName" in selectedUser.messageThread) {
          setDisplayName(selectedUser.messageThread.supplierCompanyName);
        } else {
          setDisplayName(selectedUser.messageThread.senderName);
        }

        const localRecipientId =
          selectedUser.messageThread.senderId === userData?.id
            ? selectedUser.messageThread.recipientId
            : selectedUser.messageThread.senderId;
        if (localRecipientId) {
          setRecipientId(localRecipientId);
          //console.log("localRecipientId", localRecipientId)
        }
      }
    }
  }, [selectedUser]);

  const [themeColor, setThemeColor] = useState("");

  useEffect(() => {
    if (Snippets.user.getCurrentUserType() === "SELLER") {
      setThemeColor("ad1e23");
    } else {
      setThemeColor("552277");
    }

    const userProfile: any = Snippets.user.getCurrentUserProfile();

    SetUserProfile(userProfile);

    let localRecipientId = 0;
    chatHistory.map((chat: any, chatIndex: number): void => {
      /*
      console.log(
        "SENDER AND RECEIVER",
        chat.senderId === userData?.id,
        chat.senderId,
        userData?.id,
      );
      */

      if (Snippets.user.getCurrentUserType() === "SELLER") {
        const localRecipientId =
          chat.senderId === userData?.id ? chat.recipientId : chat.senderId;
        if (localRecipientId) {
          setRecipientId(localRecipientId);
          //console.log("SellerlocalRecipientId", localRecipientId);
          return null;
        }
      }
    });

    return () => {
      setDisplayName("");
    };
  }, [chatHistory]);

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber == null) return "";

    let formattedPhoneNumber = phoneNumber.replace(/^0/, "");
    formattedPhoneNumber = formattedPhoneNumber.replace("+263", "");
    return "+263" + formattedPhoneNumber;
  };

  const itemsPhone: MenuProps["items"] = [
    {
      key: "0",
      icon: <PhoneOutlined />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={"tel:+263772128622"}>
          Phone: {recipient.contactDetails.phoneNumber}
        </a>
      ),
    },
    {
      key: "1",
      icon: <PhoneOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://dialer.supplyzone.ai/?phoneNumber=${formatPhoneNumber(
            recipient.contactDetails.phoneNumber
          )}`}
        >
          Voip: {recipient.contactDetails.phoneNumber}
        </a>
      ),
    },
    {
      key: "2",
      icon: <WhatsAppOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"https://wa.me/+263772128622?text=Test"}
        >
          WhatsApp: {recipient.contactDetails.phoneNumber}
        </a>
      ),
    },
    {
      key: "3",
      icon: <MailOutlined />,
      label: (
        <a href={`#contact-form`}>
          Email: {recipient.contactDetails.emailAddress}
        </a>
      ),
    },
  ];

  useEffect(() => {
    if (recipientId > 0) {
      AxiosAPI.apiRequest("GET", `/marketplace/users/${recipientId}`, {}).then(
        ({ data: recipient }) => {
          setRecipient(recipient);
        }
      );
    }
  }, [recipientId]);

  const [userProfile, SetUserProfile] = useState({ id: 0 });

  const [textWrapperBottom, setTextWrapperBottom] = React.useState("35px");

  useEffect(() => {
    const userProfile: any = Snippets.user.getCurrentUserProfile();
    SetUserProfile(userProfile);
  }, []);

  useEffect(() => {
    console.log("SELECTED ITEM >> ::: >>", selectedRFQ);
  }, [selectedRFQ]);

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        {loadingFirstTime ? (
          <>
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
          </>
        ) : (
          <InfiniteScroll
            dataLength={listItems.length}
            next={loadMoreData}
            hasMore={listItems.length < 5}
            loader={
              <>
                <Divider plain>
                  <SyncOutlined spin /> loading...
                </Divider>
              </>
            }
            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv"
          >
            <List
              key={"id"}
              dataSource={listItems}
              renderItem={(item: any, itemIndex: number) => (
                <List.Item
                  key={item.id}
                  onClick={() => {
                    showDrawer(item);
                    setSelectedRFQHandler(item, itemIndex);
                  }}
                >
                  <List.Item.Meta
                    //avatar={<Avatar src={item.picture.large} />}
                    title={
                      <>
                        <Flex
                          style={{ width: "100%" }}
                          justify="space-between"
                          className="list-view-title"
                        >
                          <span className="one-liner-text">
                            <strong>
                              {listViewLabel === "RFQ"
                                ? item.productName
                                : item.title}
                            </strong>
                          </span>
                          <span className="text-grey">
                            {format(item.updatedAt)
                              .replace("year", "yr")
                              .replace("month", "mo")
                              .replace("week", "wk")
                              .replace("hour", "hr")
                              .replace("minute", "min")
                              .replace("second", "sec")
                              .replace(" ago", "")}
                          </span>
                        </Flex>
                      </>
                    }
                    description={
                      <>
                        {listViewLabel === "RFQ" ? (
                          <Flex
                            style={{ width: "100%" }}
                            justify="space-between"
                            className="horizontal-center"
                          >
                            <span className="">
                              <strong>
                                RFQ{Snippets.string.rfqNumber(item)}
                              </strong>
                              <br />
                              <span className="one-liner-text">
                                Category: {item.category.name}
                              </span>
                            </span>
                            <span className="">
                              <Flex
                                style={{ width: "100%" }}
                                justify="space-between"
                              >
                                {item.attachments > 0 && <PaperClipOutlined />}

                                {item.unreadMessages > 0 && (
                                  <Badge
                                    count={item.unreadMessages}
                                    color="#542278"
                                  />
                                )}
                              </Flex>
                            </span>
                          </Flex>
                        ) : (
                          <>
                            {"lineItems" in item &&
                              Array.isArray(item.lineItems) && (
                                <Flex
                                  style={{ width: "100%" }}
                                  justify="space-between"
                                  className="horizontal-center"
                                >
                                  <span className="">
                                    <strong>
                                      INQ{Snippets.string.inqNumber(item)}
                                    </strong>
                                    <br />
                                    <span className="one-liner-text">
                                      Category:{" "}
                                      {item.lineItems[0].category.name}
                                    </span>
                                  </span>
                                  <span className="">
                                    <Flex
                                      style={{ width: "100%" }}
                                      justify="space-between"
                                    >
                                      {item.messageAttachmentsCount > 0 && (
                                        <PaperClipOutlined />
                                      )}

                                      {item.unreadMessages > 0 && (
                                        <Badge
                                          count={item.unreadMessages}
                                          color="#542278"
                                        />
                                      )}
                                    </Flex>
                                  </span>
                                </Flex>
                              )}
                          </>
                        )}
                      </>
                    }
                  />
                  <div></div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        )}
      </div>
      <Drawer
        title={
          <>
            <Flex justify="space-between" align="center">
              <Title level={3} style={{ margin: 0 }}>
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined color="#ffffff" />}
                  onClick={onClose}
                />{" "}
                Messages
              </Title>
              <Flex justify="space-between" align="center">
                <Button
                  size="large"
                  icon={<FilePdfOutlined color="#ffffff" />}
                  type="text"
                  onClick={() => {
                    setDocumentDetailsDrawerOpen(true);
                  }}
                />
                <Badge count={attachmentList.length}>
                  <Button
                    size="large"
                    icon={<PaperClipOutlined color="#ffffff" />}
                    type="text"
                    onClick={() => {
                      setDocumentAttachmentsDrawerOpen(true);
                    }}
                  />
                </Badge>
              </Flex>
            </Flex>
          </>
        }
        closeIcon={<></>}
        width={520}
        closable={true}
        onClose={onClose}
        open={open}
        className="kommand"
      >
        {loading ? (
          <div className="pr-5 pl-5 pt-5 pb-5">
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
            <Skeleton avatar paragraph={{ rows: 1 }} active />
          </div>
        ) : (
          <div
            id="scrollableDivInner"
            style={{
              overflow: "auto",
              padding: "0 16px",
              border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            <Title className="mt-5" level={4}>
              {listViewLabel === "RFQ"
                ? `RFQ${Snippets.string.rfqNumber(selectedRFQ)}`
                : `INQ${Snippets.string.inqNumber(selectedRFQ)}`}
            </Title>
            <Divider />
            <InfiniteScroll
              dataLength={kommandContactListView.length}
              next={loadMoreData}
              hasMore={kommandContactListView.length < 50}
              loader={
                <>
                  {/* <Divider plain><SyncOutlined spin /> loading...</Divider> */}
                </>
              }
              endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
              scrollableTarget="scrollableDivInner"
            >
              <List
                key={"id"}
                dataSource={kommandContactListView}
                renderItem={(item: any, itemIndex: number) => (
                  <List.Item
                    key={item.id}
                    onClick={() => {
                      setThreadId(item.threadId);
                      setKommandContactDetails({
                        title: item.displayName,
                        subTitle: item.displayTitle,
                        avatar: item.displayAvatar,
                      });
                      setKommandContactThread(item.thread);
                      showChildrenDrawer(item);
                      setSelectedUser(item);
                      setSelectedUserIndex(itemIndex);
                    }}
                  >
                    <List.Item.Meta
                      avatar={<Avatar size={48} src={item.displayAvatar} />}
                      title={
                        <>
                          <Flex
                            style={{ width: "100%" }}
                            justify="space-between"
                          >
                            <span>
                              <strong>{item.displayName}</strong>
                            </span>
                            <span className="text-grey">
                              {format(item.latestMessage.timestamp).replace(
                                " ago",
                                ""
                              )}
                            </span>
                          </Flex>
                        </>
                      }
                      description={
                        <>
                          <Flex
                            style={{ width: "100%" }}
                            justify="space-between"
                            className="list-view-title"
                          >
                            <span className="one-liner-text">
                              {item.latestMessage.text}
                            </span>
                            {/* 
                            <span className="">
                              <Flex
                                style={{ width: "100%" }}
                                justify="space-between"
                              >
                                {item.messageAttachmentsCount > 0 && (
                                  <PaperClipOutlined />
                                )}

                                {item.unreadMessages > 0 && (
                                  <Badge
                                    count={item.unreadMessages}
                                    color="#542278"
                                  />
                                )}
                              </Flex>
                            </span>
                            */}
                          </Flex>
                        </>
                      }
                    />
                    <div></div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>

            <Drawer
              title={`Inquiry Details`}
              width={"90%"}
              open={documentDetailsDrawerOpen}
              onClose={() => {
                setDocumentDetailsDrawerOpen(false);
              }}
              closable={true}
            >
              <div className="w-100 pt-5 pl-5 pr-5">
                <BuyerInquiryDetails
                  ref={childRef}
                  listViewLabel={listViewLabel}
                  selectedRFQ={selectedRFQ}
                  getTime={getTime}
                  inquiryRespondingCompany={inquiryRespondingCompany}
                />
              </div>
            </Drawer>
            <Drawer
              title={`Attachments List`}
              width={"90%"}
              open={documentAttachmentsDrawerOpen}
              onClose={() => {
                setDocumentAttachmentsDrawerOpen(false);
              }}
              closable={true}
            >
              <div className="w-100 pt-5 pl-5 pr-5">
                {attachmentList.length === 0 ? (
                  <Empty
                    imageStyle={{ height: 150 }}
                    description={<span>No attachments.</span>}
                  />
                ) : (
                  <div
                    className="document-details-wrapper"
                    style={{
                      height: "100px",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    {attachmentList.map(
                      (attachment: any, attachmentKey: number) => (
                        <DocumentAttachments
                          key={attachmentKey}
                          attachment={attachment}
                          inquiryRespondingCompany={inquiryRespondingCompany}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            </Drawer>
          </div>
        )}
        <Drawer
          className="message-thread"
          title={
            <>
              <Row>
                <Col span={"24"}>
                  <Flex align="center" className="w-100" gap={8}>
                    <Button
                      type="text"
                      icon={<ArrowLeftOutlined color="#ffffff" />}
                      onClick={onChildrenDrawerClose}
                    />{" "}
                    <img
                      alt=""
                      style={{
                        objectFit: "cover",
                        width: "48px",
                        height: "48px",
                        borderRadius: "50px",
                      }}
                      src={
                        "primaryContactAvatar" in recipient &&
                        recipient.primaryContactAvatar
                          ? "https://api-live.supplyzone.ai/home/download-attachment/" +
                            recipient.primaryContactAvatar
                          : `https://ui-avatars.com/api/?format=svg&background=cccccc&color=777777&size=48&bold=true&rounded=true&name=${
                              recipient.person.firstName +
                              " " +
                              recipient.person.lastName
                            }`
                      }
                    />
                    <div className="w-100">
                      <h5 style={{ fontSize: "20px" }}>
                        <strong>
                          {recipient.person.firstName +
                            " " +
                            recipient.person.lastName}
                        </strong>
                      </h5>
                      <span style={{ fontSize: "12px", display: "block" }}>
                        {
                          /* {recipient.person.designation} */ kommandContactDetails.subTitle
                        }
                      </span>
                    </div>
                  </Flex>
                </Col>
              </Row>
            </>
          }
          width={"100%"}
          closable={true}
          onClose={onChildrenDrawerClose}
          closeIcon={<></>}
          open={childrenDrawer}
        >
          <div className="thread-wrapper">
            <Flex gap="small" className="pt-2 pr-5 pl-5">
              <Button size="large" icon={<PhoneOutlined />} type="primary">
                Call Supplier
              </Button>
              <Button
                size="large"
                icon={<WhatsAppOutlined />}
                style={{ width: 64 }}
              ></Button>
              <Button
                size="large"
                icon={<MailOutlined />}
                style={{ width: 64 }}
              ></Button>
              <Button
                size="large"
                icon={<CommentOutlined />}
                style={{ width: 64 }}
              ></Button>
            </Flex>
            <div id="scroll-chats" className="row scroll-chats mt-2">
              <ChatHistory
                dummyChatsIsLoading={dummyChatsIsLoading}
                chatHistory={kommandContactThread}
                userProfile={userProfile}
                getInitialsFromName={getInitialsFromName}
                onDisplayName={onDisplayNameHandler}
              />
            </div>

            <div
              className="row mt-3 messenger-text-wrapper cc"
              style={{ bottom: textWrapperBottom }}
            >
              <img
                src="/assets/images/shadow.png"
                alt=""
                className="messenger-text-shadow"
              />
              <div className="col">
                <ChatComposer
                  chatMessageText={chatMessageText}
                  setChatMessageText={setChatMessageText}
                  replyMessage={replyMessage}
                  replyMessageAttachment={replyMessageAttachment}
                  isSending={isSending}
                />
              </div>
            </div>
          </div>
        </Drawer>
      </Drawer>
    </>
  );
};

export { KommandListView };
