/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { useParams } from "react-router";
import "./seller-page.css";
export default () => {
  return (

    <div className="become-a-vendor">

      <div className="container text-center" style={{ marginTop: 20 }}>
        <div>
          <img
            src="/assets/images/bas/lap-phone.png"
            className="img-fluid"
            alt="..."
            style={{ borderRadius: 0, width: "100%", marginBottom: "0%" }}
          />
        </div>
      </div>
      <div className="intro-bar">
        <p className="header-text">Next Generation Sales</p>
        <p className="paragraph-text">
          Supply Zone is a powerful B2B trade platform for industrial and commercial products. We've dedicated ourselves to building low-cost, high-quality infrastructure that connects you to a diverse range of buyers and simplifies your sales process.
        </p>
        <p>
          <a className="register-now-btn" href="/become-a/seller/register" >Register Now</a>
        </p>
      </div>
      <div className="container" style={{ marginTop: 30 }}>
        <div className="row justify-content-start">
          <div className="col-4">
            <img
              src="/assets/images/bas/mobile.png"
              style={{ width: "100%", marginBottom: 100 }}
            />
          </div>
          <div className="col-8">
            <img
              src="/assets/images/bas/headers.svg"
              style={{ width: "100%", marginBottom: 100 }}
            />
          </div>
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: 30 }}>
        <div>
          <img
            src="/assets/images/bas/service.png"
            className="img-fluid"
            alt="..."
            style={{ borderRadius: 0, width: "100%", marginBottom: "0%" }}
          />
        </div>
      </div>
      <div className="intro-bar ">
        <p className="header-text">Service Overview</p>
        <p className="paragraph-text">
          {" "}
          As a Supply Zone member, you gain access to our network of professional buyers, increase your market exposure, build your brand, and gain the opportunity for recognition and awards within your industry.

        </p>
      </div>
      <div className="container" style={{ marginTop: 20 }}>
        <div className="row justify-content-start">
          <div className="col-6">
            <img
              src="/assets/images/bas/cap-part1.svg"
              style={{ width: "80%", marginBottom: 100 }}
            />
          </div>
          <div className="col-6">
            <img
              src="/assets/images/bas/cap-part2.svg"
              style={{ width: "80%", marginBottom: 100, marginTop: "15%" }}
            />
          </div>
        </div>
        <div className="container text-center" style={{ marginTop: 30 }}>
          <div>
            <img
              src="/assets/images/bas/sup-vetting.png"
              className="img-fluid"
              alt="..."
              style={{ borderRadius: 0, width: "100%", marginBottom: "0%" }}
            />
          </div>
        </div>
      </div>
      <div className="intro-bar ">
        <p className="header-text">Member Vetting &amp; Recognition</p>
        <p className="paragraph-text">
          {" "}
          Supply Zone awards credential badges based on noteworthy accomplishments. These credentials boost your brand in a powerful way, giving weight to critical intangibles such as reliability and service excellence. This increases the bargaining power of your sales reps, and sets you apart from competitors.
        </p>
        <p>
          <a className="register-now-btn" href="/become-a/seller/register" >Register Now</a>
        </p>
      </div>
      <div className="container text-center" style={{ marginTop: 20 }}>
        <div>
          <img
            src="/assets/images/bas/badges.svg"
            className="img-fluid"
            alt="..."
            style={{
              marginLeft: "0%",
              marginRight: "5%",
              width: "12%",
              marginBottom: 100
            }}
          />
          <img
            src="/assets/images/bas/badges-tags.svg"
            className="img-fluid"
            alt="..."
            style={{
              marginTop: 0,
              borderRadius: 0,
              width: "55%",
              marginBottom: 100
            }}
          />
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: 30 }}>
        <div>
          <img
            src="/assets/images/bas/horse.png"
            className="img-fluid"
            alt="..."
            style={{ borderRadius: 0, width: "100%", marginBottom: "0%" }}
          />
        </div>
      </div>
      <div className="intro-bar">
        <p className="header-text">Pricing &amp; Membership</p>
        <p className="paragraph-text">
          {" "}
          Our pricing plan is simple. We offer a Free Tier which provides you with introductory access to Supply Zone. In case you need more, we have a Paid Tier that gives you premium placement in search results and removes restrictions on RFQs you can participate in. And for those who need the most advanced features, we have an Advanced Tier that eliminates the manual quotation process, with AI-generated AutoQuote.
        </p>
        <p>
          <a className="register-now-btn" href="/become-a/seller/register" >Register Now</a>
        </p>
      </div>
      <div className="container text-center" style={{ marginTop: 20 }}>
        <div>
          <img
            src="/assets/images/bas/price-1.svg"
            className="img-fluid"
            alt="..."
            style={{ marginLeft: "0%", width: "80%", marginBottom: 15 }}
          />
          <img
            src="/assets/images/bas/price-2.svg"
            className="img-fluid"
            alt="..."
            style={{
              marginTop: 0,
              borderRadius: 0,
              width: "80%",
              marginBottom: 15
            }}
          />
          <img
            src="/assets/images/bas/price-3.svg"
            className="img-fluid"
            alt="..."
            style={{
              marginTop: 0,
              borderRadius: 0,
              width: "80%",
              marginBottom: 15
            }}
          />
          <img
            src="/assets/images/bas/price-4.svg"
            className="img-fluid"
            alt="..."
            style={{
              marginTop: 0,
              borderRadius: 0,
              width: "80%",
              marginBottom: 100
            }}
          />
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: 30 }}>
        <div>
          <img
            src="/assets/images/bas/procart.png"
            className="img-fluid"
            alt="..."
            style={{ borderRadius: 0, width: "100%", marginBottom: "0%" }}
          />
        </div>
      </div>
      <div className="intro-bar ">
        <p className="header-text">Product Category</p>
        <p className="paragraph-text">
          {" "}
          Product categories are a systematic way to organize and classify suppliers. We offer our members 12 product categories. We adjust categories and sub-categories from time to time, to improve search accuracy.

        </p>
      </div>
      <div className="container text-center" style={{ marginTop: 20 }}>
        <div>
          <img
            src="/assets/images/bas/pro-text.svg"
            className="img-fluid"
            alt="..."
            style={{ marginLeft: "0%", width: "80%", marginBottom: 100 }}
          />
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: 30 }}>
        <div>
          <img
            src="/assets/images/bas/implementation.png"
            className="img-fluid"
            alt="..."
            style={{ borderRadius: 0, width: "100%", marginBottom: "0%" }}
          />
        </div>
      </div>
      <div className="intro-bar ">
        <p className="header-text">Implementation Process</p>
        <p className="paragraph-text">
          {" "}
          The process to join Supply Zone is streamlined and user-friendly. It starts with a simple registration, followed by your company and product listing. Upon successful registration, you gain access to B2B inquiries, subject to limitations at the free tier level. The transition to the paid tier is seamless, offering greater market exposure and growth opportunities.


        </p>
        <p>
          <a className="register-now-btn" href="/become-a/seller/register" >Register Now</a>
        </p>
      </div>
      <div className="container text-center" style={{ marginTop: 20 }}>
        <div>
          <img
            src="/assets/images/bas/imple-text.svg"
            className="img-fluid"
            alt="..."
            style={{ marginLeft: "0%", width: "80%", marginBottom: 100 }}
          />
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: 30 }}>
        <div>
          <img
            src="/assets/images/bas/FAQS.png"
            className="img-fluid"
            alt="..."
            style={{ borderRadius: 0, width: "100%", marginBottom: "0%" }}
          />
        </div>
      </div>
      <div className="intro-bar">
        <p className="header-text">Frequently Asked Questions (FAQs)</p>
      </div>
      <div className="container text-center" style={{ marginTop: 20 }}>
        <div>
          <img
            src="/assets/images/bas/FAQ-TEXT.svg"
            className="img-fluid"
            alt="..."
            style={{ marginLeft: "0%", width: "80%", marginBottom: 100 }}
          />
        </div>
      </div>

    </div>
  );
};
