import React, {FC} from 'react' 
import UserManagementPage from './UserManagementPage';

const UserManagementPageWrapper: FC = () => {
  return (
    <>
      <UserManagementPage theme="auto" />
    </>
  )
}

export default UserManagementPageWrapper;
