import React, { FC, useRef, useState } from "react";
import UserModal from "./UserModal";
import axios from "axios";
import Cookies from "universal-cookie";
import { User } from "./user";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { Button, Drawer, DrawerProps, Space } from "antd";
import Snippets from "../../../../helpers/Snippets";

interface DocumentListItemProps {
  children?: React.ReactNode;
  theme?: string;
}

const UserManagementPage: FC<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  const { theme }: any = props;

  const [showUsersModal, setShowUsersModal] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [token, setToken] = React.useState("");
  const [user, setUser] = React.useState(new User());

  const toggleUsersModal = (newUser: User) => {
    setUser(newUser);
    setShowUsersModal(!showUsersModal);
    openDrawer();
    setOpen(!open);
  };
  const editUser = (editUser: any) => {
    const editingUser = new User();
    editingUser.id = editUser.id;
    editingUser.firstName = editUser.person.firstName;
    editingUser.lastName = editUser.person.lastName;
    editingUser.email = editUser.email;
    editingUser.userRole = editUser.role;
    editingUser.designation = editUser.person.designation;
    editingUser.phoneNumber = editUser.contactDetails.phoneNumber;
    setUser(editingUser);
    setShowUsersModal(true);
    setOpen(true);
  };

  const fetchUsers = (token: string) => {
    AxiosAPI.getProfileUsers()
      .then(({ data: users }) => {
        setUsersList(users);
      })
      .catch((error: any) => {});
  };


  const toggleIsActive = (editUser: any) => {
    const editingUser = new User();
    editingUser.id = editUser.id;
    editingUser.email = editUser.email;
    var config = {
      method: "patch",
      url: Snippets.urls.getAPIBaseURL() + "/profile/users/is-active",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: editingUser,
    };
    axios(config)
      .then(({ data: users }) => {
        fetchUsers(token);
      })
      .catch((error) => {
        alert("Email address is already registered.");
      });
  };

  React.useEffect(() => {
    const cookies = new Cookies();
    let token = cookies.get("szAccessToken");

    if (typeof token === "undefined") {
      token = localStorage.getItem("szAccessToken");
    }

    fetchUsers(token);
    setToken(token);
  }, []);

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState<DrawerProps['size']>('large');


  const openDrawer = () => {
    setOpen(true);
    setShowUsersModal(!showUsersModal)
  };

  const onClose = () => {
    setOpen(false);
    setShowUsersModal(!showUsersModal)
  };


  return (
    <>
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1">
              <h2 className="title ls-normal mb-0">User Management</h2>
              <a
                href="#"
                className="btn btn-primary pl-3 pr-3 font-size-normal font-weight-bold ls-25 mb-0 xaction-btn"
                onClick={() => {
                  toggleUsersModal(new User());
                }}
              >
                Add New User
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 w-100">
              <form className="form">
                {usersList.map((listItem: any, listItemIndex: number) => (
                  <div key={listItemIndex} className="card shadow-sm mb-5">
                    <div className="card-body d-flex align-items-center py-8">
                      <div className="row w-100">
                        <div className="col-md-1 text-center">
                          <img
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png";
                            }}
                            src={`https://api-live.supplyzone.ai/home/download-attachment/${listItem.avatarS3Key}`}
                            alt={listItem.person.firstName}
                            width="100%"
                            style={{
                              maxHeight: "70px",
                              height: "auto",
                              width: "auto",
                              maxWidth: "100%",
                            }}
                          />
                        </div>
                        <div className="col-md-3 r-list-view">
                          <span>
                            <strong>
                              {listItem.person.firstName +
                                " " +
                                listItem.person.lastName}
                            </strong>
                          </span>
                          <br />
                          <span>
                            <strong>Email:</strong> {listItem.email}
                          </span>
                          <br />
                        </div>
                        <div className="col-md-3 r-list-view">
                          <span>
                            <strong>Phone:</strong>{" "}
                            {listItem.contactDetails &&
                              listItem.contactDetails.phoneNumber}
                          </span>
                          <br />

                          <span>
                            <strong>Role:</strong>{" "}
                            {listItem.role ? listItem.role : "ADMIN"}
                          </span>
                          <br />
                        </div>
                        <div className="col-md-3 r-list-view">
                          <span>
                            <strong>Created On:</strong>{" "}
                            {listItem.createdAt.slice(0, 10)}
                          </span>
                          <br />
                          <span>
                            <strong>Is Active:</strong>{" "}
                            {listItem.enabled ? "Yes" : "No"}
                          </span>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="#"
                            target="_self"
                            className="sz-btn-primary solid mb-2"
                            style={{ marginBottom: "10px" }}
                            onClick={(e) => {
                              editUser(listItem);
                            }}
                          >
                            <span className="indicator-label">
                              Edit Details
                            </span>
                          </a>
                          <a
                            href="#"
                            target="_self"
                            onClick={(e) => {
                              toggleIsActive(listItem);
                              listItem.enabled = false;
                            }}
                            className="sz-btn-primary outline"
                          >
                            <span className="indicator-label">
                              {listItem.enabled ? "Disable" : "Enable"}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </form>
              <Drawer
                title={`Add User`}
                placement="right"
                size={size}
                onClose={onClose}
                open={open}
              >
                <UserModal
                  showUsersModal={showUsersModal}
                  closeUsersModal={toggleUsersModal}
                  fetchUsers={fetchUsers}
                  user={user}
                  setUser={setUser}
                ></UserModal>
              </Drawer>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserManagementPage;
