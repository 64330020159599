import React, { FunctionComponent, useEffect, useState } from "react";
import { Profile } from "../account/profile-wizard/accounts/model/Profile";
import { Col, Row } from "antd";
import ProductDetailsInformationTabsCompanyProfile from "../shop/Products/ProductDetailsInformationTabsCompanyProfile";

interface DocumentListItemProps {
    children?: React.ReactNode;
    profile: Profile;
}
const CompanyProfile: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        profile,
    }: any = props;

    return (
        <>

            <div className="card xcards mb-5">
                <div className="collapse show">
                    <div className="card-body border-top p-9">
                        <Row>
                            <Col span={24}>
                                <ProductDetailsInformationTabsCompanyProfile
                                    name={profile.name}
                                    address={`${profile.primaryContact.addressLine1}${profile.primaryContact.addressLine2 !== null ? ', ' + profile.primaryContact.addressLine2 : ''}`}
                                    contact={`${profile.primaryContactPerson.firstName} ${profile.primaryContactPerson.lastName}`}
                                    phone={profile.primaryContact.phoneNumber}
                                    email={profile.primaryContact.emailAddress}
                                    banners={[1, 2, 3, 4, 5]}
                                    sliders={[]}
                                    profile={profile}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export { CompanyProfile };