import React, { useEffect, useState } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Dropdown,
  Flex,
  MenuProps,
  message,
  Popconfirm,
  PopconfirmProps,
  Progress,
  Row,
  Skeleton,
  Space,
  Tag,
} from "antd";
const RFQBuyerRating: any = ({
  rating,
}: any) => {

  const [ratings, setRatings] = useState(
    {
      average: 4.2,
      global: 1324,
      data: [
        {
          star: 5,
          percentage: 63,
        },
        {
          star: 4,
          percentage: 17,
        },
        {
          star: 3,
          percentage: 7,
        },
        {
          star: 2,
          percentage: 4,
        },
        {
          star: 1,
          percentage: 8,
        },
      ]
    });

  return (
    <>
      <Flex className="mt-3 mb-3" vertical>
        <span className="rfq-mk-iconic-txt rating-stars-wrapper">
          <span className="stars">★★★★☆</span>{" "}
          <span className="stars-text">4.2 out of 5</span>{" "}
          <h5 className="mt-3 mb-3">{ratings.global} global ratings</h5>
        </span>
        <Space className="mt-3 mb-3" direction="vertical" size={5}>
          {ratings.data.map((rate: any, rateIndex: number) => (
            <Flex
              key={rateIndex}
              vertical={false}
              align="center"
              justify="space-between"
            >
              <div className="" style={{ width: 72 }}>
                {rate.star} star
              </div>
              <Progress
                percent={rate.percentage}
                size={[300, 20]}
                trailColor="#cccccc"
              />
            </Flex>
          ))}
        </Space>
      </Flex>
    </>
  );
};
export { RFQBuyerRating };
