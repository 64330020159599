import { Col, Radio, RadioChangeEvent, Row, Space, Tag } from "antd";
import React, { FunctionComponent, useState } from "react";
import { Profile } from "./model/Profile";
import { EditOutlined } from "@ant-design/icons";

// @ts-ignore
import { DefaultEditor } from "react-simple-wysiwyg/lib/index.cjs";
import AxiosAPI from "../../../../../helpers/AxiosAPI";

interface DocumentListItemProps {
    children?: React.ReactNode;
    profile: Profile;
}
const BusinessDetails: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        profile,
    }: any = props;

    const [dataChanged, setDataChanged] = useState(false)
    const [initialDataLoad, setInitialDataLoad] = useState(false)
    const [editing, setEditing] = useState(true)

    React.useEffect(() => {
        if (!initialDataLoad && (profile.name)) {
            setInitialDataLoad(true)
        }
    }, [profile])

    const saveChanges = () => {
        AxiosAPI.saveProfileData(profile)
            .then(() => {
                //setEditing(false)
                setDataChanged(false)
            })
    }

    const industrySectors: string[] = [
        "Manufacturing", "Retail", "Healthcare", "Education", "Technology", "Hospitality and Tourism", "Media and Entertainment", "Energy", "Services", "Agriculture", "Automotive", "Construction", "Consumer Goods", "Financial Services", "Food and Beverage", "Pharmaceuticals", "Aerospace and Defense", "Chemicals", "Mining", "Oil and Gas", "Telecommunications", "Government Agencies", "Other"
    ]

    const cities: string[] = [
        "Beitbridge", "Bindura", "Binga",
        "Bulawayo", "Chegutu", "Chinhoyi", "Chiredzi",
        "Chitungwiza", "Chipinge", "Chimanimani", "Epworth",
        "Esigodini", "Gokwe", "Gweru", "Gwanda", "Harare",
        "Hopley", "Hwange", "Inyathi", "Kadoma", "Kariba", "Karoi",
        "Kwekwe", "Lupane", "Macheke", "Marondera", "Masvingo",
        "Mberengwa", "Mount Darwin", "Mvuma", "Mutare", "Mutoko", "Nyanga",
        "Norton", "Plumtree", "Redcliff", "Rusape", "Ruwa",
        "Shurugwi", "Tsholotsho", "Triangle", "Victoria Falls", "Zimunya",
        "Zvishavane"
    ];

    const years = Array.from({ length: 101 }, (_, i) => 2024 - i);

    const onBuyerBusinessTypeChange = (e: RadioChangeEvent) => {
        setDataChanged(true)
        profile.businessType = e.target.value;
    };

    const [employeesCountList, setEmployeesCountList] = React.useState([]);
    const [salesCountList, setSalesCountList] = React.useState([
        "$0 - $499,999", "$500,000 - $999,999", "$1MM - $9.99MM", "$10MM - $19.99MM", "$20MM - $29.99MM", "$30MM - $39.99MM", "$40MM - $49.99MM", "$50MM - $59.99MM", "$60MM - $69.99MM", "$70MM - $79.99MM", "$80MM - $89.99MM", "$90MM - $99.99MM", "$100MM - $199MM", "$200MM - $299MM", "$300MM - $399MM", "$400MM - $499MM", "$500MM - $599MM", "$600MM - $699MM", "$700MM - $799MM", "$800MM - $899MM", "$900MM - $999MM", "$1B+"
    ]);
    React.useEffect(() => {
        const tmpCountList: any[] = [];
        for (let x = 50; x <= 500; x += 50) {
            tmpCountList.push(x - 49 + " - " + x);
        }
        for (let x1 = 1000; x1 <= 5000; x1 += 500) {
            tmpCountList.push(x1 - 499 + " - " + x1);
        }
        setEmployeesCountList(tmpCountList);

        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });
        
        /*const tmpSalesList: any[] = [];
        for (let x = 99_999; x <= 1_000_000; x += 1_00_000) {
            tmpSalesList.push(
                formatter.format(x - 99_999) + " - " + formatter.format(x)
            );
        }
        setSalesCountList(tmpSalesList);*/
    }, [])


    return (
        <>
            {profile.name && (
                <div className="card mb-5">
                    <div className="card-header border-0 cursor-pointer">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">Company Details</h3>
                        </div>
                        {!editing && (
                            <EditOutlined onClick={(e) => {
                                setEditing(!editing);
                            }} />
                        )}
                    </div>
                    <div className="collapse show">
                        <div className="card-body border-top p-9">
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Company Name</strong>
                                    <br />
                                    {editing ? (
                                        <input type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            defaultValue={profile.name}
                                            onChange={(e: any) => {
                                                profile.name = e.target.value
                                                setDataChanged(true)
                                            }}
                                        ></input>
                                    ) : (
                                        <span>{profile.name}</span>
                                    )}

                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Email Address</strong>
                                    <br />
                                    {editing ? (
                                        <input type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            defaultValue={profile.primaryContact.emailAddress}
                                            onChange={(e: any) => {
                                                profile.primaryContact.emailAddress = e.target.value
                                                setDataChanged(true)
                                            }}
                                        ></input>
                                    ) : (
                                        <span>{profile.primaryContact.emailAddress}</span>
                                    )}
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Phone Number</strong>
                                    <br />
                                    {editing ? (
                                        <input type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            defaultValue={profile.primaryContact.phoneNumber}
                                            onChange={(e: any) => {
                                                profile.primaryContact.phoneNumber = e.target.value
                                                setDataChanged(true)
                                            }}
                                        ></input>
                                    ) : (
                                        <span>{profile.primaryContact.phoneNumber}</span>
                                    )}
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Profile Type</strong>
                                    <br />

                                    <span className="card-text-value"><Tag className="mt-1 solid-tags">{profile.profileType}</Tag></span>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">

                                    <strong>Registration Number</strong>
                                    <br />
                                    {editing ? (
                                        <input type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            defaultValue={profile.companyRegistrationNumber}
                                            onChange={(e: any) => {
                                                profile.companyRegistrationNumber = e.target.value
                                                setDataChanged(true)
                                            }}
                                        ></input>
                                    ) : (
                                        <span>{profile.companyRegistrationNumber}</span>
                                    )}
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Physical Address</strong>
                                    <br />
                                    {editing ? (
                                        <input type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            defaultValue={profile.primaryContact.addressLine1}
                                            onChange={(e: any) => {
                                                profile.primaryContact.addressLine1 = e.target.value
                                                setDataChanged(true)
                                            }}
                                        ></input>
                                    ) : (
                                        <span>{profile.primaryContact.addressLine1}</span>
                                    )}
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Year Established</strong>
                                    <br />
                                    {editing ? (
                                        <select
                                            className={`form-control form-control-lg form-control-solid `}
                                            placeholder="Year Established"
                                            value={profile.yearEstablished}
                                            onChange={(e: any) => {
                                                profile.yearEstablished = e.target.value
                                            }}
                                        >
                                            <option value="">
                                                Select Year Established...
                                            </option>
                                            {years.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{profile.yearEstablished}</span>
                                    )}
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Total Employees</strong>
                                    <br />
                                    {editing ? (


                                        <select
                                            className="form-control form-control-lg form-control-solid w-100 is-select"
                                            defaultValue={profile.totalEmployees}
                                            onChange={(e: any) => {
                                                profile.totalEmployees = e.target.value
                                                setDataChanged(true)
                                            }}
                                        >
                                            <option value="">Select Total Employees...</option>
                                            {employeesCountList.map((opt: string) => (
                                                <option value={opt}>{opt}</option>
                                            ))}
                                            <option value="5000+">5000+</option>
                                        </select>

                                    ) : (
                                        <span>{profile.totalEmployees}</span>
                                    )}
                                </Col>



                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Total Annual Sales</strong>
                                    <br />
                                    {editing ? (

                                        <select
                                            className="form-control form-control-lg form-control-solid w-100 is-select"
                                            defaultValue={profile.totalAnnualSales}
                                            onChange={(e: any) => {
                                                profile.totalAnnualSales = e.target.value
                                                setDataChanged(true)
                                            }}
                                        >
                                            <option value="">Select Total Annual Sales...</option>
                                            {salesCountList.map((opt: string) => (
                                                <option value={opt}>{opt}</option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{profile.totalAnnualSales}</span>
                                    )}
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Trading Capacity</strong>
                                    <br />
                                    {editing ? (
                                       
                                        <select
                                            className="form-control form-control-lg form-control-solid w-100 is-select"
                                            defaultValue={profile.tradingCapacity}
                                            onChange={(e: any) => {
                                                profile.tradingCapacity = e.target.value
                                                setDataChanged(true)
                                            }}
                                        >
                                            <option value="">Trading Capacity...</option>
                                            {salesCountList.map((opt: string) => (
                                                <option value={opt}>{opt}</option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{profile.tradingCapacity}</span>
                                    )}

                                </Col>



                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>Industry Sector</strong>
                                    <br />
                                    {editing ? (
                                        <select
                                            className={`form-control form-control-lg form-control-solid `}
                                            placeholder="Industry Sector"
                                            value={profile.industry}
                                            onChange={(e: any) => {
                                                profile.industry = e.target.value
                                            }}
                                        >
                                            <option value="">
                                                Select Industry...
                                            </option>
                                            {industrySectors.map((industrySector) => (
                                                <option key={industrySector} value={industrySector}>
                                                    {industrySector}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{profile.industry}</span>
                                    )}
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="pe-2 mb-5">
                                    <strong>City/Town</strong>
                                    <br />
                                    {editing ? (
                                        <select
                                            className={`form-control form-control-lg form-control-solid `}
                                            placeholder="City/Town"
                                            value={profile.city}
                                            onChange={(e: any) => {
                                                profile.city = e.target.value
                                            }}
                                        >
                                            <option value="">
                                                Select City/Town...
                                            </option>
                                            {cities.map((city) => (
                                                <option key={city} value={city}>
                                                    {city}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{profile.city}</span>
                                    )}
                                </Col>

                                <Col span={24} className="pe-2">
                                    <strong>Business Type</strong>
                                    <br />
                                    {editing ? (
                                        <>
                                            {profile.profileType === "SELLER" ? (
                                                <div className="row">
                                                    {[
                                                        "AGENT",
                                                        "BUYING_OFFICE",
                                                        "CONSULTANT",
                                                        "DISTRIBUTOR",
                                                        "EXPORTER",
                                                        "IMPORTER",
                                                        "MANUFACTURER",
                                                        "ONLINE_SELLER",
                                                        "RETAILER",
                                                        "WHOLESALER",
                                                        "TRADING_COMPANY",
                                                    ].map((item: any, itemIndex: number) => (
                                                        <div key={itemIndex} className="col-md-2 mb-2">
                                                            <label className="form-check form-check-inline form-check-solid">
                                                                <input
                                                                    className="form-check-input"
                                                                    name="communication[]"
                                                                    value={item}
                                                                    type="checkbox"
                                                                    defaultChecked={profile.businessType !== null && profile.businessType.split(",").includes(item)}
                                                                    onClick={() => {
                                                                        setDataChanged(true)
                                                                        if (profile.businessType !== null) {

                                                                            let businessTypeArray: any = profile.businessType.split(",");

                                                                            if (Array.isArray(businessTypeArray) && businessTypeArray.length > 0) {

                                                                                if (!businessTypeArray.includes(item)) {

                                                                                    businessTypeArray.push(item);

                                                                                    setTimeout(() => {

                                                                                        profile.businessType = businessTypeArray.join(",");
                                                                                    }, 100);

                                                                                } else {

                                                                                    let newArray: Array<any> = businessTypeArray.filter(element => element !== item);

                                                                                    profile.businessType = newArray.join(",");

                                                                                }
                                                                            } else {
                                                                                profile.businessType = item;
                                                                            }

                                                                        } else {
                                                                            profile.businessType = item;
                                                                        }

                                                                    }}
                                                                />
                                                                <span className="fw-bold ps-2 fs-6">
                                                                    {item.split("_").join(" ")}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (

                                                <Radio.Group onChange={onBuyerBusinessTypeChange} defaultValue={profile.businessType} className="mb-6">
                                                    <Space direction="vertical">
                                                        <Radio value={"LARGE_ENTERPRISE"}>LARGE ENTERPRISE</Radio>
                                                        <Radio value={"MEDIUM_ENTERPRISE"}>MEDIUM ENTERPRISE</Radio>
                                                        <Radio value={"SMALL_ENTERPRISE"}>SMALL ENTERPRISE</Radio>
                                                        <Radio value={"INDIVIDUAL"}>INDIVIDUAL</Radio>
                                                    </Space>
                                                </Radio.Group>


                                            )}
                                        </>
                                    ) : (
                                        <span className="card-text-value">{
                                            profile.businessType.split(",").map((tag: any, tagIndex: number) => (
                                                <>
                                                    {tag.length > 3 && (
                                                        <Tag key={tagIndex} className="mt-1" style={{ textTransform: "capitalize" }}>{tag.replace('_', ' ').toLowerCase()}</Tag>
                                                    )}
                                                </>

                                            ))}
                                        </span>
                                    )}
                                </Col>




                                <Col span={22}>
                                    <strong>Business Summary:</strong>
                                    <br />
                                    {editing ? (
                                        <DefaultEditor
                                            value={profile.businessSummary}
                                            onChange={(e: any) => {
                                                profile.businessSummary = e.target.value;
                                            }}
                                        />
                                    ) : (
                                        <span className="card-text-value">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: profile.businessSummary }}
                                            />
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {dataChanged && (
                        <div className="card-footer d-flex justify-content-end py-6 ">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => saveChanges()}

                            >
                                Save Changes
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export { BusinessDetails };