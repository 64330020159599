import React, { FC } from "react";
import ProfileWizardPage from "./ProfileWizardPage";
import { PersonalSettings } from "./accounts/PersonalSettings";

const ProfileSettingsPageWrapper: FC = () => {
  return (
    <>
      <div className="profile-wizard mr-3 ml-3 mt-5 ">
        <PersonalSettings />
      </div>
    </>
  );
};

export default ProfileSettingsPageWrapper;
