import React, { useEffect, useState } from "react";

const AnalyticsInvites: React.FC = () => {

  useEffect(() => {
    (window as any).Highcharts.chart("c2", {
      chart: {
        type: "line",
      },
      title: {
        text: "Supplier Invitations",
      },
      subtitle: {
        text: "Buyer: Tatenda Sibanda",
      },
      xAxis: {
        categories: [
          "Jan 24",
          "Feb 24",
          "Mar 24",
          "Apr 24",
          "May 24",
          "Jun 24",
          "Jul 24",
          "Aug 24",
          "Sep 24",
          "Oct 24",
          "Nov 24",
          "Dec 24",
        ],
        accessibility: {
          description: "Months of the year",
        },
      },
      yAxis: {
        title: {
          text: "Volume",
        },
        labels: {
          format: "{value}",
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      plotOptions: {
        line: {
          marker: {
            radius: 1,
            lineColor: "#666666",
            lineWidth: 1,
          },
        },
      },
      series: [
        {
          name: "Invitations Accepted",
          marker: {
            symbol: "circle",
          },
          color: "#ff9800",
          data: [42, 36, 56, 44, 42, 40, 50, 39, 41, 29, 40, 30],
        },
        {
          name: "Invitations Sent",
          marker: {
            symbol: "circle",
          },
          color: "#2196f3",
          data: [50, 48, 60, 64, 48, 42, 56, 50, 47, 56, 48, 36],
        },
      ],
    });
  }, []);

  return (
    <>
      <div className="card mb-5">
        <div className="collapse show">
          <div id="kt_account_signin_method" className="collapse show">
            <div className="card-body border-top p-9">
              <div className="c2" id="c2">
                Loading...
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AnalyticsInvites };
