/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import MessengerAdvancedInquiryCol1 from "./MessengerAdvancedInquiryCol1";
import MessengerAdvancedInquiryCol2 from "./MessengerAdvancedInquiryCol2";
import MessengerAdvancedInquiryCol3 from "./MessengerAdvancedInquiryCol3";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { format } from "date-fns";
import Snippets from "../../../../helpers/Snippets";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import { UserPilotEvents, UserPilotHelper } from "../../../../helpers/UserPilotHelper";
import { useAppDataContext } from "../../../../data";
import { Link } from "react-router-dom";
import { Badge, Empty } from "antd";
const MessengerAdvancedInquiryListViewPage: any = () => {

  const {
    currentlySelectedFolder,
    userDocumentsInquiries,
    userDocumentsInquiriesActive,
    userDocumentsInquiriesCompleted,
    userDocumentsInquiriesArchived,
    profileCounters,
  } = useAppDataContext();
 
  useEffect(()=>{

    switch(currentlySelectedFolder){
      case "ALL" : {
        setListItems(userDocumentsInquiries);
        break;
      }
      case "ACTIVE" : {
        setListItems(userDocumentsInquiriesActive);
        break;
      }
      case "COMPLETED" : {
        setListItems(userDocumentsInquiriesCompleted);
        break;
      }
      case "ARCHIVED" : {
        setListItems(userDocumentsInquiriesArchived);
        break;
      }
      default: {
        setListItems(userDocumentsInquiries);
        break;
      }
    }

  },[
    currentlySelectedFolder
  ])

  //fire base config
  const firebaseConfig = {
    apiKey: "AIzaSyCQOwBBZaFOVLS1cBJS-XSWAuKwNna1PKE",
    authDomain: "supply-zone.firebaseapp.com",
    databaseURL: "https://supply-zone.firebaseio.com",
    projectId: "supply-zone",
    storageBucket: "supply-zone.appspot.com",
    messagingSenderId: "751514788423",
    appId: "1:751514788423:web:670d6b392d713e7a5bc762"
  };
  initializeApp(firebaseConfig)
  // Initialize Firebase
  const [firebaseToken, setFirebaseToken] = useState("")
  const messaging = getMessaging();

  function requestPermission() {
    //console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        //console.log('Notification permission granted.');

      }
    })
  }

  getToken(messaging, { vapidKey: 'BIFDaox5IIg_JGcPY2K1SpSieMMVrSya3JKaLTK5QLclWQS73paBdTpmyKyjw12ih-VVT3ADlW7S59T3w3e2N4o' })
    .then((currentToken: any) => {
      if (currentToken) {
        //console.log(currentToken)
        setFirebaseToken(currentToken);
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        //console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err: any) => {
      //console.log('An error occurred while retrieving token. ', err);
      requestPermission()
      // ...
    })

  useEffect(() => {

    onMessage(messaging, (payload) => {
      //console.log('Message received. ', payload);
      if (!iJustReplied) {

        var config = {
          method: "get",
          url: Snippets.urls.getAPIBaseURL() + "/messages/" + threadId,
          headers: {
            Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        };
        axios(config)
          .then((res: any) => {
            setChatHistory(res.data.thread)
          })
      }
      setIJustReplied(false)

    })
    // ...
  })

  //end firebase things
  const [isSending, setIsSending] = useState(false)
  const [iJustReplied, setIJustReplied] = useState(false)


  const getTime: Function = (timeString: string): string => {
    if (!timeString) return timeString;
    return format(new Date(timeString), "yyyy-MMM-dd");
  };

  function getInitialsFromName(name: string): string {
    if (typeof name === "undefined") {
      return name;
    }
    name = name.replace("(", "").replace(")", "").replace(".", "");
    const names = name.split(" ");
    if (names.length === 0) {
      return name;
    }
    const initials =
      names[0].substring(0, 1).toUpperCase() +
      names[1].substring(0, 1).toUpperCase();
    return initials;
  }

  const [isListView, setisListView] = useState(true);

  const [dummyUsersSearch, setDummyUsersSearch] = useState("");

  const [rfqsSearch, setRfqsSearch] = useState("");

  const [dummyRFQsIsLoading, setRFQsUsersIsLoading] = useState(false);

  const [dummyUsersIsLoading, setDummyUsersIsLoading] = useState(false);

  const [dummyChatsIsLoading, setDummyChatsIsLoading] = useState(false);

  const [dummyUsers, setDummyUsers] = useState([]);

  const [listItems, setListItems] = useState([]);

  const [chatHistory, setChatHistory] = useState([]);

  const [universalResponseId, setUniversalResponseId] = useState(0);

  const [searchValueRFQ, setSearchValueRFQ] = useState("");

  const [searchValueUser, setSearchValueUser] = useState("");

  const [selectedUserIndex, setSelectedUserIndex] = useState(-1);

  const [selectedUser, setSelectedUser] = useState({
    hasOwnProperty: () => { },
    messageThread: {
      senderName: "",
      supplierCompanyName: "",
    },
  })

  const [listViewLabel, setListViewLabel] = useState("INQ");

  const [buyerProfile, setSellerProfile] = useState({ id: 0 })

  const [showTools, setShowTools] = useState(true);

  const [selectedRFQIndex, setSelectedRFQIndex] = useState(-1);

  const [viewState, setViewState] = useState("");

  const [userToken, setUserToken] = useState<any>("");

  const [userType, setUserType] = useState<any>("BUYER");

  const [openTab, setOpenTab] = useState(1);

  const [szSelectedItemID, setSzSelectedItemID] = useState(0);

  const [szSelectedItemIndex, setSzSelectedItemIndex] = useState(0);

  const selectURLItemIndex: Function = (listData: any) => {
    if (listData.length === 0) return;

    let itemId: number = AxiosAPI.getQueryVariable(window, "id");

    if (!itemId) {
      itemId = AxiosAPI.getQueryVariable(window, "document-id");
    }

    if (!itemId) {
      itemId = AxiosAPI.getQueryVariable(window, "rfq-id");
    }

    if (!itemId) {
      itemId = AxiosAPI.getQueryVariable(window, "inq-id");
    }

    if (!itemId) {
      itemId = listData[0].id;
    }

    const _item: any = findObjectById(listData, itemId);

    //console.log("SELECTED-INDEX-RFQ-0", _item);

    if (!_item) {
      return (window.location.href = Snippets.urls.getMessengerURL(
        user.profile.profileType,
        listViewLabel,
        0
      ));
    }

    //

    setSelectedRFQHandler(_item.object, _item.index);
  };

  const findObjectById: any = (arr: any, id: number) => {
    //console.log(typeof arr, typeof id, id, arr);
    const index = findIndexByAttribute(arr, "id", id);
    if (index === -1) {
      return null;
    }
    return { object: arr[index], id: parseInt(`${id}`), index: index };
  };

  const findIndexByAttribute: any = (
    arr: [],
    attribute: any,
    value: number
  ): number => {
    return arr.findIndex(
      (obj) => parseInt(`${obj[attribute]}`) === parseInt(`${value}`)
    );
  };

  const getListData: Function = (_userType: any, _viewType: any) => {
    //console.log("_______GET RFQS", _userType, _viewType);

    if (_viewType.toUpperCase() === "RFQ") {
      if (_userType.toUpperCase() === "BUYER") {
        AxiosAPI.apiRequest('get','/buyer/rfqs?notInFolder=1',{})
          .then((res: any) => {
            let listData = res.data;

            if (typeof listData === "object") {
              listData = listData.reverse();
            }

            setListItems(listData);

            setTimeout(() => {
              selectURLItemIndex(listData);
            }, 1000);
          })
          .catch((error: any) => {
            //console.log("::: LIST DATA FETCH ERROR :::", error);
          })
      } else {
        AxiosAPI.getSellerRFQs()
          .then((res: any) => {
            let listData = res.data;

            if (typeof listData === "object") {
              listData = listData.reverse();
            }

            setListItems(listData);

            setTimeout(() => {
              selectURLItemIndex(listData);
            }, 1000);
          })
          .catch((error: any) => {
            //console.log("::: LIST DATA FETCH ERROR :::", error);
          })
      }
    } else {
      if (_userType.toUpperCase() === "BUYER") {
        AxiosAPI.getUserInquiries()
          .then((res: any) => {
            let listData = res.data;

            if (typeof listData === "object") {
              listData = listData.reverse();
            }

            setListItems(listData);

            setTimeout(() => {
              selectURLItemIndex(listData);
            }, 1000);
          })
          .catch((error: any) => {
            //console.log("::: LIST DATA FETCH ERROR :::", error);
          })
      } else {
        AxiosAPI.getSellerInquiries()
          .then((res: any) => {
            let listData = res.data;

            if (typeof listData === "object") {
              listData = listData.reverse();
            }

            setListItems(listData);

            setTimeout(() => {
              selectURLItemIndex(listData);
            }, 1000);
          })
          .catch((error: any) => {
            //console.log("::: LIST DATA FETCH ERROR :::", error);
          })
      }
    }
  };

  const [threadId, setThreadId] = useState(0);

  const [chatMessageText, setChatMessageText] = useState("");

  const [inquiryIdForMessage, setINQIdForMessage] = useState(0);

  const [rfqIdForMessage, setRFQIdForMessage] = useState(0);
  const [inquiryRespondingCompany, setInquiryRespondingCompany] = useState({
    responseCompanyContactName: "",
    responseCompanyContactNumber: "",
    responseCompanyName: "",
  })

  const [sellerHasRespondedThis, setSellerHasRespondedThis] = useState(false);

  const replyMessage: Function = () => {
    setIJustReplied(true)
    setIsSending(true)
    if (userType === "SELLER") {
      if (listViewLabel === "RFQ") {
        if (!sellerHasRespondedThis) {
          AxiosAPI.replyRFQ(rfqIdForMessage, chatMessageText)
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread)
              setIsSending(false)
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            })
        } else {
          AxiosAPI.replyMessage(threadId, chatMessageText)
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread)
              setIsSending(false)
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            })
        }
      } else {
        UserPilotHelper.logEvent(UserPilotEvents.RESPOND_TO_INQUIRY, {
          id: threadId
        })
        if (!sellerHasRespondedThis) {
          AxiosAPI.replyInquiry(
            inquiryIdForMessage,
            chatMessageText,
            selectedRFQ
          )
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread)
              setIsSending(false)
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            })
        } else {
          AxiosAPI.replyMessage(threadId, chatMessageText)
            .then((res: any) => {
              //setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
              setChatMessageText("");
              setSellerHasRespondedThis(true);
              setChatHistory(res.data.thread)
              setIsSending(false)
            })
            .catch((error: any) => {
              //console.log("::: LIST DATA FETCH ERROR :::", error);
            })
        }
      }
    } else {
      AxiosAPI.replyMessage(threadId, chatMessageText)
        .then((res: any) => {
          //setSelectedUser(selectedUser);
          //setSelectedUserIndex(selectedUserIndex);
          setChatMessageText("");
          //console.log("SELECTED_USER", selectedUser);
          //setSelectedUserHandler(selectedUser);
          setChatHistory(res.data.thread)
          setIsSending(false)
        })
        .catch((error: any) => {
          //console.log("::: LIST DATA FETCH ERROR :::", error);
        })
    }
  };

  const replyMessageAttachment: Function = (file: any, fileData: any) => {
    let attachmentText: string = chatMessageText;

    if (attachmentText === "") {
      attachmentText = " ";
    }

    AxiosAPI.replyMessageAttachment(threadId, attachmentText, file, fileData)
      .then((res: any) => {
        //console.log("::: REPLY ATTc MESSAGE SUCCESS :::", res.data);
        if (userType === "SELLER") {
          setSelectedRFQHandler(selectedRFQ, selectedRFQIndex);
          setChatMessageText("");
        } else {
          setSelectedUserHandler(selectedUser);
          setChatMessageText("");
        }
      })
      .catch((error: any) => {
        //console.log("::: REPLY ATTc MESSAGE ERROR :::", error);
      })
  };

  const getBuyerSellerRFQResponses: Function = (listItem: any) => {
    setDummyUsersIsLoading(true);

    setDummyUsers([]);

    //console.log("::: BUYER SUPPLIER RFQ REQ :::", listItem);

    AxiosAPI.getBuyerSellerRFQResponses(listItem.id)
      .then((res: any) => {
        //console.log("::: BUYER SUPPLIER RFQ RES :::", listItem);

        setDummyUsersIsLoading(false);

        let listData = res.data;

        if (typeof listData === "object") {
          listData = listData.reverse();
        }

        setDummyUsers(listData);

        if (listData.length === 0) return;

        setSelectedUser(listData[0]);
        setSelectedUserIndex(0);
      })
      .catch((error: any) => {
        //console.log("::: LIST DATA FETCH ERROR :::", error);
      })
  };

  const getBuyerSellerInquiryResponses: Function = (listItem: any) => {
    setDummyUsersIsLoading(true);

    setDummyUsers([]);

    //console.log("::: BUYER SUPPLIER INQ REQ :::", listItem);

    AxiosAPI.getUserInquiryResponses(listItem.id)
      .then((res: any) => {
        //console.log("::: BUYER SUPPLIER INQ RES :::", listItem);

        setDummyUsersIsLoading(false);

        let listData = res.data;

        if (typeof listData === "object") {
          listData = listData.reverse();
        }

        const attachments: any[] = []
        listData.map((line: any) => {
          line.messageThread.thread.map((chat: any): void => {

            if (chat.s3Key && chat.s3Key.length > 4) {

              attachments.push({
                name: chat.attachmentName,
                type: chat.attachmentType,
                s3Key: chat.s3Key,
                modified: chat.date.split("T")[0],
                senderCompany: chat.senderCompany,
              })



            }
          })
        })

        setAttachmentList(attachments);

        setInquiryRespondingCompany(res.data[0]);
        //console.log(res.data);

        setDummyUsers(listData);

        if (listData.length === 0) return;

        setSelectedUser(listData[0]); setSelectedUserIndex(0);
      })
      .catch((error: any) => {
        //console.log("::: LIST DATA FETCH ERROR :::", error);
      })
  };

  const updateFCMTokenAndThread = () => {
    if (!firebaseToken || firebaseToken == "") return;
    if (threadId == 0) return;


    var config = {
      method: "post",
      url: Snippets.urls.getAPIBaseURL() + "/messages/token",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        threadId: threadId,
        token: firebaseToken
      }
    };
    axios(config).then((res: any) => { 
      //console.log(res) 
    })
  }
  useEffect(() => {
    updateFCMTokenAndThread()
  }, [threadId]);
  
  const getChatHistory: Function = (
    responseId: any,
    inquiryResponseId: any
  ) => {
    //console.log("::: getChatHistory :::", responseId);

    setDummyChatsIsLoading(true);

    setUniversalResponseId(responseId);

    function noChatHistory() {
      setDummyChatsIsLoading(false);

      setChatHistory([]);
    }
    const attachments: any = [];
    if (userType.toUpperCase() === "BUYER") {
      if (listViewLabel === "RFQ") {
        AxiosAPI.getBuyerRFQResponsesChats(responseId)
          .then((res: any) => {
            setDummyChatsIsLoading(false);

            let listData = res.data;
            //console.log("Found: ", listData)
            listData.messageThread.thread.map((chat: any): void => {

              if (chat.s3Key && chat.s3Key.length > 4) {

                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                })

                //console.log("Found: ", chat.attachmentName)

              }

              return null;

            })
            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getBuyerRFQResponsesChats :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            //console.warn("::: CHAT ERROR getBuyerRFQResponsesChats :::", error);
            noChatHistory();
            setSellerHasRespondedThis(false);
          })
      } else {
        if (inquiryResponseId === undefined)
          inquiryResponseId = inquiryIdForMessage;

        AxiosAPI.getBuyerINQResponsesChats(inquiryResponseId, responseId)
          .then((res: any) => {
            setDummyChatsIsLoading(false);

            let listData = res.data;
            listData.messageThread.thread.map((chat: any): void => {

              if (chat.s3Key && chat.s3Key.length > 4) {

                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                })

              }

              return null;

            })
            //console.log("!!!!!::::", listData);

            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getBuyerINQResponsesChats :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            //console.warn("::: CHAT ERROR getBuyerINQResponsesChats :::", error);
            noChatHistory();
            setSellerHasRespondedThis(false);
          })
      }
    } else {
      if (listViewLabel === "RFQ") {
        AxiosAPI.getSellerRFQResponsesChats(responseId)
          .then((res: any) => {
            setSellerHasRespondedThis(true);
            setDummyChatsIsLoading(false);

            let listData = res.data;
            listData.messageThread.thread.map((chat: any): void => {

              if (chat.s3Key && chat.s3Key.length > 4) {

                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                })

              }

              return null;

            })
            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getSellerRFQResponsesChats :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            /*
            console.warn(
              "::: CHAT ERROR getSellerRFQResponsesChats :::",
              error
            );
            */
            noChatHistory();
            setSellerHasRespondedThis(false);
            setRFQIdForMessage(responseId);
          })
      } else {
        AxiosAPI.getSellerINQResponsesChats(responseId)
          .then((res: any) => {
            setSellerHasRespondedThis(true);
            setDummyChatsIsLoading(false);

            let listData = res.data;
            listData.messageThread.thread.map((chat: any): void => {

              if (chat.s3Key && chat.s3Key.length > 4) {

                attachments.push({
                  name: chat.attachmentName,
                  type: chat.attachmentType,
                  s3Key: chat.s3Key,
                  modified: chat.date.split("T")[0],
                  senderCompany: chat.senderCompany,
                })

              }

              return null;

            })
            //console.warn("::: CHAT getSellerINQResponsesChats 1 :::", listData);

            if (Array.isArray(listData)) {
              if (listData.length === 0) return;
            }

            if (!listData.hasOwnProperty("messageThread")) return;

            if (!listData.messageThread.hasOwnProperty("thread")) return;

            if (listData.messageThread.length === 0) return;

            //console.warn("::: CHAT getSellerINQResponsesChats 2 :::", listData);

            const chats = listData.messageThread.thread;

            setThreadId(listData.messageThread.id);

            //console.warn("::: CHAT getSellerINQResponsesChats 3 :::", chats);

            setChatHistory(chats);
          })
          .catch((error: any) => {
            /*
            console.warn(
              "::: CHAT ERROR getSellerINQResponsesChats :::",
              error
            );
            */
            noChatHistory();
            setSellerHasRespondedThis(false);
            setINQIdForMessage(responseId);
          })
      }
    }
    setAttachmentList(attachments);
  };

  const [attachmentList, setAttachmentList] = useState([]);

  const getRFQAttachments: any = (listItem: any) => {

    //console.log("GET MESSAGE THREAD", listItem);

  }

  const resetViewStateHandler: any = () => {

    setViewState(listViewLabel);

  }

  const setSelectedRFQHandler = (listItem: any, listItemIndex: number) => {
    listItem.unreadMessages = 0
    setisListView(false);

    setSelectedRFQ(listItem);
    setSelectedRFQIndex(listItemIndex);

    getRFQAttachments(listItem);

    if (userType.toUpperCase() === "BUYER") {
      setViewState(listViewLabel);

      if (listViewLabel === "RFQ") {
        getBuyerSellerRFQResponses(listItem);
      } else {
        getBuyerSellerInquiryResponses(listItem);
      }
    } else {
      getChatHistory(listItem.id);
    }
  };

  const setSelectedUserHandler = (user: any) => {
    //console.log("::: SELECTED USER :::", user);

    function setupUser(user: any) {
      setSelectedUser(user);

      setINQIdForMessage(user.id);

      getChatHistory(user.inquiryHeaderId, user.id);

      setViewState("CHAT");

      setTimeout(() => {
        var objDiv = document.getElementById("kt_chat_messenger_body_scroll");

        if (typeof objDiv !== "undefined" && objDiv !== null) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      }, 2000);
    }

    if (user !== null) {
      setupUser(user);
    } else {
      AxiosAPI.getProfileData()
        .then((res: any) => {
          let profileData = res.data;

          //console.log("::: SETUP USER PROFILE :::", profileData);

          setupUser(user);
        })
        .catch((error: any) => {
          //console.log("::: SETUP USER PROFILE ERROR :::", error);
        })
    }
  };

  const [selectedRFQ, setSelectedRFQ] = useState({
    id: 0,
    createdAt: "",
    validTo: "",
    productName: "",
    category: {
      name: "",
    },
    sourcingType: "",
    rfqItems: [],
    preferredUnitPrice: {
      currency: "",
      amount: "",
    },
    productDetails: "",
    estimatedOrderQuantity: {
      size: "",
      unitOfMeasurement: "",
    },
  })

  const [userAvatar, setUserAvatar] = React.useState(
    "https://t4.ftcdn.net/jpg/03/32/59/65/360_F_332596535_lAdLhf6KzbW6PWXBWeIFTovTii1drkbT.jpg"
  );

  const getProfileData: Function = (_userType: string, _viewType: string) => {
    AxiosAPI.getProfileData()
      .then((res: any) => {
        let profileData = res.data;

        //console.log("::: PROFILE :::", profileData);

        setLoggedInUserData(profileData);

        if ("avatarS3Key" in profileData) {
          if (profileData.avatarS3Key && profileData.avatarS3Key !== "") {
            setUserAvatar(
              "https://api-live.supplyzone.ai/home/download-attachment/" +
              profileData.avatarS3Key
            );
          }
        }

        setSellerProfile(profileData);

        getListData(`${_userType}`, `${_viewType}`);
        
      })
      .catch((error: any) => {
        //console.log("::: PROFILE ERROR :::", error);
      })
  };

  const [loggedInUserData, setLoggedInUserData] = React.useState({
    email: "",
    person: {
      firstName: "",
      lastName: "",
      title: "",
      designation: "",
    },
    avatarS3Key: "",
  })

  useEffect(() => {
    //console.log(":::::::: LOGGED iN DATA 0 :::", Snippets.user.getCurrentUserAccessToken());

    AxiosAPI.getProfileData()
      .then((res: any) => {
        //console.log(":::::::: LOGGED iN DATA 1 SUCCESS :::", res.data);

        setLoggedInUserData(res.data);

        setTimeout(() => {
          setUserType(Snippets.user.getCurrentUserType());

          getProfileData(Snippets.user.getCurrentUserType(), listViewLabel);
        }, 2000);
      })
      .catch((e: any) => {
        //console.log(":::::::: LOGGED iN DATA 1 ERROR :::", e);
      })
  }, []);

  useEffect(() => {
    if (listItems.length === 0) return;
    selectURLItemIndex(listItems);
  }, [listItems]);

  const [stillLoadingDocs, setStillLoadingDocs] = useState(true);

  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    //console.log("::: DOCS :::", listItems.length, listItems);
    if (listItems.length === 0) {
      setTimeout(() => {
        setDomReady(true);
        setStillLoadingDocs(false);
      }, 5000)
    } else {
      setDomReady(true);
      setStillLoadingDocs(false);
    }
  }, [listItems]);

  const [user, setUser] = React.useState({
    email: "",
    avatarS3Key: "",
    pic: "",
    profile: {
      profileType: "...",
      primaryContactPerson: {
        title: "",
        firstName: "",
        lastName: "",
        designation: "",
      },
    },
  })

  React.useEffect(() => {
    
    const _user: any = Snippets.user.getCurrentUserProfile();

    setUser(_user);

    //let userFullName: string = Snippets.user.getCurrentUserFullName();

  }, []);

  return (
    <>
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1">
              <div className="kommand-tabs">
                <div className="ant-tabs ant-tabs-top ant-tabs-card css-var-rgt ant-tabs-css-var">
                  <div role="tablist" className="ant-tabs-nav">
                    <div className="ant-tabs-nav-wrap">
                      <div
                        className="ant-tabs-nav-list"
                        style={{ transform: "translate(0px, 0px)" }}
                      >
                        <div
                          data-node-key={2}
                          className="ant-tabs-tab bbm bg-none"
                        >
                          <Link
                            to="/messenger/rfqs"
                            role="tab"
                            aria-selected="false"
                            className="ant-tabs-tab-btn"
                            tabIndex={0}
                            id="rc-tabs-11-tab-2"
                            aria-controls="rc-tabs-11-panel-2"
                          >
                            <Badge
                              color="#542277"
                              count={profileCounters.unreadRFQMessages}
                              offset={[18, 6]}
                            >
                              My RFQs
                            </Badge>
                          </Link>
                        </div>
                        <div
                          data-node-key={1}
                          className="ant-tabs-tab ant-tabs-tab-active"
                        >
                          <a
                            role="tab"
                            aria-selected="true"
                            className="ant-tabs-tab-btn"
                            tabIndex={0}
                            id="rc-tabs-11-tab-1"
                            aria-controls="rc-tabs-11-panel-1"
                          >
                            <Badge
                              color="#542277"
                              count={profileCounters.unreadInquiryMessages}
                              offset={[16, 6]}
                            >
                              My Inquiries
                            </Badge>
                          </a>
                        </div>
                        <div
                          data-node-key={3}
                          className="ant-tabs-tab bbm bg-none w-100"
                        >
                          <div
                            role="tab"
                            aria-selected="false"
                            className="ant-tabs-tab-btn"
                            tabIndex={0}
                            id="rc-tabs-11-tab-3"
                            aria-controls="rc-tabs-11-panel-3"
                          >
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {userType.toUpperCase() === "!SELLER" && (
                <a
                  href="#"
                  className="btn btn-primary pl-3 pr-3 font-size-normal font-weight-bold ls-25 mb-0 xaction-btn"
                >
                  Submit a Quotation
                </a>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 list-view">
              <MessengerAdvancedInquiryCol1
                listItems={listItems}
                listViewLabel={listViewLabel}
                selectedRFQIndex={selectedRFQIndex}
                setSelectedRFQHandler={setSelectedRFQHandler}
                userType={userType}
                stillLoading={!listItems || listItems.length === 0}
              />
            </div>
            <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4">
              {userType.toUpperCase() === "BUYER" ? (
                <MessengerAdvancedInquiryCol2
                  listViewLabel={listViewLabel}
                  selectedRFQ={selectedRFQ}
                  getTime={getTime}
                  userType={userType}
                  chatHistory={chatHistory}
                  dummyUsersIsLoading={dummyUsersIsLoading}
                  dummyUsers={dummyUsers}
                  selectedUser={selectedUser}
                  getInitialsFromName={getInitialsFromName}
                  setSelectedUserHandler={setSelectedUserHandler}
                  stillLoading={!listItems || listItems.length === 0}
                  listItems={listItems}
                />
              ) : (
                <MessengerAdvancedInquiryCol3
                  listViewLabel={listViewLabel}
                  selectedRFQ={selectedRFQ}
                  getTime={getTime}
                  inquiryRespondingCompany={inquiryRespondingCompany}
                  viewState={viewState}
                  resetViewState={resetViewStateHandler}
                  selectedUser={selectedUser}
                  dummyChatsIsLoading={dummyChatsIsLoading}
                  chatHistory={chatHistory}
                  getInitialsFromName={getInitialsFromName}
                  chatMessageText={chatMessageText}
                  setChatMessageText={setChatMessageText}
                  replyMessage={replyMessage}
                  replyMessageAttachment={replyMessageAttachment}
                  attachmentList={attachmentList}
                  stillLoading={!listItems || listItems.length === 0}
                  listItems={listItems}
                  isSending={isSending}
                />
              )}
            </div>
            <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5 col-xl-5">
              <MessengerAdvancedInquiryCol3
                listViewLabel={listViewLabel}
                selectedRFQ={selectedRFQ}
                getTime={getTime}
                inquiryRespondingCompany={inquiryRespondingCompany}
                viewState={
                  userType.toUpperCase() === "BUYER" ? viewState : "CHAT"
                }
                resetViewState={resetViewStateHandler}
                selectedUser={selectedUser}
                dummyChatsIsLoading={dummyChatsIsLoading}
                chatHistory={chatHistory}
                getInitialsFromName={getInitialsFromName}
                chatMessageText={chatMessageText}
                setChatMessageText={setChatMessageText}
                replyMessage={replyMessage}
                replyMessageAttachment={replyMessageAttachment}
                attachmentList={attachmentList}
                stillLoading={!listItems || listItems.length === 0}
                isSending={isSending}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MessengerAdvancedInquiryListViewPage;
