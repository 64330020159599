import * as React from "react";
import Cookies from 'universal-cookie';
import axios from 'axios'
import { User } from "./user";
import './users.scss'
import Snippets from "../../../../helpers/Snippets";
import AxiosAPI from "../../../../helpers/AxiosAPI";
export default ({ showUsersModal, closeUsersModal, fetchUsers, user, setUser }) => {

    const [token, setToken] = React.useState("");
    const [savingUser, setSavingUser] = React.useState(false);

    const saveUser = () => {

        const baseURL = Snippets.urls.getAPIBaseURL();
        const method = parseInt(user.id) > 0 ? "put" : "post"
        const invitePayload = {
            "email": user.email ,
            "profileType": "SELLER",
            "isInternalInvite": true
          }
       
        AxiosAPI.apiRequest(method,  '/profile/invite-user',invitePayload).then(({ data: users }) => {
            fetchUsers(token)
            closeUsersModal(new User())

        }).catch((error) => {
            alert("Email address is already registered.")
        });

    }

    React.useEffect(() => {


        const cookies = new Cookies();
        let token = cookies.get('szAccessToken');

        if (typeof token === "undefined") {
            token = localStorage.getItem('szAccessToken');
        }
        setToken(token)


    }, [user]);

    return (
        <>
            <div className="users-modal-background" style={{ display: (showUsersModal) ? "block" : "none" }}>
                <div className="users-modal">
                    <div data-testid="add-user-slider" className="css-9g696q">
                        <div className="css-653y0y-AddUserBody">
                            <div className="css-aqlc50">
                                <div className="css-raag6e">
                                    <span className="css-1sbuixh-SecondaryText">Add a new user to this organization and assign the role of the user for accounts.</span>
                                </div>
                               
                                <div className="css-1uub6w8-FormikFieldContainer">
                                    <label htmlFor="email" className="css-47ktjw" >Email Address</label>
                                    <div className="css-bcefp7"></div>
                                    <div className="css-1pixi5w">
                                        <div className="css-rwk3fz">
                                            <div className="css-16teaqt">
                                                <input type="text" name="email" id="email" aria-disabled="false" aria-required="true" aria-invalid="false"
                                                    value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-raag6e">
                                    </div>
                                </div>
                                <div className="css-aqlc50">
                                    <div className="css-aqlc50">
                                        <div data-testid="radio-label-orgRole" className="css-1xbns9q">
                                            <label htmlFor="orgRole" className="css-47ktjw" >Organization Role</label>
                                        </div>
                                        <div className="css-1ti47aj">
                                            <div className="css-aqlc50" style={{ position: "relative" }}>
                                                <input type="radio" id="user" name="orgRole" aria-checked="true" data-testid="radio-item-user" className="business-type-radio"
                                                    value="USER" checked={user.userRole == "USER"}
                                                    onChange={(_event) => _event.target.checked ? setUser({ ...user, userRole: 'USER' }) : ''} />
                                            </div>
                                            <div className="css-1f8lw3p">
                                                <div className="css-aqlc50">
                                                    <p className="css-1yku2z4-PrimaryText">USER</p>
                                                    <span className="css-1sbuixh-SecondaryText">Can view and modify their own user profile</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="css-1ti47aj">
                                            <div className="css-aqlc50" style={{ position: "relative" }}>
                                                <input type="radio" id="org_admin" name="orgRole" aria-checked="false" data-testid="radio-item-org_admin" className="business-type-radio"
                                                    checked={user.userRole == "ADMIN"}
                                                    value="ADMIN"
                                                    onChange={(_event) => _event.target.checked ? setUser({ ...user, userRole: 'ADMIN' }) : ''} />
                                            </div>
                                            <div className="css-1f8lw3p">
                                                <div className="css-aqlc50">
                                                    <p className="css-1yku2z4-PrimaryText">ORGANIZATION ADMIN</p>
                                                    <span className="css-1sbuixh-SecondaryText">Can modify organization's security settings, view and modify user profiles and user types</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="css-1n81iq5-AddUserFooter">
                            <div className="css-1kpjyzc">
                                <div className="css-1lzz26o">
                                    <button className="css-6x4naz" type="button" onClick={closeUsersModal} >Cancel</button>
                                </div>
                                <div className="css-aqlc50">
                                    <div className="css-14jv7p5">
                                        <button className="css-5bn7x9" type="submit" onClick={saveUser}>
                                            {!savingUser && <span className='indicator-label'>{parseInt(user.id) > 0 ? 'Edit User' : 'Add User'}</span>}
                                            {savingUser && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>


        </>
    );
}