import * as React from "react";
import ProductDetailsInformationTabsCompanyProfile from "./ProductDetailsInformationTabsCompanyProfile";
import axios from "axios";
import { Col, Row } from "antd";

export default ({ product, supplier }) => {


  const [productAttachments, setProductAttachments] = React.useState([]);

  const [productData, setProductData] = React.useState<any>(false);

  /* contact sup */
  const [visitorName, setVisitorName] = React.useState('')
  const [visitorEmail, setVisitorEmail] = React.useState('')
  const [visitorMessage, setVisitorMessage] = React.useState('')

  const sendEmail = () => {
    if (visitorName == "" || visitorMessage == "") return;
    var config = {
      method: 'post',
      url: 'https://szbackend.eu-west-2.elasticbeanstalk.com/marketplace/contact-supplier',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        "productId": product.id,
        "senderEmail": visitorEmail,
        "senderName": visitorName,
        "message": visitorMessage
      }
    };
    axios(config).then(() => {
      window.Swal.fire({
        icon: "success",
        title: "Success",
        html:
          "Your message was successfully sent",
        cancelButtonText: "Close",
      }).then((result: any) => {

      });

      setVisitorEmail("")
      setVisitorMessage("")
      setVisitorName("")

    }).catch((error) => { });
  }
  /* end contact sup*/

  React.useEffect(() => {

    const _product = product;

    setProductAttachments([{
      title: "Product Certification",
      name: "Certification.pdf",
      size: 2874724,
      type: "application.pdf",
      date: "2-Mar-2023",
    }, {
      title: "Technical Datasheet",
      name: "Datasheet.pdf",
      size: 5374724,
      type: "application.pdf",
      date: "2-Mar-2023",
    }, {
      title: "Product Details",
      name: "Detailed Summary.pdf",
      size: 874724,
      type: "application.pdf",
      date: "2-Mar-2023",
    },]);

    setProductData(_product);

    //console.log('::: PRODUCT :::', _product);


  }, [product]);

  return (
    <>

      {supplier.hasOwnProperty('name') && productData && (

        <>

        <div className="tab tab-nav-boxed tab-nav-underline product-tabs xcards pt-0 mt-4">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a href="#product-tab-description" className="nav-link blue active">
                Product Details
              </a>
            </li>
            <li className="nav-item">
              <a href="#product-tab-vendor" className="nav-link blue">
                Company Profile
              </a>
            </li>
            <li className="nav-item d-none">
              <a name="contact-form" id="contact-form" href="#product-tab-reviews" className="nav-link blue">
                Contact Supplier
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active pt-0" id="product-tab-description">
              <div className="row mb-4 pt-5 pr-5 pl-5 pb-5">
                <div className="col-md-12 mb-5">
                  <h4 className="title tab-pane-title font-weight-bold mb-2">
                    Detail
                  </h4>
                  <h5 style={{ fontWeight: "500", lineHeight: "145%" }} dangerouslySetInnerHTML={{ __html: product.description }} />
                  <hr />
                  <div dangerouslySetInnerHTML={{ __html: product.shortDescription }} />

                  {product.productFileAttachments.map((item: any) => (
                    <>
                      {item.attachmentType == "PRODUCT_CERTIFICATE" && (
                        <p><a target="_blank" href={`https://api-live.supplyzone.ai//home/product-attachment/${item.s3Key}`}>Download product certificate</a></p>
                      )}
                      {item.attachmentType == "TECHNICAL_SPECIFICATION_SHEET" && (
                        <p><a target="_blank" href={`https://api-live.supplyzone.ai//home/product-attachment/${item.s3Key}`}>Download more information about this product</a></p>
                      )}
                    </>
                  ))}

                </div>

              </div>

            </div>
            <div className="tab-pane pt-0" id="product-tab-vendor">
              <div className="row mb-3 row mb-4 pt-5 pr-5 pl-5 pb-5">

                <Row>
                  <Col span={24}>
                    <ProductDetailsInformationTabsCompanyProfile
                    name={supplier.name}
                    address={`${supplier.primaryContact.addressLine1}${supplier.primaryContact.addressLine2 !== null ? ', '+supplier.primaryContact.addressLine2 : ''}`} 
                    contact={`${supplier.primaryContactPerson.firstName} ${supplier.primaryContactPerson.lastName}`}
                    phone={supplier.primaryContact.phoneNumber} 
                    email={supplier.primaryContact.emailAddress}
                    banners={[1,2,3,4,5]}
                    sliders={[]}
                    profile={supplier}
                    />
                  </Col>
                </Row>

              </div> 
            </div>
            <div className="tab-pane pt-0" id="product-tab-reviews">
              <div className="row mb-4 row mb-4 pt-5 pr-5 pl-5 pb-5">
                <div className="col-xl-12 col-lg-12 mb-4">
                  <div className="review-form-wrapper">
                    <h3 className="title tab-pane-title font-weight-bold mb-1">
                      Submit Your Message
                    </h3>
                    <p className="mb-3">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <form action="#" method="POST" className="review-form">
                      <div className="row gutter-md">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                            id="author"
                            onChange={(_event) => setVisitorName(_event.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Email"
                            id="email_1"
                            onChange={(_event) => setVisitorEmail(_event.target.value)}
                          />
                        </div>
                      </div>
                      <textarea
                        placeholder="Write your message here..."
                        className="form-control"
                        id="review"
                        onChange={(_event) => setVisitorMessage(_event.target.value)}
                      ></textarea>
                      <div className="form-group">
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          id="save-checkbox"
                        />
                        <label htmlFor="save-checkbox">
                          Save my name, email, and website in this browser for the
                          next time I comment.
                        </label>
                      </div>
                      <button type="button" className="btn btn-dark" onClick={sendEmail}>
                        Send Message
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        </>

      )}

    </>
  );
};
