import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Button, Flex, Input, Row, Col, theme, message, Radio, Select } from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";
import { useAppDataContext } from '../../../../data/context/AppDataContext';
import FormItem from "antd/es/form/FormItem";
import PhoneInput from "antd-phone-input";
import axios from "axios";
import Snippets from "../../../../helpers/Snippets";

const BuyerActivation: React.FC = () => {

    const locationOptions: any = [
        { value: "Alexandra Park" },
        { value: "Arcadia" },
        { value: "Ashbrittle" },
        { value: "Ashdown Park" },
        { value: "Avenues" },
        { value: "Avondale" },
        { value: "Avonlea" },
        { value: "Ballantyne Park" },
        { value: "Belgravia" },
        { value: "Belvedere" },
        { value: "Bloomingdale" },
        { value: "Bluff Hill" },
        { value: "Borrowdale" },
        { value: "Borrowdale Brooke" },
        { value: "Braeside" },
        { value: "Budiriro" },
        { value: "Carrick Creagh" },
        { value: "Chisipite" },
        { value: "Chitungwiza" },
        { value: "Colne Valley" },
        { value: "Cotswold Hills" },
        { value: "Cranborne" },
        { value: "Crowborough" },
        { value: "Cape Town" },
        { value: "Dzivaresekwa" },
        { value: "Eastlea" },
        { value: "Eastlea North" },
        { value: "Eastlea South" },
        { value: "Emerald Hill" },
        { value: "Graniteside" },
        { value: "Glen Lorne" },
        { value: "Glen Norah" },
        { value: "Glen View" },
        { value: "Good Hope" },
        { value: "Greencroft" },
        { value: "Greendale" },
        { value: "Greystone Park" },
        { value: "Groombridge" },
        { value: "Gunhill" },
        { value: "Haig Park" },
        { value: "Harare Central" },
        { value: "Hatcliffe" },
        { value: "Hatfield" },
        { value: "Helensvale" },
        { value: "Highfield" },
        { value: "Highlands" },
        { value: "Hillside" },
        { value: "Hogerty Hill" },
        { value: "Hopley" },
        { value: "Kambuzuma" },
        { value: "Kensington" },
        { value: "Kuwadzana" },
        { value: "Lenana Park" },
        { value: "Letombo Park" },
        { value: "Lewisam" },
        { value: "Lochinvar" },
        { value: "Mabvuku" },
        { value: "Madokero" },
        { value: "Mabelreign" },
        { value: "Malvern" },
        { value: "Mandara" },
        { value: "Maranatha" },
        { value: "Marlborough" },
        { value: "Mbare" },
        { value: "Matidoda" },
        { value: "Meyrick Park" },
        { value: "Milton Park" },
        { value: "Monavale" },
        { value: "Montague" },
        { value: "Mount Pleasant" },
        { value: "Msasa" },
        { value: "Mufakose" },
        { value: "Nkwisi Gardens" },
        { value: "Newlands" },
        { value: "Northwood" },
        { value: "Philadelphia" },
        { value: "Pomona" },
        { value: "Prospect" },
        { value: "Queensdale" },
        { value: "Rhodesville" },
        { value: "Ridgeview" },
        { value: "Ruwa" },
        { value: "Sentosa" },
        { value: "Shawasha Hills" },
        { value: "Southerton" },
        { value: "St Andrew's Park" },
        { value: "St Martins" },
        { value: "Strathaven" },
        { value: "Sunridge" },
        { value: "Sunway City" },
        { value: "Sunningdale" },
        { value: "The Grange" },
        { value: "Tynwald" },
        { value: "Umwinsidale" },
        { value: "Vainona" },
        { value: "Warren Park" },
        { value: "Waterfalls" },
        { value: "Westgate" },
        { value: "Westlea" },
        { value: "Willow Creek" },
        { value: "Zimre Park" },
        { value: "Ascot" },
        { value: "Barbour Fields" },
        { value: "Barham Green" },
        { value: "Beacon Hill" },
        { value: "Belmont" },
        { value: "Belmont East" },
        { value: "Bellevue" },
        { value: "Bradfield" },
        { value: "Burnside" },
        { value: "Cement" },
        { value: "City Centre" },
        { value: "Cowdray Park" },
        { value: "Donnington" },
        { value: "Donnington West" },
        { value: "Douglasdale" },
        { value: "Eloana" },
        { value: "Entumbane" },
        { value: "Famona" },
        { value: "Fortunes Gate" },
        { value: "Four Winds" },
        { value: "Glengarry" },
        { value: "Glenville" },
        { value: "Gwabalanda" },
        { value: "Hillside" },
        { value: "Hillside South" },
        { value: "Hillside West" },
        { value: "Hyde Park" },
        { value: "Ilanda" },
        { value: "Jacaranda" },
        { value: "Kelvin East" },
        { value: "Kelvin West" },
        { value: "Killarney" },
        { value: "Kingsdale" },
        { value: "Kumalo" },
        { value: "Lobengula" },
        { value: "Lockview" },
        { value: "Luveve" },
        { value: "Magwegwe" },
        { value: "Mahatsula North" },
        { value: "Mahatsula South" },
        { value: "Makokoba" },
        { value: "Malindela" },
        { value: "Manningdale" },
        { value: "Matsheumhlope" },
        { value: "Matshobana" },
        { value: "Morningside" },
        { value: "Montrose" },
        { value: "Montgomery" },
        { value: "Morningside" },
        { value: "Mpopoma" },
        { value: "Mzilikazi" },
        { value: "Newton" },
        { value: "Newton West" },
        { value: "Nkulumane" },
        { value: "Nketa" },
        { value: "North End" },
        { value: "North Trenance" },
        { value: "Orange Grove" },
        { value: "Parklands" },
        { value: "Pelandaba" },
        { value: "Pumula" },
        { value: "Queens Park West" },
        { value: "Raylton" },
        { value: "Richmond" },
        { value: "Riverside North" },
        { value: "Riverside South" },
        { value: "Romney Park" },
        { value: "Rowena" },
        { value: "Sauerstown" },
        { value: "Selborne Park" },
        { value: "South Riding" },
        { value: "Southdale" },
        { value: "Southwold" },
        { value: "Steeldale" },
        { value: "Sunnyside" },
        { value: "Sunninghill" },
        { value: "The Suburbs" },
        { value: "Tshabalala" },
        { value: "Umguza Estate" },
        { value: "University Site" },
        { value: "Waterford" },
        { value: "West Somerton" },
        { value: "Westgate" },
        { value: "Westondale" },
        { value: "Whitecairns" },
        { value: "Willsgrove" },
        { value: "Willowvale" },
        { value: "Woodlands" },
        { value: "Woodville" },
        { value: "Woodville Park" },
        { value: "Beitbridge" },
        { value: "Bindura" },
        { value: "Bulawayo" },
        { value: "Chegutu" },
        { value: "Chinhoyi" },
        { value: "Chipinge" },
        { value: "Chiredzi" },
        { value: "Chitungwiza" },
        { value: "Gwanda" },
        { value: "Gweru" },
        { value: "Harare" },
        { value: "Hwange" },
        { value: "Kadoma" },
        { value: "Kariba" },
        { value: "Karoi" },
        { value: "Kwekwe" },
        { value: "Marondera" },
        { value: "Masvingo" },
        { value: "Mutare" },
        { value: "Norton" },
        { value: "Plumtree" },
        { value: "Redcliff" },
        { value: "Rusape" },
        { value: "Ruwa" },
        { value: "Shurugwi" },
        { value: "Victoria Falls" },
        { value: "Zvishavane" },
    ];

    const years = Array.from(
        new Array(new Date().getFullYear() - 1899),
        (_, index) => 1900 + index
      );
    const yearsOptions: any = []
    years.forEach((year)=>{
        yearsOptions.push({value: year})
    })

    const industrySectors: string[] = [
        "Manufacturing", "Retail", "Healthcare", "Education", "Technology", "Hospitality and Tourism", "Media and Entertainment", "Energy", "Services", "Agriculture", "Automotive", "Construction", "Consumer Goods", "Financial Services", "Food and Beverage", "Pharmaceuticals", "Aerospace and Defense", "Chemicals", "Mining", "Oil and Gas", "Telecommunications", "Government Agencies", "Other"
    ]
    const industrySectorOptions: any = []
    industrySectors.forEach((industry)=>{
        industrySectorOptions.push({value: industry})
    })

   
    const { token } = theme.useToken();

    const [confettiShown, setConfettiShown] = useState(false);

    const [current, setCurrent] = useState(0);
    const [companySize, setCompanySize] = useState("1");

    const [salesRepresenative, setSalesRepresenative] = useState("1");

    const [phoneNumber, setPhoneNumber] = useState("");

    const [organization, setOrganization] = useState("");

    const [designation, setDesignation] = useState("");

    const [annualTurnOver, setAnnualTurnOver] = useState("100000");

    const [messageApi, contextHolder] = message.useMessage();

   

     const [isActivated, setIsActivated] = useState(false);



    //good 
    const [businessLocation, setBusinessLocation] = useState("")
    const [businessAddress, setBusinessAddress] = useState("")
    const [yearEstablished, setYearEstablished] = useState("")
    const [organisationName, setOrganisationName] = useState("")
    const [industrySector, setIndustrySector] = useState("")


    const renderSteps: any = () => {
        setSteps([

            {
                title: "Business Profile",
                content: (
                    <>
                        <Row>
                            <Col className="w-100">
                                <h2 className="mt-5 mb-3">Set up a profile</h2>
                                <h4 className="mt-1 mb-5">
                                    Showcase Your Brand with a Compelling Business Profile.
                                </h4>
                            </Col>
                        </Row>
                        <Row
                            className="w-100 m-auto pt-5 pb-5"
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="text-left text-align-left"
                            >
                                <div className="xcards category category-classic category-absolute w-100">
                                    <h3>Business Location</h3>
                                    <p className="fs-16 mt-3">
                                        Select Location
                                    </p>
                                    <FormItem className="mb-0" name="phone" >
                                        <Select
                                            size="large"
                                            className="mt-2 mb-2 grey-input w-100"
                                            placeholder="Location"
                                            onChange={(value) => {
                                                setBusinessLocation(value);
                                            }}
                                            options={locationOptions}
                                        />
                                    </FormItem>
                                </div>
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="text-left text-align-left"
                            >
                                <div className="xcards category category-classic category-absolute w-100">
                                    <h3>Business Address</h3>
                                    <p className="fs-16 mt-3">
                                        Enter the business address
                                    </p>
                                    <Input
                                        size="large"
                                        className="mt-4 w-100"
                                        placeholder="Business Address"
                                        disabled={isActivated}
                                        onChange={(e) => setBusinessAddress(e.target.value)}
                                    />
                                </div>
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="text-left text-align-left"
                            >
                                <div className="xcards category category-classic category-absolute w-100">
                                    <h3>Year Established</h3>
                                    <p className="fs-16 mt-3">
                                    Year Established
                                    </p>
                                    <FormItem className="mb-0" name="phone" >
                                        <Select
                                            size="large"
                                            className="mt-2 mb-2 grey-input w-100"
                                            placeholder="Year Established"
                                            onChange={(value) => {
                                                setYearEstablished(value);
                                            }}
                                            options={yearsOptions}
                                        />
                                    </FormItem>
                                </div>
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="text-left text-align-left"
                            >
                                <div className="xcards category category-classic category-absolute w-100">
                                    <h3>Organisation Name</h3>
                                    <p className="fs-16 mt-3">
                                        Eter the organisation Name
                                    </p>
                                    <FormItem className="mb-0" name="phone" >
                                    <Input
                                        size="large"
                                        className="mt-4 w-100"
                                        placeholder="Organisation name"
                                        disabled={isActivated}
                                        onChange={(e) => setOrganisationName(e.target.value)}
                                        value={organisationName}
                                    />
                                    </FormItem>
                                </div>
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="text-left text-align-left"
                            >
                                <div className="xcards category category-classic category-absolute w-100">
                                    <h3>Industry Sector</h3>
                                    <p className="fs-16 mt-3">
                                    Select Industry Sector
                                    </p>
                                    <FormItem className="mb-0" name="phone" >
                                        <Select
                                            size="large"
                                            className="mt-2 mb-2 grey-input w-100"
                                            placeholder="Industry Sector"
                                            onChange={(value) => {
                                                setIndustrySector(value);
                                            }}
                                            options={industrySectorOptions}
                                        />
                                    </FormItem>
                                </div>
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="text-left text-align-left"
                            >
                                <div className="xcards category category-classic category-absolute w-100">
                                    <h3>How many employees are in your company?</h3>
                                    <p className="fs-16 mt-3">Select the company size below</p>
                                    <Radio.Group
                                        className="w-100"
                                        defaultValue="1-5"
                                        buttonStyle="solid"
                                        disabled={isActivated}
                                        onChange={(newValue: any) => {
                                            setCompanySize(newValue.target.value);
                                        }}
                                        value={companySize}
                                    >
                                        <Radio.Button value="0 - 100">0 - 100</Radio.Button>
                                        <Radio.Button value="100 - 500">100 - 500</Radio.Button>
                                        <Radio.Button value="500 - 1000">500 - 1000</Radio.Button>
                                        <Radio.Button value="1000+">1000+</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </Col>
                           
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                className="text-left text-align-left"
                            >
                                <div className="xcards category category-classic category-absolute w-100">
                                    <h3>Annual Turnover</h3>
                                    <p className="fs-16 mt-3">Total revenue generated annually</p>

                                    <Radio.Group
                                        className="w-100"
                                        defaultValue="1-5"
                                        buttonStyle="solid"
                                        disabled={isActivated}
                                        onChange={(newValue: any) => {
                                            setAnnualTurnOver(newValue.target.value);
                                        }}
                                        value={annualTurnOver}
                                    >
                                        <Radio.Button value="$0K - $500K">$0K - $500K</Radio.Button>
                                        <Radio.Button value="$500K - $5M">$500K - $5M</Radio.Button>
                                        <Radio.Button value="$5M - $25K">$5M - $25K</Radio.Button>
                                        <Radio.Button value="$25M+">$25M+</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </Col>
                        </Row>
                    </>
                ),
            },
        ]);
    }

    const [steps, setSteps] = useState([]);

    useEffect(() => {
        renderSteps();
    }, [isActivated]);

    useEffect(() => {
        renderSteps();
        console.log(":: PROFILE UPDATE ::", [
            setCompanySize,
            companySize,
            salesRepresenative,
            phoneNumber,
            organization,
            designation,
            annualTurnOver,
        ]);
    }, [
        setCompanySize,
        companySize,
        salesRepresenative,
        phoneNumber,
        organization,
        designation,
        annualTurnOver,
    ]);

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const contentStyle: React.CSSProperties = {
        color: token.colorTextTertiary,
        backgroundColor: `transparent /* token.colorFillAlter */`,
        borderRadius: token.borderRadiusLG,
        border: `none /*1px dashed ${token.colorBorder}*/`,
        margin: "auto",
        textAlign: "center",
    };

    const buyerActivationComplete = ()=>{
        console.log(Snippets.user.getCurrentUserAccessToken());
        const payload = {
            companyRegisteredCity: businessLocation,
            companyRegisteredAddress: businessAddress,
            yearEstablished: parseInt(yearEstablished),
            name: organisationName,
            industry: industrySector,
            totalEmployees: companySize,
            totalAnnualSales: annualTurnOver
        }

        AxiosAPI.apiRequest('patch', '/buyer/update-fields', payload)
        .then((res: any) => {
            window.location.href = '/'
          })
          .catch((error: any) => {
            
          });
    }

   
    return (
        <>
            {contextHolder}

            <main className="main">
                <div className="container pt-2">
                    <div className="product-wrapper-1  mb-1">
                        <div className="title-link-wrapper pb-2 mb-1 w-100">
                            <Flex className="w-100" align="center" justify="space-between">
                                <h2
                                    className="title ls-normal mb-0 w-100 blackx"
                                    id="top-anchor"
                                >
                                    Buyer Activation
                                </h2>
                                <Flex
                                    className=""
                                    align="center"
                                    justify="space-between"
                                    gap={24}
                                >
                                    <Button
                                        size="large"
                                        type="primary"
                                        block
                                        onClick={() => buyerActivationComplete()}
                                    >
                                        Continue
                                    </Button>
                                </Flex>
                            </Flex>
                        </div>

                        {confettiShown && <Confetti />}
                        <div style={contentStyle} className="m-auto mt-10 ">
                            {steps.length > 0 && <>{steps[current].content}</>}
                        </div>
                        <div className="title-link-wrapper top pb-2 mt-5 w-100">
                            <Flex className="w-100" align="center" justify="space-between">
                                <h2 className="title ls-normal mb-0 w-100 blackx"> </h2>
                                <Flex
                                    className=""
                                    align="center"
                                    justify="space-between"
                                    gap={24}
                                >
                                    <Button
                                        size="large"
                                        type="primary"
                                        block
                                        onClick={() => buyerActivationComplete()}
                                    >
                                        Continue
                                    </Button>
                                </Flex>
                            </Flex>
                        </div>
                    </div>
                </div>
            </main>

        </>
    );
};
export { BuyerActivation };
