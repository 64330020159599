import Cookies from 'universal-cookie';
import AxiosAPI from './AxiosAPI';
import { UserPilotEvents, UserPilotHelper } from './UserPilotHelper';

const cookies = new Cookies();

const Snippets: any = {

    inquiries: {

        save: (api: any, product: any, supplier: any, productPrice: any, inquiryQuantity: number, emailAddress: string, details: string, callbackSuccess: any, callbackError: any) => {

            var expiryDate = new Date();

            var expiryDateInOneMonth = new Date(

                expiryDate.setMonth(expiryDate.getMonth() + 1)

            );

            var expiryFullDate = expiryDateInOneMonth.toISOString();

            let productEstimatePrice: number = 0;

            if (product.pricingOption === "LADDER_PRICE") {
                productEstimatePrice = parseFloat(product.productPrices[0].price);
            }
            if (product.pricingOption === "PRICE_RANGE") {
                productEstimatePrice =
                    (product.productPrices[0].price + product.productPrices[1].price) / 2;
            }
            if (product.pricingOption === "FIXED") {
                productEstimatePrice = productPrice;
            }

            const data = {
                id: 0,
                title: product.name,
                expirationDate: expiryFullDate,
                deliveryCity: "Harare",
                details: details,
                contactName: Snippets.user.getCurrentUserFullName(), //`${emailAddress}`.split("@")[0],
                contactEmail: emailAddress,
                isPublic: true,
                estimatedValue: productEstimatePrice * inquiryQuantity,
                status: "DRAFT",
                lineItems: [
                    {
                        id: 0,
                        productId: product.id,
                        quantity: inquiryQuantity,
                    },
                ],
                attachments: [] as any,
            };

            //console.log("SEND INQ", data)

            setTimeout(() => {

                api.saveInquiry(data)
                    .then((res: any) => {

                        const result = res.data;

                        callbackSuccess(result);

                        UserPilotHelper.logEvent(UserPilotEvents.INQUIRE_PRODUCT, result);

                        //console.log(":: SAVED RFQ :::", result);

                        (window as any).Swal.fire({
                            icon: "success",
                            title: "Submitted",
                            html:
                                "Your inquiry has been sent to " +
                                supplier.name +
                                " for the product <br /><b>" +
                                product.name.substring(0, 32) +
                                "...</b>",
                            showCancelButton: true,
                            confirmButtonText: "Show Inquiry",
                            cancelButtonText: "Close",
                            denyButtonText: `No`,
                        }).then((result: any) => {
                            if (result.isConfirmed) {
                                window.location.href =
                                    Snippets.urls.getStoreFrontURL() + "/messenger/inquiries";
                            } else if (result.isDenied) {
                            }
                        });

                    })
                    .catch((error: any) => {

                        callbackError(error);

                        //console.log(":: ERROR RFQ :::", error);

                        (window as any).Swal.fire({
                            icon: "error",
                            title: "Failed",
                            text: "There was an error submitting your request. Please try again",
                        });

                    });

            }, 500);

        }

    },

    urls: {


        getEnv: () => {

            let isLocal: boolean = false;
            let isDev: boolean = false;
            let isCommercial: boolean = false;
            let isProduction: boolean = false;

            if (typeof window !== "undefined") {

                const currentURL: string = (window as any).location.href;

                if (currentURL.includes('localhost')) {
                    isLocal = true;
                }

                if (currentURL.includes('127.0.0.1')) {
                    isLocal = true;
                }

                if (currentURL.includes('dev.supplyzone.ai')) {
                    isDev = true;
                }

                if (currentURL.includes('commercial.supplyzone.ai')) {
                    isCommercial = true;
                }

                if (currentURL.includes('htpps://supplyzone.ai')) {
                    isProduction = true;
                }

            }

            return {
                isLocal: isLocal,
                isDev: isDev,
                isCommercial: isCommercial,
                isProduction: isProduction
            }

        },

        getStoreFrontURL: () => {

            const env: any = Snippets.urls.getEnv();

            let _url: string = '';

            if (env.isLocal) {
                _url = 'http://localhost:3000';
            }

            if (env.isDev) {
                _url = 'https://dev.supplyzone.ai';
            }

            if (env.isCommercial) {
                _url = 'https://commercial.supplyzone.ai';
            }

            if (env.isProduction) {
                _url = 'https://supplyzone.ai';
            }

            return _url;

        },

        getForSellersURL: () => {

            return Snippets.urls.getStoreFrontURL() + '/become-a/seller';

        },

        getForBuyersURL: () => {

            return '#'; //Snippets.urls.getStoreFrontURL() + '/become-a/buyer';

        },

        getBuyerLandingURL: () => {

            const env: any = Snippets.urls.getEnv();

            let _url: string = '';

            if (env.isLocal) {
                _url = 'http://localhost:3011';
            }

            if (env.isDev) {
                _url = 'https://business.dev.supplyzone.ai';
            }

            if (env.isCommercial) {
                _url = 'https://business.commercial.supplyzone.ai';
            }

            if (env.isProduction) {
                _url = 'https://business.supplyzone.ai';
            }

            return _url;

        },

        getMyProfileURL: () => {

            const env: any = Snippets.urls.getEnv();

            let _url: string = '';

            if (env.isLocal) {
                _url = 'http://localhost:3011';
            }

            if (env.isDev) {
                _url = 'https://accounts.dev.supplyzone.ai';
            }

            if (env.isCommercial) {
                _url = 'https://accounts.commercial.supplyzone.ai';
            }

            if (env.isProduction) {
                _url = 'https://accounts.supplyzone.ai';
            }

            return _url + `/crafted/account/profile`;

        },

        getSellerLandingURL: () => {

            const env: any = Snippets.urls.getEnv();

            let _url: string = '';

            if (env.isLocal) {
                _url = 'http://localhost:3011';
            }

            if (env.isDev) {
                _url = 'https://sell.dev.supplyzone.ai';
            }

            if (env.isCommercial) {
                _url = 'https://sell.commercial.supplyzone.ai';
            }

            if (env.isProduction) {
                _url = 'https://sell.supplyzone.ai';
            }

            return _url;

        },

        getMessengerURL: (userType: string, viewType: string, documentId: number = 1) => {

            const env: any = Snippets.urls.getEnv();

            let _url: string = '';

            if (env.isLocal) {
                _url = `http://localhost:3000/?user-type=${userType.toString().toUpperCase()}&view=${viewType.toString().toUpperCase()}&id=${documentId}&token=${Snippets.user.getCurrentUserAccessToken()}`;
            }

            if (env.isDev) {
                _url = `https://messenger.dev.supplyzone.ai/?user-type=${userType.toString().toUpperCase()}&view=${viewType.toString().toUpperCase()}&id=${documentId}&token=${Snippets.user.getCurrentUserAccessToken()}`;
            }

            if (env.isCommercial) {
                _url = `https://messenger.commercial.supplyzone.ai/?user-type=${userType.toString().toUpperCase()}&view=${viewType.toString().toUpperCase()}&id=${documentId}&token=${Snippets.user.getCurrentUserAccessToken()}`;
            }

            if (env.isProduction) {
                _url = `https://messenger.supplyzone.ai/?user-type=${userType.toString().toUpperCase()}&view=${viewType.toString().toUpperCase()}&id=${documentId}&token=${Snippets.user.getCurrentUserAccessToken()}`;
            }

            return _url;

        },

        getLogoutURLBackend: () => {

            const env: any = Snippets.urls.getEnv();

            let _url: string = '';

            if (env.isLocal) {
                _url = 'http://localhost:3011/logout';
            }

            if (env.isDev) {
                _url = 'https://accounts.dev.supplyzone.ai/logout';
            }

            if (env.isCommercial) {
                _url = 'https://accounts.commercial.supplyzone.ai/logout';
            }

            if (env.isProduction) {
                _url = 'https://accounts.supplyzone.ai/logout';
            }

            return _url;

        },

        getLogoutURLFrontend: () => {

            const env: any = Snippets.urls.getEnv();

            let _url: string = '';

            if (env.isLocal) {
                _url = 'http://localhost:3000/?logout=1';
            }

            if (env.isDev) {
                _url = 'https://dev.supplyzone.ai/?logout=1';
            }

            if (env.isCommercial) {
                _url = 'https://commercial.supplyzone.ai/?logout=1';
            }

            if (env.isProduction) {
                _url = 'https://supplyzone.ai/?logout=1';
            }

            return _url;

        },

        getHomePageURL: () => {

            return window.location.origin;

        },

        getAPIBaseURL: () => {

            return process.env.REACT_APP_API_BASE_URL || 'https://api-live.supplyzone.ai';
            //return process.env.REACT_APP_API_BASE_URL || 'https://szbackend.eu-west-2.elasticbeanstalk.com';
            //return 'http://localhost:8081'

        },

        getQueryVar: (key: string) => {

            const queryString = window.location.search.substring(1);
            const queryParts = queryString.split("&");
            let query = "";
            for (let x = 0; x < queryParts.length; x++) {
                let tmpArr = queryParts[x].split("=");
                if (tmpArr[0] === key) {
                    query = tmpArr[1].replace("+", " ");
                }
            }

            return query;

        },


        getQueryParams: () => {

            const queryString = window.location.search;
            const urlParams: any = new URLSearchParams(queryString);
            //console.log("::::::::::::::", urlParams)
            const vars: { [key: string]: string } = {};
            for (const [key, value] of urlParams?.entries()) {
                vars[key] = value;
            }
            return vars;

        },



    },

    events: {

        subscribe: (eventName: any, listener: any) => {

            document.addEventListener(eventName, listener);

        },

        unsubscribe: (eventName: any, listener: any) => {

            document.removeEventListener(eventName, listener);

        },

        publish: (eventName: any, data: any) => {

            const event = new CustomEvent(eventName, { detail: data });
            document.dispatchEvent(event);

        },

    },

    user: {

        getCurrentUserData: () => {

            return localStorage.getItem('userData');

        },

        getCurrentUserProfile: () => {

            return localStorage.getItem('userProfile');

        },

        getCurrentUserDataObj: () => {

            let userData: any = localStorage.getItem('userData');

            if (userData !== null && userData.length > 2) {
                try {
                    userData = JSON.parse(localStorage.getItem('userProfile'));
                } catch (e) {
                    userData = {};
                } finally {

                }
            }

            return userData;

        },

        getCurrentUserProfileObj: () => {

            let userProfile: any = localStorage.getItem('userProfile');

            if (userProfile !== null && userProfile.length > 2) {
                try {
                    userProfile = JSON.parse(localStorage.getItem('userProfile'));
                } catch (e) {
                    userProfile = {};
                } finally {

                }
            }

            return userProfile;

        },

        getCurrentUserType: () => {

            return localStorage.getItem('userType')?.replace(/"/g, '');

        },

        getCurrentUserId: () => {

            return Snippets.user.getCurrentUserDataObj()?.id || 0;

        },

        getCurrentUserEmail: () => {

            return localStorage.getItem('userEmailAddress')?.replace(/"/g, '');

        },

        getCurrentUserFullName: () => {

            return localStorage.getItem('userDisplayName')?.replace(/"/g, '');

        },

        getCurrentUserAvatar: (avatarS3Key: string = '') => {


            if (avatarS3Key === '') {

                let szUserData: any = Snippets.user.getCurrentUserProfile();

                avatarS3Key = szUserData.avatarS3Key;

            }

            return avatarS3Key ? "https://api-live.supplyzone.ai/home/download-attachment/" + avatarS3Key : "https://t4.ftcdn.net/jpg/03/32/59/65/360_F_332596535_lAdLhf6KzbW6PWXBWeIFTovTii1drkbT.jpg";

        },

        getCurrentUserAccessToken: () => {

            return localStorage.getItem('userAccessToken')?.replace(/"/g, '');

        },

    },

    string: {

        pad: (num: number, paddingString: string = "0", paddingLength: number = 6, prefix: string = "") => {
            // Convert the number to a string and concatenate with '1000'
            let paddedString = num.toString();

            // Ensure it has at least 6 digits
            return prefix + paddedString.padStart(paddingLength, paddingString);
        },

        rfqNumber: (rfq: any) => {
            return Snippets.string.pad(rfq.id, "0", 4, "1001")
        },

        inqNumber: (rfq: any) => {
            return Snippets.string.pad(rfq.id, "0", 4, "1002")
        },

        invNumber: (rfq: any) => {
            return Snippets.string.pad(rfq.id, "0", 4, "1003")
        },

        limitCharacters: (text: string, limit: number) => {
            if (text.length <= limit) {
                return text;
            }

            // Find the last space within the limit
            let trimmed = text.substr(0, limit);
            let lastSpace = trimmed.lastIndexOf(' ');

            // If a space is found, cut at the space; otherwise, cut at the limit
            if (lastSpace > 0) {
                trimmed = trimmed.substr(0, lastSpace);
            }

            return trimmed + '...';
        },

        toSlug: (str: any) => {

            return str.toString()               // Convert to string
                .normalize('NFD')               // Change diacritics
                .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
                .replace(/\s+/g, '-')            // Change whitespace to dashes
                .toLowerCase()                  // Change to lowercase
                .replace(/&/g, '-and-')          // Replace ampersand
                .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
                .replace(/-+/g, '-')             // Remove duplicate dashes
                .replace(/^-*/, '')              // Remove starting dashes
                .replace(/-*$/, '');             // Remove trailing dashes

        },
        toTitleCase: (str: string) => {

            const words = str.split("_");
            const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
            const result = capitalizedWords.join(" ");

            return result;

        }

    },

    settings: {

        env: 'live'

    },

    konstants: {

    },

    arrays: {

        chunk: (inputArray: any, perChunk: number) => {

            const result = inputArray.reduce((resultArray: any[][], item: any, index: number) => {
                const chunkIndex = Math.floor(index / perChunk)

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [] // start a new chunk
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, [])

            return result;

        },
        dynamicSort: (property: any) => {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a: any, b: any) {
                /* next line works with strings and numbers, 
                 * and you may want to customize it to your needs
                 */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

    },

    numbers: {

        random: (min: number, max: number) => {

            return Math.floor(Math.random() * (max - min + 1)) + min;

        }
    }


};

switch (Snippets.settings.env) {
    case 'local':
        Snippets.konstants.BASE_URL_API = 'https://api-live.supplyzone.ai'
        break;
    case 'local-dev':
        Snippets.konstants.BASE_URL_API = 'http://localhost:8080'
        break;
    case 'remote-dev':
        Snippets.konstants.BASE_URL_API = 'https://api-live.supplyzone.ai'
        break;
    case 'live':
        Snippets.konstants.BASE_URL_API = 'https://api-live.supplyzone.ai'
        break;
}
export default Snippets;