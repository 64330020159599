/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { format as formatTime, render, cancel, register } from "timeago.js";
import Snippets from "../../../../helpers/Snippets";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Flex,
  Image,
  Menu,
  MenuProps,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import Meta from "antd/es/card/Meta";
import {
  CommentOutlined,
  ControlOutlined,
  CopyOutlined,
  DownOutlined,
  FacebookFilled,
  FlagOutlined,
  FolderOutlined,
  FrownOutlined,
  HeartFilled,
  HeartOutlined,
  InstagramOutlined,
  LikeFilled,
  LinkedinFilled,
  MailOutlined,
  MoreOutlined,
  ShareAltOutlined,
  SlackOutlined,
  TwitterOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import MenuItem from "antd/es/menu/MenuItem";
import { useAppDataContext } from "../../../../data/context/AppDataContext";
import { Typography } from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";

const { Title } = Typography;

const RFQAdvancedListViewCard: any = ({ listItem }: any) => {
  const { setIsLoginModalOpen, userType, isLoggedIn } = useAppDataContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [rfqLink, setRFQLink] = React.useState("");

  React.useEffect(() => {
    let _domain: string = "";

    const _url: string = window.location.href;

    if (_url.includes("localhost")) {
      _domain = "http://localhopst:3000";
    }

    if (_url.includes("dev.supplyzone.ai")) {
      _domain = "https://messenger.dev.supplyzone.ai";
    }

    if (_url.includes("commercial.supplyzone.ai")) {
      _domain = "https://messenger.commercial.supplyzone.ai";
    }

    if (_url.includes("https://supplyzone.ai")) {
      _domain = "https://messenger.supplyzone.ai";
    }

    const _token: string = Snippets.user.getCurrentUserAccessToken();

    const _link: string = `${_domain}/?user-type=BUYER&view=RFQ&token=${_token}`;

    setRFQLink(_link);
  }, []);

  const [open, setOpen] = React.useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const shareData = {
    title: "My awesome website",
    text: "Check out this cool website!",
    url: "https://www.example.com",
  };

  const doLike = async () => {
    try {
      await navigator.share(shareData);
      //console.log("Shared successfully");
    } catch (err) {
      //console.error("Error sharing:", err);
    }
  };

  const doShare = async () => {
    try {
      await navigator.share(shareData);
      //console.log("Shared successfully");
    } catch (err) {
      //console.error("Error sharing:", err);
    }
  };

  const doMore = async () => {
    showDrawer();
  };

  const doAction: any = () => {
    if (!isLoggedIn) {
      setIsLoginModalOpen(true);

      return;
    } else {
      if (userType === "BUYER") {
        messageApi.error(
          "You can only respond to an RFQ when you are logged in as a seller"
        );
      } else {
        window.location.href = "/messenger/rfqs?id=" + listItem.id;
      }
    }
  };

  const copyLink: any = () => {
    navigator.clipboard.writeText(window.location.href);

    messageApi.info("Link copied to clipboard");
  };

  const getTime: Function = (timeString: string): string => {
    if (!timeString) return timeString;
    return format(new Date(timeString), "yyyy-MMM-dd");
  };

  const viewRFQDetailsModal: any = () => {
    setModal2Open(true);
  };

  type MenuItem = Required<MenuProps>["items"][number];

  const itemsShare: MenuItem[] = [
    {
      key: "1",
      icon: <FacebookFilled />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"/share?item=product&via=facebook&id="}
        >
          Share via Facebook
        </a>
      ),
    },
    {
      key: "2",
      icon: <TwitterOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"/share?item=product&via=twitter&id="}
        >
          Share via Twitter
        </a>
      ),
    },
    {
      key: "3",
      icon: <InstagramOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"/share?item=product&via=instagram&id="}
        >
          Share via Instagram
        </a>
      ),
    },
    {
      key: "4",
      icon: <LinkedinFilled />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"/share?item=product&via=linkedin&id="}
        >
          Share via Linked In
        </a>
      ),
    },
    {
      key: "5",
      icon: <SlackOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"/share?item=product&via=slack&id="}
        >
          Share via Linked In
        </a>
      ),
    },
    {
      key: "9A",
      icon: <CopyOutlined />,
      label: <a onClick={copyLink}>Copy Link</a>,
    },
  ];

  const itemsMore: MenuItem[] = [
    {
      key: "6A",
      icon: <ControlOutlined />,
      label: <a href={"#"}>Move RFQ to All RFQs</a>,
    },
    {
      key: "6B",
      icon: <ControlOutlined />,
      label: <a href={"#"}>Move RFQ to Active RFQs</a>,
    },
    {
      key: "6C",
      icon: <ControlOutlined />,
      label: <a href={"#"}>Move RFQ to Completed RFQs</a>,
    },
    {
      key: "2",
      icon: <UnorderedListOutlined />,
      label: (
        <a
          onClick={() => {
            if (isMobile) {
              onClose();
              toggleVisibility();
            } else {
              viewRFQDetailsModal();
            }
          }}
        >
          View RFQ details
        </a>
      ),
    },
    {
      key: "7",
      icon: <ShareAltOutlined />,
      label: <a onClick={doShare}>Share this RFQ</a>,
    },
    {
      key: "8",
      icon: <FlagOutlined />,
      label: <a href={"#"}>Report this RFQ</a>,
    },
    {
      key: "9",
      icon: <MailOutlined />,
      label: <a href={"#"}>Send Feedback</a>,
    },
    {
      key: "9A",
      icon: <CopyOutlined />,
      label: <a onClick={copyLink}>Copy Link</a>,
    },
    {
      key: "10",
      label: "",
    },
    {
      key: "11",
      label: "",
    },
    {
      key: "12",
      label: "",
    },
  ];

  const [thumbURL, setThumbURL] = React.useState("");

  React.useEffect(() => {
    //console.log(":::::::::", listItem);

    if (listItem.attachments.length > 0) {
      if (
        listItem.attachments[0].s3Key.includes("gif") ||
        listItem.attachments[0].s3Key.includes("png") ||
        listItem.attachments[0].s3Key.includes("jpeg") ||
        listItem.attachments[0].s3Key.includes("jpg") ||
        listItem.attachments[0].s3Key.includes("webp")
      ) {
        if (
          listItem.attachments[0].s3Key ===
            "5d06d91c-8b0c-40fe-ae8b-e80e1446a94a.jpg" ||
          listItem.attachments[0].s3Key ===
            "b0bea552-6f3f-4d50-872b-3c4d8c8b3da4.jpg"
        ) {
          if (listItem.category.image !== null) {
            setThumbURL(
              `https://api-live.supplyzone.ai//home/product-attachment/${listItem.category.image}`
            );
          } else {
            setThumbURL(
              "https://img.freepik.com/premium-vector/modern-minimalism-empty-podium-stage-product-display-showcase-template-geometrical-3d-blue-purple-box-cylinder-with-frame_22052-2800.jpg"
            );
          }
        } else {
          setThumbURL(
            `https://api-live.supplyzone.ai//home/product-attachment/${listItem.attachments[0].s3Key}`
          );
        }
      } else {
        if (listItem.category.image !== null) {
          setThumbURL(
            `https://api-live.supplyzone.ai//home/product-attachment/${listItem.category.image}`
          );
        } else {
          setThumbURL(
            "https://img.freepik.com/premium-vector/modern-minimalism-empty-podium-stage-product-display-showcase-template-geometrical-3d-blue-purple-box-cylinder-with-frame_22052-2800.jpg"
          );
        }
      }
    } else {
      if (listItem.category.image !== null) {
        setThumbURL(
          `https://api-live.supplyzone.ai//home/product-attachment/${listItem.category.image}`
        );
      } else {
        setThumbURL(
          "https://img.freepik.com/premium-vector/modern-minimalism-empty-podium-stage-product-display-showcase-template-geometrical-3d-blue-purple-box-cylinder-with-frame_22052-2800.jpg"
        );
      }
    }
  }, []);
  const [modal2Open, setModal2Open] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileRegex = /Mobi|Android/i;
    if (mobileRegex.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const [isFullyVisible, setIsFullyVisible] = useState(false);

  const toggleVisibility = () => {
    if (isMobile) {
      setIsVisible(!isVisible);
    } else {
      setModal2Open(true);
    }
  };

  const toggleFullVisibility = () => {
    setIsFullyVisible(!isFullyVisible);
  };

  const [buyerAvatar, setbuyerAvatar] = useState("");
  const [buyerFullName, setbuyerFullName] = useState("");
  const [buyerDesignation, setbuyerDesignation] = useState("");
  const [buyerCompanyName, setbuyerCompanyName] = useState("");
  const [buyerCompanyLogo, setbuyerCompanyLogo] = useState("");
  const [buyerCompanyBusinessType, setbuyerCompanyBusinessType] = useState("");

  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    //console.log("GET THIS BUYER", listItem);

    AxiosAPI.getBuyerProfile(listItem.buyerId)
      .then((res: any) => {
        //console.log("GOT BUYER", res);

        setbuyerAvatar(
          `https://api-live.supplyzone.ai/home/download-attachment/${res.data.primaryContactAvatar}`
        );
        setbuyerFullName(
          res.data.person.firstName + " " + res.data.person.lastName
        );
        setbuyerDesignation(res.data.person.designation ?? "Buyer Officer");
        setbuyerCompanyName(res.data.companyName);
        setbuyerCompanyLogo(
          `https://szbackend.eu-west-2.elasticbeanstalk.com/marketplace/supplier/${listItem.buyerId}/document/LOGO`
        );
        setbuyerCompanyBusinessType(
          Snippets.string.toTitleCase(res.data.businessType)
        );
      })
      .catch((error: any) => {
        //console.log("GOT BUYER ERROR", error);
      });
  }, [listItem]);

  return (
    <>
      {contextHolder}
      {true ? (
        <Card
          hoverable
          style={{ width: "100%" }}
          className={`rfq-card-${listItem.id} xcards pt-0 pb-0 pl-0 pr-0`}
          cover={<Image src={thumbURL} />}
        >
          <Meta
            title={
              <>
                <Flex justify={"space-between"} align={"center"}>
                  <span onClick={toggleVisibility}>
                    {listItem.productName.length > 42
                      ? listItem.productName.slice(0, 40) + "..."
                      : listItem.productName}
                  </span>
                  <span className="small-more">
                    <small>
                      <a onClick={toggleVisibility}>
                        {isVisible ? "Show less" : "View more"}
                      </a>
                    </small>
                  </span>
                </Flex>
              </>
            }
            description={
              <>
                <div className="both-ends">
                  <span>{formatTime(listItem.createdAt)}</span>
                  <div className="card-action-btns">
                    <Space direction="horizontal" size={0}>
                      <Button type="text" onClick={doLike}>
                        <HeartOutlined />
                      </Button>

                      <Button
                        className="hide-in-desktop"
                        type="text"
                        onClick={doShare}
                      >
                        <ShareAltOutlined />
                      </Button>

                      <Dropdown
                        className="hide-in-mobile"
                        menu={{ items: itemsShare }}
                      >
                        <Button type="text">
                          <ShareAltOutlined />
                        </Button>
                      </Dropdown>

                      <Button
                        className="hide-in-desktop"
                        type="text"
                        onClick={doMore}
                      >
                        <MoreOutlined />
                      </Button>

                      <Dropdown
                        className="hide-in-mobile"
                        menu={{ items: itemsMore }}
                      >
                        <Button type="text">
                          <MoreOutlined />
                        </Button>
                      </Dropdown>
                    </Space>
                  </div>
                </div>
                {isVisible && (
                  <div>
                    <Divider orientation="center" />
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <strong>RFQ Number:</strong> #1
                        {listItem.id.toString().padStart(4, "0")}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <strong>Category:</strong> {listItem.category.name}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <strong>Buyer: </strong> {listItem.contactName}
                      </Col>
                    </Row>
                    <Divider orientation="center" />
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <p className="normal-text mb-3">
                          {listItem.productDetails.length > 600
                            ? isFullyVisible
                              ? listItem.productDetails
                              : listItem.productDetails.slice(0, 600) + "..."
                            : listItem.productDetails}
                          {listItem.productDetails.length > 600 &&
                            !isFullyVisible && (
                              <a
                                onClick={() => {
                                  toggleFullVisibility();
                                }}
                              >
                                view more &raquo;
                              </a>
                            )}
                        </p>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button type="primary" onClick={doAction}>
                          Respond to RFQ
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            }
          />
        </Card>
      ) : (
        <></>
      )}
      <div className="more-options">
        <Drawer
          title="More options"
          placement="bottom"
          width={500}
          onClose={onClose}
          open={open}
        >
          <Menu
            style={{ width: "100%" }}
            mode="vertical"
            items={itemsMore}
            getPopupContainer={function test(node) {
              return node.parentNode as HTMLElement;
            }}
          />
        </Drawer>
      </div>
      <div>
        <Modal
          title="RFQ Details"
          centered
          className={`rfq-details-modal ${isMobile ? "mobile" : "desktop"}`}
          open={modal2Open}
          onCancel={() => setModal2Open(false)}
          footer={
            <>
              <Divider orientation="center" className="mt-0" />
              <Flex justify={"flex-end"} align={"flex-end"}>
                <Button
                  size="large"
                  type="default"
                  onClick={() => setModal2Open(false)}
                >
                  Close
                </Button>
                <Button size="large" type="primary" onClick={doAction}>
                  Respond to RFQ
                </Button>
              </Flex>
            </>
          }
        >
          <Divider orientation="center" className="mt-0 mb-0" />
          <Row
            className="rfq-modal-container"
            gutter={16}
            style={{ width: "100%" }}
          >
            <Col flex={"auto"}>
              <Space direction="vertical" size={25} style={{ width: "100%" }}>
                <Row style={{ width: "100% !important" }}>
                  <Col span={24}>
                    <h3 className="rfq-title mt-0 mb-3">
                      <a href={`${rfqLink}&rfq-id=${listItem.id}`}>
                        <strong>
                          {listItem.productName.length > 42
                            ? listItem.productName.slice(0, 40) + "..."
                            : listItem.productName}
                        </strong>
                      </a>
                      <span className="rfq-status-pill">New</span>
                    </h3>
                  </Col>
                  <Col span={24}>
                    <Space
                      direction="vertical"
                      size={25}
                      style={{ width: "100%" }}
                    >
                      <Row gutter={10}>
                        <Col span={10}>
                          <span>
                            <strong>RFQ Number:</strong>
                          </span>
                        </Col>
                        <Col span={14}>
                          <span>
                            #1{listItem.id.toString().padStart(4, "0")}
                          </span>
                        </Col>
                      </Row>

                      <Row gutter={10}>
                        <Col span={10}>
                          <span>
                            <strong>Posted:</strong>
                          </span>
                        </Col>
                        <Col span={14}>
                          <span>{formatTime(listItem.createdAt)}</span>
                        </Col>
                      </Row>

                      <Row gutter={10}>
                        <Col span={10}>
                          <span>
                            <strong>Budget:</strong>
                          </span>
                        </Col>
                        <Col span={14}>
                          <span>USD 4300.00</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          className="rfq-data-description"
                        >
                          <Divider orientation="center" />
                          <p className="normal-text mb-3">
                            <h5>
                              <strong>Description</strong>
                            </h5>
                            {listItem.productDetails}
                          </p>
                          <p className="mt-4 mb-1">
                            <h5>
                              <strong>Category</strong>
                            </h5>
                          </p>
                          <p className="category-pills">
                            <span>{listItem.category.name}</span>
                          </p>
                          {listItem.attachments.length > 0 && (
                            <p className="mt-4 normal-text mt-3 mb-3">
                              <h5>
                                <strong>Attachments</strong>
                              </h5>
                              <a>
                                <span>
                                  <img
                                    src="https://thenounproject.com/api/private/icons/4489052/edit/?backgroundShape=SQUARE&backgroundShapeColor=%23000000&backgroundShapeOpacity=0&exportSize=752&flipX=false&flipY=false&foregroundColor=%23000000&foregroundOpacity=1&imageFormat=png&rotation=0"
                                    width="24"
                                  />
                                </span>{" "}
                                {listItem.attachments.length} attachment file
                                {listItem.attachments.length > 1 && <>s</>}
                              </a>
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                </Row>
              </Space>
            </Col>
            <Col flex={"320px"}>
              <Space direction="vertical" size={10} style={{ width: "100%" }}>

                <Row gutter={16}>
                  <Col span={12}>
                    <Dropdown menu={{ items: itemsMore }}>
                      <Button type="primary" block>
                        <Space>
                          RFQ Actions
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <Button type="default" block onClick={()=>setIsLiked(!isLiked)}>
                    <div className={`heart ${isLiked ? "is-active":""}`}></div> <span className="ml-4">10K Likes</span>
                      </Button>
                  </Col>
                </Row>
                

                <Card className="xcards mt-0 pt-1" style={{ width: "100%" }}>
                  <Space
                    direction="vertical"
                    size={25}
                    style={{ width: "100%" }}
                  >
                    <Flex justify="flex-start" align="center" gap="middle">
                      <Avatar size={48} src={buyerAvatar} />
                      <Row>
                        <Col span={24}>
                          <Title level={5} className="mb-0">
                            {buyerFullName}
                          </Title>
                        </Col>
                        <Col span={24}>
                          <span className="buyer-designation-title">
                            {buyerDesignation}
                          </span>
                        </Col>
                      </Row>
                    </Flex>
                    <Row gutter={10}>
                      <Col span={24}>
                        <Button
                          block
                          type="primary"
                          size="large"
                          className="btn-primary-sz"
                        >
                          <MailOutlined />
                          &nbsp;&nbsp;&nbsp;Contact Buyer
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </Card>

                <Card className="xcards mt-0 pt-1" style={{ width: "100%" }}>
                  <Space
                    direction="vertical"
                    size={25}
                    style={{ width: "100%" }}
                  >
                    <Flex justify="flex-start" align="center" gap="middle">
                      <Avatar size={48} src={buyerCompanyLogo} />
                      <Row>
                        <Col span={24}>
                          <Title level={4} className="mb-0">
                            {buyerCompanyName}
                          </Title>
                        </Col>
                        <Col span={24}>
                          <span className="buyer-designation-title">
                            {buyerCompanyBusinessType}
                          </span>
                        </Col>
                      </Row>
                    </Flex>
                    <Row gutter={10}>
                      <Col span={24}>
                        <Button
                          block
                          type="primary"
                          size="large"
                          className="btn-primary-sz"
                        >
                          <MailOutlined />
                          &nbsp;&nbsp;&nbsp;Contact Buyer
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          block
                          type="default"
                          size="large"
                          className="mt-2 btn-default-sz"
                        >
                          <CommentOutlined />
                          &nbsp;&nbsp;&nbsp;Chat With Buyer
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </Card>
              </Space>
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  );
};

export default RFQAdvancedListViewCard;
