import { GiftOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Button, Flex, Space, Tooltip } from "antd";
import React from "react";
import { MdInfoOutline } from "react-icons/md";
const EarningPointsCard: any = ({
  title,
  points,
  description,
  btnLabel,
  btnUrl,
  btnInfo,
  cardLabel,
}: any) => {
  const navigate: any = () => {
    window.location.href = btnUrl;
    console.log("NAVIGATE::", btnUrl);
  };

  return (
    <>
      <div className="xcards">
        <Space direction="vertical" className="w-100">
          <Flex justify={"space-between"} align={"center"}>
            <strong>
              {title}{" "}
              {btnInfo.length > 0 && (
                <Tooltip title={btnInfo}>
                  <MdInfoOutline className="info-icon" />
                </Tooltip>
              )}
            </strong>
            <Button
              className="orange-grad-btn"
              type="default"
              size={"small"}
              style={{ width: 100 }}
            >
              <GiftOutlined /> {points}
            </Button>
          </Flex>
          <p>{description}</p>
          <Button
            className="orange-grad-btn"
            type="primary"
            style={{ textTransform: "capitalize" }}
            onClick={navigate}
          >
            {btnLabel}
          </Button>
        </Space>
      </div>
    </>
  );
};

export default EarningPointsCard;
