import { Col, Row } from "antd";
import React, { FunctionComponent, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import "chart.js/auto";

  import { Bar } from 'react-chartjs-2';
interface DocumentListItemProps {
    children?: React.ReactNode;
    data: any;
    title:string
}

const BarChart: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        data,
        title
    }: any = props;

    const options = {
        indexAxis: 'y' as const,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right' as const,
          },
          title: {
            display: true,
            text: title,
          },
        },
      };

    return <Bar options={options} data={data} />;
};

export default BarChart;
