import * as React from "react";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import ProductSimpleAside from "./Widgets/ProductSimpleAside";
import Snippets from "../../../../helpers/Snippets";
import { useParams } from "react-router-dom";
import { useAppDataContext } from "../../../../data";
import { Button, Dropdown, MenuProps, Space, Tag, Tooltip } from "antd";
import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  ShareAltOutlined,
  SlackOutlined,
  TeamOutlined,
  TwitterOutlined,
  UserAddOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import "./chat-with-supplier.scss";
import ChatWithSeller from "./ChatWithSeller";
import { useLocalStorage } from "usehooks-ts";
import { Link } from "react-router-dom";

export default ({ supplier, onSetProductPreviewHandler }: any) => {
  let { openChat } = useParams();

  const {
    isLoggedIn,
    userType: profileType,
    userData,
    setIsLoginModalOpen,
  } = useAppDataContext();

  const [supplierProducts, setSupplierProducts] = React.useState([]);
  const [showChatWindow, setShowChatWindow] = React.useState(false);
  const [openChatParam, setOpenChatParam] = React.useState(openChat);

  const [] = React.useState(false);
  const [] = React.useState(openChat);
  const [] = React.useState({
    id: 0,
    profileType: "",
    profile: {
      profileType: "",
    },
  });

  const closeChatWindow = () => {
    setShowChatWindow(false);
  };
  React.useEffect(() => {
    AxiosAPI.getSupplierProducts(supplier.id, 7)
      .then(({ data: _products }) => {
        //console.warn(":::ASIDE SUPPLIER PRODUCTS:::", supplier.id, _products);
        setSupplierProducts(_products);
      })
      .catch((error) => {
        //console.warn(":::ASIDE SUPPLIER PRODUCTS ERROR:::", supplier.id, error);
      });
  }, [supplier]);

  const openModal = () => {
    window.document.getElementById("contact-form").click();
    Snippets.events.publish("open-modal", {
      isLoggedIn: isLoggedIn,
      profileType: profileType,
    });
  };

  const getBadgeClass = (badge: string) => {
    switch (badge) {
      case "Inspected":
        return "inspected";

      case "Top 20%":
        return "top20";

      case "Top 10%":
        return "top10";

      case "Top 5%":
        return "top5";

      default:
        return "self-registered";
    }
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    if(!phoneNumber) return '';
    const formattedPhoneNumber = phoneNumber.replace(/^0/, "");
    return "+263" + formattedPhoneNumber;
  };

  const [companyProfilelink, setCompanyProfileLink] = React.useState("");
  const [profileFollowed, setProfileFollowed] = React.useState(false);
  React.useEffect(() => {
    let _token: string = Snippets.user.getCurrentUserAccessToken();
    let instance: string = Snippets.urls.getEnv().isDev ? "dev" : "commercial";
    setCompanyProfileLink(`/supplier/${supplier.id}`);

    fetchIsLikedAndFollowed();
  }, [supplier]);

  const fetchIsLikedAndFollowed = () => {
    if (isLoggedIn) {
      AxiosAPI.publicApiRequest(
        "GET",
        `/product-statistics/profile-followed?profileId=${supplier.id}&appUserId=${userData.id}`,
        null
      ).then(({ data: response }) => {
        setProfileFollowed(response.success);
      });
    }
  };
  const followProfile = () => {
    if (supplier.id && isLoggedIn) {
      AxiosAPI.publicApiRequest("POST", `/product-statistics/follow-profile`, {
        appUserId: userData.id,
        profileId: supplier.id,
      }).then(({ data: response }) => {
        setProfileFollowed(response.success);
      });
    } else {
      setIsLoginModalOpen(true);
    }
  };
  const itemsPhone: MenuProps["items"] = [
    {
      key: "0",
      icon: <PhoneOutlined />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={"tel:+263772128622"}>
          Phone: +263 772 123 456
        </a>
      ),
    },
    {
      key: "1",
      icon: <PhoneOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://dialer.supplyzone.ai/?phoneNumber=${formatPhoneNumber(
            supplier.primaryContact.phoneNumber
          )}`}
        >
          Voip: {formatPhoneNumber(supplier.primaryContact.phoneNumber)}
        </a>
      ),
    },
    {
      key: "2",
      icon: <WhatsAppOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"https://wa.me/+263772128622?text=Test"}
        >
          WhatsApp: +263 772 123 456
        </a>
      ),
    },
    {
      key: "3",
      icon: <MailOutlined />,
      label: (
        <a onClick={openModal} href={`#contact-form`}>
          Email: {supplier.primaryContact.emailAddress}
        </a>
      ),
    },
  ];
  const supplierNameToolTip: MenuProps["items"] = [
    {
      key: "0",
      label: supplier.name,
    },
  ];

  return (
    <>
      <aside
        className="sidebar product-sidebar sidebar-fixed right-sidebar sticky-sidebar-wrapper"
        id="product-sidebar"
      >
        <div>
          <div className="sidebar-overlay"></div>
          <a className="sidebar-close" href="#">
            <i className="close-icon"></i>
          </a>
          <a href="#" className="sidebar-toggle d-flex d-lg-none">
            <i className="fas fa-chevron-left"></i>
          </a>
          <div className="sidebar-content scrollable pr-3 pl-3 pt-3 pb-3 widget widget-products xcards w-100">
            <div className="icon-box icon-box-side">
              <div className="icon-box-content" style={{ width: "100%" }}>
                <h4 className="icon-box-title">
                  <div className="d-flex">
                    <img
                      style={{
                        objectFit: "cover",
                        width: "48px",
                        height: "48px",
                        borderRadius: "50px",
                      }}
                      src={
                        "primaryContactAvatar" in supplier &&
                          supplier.primaryContactAvatar
                          ? "https://api-live.supplyzone.ai/home/download-attachment/" +
                          supplier.primaryContactAvatar
                          : "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
                      }
                    />
                    <div style={{ marginLeft: "18px" }}>
                      <strong>
                        {supplier.primaryContactPerson.firstName}{" "}
                        {supplier.primaryContactPerson.lastName}
                      </strong>
                      <br />
                      <span style={{ fontSize: "12px" }}>
                        {supplier.primaryContactPerson.designation ?? ""}
                      </span>
                    </div>
                  </div>
                </h4>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <Dropdown menu={{ items: itemsPhone }} arrow>
                      <button
                        className="btn btn-primary pl-3 pr-3"
                        style={{
                          padding: "0.73em 1.98em",
                          width: "100%",
                          textTransform: "capitalize",
                          borderRadius: "10px",
                        }}
                      >
                        <i className="w-icon-envelop-closed mr-1"></i>
                        <span> Contact Supplier</span>
                      </button>
                    </Dropdown>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <button
                      className={`btn btn-primary pl-3 pr-3 bottom ${isLoggedIn && profileType === "BUYER"
                          ? ""
                          : "chat-tooltip"
                        }`}
                      style={{
                        padding: "0.73em 1.98em",
                        width: "100%",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        color: "#552278",
                        backgroundColor: "#fff",
                      }}
                      onClick={() => setShowChatWindow(true)}
                    >
                      <i className="w-icon-comment mr-1"></i>
                      <span> Chat With Supplier</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sidebar-content scrollable pr-3 pl-3 pt-3 pb-3 widget widget-products xcards w-100 mt-4">
            <div className="icon-box icon-box-side">
              <div className="icon-box-content">
                <h4 className="icon-box-title">
                  <div>
                    <img
                      alt=""
                      src={`https://api-live.supplyzone.ai/marketplace/supplier/${supplier.id}/document/LOGO`}
                      style={{ width: "48px" }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png";
                      }}
                    />
                    <Dropdown menu={{ items: supplierNameToolTip }} arrow>
                      <strong>
                        &nbsp;&nbsp;&nbsp;
                        {supplier.name.length > 15
                          ? supplier.name.substring(0, 10) + "..."
                          : supplier.name}
                      </strong>
                    </Dropdown>
                  </div>
                </h4>
                <div className="row">
                  <p className="profile mt-0 d-none">
                    <div className="popover__wrapper">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <div
                        className={`${getBadgeClass(
                          supplier.credentialsBadge
                        )} tooltipx bottom`}
                      >
                        <a>
                          <Tag key="supplier-credential-badge">
                            {supplier.credentialsBadge
                              ? supplier.credentialsBadge
                              : "Self-registered"}
                          </Tag>
                        </a>
                      </div>
                    </div>

                    <img
                      src="https://cdn.countryflags.com/thumbs/zimbabwe/flag-button-round-250.png"
                      style={{ width: "28px" }}
                    />

                    <span className="badge">
                      <strong>{supplier.years}</strong> Years
                    </span>
                    <Button
                      className="follow-seller-btn follow-seller-btn2"
                      type="default"
                      icon={
                        profileFollowed ? <TeamOutlined /> : <UserAddOutlined />
                      }
                      size="small"
                      onClick={followProfile}
                    >
                      {profileFollowed ? "Following" : "Follow"}
                    </Button>
                  </p>
                </div>
                <div className="row mt-3">
                  <p className="">
                    <strong>Average Response Time:</strong>{" "}
                    <span className="text-greenx">{supplier.response}</span>
                  </p>
                  {supplier.businessType && (
                    <p className="mt-2">
                      <strong>Business Type: </strong>
                      {supplier.businessType
                        .split(",")
                        .map((tag: any, tagIndex: number) => (
                          <>
                            {tag !== "" && (
                              <Tag
                                key={tagIndex}
                                className="mt-1 solid-tags"
                                style={{ textTransform: "capitalize" }}
                              >
                                {tag.replace("_", " ").toLowerCase()}
                              </Tag>
                            )}
                          </>
                        ))}
                    </p>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <Link
                      to={companyProfilelink}
                      className="btn btn-primary pl-3 pr-3"
                      style={{
                        color: "#552278",
                        background: "transparent",
                        padding: "0.73em 1.98em",
                        width: "100%",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                      }}
                    >
                      <i className="w-icon-search mr-1"></i>
                      <span> View Company Profile</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sidebar-content scrollable pr-3 pl-3 pt-3 pb-3 widget widget-products xcards w-100 mt-4">
            <div className="title-link-wrapper mb-2">
              <h4 className="title title-link font-weight-bold">
                You may also like
              </h4>
            </div>

            <div className="swiper nav-top">
              <div
                className="swiper-container swiper-theme nav-top"
                data-swiper-options="{ 'slidesPerView': 1, 'spaceBetween': 20, 'navigation': { 'prevEl': '.swiper-button-prev', 'nextEl': '.swiper-button-next' } }"
              >
                <div className="swiper-wrapper">
                  <div className="widget-col swiper-slide">
                    {supplierProducts.map((product, productIndex) => (
                      <ProductSimpleAside
                        onSetProductPreview={onSetProductPreviewHandler}
                        product={product}
                        key={productIndex}
                      />
                    ))}
                  </div>
                </div>
                <button className="swiper-button-next"></button>
                <button className="swiper-button-prev"></button>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <ChatWithSeller
        showChatWindow={showChatWindow}
        supplier={supplier}
        openChatWindow={closeChatWindow}
        isLoggedIn={isLoggedIn}
        profileType={profileType}
      ></ChatWithSeller>
    </>
  );
};
