/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  ChangeEvent,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Userpilot } from "userpilot";
import Snippets from "../../../../helpers/Snippets";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { Cascader, Col, ConfigProvider, Modal, Progress, Row, Switch, Upload, UploadProps, message } from "antd";
import { FileUploader } from "react-drag-drop-files";
import { DefaultEditor } from "react-simple-wysiwyg/lib/index.cjs";
import "./product-create.scss";
import { UserPilotEvents, UserPilotHelper } from "../../../../helpers/UserPilotHelper";
import { DefaultOptionType } from "antd/es/select";
import { ExclamationCircleFilled, LoadingOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import sanitizeHtml from "sanitize-html"
interface DocumentListItemProps {
  children?: React.ReactNode;
}

const ProductCreatePage: FC<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  // eslint-disable-next-line no-empty-pattern
  const { }: any = props;

  // categories drop down
  interface Option {
    value: string | number;
    label: string;
    children?: Option[];
  }
  const [categoryList, setCategoryList] = useState<Option[]>([]);
  const onChange = (value: string[]) => {
    if (value) {
      const catId = value[value.length - 1];
      let selectedCat;
      for (let i = 0; i < productCategoryOptions.length; i++) {
        if (productCategoryOptions[i].id == catId) {
          selectedCat = productCategoryOptions[i];
        }
      }
      setProductCategoryOptionsEvent(selectedCat);
    }
  };

  const [messageApi, contextHolder] = message.useMessage();
  // @ts-ignore
  let { product_id } = useParams();

  const navigate = useNavigate();

  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

  const fileTypePDF = ["PDF"];

  const onEditorChange = (e: { target: { value: SetStateAction<string> } }) => {
    set_shortDescription(e.target.value);
  };

  const onProductWarrantyEditorChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    set_productWarranty(e.target.value);
  };

  const [productId, set_productId] = useState("");
  const [productName, set_productName] = useState("");
  const [optimisationKeywords, set_optimisationKeywords] = useState("");
  const [modelNumber, set_modelNumber] = useState("");
  const [description, set_description] = useState("");
  const [shortDescription, set_shortDescription] = useState("");
  const [productWarranty, set_productWarranty] = useState("");
  const [productFileAttachments, set_productFileAttachments] = useState([
    { id: 0, data: null, attachmentType: "IMAGE", s3Key: "string" },
  ]);
  const [productVideoURL, set_productVideoURL] = useState("");
  const [productGroup, set_productGroup] = useState("");
  const [productCertification, set_productCertification] = useState([]);
  const [technicalCertification, set_technicalCertification] = useState([]);
  const [minimumOrderQuantity, set_minimumOrderQuantity] = useState("");
  const [unitOfMeasurement, set_unitOfMeasurement] = useState("");
  const [fobPort, set_fobPort] = useState("");
  const [fobPrice, set_fobPrice] = useState("");
  const [enableDirectOrder, set_enableDirectOrder] = useState(false);
  const [receivePaymentSettings, set_receivePaymentSettings] = useState(false);
  const [orderLeadTimeRangeFrom, set_orderLeadTimeRangeFrom] = useState("");
  const [orderLeadTimeRangeTo, set_orderLeadTimeRangeTo] = useState("");
  const [paymentMethod, set_paymentMethod] = useState("CASH");
  const [sampleAvailability, set_sampleAvailability] = useState(false);
  const [areSmallOrdersAccepted, set_areSmallOrdersAccepted] = useState(true);
  const [displayPrice, setDisplayPrice] = useState(true)
  const [dimensionPerUnit, set_dimensionPerUnit] = useState({
    length: 0,
    width: 0,
    height: 0,
    unitOfMeasurement: "KG",
  });
  const [weightPerUnit, set_weightPerUnit] = useState({
    size: 0,
    unitOfMeasurement: "KG",
  });
  const [exportCartonDimension, set_exportCartonDimension] = useState({
    length: 0,
    width: 0,
    height: 0,
    unitOfMeasurement: "KG",
  });
  const [exportCartonUnits, set_exportCartonUnits] = useState("");
  const [exportCartonWeight, set_exportCartonWeight] = useState({
    size: 0,
    unitOfMeasurement: "KG",
  });
  const [usHtsCode, set_usHtsCode] = useState("");
  const [brandName, set_brandName] = useState("");
  const [szId, set_szId] = useState("");
  const [otherBrandName, set_otherBrandName] = useState("");
  const [origin, set_origin] = useState("");
  const [exportMarket, set_exportMarket] = useState("");
  const [category, set_category] = useState({
    id: 0,
    value: 0,
    label: "",
    name: "",
    parent: "string",
    children: ["string"],
    canHaveProducts: true,
  });
  const [status, set_status] = useState("DRAFT");
  const [pricingOption, set_pricingOption] = useState("LADDER_PRICE");
  const [ladderPriceOption, set_ladderPriceOption] = useState("EX_STOCK");
  const [productPricesRangeFrom, set_productPricesRangeFrom] = useState(0);
  const [productPricesRangeTo, set_productPricesRangeTo] = useState(0);
  const [productFixedPrice, set_productFixedPrice] = useState("");
  const [productPrices, set_productPrices] = useState([
    { id: 0, minimumOrderQuantity: 0, maximumOrderQuantity: 0, price: 0 },
  ]);
  const [declaration, set_declaration] = useState(false);

  const [imgPrimaryImage, setImgPrimaryImage] = useState<any>("NULL");
  const [imgPrimaryImageUploadInProgress, setImgPrimaryImageUploadInProgress] =
    useState<boolean>(false);
  const [imgPrimaryImageUploadCompleted, setImgPrimaryImageUploadCompleted] =
    useState<boolean>(false);
  const [imgPrimaryImageUploadPercentage, setImgPrimaryImageUploadPercentage] =
    useState<number>(0);
  const [imagePrimaryImageS3Key, setImagePrimaryImageS3Key] = useState("");

  const [imgGalleryImages, setImgGalleryImages] = useState<any>([]);
  const [imgGalleryImageUploadInProgress, setImgGalleryImageUploadInProgress] =
    useState<boolean>(false);
  const [imgGalleryImageUploadCompleted, setImgGalleryImageUploadCompleted] =
    useState<boolean>(false);
  const [imgGalleryImageUploadPercentage, setImgGalleryImageUploadPercentage] =
    useState<number>(0);
  const [recentlyUploadedImages, setRecentlyUploadedImages] = useState([]);

  const removeGalleryItem: any = (indexKey: number) => {
    const data = {
      productId: productId,
      imageIndex: indexKey,
    };
    setImgPrimaryImageUploadInProgress(true);
    setImgPrimaryImageUploadCompleted(false);
    setSavingProduct(true);
    var config = {
      method: "post",
      url: Snippets.urls.getAPIBaseURL() + "/supplier/delete-attachment",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(() => {
        setProductImage(indexKey, "");
        setImagesPlaceHolderArr([1, 2, 3, 4, 5, 6, 7]);
        setImgPrimaryImageUploadInProgress(false);
        setImgPrimaryImageUploadCompleted(true);
        setSavingProduct(false);
      })
      .catch((error: any) => {
        setImgPrimaryImageUploadInProgress(false);
        setImgPrimaryImageUploadCompleted(true);
        setSavingProduct(false);
      });
  };

  const setLadderPriceOption: any = (option: string) => {
    set_ladderPriceOption(option);
    if (option == "IN_STOCK") {
      set_orderLeadTimeRangeFrom("0");
      set_orderLeadTimeRangeTo("0");
    }
  };

  /* attributes */
  const [attribute1, setAttribute1] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute2, setAttribute2] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute3, setAttribute3] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute4, setAttribute4] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute5, setAttribute5] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute6, setAttribute6] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute7, setAttribute7] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute8, setAttribute8] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute9, setAttribute9] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });
  const [attribute10, setAttribute10] = useState({
    id: 0,
    name: "",
    value: "",
    options: [],
  });

  const [categoryBrandsList, setCategoryBrandsList] = useState([]);

  function formatFileSize(size: number) {
    var sizes = [
      " Bytes",
      " KB",
      " MB",
      " GB",
      " TB",
      " PB",
      " EB",
      " ZB",
      " YB",
    ];
    for (var i = 1; i < sizes.length; i++) {
      if (size < Math.pow(1024, i))
        return (
          Math.round((size / Math.pow(1024, i - 1)) * 100) / 100 + sizes[i - 1]
        );
    }
    return size;
  }

  const [productCertificate, setProductCertificate] = useState<any>({});

  const [
    productCertificateUploadInProgress,
    setProductCertificateUploadInProgress,
  ] = useState<boolean>(false);
  const [
    productCertificateUploadCompleted,
    setProductCertificateUploadCompleted,
  ] = useState<boolean>(false);
  const [
    productCertificateUploadPercentage,
    setProductCertificateUploadPercentage,
  ] = useState<any>(0);

  const uploadProductCertificate = (_file: any) => {
    //console.log("Product Certificate:", _file);

    setProductCertificate({
      name: _file.name,
      type: _file.type,
      size: formatFileSize(_file.size),
      date: _file.lastModifiedDate.toLocaleDateString().slice(0, 15),
    });

    const reader = new FileReader();

    reader.addEventListener(
      "progress",
      (e) => {
        setProductCertificateUploadInProgress(true);
        setProductCertificateUploadCompleted(false);
        let percentage: number = (e.loaded / e.total) * 100;
        setProductCertificateUploadPercentage(percentage);
      },
      false
    );

    reader.addEventListener(
      "load",
      () => {
        setProductCertificateUploadInProgress(false);
        setProductCertificateUploadCompleted(true);
        const _uploadedFile: any = {
          isPrimary: false,
          indexKey: "PRODUCT_CERTIFICATE",
          id: productId,
          data: reader.result,
          attachmentType: "PRODUCT_CERTIFICATE",
          meta: {
            type: _file.type,
            size: _file.size,
            name: _file.name,
            lastModified: _file.lastModified,
          },
          s3Key: "string",
        };
        set_productCertification(_uploadedFile);
        saveProductAttachment(_uploadedFile, "PRODUCT_CERTIFICATE", true);
      },
      false
    );

    try {
      //console.log(":::FILE READ::: PRODUCT_CERTIFICATE", _file);

      reader.readAsDataURL(_file);
    } catch (e) {
      //console.warn(":::Error reading file PRODUCT_CERTIFICATE:::", e);
    }
  };

  const [technicalDatasheet, setTechnicalDatasheet] = useState<any>({});

  const [
    technicalDatasheetUploadInProgress,
    setTechnicalDatasheetUploadInProgress,
  ] = useState<boolean>(false);
  const [
    technicalDatasheetUploadCompleted,
    setTechnicalDatasheetUploadCompleted,
  ] = useState<boolean>(false);
  const [
    technicalDatasheetUploadPercentage,
    setTechnicalDatasheetUploadPercentage,
  ] = useState<any>(0);

  const uploadProductTechnicalDatasheet = (_file: any) => {
    //console.log("Product Data Sheet:", _file);

    setTechnicalDatasheet({
      name: _file.name,
      type: _file.type,
      size: formatFileSize(_file.size),
      date: _file.lastModifiedDate.toLocaleDateString().slice(0, 15),
    });

    const reader = new FileReader();

    reader.addEventListener(
      "progress",
      (e) => {
        setTechnicalDatasheetUploadInProgress(true);
        setTechnicalDatasheetUploadCompleted(false);
        let percentage: number = (e.loaded / e.total) * 100;
        setTechnicalDatasheetUploadPercentage(percentage);
      },
      false
    );

    reader.addEventListener(
      "load",
      () => {
        setTechnicalDatasheetUploadInProgress(false);
        setTechnicalDatasheetUploadCompleted(true);
        const _uploadedFile: any = {
          isPrimary: false,
          indexKey: "TECHNICAL_SPECIFICATION_SHEET",
          id: productId,
          data: reader.result,
          attachmentType: "TECHNICAL_SPECIFICATION_SHEET",
          meta: {
            type: _file.type,
            size: _file.size,
            name: _file.name,
            lastModified: _file.lastModified,
          },
          s3Key: "string",
        };
        set_technicalCertification(_uploadedFile);
        saveProductAttachment(
          _uploadedFile,
          "TECHNICAL_SPECIFICATION_SHEET",
          true
        );
      },
      false
    );

    try {
      //console.log(":::FILE READ TECHNICAL_SPECIFICATION_SHEET:::", _file);

      reader.readAsDataURL(_file);
    } catch (e) {
      //console.warn(":::Error reading file TECHNICAL_SPECIFICATION_SHEET:::", e);
    }
  };

  const [savingProduct, setSavingProduct] = useState<boolean>(false);

  const [savedProduct, setSavedProduct] = useState<any>({});

  const saveProduct = () => {
    const _product: any = generateProductPayload();

    if (category.id == 0) {
      alert("Select product category");
      setSavingProduct(false);
      return false;
    }
    if (category.canHaveProducts === false) {
      alert(
        "You can not save a product at Main category level. Please select a Sub-category."
      );
      setSavingProduct(false);
      return false;
    }
    if (description == "" || shortDescription == "") {
      alert("Product description is required");
      setSavingProduct(false);
      return false;
    }
    if (optimisationKeywords == "") {
      alert("Optimisation keywords are required");
      setSavingProduct(false);
      return false;
    }
    if (_product.name == "") {
      alert("Product name is required");
      setSavingProduct(false);
      return false;
    }
    if (productImagesList.length < 2) {
      alert("You need to attach the primary image");
      setSavingProduct(false);
      return false;
    }

    if (unitOfMeasurement == "") {
      alert("Select Minimum Order Quantity Unit of measurement.");
      setSavingProduct(false);
      return false;
    }

    setSavingProduct(true);
    const key = "updatable";

    setTimeout(() => {
      setSavingProduct(false);
      const res = postProduct(_product)
        .then(function (response: { data: any }) {
          const _savedProduct = response.data;

          if (_savedProduct.hasOwnProperty("name")) {
            set_productId(_savedProduct.id);

            setSavedProduct(_savedProduct);



            UserPilotHelper.logEvent(UserPilotEvents.SAVE_PRODUCT, {
              id: _savedProduct.id,
              name: _savedProduct.name,
            });

            messageApi.open({
              type: 'success',
              content: `${_savedProduct.name} has been saved successfully`,
            }).then(function () {
              setTimeout(() => {
                navigate("/products/manage");
              }, 2000)
            });
          }
        })
        .catch(function (error: any) {
          //console.log(22222777, error); // 401
        });
    }, 1000);
  };

  const saveProductAsDraft: Function = () => {
    const _product: any = generateProductPayload();

    if (parseInt(_product.id) > 0) return;

    if (category.id === 0 || _product.name === "") return;

    const draftProduct = {
      categoryId: category.id,
      productName: _product.name,
    };

    var config = {
      method: "post",
      url: Snippets.urls.getAPIBaseURL() + "/supplier/products",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: draftProduct,
    };

    axios(config)
      .then(function (response: any) {
        const _savedProduct = response.data;

        if (_savedProduct.hasOwnProperty("name")) {
          set_productId(_savedProduct.id);
          setSavedProduct(_savedProduct);

          //console.log(_savedProduct.name + " has been saved as draft successfully");

        }
      })
      .catch(function (error: any) {
        //console.log("Draft Error::", error); // 401
      });
  };

  const saveProductAttachment: Function = (
    attachment: any,
    attachmentType: any
  ) => {
    setSavingProduct(true);
    const data = {
      name: attachment.meta.name,
      attachmentType: attachmentType,
      size: attachment.meta.size,
      type: attachment.meta.type,
      data: attachment.data,
      isPrimary: attachment.isPrimary,
      entityId: productId,
      imageIndex: attachment.imageIndex,
    };

    var config = {
      method: "post",
      url: Snippets.urls.getAPIBaseURL() + "/supplier/upload-attachment",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response: any) {
        setRecentlyUploadedImages([
          ...recentlyUploadedImages,
          attachment.meta.name,
        ]);
        recentlyUploadedImages.push(attachment.meta.name);
        //console.log(`Added array ${recentlyUploadedImages} `);

        setImgPrimaryImageUploadInProgress(false);
        setImgPrimaryImageUploadCompleted(true);
        setSavingProduct(false);
      })
      .catch(function (error: any) {
        //console.log("Draft Error:", error); // 401
        setImgPrimaryImageUploadInProgress(false);
        setImgPrimaryImageUploadCompleted(true);
        setSavingProduct(false);
      });
  };

  const postProduct: Function = async (product: any) => {
    const data = JSON.stringify(product);

    return AxiosAPI.saveProduct(data);
  };

  const [gaugePercentage, setGaugePercentage] = useState(0);

  const [segA, setSegA] = useState(0);
  const [segB, setSegB] = useState(0);
  const [segC, setSegC] = useState(0);
  const [segD, setSegD] = useState(0);

  const [imagesPlaceHolderArr, setImagesPlaceHolderArr] = useState([
    2, 3, 4, 5, 6, 7, 8,
  ]);
  const [productScoreTab, setProductScoreTabNumber] = useState(1);
  const imagesContainerStyle: any = {
    border: "1px dashed #e4e6ef",
    borderRadius: "0.475rem",
    textAlign: "center",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100px",
    cursor: "pointer",
  };

  const setProductScoreTab = (tab: number) => {
    //document.getElementById(`card-section-${tab}`).scrollIntoView();

    const y =
      document.getElementById(`card-section-${tab}`).getBoundingClientRect()
        .top + window.scrollY;
    window.scroll({
      top: y - 150,
      behavior: "smooth",
    });

    setProductScoreTabNumber(tab);
  };

  const [productCategoryOptions, setProductCategoryOptions] = useState<any>([]);

  const getCategories: Function = async () => {
    AxiosAPI.getCategories()
      .then(({ data: categories }) => {
        //console.log("CATEGORIES", categories);

        const cats: any[] = [];

        categories.map((cat: { name: any; id: any }) => {
          const _cat: any = cat;

          _cat.label = cat.name;
          _cat.value = cat.id;

          cats.push(_cat);
        });

        setProductCategoryOptions(cats);
      })
      .catch((error: any) => {
        //console.log("ERROR FETCHING CATEGORIES", error);
      });
  };

  const getCategoryList: Function = async () => {
    var config = {
      method: "get",
      url: Snippets.urls.getAPIBaseURL() + "/marketplace/category/0",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(({ data: categories }) => {
        setCategoryList(categories.children);
      })
      .catch((error: any) => { });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );

  const setProductCategoryOptionsEvent = (cat: {
    id: any;
    name: any;
    parntId: any;
    canHaveProducts: any;
  }) => {
    //console.warn("::: CAT :::", cat);

    set_category({
      id: cat.id,
      value: cat.id,
      label: cat.name,
      name: cat.name,
      parent: cat.parntId,
      children: [],
      canHaveProducts: cat.canHaveProducts,
    });
    fetchAttributes(cat.id, productId);
    fetchCategoryBrands(cat.id);
  };

  var productAttributes: any[];
  const productAttributeValue = (attributeId: any) => {
    if (productAttributes === undefined) return "";
    var value = "";
    productAttributes.forEach((attribute: { id: any; value: string }) => {
      //console.log(attribute);
      if (attribute.id == attributeId) value = attribute.value;
    });
    return value;
  };

  const fetchAttributes = (catId: string, productId: string) => {
    //lets fetch attributes
    setAttribute1({ id: 0, name: "", value: "", options: [] });
    setAttribute2({ id: 0, name: "", value: "", options: [] });
    setAttribute3({ id: 0, name: "", value: "", options: [] });
    setAttribute4({ id: 0, name: "", value: "", options: [] });
    setAttribute5({ id: 0, name: "", value: "", options: [] });
    setAttribute6({ id: 0, name: "", value: "", options: [] });
    setAttribute7({ id: 0, name: "", value: "", options: [] });
    setAttribute8({ id: 0, name: "", value: "", options: [] });
    setAttribute9({ id: 0, name: "", value: "", options: [] });
    setAttribute10({ id: 0, name: "", value: "", options: [] });
    var attributesConfig = {
      method: "get",
      url:
        Snippets.urls.getAPIBaseURL() +
        "/supplier/category/" +
        catId +
        "/attributes",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(attributesConfig)
      .then(({ data: attributes }) => {
        for (var x = 0; x <= 9; x++) {
          if (attributes[x]) {
            var y = x + 1;
            var _options = [];
            if (attributes[x].options && attributes[x].options.length > 0)
              _options = attributes[x].options.split(",");
            switch (y) {
              case 1:
                setAttribute1({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 2:
                setAttribute2({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                //console.log(attribute2);
                break;
              case 3:
                setAttribute3({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 4:
                setAttribute4({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 5:
                setAttribute5({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 6:
                setAttribute6({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 7:
                setAttribute7({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 8:
                setAttribute8({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 9:
                setAttribute9({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
              case 10:
                setAttribute10({
                  id: attributes[x].id,
                  name: attributes[x].name,
                  value: productAttributeValue(attributes[x].id),
                  options: _options,
                });
                break;
            }
          }
        }
      })
      .catch((error: any) => { });
  };

  const fetchCategoryBrands = (catId: string) => {
    setCategoryBrandsList(["Other"]);
    var brandsConfig = {
      method: "get",
      url:
        Snippets.urls.getAPIBaseURL() +
        "/super/category/" +
        catId +
        "/category-brand",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(brandsConfig)
      .then(({ data: brands }) => {
        let brandsList = ["Select"];
        brands.map((brand: { name: string }) => {
          brandsList.push(brand.name);
        });
        brandsList.push("Other");
        setCategoryBrandsList(brandsList);
      })
      .catch((error: any) => { });
  };
  const [productUOMs, setProductUOMs] = useState<any>([]);

  const getUOMs: Function = async () => {
    AxiosAPI.getUOMs()
      .then(({ data: UOMs }) => {
        //console.log("UOMs", UOMs);

        setProductUOMs(UOMs.filter((element: any, index: number, arr: any[]) => { return element != "UNITS"; }));
      })
      .catch((error: any) => {
        //console.log("ERROR FETCHING UOMs", error);
      });
  };

  const getProduct: Function = async (productId: any) => {
    AxiosAPI.getProduct(productId)
      .then(({ data: product }) => {
        //console.log("PRODUCT", product);

        set_productId(product.id);
        set_productName(product.name ? product.name : "");
        set_optimisationKeywords(
          product.optimisationKeywords ? product.optimisationKeywords : ""
        );
        set_modelNumber(product.modelNumber ? product.modelNumber : "");
        set_description(product.description ? product.description : "");
        set_shortDescription(
          product.shortDescription ? product.shortDescription : ""
        );
        set_productVideoURL(
          product.productVideoURL ? product.productVideoURL : ""
        );
        set_productGroup(product.productGroup ? product.productGroup : "");
        set_productCertification(
          product.productCertification ? product.productCertification : ""
        );
        set_technicalCertification(
          product.technicalCertification ? product.technicalCertification : ""
        );
        set_minimumOrderQuantity(
          product.minimumOrderQuantity ? product.minimumOrderQuantity : ""
        );
        set_unitOfMeasurement(
          product.unitOfMeasurement ? product.unitOfMeasurement : ""
        );
        set_fobPort(product.fobPort ? product.fobPort : "");
        set_fobPrice(product.fobPrice ? product.fobPrice : "");
        set_enableDirectOrder(
          product.enableDirectOrder ? product.enableDirectOrder : false
        );
        set_receivePaymentSettings(
          product.receivePaymentSettings
            ? product.receivePaymentSettings
            : false
        );
        set_orderLeadTimeRangeFrom(
          product.orderLeadTimeRangeFrom ? product.orderLeadTimeRangeFrom : ""
        );
        set_orderLeadTimeRangeTo(
          product.orderLeadTimeRangeTo ? product.orderLeadTimeRangeTo : ""
        );
        set_paymentMethod(product.paymentMethod ? product.paymentMethod : "CASH");
        set_sampleAvailability(
          product.sampleAvailability ? product.sampleAvailability : false
        );
        set_areSmallOrdersAccepted(
          product.areSmallOrdersAccepted ? product.areSmallOrdersAccepted : ""
        );
        set_dimensionPerUnit(
          product.dimensionPerUnit
            ? product.dimensionPerUnit
            : { length: 0, width: 0, height: 0, unitOfMeasurement: "KG" }
        );
        set_weightPerUnit(
          product.weightPerUnit
            ? product.weightPerUnit
            : { size: 0, unitOfMeasurement: "KG" }
        );
        set_exportCartonDimension(
          product.exportCartonDimension
            ? product.exportCartonDimension
            : { length: 0, width: 0, height: 0, unitOfMeasurement: "KG" }
        );
        set_exportCartonUnits(
          product.exportCartonUnits ? product.exportCartonUnits : ""
        );
        set_exportCartonWeight(
          product.exportCartonWeight
            ? product.exportCartonWeight
            : { size: 0, unitOfMeasurement: "KG" }
        );
        set_usHtsCode(product.usHtsCode ? product.usHtsCode : "");
        set_brandName(product.brandName ? product.brandName : "");
        set_szId(product.szId ? product.szId : "");
        set_origin(product.origin ? product.origin : "");
        set_exportMarket(product.exportMarket ? product.exportMarket : "");
        set_category(product.category ? product.category : "");
        set_status(product.status ? product.status : "DRAFT");
        set_pricingOption(product.pricingOption ? product.pricingOption : "");
        set_declaration(product.declaration ? product.declaration : "");
        set_pricingOption(
          product.pricingOption ? product.pricingOption : "LADDER_PRICE"
        );

        //set product prices
        //productPricesRangeFrom
        if (product.pricingOption === "FIXED") {
          set_productFixedPrice(product.productPrices[0].price);
        }
        if (product.pricingOption == "PRICE_RANGE") {
          set_productPricesRangeFrom(product.productPrices[0].price);
          set_productPricesRangeTo(product.productPrices[1].price);

          //console.log("product prices", product.productPrices);
        }

        if (parseInt(product.orderLeadTimeRangeFrom) == 0) {
          setLadderPriceOption("IN_STOCK");
          /*
          console.log(
            "Lead time from: ",
            parseInt(product.orderLeadTimeRangeFrom)
          );
          */
        }


        if( product.displayPrice === false ){
          setDisplayPrice(false);
        }

        if (product.pricingOption == "LADDER_PRICE") {
          const productPricesArray: any = [];

          product.productPrices.map(
            (
              item: {
                id: any;
                maximumOrderQuantity: any;
                minimumOrderQuantity: any;
                price: any;
              },
              index: number
            ) => {
              productPricesArray.push({
                id: item.id,
                maximumOrderQuantity: item.maximumOrderQuantity,
                minimumOrderQuantity: item.minimumOrderQuantity,
                price: item.price,
              });

              if (index === product.productPrices.length - 1) {
                set_productPrices(productPricesArray);
              }
            }
          );
        }

        set_productFileAttachments(product.productFileAttachments);

        const currentImages: FileObject[] = []
        product.productFileAttachments
          .filter(function (attachment: any, index: number, arr: []) { return attachment.attachmentType = "IMAGE" })
          .map((attachment: any) => {
            currentImages.push({
              name: attachment.s3Key,
              uid: attachment.s3Key,
              thumbUrl: uploadsBaseUrl + attachment.s3Key,
              url: uploadsBaseUrl + attachment.s3Key,
              state: "",
              s3Key: attachment.s3Key,
              type: attachment.attachmentType
            });
          })
        setProductImagesList(currentImages)

        setTimeout(() => {
          let otherImagesList: any[] = [];
          let keysIndex = 0;
          let otherImagesKey = 1;
          product.productFileAttachments.map((item: any) => {
            if (item.attachmentType == "PRODUCT_CERTIFICATE") {
              setProductCertificate({
                name: "Product Certificate",
                type: "PDF",
                size: 1024,
                date: item.updatedAt,
              });

              return;
            }
            if (item.attachmentType == "TECHNICAL_SPECIFICATION_SHEET") {
              setTechnicalDatasheet({
                name: "Technical Specification Sheet",
                type: "PDF",
                size: 1024,
                date: item.updatedAt,
              });

              return;
            }
            if (item.attachmentType != "IMAGE") {
              return;
            }
            const _image = {
              isPrimary: item.isPrimary,
              indexKey: "I" + ++keysIndex,
              id: item.id,
              data:
                Snippets.urls.getAPIBaseURL() +
                "/home/product-attachment/" +
                item.s3Key,
              attachmentType: item.attachmentType,
              meta: {
                type: "image/png",
                size: 1024,
                name: item.name,
                lastModified: item.updatedAt,
              },
              s3Key: item.s3Key,
              imageIndex: item.imageIndex,
            };

            if (item.isPrimary) {
              setProductImage0(_image.data);
            } else {
              setProductImage(_image.imageIndex, _image.data);
            }
          });
          setImagesPlaceHolderArr([1, 2, 3, 4, 5, 6, 7]);

          setImgGalleryImages(otherImagesList);
          setImgGalleryImageUploadCompleted(true);
        }, 1000);

        set_declaration(product.declaration);
        set_category({
          id: product.category.id,
          value: product.category.id,
          label: product.category.name,
          name: product.category.name,
          parent: "string",
          children: [],
          canHaveProducts: product.canHaveProducts,
        });
        productAttributes = product.attributes;
        fetchAttributes(product.category.id, product.id);
        fetchCategoryBrands(product.category.id);
      })
      .catch((error: any) => {
        //console.log("ERROR FETCHING PRODUCT", error);
      });
  };

  useEffect(() => {
    getCategories();
    getCategoryList();

    getUOMs();
  }, []);

  useEffect(() => {
    if (product_id) {
      getProduct(product_id);
    }
  }, []);

  const cleanHtml = (dirty: string) => {
    return sanitizeHtml(dirty);
  }

  const generateProductPayload = () => {
    const payloadBrandName = brandName == "Other" ? otherBrandName : brandName;

    return {
      id: productId,
      name: productName,
      optimisationKeywords: optimisationKeywords,
      modelNumber: modelNumber,
      description: description,
      productDescriptionText: cleanHtml(shortDescription),
      shortDescription: cleanHtml(shortDescription),
      productVideoURL: productVideoURL,
      productGroup: productGroup,
      productCertification: productCertification,
      technicalCertification: technicalCertification,
      minimumOrderQuantity: minimumOrderQuantity,
      unitOfMeasurement: unitOfMeasurement,
      fobPort: fobPort,
      fobPrice: fobPrice,
      enableDirectOrder: enableDirectOrder,
      receivePaymentSettings: receivePaymentSettings,
      orderLeadTimeRangeFrom: orderLeadTimeRangeFrom,
      orderLeadTimeRangeTo: orderLeadTimeRangeTo,
      paymentMethod: paymentMethod,
      sampleAvailability: sampleAvailability,
      areSmallOrdersAccepted: areSmallOrdersAccepted,
      dimensionPerUnit: dimensionPerUnit,
      weightPerUnit: weightPerUnit,
      exportCartonDimension: exportCartonDimension,
      exportCartonUnits: exportCartonUnits,
      exportCartonWeight: exportCartonWeight,
      usHtsCode: usHtsCode,
      brandName: payloadBrandName,
      origin: origin,
      exportMarket: exportMarket,
      category: category,
      status: status,
      pricingOption: pricingOption,
      productPrices: productPrices,
      declaration: declaration,
      productPricesRangeFrom: productPricesRangeFrom,
      productPricesRangeTo: productPricesRangeTo,
      productFixedPrice: productFixedPrice,
      attributes: [
        attribute1,
        attribute2,
        attribute3,
        attribute4,
        attribute5,
        attribute6,
        attribute7,
        attribute8,
        attribute9,
        attribute10,
      ],
      szId: szId,
      displayPrice: displayPrice
    };
  };

  let handleChangeMinimumOrderQuantity = (
    i: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    let newFormValues = [...productPrices];
    newFormValues[i]["minimumOrderQuantity"] = parseInt(e.target.value);
    set_productPrices(newFormValues);
  };

  let handleChangeMaximumOrderQuantity = (
    i: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    let newFormValues = [...productPrices];
    newFormValues[i]["maximumOrderQuantity"] = parseInt(e.target.value);
    set_productPrices(newFormValues);
  };

  let handleChangeMOQPrice = (i: number, e: ChangeEvent<HTMLInputElement>) => {
    let newFormValues = [...productPrices];
    newFormValues[i]["price"] = parseFloat(e.target.value);
    set_productPrices(newFormValues);
  };

  let addFormFields = () => {
    set_productPrices([
      ...productPrices,
      {
        id: 0,
        minimumOrderQuantity: 101,
        maximumOrderQuantity: 999,
        price: 10,
      },
    ]);
  };

  let removeFormFields = (i: number) => {
    let newFormValues = [...productPrices];
    newFormValues.splice(i, 1);
    set_productPrices(newFormValues);
  };

  const [progressStepperAddionalClass, setProgressStepperAddionalClass] =
    useState("");

  const [progressStepperMaxWidth, setProgressStepperMaxWidth] = useState("");

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 150) {
      setProgressStepperAddionalClass("fixed");
      let obj = document.getElementById("card-section-1");
      let offsetright = window.innerWidth - obj.offsetLeft - obj.offsetWidth;
      setProgressStepperMaxWidth(`${offsetright - 25}px`);
    } else {
      setProgressStepperAddionalClass("");
    }
  };
  const [selectedTab, setSelectedTab] = useState(1);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  /* product image gallery, there is a better way to do this */
  const productImageGalleryInputFile = useRef(null);

  const [productImage0, setProductImage0] = useState("");
  const [productImage1, setProductImage1] = useState("");
  const [productImage2, setProductImage2] = useState("");
  const [productImage3, setProductImage3] = useState("");
  const [productImage4, setProductImage4] = useState("");
  const [productImage5, setProductImage5] = useState("");
  const [productImage6, setProductImage6] = useState("");
  const [productImage7, setProductImage7] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(-1);

  const selectProductImage = (index: number) => {
    setCurrentImageIndex(index);
    productImageGalleryInputFile.current.click();
  };

  const getProductImageSrc = (index: number) => {
    switch (index) {
      case 0:
        return productImage0;
        break;
      case 1:
        return productImage1;
        break;
      case 2:
        return productImage2;
        break;
      case 3:
        return productImage3;
        break;
      case 4:
        return productImage4;
        break;
      case 5:
        return productImage5;
        break;
      case 6:
        return productImage6;
        break;
      case 7:
        return productImage7;
        break;
      default:
        return "";
    }
  };

  const setProductImage = (index: number, src: SetStateAction<string>) => {
    switch (index) {
      case 0:
        setProductImage0(src);
        break;
      case 1:
        setProductImage1(src);
        break;
      case 2:
        setProductImage2(src);
        break;
      case 3:
        setProductImage3(src);
        break;
      case 4:
        setProductImage4(src);
        break;
      case 5:
        setProductImage5(src);
        break;
      case 6:
        setProductImage6(src);
        break;
      case 7:
        setProductImage7(src);
        break;
    }
  };

  const calculatedValue = useMemo(() => {
    // Do expensive calculation and return.

    let _score: number = 0;

    setSegA(0);
    setSegB(0);

    if (productName.toString().length > 10) {
      _score = _score + 10;
      setSegA((prevCount) => prevCount + 10);
    }
    if (category.id > 0) {
      _score = _score + 10;
      setSegA((prevCount) => prevCount + 10);
    }
    if (description.toString().length > 10) {
      _score = _score + 10;
      setSegA((prevCount) => prevCount + 10);
    }
    if (shortDescription.toString().length > 10) {
      _score = _score + 10;
      setSegA((prevCount) => prevCount + 10);
    }
    if (optimisationKeywords.toString().length > 3) {
      _score = _score + 10;
      setSegA((prevCount) => prevCount + 10);
    }
    if (modelNumber.toString().length > 5) {
      _score = _score + 5;
      setSegA((prevCount) => prevCount + 5);
    }
    if (productImage0.toString().length > 5) {
      _score = _score + 10;
      setSegA((prevCount) => prevCount + 10);
    }
    if (productImage1.toString().length > 5) {
      _score = _score + 5;
      setSegA((prevCount) => prevCount + 5);
    }
    if (productImage2.toString().length > 5) {
      _score = _score + 5;
      setSegA((prevCount) => prevCount + 5);
    }
    if (productVideoURL.toString().length > 5) {
      _score = _score + 5;
      setSegA((prevCount) => prevCount + 5);
    }
    if (minimumOrderQuantity.toString().length > 0) {
      _score = _score + 10;
      setSegB((prevCount) => prevCount + 10);
    }

    if (pricingOption == "FIXED") {
      if (parseFloat(productFixedPrice) > 0) {
        _score = _score + 10;
        setSegB((prevCount) => prevCount + 10);
      }
    }
    if (pricingOption == "LADDER_PRICE") {
      if (parseFloat(productPricesRangeFrom) > 0) {
        _score = _score + 5;
        setSegB((prevCount) => prevCount + 5);
      }
      if (parseFloat(productPricesRangeTo) > 0) {
        _score = _score + 5;
        setSegB((prevCount) => prevCount + 5);
      }
    }
    if (pricingOption == "PRICE_RANGE") {
      if (productPrices.length > 1) {
        _score = _score + 10;
        setSegB((prevCount) => prevCount + 10);
      }
    }

    setGaugePercentage(_score / 100);

    return _score;
  }, [
    productName,
    optimisationKeywords,
    modelNumber,
    description,
    shortDescription,
    productFileAttachments,
    productVideoURL,
    productGroup,
    productCertification,
    technicalCertification,
    minimumOrderQuantity,
    unitOfMeasurement,
    fobPort,
    fobPrice,
    enableDirectOrder,
    receivePaymentSettings,
    orderLeadTimeRangeFrom,
    orderLeadTimeRangeTo,
    paymentMethod,
    sampleAvailability,
    areSmallOrdersAccepted,
    dimensionPerUnit,
    weightPerUnit,
    exportCartonDimension,
    exportCartonUnits,
    exportCartonWeight,
    usHtsCode,
    brandName,
    origin,
    exportMarket,
    category,
    status,
    pricingOption,
    productPricesRangeFrom,
    productPricesRangeTo,
    productPrices,
    declaration,
    productImage0,
    productImage1,
    productImage2,
    productFixedPrice,
  ]);

  const selectFile = (event: { target: { files: any[] } }) => {
    //we need to have category and name set
    const _product: any = generateProductPayload();

    if (_product.id == "") {
      alert(
        "You must select product category and name before uploading images"
      );
      return;
    }

    let _file = event.target.files[0];
    //uploading to server
    if (_file.size > 5000000) {
      alert("Image size must not be greater than 5MB");
      //console.log("File size: " + _file.size);
      return;
    }
    const reader = new FileReader();

    reader.addEventListener(
      "progress",
      (e) => {
        setImgPrimaryImageUploadInProgress(true);
        setImgPrimaryImageUploadCompleted(false);
        let percentage: number = (e.loaded / e.total) * 50;
        setImgPrimaryImageUploadPercentage(percentage);
      },
      false
    );

    reader.addEventListener(
      "load",
      () => {
        let imgSrc = URL.createObjectURL(_file);
        setProductImage(currentImageIndex, imgSrc);
        //console.log(imgSrc + " : for index " + currentImageIndex);

        let isPrimary = currentImageIndex == 0;
        const _uploadedFile: any = {
          isPrimary: isPrimary,
          indexKey: "I" + currentImageIndex,
          id: productId,
          data: reader.result,
          attachmentType: "IMAGE",
          meta: {
            type: _file.type,
            size: _file.size,
            name: _file.name,
            lastModified: _file.lastModified,
          },
          s3Key: "string",
          imageIndex: currentImageIndex,
        };
        saveProductAttachment(_uploadedFile, "IMAGE");

        setImagesPlaceHolderArr([1, 2, 3, 4, 5, 6, 7]);
      },
      false
    );

    try {
      reader.readAsDataURL(_file);
    } catch (e) {
      //console.warn(":::Error reading file IMAGE:::", e);
    }
  };

  const clsx = () => { };
  const tab = () => { };

  function toggleAvailability(checked: boolean): void {
    set_sampleAvailability(checked)
  }
  function toggleDisplayPrice(checked: boolean): void {
    setDisplayPrice(checked)
  }
  interface FileObject {
    name: string;
    uid: string;
    thumbUrl: string;
    url: string;
    state: string;
    s3Key: string;
    type: string;
  }

  const removeFileAttachment = (fileObject: any) => {
    //console.warn("::: fileObject :::", fileObject);

    const modalConfirmAttachmentDelete: any = Modal.confirm({
      centered: true,
      title: "Remove attachment?",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to remove the file: " + fileObject.name,
      onOk() {
        return new Promise((resolve, reject) => {
          AxiosAPI.removeFileAttachment(fileObject.s3Key)
            .then((res: any) => {
              modalConfirmAttachmentDelete.destroy();
              messageApi.open({
                type: "success",
                content: "Attachment removed successfully",
              });
            })
            .catch((error: any) => { });
        }).catch(() => {
          messageApi.open({
            type: "error",
            content: "Error removing attachment",
          });
        });
      },
      onCancel() { },
    });
  };

  const uploadsBaseUrl: string =
    "https://api-live.supplyzone.ai/home/download-attachment/"
  const [loadingAttachments, setLoadingAttachments] = useState(false);

  const propsCarousel: UploadProps = {
    beforeUpload(file) {
      setLoadingAttachments(true);
      return new Promise((resolve: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const imgData: string = reader.result as string;

          const image = new Image();
          image.src = imgData;
          image.onload = () => {
            const { width } = image;

            /*if (width < 1230) {
              messageApi.open({
                type: 'error',
                content: "Minimum width of uploaded banner must be is 1230 pixels",
              });
              setLoadingAttachments(false);
              return;
            }*/


            const _uploadedFile: any = {
              name: file.name,
              attachmentType: "IMAGE",
              size: file.size,
              type: file.type,
              data: imgData,
              entityId: 0,
              isPrimary: setProductImagesList.length == 0,
              imageIndex: productImagesList.length,
              meta: {
                type: file.type,
                size: file.size,
                name: file.name,
                lastModified: file.lastModified,
              }
            };

            _uploadedFile.label = file.name;

            AxiosAPI.saveProductAttachment(productId, _uploadedFile, "IMAGE")
              .then((res: any) => {
                _uploadedFile.url = `${uploadsBaseUrl}${res.data}`
                setProductImagesList([...productImagesList, _uploadedFile]);

                setLoadingAttachments(false);

                message.success(
                  'File "' + _uploadedFile.label + '" uploaded successfully!'
                );
              })
              .catch((error: any) => {
                message.success('Error uploading "' + _uploadedFile.label + '"');
                setLoadingAttachments(false);
              });

          };


        };
      });
    },
  };



  const [productImagesList, setProductImagesList] = useState<FileObject[]>(
    []
  );
  return (
    <>

      <main className="main wolmart">
        <div className="container pt-2">
          <div className="product-wrapper-1 appear-animatex mb-1">
            <div className="title-link-wrapper pb-1 mb-1">
              <h2 className="title ls-normal mb-0">Create Product</h2>
              <a
                href="#"
                className="btn btn-primary pl-3 pr-3 font-size-normal font-weight-bold ls-25 mb-0 xaction-btn"
              >
                Save Product
              </a>
            </div>
          </div>
          <div className="row">
            {/* begin::Form */}
            <form className="form">
              <div className="row">
                <div className="col-md-9">
                  <div className="card card-custom" id="card-section-1">
                    <div className="card-header card-header-stretch overflow-auto">
                      <h4 style={{ lineHeight: '2.8' }}>Primary Product Information</h4>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className="card-body">
                      <div className="tab-content pt-3">
                        <div
                          className={clsx("tab-pane", {
                            active: tab === "Header",
                          })}
                        >
                          <div className="row">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Product Category:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              {/*
                <Select options={productCategoryOptions} value={category} isLoading={productCategoryOptions.length === 0} onChange={setProductCategoryOptionsEvent} />
          */}
                              <Cascader
                                options={categoryList}
                                value={[category.name]}
                                onChange={onChange}
                                size="large"
                                className="w-100"
                                placeholder="Please select"
                                showSearch={{ filter }}
                              />
                              <div className="form-text text-muted">
                                Select product category.{" "}
                              </div>
                            </div>
                          </div>

                          {/* extra attributes */}
                          <div className="row mb-10">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-9">
                              <div className="row">
                                {attribute1.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute1.name}
                                      </label>
                                      {attribute1.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          onChange={(_event) =>
                                            setAttribute1({
                                              id: attribute1.id,
                                              name: attribute1.name,
                                              value: _event.target.value,
                                              options: attribute1.options,
                                            })
                                          }
                                          defaultValue={attribute1.value}
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute1.value}
                                          onChange={(_event) =>
                                            setAttribute1({
                                              id: attribute1.id,
                                              name: attribute1.name,
                                              value: _event.target.value,
                                              options: attribute1.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute1.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute2.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute2.name}
                                      </label>
                                      {attribute2.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          onChange={(_event) =>
                                            setAttribute2({
                                              id: attribute2.id,
                                              name: attribute2.name,
                                              value: _event.target.value,
                                              options: attribute2.options,
                                            })
                                          }
                                          defaultValue={attribute2.value}
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute2.value}
                                          onChange={(_event) =>
                                            setAttribute2({
                                              id: attribute2.id,
                                              name: attribute2.name,
                                              value: _event.target.value,
                                              options: attribute2.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute2.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="row">
                                {attribute3.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute3.name}
                                      </label>
                                      {attribute3.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute3.value}
                                          onChange={(_event) =>
                                            setAttribute3({
                                              id: attribute3.id,
                                              name: attribute3.name,
                                              value: _event.target.value,
                                              options: attribute3.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute3.value}
                                          onChange={(_event) =>
                                            setAttribute3({
                                              id: attribute3.id,
                                              name: attribute3.name,
                                              value: _event.target.value,
                                              options: attribute3.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute3.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute4.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute4.name}
                                      </label>
                                      {attribute4.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute4.value}
                                          onChange={(_event) =>
                                            setAttribute4({
                                              id: attribute4.id,
                                              name: attribute4.name,
                                              value: _event.target.value,
                                              options: attribute4.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute4.value}
                                          onChange={(_event) =>
                                            setAttribute4({
                                              id: attribute4.id,
                                              name: attribute4.name,
                                              value: _event.target.value,
                                              options: attribute4.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute4.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute5.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute5.name}
                                      </label>
                                      {attribute5.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute5.value}
                                          onChange={(_event) =>
                                            setAttribute5({
                                              id: attribute5.id,
                                              name: attribute5.name,
                                              value: _event.target.value,
                                              options: attribute5.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute5.value}
                                          onChange={(_event) =>
                                            setAttribute5({
                                              id: attribute5.id,
                                              name: attribute5.name,
                                              value: _event.target.value,
                                              options: attribute5.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute5.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute6.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute6.name}
                                      </label>
                                      {attribute6.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute6.value}
                                          onChange={(_event) =>
                                            setAttribute6({
                                              id: attribute6.id,
                                              name: attribute6.name,
                                              value: _event.target.value,
                                              options: attribute6.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute6.value}
                                          onChange={(_event) =>
                                            setAttribute6({
                                              id: attribute6.id,
                                              name: attribute6.name,
                                              value: _event.target.value,
                                              options: attribute6.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute6.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute7.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute7.name}
                                      </label>
                                      {attribute7.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute7.value}
                                          onChange={(_event) =>
                                            setAttribute7({
                                              id: attribute7.id,
                                              name: attribute7.name,
                                              value: _event.target.value,
                                              options: attribute7.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute7.value}
                                          onChange={(_event) =>
                                            setAttribute7({
                                              id: attribute7.id,
                                              name: attribute7.name,
                                              value: _event.target.value,
                                              options: attribute7.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute7.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute8.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute8.name}
                                      </label>
                                      {attribute8.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute8.value}
                                          onChange={(_event) =>
                                            setAttribute8({
                                              id: attribute8.id,
                                              name: attribute8.name,
                                              value: _event.target.value,
                                              options: attribute8.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute8.value}
                                          onChange={(_event) =>
                                            setAttribute8({
                                              id: attribute8.id,
                                              name: attribute8.name,
                                              value: _event.target.value,
                                              options: attribute8.options,
                                            })
                                          }
                                        >
                                          <option value="">Select</option>
                                          {attribute8.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute9.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute9.name}
                                      </label>
                                      {attribute9.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute9.value}
                                          onChange={(_event) =>
                                            setAttribute9({
                                              id: attribute9.id,
                                              name: attribute9.name,
                                              value: _event.target.value,
                                              options: attribute9.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute9.value}
                                          onChange={(_event) =>
                                            setAttribute9({
                                              id: attribute9.id,
                                              name: attribute9.name,
                                              value: _event.target.value,
                                              options: attribute9.options,
                                            })
                                          }
                                        >
                                          {attribute9.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                                {attribute10.id > 0 && (
                                  <>
                                    <div className="col-lg-6">
                                      <label className="col-form-label">
                                        {attribute10.name}
                                      </label>
                                      {attribute10.options.length == 0 ? (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          defaultValue={attribute10.value}
                                          onChange={(_event) =>
                                            setAttribute10({
                                              id: attribute10.id,
                                              name: attribute10.name,
                                              value: _event.target.value,
                                              options: attribute10.options,
                                            })
                                          }
                                        />
                                      ) : (
                                        <select
                                          className="form-select form-select-solid"
                                          value={attribute10.value}
                                          onChange={(_event) =>
                                            setAttribute10({
                                              id: attribute10.id,
                                              name: attribute10.name,
                                              value: _event.target.value,
                                              options: attribute10.options,
                                            })
                                          }
                                        >
                                          {attribute10.options.map(
                                            (opt: any) => (
                                              <option value={opt}>
                                                {" "}
                                                {opt}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* end extra attributes */}

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Product Name:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                id="productName"
                                name="productName"
                                value={productName}
                                onChange={(_event) =>
                                  set_productName(_event.target.value)
                                }
                                onBlur={(_event) => saveProductAsDraft()}
                              />
                              <div className="form-text text-muted">
                                Enter alpha numerical entry with a max of 32
                                characters.
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Short Description:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                id="description"
                                name="description"
                                value={description}
                                onChange={(_event) =>
                                  set_description(_event.target.value)
                                }
                              />
                              <div className="form-text text-muted">
                                Enter a short description of the product.
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Search Optimization Keywords:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                id="optimisationKeywords"
                                name="optimisationKeywords"
                                value={optimisationKeywords}
                                onChange={(_event) =>
                                  set_optimisationKeywords(_event.target.value)
                                }
                              />
                              <div className="form-text text-muted">
                                Ensure accurate search results and make your
                                product more attractive.
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <div className="col-lg-3 "></div>

                            <div className="col-lg-9 col-xl-9">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    Model Number:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    id="modelNumber"
                                    name="modelNumber"
                                    value={modelNumber}
                                    onChange={(_event) =>
                                      set_modelNumber(_event.target.value)
                                    }
                                  />
                                  <div className="form-text text-muted">
                                    Enter a short description of the product.
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    Brand:
                                  </label>
                                  <select
                                    className="form-select form-select-solid"
                                    value={brandName}
                                    onChange={(_event) =>
                                      set_brandName(_event.target.value)
                                    }
                                  >
                                    {categoryBrandsList.map((opt: any) => (
                                      <option value={opt}> {opt} </option>
                                    ))}
                                  </select>
                                  {brandName == "Other" && (
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid mt-3"
                                      value={otherBrandName}
                                      placeholder="Other brand"
                                      onChange={(_event) =>
                                        set_otherBrandName(_event.target.value)
                                      }
                                    />
                                  )}
                                  <div className="form-text text-muted">
                                    Select brand.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <div className="col-lg-3 "></div>

                            <div className="col-lg-9 col-xl-9">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label className="col-form-label">
                                    SZ ID:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    id="szId"
                                    name="szId"
                                    value={szId}
                                    onChange={(_event) =>
                                      set_szId(_event.target.value)
                                    }
                                  />
                                  <div className="form-text text-muted">
                                    Enter SZID.
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Product Show:
                            </label>

                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              ref={productImageGalleryInputFile}
                              onChange={selectFile}
                            />
                            <div className="col-lg-9 col-xl-9">
                              <div className="row">
                                <div className="col-lg-12">
                                  {imgPrimaryImageUploadInProgress && (
                                    <progress
                                      id="file"
                                      value={imgPrimaryImageUploadPercentage}
                                      max="100"
                                    >
                                      {" "}
                                      {imgPrimaryImageUploadPercentage}%{" "}
                                    </progress>
                                  )}
                                </div>
                              </div>
                              <div className="row">

                                <Row className="p-2 profile-images">
                                  <ImgCrop rotationSlider aspect={640 / 640}>
                                    <Upload
                                      listType="picture-card"
                                      fileList={productImagesList}
                                      onRemove={removeFileAttachment}
                                      {...propsCarousel}
                                    >
                                      {productImagesList.length < 5 &&
                                        (loadingAttachments ? (
                                          <LoadingOutlined />
                                        ) : (
                                          "+ Upload"
                                        ))}
                                    </Upload>
                                  </ImgCrop>
                                </Row>

                              </div>
                            </div>

                            <div className="col-lg-3 col-xl-3"></div>
                            <div className="col-lg-5 col-xl-5">
                              <div className="form-text text-muted">
                                Size: 640x640 pixel or above;JPG/JPEG/PNG
                                format;less than 5MB. Do not use unauthorized
                                third-party brand logos and product images
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Product Video URL:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                id="productVideoURL"
                                name="productVideoURL"
                                value={productVideoURL}
                                onChange={(_event) =>
                                  set_productVideoURL(_event.target.value)
                                }
                              />
                              <div className="form-text text-muted">
                                Enter the full video url e.g:
                                https://youtube.com/watch/hGS639nH8D6^.
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Product Description:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              <DefaultEditor
                                value={shortDescription}
                                onChange={onEditorChange}
                              />
                              <div className="form-text text-muted">
                                Up to 10 image & text templates can be
                                inserted.Images can be in JPG/JPEG/PNG format
                                and less than 5MB.Phone numbers, email address,
                                chat names, QR codes or other contact
                                information are not allowed on the image.
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Product Warranty:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              <DefaultEditor
                                value={productWarranty}
                                onChange={onProductWarrantyEditorChange}
                              />
                              <div className="form-text text-muted">
                                Up to 10 image & text templates can be
                                inserted.Images can be in JPG/JPEG/PNG format
                                and less than 5MB.Phone numbers, email address,
                                chat names, QR codes or other contact
                                information are not allowed on the image.
                              </div>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Product Certificate:
                            </label>
                            <div className="col-lg-4 col-xl-4">
                              <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 file-uploader">
                                <FileUploader
                                  label=""
                                  handleChange={uploadProductCertificate}
                                  id="productCertification"
                                  name="productCertification"
                                  types={fileTypes}
                                />
                              </label>
                              <div className="form-text text-muted">
                                Size: 640x640 pixel or above;JPG/JPEG/PNG
                                format;less than 5MB. Do not use unauthorized
                                third-party brand logos and product images
                              </div>
                            </div>
                            {productCertificate &&
                              productCertificate.hasOwnProperty("type") && (
                                <div className="col-lg-5 col-xl-5">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <img
                                        src="https://lrwa.org/wp-content/uploads/2018/12/adobe-pdf-icon.png"
                                        width={"95px"}
                                      />
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-text text-muted">
                                        File Name:{" "}
                                        <strong>
                                          {productCertificate.name}
                                        </strong>
                                      </div>
                                      <div className="form-text text-muted">
                                        File Type: {productCertificate.type}
                                      </div>
                                      <div className="form-text text-muted">
                                        Size: {productCertificate.size}
                                      </div>
                                      <div className="form-text text-muted">
                                        Modified: {productCertificate.date}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Technical Specification Sheet:
                            </label>
                            <div className="col-lg-4 col-xl-4">
                              <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 file-uploader">
                                <FileUploader
                                  label=""
                                  handleChange={uploadProductTechnicalDatasheet}
                                  id="technicalCertification"
                                  name="technicalCertification"
                                  types={[...fileTypes, ...fileTypePDF]}
                                />
                              </label>
                              <div className="form-text text-muted">
                                Size: 640x640 pixel or above;JPG/JPEG/PNG
                                format;less than 5MB. Do not use unauthorized
                                third-party brand logos and product images
                              </div>
                            </div>
                            {technicalDatasheet &&
                              technicalDatasheet.hasOwnProperty("type") && (
                                <div className="col-lg-5 col-xl-5">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <img
                                        src="https://lrwa.org/wp-content/uploads/2018/12/adobe-pdf-icon.png"
                                        width={"95px"}
                                      />
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-text text-muted">
                                        File Name:{" "}
                                        <strong>
                                          {technicalDatasheet.name}
                                        </strong>
                                      </div>
                                      <div className="form-text text-muted">
                                        File Type: {technicalDatasheet.type}
                                      </div>
                                      <div className="form-text text-muted">
                                        Size: {technicalDatasheet.size}
                                      </div>
                                      <div className="form-text text-muted">
                                        Modified: {technicalDatasheet.date}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end::Body */}
                  </div>

                  <div className="card card-custom mt-10" id="card-section-2">
                    <div className="card-header card-header-stretch overflow-auto">
                      <h4 style={{ lineHeight: '2.8' }}>Price and Order Information</h4>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className="card-body">
                      <div className="tab-content pt-3">
                        <div
                          className={clsx("tab-pane", {
                            active: tab === "Header",
                          })}
                        >
                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Minimum Order Quantity:
                            </label>

                            <div className="col-lg-6 col-xl-6">
                              <div className="form-text text-muted">
                                Please enter quantity:
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                id="minimumOrderQuantity"
                                name="minimumOrderQuantity"
                                value={minimumOrderQuantity}
                                onChange={(_event) =>
                                  set_minimumOrderQuantity(_event.target.value)
                                }
                              />
                            </div>

                            <div className="col-lg-3 col-xl-3">
                              <div className="form-text text-muted">
                                Unit of Measure:
                              </div>
                              <select
                                className="form-select form-select-solid"
                                name="unitOfMeasurement"
                                id="unitOfMeasurement"
                                value={unitOfMeasurement}
                                onChange={(_event) =>
                                  set_unitOfMeasurement(_event.target.value)
                                }
                              >
                                <option value="fluid">
                                  {" "}
                                  --- Select Unit ---
                                </option>
                                {productUOMs.map((uom: any) => (
                                  <option value={uom}> {uom} </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Price:
                            </label>

                            <div className="col-lg-3 col-xl-3  col-form-label text-lg-end">
                              {/* begin::Radio */}
                              <div className="form-check form-check-custom form-check-solid mb-5">
                                {/* begin::Input */}
                                <input
                                  className="form-check-input me-3"
                                  type="radio"
                                  name="fobPrice"
                                  id="fobPrice1"
                                  checked={pricingOption == "LADDER_PRICE"}
                                  value={fobPrice}
                                  onChange={(_event) =>
                                    _event.target.checked
                                      ? set_pricingOption("LADDER_PRICE")
                                      : ""
                                  }
                                />
                                {/* end::Input */}

                                {/* begin::Label */}
                                <label
                                  className="form-check-label"
                                  htmlFor="kt_docs_formvalidation_radio_option_1"
                                >
                                  <div className="fw-semibold text-gray-800">
                                    Ladder Price
                                  </div>
                                </label>
                                {/* end::Label */}
                              </div>
                              {/* end::Radio */}
                            </div>

                            <div className="col-lg-3 col-xl-3  col-form-label text-lg-end">
                              {/* begin::Radio */}
                              <div className="form-check form-check-custom form-check-solid mb-5">
                                {/* begin::Input */}
                                <input
                                  className="form-check-input me-3"
                                  type="radio"
                                  name="fobPrice"
                                  id="fobPrice2"
                                  checked={pricingOption == "PRICE_RANGE"}
                                  value={fobPrice}
                                  onChange={(_event) =>
                                    _event.target.checked
                                      ? set_pricingOption("PRICE_RANGE")
                                      : ""
                                  }
                                />
                                {/* end::Input */}

                                {/* begin::Label */}
                                <label
                                  className="form-check-label"
                                  htmlFor="kt_docs_formvalidation_radio_option_1"
                                >
                                  <div className="fw-semibold text-gray-800">
                                    Price Range
                                  </div>
                                </label>
                                {/* end::Label */}
                              </div>
                              {/* end::Radio */}
                            </div>

                            <div className="col-lg-3 col-xl-3  col-form-label text-lg-end">
                              {/* begin::Radio */}
                              <div className="form-check form-check-custom form-check-solid mb-5">
                                {/* begin::Input */}
                                <input
                                  className="form-check-input me-3"
                                  type="radio"
                                  name="fobPrice"
                                  id="fobPrice3"
                                  checked={pricingOption == "FIXED"}
                                  value={fobPrice}
                                  onChange={(_event) =>
                                    _event.target.checked
                                      ? set_pricingOption("FIXED")
                                      : ""
                                  }
                                />
                                {/* end::Input */}

                                {/* begin::Label */}
                                <label
                                  className="form-check-label"
                                  htmlFor="kt_docs_formvalidation_radio_option_1"
                                >
                                  <div className="fw-semibold text-gray-800">
                                    Fixed
                                  </div>
                                </label>
                                {/* end::Label */}
                              </div>
                              {/* end::Radio */}
                            </div>

                            <div className="col-lg-3 col-xl-3  col-form-label text-lg-end"></div>

                            <div className="col-lg-9 col-xl-9 mt-0 pt-0">


                              <div className="form-text text-muted">
                                Specify price based on quantity
                              </div>

                              {pricingOption === "FIXED" && (
                                <label
                                  className="btn btn-outline btn-outline-dashed btn-outline-default p-7 mt-5 mb-10"
                                  style={{ width: "100%" }}
                                >
                                  <div className="row">
                                    <label className="col-lg-2 col-xl-2 col-form-label text-lg-end">
                                      Price:
                                    </label>
                                    <div className="col-lg-6 col-xl-6">
                                      <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid"
                                        min="0.01"
                                        step="0.01"
                                        name="productFixedPrice"
                                        value={productFixedPrice}
                                        onChange={(e) =>
                                          set_productFixedPrice(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </label>
                              )}

                              {pricingOption === "PRICE_RANGE" && (
                                <label
                                  className="btn btn-outline btn-outline-dashed btn-outline-default p-7 mt-5 mb-10"
                                  style={{ width: "100%" }}
                                >
                                  <div className="row">
                                    <label className="col-lg-2 col-xl-2 col-form-label text-lg-end">
                                      From:
                                    </label>
                                    <div className="col-lg-4 col-xl-4">
                                      <div className="form-text text-muted text-align-left">
                                        Minimum Price:
                                      </div>
                                      <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control form-control-lg form-control-solid"
                                        name="productPricesRangeFrom"
                                        value={productPricesRangeFrom}
                                        onChange={(e) =>
                                          set_productPricesRangeFrom(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <label className="col-lg-2 col-xl-2 col-form-label text-lg-end">
                                      To:
                                    </label>
                                    <div className="col-lg-4 col-xl-4">
                                      <div className="form-text text-muted text-align-left">
                                        Maximum Price:
                                      </div>
                                      <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control form-control-lg form-control-solid"
                                        name="productPricesRangeTo"
                                        value={productPricesRangeTo}
                                        onChange={(e) =>
                                          set_productPricesRangeTo(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </label>
                              )}

                              {pricingOption == "LADDER_PRICE" && (
                                <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 mt-5 mb-10">
                                  {productPrices.map((element, index) => (
                                    <div className="row" key={index}>
                                      <label className="col-lg-2 col-xl-2 col-form-label text-lg-end">
                                        MOQ:
                                      </label>
                                      <div className="col-lg-3 col-xl-3">
                                        <div className="form-text text-muted text-align-left">
                                          Minimum Qty:
                                        </div>
                                        <input
                                          type="number"
                                          className="form-control form-control-lg form-control-solid"
                                          name={`moqMin-${index}`}
                                          id={`moqMin-${index}`}
                                          value={element.minimumOrderQuantity}
                                          onChange={(e) =>
                                            handleChangeMinimumOrderQuantity(
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-3 col-xl-3">
                                        <div className="form-text text-muted text-align-left">
                                          Maximum Qty:
                                        </div>
                                        <input
                                          type="number"
                                          className="form-control form-control-lg form-control-solid"
                                          name={`moqMax-${index}`}
                                          id={`moqMax-${index}`}
                                          value={element.maximumOrderQuantity}
                                          onChange={(e) =>
                                            handleChangeMaximumOrderQuantity(
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-3 col-xl-3">
                                        <div className="form-text text-muted text-align-left">
                                          Price in USD:
                                        </div>
                                        <input
                                          type="number"
                                          min="0.01"
                                          step="0.01"
                                          className="form-control form-control-lg form-control-solid"
                                          name={`moqPrice-${index}`}
                                          id={`moqPrice-${index}`}
                                          value={element.price}
                                          onChange={(e) =>
                                            handleChangeMOQPrice(index, e)
                                          }
                                        />
                                        {index ? (
                                          <a
                                            className={"remove-moq-button"}
                                            onClick={() =>
                                              removeFormFields(index)
                                            }
                                          >
                                            <img
                                              src={"/media/icons/close.png"}
                                              width="20"
                                            />
                                          </a>
                                        ) : null}
                                      </div>

                                      <hr className={"mb-3 mt-3"} />
                                    </div>
                                  ))}

                                  <div style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-outline btn-outline-solid btn-outline-primary btn-active-light-primary me-19"
                                      type="button"
                                      onClick={() => addFormFields()}
                                    >
                                      Add Ladder Price
                                    </button>
                                  </div>
                                </label>
                              )}


                            </div>

                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Display Price:
                            </label>
                            <div className="col-lg-9 col-xl-9 mt-0 pt-0">

                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorPrimary: "#552278"
                                  },
                                }}
                              >
                                <Switch defaultChecked checkedChildren="Yes" checked = {displayPrice} unCheckedChildren="No" onChange={toggleDisplayPrice} style={{ borderRadius: "100px" }} />
                              </ConfigProvider>
                            </div>
                          </div>

                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Order Lead Time Range:
                            </label>
                            <div className="col-lg-3 col-xl-3  col-form-label text-lg-end">
                              {/* begin::Radio */}
                              <div className="form-check form-check-custom form-check-solid mb-5">
                                {/* begin::Input */}
                                <input
                                  className="form-check-input me-3"
                                  type="radio"
                                  name="ladderPriceOption"
                                  id="ladderPriceOption1"
                                  checked={ladderPriceOption == "EX_STOCK"}
                                  onChange={(_event) =>
                                    _event.target.checked
                                      ? setLadderPriceOption("EX_STOCK")
                                      : ""
                                  }
                                />
                                {/* end::Input */}

                                {/* begin::Label */}
                                <label
                                  className="form-check-label"
                                  htmlFor="kt_docs_formvalidation_radio_option_1"
                                >
                                  <div className="fw-semibold text-gray-800">
                                    Awaiting replenishment
                                  </div>
                                </label>
                                {/* end::Label */}
                              </div>
                              {/* end::Radio */}
                            </div>
                            <div className="col-lg-3 col-xl-3  col-form-label text-lg-end">
                              {/* begin::Radio */}
                              <div className="form-check form-check-custom form-check-solid mb-5">
                                {/* begin::Input */}
                                <input
                                  className="form-check-input me-3"
                                  type="radio"
                                  name="ladderPriceOption"
                                  id="ladderPriceOption2"
                                  checked={ladderPriceOption == "IN_STOCK"}
                                  onChange={(_event) =>
                                    _event.target.checked
                                      ? setLadderPriceOption("IN_STOCK")
                                      : ""
                                  }
                                />
                                {/* end::Input */}

                                {/* begin::Label */}
                                <label
                                  className="form-check-label"
                                  htmlFor="kt_docs_formvalidation_radio_option_1"
                                >
                                  <div className="fw-semibold text-gray-800">
                                    In Stock
                                  </div>
                                </label>
                                {/* end::Label */}
                              </div>
                              {/* end::Radio */}
                            </div>
                            <div className="col-lg-3 col-xl-3  col-form-label text-lg-end"></div>

                            {ladderPriceOption == "EX_STOCK" && (
                              <>
                                <div className="col-lg-3 col-xl-3  col-form-label text-lg-end"></div>

                                <div className="col-lg-3 col-xl-3">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name={`moqMax-0`}
                                    id={`moqMax-0`}
                                    value={orderLeadTimeRangeFrom}
                                    onChange={(e) =>
                                      set_orderLeadTimeRangeFrom(e.target.value)
                                    }
                                  />
                                </div>

                                <label className="col-lg-1 col-form-label text-lg-end">
                                  To:
                                </label>

                                <div className="col-lg-3 col-xl-3">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name={`moqMax-0`}
                                    id={`moqMax-0`}
                                    value={orderLeadTimeRangeTo}
                                    onChange={(e) =>
                                      set_orderLeadTimeRangeTo(e.target.value)
                                    }
                                  />
                                </div>

                                <label className="col-lg-1 col-form-label text-lg-end">
                                  days
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end::Body */}
                  </div>

                  <div className="card card-custom mt-10" id="card-section-3">
                    <div className="card-header card-header-stretch overflow-auto">
                      <h4 style={{ lineHeight: '2.8' }}>Sample Policy</h4>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className="card-body">
                      <div className="tab-content pt-3">
                        <div
                          className={clsx("tab-pane", {
                            active: tab === "Header",
                          })}
                        >
                          <div className="row mb-10">
                            <label className="col-lg-3 col-form-label text-lg-end">
                              Sample Availability:
                            </label>
                            <div className="col-lg-9 col-xl-9">
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorPrimary: "#552278"
                                  },
                                }}
                              >
                                <Switch defaultChecked onChange={toggleAvailability} style={{ borderRadius: "100px" }} checkedChildren="Yes" unCheckedChildren="No" />
                              </ConfigProvider>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end::Body */}
                  </div>

                  <div className="card mt-10" id="card-section-5">
                    <div className="card-body d-flex align-items-center py-8">
                      {/* begin::Checkbox */}
                      <div className="form-check form-check-custom form-check-solid mb-5">
                        {/* begin::Input */}
                        <input
                          className="form-check-input me-3"
                          type="checkbox"
                          name="declaration"
                          id="declaration"
                          checked={declaration}
                          onChange={(_event) =>
                            set_declaration(_event.target.checked)
                          }
                        />
                        {/* end::Input */}

                        {/* begin::Label */}
                        <label className="form-check-label" htmlFor="ex1">
                          <div className="fw-semibold text-gray-800">
                            All submitted information including pictures and
                            data are true, valid and legal. Supply Zone has the
                            right to take down illegal products and products
                            with falsified information.
                          </div>
                        </label>
                        {/* end::Label */}
                      </div>
                      {/* end::Checkbox */}
                    </div>
                  </div>

                  <div className="card mt-10" id="card-section-6">
                    <div className="card-body d-flex align-items-center py-8">
                      {/* begin::Footer */}
                      <div className="row">
                        <div className="col-lg-12 col-xl-12">
                          <button
                            type="button"
                            onClick={saveProduct}
                            className="btn btn-primary me-4"
                          >
                            {!savingProduct && (
                              <span className="indicator-label">
                                Save Product
                              </span>
                            )}
                            {savingProduct && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please wait...{" "}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                      {/* end::Footer */}
                    </div>
                  </div>
                </div>

                <div
                  className={`col-md-3 progress-stepper-container  ${progressStepperAddionalClass}`}
                  style={{ maxWidth: progressStepperMaxWidth }}
                >
                  <div className="card mb-10 p-5">
                    <h3>Product Score</h3>

                    <Row>
                      <Col flex="auto"></Col>
                      <Col flex="auto">
                        <Progress
                          type="dashboard"
                          percent={gaugePercentage * 100}
                          size={240}
                        />
                      </Col>
                      <Col flex="auto"></Col>
                    </Row>

                    <p>
                      We recommend to obtain at least 80 points. A higher score,
                      means a higher product ranking and better exposure to
                      buyers
                    </p>
                    <ul style={{ padding: 0, margin: 0 }}>
                      <li
                        className={
                          `progress-stepper-wrapper ` +
                          (productScoreTab === 1 ? `active` : ``)
                        }
                      >
                        <span
                          className={
                            `progress-stepper ` +
                            (productScoreTab === 1 ? `active` : ``)
                          }
                          onClick={(e) => {
                            setProductScoreTab(1);
                          }}
                        >
                          Primary Product Information
                        </span>
                        <span className="progress-stepper-value">
                          {segA}/70
                        </span>
                      </li>
                      <li
                        className={
                          `progress-stepper-wrapper ` +
                          (productScoreTab === 2 ? `active` : ``)
                        }
                      >
                        <span
                          className={
                            `progress-stepper ` +
                            (productScoreTab === 2 ? `active` : ``)
                          }
                          onClick={(e) => {
                            setProductScoreTab(2);
                          }}
                        >
                          Price and Order Information
                        </span>
                        <span className="progress-stepper-value">
                          {segB}/28
                        </span>
                      </li>
                      <li
                        className={
                          `progress-stepper-wrapper ` +
                          (productScoreTab === 3 ? `active` : ``)
                        }
                      >
                        <span
                          className={
                            `progress-stepper ` +
                            (productScoreTab === 3 ? `active` : ``)
                          }
                          onClick={(e) => {
                            setProductScoreTab(3);
                          }}
                        >
                          Sample Policy
                        </span>
                        <span className="progress-stepper-value">{segC}/2</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
            {/* end::Form */}
          </div>
        </div>
      </main>
      {contextHolder}
    </>
  );
};

export default ProductCreatePage;
