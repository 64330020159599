import { PlayCircleFilled, PlaySquareOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import * as React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { Navigation, Pagination, Scrollbar, A11y, Thumbs, FreeMode, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './swiper.css'
function getYouTubeVideoId(link) {
  if (link == null) return ""
  //check if valid youtube link
  if (!link.replace(".", "").includes("youtube")) {
    return ""
  }
  //check if link like 'https://www.youtube.com/watch?v=wnIn0PogM0c'
  if (link.includes("=")) {
    let parts = link.split("=")
    return parts[parts.length - 1]
  }
  //we assume link is like this 'https://www.youtube.com/watch/wnIn0PogM0c'
  let parts = link.split("/")
  return parts[parts.length - 1]
}
export default ({ product }) => {
  const [modalShown, setModalShown] = React.useState(false)
  const [youtubeUrl, setYoutubeUrl] = React.useState("")

  React.useEffect(() => {
    const element = document.getElementById('player1');
    if (modalShown) {
      element.src = youtubeUrl
    } else {
      if (element) {
        element.src = ""
      }
    }
  }, [modalShown]);

  const [sliderImages, setSliderImages] = React.useState([])
  const [currentSliderImage, setCurrentSliderImage] = React.useState("")
  React.useEffect(() => {
    if (product.name) {
      const images = []
      if (getYouTubeVideoId(product.productVideoURL) != "") {
        images.push(`http://i3.ytimg.com/vi/${getYouTubeVideoId(product.productVideoURL)}/hqdefault.jpg`)
      }
      product.productFileAttachments.map((productImage: any) => {
        if (productImage.s3Key !== 'string') {
          images.push(`https://api-live.supplyzone.ai//home/product-attachment/${productImage.s3Key}`)
        }
      })

      setCurrentSliderImage(images[0])
      setSliderImages(images)

    }
  }, [product]);


  const showModal = (youtubeUrl: string) => {
    setYoutubeUrl(youtubeUrl)
    setModalShown(true);
  };
  const closePlayerModal = () => {
    //console.log("close")
    //document.getElementById("player1").src = ""
    setModalShown(false);
  };
  const [zoomStyle, setZoomStyle] =  React.useState({backgroundImage: `url(${currentSliderImage})`,backgroundPosition: '0% 0%',display:'none'})

  const handleMouseMove = (e:any) => {
    if(currentSliderImage.includes('i3.ytimg.com') ) {
      return;
    }
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.pageX - left) / width * 100
    const y = (e.pageY - top) / height * 100
    setZoomStyle({backgroundImage: `url(${currentSliderImage})`,backgroundPosition: `${x}% ${y}%`,display:'block' })
  }
  const handleMouseOut = () => {
    setZoomStyle({backgroundImage: `url()`,backgroundPosition: `0% 0%`,display:'none' })
  }
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  return (
    <>
      {sliderImages.length > 0 ? (
        <>
          <div className="col-md-6 mb-6">
            <div className="product-gallery product-gallery-sticky">
              <div className="swiper-container product-single-swiper  swiper-theme nav-inner">
                <div className="swiper-slide swiper-slide-active" style={{ height: "400px" }}>


                  <img src={`${currentSliderImage}`} className="product-simple" width="800" height="900" alt={product.name} 
                   onMouseMove={(e)=>handleMouseMove(e)} onMouseOut={()=>handleMouseOut()} />

                  {currentSliderImage.includes('i3.ytimg.com') && (
                    <PlayCircleFilled style={{ position: "absolute", top: "38%", right: "42%", fontSize: "70px", color: "red" }}
                      onClick={() => { showModal(`https://www.youtube.com/embed/${getYouTubeVideoId(product.productVideoURL)}?enablejsapi=1&html5=1&rel=0`) }} />
                  )}

                </div>
              </div>


              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                navigation={true}
                className="product-thumbs-wrap swiper-container"
              >

                {sliderImages.map((sliderImage: any, swiperIndex: number) => (
                  <SwiperSlide >
                    <img
                      src={`${sliderImage}`}
                      className={`product-simple`}
                      alt={product.name} width="800" height="900"
                      onClick={() => setCurrentSliderImage(sliderImage)} 
                      onMouseOver={()=>setCurrentSliderImage(sliderImage) }
                      />
                    {sliderImage.includes('i3.ytimg.com') && (
                      <PlayCircleFilled style={{ position: "absolute", top: "38%", right: "42%", fontSize: "20px", color: "red" }}
                      onClick={() => setCurrentSliderImage(sliderImage)}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>


            </div>
          </div >
          <div className="zoom-container" style={zoomStyle}></div>
        </>
      ) : (
        <SkeletonTheme height="50">
          <div className="col-md-6 mb-6">
            {<Skeleton count={1} height={300} />}
            {<Skeleton count={1} height={30} />}
          </div>
        </SkeletonTheme>
      )
      }

      <Modal open={modalShown} onCancel={closePlayerModal}
        width={550}
        footer={(_, { }) => (
          <></>
        )}
      >
        <iframe id="player1" frameBorder={0}
          allowFullScreen iframe-video style={{ width: "500px", height: "300px", marginTop: "25px" }}></iframe>
      </Modal>
    </>
  );
};