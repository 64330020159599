/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import Skeleton from "react-loading-skeleton";
import Snippets from "../../../../helpers/Snippets";
import Search, { SearchProps } from "antd/es/input/Search";
import { AutoComplete, Input, Pagination } from "antd";
import { useAppDataContext } from "../../../../data";
import "./manage-products.scss"
interface DocumentListItemProps {
  children?: React.ReactNode;
}

const ProductManagementPage: FC<DocumentListItemProps> = ({
  children,
  ...props
}) => {

  const {
    userData,
    userProfile
  } = useAppDataContext();

  // eslint-disable-next-line no-empty-pattern
  const { }: any = props;

  const [listItems, setListItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  function dynamicSort(property: any) {
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a: any, b: any) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }
  const getListData: Function = (page: number, size: number) => {
    setIsLoading(true);
    page = page - 1
    AxiosAPI
      .apiRequest('get', `/supplier/${userData.profile.id}/products?page=${page}&size=${size}`, {})
      .then((res) => {
        let page = res.data
        setListItems(page.content)
        setTotalProducts(page.totalElements)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    getListData(currentPage, productsPerPage)

  }, [])

  const onSearch = (value: string) => {

    if (value.length > 3) {

      setSearching(true)
      setIsLoading(true);
      AxiosAPI.apiRequest('get', `/supplier/search-products/0/30/${value}`, {})
        .then((res) => {
          let page = res.data
          setListItems(page)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })

    }
    if (value.length == 3 && listItems.length == 0) {
      getListData(currentPage, productsPerPage)
      setSearching(false)
    }

  }
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [anotherOptions, setAnotherOptions] = useState<{ value: string }[]>([]);

  const [totalProducts, setTotalProducts] = useState(0)
  const [productsPerPage, setProductsPerPage] = useState(50)
  const [currentPage, setCurrentPage] = useState(1)
  const [searching, setSearching] = useState(false)

  const pageChanged = (page: number, size: number) => {
    setCurrentPage(page)
    getListData(page, size)
    setProductsPerPage(size)
  }
  return (
    <>
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1 appear-animatex mb-1">
            <div className="title-link-wrapper pb-1 mb-1">
              <h2 className="title ls-normal mb-0 mr-5">My Products</h2>

              <input
                type="text"
                className="form-control  form-control-solid"
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                style={{ width: "25%", marginTop: "5px", borderRadius: "8px" }}
                placeholder="Search for product"
              />

              <a
                href="/products/create"
                className="btn btn-primary pl-3 pr-3 font-size-normal font-weight-bold ls-25 mb-0 xaction-btn"
              >
                Create Product
              </a>
            </div>
          </div>

          <div className="row mt-10">

            {isLoading ? (
              <Skeleton count={5} height={100} className="mb-5 rounded-2" />
            ) : (
              <>



                <form className='form'>
                  {!searching && (
                    <div className="row mt-2 mb-2 text-right">
                      <div className="col-md-12">
                        <Pagination
                          showSizeChanger
                          current={currentPage}
                          pageSize={productsPerPage}
                          total={totalProducts}
                          onChange={pageChanged}
                          pageSizeOptions={[25, 50, 75, 100]}
                        />
                      </div>
                    </div>
                  )}

                  {listItems.length > 0 ? (
                    listItems.map((listItem: any, listItemIndex: number) => (
                      <div key={listItemIndex} className='card shadow-sm mb-5'>
                        <div className='card-body d-flex align-items-center py-8'>
                          <div className='row w-100'>
                            <div className='col-md-1 text-center'>
                              <img
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null // prevents looping
                                  currentTarget.src =
                                    'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png'
                                }}
                                src={`${Snippets.urls.getAPIBaseURL()}/home/product-attachment/${listItem.imageUrl}`}
                                className={`product-simple product-id-${listItem.id}`}
                                alt={listItem.name}
                                width='100%'
                                style={{ maxHeight: "70px", height: "auto", width: "auto", maxWidth: "100%" }}
                              />
                            </div>
                            <div className='col-md-3 r-list-view'>
                              <span>
                                <strong>
                                  {listItem.name.length > 22
                                    ? listItem.name.slice(0, 20) + '...'
                                    : listItem.name}
                                </strong>
                              </span>
                              <br />
                              <span>
                                <strong>Model No:</strong> {listItem.modelNumber}
                              </span>
                              <br />
                              <span>
                                <strong>SKU Number:</strong> SZ {listItem.id.toString().padStart(6, 0)}
                              </span>
                              <br />
                            </div>
                            <div className='col-md-3 r-list-view'>
                              <span>
                                <strong>Category:</strong> {listItem.category}
                              </span>
                              <br />
                              {(listItem.pricingOption == "LADDER_PRICE" || listItem.pricingOption == "PRICE_RANGE") && (
                                <span>
                                  <strong>Price:</strong> US $
                                  {listItem.prices != null
                                    ? listItem.prices.sort(dynamicSort('price'))[0].price.toFixed(2)
                                    : '0'}{' '}
                                  - $
                                  {listItem.prices != null
                                    ? listItem.prices
                                      .sort(dynamicSort('price'))
                                    [listItem.prices.length - 1].price.toFixed(2)
                                    : '0'}
                                </span>
                              )}
                              {listItem.pricingOption == "FIXED" && (
                                <span>
                                  <strong>Price:</strong> US $
                                  {listItem.prices != null
                                    ? listItem.prices.sort(dynamicSort('price'))[0].price.toFixed(2)
                                    : '0'}{' '}
                                </span>
                              )}
                              <br />
                              <span>
                                <strong>Status:</strong> {listItem.status}
                              </span>
                              <br />
                            </div>
                            <div className='col-md-3 r-list-view'>
                              <span>
                                <strong>Supplier:</strong> {userData.profile.name}
                              </span>
                              <br />
                              <span>
                                <strong>UoM:</strong> {listItem.unitOfMeasurement}
                              </span>
                              <br />
                              <span>
                                <strong>Date:</strong> {listItem.updatedAt.slice(0, 10)}
                              </span>
                              <br />
                            </div>
                            <div className='col-md-2'>
                              <a
                                href={`/products/create/${listItem.id}`}
                                target='_self'
                                className='btn btn-sm btn-primary me-4 w-100'
                                style={{ marginBottom: '10px', borderRadius: '10px' }}
                              >
                                <span className='indicator-label'>Edit Details</span>
                              </a>
                              <br />
                              <a
                                href={`/product/${Snippets.string.toSlug(listItem.category)}/${Snippets.string.toSlug(listItem.name)}/${listItem.id}`}
                                target='_self'
                                className='btn btn-sm btn-outline btn-outline-solid btn-outline-primary btn-active-light-primary me-4 w-100'
                                style={{ borderRadius: '10px' }}
                              >
                                <span className='indicator-label'>View Details</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='card shadow-sm mb-5'>
                      <div className='card-body py-8'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className={'flex-box-center-children'}>
                              <img
                                src={'/media/blank-box.png'}
                                style={{ margin: 'auto', width: '100%', maxWidth: '300px' }}
                              />
                              <h3 className={'mt-10'}>No items to show</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
                {/* end::Form */}
              </>
            )}

          </div>
        </div>
      </main>
    </>
  );
};

export default ProductManagementPage;
