import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Button,
  Row,
  Col,
  Skeleton,
  Divider,
  Badge,
  message,
  Card,
  Segmented,
} from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";

interface PlanItem {
  title: string;
  subTitle: string;
  featured: boolean;
  featuredText: string;
  pricePrefix: string;
  buttonText: string;
  buttonColor: string;
  priceAmountMonthly: number;
  priceAmountAnnually: number;
  priceAmountAnnuallyM: number;
  priceDiscount: number;
  features: Array<string>;
  footer: string;
}

const PriceTables2: any = ({
  proceedURL,
  supplierActivationComplete,
  rfqId,
}: any) => {
  const [plans, setPlans] = useState({sme:[], corporate: []});

  const [isPageLoading, setIsPageLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const getPlans: any = () => {
    setIsPageLoading(true);
    AxiosAPI.getChildCategoryList(0)
      .then((res) => {
        console.log(":: CATEGORIES  ::", res.data.children);
        setPlans(
          res.data.children
            .filter((cat: any) => cat.image !== null)
            .filter((cat: any) => cat.name !== "SUPER")
            .filter((cat: any) => cat.name !== "HORECA")
        );
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log(":: CATEGORIES ERROR::", error);
        setIsPageLoading(false);
      });
  };

  const subscribePlan: any = (plan: PlanItem) => {
    console.log("::plan.priceAmountAnnually > 0::");
    if (plan.priceAmountAnnually > 0) {
      messageApi.open({
        type: "warning",
        content: "Please contact support@supplyzone.ai",
      });
    } else {
      supplierActivationComplete();
    }
  };

  const [billSemiAnnually, setBillSemiAnnually] = useState(false);

  const [isSME, setIsSME] = useState(true);

  useEffect(() => {
    // getPlans();

    setPlans({
      sme: [
        {
          title: "Invited Supplier",
          subTitle: "Start selling B2B online with ease",
          featured: false,
          featuredText: "",
          buttonText: "Continue",
          buttonURL: proceedURL,
          buttonColor: "green",
          pricePrefix: "$",
          priceAmountMonthly: 0,
          priceAmountAnnually: 0,
          priceDiscount: 15,
          features: [
            "Respond to RFQs from invitor",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "3 categories",
            "5 users",
          ],
          footer: "",
        },
        {
          title: "Bronze",
          subTitle: "Scale your business to new heights",
          featured: false,
          featuredText: "20% off — Special Offer",
          buttonText: "Upgrade Now",
          buttonURL: "/supplier/request-inspection",
          buttonColor: "purple",
          pricePrefix: "$",
          priceAmountMonthly: 160,
          priceAmountAnnually: 1920,
          priceAmountAnnuallyM: 140,
          priceDiscount: 0,
          features: [
            "Unrestricted access to RFQs",
            "20 RFQ responses / month",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "6 categories",
            "5 users",
            "Basic business verification",
          ],
          footer:
            "* Cost shown includes the one-time Keywords advertising for an amount of $500 to help you have a better performance.",
        },
        {
          title: "Silver",
          subTitle: "Accelerate your growth as a reliable stockist",
          featured: true,
          featuredText: "MOST POPULAR",
          buttonText: "Upgrade Now",
          buttonURL: "/supplier/request-inspection",
          buttonColor: "purple",
          pricePrefix: "$",
          priceAmountMonthly: 270,
          priceAmountAnnually: 3240,
          priceAmountAnnuallyM: 240,
          priceDiscount: 0,
          features: [
            "Unrestricted access to RFQs",
            "40 RFQ responses / month",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "9 categories",
            "5 users",
            "Pro business verification",
            "Max 5,000 products listed",
            "5 product showcase slots",
          ],
          footer: "",
        },
        {
          title: "Gold",
          subTitle: "Gain instant trust with top-level features",
          featured: false,
          featuredText: "",
          buttonText: "Upgrade Now",
          buttonURL: "/supplier/request-inspection",
          buttonColor: "purple",
          pricePrefix: "$",
          priceAmountMonthly: 480,
          priceAmountAnnually: 5760,
          priceAmountAnnuallyM: 440,
          priceDiscount: 0,
          features: [
            "Unrestricted access to RFQs",
            "80 RFQ responses / month",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "Unlimited categories",
            "5 users",
            "10 point onsite inspection",
            "Unlimited products listed",
            "10 product showcase slots",
            "Gold supplier badge",
            "Traffic booster service",
          ],
          footer: "",
        },
      ],
      corporate: [
        {
          title: "Invited Supplier",
          subTitle: "Start selling B2B online with ease",
          featured: false,
          featuredText: "",
          buttonText: "Continue",
          buttonURL: proceedURL,
          buttonColor: "green",
          pricePrefix: "$",
          priceAmountMonthly: 0,
          priceAmountAnnually: 0,
          priceDiscount: 15,
          features: [
            "Respond to RFQs from invitor",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "3 categories",
            "5 users",
          ],
          footer: "",
        },
        {
          title: "Bronze",
          subTitle: "Gain instant trust with top-level features",
          featured: false,
          featuredText: "",
          buttonText: "Upgrade Now",
          buttonURL: "/supplier/request-inspection",
          buttonColor: "purple",
          pricePrefix: "$",
          priceAmountMonthly: 480,
          priceAmountAnnually: 5760,
          priceAmountAnnuallyM: 440,
          priceDiscount: 0,
          features: [
            "Unrestricted access to RFQs",
            "200 RFQ responses / month",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "Unlimited categories",
            "10 users",
            "10 point onsite inspection",
            "Unlimited products listed",
            "20 product showcase slots",
            "$500 advertising credit",
            "Traffic booster service",
          ],
          footer: "",
        },
        {
          title: "Silver",
          subTitle: "Gain instant trust with top-level features",
          featured: false,
          featuredText: "",
          buttonText: "Upgrade Now",
          buttonURL: "/supplier/request-inspection",
          buttonColor: "purple",
          pricePrefix: "$",
          priceAmountMonthly: 916,
          priceAmountAnnually: 10992,
          priceAmountAnnuallyM: 825,
          priceDiscount: 0,
          features: [
            "Unrestricted access to RFQs",
            "400 RFQ responses / month",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "Unlimited categories",
            "20 users",
            "10 point onsite inspection",
            "Unlimited products listed",
            "40 product showcase slots",
            "$2,000 advertising credit",
            "Maximum Traffic booster ",
          ],
          footer: "",
        },
        {
          title: "Gold",
          subTitle: "Gain instant trust with top-level features",
          featured: false,
          featuredText: "",
          buttonText: "Upgrade Now",
          buttonURL: "/supplier/request-inspection",
          buttonColor: "purple",
          pricePrefix: "$",
          priceAmountMonthly: 1833,
          priceAmountAnnually: 21996,
          priceAmountAnnuallyM: 1650,
          priceDiscount: 0,
          features: [
            "Unrestricted access to RFQs",
            "Unlimited RFQ responses / month",
            "Instant messaging service",
            "Dedicated customer support",
            "0% sales commission",
            "Unlimited categories",
            "Unlimited users",
            "10 point onsite inspection",
            "Unlimited products listed",
            "60 product showcase slots",
            "Gold supplier badge",
            "$4,000 advertising credit",
            "Maximum Traffic booster ",
          ],
          footer: "",
        },
      ],
    });
  }, []);

  const formatCurrency: any = (amount: number, plain: boolean = false) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (plain) {
      return formatter
        .format(amount)
        .toString()
        .replace(".00", "")
        .replace("$", "")
        .replace("NaN", "0");
    } else {
      return formatter.format(amount);
    }
  };

  const getDiscounted: any = (priceAmount: number, priceDiscount: number) => {
    // Validate input
    if (priceAmount < 0 || priceDiscount < 0 || priceDiscount > 100) {
      throw new Error(
        "Invalid input: priceAmount must be non-negative and priceDiscount must be between 0 and 100."
      );
    }

    // Calculate the discount
    const discountAmount = (priceAmount * priceDiscount) / 100;
    const discountedPrice = priceAmount - discountAmount;

    return parseFloat(discountedPrice.toFixed(2)); // Return the discounted price rounded to 2 decimal places
  };

  return (
    <>
      {contextHolder}
      {!isPageLoading ? (
        <>
          <Row>
            <Col className="w-100 text-center">
              <h2 className="mb-5 mb-3">Select a plan to start</h2>
              <h4 className="mt-1 mb-2">
                Pay semi annually, annually or cancel at any time.
              </h4>
            </Col>
            <Col className="switcher mt-3 mb-2">
              <Segmented<string>
                options={["SMEs", "Corporates"]}
                onChange={(value) => {
                  setIsSME(value === "SMEs");
                }}
              />
              <h4 className="mt-5 w-100 text-center">
                <a
                  href="#"
                  onClick={() => {
                    setBillSemiAnnually(!billSemiAnnually);
                  }}
                >
                  Show {billSemiAnnually ? "annual" : "semi-annual"} prices
                </a>
              </h4>
            </Col>
          </Row>
          <Row
            className="w-100 m-auto pt-2 pb-2"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            {plans[isSME ? "sme" : "corporate"].map(
              (plan: PlanItem, planIndex: number) => (
                <Col
                  key={planIndex}
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  xl={6}
                  className="text-left text-align-left"
                >
                  <Badge.Ribbon
                    className={`plan-ribbon ${plan.featured ? "" : "d-none"}`}
                    text={plan.featuredText}
                    color="#542278"
                  >
                    <Card
                      className={`xcards category category-classic category-absolute w-100 mh-664 pr-1 pl-1`}
                    >
                      <div className="text-center">
                        <h2 className="mt-3 mb-3">{plan.title}</h2>
                        <p className="mb-5 text-center w-70">{plan.subTitle}</p>

                        <h3 className="plan-price">
                          {plan.pricePrefix}{" "}
                          {formatCurrency(
                            billSemiAnnually
                              ? plan.priceAmountMonthly
                              : plan.priceAmountAnnuallyM,
                            true
                          )}
                          <small className="">/mo</small>
                        </h3>

                        {plan.priceAmountMonthly > 0 ? (
                          <p className="mt-2 mb-2 text-center w-100">
                            billed{" "}
                            {billSemiAnnually ? "semi-annually" : "annually"} at
                            <br />
                            {formatCurrency(
                              billSemiAnnually
                                ? plan.priceAmountMonthly * 12
                                : plan.priceAmountAnnuallyM * 12
                            )}
                          </p>
                        ) : (
                          <p className="mt-2 mb-2 text-center w-100">
                            &nbsp;
                            <br />
                            &nbsp;
                          </p>
                        )}

                        <Button
                          type="primary"
                          size="large"
                          className={`mt-5 mb-5 ${
                            plan.priceAmountAnnually > 0
                              ? "tooltipx upgrade bottom"
                              : ""
                          }  btnx ${plan.buttonColor}`}
                          block
                          onClick={() => subscribePlan(plan)}
                        >
                          {plan.buttonText}
                        </Button>
                      </div>
                      <h4>Includes</h4>
                      <ul className="pricing-feature-ul">
                        {plan.features.map(
                          (feature: string, featureIndex: number) => (
                            <li
                              key={featureIndex}
                              className="pricing-feature-item-list"
                              style={{ listStyle: "none" }}
                            >
                              <img
                                src="https://png.pngtree.com/png-clipart/20220103/original/pngtree-green-check-mark-icon-tick-yes-symbol-positive-accept-approve-vector-png-image_7020678.png"
                                className="pricing-feature-item-bullet"
                              />
                              <span className="pricing-feature-item-text fs-16 lh-2">
                                {feature}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              )
            )}
          </Row>
          {!Number.isNaN(parseInt(rfqId)) && (
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <Button
                  type="primary"
                  size="large"
                  block
                  className={`mt-2 mb-2 w-100 btnx green`}
                  onClick={supplierActivationComplete}
                >
                  Continue
                </Button>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-3"></div>
            </div>
          )}
        </>
      ) : (
        <Row
          className="w-100 m-auto pt-5 pb-5"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          {[1, 2, 3].map((_: any, categoryIndex: number) => (
            <Col key={categoryIndex} xs={24} sm={12} md={8} lg={8} xl={8}>
              <div
                className={`xcards category category-classic category-absolute w-100`}
              >
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </div>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};
export { PriceTables2 };
