import * as React from "react";
import { useEffect } from "react";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import ProductCategory from "../Products/Widgets/ProductCategory";
import ProductFeatured from "../Products/Widgets/ProductFeatured";
import ProductFeaturedMain from "../Products/Widgets/ProductFeaturedMain";
import ProductsDoubleSlider8UP from "../Products/Widgets/ProductsDoubleSlider8UP";
import ProductsDoubleSlider4UP from "../Products/Widgets/ProductsDoubleSlider4UP";
import ProductsSlider3UP from "../Products/Widgets/ProductsSlider3UP";
import ProductCategory2 from "../Products/Widgets/ProductCategory2";
import RFQFormBanner from "./RFQFormBanner";
import { isMobile } from "react-device-detect";
import { useDeviceDetect } from "use-device-detect";
import ProductCategoryDrillDown from "../Products/Widgets/ProductCategoryDrillDown";
import Snippets from "../../../../helpers/Snippets";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import LegalTerms from "./legal/LegalTerms";
import LegalCookies from "./legal/LegalCookies";
import LegalSecurity from "./legal/LegalSecurity";
import LegalPrivacy from "./legal/LegalPrivacy";
import LegalIP from "./legal/LegalIP";

export default () => {
  const [documentTitle, setDocumentTitle] = React.useState("");
  const [legalDoc, setLegalDoc] = React.useState("");

  useEffect(() => {
    const urlSegment = window.location.href.split("/");

    switch (urlSegment[urlSegment.length - 1]) {
      case "terms": {
        setDocumentTitle("Terms and Conditions");

        setLegalDoc("terms");

        break;
      }

      case "privacy": {
        setDocumentTitle("Privacy Policy");

        setLegalDoc("privacy");

        break;
      }

      case "security": {
        setDocumentTitle("Security Measures");

        setLegalDoc("security");

        break;
      }

      case "ip": {
        setDocumentTitle("IP Policy");

        setLegalDoc("ip");

        break;
      }

      case "cookies": {
        setDocumentTitle("Cookie Policy");

        setLegalDoc("cookies");

        break;
      }

      default: {
        setDocumentTitle("Terms of Use");

        setLegalDoc("terms");

        break;
      }
    }
  }, []);

  return (
    <>
      <main>
        <div className="container pb-2">
          <div className="intro-wrapper">
            <div
              className="legal-doc mt-4"
              style={{
                border: "1px solid rgb(245, 245, 245)",
                margin: "10px 0px",
                padding: "25px",
              }}
            >
              <div className="title-link-wrapper mb-3">
                <h2 className="title mb-0 pt-2 pb-2">{documentTitle}</h2>
              </div>
              <div className="px-4 py4">
                {legalDoc === "terms" && <LegalTerms />}

                {legalDoc === "cookies" && <LegalCookies />}

                {legalDoc === "security" && <LegalSecurity />}

                {legalDoc === "privacy" && <LegalPrivacy />}

                {legalDoc === "ip" && <LegalIP />}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
