import React, { FC } from "react";
import { PricingOnePager } from "./PricingOnePager";

const PricingPageWrapper: FC = () => {
  return (
    <>
      <PricingOnePager />
    </>
  );
};

export default PricingPageWrapper;
