import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Button,
  Flex,
  Tabs,
  Input,
  Select,
  Radio,
  Switch,
  Pagination,
  PaginationProps,
} from "antd";
import Skeleton from "react-loading-skeleton";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import {
  AppstoreOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { RFQListItem } from "./RFQListItem";
import { useAppDataContext } from "../../../../data/context/AppDataContext";
import RFQFreeText from "./RFQFreeText";
import RFQPagination from "./RFQPagination";
import { isMobile } from "react-device-detect";
import Snippets from "../../../../helpers/Snippets";

const RFQOnePager: React.FC = () => {
  const {
    isLoginModalOpen,
    setIsLoginModalOpen,
    userAccessToken,
    setUserAccessToken,
    userDisplayName,
    setUserDisplayName,
    userEmailAddress,
    setUserEmailAddress,
    userType,
    setUserType,
    userData,
    setUserData,
    userProfile,
    setUserProfile,
    isLoggedIn,
    setIsLoggedIn,
    loginUser,
    logoutUser,
    profileCounters,
    getAllDocuments,
  } = useAppDataContext();

  const [isPageLoading, setIsPageLoading] = useState(true);

  const [isClearingFilters, setIsClearingFilters] = useState(false);

  const [listItems, setListItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(1); // items per chunk

  const [currentResultsTotal, setCurrentResultsTotal] = useState(0); // items per chunk

  const [perChunk, setPerChunk] = useState(5); // items per chunk

  const [chunkedArray, setChunkedArray] = useState([[]]);

  const [isNewestFirst, setIsNewsetFirst] = useState(false);

  const chunkArray: any = (inputArray: any) => {
    if (inputArray === undefined) {
      return;
    }
    return inputArray.reduce((resultArray: any, item: any, index: any) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  };

  const [showingPageStringHeading, setShowingPageStringHeading] = useState("");
  const [showingPageString, setShowingPageString] = useState("");

  const paginateRFQs: any = (list: any) => {
    setCurrentResultsTotal(list.length);
    setFilteredList(list);
    const _chunkedArray: any = chunkArray(list);
    setChunkedArray(_chunkedArray);
    console.log("::: MARKETPLACE RFQS :::", _chunkedArray);

    const start = (currentPage - 1) * perChunk + 1;
    const end = Math.min(currentPage * perChunk, currentResultsTotal);

    setShowingPageString(
      `Showing ${start} - ${end} results of ${currentResultsTotal}`
    );
  };

  const [filteredList, setFilteredList] = useState([]);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    console.log("onShowSizeChange", current, pageSize);
    setPerChunk(pageSize);
    setCurrentPage(current);
    setTimeout(() => {
      paginateRFQs(filteredList, current, pageSize);
    }, 500);
  };

  const onShowPageChange: PaginationProps["onChange"] = (current, pageSize) => {
    console.log("onShowPageChange", current, pageSize);
    setPerChunk(pageSize);
    setCurrentPage(current);
    setTimeout(() => {
      paginateRFQs(filteredList, current, pageSize);
    }, 500);
  };
  const getListData: Function = () => {
    const _params: any = {
      p: 1,
      q: "",
      s: 50000,
    };

    AxiosAPI.getMarketplaceRFQs(_params)
      .then((res: any) => {
        let listData = res.data;

        if (isNewestFirst) {
          listData = listData.reverse();
        }

        setListItems(listData);
        setIsPageLoading(false);
        paginateRFQs(listData, currentPage, perChunk);
      })
      .catch((error: any) => {
        console.log("::: LIST DATA FETCH ERROR :::", error);
        setIsPageLoading(false);
      });
  };

  const [supplierCategories, setSupplierCategories] = useState([]);

  const getProfileData: Function = () => {
    AxiosAPI.getProfileData()
      .then((res: any) => {
        let usr = res.data;
        setSupplierCategories(
          usr.categories.map((cat: any) => {
            return cat.id;
          })
        );
      })
      .catch((error: any) => {
        console.log("::: USER PROFILE FETCH ERROR :::", error);
      });
  };

  const [categoryListArray, setCategoryListArray] = useState([]);

  const [categoryList, setCategoryList] = useState([]);

  const [categoriesValueList, setCategoriesValueList] = useState([]);

  const [filterRFQValue, setFilterRFQValue] = useState("");

  const [filterOrgSize, setFilterOrgSize] = useState("");

  const [filterCategories, setFilterCategories] = useState([]);

  const [filterSector, setFilterSector] = useState([]);

  const [filterLocation, setFilterLocation] = useState([]);

  const [filterKeywords, setFilterKeywords] = useState("");

  const [filterExpiry, setFilterExpiry] = useState(false);

  const [filterFavourite, setFilterFavourite] = useState(false);

  const arrangeCategories: any = (_categoryList: any) => {
    let cats = _categoryList.map((category: any) => {
      return {
        value: category.id,
        label: category.name,
      };
    });
    console.log(":: arrangeCategories ::", _categoryList, cats);
    setCategoriesValueList(cats);
  };

  useEffect(() => {
    console.log(":: USE EFFECT :: arrangeCategories ::", categoryList);
    arrangeCategories(categoryListArray);
  }, [categoryListArray]);

  const [showListView, setShowListView] = useState(true);

  useEffect(() => {
    getListData();
    getCategoryListData();
    getSectorListData();
    getProfileData();
  }, []);

  const [categoryListData, setCategoryListData] = useState([]);

  const getCategoryListData = () => {
    /* FFs */
    AxiosAPI.publicApiRequest("get", "/model/group-categories/top", null)
      .then(({ data: categories }) => {
        setCategoryListData(
          categories.map((cat: any) => {
            return {
              label: cat.name,
              value: `${cat.id}-${cat.name}`,
            };
          })
        );
      })
      .catch((error) => {});
  };

  const [sectorListData, setSectorListData] = useState([]);

  const getSectorListData: any = () => {
    const industrySectors: any = [
      { value: "Accounting" },
      { value: "Airlines/Aviation" },
      { value: "Alternative Dispute Resolution" },
      { value: "Alternative Medicine" },
      { value: "Animation" },
      { value: "Apparel & Fashion" },
      { value: "Architecture & Planning" },
      { value: "Arts & Crafts" },
      { value: "Automotive" },
      { value: "Aviation & Aerospace" },
      { value: "Banking" },
      { value: "Biotechnology" },
      { value: "Broadcast Media" },
      { value: "Building Materials" },
      { value: "Business Supplies & Equipment" },
      { value: "Capital Markets" },
      { value: "Chemicals" },
      { value: "Civic & Social Organization" },
      { value: "Civil Engineering" },
      { value: "Commercial Real Estate" },
      { value: "Computer & Network Security" },
      { value: "Computer Games" },
      { value: "Computer Hardware" },
      { value: "Computer Networking" },
      { value: "Computer Software" },
      { value: "Construction" },
      { value: "Consumer Electronics" },
      { value: "Consumer Goods" },
      { value: "Consumer Services" },
      { value: "Cosmetics" },
      { value: "Dairy" },
      { value: "Defense & Space" },
      { value: "Design" },
      { value: "Education Management" },
      { value: "E-learning" },
      { value: "Electrical & Electronic Manufacturing" },
      { value: "Entertainment" },
      { value: "Environmental Services" },
      { value: "Events Services" },
      { value: "Executive Office" },
      { value: "Facilities Services" },
      { value: "Farming" },
      { value: "Financial Services" },
      { value: "Fine Art" },
      { value: "Fishery" },
      { value: "Food & Beverages" },
      { value: "Food Production" },
      { value: "Fundraising" },
      { value: "Furniture" },
      { value: "Gambling & Casinos" },
      { value: "Glass, Ceramics & Concrete" },
      { value: "Government Administration" },
      { value: "Government Relations" },
      { value: "Graphic Design" },
      { value: "Health, Wellness & Fitness" },
      { value: "Higher Education" },
      { value: "Hospital & Health Care" },
      { value: "Hospitality" },
      { value: "Human Resources" },
      { value: "Import & Export" },
      { value: "Individual & Family Services" },
      { value: "Industrial Automation" },
      { value: "Information Services" },
      { value: "Information Technology & Services" },
      { value: "Insurance" },
      { value: "International Affairs" },
      { value: "International Trade & Development" },
      { value: "Internet" },
      { value: "Investment Banking/Venture" },
      { value: "Investment Management" },
      { value: "Judiciary" },
      { value: "Law Enforcement" },
      { value: "Law Practice" },
      { value: "Legal Services" },
      { value: "Legislative Office" },
      { value: "Leisure & Travel" },
      { value: "Libraries" },
      { value: "Logistics & Supply Chain" },
      { value: "Luxury Goods & Jewelry" },
      { value: "Machinery" },
      { value: "Management Consulting" },
      { value: "Maritime" },
      { value: "Marketing & Advertising" },
      { value: "Market Research" },
      { value: "Mechanical or Industrial Engineering" },
      { value: "Media Production" },
      { value: "Medical Device" },
      { value: "Medical Practice" },
      { value: "Mental Health Care" },
      { value: "Military" },
      { value: "Mining & Metals" },
      { value: "Motion Pictures & Film" },
      { value: "Museums & Institutions" },
      { value: "Music" },
      { value: "Nanotechnology" },
      { value: "Newspapers" },
      { value: "Nonprofit Organization Management" },
      { value: "Oil & Energy" },
      { value: "Online Publishing" },
      { value: "Outsourcing/Offshoring" },
      { value: "Package/Freight Delivery" },
      { value: "Packaging & Containers" },
      { value: "Paper & Forest Products" },
      { value: "Performing Arts" },
      { value: "Pharmaceuticals" },
      { value: "Philanthropy" },
      { value: "Photography" },
      { value: "Plastics" },
      { value: "Political Organization" },
      { value: "Primary/Secondary" },
      { value: "Printing" },
      { value: "Professional Training" },
      { value: "Program Development" },
      { value: "Public Policy" },
      { value: "Public Relations" },
      { value: "Public Safety" },
      { value: "Publishing" },
      { value: "Railroad Manufacture" },
      { value: "Ranching" },
      { value: "Real Estate" },
      { value: "Recreational" },
      { value: "Facilities & Services" },
      { value: "Religious Institutions" },
      { value: "Renewables & Environment" },
      { value: "Research" },
      { value: "Restaurants" },
      { value: "Retail" },
      { value: "Security & Investigations" },
      { value: "Semiconductors" },
      { value: "Shipbuilding" },
      { value: "Sporting Goods" },
      { value: "Sports" },
      { value: "Staffing & Recruiting" },
      { value: "Supermarkets" },
      { value: "Telecommunications" },
      { value: "Textiles" },
      { value: "Think Tanks" },
      { value: "Tobacco" },
      { value: "Translation & Localization" },
      { value: "Transportation/Trucking/Railroad" },
      { value: "Utilities" },
      { value: "Venture Capital" },
      { value: "Veterinary" },
      { value: "Warehousing" },
      { value: "Wholesale" },
      { value: "Wine & Spirits" },
      { value: "Wireless" },
      { value: "Writing & Editing" },
    ];

    setSectorListData(industrySectors);
  };

  const resetFilters: any = () => {
    setIsClearingFilters(true);
    setFilterRFQValue("");
    setFilterOrgSize("");
    setFilterCategories([]);
    setFilterSector([]);
    setFilterLocation([]);
    setFilterKeywords("");
    setFilterExpiry(false);
    setFilterFavourite(false);
    setTimeout(() => {
      setIsClearingFilters(false);
    }, 1000);
  };

  const applyFilters: any = () => {
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  };

  function fallsWithinRange(amount: any) {
    // Convert amount to a number if it's a string
    amount = parseFloat(`${amount}`);

    switch (filterRFQValue) {
      case "$0K - $1K":
        return amount >= 0 && amount <= 1000;
      case "$1K - $5K":
        return amount > 1000 && amount <= 5000;
      case "$5K - $10K":
        return amount > 5000 && amount <= 10000;
      case "$10K+":
        return amount > 10000;
      default:
        return false; // Invalid range
    }
  }

  const getRFQValue: any = (item: any) => {
    return item.preferredUnitPrice.amount * item.estimatedOrderQuantity.size;
  };

  const filterRFQValueExe = (data: any, text: string) => {
    return data.filter((cat: any) => fallsWithinRange(getRFQValue(cat)));
  };

  function fallsWithinOrganizationSizeRange(size: any) {
    // Convert size to a number if it's a string
    size = parseInt(size);

    // Function to parse range string
    function parseRange(range: any) {
      const [min, max] = range
        .split("-")
        .map((num: any) => parseInt(num.trim()));
      return { min, max: max || Infinity };
    }

    // Normalize the selectedRange
    let range;
    if (typeof filterOrgSize === "string") {
      if (filterOrgSize.includes("-")) {
        range = parseRange(filterOrgSize);
      } else {
        switch (filterOrgSize) {
          case "0 - 100":
            range = { min: 0, max: 100 };
            break;
          case "100 - 500":
            range = { min: 100, max: 500 };
            break;
          case "500 - 1000":
            range = { min: 500, max: 1000 };
            break;
          case "1000+":
            range = { min: 1000, max: Infinity };
            break;
          default:
            return false; // Invalid range
        }
      }
    } else {
      return false; // Invalid input type
    }

    // Check if size falls within the range
    return size >= range.min && size < range.max;
  }

  const filterOrgSizeExe = (data: any, text: string) => {
    return data.filter((cat: any) =>
      fallsWithinOrganizationSizeRange(cat.organisation.companySize)
    );
  };

  const filterCategoryExe = (data: any, categories: any) => {
    return data.filter((cat: any) => {
      let cats = categories.map((searchCat: any) => {
        let catParts = searchCat.split("-");

        return parseInt(`${catParts[0]}`);
      });

      return cats.includes(cat.category.id);
    });
  };

  function containsSubstring(mainString: string, searchTerm: any) {

    let isFound: boolean = false;
    if (mainString === undefined || mainString === null || mainString === "") {
      return false;
    }
    if (Array.isArray(searchTerm)) {
      searchTerm.map((term) => {
        if (containsSubstring(mainString, term)) {
          isFound = true;
        }
      });
      return isFound;
    } else {
      const lowerMainString = mainString.toLowerCase();
      const lowerSearchTerm = searchTerm.toLowerCase();

      // Remove spaces from the search term
      const searchTermNoSpaces = lowerSearchTerm.replace(/\s+/g, "");

      // Check if the main string contains the search term with or without spaces
      let isFoundInString =
        lowerMainString.includes(lowerSearchTerm) ||
        lowerMainString.replace(/\s+/g, "").includes(searchTermNoSpaces);

      // console.log(
      //   "+++++++++++isFoundInString",
      //   mainString,
      //   searchTerm,
      //   isFoundInString
      // );

      return isFoundInString;
    }
  }

  const filterSectorExe = (data: any, industries: any) => {
    return data.filter((cat: any) => {
      let industryFound: boolean = false;
      industries.map((searchInd: any) => {
        if (containsSubstring(cat.organisation.industry, searchInd)) {
          industryFound = true;
        }
      });
      return industryFound;
    });
  };

  const filterLocationExe = (data: any, locations: any) => {
    return data.filter((cat: any) => {
      let locationFound: boolean = false;

      locations.map((searchLoc: any) => {
        if (
          containsSubstring(cat.organisation.primaryContact.city, searchLoc)
        ) {
          locationFound = true;
        }
      });

      return locationFound;
    });
  };

  const filterKeywordsExe = (data: any, text: string) => {

    return data.filter((cat: any) => {

      let gotHit: boolean = false;

      if (!cat) {
        return false;
      }

      const rfqNumber: string = Snippets.string.rfqNumber(cat);

      if (containsSubstring(cat.productName, text)) {
        gotHit = true;
      }

      if (containsSubstring(rfqNumber, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.productDetails, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.sourcingType, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.contactName, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.buyerBusinessType, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.category.name, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.organisation.industry, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.organisation.city, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.organisation.businessType, text)) {
        gotHit = true;
      }

      if (containsSubstring(cat.organisation.primaryContact.city, text)) {
        gotHit = true;
      }

      if (
        containsSubstring(
          `${cat.estimatedOrderQuantity.size * cat.preferredUnitPrice.amount}`,
          text
        )
      ) {
        gotHit = true;
      }

      return gotHit;
    });
  };

  function isWithin72Hours(futureDate: string) {
    // Parse the input date string
    const futureDateObj: any = new Date(futureDate);

    // Get the current date and time
    const now: any = new Date();

    // Calculate the difference in milliseconds
    const differenceMs = futureDateObj - now;

    // Convert 72 hours to milliseconds
    const hours72 = 72 * 60 * 60 * 1000;

    // Check if the difference is less than or equal to 72 hours and greater than 0
    return differenceMs <= hours72 && differenceMs > 0;
  }

  const filterExpireExe = (data: any) => {
    return data.filter((cat: any) => isWithin72Hours(cat.validTo));
  };

  const filterFavouritesExe = (data: any) => {
    return data;
    // Todo, check if it is within Fav items
  };

  useEffect(() => {
    setIsPageLoading(true);

    let filteredData: any = listItems;

    if (filterRFQValue !== "") {
      filteredData = filterRFQValueExe(filteredData, filterRFQValue);
    }

    if (filterOrgSize !== "") {
      filteredData = filterOrgSizeExe(filteredData, filterOrgSize);
    }

    if (filterCategories.length > 0) {
      filteredData = filterCategoryExe(filteredData, filterCategories);
    }

    if (filterSector.length > 0) {
      filteredData = filterSectorExe(filteredData, filterSector);
    }

    if (filterLocation.length > 0) {
      filteredData = filterLocationExe(filteredData, filterLocation);
    }

    if (filterKeywords !== "") {
      filteredData = filterKeywordsExe(filteredData, filterKeywords);
    }

    if (filterExpiry) {
      filteredData = filterExpireExe(filteredData);
    }

    if (filterFavourite) {
      filteredData = filterFavouritesExe(filteredData);
    }

    paginateRFQs(filteredData, currentPage, perChunk);

    console.log(
      "FILTER",
      {
        filterRFQValue,
        filterOrgSize,
        filterCategories,
        filterSector,
        filterLocation,
        filterKeywords,
        filterExpiry,
        filterFavourite,
      },
      filteredData
    );

    setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
  }, [
    filterRFQValue,
    filterOrgSize,
    filterCategories,
    filterSector,
    filterLocation,
    filterKeywords,
    filterExpiry,
    filterFavourite,
  ]);

  const keywordOptions: any = [];

  const locationOptions: any = [
    { value: "Alexandra Park" },
    { value: "Arcadia" },
    { value: "Ashbrittle" },
    { value: "Ashdown Park" },
    { value: "Avenues" },
    { value: "Avondale" },
    { value: "Avonlea" },
    { value: "Ballantyne Park" },
    { value: "Belgravia" },
    { value: "Belvedere" },
    { value: "Bloomingdale" },
    { value: "Bluff Hill" },
    { value: "Borrowdale" },
    { value: "Borrowdale Brooke" },
    { value: "Braeside" },
    { value: "Budiriro" },
    { value: "Carrick Creagh" },
    { value: "Chisipite" },
    { value: "Chitungwiza" },
    { value: "Colne Valley" },
    { value: "Cotswold Hills" },
    { value: "Cranborne" },
    { value: "Crowborough" },
    { value: "Cape Town" },
    { value: "Dzivaresekwa" },
    { value: "Eastlea" },
    { value: "Eastlea North" },
    { value: "Eastlea South" },
    { value: "Emerald Hill" },
    { value: "Graniteside" },
    { value: "Glen Lorne" },
    { value: "Glen Norah" },
    { value: "Glen View" },
    { value: "Good Hope" },
    { value: "Greencroft" },
    { value: "Greendale" },
    { value: "Greystone Park" },
    { value: "Groombridge" },
    { value: "Gunhill" },
    { value: "Haig Park" },
    { value: "Harare Central" },
    { value: "Hatcliffe" },
    { value: "Hatfield" },
    { value: "Helensvale" },
    { value: "Highfield" },
    { value: "Highlands" },
    { value: "Hillside" },
    { value: "Hogerty Hill" },
    { value: "Hopley" },
    { value: "Kambuzuma" },
    { value: "Kensington" },
    { value: "Kuwadzana" },
    { value: "Lenana Park" },
    { value: "Letombo Park" },
    { value: "Lewisam" },
    { value: "Lochinvar" },
    { value: "Mabvuku" },
    { value: "Madokero" },
    { value: "Mabelreign" },
    { value: "Malvern" },
    { value: "Mandara" },
    { value: "Maranatha" },
    { value: "Marlborough" },
    { value: "Mbare" },
    { value: "Matidoda" },
    { value: "Meyrick Park" },
    { value: "Milton Park" },
    { value: "Monavale" },
    { value: "Montague" },
    { value: "Mount Pleasant" },
    { value: "Msasa" },
    { value: "Mufakose" },
    { value: "Nkwisi Gardens" },
    { value: "Newlands" },
    { value: "Northwood" },
    { value: "Philadelphia" },
    { value: "Pomona" },
    { value: "Prospect" },
    { value: "Queensdale" },
    { value: "Rhodesville" },
    { value: "Ridgeview" },
    { value: "Ruwa" },
    { value: "Sentosa" },
    { value: "Shawasha Hills" },
    { value: "Southerton" },
    { value: "St Andrew's Park" },
    { value: "St Martins" },
    { value: "Strathaven" },
    { value: "Sunridge" },
    { value: "Sunway City" },
    { value: "Sunningdale" },
    { value: "The Grange" },
    { value: "Tynwald" },
    { value: "Umwinsidale" },
    { value: "Vainona" },
    { value: "Warren Park" },
    { value: "Waterfalls" },
    { value: "Westgate" },
    { value: "Westlea" },
    { value: "Willow Creek" },
    { value: "Zimre Park" },
    { value: "Ascot" },
    { value: "Barbour Fields" },
    { value: "Barham Green" },
    { value: "Beacon Hill" },
    { value: "Belmont" },
    { value: "Belmont East" },
    { value: "Bellevue" },
    { value: "Bradfield" },
    { value: "Burnside" },
    { value: "Cement" },
    { value: "City Centre" },
    { value: "Cowdray Park" },
    { value: "Donnington" },
    { value: "Donnington West" },
    { value: "Douglasdale" },
    { value: "Eloana" },
    { value: "Entumbane" },
    { value: "Famona" },
    { value: "Fortunes Gate" },
    { value: "Four Winds" },
    { value: "Glengarry" },
    { value: "Glenville" },
    { value: "Gwabalanda" },
    { value: "Hillside" },
    { value: "Hillside South" },
    { value: "Hillside West" },
    { value: "Hyde Park" },
    { value: "Ilanda" },
    { value: "Jacaranda" },
    { value: "Kelvin East" },
    { value: "Kelvin West" },
    { value: "Killarney" },
    { value: "Kingsdale" },
    { value: "Kumalo" },
    { value: "Lobengula" },
    { value: "Lockview" },
    { value: "Luveve" },
    { value: "Magwegwe" },
    { value: "Mahatsula North" },
    { value: "Mahatsula South" },
    { value: "Makokoba" },
    { value: "Malindela" },
    { value: "Manningdale" },
    { value: "Matsheumhlope" },
    { value: "Matshobana" },
    { value: "Morningside" },
    { value: "Montrose" },
    { value: "Montgomery" },
    { value: "Morningside" },
    { value: "Mpopoma" },
    { value: "Mzilikazi" },
    { value: "Newton" },
    { value: "Newton West" },
    { value: "Nkulumane" },
    { value: "Nketa" },
    { value: "North End" },
    { value: "North Trenance" },
    { value: "Orange Grove" },
    { value: "Parklands" },
    { value: "Pelandaba" },
    { value: "Pumula" },
    { value: "Queens Park West" },
    { value: "Raylton" },
    { value: "Richmond" },
    { value: "Riverside North" },
    { value: "Riverside South" },
    { value: "Romney Park" },
    { value: "Rowena" },
    { value: "Sauerstown" },
    { value: "Selborne Park" },
    { value: "South Riding" },
    { value: "Southdale" },
    { value: "Southwold" },
    { value: "Steeldale" },
    { value: "Sunnyside" },
    { value: "Sunninghill" },
    { value: "The Suburbs" },
    { value: "Tshabalala" },
    { value: "Umguza Estate" },
    { value: "University Site" },
    { value: "Waterford" },
    { value: "West Somerton" },
    { value: "Westgate" },
    { value: "Westondale" },
    { value: "Whitecairns" },
    { value: "Willsgrove" },
    { value: "Willowvale" },
    { value: "Woodlands" },
    { value: "Woodville" },
    { value: "Woodville Park" },
    { value: "Beitbridge" },
    { value: "Bindura" },
    { value: "Bulawayo" },
    { value: "Chegutu" },
    { value: "Chinhoyi" },
    { value: "Chipinge" },
    { value: "Chiredzi" },
    { value: "Chitungwiza" },
    { value: "Gwanda" },
    { value: "Gweru" },
    { value: "Harare" },
    { value: "Hwange" },
    { value: "Kadoma" },
    { value: "Kariba" },
    { value: "Karoi" },
    { value: "Kwekwe" },
    { value: "Marondera" },
    { value: "Masvingo" },
    { value: "Mutare" },
    { value: "Norton" },
    { value: "Plumtree" },
    { value: "Redcliff" },
    { value: "Rusape" },
    { value: "Ruwa" },
    { value: "Shurugwi" },
    { value: "Victoria Falls" },
    { value: "Zvishavane" },
  ];

  return (
    <>
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1 w-100">
              <Flex className="w-100" align="center" justify="space-between">
                <h2 className="title ls-normal mb-0 w-100">RFQ Marketplace</h2>
                <Flex
                  className="w-100 hide-in-mobile d-none"
                  align="center"
                  justify="flex-end"
                >
                  <Button
                    className="mt-2 mb-2 mr-4 grey-btn"
                    style={{ width: 48 }}
                    size="large"
                    type="default"
                    onClick={() => {
                      setShowListView(true);
                    }}
                    icon={<UnorderedListOutlined />}
                  ></Button>
                  <Button
                    className="mt-2 mb-2 grey-btn"
                    style={{ width: 48 }}
                    size="large"
                    type="default"
                    onClick={() => {
                      setShowListView(false);
                    }}
                    icon={<AppstoreOutlined />}
                  ></Button>
                </Flex>
              </Flex>
            </div>
            <div className="pb-2 mb-3" style={isMobile ? { marginTop: 5 } : {}}>
              <>
                <div className="card shadow-sm mb-5 pl-2 pr-2">
                  <div className="card-body d-flex-invite pr-4 pl-4 pt-0 pb-2">
                    <div className="row mt-5 mb-2">
                      <div className="col-md-6 mb-2">
                        RFQ Value:
                        <Radio.Group
                          className="w-100"
                          defaultValue="1-5"
                          buttonStyle="solid"
                          onChange={(newValue: any) => {
                            setFilterRFQValue(newValue.target.value);
                          }}
                          value={filterRFQValue}
                        >
                          <Radio.Button value="$0K - $500K">
                            $0K - $1K
                          </Radio.Button>
                          <Radio.Button value="$500K - $5M">
                            $1K - $5K
                          </Radio.Button>
                          <Radio.Button value="$5M - $25K">
                            $5K - $10K
                          </Radio.Button>
                          <Radio.Button value="$25M+">$10K+</Radio.Button>
                        </Radio.Group>
                      </div>
                      <div className="col-md-6 mb-2">
                        Organisation Size:
                        <Radio.Group
                          className="w-100"
                          defaultValue="1-5"
                          buttonStyle="solid"
                          onChange={(newValue: any) => {
                            setFilterOrgSize(newValue.target.value);
                          }}
                          value={filterOrgSize}
                        >
                          <Radio.Button value="0 - 100">0 - 100</Radio.Button>
                          <Radio.Button value="100 - 500">
                            100 - 500
                          </Radio.Button>
                          <Radio.Button value="500 - 1000">
                            500 - 1000
                          </Radio.Button>
                          <Radio.Button value="1000+">1000+</Radio.Button>
                        </Radio.Group>
                      </div>

                      <div className="col-md-3 mb-2">
                        <Select
                          size="large"
                          mode="multiple"
                          className="mt-2 mb-2 grey-input w-100"
                          placeholder="Filter by RFQ category"
                          value={filterCategories}
                          onChange={(value) => {
                            setFilterCategories(value);
                          }}
                          options={categoryListData}
                        />
                      </div>

                      <div className="col-md-3 mb-2">
                        <Select
                          size="large"
                          mode="multiple"
                          className="mt-2 mb-2 grey-input w-100"
                          placeholder="Filter by industry sector"
                          value={filterSector}
                          onChange={(value) => {
                            setFilterSector(value);
                          }}
                          options={sectorListData}
                        />
                      </div>

                      <div className="col-md-3 mb-2">
                        <Select
                          size="large"
                          mode="multiple"
                          className="mt-2 mb-2 grey-input w-100"
                          placeholder="Filter by location"
                          value={filterLocation}
                          onChange={(value) => {
                            setFilterLocation(value);
                          }}
                          options={locationOptions}
                        />
                      </div>

                      <div className="col-md-3 mb-2">
                        <Input
                          size="large"
                          className="mt-2 mb-2 grey-input w-100"
                          onChange={(e) => {
                            setFilterKeywords(e.target.value);
                          }}
                          value={filterKeywords}
                          placeholder="Filter by keywords"
                        />
                      </div>

                      <div className="col-md-4 mb-0">
                        <div className="mt-3">
                          <Switch
                            className="mr-2"
                            defaultChecked
                            checked={filterExpiry}
                            onChange={(value: boolean) => {
                              setFilterExpiry(value);
                            }}
                          />{" "}
                          Show RFQs expiring in 72 hours
                        </div>
                      </div>

                      <div className="col-md-4 mb-0">
                        <div className="mt-3">
                          <Switch
                            className="mr-2"
                            defaultChecked
                            checked={filterFavourite}
                            onChange={(value: boolean) => {
                              setFilterFavourite(value);
                            }}
                          />{" "}
                          Show my favourite RFQs
                        </div>
                      </div>

                      <div
                        className={`col-md-4 mb-0 ${isMobile ? "mt-5" : ""}`}
                      >
                        <Flex
                          className="mt-0 mb-0 w-100"
                          gap={8}
                          vertical={isMobile}
                          justify={isMobile ? "center" : "right"}
                          align={isMobile ? "center" : "center"}
                        >
                          <Button
                            className="mt-2 mb-0"
                            size="large"
                            type="default"
                            loading={isClearingFilters}
                            block={isMobile}
                            style={{ float: "right" }}
                            onClick={() => {
                              resetFilters();
                            }}
                          >
                            Clear Filters
                          </Button>
                          <Button
                            className="mt-2 mb-0"
                            size="large"
                            block={isMobile}
                            type="primary"
                            loading={isPageLoading && !isClearingFilters}
                            style={{ float: "right" }}
                            onClick={() => {
                              applyFilters();
                            }}
                          >
                            Apply Filters
                          </Button>
                        </Flex>
                      </div>

                      <div className="col-md-12 mt-4 mb-2">
                        <hr />
                      </div>

                      <div className="col-md-12">
                        <RFQPagination
                          showingPageString={showingPageString}
                          onShowPageChange={onShowPageChange}
                          onShowSizeChange={onShowSizeChange}
                          currentPage={currentPage}
                          currentResultsTotal={currentResultsTotal}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </div>

                {currentResultsTotal === 0 && !isPageLoading && (
                  <>
                    <div className="card shadow-sm mb-5 pl-2 pr-2">
                      <div className="card-body">
                        <div className="text-center">
                          <img
                            style={{ width: 400, margin: "auto" }}
                            src="https://cdni.iconscout.com/illustration/premium/thumb/boy-explaining-about-no-data-folder-10962347-8881971.png?f=webp"
                          />
                          <br />
                          <br />
                          <h3 className="mb-10">No RFQs</h3>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {isPageLoading ? (
                  <Skeleton
                    count={5}
                    height={100}
                    className="mt-3 mb-5 rounded-2"
                  />
                ) : (
                  <>
                    {currentResultsTotal > 0 && (
                      <>
                        {chunkedArray[currentPage - 1].map(
                          (item: any, itemIndex: number) => (
                            <RFQListItem
                              key={itemIndex}
                              item={item}
                              supplierCategories={supplierCategories}
                            />
                          )
                        )}

                        <div className="card shadow-sm mb-5 pl-2 pr-2">
                          <div className="card-body">
                            <RFQPagination
                              showingPageString={showingPageString}
                              onShowPageChange={onShowPageChange}
                              onShowSizeChange={onShowSizeChange}
                              currentPage={currentPage}
                              currentResultsTotal={currentResultsTotal}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export { RFQOnePager };
