import * as React from "react";
import { useState } from 'react';
import ProductsSearchAside from "./ProductsSearchAside";
import Snippets from "../../../../helpers/Snippets";
import axios from "axios";
import ProductSimple from "./Widgets/ProductSimple";
import { max } from "moment";
import ProductsSearchResultsList from "./ProductsSearchResultsList";
import Skeleton from "react-loading-skeleton";
import { UserPilotHelper, UserPilotEvents } from "../../../../helpers/UserPilotHelper";
export default () => {

  var categoryId = "0"
  var brand = ""

  var queryString = window.location.search.substring(1)
  var queryParts = queryString.split("&")
  var query = ""
  for (let x = 0; x < queryParts.length; x++) {
    let tmpArr = queryParts[x].split("=");
    if (tmpArr[0] == "search") {
      query = tmpArr[1].replace("+", " ");
    }
    if (tmpArr[0] == "categoryId") {
      categoryId = tmpArr[1].replace("+", " ");
    }
    if (tmpArr[0] == "brand") {
      brand = tmpArr[1].replace("+", " ");
    }

  }


  const [resultsFetched, setResultsFetched] = useState(false)

  const [productList, setProductsList] = React.useState([]);
  const [filteredProductList, setFilteredProductsList] = React.useState([]);
  const [categoryList, setCategoryList] = useState([])
  const [priceRangesList, setPriceRangesList] = useState([])
  const [attributesList, setAttributesList] = useState([])
  const [brandsList, setBrandsList] = useState(new Map<string, number>)
  const [dummyArray, setDummyArray] = React.useState([1, 2, 3, 4])

  const roundToTen = (number: number) => {
    return Math.ceil(number / 10) * 10
  }
  React.useEffect(() => {

    var config = {
      method: 'get',
      url: Snippets.konstants.BASE_URL_API + '/home/search-products/0/50/' + query + "?categoryId=" + categoryId + "&brand=" + brand,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    };

    axios(config).then(({ data: products }) => {
      setResultsFetched(true)

      UserPilotHelper.logEvent(UserPilotEvents.SEARCH_PRODUCT,{'query':query,'results': (products) ? products.length : 0});
      if (products) {

        setProductsList(products);
        setFilteredProductsList(products);

        //find min and max price for price range filter
        let minPrice = Number.MAX_VALUE;
        let maxPrice = 0;
        let categoriesMap = new Map();
        let categoriesCount: number[] = []
        let brandsMap = new Map()
        products.forEach((product: any) => {

          //pricing
          maxPrice = (product.price > maxPrice) ? product.price : maxPrice
          minPrice = (product.price < minPrice) ? product.price : minPrice

          //categories
          categoriesCount[product.categoryId] = (categoriesCount[product.categoryId] == undefined) ? 1 : categoriesCount[product.categoryId] + 1
          categoriesMap.set(product.categoryId, new SearchCategory(product.category, product.categoryId, categoriesCount[product.categoryId]))

          //brands
          if (product.brandName == "" || product.brandName == null) product.brandName = "No Brand";
          if (brandsMap.has(product.brandName))
            brandsMap.set(product.brandName, parseInt(brandsMap.get(product.brandName)) + 1)
          else
            brandsMap.set(product.brandName, 1)

          product.attributeValues = product.attributes.map((attribute: any) => attribute.value.toUpperCase())

        });
        setBrandsList(brandsMap)

        //find and save unique categories
        let categoryIdArr: number[] = []
        let categories: SearchCategory[] = []
        categoriesMap.forEach((value, key) => {
          categories.push(value)
          categoryIdArr.push(value.id)
        })
        setCategoryList(categories)

        //create price ranges list
        if (minPrice != Number.MAX_VALUE) {
          let priceRanges_ = []
          let priceRangesCount = products.length > 5 ? 5 : products.length
          let rangeSize = (maxPrice - minPrice) / priceRangesCount
          for (let x = 0; x < priceRangesCount; x++) {
            let priceRange = {
              min: roundToTen(minPrice + (x * rangeSize)),
              max: roundToTen(minPrice + (x * rangeSize) + (rangeSize - 1))
            }
            priceRanges_.push(priceRange)
          }
          setPriceRangesList(priceRanges_)
        }

        //fetch attributes
        var attributesConfig = {
          method: 'get',
          url: Snippets.konstants.BASE_URL_API + '/marketplace/category/' + categoryIdArr[0] + "/product-attributes",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        };

        axios(attributesConfig).then(({ data: attributes }) => {
          //console.log(attributes)
          setAttributesList(attributes)
        }).catch((error: any) => {

        })

      }

    }).catch((error: any) => {
      setResultsFetched(true)
    });

  }, []);

  const filterResults = (brands: string[], attributes: string[]) => {
    //console.log(brands, attributes)
    let newFilteredProducts = productList;
    //console.log(newFilteredProducts)
    if (brands.length > 0) {
      newFilteredProducts = newFilteredProducts.filter(product =>
        brands.includes(product.brandName)
      );
    }
    if (attributes.length > 0) {
      newFilteredProducts = newFilteredProducts.filter(product =>
        attributes.some(attribute => product.attributeValues.includes(attribute.toUpperCase()))
      );
    }
    setFilteredProductsList(newFilteredProducts)
  }

  return (
    <>
      {/*    Start of Main    */}
      {resultsFetched ? (
        <>

          {/*    Start of Breadcrumb    */}

          <div className="breadcrumb-container container">
            <h4 className="mt-5">You searched for {query}</h4>
            <p>Showing {productList.length} products found matching keyword(s) <strong>{query}</strong></p>
          </div>

          {/*    End of Breadcrumb    */}
          <main id="main" className="main mb-10 pb-1" style={{
            backgroundColor: 'rgb(245,245,245)', padding: '20px 0'
          }}>

            {/*    Start of Page Content    */}
            <div className="page-content">
              <div className="container">
                <div className="row gutter-lg">
                  <ProductsSearchAside categories={categoryList}
                    priceRanges={priceRangesList}
                    attributes={attributesList}
                    brands={brandsList}
                    query={query}
                    categoryId={categoryId}
                    filterResults={filterResults}></ProductsSearchAside>
                  <div className="main-content">
                    <ProductsSearchResultsList productList={filteredProductList}></ProductsSearchResultsList>
                  </div>

                </div>
              </div>
            </div>
            {/*    End of Page Content    */}
          </main>
        </>
      ) : (
        <>
          <div className="breadcrumb-container container">
            <div className="row">
              <div className="col-md-3 mt-3">
                {<Skeleton count={1} height={15} />}
                {<Skeleton count={1} height={12} />}
              </div>
            </div>

          </div>

          <main id="main" className="main mb-10 pb-1" style={{
            backgroundColor: 'rgb(245,245,245)', padding: '20px 0'
          }}>

            <div className="page-content">
              <div className="container">
                <div className="row gutter-lg">
                  <aside
                    className="sidebar product-sidebar sidebar-fixed left-sidebar sticky-sidebar-wrapper"
                    id="product-sidebar"
                  >
                    <div className="sidebar-overlay"></div>
                    <a className="sidebar-close" href="#">
                      <i className="close-icon"></i>
                    </a>
                    <a href="#" className="sidebar-toggle d-flex d-lg-none">
                      <i className="fas fa-chevron-left"></i>
                    </a>
                    <div className="sidebar-content scrollable pr-3 pl-3 pt-3 pb-3">
                      {<Skeleton count={4} height={30} />}
                    </div>
                  </aside>

                  <div className="main-content">

                    <div className="row mb-3"><div className="col-md-4"> {<Skeleton count={1} height={20} />}</div> </div>


                    <div className="row cols-xl-4 cols-lg-4 cols-md-4 cols-sm-4 cols-4">
                      {dummyArray.map(() => (
                        <div className="swiper-slide category-wrap mt-20" style={{ marginBottom: "20px" }}>
                          <div className="category category-classic category-absolute overlay-zoom br-sm" style={{ padding: "10px" }}>
                            {<Skeleton count={1} height={98} />}
                            {<Skeleton count={1} height={32} />}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
      {/*    End of Main    */}
    </>
  );
};

export class SearchCategory {
  name: string
  id: number
  count: number
  constructor(name: string, id: number, count: number) {
    this.name = name
    this.id = id
    this.count = count
  }
}

export class BrandFilter {
  count: number
  name: string
  brandLink: string
  constructor(count: number, name: string, brandLink: string) {
    this.count = count
    this.name = name
    this.brandLink = brandLink
  }
}