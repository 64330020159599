import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import RFQAdvancedListViewFilterBar from "./RFQAdvancedListViewFilterBar";
import RFQAdvancedListViewCard from "./RFQAdvancedListViewCard";
import { Badge, Button, Card, Col, Drawer, Pagination, Row, Segmented, Skeleton } from "antd";
import type { PaginationProps } from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { useAppDataContext } from "../../../../data/context/AppDataContext";
import Meta from "antd/es/card/Meta";
import Snippets from "../../../../helpers/Snippets";
const RFQAdvancedListViewPage: React.FC = () => {
  const [listItems, setListItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  function dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const [categoryItemList, setCategoryItemList] = useState([]);

  const [businessTypeItemList, setBusinessTypeItemList] = useState([]);

  const [listItemsX, setListItemsX] = useState([]);

  const [listItemsSelectedCategory, setListItemsSelectedCategory] = useState(0);

  const [listItemsCategories, setListItemsCategories] = useState([]);

  const [listItemsBusinessType, setListItemsBusinessType] = useState([]);

  const [listItemsPriceRange, setListItemsPriceRange] = useState([0, 0]);

  useEffect(()=>{

    setListItemsX(listItems);

  },[listItems])

   
  useEffect(()=>{

    const _list:any = [];

    const _listItems:any = listItems;

    _listItems.map((item:any)=>{

      let shouldIncludeItem:boolean = true;

      if(listItemsCategories.length > 0){

          if(listItemsCategories.includes(item.category.id)){

            shouldIncludeItem = true;

          }else{

            shouldIncludeItem = false;

          }

      }else{

        shouldIncludeItem = true;

      }

      if(listItemsBusinessType.length > 0){

          if(listItemsBusinessType.includes(item.buyerBusinessType)){

            shouldIncludeItem = true;

          }else{

            shouldIncludeItem = false;

          }

      }else{

        shouldIncludeItem = true;

      }

      if(listItemsPriceRange[0] > 0){

        if(item.preferredUnitPrice.amount >= listItemsPriceRange[0]){

          shouldIncludeItem = true;

        }else{

          shouldIncludeItem = false;

        }

      }

      if(listItemsPriceRange[1] > 0){

        if(item.preferredUnitPrice.amount <= listItemsPriceRange[1]){

          shouldIncludeItem = true;

        }else{

          shouldIncludeItem = false;

        }

      }

      if(shouldIncludeItem){

        _list.push(item);

      }

      return _list;

    })

    //console.log("listItemsCategories, listItemsPriceRange", [listItemsCategories, listItemsPriceRange], _list);

    setListItemsX(_list.reverse().reverse());


  },[listItemsBusinessType, listItemsCategories, listItemsPriceRange])

  useEffect(()=>{

    const _list:any = [];

    const _listItems:any = listItems;

    _listItems.map((item:any)=>{

      let shouldIncludeItem:boolean = true;

      if(listItemsSelectedCategory > 0){

          if(listItemsSelectedCategory === item.category.id){

            shouldIncludeItem = true;

          }else{

            shouldIncludeItem = false;

          }

      }else{

        shouldIncludeItem = true;

      }

      if(shouldIncludeItem){

        _list.push(item);

      }

      return _list;

    })

    //console.log("listItemsSelectedCategory", listItemsSelectedCategory, _list);

    setListItemsX(_list.reverse().reverse());


  },[listItemsSelectedCategory])
   
  const onCategorySearchHandler:any = (_categoryId:number) => {
    
    //console.log(_categoryId);

    setListItemsSelectedCategory(_categoryId);

  }

  const onCategoryHandler:any = (_categoryItem:any, _categoryIsChecked:boolean, _allCats:Array<any>) => {

    //console.log(_categoryItem, _categoryIsChecked, _allCats);

    setListItemsCategories(_allCats);
    
  }

  const onBudgetHandler:any = (min:string, max:string) => {

    //console.log(parseFloat(min) || 0, parseFloat(max) || 0);

    setListItemsPriceRange([parseFloat(min) || 0, parseFloat(max) || 0]);
    
  }

  const onUserTypeHandler:any = () => {
    
  }

  const onBusinessTypeHandler:any = (_businessTypeItem:any, _businessTypeIsChecked:boolean, _allBTs:Array<any>) => {

    //console.log(_businessTypeItem, _businessTypeIsChecked, _allBTs);

    setListItemsBusinessType(_allBTs);
    
  }

  const onPurchaseOrdersHandler:any = () => {
    
  }

  const [itemsPerPage, setItemsPerPage] = useState<number>(6);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMoreItems = () => {

    setIsLoadingMore(true);

    setTimeout(()=>{

      setIsLoadingMore(false);

      setItemsPerPage(itemsPerPage + 6);

    },1000)

  };

  const [urlVars, setUrlVars] = useState({});

  const [folderValue, setFolderValue] = useState<string | number>('ACTIVE');

  const getListData: Function = () => {

    const _params:any = Snippets.urls.getQueryParams();

    setUrlVars(_params);

    //console.log("::: LIST DATA FETCH BEGIN ::: URL PARAMS :::", _params);

    AxiosAPI.getMarketplaceRFQs(_params)
      .then((res: any) => {

        let listData = res.data;

        if (isNewestFirst) {
          listData = listData.reverse();
        }

        setListItems(listData);
        setIsLoading(false);
        const _chunkedArray: any = chunkArray(listData);
        setChunkedArray(_chunkedArray);
        setCurrentPage(0);
        //console.log("::: MARKETPLACE RFQS :::", _chunkedArray);
      })
      .catch((error: any) => {
        //console.log("::: LIST DATA FETCH ERROR :::", error);
        setIsLoading(false);
      });

  };
 
  useEffect(() => {

    let _list:Array<any> = [];
    
    const _listObj:any = {};
    
    let _listBT:Array<any> = [];
    
    const _listObjBT:any = {};
    
    listItems.map((listItem:any)=>{

      const _key:string = 'C'+listItem.category.id;

      if(_key in _listObj){

        _listObj[_key].items.push(listItem);

      }else{
        _listObj[_key] = {};
        _listObj[_key].id  =listItem.category.id;
        _listObj[_key].name  =listItem.category.name;
        _listObj[_key].items = [];
        _listObj[_key].items.push(listItem);
      }

      /////

      if(listItem.buyerBusinessType !== null){

        const _keyBT:string = 'BT_'+listItem.buyerBusinessType;

        //console.log("_keyBT", _keyBT);

        if(_keyBT in _listObjBT){

          _listObjBT[_keyBT].items.push(listItem);

        }else{
          _listObjBT[_keyBT] = {};
          _listObjBT[_keyBT].name  =listItem.buyerBusinessType;
          _listObjBT[_keyBT].items = [];
          _listObjBT[_keyBT].items.push(listItem);
        }

      }

      return listItem;

    });

    _list = Object.values(_listObj);

    setCategoryItemList(_list);

    _listBT = Object.values(_listObjBT);

    //console.log("_listObjBT", _listObjBT);

    setBusinessTypeItemList(_listBT);

    //////



  }, [listItems]);

  const [currentPage, setCurrentPage] = React.useState(1); // items per chunk

  const [perChunk, setPerChunk] = React.useState(7); // items per chunk

  const [chunkedArray, setChunkedArray] = React.useState([]);

  const chunkArray: any = (inputArray: any) => {
    return inputArray.reduce((resultArray: any, item: any, index: any) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  };

  const [isNewestFirst, setIsNewsetFirst] = React.useState(true);

  const setNewestFirst: any = (value: any) => {
    setIsNewsetFirst(value);
    getListData();
  };

  useEffect(() => {
    getListData();
  }, []);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current: any,
    pageSize: any
  ) => {
    //console.log(current, pageSize);
    setPerChunk(pageSize);
    setTimeout(() => {
      setCurrentPage(current - 1);
    }, 500);
  };

  const onPageCurrentChange: any = (current: any) => {
    //console.log(current);
    setCurrentPage(current - 1);
  };

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const openFilters: any = () => {
    setIsFiltersOpen(true);
  };

  const closeFilters: any = () => {
    setIsFiltersOpen(false);
  };

  const { userType } = useAppDataContext();

  return (
    <>
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1 appear-animatex mb-1">
            <div
              className="title-link-wrapper pb-1 mb-1 d-flex-end"
              style={{ justifyContent: "space-between" }}
            >
              <h2 className="title ls-normal mb-0">RFQ Marketplace</h2>
              <Row className="mr-5">
                <Col className="hide-in-desktop" span={12}>
                  <Button
                    type="default"
                    onClick={() => {
                      isFiltersOpen ? closeFilters() : openFilters();
                    }}
                  >
                    {isFiltersOpen ? "Close Filters" : "Open Filters"}
                  </Button>
                </Col>
                {userType === "BUYER" && (
                  <Col span={12}>
                    <Button
                      className="ml-2"
                      onClick={() => {
                        window.location.href = "/rfqs/create";
                      }}
                      type="primary"
                    >
                      Post an RFQ
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 hide-in-mobile">
              <div className="xcards">
                <RFQAdvancedListViewFilterBar
                  onCategorySearch={onCategorySearchHandler}
                  onCategory={onCategoryHandler}
                  onBudget={onBudgetHandler}
                  onUserType={onUserTypeHandler}
                  onBusinessType={onBusinessTypeHandler}
                  onPurchaseOrders={onPurchaseOrdersHandler}
                  categoryItemList={categoryItemList}
                  businessTypeItemList={businessTypeItemList}
                />
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
              <Row className="" gutter={20}>
                {isLoading ? (
                  <>
                    {[...Array(9)].map(
                      (item: any, itemKey: number) => (
                        <Col
                          key={itemKey}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          xl={8}
                        >
                          <Card
                            className="xcards ml-2 mr-2 "
                            hoverable
                            style={{ width: "100%" }}
                            cover={
                              <Skeleton.Image
                                style={{ width: "100%", height: 200 }}
                                active={true}
                              />
                            }
                          >
                            <Meta
                              title={<Skeleton paragraph={{ rows: 1 }} />}
                            />
                          </Card>
                        </Col>
                      )
                    )}
                  </>
                ) : (
                  <>
                    {listItemsX.length > 0 ? (

                      <>

                      {listItemsX.slice(0, itemsPerPage).map((listItem: any, listItemIndex: number) => (
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <RFQAdvancedListViewCard
                            listItem={listItem}
                            key={listItemIndex}
                          />
                        </Col>
                      ))}

                      <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Button loading={isLoadingMore} type="primary" size="large" className="load-more-button" onClick={loadMoreItems}>Load More Items</Button>
                      </Col>
                      
                      </>
                    ) : (
                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Card
                        hoverable
                        style={{ width: "100%" }}
                        className="xcards pt-0 pb-0 pl-0 pr-0" ><div className={"flex-box-center-children"}>
                                <img
                                  alt=""
                                  src={"/media/blank-box.png"}
                                  style={{
                                    margin: "auto",
                                    width: "100%",
                                    maxWidth: "300px",
                                  }}
                                />
                                <h4 className={"mt-10"}>No items to show</h4>
                              </div></Card>
                    </Col>
                    )}
                  </>
                )}
              </Row>
            </div>
          </div>
        </div>
      </main>

      <Drawer
        title="RFQ Search Filters"
        placement="left"
        width="90%"
        onClose={closeFilters}
        open={isFiltersOpen}
        className="pl-5 pr-2"
      >
        <RFQAdvancedListViewFilterBar />
      </Drawer>
    </>
  );
};
export { RFQAdvancedListViewPage };
