import React, { FC, useEffect, useState } from "react";
import ChatUserListItem from "./ChatUserListItem";
import SellerRFQDetails from "./SellerRFQDetails";
import Icon, {
  StarOutlined,
  LikeOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Avatar, List, Skeleton, Switch, Empty } from "antd";

const Fade = require("react-reveal/Fade");

const MessengerAdvancedInquiryCol2: FC<any> = ({ children, ...props }) => {
  const {
    listViewLabel,
    selectedRFQ,
    getTime,
    userType,
    chatHistory,
    dummyUsersIsLoading,
    dummyUsers,
    selectedUser,
    getInitialsFromName,
    setSelectedUserHandler,
    stillLoading,
    listItems,
  }: any = props;

  const [dummyUsersSearch, setDummyUsersSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //console.log(":: VIEW_STATE :: INQ");
  }, []);

  interface IconTextProps {
    icon: typeof Icon;
    text: React.ReactNode;
  }

  const listData = Array.from({ length: 3 }).map((_, i) => ({
    href: "https://ant.design",
    title: `ant design part ${i + 1}`,
    avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
  }));

  const IconText: React.FC<IconTextProps> = ({ icon, text }: any) => (
    <>
      {React.createElement(icon, { style: { marginRight: 8 } })}
      {text}
    </>
  );

  const [isDomReady, setIsDomReady] = useState(false);

  useEffect(() => {
    if (!listItems || listItems.length === 0) {
      setTimeout(() => {
        setIsDomReady(true);
      }, 5000);
    }
  }, [listItems]);

  return (
    <>
      {stillLoading ? (
        <>
          <div className="xcard card-with-title card-with-shadow">
            <div className="title-link-wrapper mb-3">
              <h2 className="title mb-0 pt-2 pb-2">Messages</h2>
            </div>
            <div className="list-view-wrapper chats">
              <div className="rfq-search-container mb-6">
                <input
                  type="text"
                  className="form-control inquiry-search"
                  placeholder="Search Messages"
                  value={dummyUsersSearch}
                  onChange={(e) => {
                    setDummyUsersSearch(e.target.value);
                  }}
                />
                <i className="w-icon-search"></i>
              </div>
              {isDomReady ? (
                <Empty />
              ) : (
                <List
                  itemLayout="vertical"
                  size="large"
                  dataSource={listData}
                  renderItem={(item) => (
                    <List.Item
                      key={item.title}
                      actions={
                        !loading
                          ? [
                              <IconText
                                icon={StarOutlined}
                                text="156"
                                key="list-vertical-star-o"
                              />,
                              <IconText
                                icon={LikeOutlined}
                                text="156"
                                key="list-vertical-like-o"
                              />,
                              <IconText
                                icon={MessageOutlined}
                                text="2"
                                key="list-vertical-message"
                              />,
                            ]
                          : undefined
                      }
                      extra={
                        !loading && (
                          <img
                            width={272}
                            alt="logo"
                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                          />
                        )
                      }
                    >
                      <Skeleton loading={loading} active avatar>
                        <List.Item.Meta
                          avatar={<Avatar src={item.avatar} />}
                          title={<a href={item.href}>{item.title}</a>}
                          description={item.description}
                        />
                        {item.content}
                      </Skeleton>
                    </List.Item>
                  )}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="xcard card-with-title card-with-shadow">
            <div className="title-link-wrapper mb-3">
              <h2 className="title mb-0 pt-2 pb-2">Messages</h2>
            </div>
            <div className="list-view-wrapper chats">
              <div className="rfq-search-container">
                <input
                  type="text"
                  className="form-control inquiry-search"
                  placeholder="Search Messages"
                  value={dummyUsersSearch}
                  onChange={(e) => {
                    setDummyUsersSearch(e.target.value);
                  }}
                />
                <i className="w-icon-search"></i>
              </div>
              <div className="intro-y box flex flex-col py-4 mt-3 mb-3">
                {dummyUsersIsLoading ? (
                  <span className="center-everything">
                    <img src="/assets/images/loading.gif" alt="" />
                  </span>
                ) : (
                  <>
                    {dummyUsers.length === 0 && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="flex-box-center-children">
                            <img
                              src="https://sell.commercial.supplyzone.ai/media/blank-box.png"
                              alt=""
                              style={{
                                margin: "auto",
                                width: "100%",
                                maxWidth: "300px",
                              }}
                            />
                            <h3 className="mt-10 text-center bold no-responses-yet">
                              No responses yet
                            </h3>
                          </div>
                        </div>
                      </div>
                    )}

                    {dummyUsers
                      .filter((q: any) => {
                        return listViewLabel === "RFQ"
                          ? q.supplierCompanyName
                              .toLowerCase()
                              .indexOf(dummyUsersSearch.toLowerCase()) > -1
                          : q.responseCompanyContactName
                              .toLowerCase()
                              .indexOf(dummyUsersSearch.toLowerCase()) > -1;
                      })
                      .map((user: any, userIndex: number) => (
                        <Fade
                          bottom
                          appear
                          delay={(250 * userIndex) / 2}
                          key={userIndex}
                        >
                          <ChatUserListItem
                            key={userIndex}
                            isSelected={selectedUser.id === user.id}
                            user={user}
                            selectedUser={selectedUser}
                            getInitialsFromName={getInitialsFromName}
                            userIndex={userIndex}
                            setSelectedUserHandler={setSelectedUserHandler}
                          />
                        </Fade>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MessengerAdvancedInquiryCol2;
