import * as React from "react";

import { createContext, useContext, useState } from "react";
import { message } from "antd";
import { useLocalStorage } from "usehooks-ts";
import Snippets from "../../helpers/Snippets";
import AxiosAPI from "../../helpers/AxiosAPI";
import {
  UserPilotEvents,
  UserPilotHelper,
} from "../../helpers/UserPilotHelper";
import axios from "axios";
import * as moment from "moment";

const userDataPlaceholder: any = {
  id: 0,
  primaryContact: {
    emailAddress: "",
  },
  primaryContactPerson: {},
  profile: {},
  profileType: "",
  email: "",
  s3Key: "",
  avatarS3Key: "",
};

export type AppContentTypeInterface = {
  isPageLoading: boolean;
  setIsPageLoading: (c: {}) => void;
  isLoginModalOpen: boolean;
  setIsLoginModalOpen: (c: {}) => void;
  userAccessToken: string;
  setUserAccessToken: (c: string) => void;
  userDisplayName: string;
  setUserDisplayName: (c: string) => void;
  userType: string;
  setUserType: (c: string) => void;
  userRole: string;
  setUserRole: (c: string) => void;
  userEmailAddress: string;
  setUserEmailAddress: (c: string) => void;
  userData: any;
  setUserData: (c: {}) => void;
  userProfile: {};
  setUserProfile: (c: {}) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (c: boolean) => void;
  loginUser: (u: string, p: string, cbs: Function, cbe: Function) => void;
  logoutUser: (cbs: Function, cbe: Function) => void;
  registerUser: (p: any, cbs: Function, cbe: Function) => void;
  profilePercentage: number;
  setProfilePercentage: (c: {}) => void;
  getProfilePercentage: (c: {}) => void;
  profileCounters: any;
  updateBadges: () => void;
  currentlySelectedFolder: any;
  setCurrentlySelectedFolder: any;
  userDocumentsInquiries: any;
  userDocumentsRFQs: any;
  userDocumentsRFQsActive: any;
  userDocumentsRFQsCompleted: any;
  userDocumentsRFQsArchived: any;
  userDocumentsInquiriesActive: any;
  userDocumentsInquiriesCompleted: any;
  userDocumentsInquiriesArchived: any;
  setUserDocumentsRFQs: any;
  setUserDocumentsRFQsActive: any;
  setUserDocumentsRFQsCompleted: any;
  setUserDocumentsRFQsArchived: any;
  setUserDocumentsInquiries: any;
  setUserDocumentsInquiriesActive: any;
  setUserDocumentsInquiriesCompleted: any;
  setUserDocumentsInquiriesArchived: any;
  getAllDocuments: () => void;
  getDocumentsByFolder: (d: string, f: string, s: any, e: any) => void;
  addDocumentToFolder: (
    d: string,
    f: string,
    i: number,
    s: any,
    e: any
  ) => void;
  selectedCategorriesForSupplier: any;
  setSelectedCategorriesForSupplier: (cats: any) => void;
  addCategorriesForSupplier: (cat: any) => void;
  removeCategorriesForSupplier: (cat: any) => void;
};

export const AppDataContext = createContext<AppContentTypeInterface>({
  isPageLoading: true,
  setIsPageLoading: () => {},
  isLoginModalOpen: false,
  setIsLoginModalOpen: () => {},
  userAccessToken: "",
  setUserAccessToken: () => {},
  userDisplayName: "",
  setUserDisplayName: () => {},
  userType: "",
  setUserType: () => {},
  userRole: "",
  setUserRole: () => {},
  userEmailAddress: "",
  setUserEmailAddress: () => {},
  userData: { id: 0 },
  setUserData: () => {},
  userProfile: {},
  setUserProfile: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  loginUser: () => {},
  logoutUser: () => {},
  registerUser: () => {},
  profilePercentage: 0,
  setProfilePercentage: () => {},
  getProfilePercentage: () => {},
  profileCounters: { unreadRFQMessages: 0, unreadInquiryMessages: 0 },
  updateBadges: () => {},
  currentlySelectedFolder: "",
  setCurrentlySelectedFolder: "",
  userDocumentsInquiries: {},
  userDocumentsRFQs: {},
  userDocumentsRFQsActive: {},
  userDocumentsRFQsCompleted: {},
  userDocumentsRFQsArchived: {},
  userDocumentsInquiriesActive: {},
  userDocumentsInquiriesCompleted: {},
  userDocumentsInquiriesArchived: {},
  setUserDocumentsRFQs: () => {},
  setUserDocumentsRFQsActive: () => {},
  setUserDocumentsRFQsCompleted: () => {},
  setUserDocumentsRFQsArchived: () => {},
  setUserDocumentsInquiries: () => {},
  setUserDocumentsInquiriesActive: () => {},
  setUserDocumentsInquiriesCompleted: () => {},
  setUserDocumentsInquiriesArchived: () => {},
  getAllDocuments: () => {},
  getDocumentsByFolder: () => {},
  addDocumentToFolder: () => {},
  selectedCategorriesForSupplier: [],
  setSelectedCategorriesForSupplier: () => {},
  addCategorriesForSupplier: () => {},
  removeCategorriesForSupplier: () => {},
});

export const useAppDataContext = () => useContext(AppDataContext);

export const AppDataContextProvider = ({ children }: any) => {
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);

  const [userAccessToken, setUserAccessToken] = useLocalStorage(
    "userAccessToken",
    ""
  );

  const [userDisplayName, setUserDisplayName] = useLocalStorage(
    "userDisplayName",
    ""
  );

  const [userEmailAddress, setUserEmailAddress] = useLocalStorage(
    "userEmailAddress",
    ""
  );

  const [userType, setUserType] = useLocalStorage("userType", "");

  const [userRole, setUserRole] = useLocalStorage("userRole", "");

  const [userData, setUserData] = useLocalStorage(
    "userData",
    userDataPlaceholder
  );

  const [userProfile, setUserProfile] = useLocalStorage("userProfile", {});

  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("isLoggedIn", false);

  const [messageApi, contextHolder] = message.useMessage();

  const [profilePercentage, setProfilePercentage] = useState(0);

  const [profileCounters, setProfileCounters] = useLocalStorage(
    "profileCounters",
    { unreadRFQMessages: 0, unreadInquiryMessages: 0 }
  );

  const key = "updatable";

  const getProfilePercentage: any = (profileId: number) => {
    AxiosAPI.getProfileCompleteness(profileId).then((res: any) => {
      let progress = res.data;
      setProfilePercentage(progress.progress);
    });
  };

  const [selectedCategorriesForSupplier, setSelectedCategorriesForSupplier] = useState([]);

  const addCategorriesForSupplier: any = (category: any) => {
    
    let cat = [
      ...selectedCategorriesForSupplier,
      category,
    ];

    console.log("::ADD::~CAT::", category, cat);

    setSelectedCategorriesForSupplier(cat);

  };

  const removeCategorriesForSupplier: any = (category: any) => {

    let cats = selectedCategorriesForSupplier.filter(
      (cat: any) => cat.id !== category.id
    );

    console.log("::REM::~CAT::", cats, category);

    let _cats = cats.map((cat: any) => ({ id: cat.id }));

    console.log("::REM::_CAT::", _cats);

    setSelectedCategorriesForSupplier(_cats);

  };

  const [currentlySelectedFolder, setCurrentlySelectedFolder] = useState("ACTIVE");

  const addDocumentToFolder: any = (
    docType: string,
    folder: string,
    id: number,
    successCallback: any,
    errorCallback: any
  ) => {
    if (docType === "RFQ") {
      AxiosAPI.addRFQToFolder(folder, id, userData)
        .then((res: any) => {
          if (typeof successCallback === "function") {
            successCallback(res.data);
          }
        })
        .catch((error: any) => {
          if (typeof errorCallback === "function") {
            errorCallback(error);
          }
        });
    } else {
      AxiosAPI.addInquiryToFolder(folder, id, userData)
        .then((res: any) => {
          if (typeof successCallback === "function") {
            successCallback(res.data);
          }
        })
        .catch((error: any) => {
          if (typeof errorCallback === "function") {
            errorCallback(error);
          }
        });
    }
  };

  const [userDocumentsRFQs, setUserDocumentsRFQs] = useState([]);
  const [userDocumentsRFQsActive, setUserDocumentsRFQsActive] = useState([]);
  const [userDocumentsRFQsCompleted, setUserDocumentsRFQsCompleted] = useState(
    []
  );
  const [userDocumentsRFQsArchived, setUserDocumentsRFQsArchived] = useState(
    []
  );

  const [userDocumentsInquiries, setUserDocumentsInquiries] = useState([]);
  const [userDocumentsInquiriesActive, setUserDocumentsInquiriesActive] =
    useState([]);
  const [userDocumentsInquiriesCompleted, setUserDocumentsInquiriesCompleted] =
    useState([]);
  const [userDocumentsInquiriesArchived, setUserDocumentsInquiriesArchived] =
    useState([]);

  const getDocumentsByFolder: any = (
    docType: string,
    folder: string,
    successCallback: any,
    errorCallback: any
  ) => {
    if (docType === "RFQ") {
      AxiosAPI.getRFQsByFolder(folder)
        .then((res: any) => {
          if (typeof successCallback === "function") {
            successCallback(res.data);
          }
        })
        .catch((error: any) => {
          if (typeof errorCallback === "function") {
            errorCallback(error);
          }
        });
    } else {
      AxiosAPI.getInquiriesByFolder(folder)
        .then((res: any) => {
          if (typeof successCallback === "function") {
            successCallback(res.data);
          }
        })
        .catch((error: any) => {
          if (typeof errorCallback === "function") {
            errorCallback(error);
          }
        });
    }
  };

  const getUserRFQs: any = (successCallback: any, errorCallback: any) => {
    AxiosAPI.getRFQsByFolder("ACTIVE")
      .then((res: any) => {
        if (typeof successCallback === "function") {
          successCallback(res.data);
        }
      })
      .catch((error: any) => {
        if (typeof errorCallback === "function") {
          errorCallback(error);
        }
      });
  };

  const getUserInquiries: any = (successCallback: any, errorCallback: any) => {
    AxiosAPI.getInquiriesByFolder("ACTIVE")
      .then((res: any) => {
        if (typeof successCallback === "function") {
          successCallback(res.data);
        }
      })
      .catch((error: any) => {
        if (typeof errorCallback === "function") {
          errorCallback(error);
        }
      });
  };

  const getAllDocuments: any = () => {
    //console.warn("::: ****  getAllDocuments ");

    //RFQs

    getUserRFQs(
      (docs: any) => {
        docs.reverse();
        setUserDocumentsRFQs(docs);
      },
      (error: any) => {
        //console.error("**** Error getting ALL RFQS", error);
      }
    );

    getDocumentsByFolder(
      "RFQ",
      "ACTIVE",
      (docs: any) => {
        docs.reverse();
        setUserDocumentsRFQsActive(docs);
      },
      (error: any) => {
        //console.error("**** Error getting ACTIVE RFQS", error);
      }
    );

    getDocumentsByFolder(
      "RFQ",
      "COMPLETED",
      (docs: any) => {
        docs.reverse();
        setUserDocumentsRFQsCompleted(docs);
      },
      (error: any) => {
        //console.error("**** Error getting COMPLETED RFQS", error);
      }
    );

    getDocumentsByFolder(
      "RFQ",
      "ARCHIVED",
      (docs: any) => {
        docs.reverse();
        setUserDocumentsRFQsArchived(docs);
      },
      (error: any) => {
        //console.error("**** Error getting ARCHIVED RFQS", error);
      }
    );

    //INQUIRIES

    getUserInquiries(
      (docs: any) => {
        docs.reverse();
        setUserDocumentsInquiries(docs);
      },
      (error: any) => {
        //console.error("**** Error getting ALL INQS", error);
      }
    );

    getDocumentsByFolder(
      "INQ",
      "ACTIVE",
      (docs: any) => {
        docs.reverse();
        setUserDocumentsInquiriesActive(docs);
      },
      (error: any) => {
        //console.error("**** Error getting ACTIVE INQS", error);
      }
    );

    getDocumentsByFolder(
      "INQ",
      "COMPLETED",
      (docs: any) => {
        docs.reverse();
        setUserDocumentsInquiriesCompleted(docs);
      },
      (error: any) => {
        //console.error("**** Error getting COMPLETED INQS", error);
      }
    );

    getDocumentsByFolder(
      "INQ",
      "ARCHIVED",
      (docs: any) => {
        docs.reverse();
        setUserDocumentsInquiriesArchived(docs);
      },
      (error: any) => {
        //console.error("**** Error getting ARCHIVED INQS", error);
      }
    );
  };

  const getCurrentUserProfile: any = () => {
    let _userProfile: any = {};

    if (userData) {
      if ("primaryContactAvatar" in userData) {
        _userProfile = {
          id: userData.id,
          email: userData.primaryContact.emailAddress,
          avatarS3Key: userData.primaryContactAvatar,
          pic: userData.primaryContactAvatar,
          profile: {
            profileType: userData.profileType,
            primaryContactPerson: userData.primaryContactPerson,
          },
        };
      } else {
        _userProfile = {
          id: userData.id,
          email: userData.email,
          avatarS3Key: userData.avatarS3Key,
          pic: userData.avatarS3Key,
          profile: userData.profile,
        };
      }
    } else {
      _userProfile = {
        id: 0,
        email: "",
        avatarS3Key: "",
        pic: "",
        profile: {
          profileType: "",
          primaryContactPerson: {
            title: "",
            firstName: "",
            lastName: "",
            designation: "",
          },
        },
      };
    }

    return _userProfile;
  };

  const getCurrentUserFullName: any = () => {
    let szUserProfile: any = getCurrentUserProfile();

    let _displayName: string = "User";

    if (szUserProfile) {
      if ("person" in szUserProfile) {
        if ("firstName" in szUserProfile.person) {
          if (szUserProfile.person.firstName.length > 0) {
            _displayName =
              szUserProfile.person.firstName +
              " " +
              szUserProfile.person.lastName;
          }
        }
      } else if ("profile" in szUserProfile) {
        if ("firstName" in szUserProfile.profile.primaryContactPerson) {
          if (szUserProfile.profile.primaryContactPerson.firstName.length > 0) {
            _displayName =
              szUserProfile.profile.primaryContactPerson.firstName +
              " " +
              szUserProfile.profile.primaryContactPerson.lastName;
          }
        }
      } else {
      }
    }
    return _displayName;
  };

  const getCurrentUserId: any = () => {
    return userData ? userData.id : 0;
  };

  const getCurrentUserEmail: any = () => {
    return userData ? userData.email : "";
  };

  const getCurrentUserAvatar: any = (avatarS3Key: string = "") => {
    if (avatarS3Key === "") {
      let szUserData: any = getCurrentUserProfile();

      avatarS3Key = szUserData.avatarS3Key;
    }

    return avatarS3Key
      ? "https://api-live.supplyzone.ai/home/download-attachment/" +
          avatarS3Key
      : "https://t4.ftcdn.net/jpg/03/32/59/65/360_F_332596535_lAdLhf6KzbW6PWXBWeIFTovTii1drkbT.jpg";
  };

  const getUserData: any = (
    token: string,
    callbackSuccess: Function,
    callbackError: Function,
    showLoginSuccessMessage: boolean = true
  ): void => {
    AxiosAPI.getUserProfile(token)
      .then((res: any) => {
        //console.log(":: DATA CONTEXT :: getUserData ::", res.data);

        const user: any = res.data;

        setIsLoggedIn(true);

        setUserData(user);

        setUserType(String(user.profile.profileType).toUpperCase());

        setUserRole(String(user.role).toUpperCase());

        setUserDisplayName(`${user.person.firstName} ${user.person.lastName}`);

        setUserEmailAddress(user.email);

        callbackSuccess(user);

        if (showLoginSuccessMessage) {
          messageApi.open({
            key,
            type: "success",
            content: "Login successful!",
            duration: 2,
          });
        }
      })
      .catch((error: any) => {
        //console.log(":: DATA CONTEXT :: error ::", error);

        logoutUser(() => {}, callbackError(error));

        callbackError(error, {
          icon: "error",
          title: "Login Failed.",
          text: "Failed to fetch data Please try again",
        });
      });
  };

  const loginUser: any = (
    emailAddress: string,
    password: string,
    callbackSuccess: Function,
    callbackError: Function
  ): void => {
    AxiosAPI.loginUser(emailAddress, password)
      .then((res: any) => {
        setUserAccessToken(res.data);
        getUserData(res.data, callbackSuccess);
        updateBadges();
      })
      .catch((error: any) => {
        callbackError(error, {
          icon: "error",
          title: "Login Failed.",
          text: "Invalid username / password combination. Please try again",
        });
      });
  };

  const logoutUser: any = (
    callbackSuccess: Function,
    callbackError: Function
  ): void => {
    try {
      messageApi.open({
        key,
        type: "loading",
        content: "Logging out...",
      });

      setUserAccessToken("");

      setUserDisplayName("");

      setUserEmailAddress("");

      setUserType("");

      setUserRole("");

      setUserData(userDataPlaceholder);

      setUserProfile({});

      setIsLoggedIn(false);

      localStorage.clear();

      setTimeout(() => {
        messageApi.open({
          key,
          type: "success",
          content: "Logout successful!",
          duration: 2,
        });

        callbackSuccess();
      }, 2000);
    } catch (error: any) {
      callbackError(null, error);
    }
  };

  const registerUser: any = (
    payload: any,
    callbackSuccess: Function,
    callbackError: Function
  ): void => {
    AxiosAPI.registerUserV2(payload)
      .then((res: any) => {
        //console.log(":: REDUX :: USER REGISTERED", res.data);
        UserPilotHelper.logEvent(UserPilotEvents.SIGN_UP, {
          email: payload.emailAddress,
        });
        typeof callbackSuccess === "function" && callbackSuccess(res.data);
      })
      .catch((error: any) => {
        //console.log(":: REDUX :: USER REGISTERED ERROR", error);
        callbackError(error, {
          icon: "error",
          title: "Registration Error.",
          text: error.response.data.message,
        });
      });
  };

  const updateBadges: any = () => {
    AxiosAPI.apiRequest("get", `/profile/profile-counters`, null).then(
      (res: any) => {
        setTimeout(() => {
          setProfileCounters(res.data);
        }, 2000);
      }
    );
  };

  React.useEffect(() => {
    if (Snippets.user.getCurrentUserAccessToken()) {
      getUserData(
        null,
        function () {},
        function () {},
        false
      );
      updateBadges();
    }
  }, []);

  const arrayIncludes = (haystack: string[], needle: string): boolean => {
    const result = haystack.filter((el) =>
      needle.toLowerCase().includes(el.toLowerCase())
    );
    return result.length > 0;
  };

  //interceptor for all http requests
  axios.interceptors.response.use(
    (response) => {
      const originalRequest = response.config;
      //refresh counters after some requests
      const readMessageRequests = [
        "supplier/inquiries/responses",
        "supplier/rfqs/",
        "/buyer/rfqs/responses",
        "buyer/inquiries/",
      ];
      if (arrayIncludes(readMessageRequests, originalRequest.url)) {
        //console.log(`Updating counters because: ${originalRequest.url}`);
        //updateBadges()
        //return null
      }

      return response;
    },
    function (error) {
      const originalRequest = error.config;
      return Promise.reject(error);
    }
  );

  return (
    <AppDataContext.Provider
      value={{
        isPageLoading,
        setIsPageLoading,
        isLoginModalOpen,
        setIsLoginModalOpen,
        userAccessToken,
        setUserAccessToken,
        userDisplayName,
        setUserDisplayName,
        userEmailAddress,
        setUserEmailAddress,
        userType,
        setUserType,
        userRole,
        setUserRole,
        userData,
        setUserData,
        userProfile,
        setUserProfile,
        isLoggedIn,
        setIsLoggedIn,
        loginUser,
        logoutUser,
        registerUser,
        profilePercentage,
        setProfilePercentage,
        getProfilePercentage,
        profileCounters,
        updateBadges,
        currentlySelectedFolder,
        setCurrentlySelectedFolder,
        userDocumentsInquiries,
        userDocumentsRFQs,
        userDocumentsRFQsActive,
        userDocumentsRFQsCompleted,
        userDocumentsRFQsArchived,
        userDocumentsInquiriesActive,
        userDocumentsInquiriesCompleted,
        userDocumentsInquiriesArchived,
        setUserDocumentsRFQs,
        setUserDocumentsRFQsActive,
        setUserDocumentsRFQsCompleted,
        setUserDocumentsRFQsArchived,
        setUserDocumentsInquiries,
        setUserDocumentsInquiriesActive,
        setUserDocumentsInquiriesCompleted,
        setUserDocumentsInquiriesArchived,
        getAllDocuments,
        getDocumentsByFolder,
        addDocumentToFolder,
        selectedCategorriesForSupplier,
        setSelectedCategorriesForSupplier,
        addCategorriesForSupplier,
        removeCategorriesForSupplier,
      }}
    >
      {contextHolder}
      {children}
    </AppDataContext.Provider>
  );
};
