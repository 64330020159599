import * as React from "react";

export default () => {
  return (
    <>
      <p className="" style={{ textAlign: "justify" }}>
        This Website (as defined in Clause 1 below) is an electronic publication
        of and a service provided by Inbox Group Private Limited (a company
        incorporated in the Seychelles Administrative Region) (hereinafter
        referred to as &quot;Inbox&quot;).
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Any and all access to and use of this Website are subject to, and
        constitute each Visitor's (as defined in Clause 1 below) acknowledgment
        and acceptance of, the following terms (&quot;Terms of Use&quot;). Each
        Visitor also agrees to accept and comply with any and all other
        guidelines, notices, operating rules, policies and instructions
        pertaining to the access and use of this Website, as well as any
        amendments to the aforementioned, issued by Inbox (and/or other relevant
        members of the Inbox Group (as defined in Clause 1 below)) and posted in
        this Website, from time to time. If any Visitor does not accept these
        Terms of Use (and such guidelines, notices, operating rules, policies
        and instructions), the Visitor must stop accessing and using this
        Website.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Inbox (and/or other relevant members of the Inbox Group (as defined in
        Clause 1 below), as the case may be) may amend or update these Terms of
        Use (and other guidelines, notices, operating rules, policies and
        instructions) at any time and Inbox may post the amended or updated
        version thereof on this Website. Such amended or updated version shall
        automatically be effective upon its posting on this Website and Visitors
        are deemed to be aware of and bound by any changes to these Terms of Use
        upon posting on this Website. The right of Inbox (and/or other relevant
        members of the Inbox Group (as defined in Clause 1 below), as the case
        may be) to amend or update these Terms of Use (and such guidelines,
        notices, operating rules, policies and instructions) in the manner
        aforesaid may be exercised without the consent of the Visitor or any
        other person or entity. If any Visitor does not accept any such
        amendments or updates, the Visitor must stop accessing and using this
        Website.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Definitions and interpretation&nbsp;
        </li>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Definitions: In these Terms of Use, the following words and
            expressions shall, unless the context otherwise requires, have the
            following respective meanings: -
          </li>
        </ol>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Affected Provision&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall have the meaning ascribed thereto in Clause 19 below.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Force Majeure Event&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        in relation to a person or entity, shall mean an event or a failure
        which is beyond that person's or entity's reasonable control, including
        but not limited to: acts of God; fire; flood; explosion; storm;
        hurricane; typhoon; tsunami; earthquake; natural or other disasters;
        vandalism; power surge or outage; cable cut; disruptions or failures in
        telecommunication systems or equipment or electrical power or supply;
        server crashes; back-up failures; disruptions to or failure of Internet
        access or connectivity; serious accidents; disease outbreaks, epidemics
        or quarantine or travel restrictions; any law, order, regulation,
        direction, action or request of any government (including state and
        local governments) or any civil or military authority, or of any
        dependent agency, commission, court, bureau, corporation or other
        instrumentality thereof; allocation regulations or orders affecting
        materials and/or the supply thereof; national emergencies,
        insurrections, riots, civil commotion, war or warlike operations; acts
        of terrorism; strikes, lockouts, work stoppages or labor disputes,
        troubles or difficulties; delays in transportation; supplier failures,
        shortages, breaches or delays; or inability after due and timely
        diligence to procure materials, accessories, equipment or parts.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Inbox Content&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean this Website, and all content, information, applications,
        programs, texts, images, links, sounds, graphics, videos, software and
        other materials displayed or made available on this Website, and all
        files within and attachments transmitted through this Website and all
        data within such files and attachments, but excluding Third-party
        Content.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Inbox Group&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean Inbox Group Pvt Ltd., a Seychelles corporation, and its
        subsidiaries (including Inbox) from time to time.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Inbox Indemnitees&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean Inbox and other members of the Inbox Group, and any
        directors, officers, employees, representatives, agents, service
        providers and/or assigns thereof.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Intellectual Property Rights&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean all copyright, patents, utility innovations, trade marks and
        service marks, geographical indications, domain names, layout design
        rights, registered designs, design rights, database rights, trade or
        business names, rights protecting trade secrets and confidential
        information, rights protecting goodwill and reputation, and all other
        similar or corresponding proprietary rights and all applications for the
        same, whether presently existing or created in the future, anywhere in
        the world, whether registered or not, and all benefits, privileges,
        rights to sue, recover damages and obtain relief or other remedies for
        any past, current or future infringement, misappropriation or violation
        of any of the foregoing rights.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Liabilities&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean claims, demands, damages, losses, liabilities, settlement
        sums, suits, actions, proceedings, judgments, fines, penalties, charges,
        costs and expenses (including but not limited to legal costs and
        expenses on a full indemnity basis.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Overall Content&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean the Inbox Content and the Third-party Content, collectively.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Password&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall refer to the valid password that is used in conjunction with the
        Username to access and use password-protected and/or secure areas of
        this Website that are restricted to authorized users only.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Inbox&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall have the meaning ascribed thereto in the first paragraph of the
        opening section above.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Relevant Activity&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        in relation to any product appearing in this Website and/or any of the
        Overall Content, shall mean any one or more of the following (as the
        context requires): the import, export, storage, transportation, transit,
        customs clearance, delivery, use, advertising, display, reproduction,
        offer, sale, supply, purchase, provision or re-sale of such product or
        any transaction, activity or dealing in respect of any such product.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Specific Services&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean those services, provided by relevant members of the Inbox
        Group in or through this Website.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Specific Terms&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean the respective terms and conditions applicable to Specific
        Services, including those prescribed or imposed by relevant members of
        the Inbox Group in relation to such Specific Services, or those
        contained in respective agreements entered into between Visitors and
        relevant members of the Inbox Group in relation to such Specific
        Services.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Terms of Use&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall have the meaning ascribed thereto in the second paragraph of the
        opening section above.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Third-party Content&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean those content, information, applications, programs, texts,
        images, links, sounds, graphics, videos, software and other materials
        displayed in or made available in or through, included in, posted
        through, or linked through, this Website (including all files within and
        attachments transmitted through this Website and all data within such
        files and attachments), which are submitted by, originate from, are
        provided by, are obtained from or pertain to third-party websites or
        other third parties, and in or to which the Intellectual Property Rights
        are not owned by members of the Inbox Group.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Third-party Licensors&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean the respective owners (not being members of the Inbox Group)
        of the titles, rights and interests in or to the Third-party Content.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Trade Marks&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall have the meaning ascribed thereto in Clause 3 below.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Username&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall refer to the unique login identification name or code which
        identifies a Visitor who registers with this Website.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Visitor&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean the individual or entity who, or the individual or entity
        whose agent or representative, accesses and/or uses this Website, or the
        services, features and functionalities provided in or through this
        Website, or any of the Overall Content.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        <b>&quot;Website&quot;</b>
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        shall mean the website on the Internet owned and/or operated by Inbox
        and the homepage of which is presently located at&nbsp;
        <a href="https://www.supplyzone.ai/">www.supplyzone.ai</a>.
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Interpretation: Any reference in these Terms of Use to any provision
            of a statute shall be construed as a reference to that provision as
            amended, re-enacted or extended at the relevant time. Any references
            herein to 'Clauses' are references to the clauses of these Terms of
            Use. In these Terms of Use, whenever the words 'include', 'includes'
            or 'including' are used, they will be deemed to be followed by the
            words 'without limitation'. Clause headings are inserted for
            convenience only and shall not affect the interpretation of these
            Terms of Use.
          </li>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Application of other terms and conditions&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        These Terms of Use shall apply to each Specific Service, in addition to
        any and all other Specific Terms applicable to such Specific Service;
        provided however that:-
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            in the event of any conflict or inconsistency between any provision
            of the Specific Terms and any provision of these Terms of Use, such
            conflict or inconsistency shall (except as otherwise expressly
            provided or agreed) be resolved in a manner favorable to Inbox
            and/or other relevant members of the Inbox Group; and
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            only to the extent that such conflict or inconsistency cannot be so
            resolved, the provisions of the Specific Terms shall prevail.
          </li>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Ownership of Intellectual Property Rights&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Unless otherwise expressly stated:-
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            all titles, rights and interests (including without limitation
            Intellectual Property Rights) in respect of the Inbox Content are
            owned by, licensed to or controlled by Inbox and/or other relevant
            members of the Inbox Group, and nothing herein confers on Visitors
            any title, right or interest in respect thereof; and
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            all titles, rights and interests (including without limitation
            Intellectual Property Rights) in respect of the Third-party Content
            are owned by, licensed to or controlled by the relevant Third-party
            Licensors, and nothing herein confers on Visitors any title, right
            or interest in respect thereof.
          </li>
        </ol>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        The trade marks, service marks, trade names and logos used and displayed
        on this Website (collectively the &quot;<b>Trade Marks</b>&quot;) are
        registered and unregistered trade marks of Inbox and/or other relevant
        members of the Inbox Group and/or other third parties. No right or
        license is given to Visitors to download, reproduce or use such Trade
        Marks. Nothing on this Website shall be construed as granting, by
        implication, estoppel, or otherwise, any license or right to use any
        Trade Marks, without the written permission of Inbox and/or other
        relevant members of the Inbox Group and/or other third parties. The
        names of Inbox and/or other relevant members of the Inbox Group may not
        be used in any way without the prior written permission of Inbox and/or
        other relevant members of the Inbox Group. Any other names of
        third-party companies, products, programs, applications, systems and
        solutions mentioned or referred to in this Website are or may be the
        trademarks of the respective owners/licensors or licensees, and in such
        cases, Inbox and/or other relevant members of the Inbox Group are not
        and should not be deemed to be connected or associated or affiliated in
        any way with such other owners/licensors or licensees.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Restricted use of Overall Content&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Visitors may use, download, reproduce and/or distribute internally
        non-substantial portions of the Inbox Content, on an occasional ad-hoc
        basis, solely and exclusively for personal or internal and
        non-commercial purposes, PROVIDED HOWEVER THAT: -
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            such use, downloading, reproduction and/or distribution of such
            Inbox Content (either in hard copy or electronic form) is not done
            in any manner which might be competitive with or prejudicial to
            Inbox and/or other relevant members of the Inbox Group and/or their
            businesses, and/or their use, downloading, reproduction and/or
            distribution of such Inbox Content or other content similar thereto;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            such Inbox Content is used, downloaded, reproduced and/or
            distributed, without modification, and with retention or inclusion
            of the appropriate notices, including copyright notices and other
            applicable proprietary notices, indicating Inbox (and/or other
            relevant members of the Inbox Group, as the case may be) as the
            source of such Inbox Content and prohibiting any further use,
            downloading, reproduction and/or distribution of such Inbox Content;
            and
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            such use, downloading, reproduction and/or distribution of any Inbox
            Content do not and shall not be deemed to transfer any title, right
            or interest in or to such Inbox Content, and such Inbox Content may
            be used, downloaded, reproduced and/or distributed only in
            accordance with Clauses 3, 4 and 5.
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Except as expressly permitted in this Clause 4, Visitors may not
            use, download, copy, reproduce, modify, alter, reverse engineer,
            decompile, disassemble, separate, sell, transfer, rent, license,
            publish, republish, display, broadcast, hyperlink, mirror, frame,
            transmit, store, install, distribute, disseminate in any form or in
            any manner or by any means, or otherwise allow access to or in any
            other fashion re-use, all or any part or parts of this Website, the
            Inbox Content and/or the Third-party Content, without the prior
            written permission of Inbox and/or other relevant members of the
            Inbox Group and/or the relevant third-party owners/licensors or
            licensees of the relevant Intellectual Property Rights, as the case
            may be.
          </li>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Protection of Intellectual Property Rights&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Visitors acknowledge that this Website and the Inbox Content (or the
        Third-Party Content, as the case may be) were prepared by Inbox and/or
        other relevant members of the Inbox Group (or the Third-Party Licensors,
        as the case may be) through the application of methods and standards of
        judgment developed and applied through the expenditure of substantial
        time, effort and money.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Visitors agree to protect all rights and interests (including without
        limitation Intellectual Property Rights) of Inbox and/or other relevant
        members of the Inbox Group (or the Third-Party Licensors, as the case
        may be) in or to this Website and the Inbox Content (or the Third-Party
        Content, as the case may be).
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        In addition, Visitors shall honor and comply with reasonable written
        requests made by Inbox and/or other relevant members of the Inbox Group
        (or, as the case may be, the Third-party Licensors) in connection with
        the protection, enforcement or exercise of Inbox's and/or other relevant
        members' of the Inbox Group (or, as the case may be, the Third-party
        Licensors') contractual and other legal (whether statutory or common
        law) or equitable rights and remedies in respect of this Website and the
        Inbox Content (or the Third-party Content, as the case may be).
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Updates&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        All updates to or addition of Inbox Content and Third-Party Content, and
        all new versions of this Website shall be subject to these Terms of Use
        as if each such update, addition and/or new version were the original
        Inbox Content, Third-party Content or Website (as the case may be)
        referred to herein.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Modifications&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Inbox and/or other relevant members of the Inbox Group reserve the right
        to control the look and feel of this Website and shall be entitled to
        publish any notices or disclaimers on this Website in the manner and to
        the extent deemed necessary by Inbox and/or other relevant members of
        the Inbox Group at their sole discretion.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Inbox and/or other relevant members of the Inbox Group reserve the right
        to update, suspend, discontinue, alter or modify this Website or any
        information, data, instructions, functionalities, features, services,
        products or other content provided in or through this Website, including
        portions thereof, from time to time and without giving any reason or
        prior notice. Such updates, suspensions, discontinuations, alterations
        or modifications may include, without limitation, changes to, additions
        of or withdrawals of information, data, instructions, functionalities,
        features, services, products or other content. Inbox and/or other
        relevant members of the Inbox Group shall not be liable if any such
        updates, suspensions, discontinuations, alterations or modifications
        prevent or disrupt Visitors from accessing this Website or associated
        information, data, instructions, functionalities, features, services,
        products or other content.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Inbox and/or other relevant members of the Inbox Group reserve the right
        (but shall not be obliged) to:
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            monitor, screen or otherwise control any activity, content or
            material on or accessible through this Website, or investigate any
            violation of these Terms of Use and take any action they deem
            appropriate;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            prevent, suspend or restrict any Visitor's access to (i) any
            password-protected or secure areas of this Website and/or (ii) any
            particular service, functionality or feature provided in or through
            this Website; and/or
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            report any activity it suspects to be in violation of any applicable
            law, statute or regulation to the appropriate authorities and to
            co-operate with such authorities.
          </li>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          No endorsement of third parties&nbsp;
        </li>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Without prejudice to Clause 9, Inbox and/or other relevant members
            of the Inbox Group do not endorse or make any representation or
            warranty whatsoever (whether express, implied or statutory), and
            shall not be responsible or liable under any circumstances
            whatsoever, in respect of: -
          </li>
        </ol>
      </ol>

      <p className="">
        1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>any third-party
        website hyperlinked to this Website, or any information, data,
        instructions, functionalities, features, services, products or other
        content offered or provided in or through such third-party website;
      </p>

      <p className="">
        2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>any third-party
        website hyperlinked to this Website, or any information, data,
        instructions, functionalities, features, services, products or other
        content offered or provided in or through such third-party website;
      </p>

      <p className="">
        3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>(without prejudice to
        the generality of the foregoing) any Third-Party Content.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Such third-party websites, third-party information, data, instructions,
        functionalities, features, services, products or other content (whether
        provided or offered in or through such third-party websites or in or
        through this Website) and Third-party Content are the sole
        responsibility of independent third parties and are not under the
        control of Inbox and/or other relevant members of the Inbox Group, and
        as against Inbox and/or other relevant members of the Inbox Group,
        Visitors' access and/or use thereof and/or reliance thereon is solely at
        such Visitors' own risk (and Visitors are advised not to act and/or rely
        thereon without first independently verifying the same).
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Visitors' rights and remedies in respect thereof are governed solely by
        other applicable agreements or terms (if any) between the relevant
        Visitor and the relevant third-party. For the avoidance of doubt, the
        agreements or terms pertaining to the foregoing are agreements or terms
        entered into directly and only between the relevant Visitor and the
        relevant third-party. In no event shall the Inbox Group establish or be
        required to establish any such agreement or terms with such Visitor.
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Without prejudice to the generality of Clause 8.a above: -
          </li>
        </ol>
      </ol>

      <p className="">
        1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>a description or
        reference to a product or service in this Website (including any
        description or reference via hyperlink) does not imply endorsement by
        Inbox and/or other relevant members of the Inbox Group of that product
        or service;
      </p>

      <p className="">
        2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Inbox and/or other
        relevant members of the Inbox Group do not make any representation or
        warranty as to the quality, accuracy, reliability, currency, suitability
        or lack of defects or deficiencies of any third-party websites,
        third-party information, data, instructions, functionalities, features,
        services, products or other content (whether provided or offered in or
        through such third-party websites or in or through this Website) and
        Third-party Content;
      </p>

      <p className="">
        3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Inbox and/or other
        relevant members of the Inbox Group shall not be liable or responsible
        for any defects, deficiencies, errors, omissions or inaccuracies or any
        delays, defamation, libel, slander, misrepresentation, falsehood,
        obscenity, pornography, profanity, or any other objectionable,
        offensive, indecent or illegal material contained in any third-party
        websites, third-party information, data, instructions, functionalities,
        features, services, products or other content (whether provided or
        offered in or through such third-party websites or in or through this
        Website) and Third-party Content; and
      </p>

      <p className="">
        4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>any third parties
        submitting or providing Third-party Content:
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        - are solely responsible for such Third-party Content;
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        - without prejudice to Clause 12, warrant and represent that they have
        the right and authority to submit or provide such Third-party Content
        and that such Third-party Content do not infringe the rights and
        interests (including without limitation Intellectual Property Rights) of
        any third-party; and
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        - hereby grant to Inbox and/or other relevant members of the Inbox Group
        a non-exclusive, world-wide, royalty-free, irrevocable license and right
        to host, transmit, distribute or use (which will include the right to
        copy, reproduce and/or publish) such Third-party Content in connection
        with the operation of this Website and for the purposes such third
        parties have instructed or requested.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Exclusion of representations and warranties&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Information displayed on this Website may be from a variety of sources.
        All data and/or information (including Overall Content) contained in
        this Website is provided for informational purposes only.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        This Website and the Overall Content are made available &quot;as
        is&quot; and &quot;as available&quot;, without any representation or
        warranty of any kind, whether express, implied or statutory, given by
        Inbox and/or other relevant members of the Inbox Group in conjunction
        with this Website and the Overall Content, including but not limited to:
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            a description or reference to a product or service in this Website
            (including any description or reference via hyperlink) does not
            imply endorsement by Inbox and/or other relevant members of the
            Inbox Group of that product or service;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Inbox and/or other relevant members of the Inbox Group do not make
            any representation or warranty as to the quality, accuracy,
            reliability, currency, suitability or lack of defects or
            deficiencies of any third-party websites, third-party information,
            data, instructions, functionalities, features, services, products or
            other content (whether provided or offered in or through such
            third-party websites or in or through this Website) and Third-party
            Content;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Inbox and/or other relevant members of the Inbox Group shall not be
            liable or responsible for any defects, deficiencies, errors,
            omissions or inaccuracies or any delays, defamation, libel, slander,
            misrepresentation, falsehood, obscenity, pornography, profanity, or
            any other objectionable, offensive, indecent or illegal material
            contained in any third-party websites, third-party information,
            data, instructions, functionalities, features, services, products or
            other content (whether provided or offered in or through such
            third-party websites or in or through this Website) and Third-party
            Content; and
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any representation or warranty that this Website and/or any of the
            Overall Content will be provided uninterrupted, secure or free from
            errors, omissions, computer viruses or other malicious, destructive
            or corrupting code, agent, program or macros, or that any identified
            defect will be corrected;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any representation or warranty of the security of any Overall
            Content transmitted by or to Visitors through this Website;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any representation or warranty that the Overall Content transmitted
            or received through this Website by Visitors will not be accessed by
            unauthorized third parties and/or disclosed by Inbox and/or other
            relevant members of the Inbox Group or their directors, officers,
            employees, representatives or agents to third parties purporting to
            be such Visitors or purporting to act under such Visitors' authority
            (and Inbox and/or other relevant members of the Inbox Group neither
            endorse nor assume any responsibility for the contents of such
            Visitors' transmissions or communications through this Website and
            such Visitors are solely responsible therefor); and
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            all representations and/or warranties, to the extent capable of
            being excluded under applicable law, are hereby expressly excluded
            by Inbox and/or other relevant members of the Inbox Group, their
            licensors, dealers, distributors, representatives, agents,
            directors, officers and employees, and all third parties (if any)
            involved in the creation, production or delivery of this Website
            and/or any of the Overall Content.
          </li>
        </ol>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        In particular, but without prejudice to the generality of the foregoing,
        Inbox and/or other relevant members of the Inbox Group, their licensors,
        dealers, distributors, representatives, agents, directors, officers and
        employees, and all third parties (if any) involved in the creation,
        production or delivery of this Website and/or the Overall Content, make
        no representations or warranties as to (and Visitors assume the entire
        risk as to):-
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            anything contained in, the performance of, or the results that may
            be obtained by using, this Website or any of the Overall Content;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            the consequences in connection with the downloading, installing or
            using of any of the Overall Content transmitted through this
            Website;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            the quality, accuracy, reliability or suitability for any purpose
            whatsoever of this Website or any of the Overall Content; and
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            the standing, credit or otherwise of any person or entity mentioned
            in this Website or in any of the Overall Content.
          </li>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Exclusion of liability&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        In no event nor for any reason whatsoever shall Inbox and/or other
        relevant members of the Inbox Group, their licensors, dealers,
        distributors, representatives, agents, directors, officers or employees,
        or any third-party involved in the creation, production or delivery of
        this Website or any of the Overall Content be liable, even if
        foreseeable or if the foregoing parties may have been advised of, or
        otherwise might have anticipated, the possibility or likelihood of such
        loss, damages or expenses, for any loss, damages or expenses (including
        direct, indirect, special, incidental, secondary, consequential,
        punitive or exemplary damage, or economic or financial loss, or any
        claims for loss of profits, loss of data, loss of business information,
        loss of use, loss of anticipated revenue, profits or benefits or
        business interruption or delay), whatsoever or howsoever caused
        (regardless of the form of action, including tort or strict liability),
        arising directly or indirectly from or in connection with:-
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any access, use, misuse or inability to access or use, or any
            information, data, instructions, functionalities, features,
            services, products or other content obtained or downloaded from or
            through, this Website, other website or webpage linked to this
            Website or any other party referred by this Website, or reliance on
            any of the Overall Content, whether based upon contract, tort,
            negligence, strict liability or otherwise;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any consequences or damages arising in connection with the
            downloading, installing or using, or the inability to download,
            install or use, of any Overall Content transmitted through this
            Website by Visitors or other third parties;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any failure, delay, disruption or interruption in, of or to this
            Website, howsoever caused or arising;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any failure, delay, disruption or interruption in, of or to the use,
            availability transmission or reception of any Overall Content
            through this Website, howsoever caused or arising;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any defect, deficiency, breakdown or failure of any software or any
            equipment or system (whether or not maintained or operated by the
            Inbox Group or any other person), or any system, server or
            connection failure, error, omission, interruption, delay in
            transmission, computer virus or other malicious, destructive or
            corrupting code, agent, program or macros, howsoever caused or
            arising;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any loss, corruption, deletion or damage of or to any Overall
            Content (whether belonging to, provided by or stored by the Visitor
            or otherwise) which is transmitted to or stored in any system or
            equipment (whether or not maintained or operated by the Inbox Group,
            the Visitor or other third parties), howsoever caused or arising;
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any error, interruption, breach or failure or delay in performance
            of its obligations under these Terms of Use or in this Website's
            operation, or for any inaccuracy, unreliability or unsuitability of
            this Website or any of the Overall Content, which is due, in whole
            or in part, directly or indirectly to a Force Majeure Event; and
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            any defects, deficiencies, errors, omissions or inaccuracies in this
            Website, any of the Overall Content or any of the third-party
            websites, third-party information, data, instructions,
            functionalities, features, services, products or other content
            (whether provided or offered in or through such third-party websites
            or in or through this Website).
          </li>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Limitation of liability&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Notwithstanding any other provision of these Terms of Use, the maximum
        cumulative liability of all of Inbox and/or other relevant members of
        the Inbox Group and their licensors, dealers, distributors,
        representatives, agents, directors, officers or employees, or third
        parties (if any) involved in the creation, production or delivery of
        this Website or any of the Overall Content, in respect of all
        Liabilities under, arising out of or in connection with these Terms of
        Use and the provision of this Website and the Overall Content, shall in
        no event exceed in the aggregate the sum of one United States Dollar
        (US$1.00) only, whether in contract, tort (including negligence or
        breach of statutory duty) or otherwise, or for any loss, damage or
        liability whatsoever.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Infringement of rights and/or violation of laws or regulations&nbsp;
        </li>
      </ol>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Each Visitor agrees and undertakes that the Visitor shall not use
            this Website or any of the Overall Content in any manner that
            infringes or violates, or might potentially infringe or violate, any
            rights and interests (including without limitation Intellectual
            Property Rights) of any person or entity, or any applicable law or
            regulation.
          </li>
        </ol>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Without limitation to the generality of the foregoing, each Visitor
        agrees and undertakes that the Visitor shall not use this Website or any
        of the Overall Content to acquire or seek to acquire any product or
        undertake or seek to undertake any activity, or to send any inquiries
        to, to communicate with, or to otherwise contact, any person or entity
        with a view to or in connection with acquiring any product or
        undertaking any transaction or activity, that would or might actually or
        potentially infringe or violate, or involve any actual or potential
        infringement or violation of, any rights and interests (including
        without limitation Intellectual Property Rights) of any person or
        entity, or any applicable law or regulation.
      </p>

      <ol>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            Without prejudice to Clause 13, each Visitor who acquires or wishes
            to acquire any product from, or who enters or wishes to enter into
            any transaction or activity with, or who sends or wishes to send any
            inquiry to, communicates or wishes to communicate with or otherwise
            contacts or wishes to contact, or who receives or wishes to receive
            any communication from, any person or entity, or who undertakes or
            wishes to undertake any Relevant Activity in relation to any product
            appearing in this Websites or any of the Overall Content, whether
            directly or indirectly as a result of or in connection with the use
            of this Website and/or any of the Overall Content shall:-
          </li>
          <ol>
            <li className="" style={{ textAlign: "justify" }}>
              bear solely and exclusively all responsibility and risks for such
              product, acquisition, transaction, activity, inquiry,
              communication, contact or Relevant Activity, and for verifying
              and/or ensuring that the same do not and will not infringe or
              violate any rights and interests (including without limitation
              Intellectual Property Rights) of any person or entity, or any
              applicable law or regulation (and none of the members of the Inbox
              Group makes any representation or warranty whatsoever (whether
              express, implied or statutory) that the same do not and will not
              infringe or violate any rights and interests (including without
              limitation Intellectual Property Rights) of any person or entity,
              or any applicable law or regulation); and
            </li>
            <li className="" style={{ textAlign: "justify" }}>
              fully indemnify, defend and/or hold harmless, as the case may be,
              the Inbox Indemnitees in respect of any and all Liabilities that
              may be suffered, sustained or incurred, directly or indirectly, by
              any of the Inbox Indemnitees, as a result of, or arising from or
              in connection with, any such product, acquisition, transaction,
              activity, inquiry, communication, contact or Relevant Activity
              infringing or violating (or being alleged to infringe or violate)
              the rights and interests (including without limitation
              Intellectual Property Rights) of any person or entity, or any
              applicable law or regulation (and none of the Inbox Indemnitees
              assumes or shall bear any liability, obligation, responsibility or
              risk whatsoever for or in respect of any such product,
              acquisition, transaction, activity, inquiry, communication,
              contact or Relevant Activity).
            </li>
          </ol>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Without prejudice to the generality of the foregoing provisions of
          Clause 12.b, each Visitor who acquires or wishes to acquire any
          product appearing in this Website is advised to conduct, and shall in
          any event be solely and exclusively responsible for conducting, its
          own due diligence enquiries, checks and investigations, including
          (without limitation) consulting the relevant government or regulatory
          authorities, and seeking its own independent legal advice, as may be
          necessary and appropriate, so as to satisfy itself and ensure that
          such product and the Relevant Activities in relation to such product
          do not and will not infringe any rights and interests (including
          without limitation Intellectual Property Rights) of any person or
          entity and are and will be in compliance with all applicable laws and
          regulations.
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Each Visitor acknowledges and agrees that Inbox and/or other relevant
        members of the Inbox Group shall not be, or be deemed to be, a party to,
        or otherwise involved in, any transaction(s) or activity(ies) that may
        be entered into between any Visitor and any person or entity directly or
        indirectly as a result of or in connection with the use by any Visitor
        of this Website.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Indemnity&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Visitors shall indemnify the Inbox Indemnitees against any Liabilities
        that may be suffered, sustained or incurred, directly or indirectly, by
        the Inbox Indemnitees as a result of, or arising from or in connection
        with: -
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          the Inbox Indemnitees having made available this Website or having
          entered into these Terms of Use with such Visitors; or
        </li>

        <li className="" style={{ textAlign: "justify" }}>
          the enforcement of the Inbox Indemnitees' rights under these Terms of
          Use; or
        </li>

        <li className="" style={{ textAlign: "justify" }}>
          any action taken by the Inbox Indemnitees in reliance upon any
          instructions which such Visitors may give in relation to this Website
          or their access thereto or use thereof; or
        </li>

        <li className="" style={{ textAlign: "justify" }}>
          any misrepresentation, negligence, fraud and/or misconduct, and/or any
          breach of these Terms of Use, by or on the part of such Visitors.
        </li>

        <li className="" style={{ textAlign: "justify" }}>
          Use and operation of this Website&nbsp;
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Use of information relating to or collected at this Website:</b>
          &nbsp;Visitors agree that all information and/or particulars sent or
          submitted by such Visitors in relation to any access and/or use of the
          Website or which have been collected from such Visitors' access and/or
          use of this Website is non-confidential and non-proprietary unless
          otherwise expressly indicated by such Visitors and may be used by
          Inbox and/or the relevant member of the Inbox Group in accordance with
          this Website's Privacy Policy (accessible through the &quot;Privacy
          Policy&quot; link in the footer of this Website).
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Contents of transmissions or communications:</b>&nbsp;With respect
          to all contents of transmissions or communications Visitors make or
          submit through this Website, Inbox Group shall be free to reproduce,
          use, disclose, host, publish, transmit and distribute all such
          contents of transmissions or communications or any part thereof to
          others without limitations, and Visitors hereby grant to Inbox and/or
          the relevant member of the Inbox Group a non-exclusive, world-wide,
          royalty-free, irrevocable license and right to do the same.
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Restricted use:</b>&nbsp;Visitors agree and undertake not to:
        </li>
        <ol>
          <li className="" style={{ textAlign: "justify" }}>
            use any software or material that contains a virus or damaging
            component which may corrupt this Website's data or detrimentally
            interfere with this Website or the operation of this Website;
          </li>
          <li className="" style={{ textAlign: "justify" }}>
            transmit any materials or information through this Website which are
            or may be objectionable, offensive, indecent, defamatory, libelous,
            slanderous, false, obscene, pornographic or which may not be
            lawfully disseminated under applicable laws or which such Visitors
            know or have reason to suspect contains any viruses or damaging
            components which may corrupt this Website's data detrimentally
            interfere with this Website or the operation of this Website; or
          </li>
          <li className="" style={{ textAlign: "justify" }}>
            use this Website other than in conformance with the acceptable use
            policies of any connected computer networks and any applicable
            Internet standards.
          </li>
        </ol>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Access to this Website:</b>&nbsp;Visitors shall be responsible for
          obtaining and using the necessary software and/or device,
          telecommunications services, hardware and/or equipment necessary to
          obtain access to this Website at their own risk and expense. In
          particular, Visitors agree and acknowledge that Inbox and/or other
          relevant members of the Inbox Group are not providing them any
          Internet access or other telecommunication services and that any
          Internet access or telecommunications services that they may require
          to access and/or use this Website or the Overall Content will be their
          sole responsibility and that they will separately obtain such access
          and/or services, at their own cost, from an appropriate Internet
          access service provider.
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>At Visitors' own risk:</b>&nbsp;Any risk of misunderstanding,
          error, loss, damage or expense resulting from the use of this Website
          is entirely at the Visitors' own risk and Inbox and/or other relevant
          members of the Inbox Group shall not be liable therefor.
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Members only:</b>&nbsp;Access to and use of password-protected
          and/or secure areas of this Website are restricted to authorized users
          only. Visitors agree and undertake not to obtain or attempt to obtain
          unauthorized access to such parts of this Website, or to any other
          protected information, through any means not intentionally made
          available by Inbox for the specific use of such Visitors.
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Username/Password:</b>&nbsp;A Username and Password may either be:
          (i) determined by and issued to a Visitor who registers with this
          Website by Inbox; or (ii) provided by a Visitor who registered with
          this Website and accepted by Inbox in its sole and absolute discretion
          in connection with the use of and/or access to this Website. Inbox may
          at any time in its sole and absolute discretion forthwith invalidate
          the Username and/or Password without giving any reason or prior notice
          and shall not be liable or responsible for any loss or damage suffered
          by or caused by such Visitor or arising out of or in connection with
          or by reason of such invalidation. Each Visitor who registers with
          this Website hereby agrees to change his Password from time to time
          and to keep the Username and Password confidential and shall be
          responsible for the security of his account and liable for any
          disclosure or use (whether such use is authorized or not) of the
          Username and/or Password. Each Visitor who registers with this Website
          shall notify Inbox immediately if he has knowledge that or has reason
          for suspecting that the confidentiality of the Username and/or
          Password has been compromised or if there has been any unauthorized
          use of the Username and/or Password.
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Purported access and/or use of this Website:</b>&nbsp;Each Visitor
          who registers with this Website agrees and acknowledges that any use
          or purported use of or access to or purported access to this Website
          and any information, data, instructions or communications referable to
          the Username and Password shall be deemed to be, as the case may be:
          (i) use of or access to this Website by such Member; and/or (ii)
          information, data, instructions or communications carried out,
          transmitted or validly issued by such Visitor. Inbox and/or other
          relevant members of the Inbox Group shall be entitled (but not
          obliged) to act upon, rely on and/or hold such Visitor solely
          responsible and liable in respect thereof as if the same were carried
          out, transmitted or validly issued by such Visitor. Each Visitor who
          registers with this Website further agrees and acknowledge that such
          Visitor shall be bound by any access or use of this Website (whether
          such access or use is authorized by such Visitor or not) and/or
          information, data, instructions or communications referable to such
          Visitor's Username and Password.
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          <b>Binding and conclusive:</b>&nbsp;Each Visitor agrees and
          acknowledges that any records maintained by Inbox and/or other
          relevant members of the Inbox Group relating to or in connection with
          this Website shall be binding and conclusive on such Visitor for all
          purposes whatsoever and shall be conclusive evidence of any
          information and/or data transmitted between Inbox and/or other
          relevant members of the Inbox Group and such Visitor. Each Visitor
          hereby agrees that all such records are admissible in evidence and
          that he shall not challenge or dispute the admissibility, reliability,
          accuracy or the authenticity of such records merely on the basis that
          such records are in electronic form or are the output of a computer
          system, and he hereby waives any of his rights, if any, to so object.
        </li>
      </ol>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Termination of access&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Visitors agree that the Inbox Group may, at its sole discretion, deny or
        suspend such Visitors' access to password-protected or secure areas of
        this Website, or any of the services, features or functionalities
        provided in or through this Website, for any reason, including without
        limitation, if the Inbox Group believes that such Visitors have violated
        or acted inconsistently with any terms or conditions set out herein, or
        if in the Inbox Group's opinion, it is not suitable to continue
        providing such areas, services, features or functionalities.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Delegation&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Inbox and/or other relevant members of the Inbox Group may delegate or
        outsource the performance of any of their functions in connection with
        this Website and reserve the right to use any agents, delegates or
        contractors on such terms as they deem appropriate.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Entire agreement&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        These Terms of Use, together with any other Specific Terms relating to
        Specific Services (if applicable), constitute the entire agreement
        between the Visitor and Inbox and/or other relevant members of the Inbox
        Group with respect to the subject matter hereof and thereof, and
        supersede any and all prior written and/or oral representations,
        understandings, agreements or communications between such parties
        regarding the subject matter hereof and thereof.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Waivers&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Failure or delay of either party to insist at any time upon strict
        compliance with any provision herein, to exercise any right or remedy
        contained in these Terms of Use or to seek remedy of any breach shall
        not constitute or be construed as a waiver of such provision or breach,
        or of any other rights or remedies, or a bar to the exercise or
        enforcement at any subsequent time or times.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        No waiver of any right under or any provision of these Terms of Use
        shall be valid or effective unless in writing signed by the party
        granting such waiver.
      </p>

      <p className="" style={{ textAlign: "justify" }}>
        Any waiver of any right under or any provision of these Terms of Use
        shall not be deemed to be a waiver of any other right(s) under or any
        other provision(s) of these Terms of Use or of the same right or
        provision on another occasion.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Illegality&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        If any provision of these Terms of Use is or becomes illegal, invalid or
        unenforceable (&quot;Affected Provision&quot;), then, to the maximum
        extent possible, such Affected Provision shall be modified and/or
        replaced by an equivalent legal, valid and enforceable provision in a
        manner which accords with or which gives effect to, as closely as
        possible, the original intent of the Affected Provision. If, however,
        such modification or replacement is not possible, then (if and to the
        extent permissible) the Affected Provision shall be deemed to be severed
        from these Terms of Use. The illegality, invalidity or unenforceability
        of, or the modification, replacement or severance of, the Affected
        Provision shall not in any way affect or impair the legality, validity
        or enforceability of the other provisions of these Terms of Use, which
        shall remain in full force and effect.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Relationship of parties&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        The relationship of the Visitors and the Inbox Group established by
        these Terms of Use shall be solely that of independent contractors.
        Nothing contained in these Terms of Use shall constitute or be construed
        as creating any fiduciary, partnership, joint venture, agency or sales
        representative relationship between the Visitors and the Inbox Group,
        and neither of the Visitors nor the Inbox Group shall have any right
        whatsoever to incur any obligations or liabilities on behalf of or
        binding on the other party.
      </p>

      <ol>
        <li className="">Rights of third parties&nbsp;</li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        Save for other relevant members of the Inbox Group, a person or entity
        who is not a party to these Terms of Use shall have no right to enforce
        any term of these Terms of Use, regardless of whether such person or
        entity has been identified by name, as a member of a class or as
        answering a particular description. For the avoidance of doubt, nothing
        in this Clause 21 shall affect the rights of any permitted assignee or
        transferee of these Terms of Use.
      </p>

      <ol>
        <li className="" style={{ textAlign: "justify" }}>
          Governing Law &amp; Jurisdiction&nbsp;
        </li>
      </ol>

      <p className="" style={{ textAlign: "justify" }}>
        These Terms of Use shall be construed in accordance with and governed by
        the laws of the Seychelles Special Administrative Region. The Visitor
        hereby submits to the non-exclusive jurisdiction of the courts of the
        Seychelles Special Administrative Region and/or such other courts of
        competent jurisdiction as Inbox and/or other relevant members of the
        Inbox Group may in its absolute discretion deem fit.
      </p>
    </>
  );
};
