import * as React from "react";
import ProductDefault from "./ProductDefault";
import Snippets from "../../../../../helpers/Snippets";
import ProductSimple from "./ProductSimple";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import Skeleton from "react-loading-skeleton";

const ProductsDoubleSlider8UP:any = ({ category, onSetProductPreviewHandler }:any) => {
    const [dummyArray, setDummyArray] = React.useState([1, 2, 3, 4, 5, 6, 7, 8])
    const [productList, setProductsList] = React.useState([]);

    React.useEffect(() => {

        AxiosAPI.publicApiRequest('GET',`/marketplace/category/${category.id[0]}/products?recursive=1&pageSize=1000`,{})
        .then(({ data: products }) => {

            //Todo: get products from category array. now it's getting all products from 1 array

            //console.warn(":::PRODUCTS:::", products, category)

            if (products) {

                const chunkedProducts = Snippets.arrays.chunk(products, 2); // Todo: consider brining back the chunk: -|> Snippets.arrays.chunk(products, 4);

                //console.warn(":::PRODUCTS CHUNKED:::", chunkedProducts)

                setProductsList(chunkedProducts);

                return;

                chunkedProducts.map((item: any, itemIndex: number) => {

                    if (item.length < 2) {
                        delete chunkedProducts[itemIndex];
                    }

                })

            }

        }).catch((error: any) => {

        });

    }, []);

    return (
        <>
            {/* Start of Footer */}
            <React.Fragment>
                {productList.length > 0 ? (
                    <>
                        <div className="col-lg-9 col-sm-8">
                            <div className="swiper-container swiper-theme" data-swiper-options="{ 'spaceBetween': 20, 'observer': true, 'observeParents': true, 'autoplay': { 'delay': 4000, 'disableOnInteraction': false }, 'loop': true, 'slidesPerView': 2, 'breakpoints': { '992': { 'slidesPerView': 3 }, '1200': { 'slidesPerView': 4 } } }">
                                <div className="swiper-wrapper row cols-xl-4 cols-lg-3 cols-2 product-col mb-5">

                                    {productList.map((chunk: any, index:number) => (
                                        <div key={index} className="swiper-slide">
                                            {chunk.map((product: any, index:number) => (
                                                <ProductSimple key={index} onSetProductPreview={onSetProductPreviewHandler} product={product} />
                                            ))}
                                        </div>
                                    ))}



                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                            {/*  End of Produts   */}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-lg-9 col-sm-8">
                            <div className=" row cols-4">
                                {dummyArray.map((item:any, index:number) => (
                                    <div key={index} className="swiper-slide category-wrap mt-20" style={{ marginBottom: "20px" }}>
                                        <div className="category category-classic category-absolute overlay-zoom br-sm" style={{ padding: "10px" }}>
                                            {<Skeleton count={1} height={202} />}
                                            {<Skeleton count={1} height={38} />}
                                            {<Skeleton count={1} height={18} />}
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </>
                )}
            </React.Fragment>
            {/* End of Footer */}
        </>
    );
};

export default ProductsDoubleSlider8UP;
