import React, { useEffect, useState } from "react";
import { Carousel, Col, Row, Tabs, TabsProps } from "antd";
import { Profile } from "../account/profile-wizard/accounts/model/Profile";
import { useParams } from "react-router-dom";
import AxiosAPI from "../../../helpers/AxiosAPI";
import { HomeTab } from "./HomeTab";
import { CompanyProfile } from "./CompanyProfile";
import { ContactUs } from "./ContactUs";
import { Products } from "./Products";
const MiniSite: React.FC = () => {
    let { supplierId } = useParams();

    const [profile, setProfile] = useState(new Profile())
    const [latestProducts, setLatestProducts] = useState([])
    const [sellingProducts, setSellingProducts] = useState([])
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [logoUrl, setLogoUrl] = useState('')
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Home',
            children: <HomeTab profile={profile} latest={latestProducts} selling={sellingProducts}></HomeTab>,
        },
        {
            key: '2',
            label: 'Products',
            children: <Products products={products} profile={profile}></Products>,
        },
        {
            key: '3',
            label: 'Company Profile',
            children: <CompanyProfile profile={profile}></CompanyProfile>,
        },
        {
            key: '4',
            label: 'Contact Us',
            children: <ContactUs profile={profile} products={products}></ContactUs>,
        },
    ];

    useEffect(() => {
        AxiosAPI.publicApiRequest('get', `/marketplace/supplier/${supplierId}`, null)
            .then((res: any) => {
                setProfile(res.data)

                for (let i = 0; i < res.data.documents.length; i++) {
                    let document = res.data.documents[i]
                    if (document.type == "LOGO") {
                        setLogoUrl(`https://api-live.supplyzone.ai/home/download-attachment/${document.s3Key}`)
                    }
                }

            })
        AxiosAPI.publicApiRequest('get', `/marketplace/supplier/${supplierId}/products/0/4`, null)
            .then((res: any) => {
                setLatestProducts(res.data)
            })
        AxiosAPI.publicApiRequest('get', `/marketplace/supplier/${supplierId}/products/1/4`, null)
            .then((res: any) => {
                setSellingProducts(res.data)
            })
        AxiosAPI.publicApiRequest('get', `/marketplace/supplier/${supplierId}/products/0/1000`, null)
            .then((res: any) => {
                setProducts(res.data)
            })

        AxiosAPI.publicApiRequest('get', `/marketplace/supplier/${supplierId}/categories`, null)
            .then((res: any) => {
                setCategories(res.data)
            })



    }, [supplierId])
    const contentStyle: React.CSSProperties = {
        height: '342px',
        color: '#fff',
        lineHeight: '342px',
        textAlign: 'center',
        background: '#364d79',
    };
    return (
        <>
            <div className="breadcrumb-container container">
                <div className="product-navigation">
                    <ul className="breadcrumb">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li className="delimiter">&gt;</li>

                        <li className="delimiter">&gt;</li>
                        <li>{profile.name}</li>
                    </ul>
                </div>
            </div>

            <main
                id="main"
                className="main mb-10 pb-1"
                style={{ backgroundColor: "rgb(245,245,245)", padding: "20px 0" }}
            >
                {/*    Start of Page Content    */}
                <div className="page-content">
                    <div className="container">
                        <div className="row gutter-lg">
                            <div >
                                {profile.name && (
                                    <>
                                        <Row>
                                            <Col span={24}>
                                                <Carousel >
                                                    {profile.documents.map((document: any, index: number) => (
                                                        document.type == 'MINI_SITE_BANNER' && (
                                                            <img src={`https://api-live.supplyzone.ai/home/download-attachment/${document.s3Key}`} />
                                                        )
                                                    ))}

                                                </Carousel>
                                            </Col>
                                            <Col span={24}>

                                                <div style={{ background: "rgba(34, 34, 34, 0.8)", padding: "2.3rem 3rem 2.6rem", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", marginBottom: "25px" }}>
                                                    <div className="store-content-left mr-auto" style={{ display: "flex" }}>
                                                        <div className="personal-info" style={{ marginRight: "1rem;" }}>
                                                            <figure className="seller-brand" style={{ margin: "-4.2rem 28px 2rem" }}>
                                                                <img src={logoUrl} alt="Brand" width="100" height="100" />
                                                            </figure>

                                                        </div>
                                                        <div className="address-info">
                                                            <h4 className="store-title" style={{ marginBottom: "0.9rem" }}>{profile.name}</h4>
                                                            <ul className="seller-info-list list-style-none" style={{ paddingLeft: "2.4rem" }}>
                                                                <li className="store-address" style={{ fontSize: "14px", marginBottom: "0.4rem" }}>
                                                                    <i className="w-icon-map-marker" style={{ margin: "0 0.4rem 0 -2.4rem", fontSize: "14px" }}></i>
                                                                    {profile.primaryContact.addressLine1} {profile.primaryContact.addressLine2} {profile.primaryContact.city}
                                                                </li>
                                                                <li className="store-phone" style={{ fontSize: "14px", marginBottom: "0.4rem" }}>
                                                                    <a href="tel:123456789">
                                                                        <i className="w-icon-phone" style={{ margin: "0 0.4rem 0 -2.4rem", fontSize: "14px" }}></i>
                                                                        {profile.primaryContact.phoneNumber}
                                                                    </a>
                                                                </li>
                                                                <li className="store-email" style={{ fontSize: "14px", marginBottom: "0.4rem" }}>
                                                                    <a href="email:#">
                                                                        <i className="far fa-envelope" style={{ margin: "0 0.4rem 0 -2.4rem", fontSize: "14px" }}></i>
                                                                        {profile.primaryContact.emailAddress}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="store-content-right">

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}>
                                                <div className="card xcards mb-5" style={{overflow:"hidden"}}>
                                                    <div className="title-link-wrapper mb-3">
                                                        <h2 className="title mb-0 pt-2 pb-2">
                                                            Categories
                                                        </h2>
                                                    </div>
                                                    <div className="rfq-search-container">
                                                        <input
                                                            type="email"
                                                            className="form-control inquiry-search"
                                                            placeholder="Search Categories"
                                                        />
                                                        <i className="w-icon-search"></i>
                                                    </div>
                                                    <div className="collapse show" style={{ padding: "0px" }}>
                                                        <div className="card-body border-top" style={{ padding: "0px" }}>
                                                            <ul className="menu vertical-menu category-menu">
                                                                {categories.map((category: any, index: number) => (
                                                                    <>
                                                                        <li
                                                                            key={index}
                                                                            className="all-categories-li" style={{ padding: "0px" }}
                                                                        >
                                                                            <a
                                                                                href={`#`}>
                                                                                {`${category.name} (${category.productsCount})`}
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={18}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Tabs className=" ml-4 " defaultActiveKey="1" items={items} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    );
};

export { MiniSite };