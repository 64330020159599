import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Button,
  Flex,
  Tabs,
  Input,
} from "antd";
import Skeleton from "react-loading-skeleton";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import {
  SearchOutlined,
} from "@ant-design/icons";
import { InvitesListItem } from "./InvitesListItem";
import { InvitesModals } from "./InvitesModals";

const InvitesOnePager: React.FC = () => {
  
  const [modalOpenInvite, setModalOpenInvite] = useState(false);

  const [isPageLoading, setIsPageLoading] = useState(true);

  const [items, setItems] = useState([]);

  const [invitesList, setInvitesList] = useState([]);

  const [parsedJSONPENDING, setParsedJSONPENDING] = useState([]);

  const [parsedJSONACCEPTED, setParsedJSONACCEPTED] = useState([]);

  const getListView: any = () => {
    console.log("::getListView::", getListView);
    setIsPageLoading(true);
    AxiosAPI.getSupplierInvites()
      .then((res) => {
        console.log(":: INVITES 1 ::", res.data);
        setInvitesList(res.data);
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.log(":: INVITES 3 ERROR::", error);
        setIsPageLoading(false);
      });
  };

  useEffect(() => {

    const _parsedJSONPENDING: any = invitesList.filter((invite: any) =>
      invite.status.includes("PENDING")
    );
    setParsedJSONPENDING(_parsedJSONPENDING);
    const _parsedJSONACCEPTED: any = invitesList.filter((invite: any) =>
      invite.status.includes("ACCEPTED")
    );
    setParsedJSONACCEPTED(_parsedJSONACCEPTED);
    console.log(":: INVITES 2 ::", [_parsedJSONPENDING, _parsedJSONACCEPTED]);

  }, [invitesList]);

  const setFilterTextPending: any = (text:string) => {
    let listview: any = invitesList
      .filter((invite: any) => invite.status.includes("PENDING"));
    let listviewFiltered: any = listview
      .filter((inviteItem: any) => inviteItem.email.includes(text));
    setParsedJSONPENDING(listviewFiltered);

  };

  const setFilterTextAccepted: any = (text: string) => {
    let listview: any = invitesList.filter((invite: any) =>
      invite.status.includes("ACCEPTED")
    );
    let listviewFiltered: any = listview.filter((inviteItem: any) =>
      inviteItem.email.includes(text)
    );
    setParsedJSONACCEPTED(listviewFiltered);
  };

  useEffect(() => {
    setItems([
      {
        key: "1",
        label: "Pending",
        children: (
          <>
            <Input
              className="mb-5"
              size="large"
              placeholder="Search pending invitations by email."
              prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={(event) => setFilterTextPending(event.target.value)}
            />

            {parsedJSONPENDING.length === 0 && (
              <>
                <div className="text-center">
                  <img
                    style={{ width: 400, margin: "auto" }}
                    src="https://cdni.iconscout.com/illustration/premium/thumb/boy-explaining-about-no-data-folder-10962347-8881971.png?f=webp"
                  />
                  <br />
                  <br />
                  <h3>No pending invitations</h3>
                </div>
              </>
            )}
            {parsedJSONPENDING.length > 0 &&
              parsedJSONPENDING
                .reverse()
                .map((item: any, itemIndex: number) => (
                  <InvitesListItem
                    key={itemIndex}
                    item={item}
                    isPending={true}
                    getListViewTrigger={getListView}
                  />
                ))}
          </>
        ),
      },
      {
        key: "2",
        label: "Accepted",
        children: (
          <>
            <Input
              className="mb-5"
              size="large"
              placeholder="Search accepted invitations by email."
              prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              onChange={(event) => setFilterTextAccepted(event.target.value)}
            />
            {parsedJSONACCEPTED.length === 0 && (
              <>
                <div className="text-center">
                  <img
                    style={{ width: 400, margin: "auto" }}
                    src="https://cdni.iconscout.com/illustration/premium/thumb/boy-explaining-about-no-data-folder-10962347-8881971.png?f=webp"
                  />
                  <br />
                  <br />
                  <h3>No accepted invitations</h3>
                </div>
              </>
            )}
            {parsedJSONACCEPTED.length > 0 &&
              parsedJSONACCEPTED
                .reverse()
                .map((item: any, itemIndex: number) => (
                  <InvitesListItem
                    key={itemIndex}
                    item={item}
                    isPending={false}
                  />
                ))}
          </>
        ),
      },
    ]);
  }, [parsedJSONACCEPTED, parsedJSONPENDING]);

  useEffect(() => {
    getListView();
  }, []);

  return (
    <>
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1 w-100">
              <Flex className="w-100" align="center" justify="space-between">
                <h2 className="title ls-normal mb-0">Supplier Invitations</h2>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    setModalOpenInvite(true);
                  }}
                >
                  Invite Supplier
                </Button>
              </Flex>
            </div>
            <p className="mb-5 mt-5">Invite suppliers to join Supply Zone.</p>
            <div className="pb-2 mb-1">
              {isPageLoading ? (
                <Skeleton count={5} height={100} className="mb-5 rounded-2" />
              ) : (
                <Tabs defaultActiveKey="1" items={items} className="w-100" />
              )}
            </div>
          </div>
        </div>
      </main>
      <InvitesModals
        rfqId={0}
        isModalOpen={modalOpenInvite}
        setIsModalOpen={setModalOpenInvite}
        getListViewTrigger={() => getListView()}
      />
    </>
  );
};
export { InvitesOnePager };
