import React, { PureComponent } from "react";
import { arc, pie } from "d3-shape";
import CountUp from 'react-countup';
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
const radius = 32;

class AnimatedDonut extends PureComponent {
  componentWillMount() {
    const sum = this.props.data.reduce((a, b, i) => a + b.value, 0);

    this.setState({
      pathLengths: this.props.data.map(({ value }) => ({
        current: (value / sum) * radius * Math.PI * 2 * 2,
        end: (value / sum) * radius * Math.PI * 2,
      })),
    });
  }

  render() {
    const { data, speed, width, onFinish, vendors, completed } = this.props;


    const formatter = (value) => <CountUp end={value} separator="," />;


    const arcs = pie()
      .value((d) => d.value)
      .sort((_) => undefined)(data);
    const drawArc = arc().outerRadius(radius).innerRadius(radius);
    const { pathLengths } = this.state;
    const { children } = this.props;

    if (pathLengths.every((offset) => offset.current <= offset.end)) {
      clearInterval(this.interval);
    }

    window.requestAnimationFrame(() => {
      this.setState(({ pathLengths }) =>
        pathLengths.some((offset) => offset.current > offset.end)
          ? {
              pathLengths: pathLengths.map((offset, i) =>
                offset.current > offset.end &&
                (i === 0 ||
                  pathLengths[i - 1].current <= pathLengths[i - 1].end)
                  ? Object.assign({}, offset, {
                      current: offset.current - speed,
                    })
                  : offset
              ),
            }
          : this.state
      );
      //onFinish();
    });

    return (
      <>
        
        <div className="drama-svg">
           
          <svg viewBox="0 0 100 100" >
            <g transform="translate(50, 50)">
              {arcs.map((arc, i) => (
                <path
                  key={i}
                  d={drawArc(arc)}
                  style={{
                    stroke: arc.data.color,
                    strokeWidth: width,
                    strokeDasharray: pathLengths[i].end * 2,
                    strokeDashoffset: pathLengths[i].current,
                  }}
                />
              ))}
              {children}
            </g>
          </svg>
          
        </div>
        
        <div className="h2-vendors">
          <h2 id="supplier-counter">
          <Odometer format="(,ddd).dd" duration={7000} value={vendors} />
          </h2>
        </div>
         
      </>
    );
  }
}

AnimatedDonut.defaultProps = {
  data: [
    { value: 20, color: "#552278" },
    { value: 40, color: "#552278" },
    { value: 60, color: "#552278" },
    { value: 80, color: "#552278" },
  ],
  speed: 1,
  width: 5,
};

export default AnimatedDonut;
