import * as React from "react";

export default () => {
  return (
    <>
      <p>
        <span>
          As a leading provider of electronic commerce services, Supply Zone
          adopts a firm commitment to ensuring information security and
          safeguarding and maintaining the confidentiality of our customers'
          information.
        </span>
      </p>

      <p>
        <span>
          Our IT infrastructure is designed to comply with industry-accepted
          security best practices.
        </span>
      </p>

      <p>
        <span>
          All our systems are hosted in world-class secured data center
          facilities and provide for secured access control to limit the
          potential for abuse by unauthorized third parties.
        </span>
      </p>

      <p>
        <span>
          Such control involves authentication by means of usernames and
          passwords before information can be accessed. The authentication is
          further protected while being transmitted over unsecured public
          networks using industry-standard encryption algorithms known as Secure
          Socket Layer (SSL).
        </span>
      </p>

      <p>
        <span>
          We also proactively review the security of our online solutions
          against new threats to ensure that they are resilient against abuse by
          hackers to garner information about our customers.
        </span>
      </p>

      <p>
        <span>
          Within Supply Zone, there are strict protocols and procedures to limit
          the types of information that can be accessed by employees. This is
          reinforced by round-the-clock monitoring of our services to ensure
          that any suspected abuse is immediately reported and rectified.
        </span>
      </p>

      <p>
        <span>
          We value your suggestions in making our services more secured. If you
          have any queries or concerns regarding security in your use of Supply
          Zone services and products, please send us an e-mail at&nbsp;
          <a href="mailto:support@inboxgroup.ai">
            <span >
              Supply Zone Security
            </span>
          </a>
          . You can also refer to our&nbsp;
          <a href="https://www.globalsources.com/STM/help-faq/en/privacy-policy/">
            <span >
              privacy policy
            </span>
          </a>
          &nbsp;to understand how the information you provide is utilized to
          better serve you.
        </span>
      </p>
    </>
  );
};
