import { Button, Divider, Flex, message, Modal, Space } from "antd";
import React, { useEffect, useState } from "react";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import { RedeemModal } from "./RedeemModal";

const PaymentDetails: any = ({onNumber, onName, onMethod}:any) => {
  

  const [payoutMethod, setPayoutMethod] = useState("");

  const [payoutNumber, setPayoutNumber] = useState("");

  const [payoutName, setPayoutName] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const savePaymentDetails: any = () => {

    setIsSaving(true);

    setTimeout(() => {

      console.log(":: PAYOUT ::", [payoutMethod, payoutNumber, payoutName]);

      setIsSaving(false);

      messageApi.info(
        `${payoutMethod} payout details saved!`
      );
      
    }, 3000)
    
  }

  useEffect(() => {

    onNumber(payoutNumber);

    onName(payoutName);

    onMethod(payoutMethod);

  }, [
    payoutNumber,
    payoutName,
    payoutMethod
  ]);

  return (
    <>
      {contextHolder}
      <div className="card mb-5">
        <div className="card-header border-0 cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Payment Details</h3>
          </div>
        </div>
        <div className="collapse show">
          <div id="kt_account_signin_method" className="collapse show">
            <div className="card-body border-top p-9">
              <div id="kt_signin_password_edit" className={"flex-row-fluid "}>
                <form
                  id="kt_signin_change_password"
                  className="form"
                  noValidate
                >
                  <div className="row mb-1">
                    <div className="col-md-12">
                      <div className="fv-row mb-0">
                        <strong>Payout Method</strong>
                        <br />
                        <select
                          value={payoutMethod}
                          onChange={(e) => {
                            setPayoutMethod(e.target.value);
                          }}
                          className="form-control form-control-lg form-control-solid "
                        >
                          <option>Ecocash</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="fv-row mb-0">
                        <strong>
                          {payoutMethod || "Ecocash"} Registered Number
                        </strong>
                        <input
                          value={payoutNumber}
                          placeholder="e.g. 0772123456 (no spaces)"
                          onChange={(e) => {
                            setPayoutNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control form-control-lg form-control-solid "
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="fv-row mb-0">
                        <strong>
                          {payoutMethod || "Ecocash"} Registered Name
                        </strong>
                        <input
                          value={payoutName}
                          placeholder="e.g. John Doe"
                          onChange={(e) => {
                            setPayoutName(e.target.value);
                          }}
                          type="text"
                          className="form-control form-control-lg form-control-solid "
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Button
                        type="default"
                        className="w-100"
                        block
                        size="large"
                        onClick={(e: any) => {
                          setPayoutName("");
                          setPayoutNumber("");
                        }}
                      >
                        Reset
                      </Button>
                    </div>
                    <div className="col-md-6">
                      <Button
                        type="primary"
                        className="w-100"
                        block
                        size="large"
                        loading={isSaving}
                        onClick={(e: any) => {
                          savePaymentDetails();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { PaymentDetails };
