import React, { FunctionComponent, useState } from "react";
import { format } from "timeago.js";
import ChatBubble from "./ChatBubble";
import { Col, Empty, Row } from "antd";
import Snippets from "../../../../helpers/Snippets";
import { useAppDataContext } from "../../../../data";

const Fade = require("react-reveal/Fade");

interface DocumentListItemProps {
  children?: React.ReactNode;
  dummyChatsIsLoading: boolean;
  chatHistory: any;
  userProfile: any;
  getInitialsFromName: any;
  onDisplayName?: any;
}

const ChatHistory: FunctionComponent<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  const {
    dummyChatsIsLoading,
    chatHistory,
    userProfile,
    getInitialsFromName,
    user,
    onDisplayName             
  }: any = props;

  const [themeColor, setThemeColor] = useState("");

  const finishedLoadingChats:any = (index:number, totalItems:number) => {

    const element:any = document.getElementById("scroll-chats");

    setTimeout(()=>{
      element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
    },500);
    
  }

  const { userData } = useAppDataContext();

  React.useEffect(() => {
    if (Snippets.user.getCurrentUserType() === "SELLER") {
      setThemeColor("ad1e23");
    } else {
      setThemeColor("552277");
    }
  }, [chatHistory]);

  return (
    <>
      {dummyChatsIsLoading ? (
        <span className="center-everything">
          <img src="/loading.gif" alt="" />
        </span>
      ) : (
        <>
          <br />

          <>
            {chatHistory && (
              <>
                {chatHistory.length === 0 ? (
                  <>
                    <div className="text-center mt-10">
                      <Empty
                        imageStyle={{ height: 100 }}
                        description={<span>No messages.</span>}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {chatHistory.map((chat: any, index: number) => (
                      <Fade bottom appear opposite delay={250*index/2} onReveal={ () => {finishedLoadingChats(index, chatHistory.length)} } >
 
                      <ChatBubble
                        key={index}
                        isOut={userData?.id === chat.senderId}
                        chat={chat}
                        getInitialsFromName={getInitialsFromName}
                        user={user}
                        onDisplayName={onDisplayName}
                      />
                      </Fade>
                    ))}
                  </>
                )}
              </>
            )}
          </>

          <br />
        </>
      )}
    </>
  );
};

export default ChatHistory;
