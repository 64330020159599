let uomsList = {
    KG: {singular:'KG',plural:'KG'},
    METER: {singular:'METER',plural:'METER'},
    MM: {singular:'MM',plural:'MM'},
    M: {singular:'M',plural:'M'},
    CM: {singular:'CM',plural:'CM'},
    KM: {singular:'KM',plural:'KM'},
    IN: {singular:'IN',plural:'IN'},
    FT: {singular:'FT',plural:'FT'},
    YD: {singular:'YD',plural:'YD'},
    PIECE: {singular:'PIECE',plural:'PIECES'},
    UNITS: {singular:'UNIT',plural:'UNITS'},
    HOUR: {singular:'HOUR',plural:'HOUR'},

    BAG: {singular:'BAG',plural:'BAG'},
    BARREL: {singular:'BARREL',plural:'BARREL'},
    BOX: {singular:'BOX',plural:'BOX'},
    CARTON: {singular:'CARTON',plural:'CARTON'},
    CONTAINER: {singular:'CONTAINER',plural:'CONTAINER'},
    DOZEN: {singular:'DOZEN',plural:'DOZEN'},
    GALLON: {singular:'GALLON',plural:'GALLON'},
    GRAM: {singular:'GRAM',plural:'GRAM'},
    LITER: {singular:'LITER',plural:'LITER'},
    M3: {singular:'M3',plural:'M3'},
    OUNCE: {singular:'OUNCE',plural:'OUNCE'},
    PAIR: {singular:'PAIR',plural:'PAIR'},
    PINT: {singular:'PINT',plural:'PINT'},
    POUND: {singular:'POUND',plural:'POUND'},
    QUART: {singular:'QUART',plural:'QUART'},
    SET: {singular:'SET',plural:'SET'},
    SHEET: {singular:'HOUR',plural:'HOUR'},
    TON: {singular:'TON',plural:'TON'},
    UNIT: {singular:'UNIT',plural:'UNIT'},
    YARD: {singular:'YARD',plural:'YARD'},
}
export {uomsList}