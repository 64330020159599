import React, {FC} from 'react' 
import { RewardZoneOnePager } from './RewardZoneOnePager';
 
const RewardZonePageWrapper: FC = () => {
  return (
    <>
      <RewardZoneOnePager />
    </>
  )
}

export default RewardZonePageWrapper;
