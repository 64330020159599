import { FunctionComponent, useState } from "react";
import { Profile } from "../account/profile-wizard/accounts/model/Profile";
import React from "react";
import { Button, Col, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import ProductSimple from "../shop/Products/Widgets/ProductSimple";

interface DocumentListItemProps {
    children?: React.ReactNode;
    profile: Profile;
    products: any[]
}

type LayoutType = Parameters<typeof Form>[0]['layout'];
const Products: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        profile,
        products,
    }: any = props;

    const onSetProductPreviewHandler = () =>{
        
    }
    return (
        <>
            <div className="row">
                {products.map((product: any) => (
                    <div className="product product-single col-md-3 col-sm-12  mb-3 product-single-pb-0">
                        <ProductSimple onSetProductPreview={onSetProductPreviewHandler} product={product} />
                    </div>
                ))}
            </div>
        </>
    )
}

export { Products };