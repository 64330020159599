import React, { useEffect, useState } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import CountUp from "react-countup";
import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Pagination,
  Progress,
  Row,
  Segmented,
  Skeleton,
  Statistic,
  Space,
  Flex,
  Modal,
  message,
  Alert,
  Popover,
  Switch,
} from "antd";
import type { PaginationProps } from "antd";

import AxiosAPI from "../../../../helpers/AxiosAPI";

import { useAppDataContext } from "../../../../data/context/AppDataContext";

import Meta from "antd/es/card/Meta";
import { RewardZoneImageLoader } from "./RewardZoneImageLoader";
import Snippets from "../../../../helpers/Snippets";
import { HTML5_FMT } from "moment";
import EarningPointsCard from "./EarningPointsCard";
import TXNCard from "./TXNCard";
import {
  CodeSandboxOutlined,
  CopyOutlined,
  GlobalOutlined,
  ShareAltOutlined,
  UserOutlined,
} from "@ant-design/icons";

///// Start Imports

import { useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import type { GetRef, TableColumnsType, TableColumnType } from "antd";
import { Input, Table } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import {
  EventTypesButtons,
  EventTypesButtonsUrls,
  EventTypesButtonsInfo,
  EventTypesLabels,
} from "../../../../helpers/UserPilotHelper";
 

type InputRef = GetRef<typeof Input>;

interface DataType {
  key: string;
  date: string;
  ref: string;
  description: string;
  credit: string;
  debit: string;
  balance: string;
}

type DataIndex = keyof DataType;

//// End Imports

const RewardZoneOnePager: React.FC = () => {
  const formatter = (value: number) => <CountUp end={value} separator="," />;

  const [includeEveryTransaction, setIncludeEveryTransaction] = useState(false);

  const { userType } = useAppDataContext();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const openFilters: any = () => {
    setIsFiltersOpen(true);
  };

  const [transactionsData, setTransactionData] = useState<DataType[]>([]);

  const [rewardsProduct, setRewardsProduct] = useState([
    {
      thumbnail: "/assets/loyalty/1.png",
      name: "Electrical Jug 2Ltrs",
      points: "5,000",
      price: "50.00",
    },
    {
      thumbnail: "/assets/loyalty/2.png",
      name: "Bluetooth Speaker",
      points: "6,500",
      price: "65.00",
    },
    {
      thumbnail: "/assets/loyalty/3.png",
      name: "Rechargable LED Torch",
      points: "3,500",
      price: "35.00",
    },
    {
      thumbnail: "/assets/loyalty/4.png",
      name: "LP 18.5KG Gas Tank",
      points: "75,000",
      price: "75.00",
    },
  ]);

  ////// Start Table

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "15%",
      ...getColumnSearchProps("date"),
      fixed: "left",
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Reference",
      dataIndex: "ref",
      key: "ref",
      width: "15%",
      ...getColumnSearchProps("ref"),
      sorter: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
      sorter: (a, b) => a.description.length - b.description.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      width: "15%",
      ...getColumnSearchProps("debit"),
      sorter: (a, b) => a.debit.length - b.debit.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      width: "15%",
      ...getColumnSearchProps("credit"),
      sorter: (a, b) => a.credit.length - b.credit.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: "15%",
      ...getColumnSearchProps("balance"),
      sorter: (a, b) => a.balance.length - b.balance.length,
      sortDirections: ["descend", "ascend"],
    },
  ];

  ////// End Table

  const [rewardsTransactions, setRewardsTransactions] = useState([]);
  const [rewardsSummary, setRewardsSummary] = useState({
    levelName: "---",
    totalPoints: 0,
  });
  const [rewardsLevels, setRewardsLevels] = useState([]);
  const [rewardsEventTypes, setRewardsEventTypes] = useState([]);
  const [rewardsItems, setRewardsItems] = useState([]);

  const [nextLevel, setNextLevel] = useState("0 pts to Level 0");

  const formatDate: any = (dateString: string) => {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the day, month, year, and time from the Date object
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the date string
    const formattedDate = `${day}-${month}-${year}, ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;

    return formattedDate;
  };

  interface RewardsLevel {
    createdAt: string;
    id: number;
    name: string;
    points: number;
    updatedAt: string;
  }

  const [pointsNext, setPointsNext] = useState(1);

  function getPointsRemainingToNextLevel(userSummary: any, levels: any): void {
    const currentLevelName: string = userSummary.levelName;

    if (currentLevelName === "---") {
      return;
    }

    const currentLevelPoints: number = userSummary.totalPoints;
    const currentLevelNumber: number = parseInt(
      currentLevelName.replace("Level ", "")
    );

    console.log(
      "::: CURRENT LEVEL :::",
      userSummary,
      currentLevelName,
      currentLevelPoints,
      currentLevelNumber
    );

    const nextLevelName: string = `Level ${currentLevelNumber + 1}`;
    const nextLevelRewards: RewardsLevel = getRewardsLevelById(
      nextLevelName,
      levels
    );

    console.log("::: NEXT LEVEL :::", nextLevelName, nextLevelRewards);

    const pointsToNextLevel = nextLevelRewards.points - currentLevelPoints;

    setPointsNext(pointsToNextLevel);

    //const _nextLevel: string = `${pointsToNextLevel} points remaining to ${nextLevelName}`;

    const _nextLevel: string = `You can earn up to ${nextLevelRewards.points} points on ${currentLevelName}`;

    console.log("::: `POINTS TO NEXT LEVEL :::", _nextLevel);

    setNextLevel(_nextLevel);
  }

  function getRewardsLevelById(
    levelName: string,
    _rewardsLevels: any
  ): RewardsLevel | undefined {
    return _rewardsLevels.find((level: any) => level.name === levelName);
  }

  const getTransactions: any = () => {
    AxiosAPI.getRewardsTransactions()
      .then((res: any) => {
        let runningBalance = 0;

        let _rewardsTransactions: any = res.data;

        if (!includeEveryTransaction) {
          _rewardsTransactions = _rewardsTransactions.filter(
            (item: any) => item.description !== "LOGIN"
          );
        }

        if (!includeEveryTransaction) {
          _rewardsTransactions = _rewardsTransactions.filter(
            (item: any) => item.description !== "SIGN_UP"
          );
        }

        if (!includeEveryTransaction) {
          _rewardsTransactions = _rewardsTransactions.filter(
            (item: any) => item.description !== "VIEW_PRODUCT"
          );
        }

        if (!includeEveryTransaction) {
          _rewardsTransactions = _rewardsTransactions.filter(
            (item: any) => item.description !== "COMPLETE_PROFILE"
          );
        }

        if (!includeEveryTransaction) {
          _rewardsTransactions = _rewardsTransactions.filter(
            (item: any) => item.description !== "SEARCH_PRODUCT"
          );
        }

        console.log(
          "::: _rewardsTransactions :::",
          includeEveryTransaction,
          _rewardsTransactions
        );

        const updatedTransactions = _rewardsTransactions.map(
          (txn: any, txnIndex: number) => {
            let _txnDescription: any = txn.description;

            let _ref: string = "";

            console.log("::: updatedTransactions :::", _txnDescription, txn);

            if (
              [
                "SEND_AN_RFQ",
                "INQUIRE_PRODUCT",
                "INVITE_SUPPLIER",
                "GENERATE_PO",
              ].includes(_txnDescription)
            ) {
              runningBalance += txn.credit - txn.debit;
            }

            if (_txnDescription === "LOGIN") {
              _txnDescription = <>Session login</>;
            }

            if (_txnDescription === "SIGN_UP") {
              _txnDescription = <>Created an SZ User Account</>;
            }

            if (_txnDescription === "VIEW_PRODUCT") {
              let _product: any = JSON.parse(txn.meta);

              const content = (
                <div>
                  <Flex>
                    <img
                      alt=""
                      title=""
                      src={`https://api-live.supplyzone.ai/home/product/${_product.product_id}/image`}
                      width={96}
                      className="mr-3"
                    />
                    <div>
                      <p>{_product.product_name}</p>
                      <p>{_product.product_category}</p>
                      <p>{_product.product_description}</p>
                      <p>Category: {_product.product_category}</p>
                      <p>Model: {_product.product_model}</p>
                      <p>
                        <a
                          target="_blank"
                          href={`/product/view/item/${_product.product_id}`}
                        >
                          View product details
                        </a>
                      </p>
                    </div>
                  </Flex>
                </div>
              );
              _txnDescription = (
                <>
                  Viewed a{" "}
                  <u>
                    <b>
                      <Popover
                        content={content}
                        title={"Product"}
                        trigger="hover"
                      >
                        <a href={`#`}>Product</a>
                      </Popover>
                    </b>
                  </u>
                </>
              );
            }

            if (_txnDescription === "COMPLETE_PROFILE") {
              _txnDescription = <>Profile Completion</>;
            }

            if (_txnDescription == "SEARCH_PRODUCT") {
              _txnDescription = <>Product Search</>;
            }

            if (_txnDescription === "INVITE_SUPPLIER") {
              _txnDescription = <>Accepted Supplier </>;
              console.log(
                "::: updatedTransactions ::: INQUIRE_PRODUCT",
                txn.meta
              );

              if ("meta" in txn && txn.meta !== undefined) {
                let _meta = JSON.parse(txn.meta);
                if ("lineItems" in _meta) {
                  _txnDescription = (
                    <div className="one-liner-text">
                      Supplier {_meta.lineItems[0].productName}  accepted the invitation
                    </div>
                  );
                  _ref = "INQ# " + Snippets.string.inqNumber(_meta);
                }
              }
            }

            if (_txnDescription === "SEND_AN_RFQ") {

              console.log(
                "::: updatedTransactions ::: SEND_AN_RFQ",
                txn.meta,
              );

              if ("meta" in txn && txn.meta !== undefined) {
                let _meta = JSON.parse(txn.meta);
                if ("productName" in _meta) {
                  _txnDescription = <div className="one-liner-text">Create RFQ: {_meta.productName}</div>;
                  _ref = "RFQ# " + Snippets.string.rfqNumber(_meta);
                }
              }
              
            }

            /*

            */

            if (_txnDescription == "INQUIRE_PRODUCT") {
              console.log(
                "::: updatedTransactions ::: INQUIRE_PRODUCT",
                txn.meta
              );

              if ("meta" in txn && txn.meta !== undefined) {
                let _meta = JSON.parse(txn.meta);
                if ("lineItems" in _meta) {
                  _txnDescription = (
                    <div className="one-liner-text">Send Inquiry: {_meta.lineItems[0].productName}</div>
                  );
                  _ref = "INQ# " + Snippets.string.inqNumber(_meta);
                }
              }
            }

            return {
              key: txnIndex,
              date: formatDate(txn.date),
              ref: _ref || "SZ" + txn.reference.toString().padStart(5, "0"),
              description: _txnDescription,
              credit: txn.credit,
              debit: txn.debit,
              balance: runningBalance,
            };
          }
        );

        setTransactionData(updatedTransactions.reverse());
      })
      .catch((error: any) => {
        // Handle error
      });
  };

  useEffect(() => {
    getTransactions();

    AxiosAPI.getRewardsLevels()
      .then((res: any) => {
        let _rewardsLevels: any = res.data;

        console.log("REWARDS:::rewardsLevels", _rewardsLevels);

        setRewardsLevels(_rewardsLevels);

        AxiosAPI.getRewardsSummary()
          .then((res: any) => {
            let _rewardsSummary: any = res.data;

            console.log("REWARDS:::rewardsSummary", _rewardsSummary);

            setRewardsSummary(_rewardsSummary);

            getPointsRemainingToNextLevel(_rewardsSummary, _rewardsLevels);
          })
          .catch((error: any) => {});
      })
      .catch((error: any) => {});

    AxiosAPI.getRewardsEventTypes()
      .then((res: any) => {
        let _rewardsEventTypes: any = res.data;

        console.log("REWARDS:::rewardsEventTypes", _rewardsEventTypes);

        setRewardsEventTypes(_rewardsEventTypes);
      })
      .catch((error: any) => {});

    AxiosAPI.getRewardsItems()
      .then((res: any) => {
        let _rewardsItems: any = res.data;

        console.log("REWARDS:::rewardsItems", _rewardsItems);

        setRewardsItems(_rewardsItems);
      })
      .catch((error: any) => {});
  }, []);

  useEffect(() => {
    getTransactions();
  }, [includeEveryTransaction]);

  const buttonLabel: any = (item: any) => {
    let _str: String = "";

    switch (item.slug) {
      case "COMPLETE_PROFILE": {
        _str = EventTypesButtons.COMPLETE_PROFILE;
        break;
      }

      case "INQUIRE_PRODUCT": {
        _str = EventTypesButtons.INQUIRE_PRODUCT;
        break;
      }

      case "SHARE_ITEMS": {
        _str = EventTypesButtons.SHARE_ITEMS;
        break;
      }

      case "SEND_AN_RFQ": {
        _str = EventTypesButtons.SEND_AN_RFQ;
        break;
      }

      case "VIEW_PRODUCT": {
        _str = EventTypesButtons.VIEW_PRODUCT;
        break;
      }

      case "LOGIN": {
        _str = EventTypesButtons.LOGIN;
        break;
      }

      case "INVITE_SUPPLIER": {
        _str = EventTypesButtons.INVITE_SUPPLIER;
        break;
      }

      case "GENERATE_PO": {
        _str = EventTypesButtons.GENERATE_PO;
        break;
      }

      default: {
        break;
      }
    }

    return _str;
  };

  const buttonUrl: any = (item: any) => {
    let _str: String = "";

    switch (item.slug) {
      case "COMPLETE_PROFILE": {
        _str = EventTypesButtonsUrls.COMPLETE_PROFILE;
        break;
      }

      case "INQUIRE_PRODUCT": {
        _str = EventTypesButtonsUrls.INQUIRE_PRODUCT;
        break;
      }

      case "SHARE_ITEMS": {
        _str = EventTypesButtonsUrls.SHARE_ITEMS;
        break;
      }

      case "SEND_AN_RFQ": {
        _str = EventTypesButtonsUrls.SEND_AN_RFQ;
        break;
      }

      case "VIEW_PRODUCT": {
        _str = EventTypesButtonsUrls.VIEW_PRODUCT;
        break;
      }

      case "LOGIN": {
        _str = EventTypesButtonsUrls.LOGIN;
        break;
      }

      case "GENERATE_PO": {
        _str = EventTypesButtonsUrls.GENERATE_PO;
        break;
      }

      default: {
        break;
      }
    }

    return _str;
  };

  const cardLabel: any = (item: any) => {
    let _str: String = "";

    switch (item.slug) {
      case "COMPLETE_PROFILE": {
        _str = EventTypesLabels.COMPLETE_PROFILE;
        break;
      }

      case "INQUIRE_PRODUCT": {
        _str = EventTypesLabels.INQUIRE_PRODUCT;
        break;
      }

      case "SHARE_ITEMS": {
        _str = EventTypesLabels.SHARE_ITEMS;
        break;
      }

      case "SEND_AN_RFQ": {
        _str = EventTypesLabels.SEND_AN_RFQ;
        break;
      }

      case "VIEW_PRODUCT": {
        _str = EventTypesLabels.VIEW_PRODUCT;
        break;
      }

      case "LOGIN": {
        _str = EventTypesLabels.LOGIN;
        break;
      }

      case "GENERATE_PO": {
        _str = EventTypesLabels.GENERATE_PO;
        break;
      }

      default: {
        break;
      }
    }

    return _str;
  };

  const buttonInfo: any = (item: any) => {
    let _str: String = "";

    switch (item.slug) {
      case "COMPLETE_PROFILE": {
        _str = EventTypesButtonsInfo.COMPLETE_PROFILE;
        break;
      }

      case "INQUIRE_PRODUCT": {
        _str = EventTypesButtonsInfo.INQUIRE_PRODUCT;
        break;
      }

      case "SHARE_ITEMS": {
        _str = EventTypesButtonsInfo.SHARE_ITEMS;
        break;
      }

      case "SEND_AN_RFQ": {
        _str = EventTypesButtonsInfo.SEND_AN_RFQ;
        break;
      }

      case "VIEW_PRODUCT": {
        _str = EventTypesButtonsInfo.VIEW_PRODUCT;
        break;
      }

      case "LOGIN": {
        _str = EventTypesButtonsInfo.LOGIN;
        break;
      }

      case "INVITE_SUPPLIER": {
        _str = EventTypesButtonsInfo.INVITE_SUPPLIER;
        break;
      }

      case "GENERATE_PO": {
        _str = EventTypesButtonsInfo.GENERATE_PO;
        break;
      }

      default: {
        break;
      }
    }

    return _str;
  };

  const [reedemRewardModalOpen, setRedeemRewardModalOpen] = useState(false);

  const [reedemBusy, setRedeemBusy] = useState(false);

  const [reedemItem, setRedeemItem] = useState({});

  const [reedemItemStep, setRedeemItemStep] = useState(1);

  const [modalOpenInsufficientFunds, setModalOpenInsufficientFunds] =
    useState(false);

  const [img1, setIMG1] = useState(false);

  const [img2, setIMG2] = useState(false);

  const [img3, setIMG3] = useState(false);

  const redeemReward: any = (item: any) => {
    setRedeemBusy(false);
    setRedeemItem(item);

    setTimeout(() => {
      setRedeemItemStep(1);
      setRedeemRewardModalOpen(true);
    }, 1000);

    setTimeout(() => {
      setIMG1(true);
      setIMG2(false);
      setIMG3(false);
    }, 2000);
  };

  const stepRedeem: any = () => {
    setRedeemBusy(true);
    setTimeout(() => {
      if (rewardsSummary.totalPoints < reedemItem.points) {
        setRedeemRewardModalOpen(false);
        setModalOpenInsufficientFunds(true);
        setRedeemBusy(false);
        return;
      }
      setRedeemBusy(false);
      setRedeemItemStep(2);
      setRedeemRewardModalOpen(true);

      setTimeout(() => {
        setIMG1(false);
        setIMG2(true);
        setIMG3(false);
      }, 2000);
    }, 1000);
  };

  const [messageApi, contextHolder] = message.useMessage();

  const stepConfirm: any = () => {
    setRedeemBusy(true);
    let _email = Snippets.user.getCurrentUserEmail();

    console.log(
      "REDEEM REWARDS:::reedemItem",
      _email,
      reedemItem.id,
      reedemItem
    );

    AxiosAPI.redeemRewards(_email, reedemItem.id)
      .then((res: any) => {
        let _redeemResult: any = res.data;

        let _crr = rewardsSummary.totalPoints;

        console.log("REDEEM REWARDS:::results", _redeemResult);

        setRedeemBusy(false);

        setRedeemItemStep(3);

        setRedeemRewardModalOpen(true);

        setMerchantCode("");
      })
      .catch((error: any) => {
        setRedeemBusy(false);
        setModalOpenInsufficientFunds(true);
        setRedeemRewardModalOpen(false);
        setMerchantCode("");
        console.log("REDEEM REWARDS:::ERROR", error);

        setTimeout(() => {
          messageApi.open({
            type: "error",
            content: error.response.data.message,
          });
        }, 1000);
      });

    setTimeout(() => {
      setIMG1(false);
      setIMG2(false);
      setIMG3(true);
    }, 2000);
  };

  const stepDismiss: any = () => {
    setRedeemBusy(true);
    setIMG1(false);
    setIMG2(false);
    setIMG3(false);
    setTimeout(() => {
      setRedeemBusy(false);

      setRedeemItemStep(4);

      setRedeemRewardModalOpen(false);

      setTimeout(() => {
        window.location.reload();
      }, 100);
    }, 100);
  };

  const [merchantCode, setMerchantCode] = useState("");

  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text: any) => {
    console.log("onChange::GetProp", text);
    setMerchantCode(text);
  };

  const { Search } = Input;

  const sharedProps = {
    onChange,
  };

  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);


  return (
    <>
      {contextHolder}
      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1">
              <h2 className="title ls-normal mb-0">My Rewards Zone</h2>
            </div>
          </div>
          <Row className="mt-5">
            <Col span={24} className="xcards">
              <div
                style={{
                  backgroundImage: "url(/assets/bg/rewards-bg.png)",
                  minHeight: 400,
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <Space direction="vertical" size={20} className="w-100">
                  <div className="points-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      version="1.1"
                      viewBox="0 0 100 100"
                      xmlSpace="preserve"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        r="48"
                        fill="none"
                        stroke="#1d5c06"
                        strokeDasharray="360"
                        strokeLinecap="round"
                        strokeWidth="4"
                        transform="rotate(-90 50 50)"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          dur="5s"
                          repeatCount="1"
                          values="360;0"
                        ></animate>
                      </circle>
                    </svg>

                    <Statistic
                      title="Wallet Points"
                      value={rewardsSummary.totalPoints}
                      formatter={formatter}
                    />
                  </div>

                  <div className="level-container">
                    <Progress
                      percent={
                        (Number(rewardsSummary.totalPoints) /
                          (Number(pointsNext) +
                            Number(rewardsSummary.totalPoints))) *
                        100
                      }
                      size={[520, 24]}
                      status="normal"
                      showInfo={false}
                    />
                    <div className="level-disc">
                      <span>
                        <strong>Level</strong>
                      </span>
                      <h5>
                        <strong>
                          {rewardsSummary.levelName.replace("Level ", "")}
                        </strong>
                      </h5>
                    </div>

                    <h5 className="points-left">
                      <strong><small>{nextLevel}</small></strong>
                    </h5>
                  </div>
                </Space>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col span={24} className="xcard card-with-title">
              <div className="title-link-wrapper mb-3">
                <h2 className="title mb-0 pt-2 pb-2">Ways To Earn Points</h2>
              </div>

              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                className="pr-2 pl-2"
              >
                {rewardsEventTypes.map((item: any, itemIndex: number) => (
                  <>
                    {item.slug !== "SHARE_ITEMS" && (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        key={itemIndex}
                      >
                        <EarningPointsCard
                          title={item.name}
                          description={item.description}
                          points={`${item.points} pts`}
                          cardLabel={cardLabel(item)}
                          btnLabel={buttonLabel(item)}
                          btnUrl={buttonUrl(item)}
                          btnInfo={buttonInfo(item)}
                        />
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col span={24} className="xcard card-with-title">
              <div className="title-link-wrapper mb-3">
                <h2 className="title mb-0 pt-2 pb-2">Redeem Rewards</h2>
              </div>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {rewardsItems
                  .filter((item) => item.name !== "")
                  .map((item: any, itemIndex: number) => (
                    <Col key={itemIndex} xs={24} sm={12} md={6} lg={6} xl={6}>
                      <div
                        className="xcards p-4 pr-3 pl-3 text-center"
                        onClick={() => redeemReward(item)}
                      >
                        <div className="image-container">
                          <img
                            alt=""
                            title=""
                            className=""
                            src={`https://api-live.supplyzone.ai/home/product-attachment/${item.s3Key}`}
                            style={{
                              width: "100%",
                              margin: "auto",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>

                        <h4 className="mt-2 mb-2 one-line-text">
                          <a href="">
                            <strong>{item.name}</strong>
                          </a>
                        </h4>

                        <h4 className="mb-2">
                          {Number(item.points).toLocaleString()} point
                          {item.points > 1 && <>s</>}
                        </h4>
                        <h6 className="text-muted muted-text text-600 text-gray text-grey">
                          {/* USD {Number(item.price).toLocaleString()} */}
                        </h6>
                        <Button className="mt-3" type="default">
                          Redeem Now
                        </Button>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col span={24} className="xcard card-with-title">
              <div className="title-link-wrapper mb-3">
                <h2 className="title mb-0 pt-2 pb-2 w-100">
                  <Flex
                    className="w-100"
                    justify="space-between"
                    align="center"
                  >
                    <div>Transaction History</div>
                    <div>
                      {/*
                      <Switch
                        defaultChecked={false}
                        value={includeEveryTransaction}
                        onChange={setIncludeEveryTransaction}
                        size="small"
                      />{" "}
                      Include all activities 
                      */}
                      <Search
                        placeholder="input search text"
                        allowClear
                        enterButton
                        onSearch={onSearch}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Flex>
                </h2>
              </div>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Table
                    columns={columns}
                    dataSource={transactionsData}
                    scroll={{ x: 1300 }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </main>
      <Modal
        title={`Redeem Reward`}
        centered
        width={580}
        open={reedemRewardModalOpen}
        onOk={() => {
          setRedeemRewardModalOpen(false);
        }}
        onCancel={() => setRedeemRewardModalOpen(false)}
        footer={<></>}
      >
        {reedemItemStep === 1 && (
          <Row gap={16}>
            <Col span={12}>
              <RewardZoneImageLoader className="" s3Key={reedemItem.s3Key} />
            </Col>
            <Col span={12}>
              <Space size={18} direction="vertical">
                <h4>{reedemItem.name}</h4>
                <h2>
                  {Number(reedemItem.points).toLocaleString()} Point
                  {reedemItem.points > 1 && <>s</>}
                </h2>
                {/* <h5 style={{ color: "#999999" }}>USD ${reedemItem.price}</h5> */}
                <p>
                  Current points balance:{" "}
                  <strong>
                    {Number(rewardsSummary.totalPoints).toLocaleString()}
                  </strong>
                </p>
                <Flex gap={5} style={{ width: "100%" }}>
                  <Button
                    type="primary"
                    block
                    loading={reedemBusy}
                    onClick={() => stepRedeem()}
                  >
                    Redeem
                  </Button>
                  <Button
                    type="default"
                    block
                    onClick={() => setRedeemRewardModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Space>
            </Col>
          </Row>
        )}

        {reedemItemStep === 2 && (
          <Row gap={16}>
            <Col span={10}>
              <Card className="mr-5 text-center">
                <Space direction="vertical" size={5}>
                  <RewardZoneImageLoader
                    className="smaller"
                    s3Key={reedemItem.s3Key}
                  />
                  <h5 className="mt-0 mb-0 ">
                    <a href="">
                      <strong>{reedemItem.name}</strong>
                    </a>
                  </h5>
                  <h5 className="">
                    {Number(reedemItem.points).toLocaleString()} point
                    {reedemItem.points > 1 && <>s</>}
                  </h5>
                  <h6 className="text-muted muted-text text-600 text-gray text-grey">
                    {/* USD {Number(reedemItem.price).toLocaleString()} */}
                  </h6>
                </Space>
              </Card>
            </Col>
            <Col span={14}>
              <Space size={18} direction="vertical">
                <h4>Input Merchant Code</h4>
                <h2>
                  <Input.OTP
                    formatter={(str) => str.toUpperCase()}
                    {...sharedProps}
                  />
                </h2>
                <h5>
                  {merchantCode.length === 6
                    ? "TV Sales & Home"
                    : "Waiting for merchant..."}
                </h5>
                <p>
                  Current points balance:{" "}
                  <strong>{rewardsSummary.totalPoints}</strong>
                </p>
                <Flex gap={5} style={{ width: "100%" }}>
                  <Button
                    type="primary"
                    block
                    loading={reedemBusy}
                    onClick={() => stepConfirm()}
                    disabled={merchantCode.length < 6}
                  >
                    Confirm
                  </Button>
                  <Button
                    type="default"
                    block
                    onClick={() => setRedeemRewardModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Space>
            </Col>
          </Row>
        )}

        {reedemItemStep === 3 && (
          <Row gap={16}>
            <Col span={10}>
              <Card className="mr-5 text-center">
                <Space direction="vertical" size={5}>
                  <RewardZoneImageLoader
                    className="smaller"
                    s3Key={reedemItem.s3Key}
                  />
                  <h5 className="mt-0 mb-0 ">
                    <a href="">
                      <strong>{reedemItem.name}</strong>
                    </a>
                  </h5>
                  <h5 className="">
                    {Number(reedemItem.points).toLocaleString()} point
                    {reedemItem.points > 1 && <>s</>}
                  </h5>
                  <h6 className="text-muted muted-text text-600 text-gray text-grey">
                    {/* {Number(reedemItem.price).toLocaleString()} */}
                  </h6>
                </Space>
              </Card>
            </Col>
            <Col span={14}>
              <Space size={18} direction="vertical">
                <Alert
                  message="Transaction completed!"
                  description={
                    <>
                      <h4 className="mb-3">
                        {reedemItem.points} point
                        {reedemItem.points > 1 && <>s</>} deducted, new points
                        balance:{" "}
                        <strong>
                          {rewardsSummary.totalPoints - reedemItem.points}
                        </strong>
                      </h4>
                      <p>Reference: SZ00023</p>
                    </>
                  }
                  type="success"
                  showIcon
                />

                <Flex gap={5} style={{ width: "100%" }}>
                  <Button
                    type="primary"
                    block
                    loading={reedemBusy}
                    onClick={() => stepDismiss()}
                  >
                    Dismiss
                  </Button>
                  <Button
                    type="default"
                    block
                    onClick={() => setRedeemRewardModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Space>
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        title="Insufficient Points"
        centered
        open={modalOpenInsufficientFunds}
        onOk={() => {
          setModalOpenInsufficientFunds(false);
          setRedeemRewardModalOpen(false);
        }}
        onCancel={() => {
          setModalOpenInsufficientFunds(false);
          setRedeemRewardModalOpen(false);
        }}
      >
        <p>
          To redeem {reedemItem.name} you must have {reedemItem.points} points
          and above.
          <br /> Your current points balance is {rewardsSummary.totalPoints}
        </p>
      </Modal>
    </>
  );
};
export { RewardZoneOnePager };
