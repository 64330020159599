import * as React from "react";

export default () => {
  return (
    <>
      <p>
        <b>Introduction&nbsp;</b>
      </p>

      <p>
        <span>
          This Cookie Policy describes the different types of cookies and
          related technologies (collectively, &quot;Cookies&quot;) which may be
          used on websites and apps, where Supply Zone acts as data controller.
        </span>
      </p>

      <p>
        <span>We use &quot;Cookies&quot; for the following reasons:</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To make the website function properly for you and to enable you to
          make use of the services we provide
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To remember your preferences and make the site easier for you to use
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To collect information about how you use our website, which is then
          anonymized and used to help us improve our services - cookies for this
          purpose are often described as analytical cookies
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To collect information about your subjects of interest and use this to
          serve content on our sites and on other websites.
        </span>
      </p>

      <p>
        <span>
          By means of this Cookie Policy we are seeking to give you clear and
          comprehensive information about the &quot;Cookies&quot; we use,
          including how to reject or block them. Some are essential for the
          operation of our websites, others are not. Opting out or blocking some
          cookies may affect the functionality of these sites. We want to be
          open about the way we use cookies and to help you make informed
          choices about their use.
        </span>
      </p>

      <p>
        <span>
          As we change the features on our websites, we will review this policy
          and update it to include details of any new cookies applied. Please
          refer to the &quot;Last Updated&quot; date at the top of this page to
          see when this Policy was last revised.
        </span>
      </p>

      <p>
        <span>
          Please note that we process your data using servers and storage
          facilities located in Canada, and to the extent that information
          collected through Cookies and similar technologies constitutes
          personal information, the provisions in our Privacy Policy apply and
          complement this Cookie Policy.
        </span>
      </p>

      <p>
        <span>Contact Details&nbsp;</span>
      </p>

      <p>
        <span>
          If you have any questions, concerns or comments, or if you require any
          help on cookie-related matters, please contact us by:
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Email at{" "}
          <a href="mailto:support@inboxgroup.ai">
            <span>Data Protection Officer</span>
          </a>
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>What are &quot;Cookies&quot;?&nbsp;</span>
      </p>

      <p>
        <b>
          <span>Browser Cookies (aka HTTP Cookies)</span>
        </b>
      </p>

      <p>
        <span>
          Browser Cookies are small text files that hold a certain amount of
          data, which our site can send to your browser to be stored on your
          device (e.g., your computer, tablet or smartphone). These can be
          accessed by our web server and retrieved to allow us to customize our
          web pages and services for you. We want our site to be informative,
          personal, and to provide you with a good experience when you use the
          service, and cookies help us improve the service to achieve that goal.
        </span>
      </p>

      <p>
        <span>There are several types of browser cookies:</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          First-Party: These cookies are created by the website the user is
          visiting. By default, first-party cookies are allowed in every web
          browser. If you were to disable first-party cookies, a website may not
          keep track of your activity as you move from page to page. For
          example, you would be unable to purchase multiple items online in the
          same transaction. Each time you added something to the cart from
          another page on the site, it would be treated as a new order, etc.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Third-Party: Some cookies on the website and other parts of the
          service are managed by third parties. They may be used to support
          analytical, advertising or social media functions provided by these
          third parties to site users and or Supply Zone. You should refer to
          the third parties' own cookie and privacy policies for information
          about how they may use your information.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Session Cookies: These types of cookies are stored only temporarily
          during a browsing session and are deleted from your device when you
          close the browser (although there may be a small delay in some
          instances before expiration, such as mobile browsing). These may be
          first or third-party cookies. We use Session Cookies to support the
          functionality of the Site and to understand your use of the Site.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Persistent Cookies: These types of cookies are not deleted when you
          close your browser but are saved on your device for a fixed period of
          time, depending on the lifetime of the specific cookie or until you
          delete them. These may be first or third-party cookies. Each time you
          visit the Site, the server that sets the cookie will recognize the
          persistent cookie saved on your device. We and others use persistent
          Cookies to store your preferences, so that they are available for your
          next visit, to keep a more accurate account of how often you visit the
          Site, how often you return, how your use of the Site may vary over
          time and the effectiveness of advertising efforts.
        </span>
      </p>

      <p>
        <span>Web Beacons&nbsp;</span>
      </p>

      <p>
        <span>
          Web beacons (also called clear GIFs or pixel tags) are tiny graphic
          images or lines of code placed in Online Services or in emails that
          include a unique identifier to allow us (and/or third parties) to
          determine unique user visits and whether you have performed a specific
          action. When you access these pages or open or click an email, these
          technologies generate a notice of that action. These tools are often
          used in conjunction with HTTP cookies and are generally used to
          measure responses to communications and improve Online Services and
          promotions.
        </span>
      </p>

      <p>
        <span>Embedded Scripts&nbsp;</span>
      </p>

      <p>
        <span>
          An embedded script is programming code that is designed to collect
          information about your interactions with Online Services, such as
          links clicked. The code is temporarily downloaded to your device from
          Supply Zone or a third-party service provider and is active only when
          you are connected to the Online Service.
        </span>
      </p>

      <p>
        <span>Local Shared Objects (LSO) &amp; Local Storage&nbsp;</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Flash Cookies (aka Local Share Objects): We may also use &quot;Flash
          Cookies&quot; on our sites for some of our animated content. They are
          used in the same way as other cookies ' to store information you
          provide such as settings, preferences, and usage. These provide
          additional features to improve your experience; however, they use a
          different technology than browser cookies. Removing or rejecting
          browser cookies via a browser setting does not necessarily affect
          Flash Cookies. See&nbsp;
          <a href="https://www.supplyzone.ai/STM/help-faq/en/cookie-policy/#ManagingCookies">
            <span>here</span>
          </a>
          &nbsp;to remove or disable a Flash Cookie.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Local Storage (e.g., device or HTML5 local storage and caching): HTML5
          uses the local storage of the device that accesses the Online
          Services. The Online Services may record information in local storage
          and read it when it is needed.
        </span>
      </p>

      <p>
        <span>Our Use of Cookies&nbsp;</span>
      </p>

      <p>
        <span>
          We use cookies on our websites and mobile apps for a variety of
          reasons as indicated below.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Strictly Necessary Cookies: These cookies are required for the
          operation of our websites. These are essential to enable you to move
          around and use our sites' features. Without these cookies, services
          such as registering for an account, cannot be provided. We use them to
          remember you as having logged in, enabling you to use inquiry and
          shopping basket as well as other functions such as sending/receiving
          Requests for Information.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Performance Cookies: These cookies collect information about how
          visitors use a website. The information these cookies collect is
          aggregated for anonymous reporting. They allow us to recognize and
          count the number of visitors and to see how visitors move around our
          website when they are using it, which pages visitors go to most often,
          etc. We use this information to monitor site performance, count page
          visits, spot technical errors, see how users reach the Site, measure
          the effectiveness of advertising (including emails we send to you) in
          order to improve our websites and to aid us in investigating problems
          raised by visitors.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Functionality Cookies: These cookies are used to recognize you when
          you return to our website and allow them to remember choices you made
          in order to provide more personal features and personalized content to
          improve your experience. For instance, a functional cookie can be used
          to remember settings such as your language, display or content
          preferences. Some Functional Cookies are essential for viewing maps or
          videos on our site. Please note that we use functionality cookies to
          track user visit data anonymously but can link them to personal data
          if a visitor has provided it to us through a registration profile in
          order to communicate personalized content such as new product
          recommendations or articles. Disabling Functional Cookies may make
          specific functions not work as intended and may impair usability of
          the system or service. If you wish to opt-out from receiving
          personalized content please&nbsp;
          <a href="mailto:support@inboxgroup.ai">
            <span>Contact Us</span>
          </a>
          .
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Targeting or Advertising Cookies: Based on your interests and visit
          patterns, these cookies allow us and other advertisers to deliver the
          most relevant products, offers, and advertisements on our site and
          third-party sites, e.g., on search engines, social media sites, or
          through email and other message platforms. For example, some
          Advertising Cookies help our service providers and other advertisers
          to select advertisements that are based on your interests, including
          those expressed or inferred by visits to our websites or apps or
          across other websites, online services, and apps over time. Others
          help prevent the same advertisement from continuously reappearing for
          you. Advertising Cookies also include Social Plug-In Cookies, which
          are used to share content from the Site with members and non-members
          of social media networks such as Facebook, Twitter, YouTube and
          Pinterest. These cookies are usually set by the social networking
          provider, enabling such sharing to be smooth and seamless.
        </span>
      </p>

      <p>
        <a></a>
        <span>Managing Cookies&nbsp;</span>
      </p>

      <p>
        <span>
          We appreciate that some users may like individual control over their
          visit to our website and may want to adjust their settings
          accordingly. However, if you choose not to accept cookies from our
          website or disable them, you may not be able to use or access some of
          our site services or features which require these cookies to function
          and we cannot guarantee that your experience will be as fulfilling as
          it would otherwise be. You may find the following helpful to explain
          how to manage cookies.
        </span>
      </p>

      <p>
        <span>Browser Cookies&nbsp;</span>
      </p>

      <p>
        <span>
          There are a number of ways to manage these &quot;cookies&quot;. For
          example:
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You can decide to accept or decline all or some of the cookies being
          sent by modifying the settings in your browser.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You can also set-up your browser to warn you every time a cookie is
          sent, so that you can review each cookie before you decide whether or
          not to accept it.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You can delete any cookies that have been installed in the cookie
          folder of your browser.
        </span>
      </p>

      <p>
        <span>
          To access and change your browser's cookie settings or manage stored
          cookies, search for and select &quot;cookies&quot; from the browser
          &quot;Help&quot; function and follow the instructions. These will vary
          by browser and possibly between versions of the same browser. If you
          use different computers in different locations to access our website,
          you will need to ensure that each browser is adjusted to suit your
          cookie preferences.
        </span>
      </p>

      <p>
        <span>
          For more information, please visit the Official Support Pages for your
          browser
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Microsoft Internet Explorer:&nbsp;
          <a
            href="https://support.microsoft.com/en-hk/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="externalWin"
          >
            <span>Delete &amp; Manage Cookies</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Mozilla Firefox:&nbsp;
          <a
            href="https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies"
            target="externalWin"
          >
            <span>Cookies &amp; Cache</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Google Chrome:&nbsp;
          <a
            href="https://support.google.com/accounts/answer/61416?hl=en"
            target="externalWin"
          >
            <span>Enable or Disable Cookies</span>
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://support.google.com/chrome/answer/95647?hl=en"
            target="externalWin"
          >
            <span>Clear, Enable, and Manage Cookies in Chrome</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Apple Safari:&nbsp;
          <a
            href="https://support.apple.com/kb/PH21411?viewlocale=en_US&amp;locale=en_US"
            target="externalWin"
          >
            <span>
              Safari for MacOS Sierra: Manage cookies and website data using
              Safari
            </span>
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://support.apple.com/en-us/HT201265"
            target="externalWin"
          >
            <span>
              Clear the history and cookies from Safari on your iPhone, iPad, or
              iPod touch
            </span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Opera:&nbsp;
          <a
            href="https://www.opera.com/help/tutorials/security/privacy/"
            target="externalWin"
          >
            <span>Referrals, redirections, and cookies</span>
          </a>
          &nbsp;objects
        </span>
      </p>

      <p>
        <span>
          For Cookies used in emails and other communications, you may limit
          their use by setting your email client to display HTML emails as text
          only. Please consult the &quot;Help&quot; section of your email client
          for more information.
        </span>
      </p>

      <p>
        <span>Adobe (Flash) Cookies&nbsp;</span>
      </p>

      <p>
        <span>
          Visit the Flash Player security and privacy section on the Adobe
          website.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          <a
            href="https://helpx.adobe.com/flash-player/kb/disable-third-party-local-shared.html"
            target="externalWin"
          >
            <span>Disable third-party local shared objects</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          <a
            href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html"
            target="externalWin"
          >
            <span>How to manage and disable local shared objects</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          If you disable Flash cookies, you may not be able to take advantage of
          all of the features of the Online Services. Also check the help on
          your browser. Some versions have functionality to block Flash Cookies.
        </span>
      </p>

      <p>
        <span>HTML5 Local Storage&nbsp;</span>
      </p>

      <p>
        <span>
          It may be possible to block or delete HTML5 local storage
          functionality from your browser's settings. Without HTML5, you may not
          be able to take advantage of all of the features of the Online
          Services.
        </span>
      </p>

      <p>
        <span>Web Beacons&nbsp;</span>
      </p>

      <p>
        <span>
          Beacons are generally used in conjunction with browser cookies. If you
          delete or decline the use of browser cookies via browser settings,
          beacons may not be able to function properly
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>Embedded Scripts&nbsp;</span>
      </p>

      <p>
        <span>
          This is mostly associated with the use of 3rd Party Cookies. Users may
          opt-out of a third-party remarketing vendor's use of cookies by
          visiting the relevant advertising opt-out page of that particular
          remarketing vendor's website (see here or by visiting the general
          Network Advertising Initiative Opt-Out page, where applicable).
        </span>
      </p>

      <p>
        <span>
          You may disable the use of&nbsp;<b>Google Analytics</b>&nbsp;by using
          the link &quot;Disable Google Analytics&quot; which creates an opt-out
          cookie that prevents the further processing of your data. Also, you
          can prevent Google's collection and use of data (cookies and IP
          address) by downloading and installing the&nbsp;
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
            target="externalWin"
          >
            <span>browser plug-in</span>
          </a>
          . For more information about Google Analytics cookies, please see
          Google's help pages and privacy policy at&nbsp;
          <a
            href="https://support.google.com/analytics/answer/6004245"
            target="externalWin"
          >
            <span>Data Privacy and Security</span>
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://policies.google.com/technologies/cookies"
            target="externalWin"
          >
            <span>How Google Uses Cookies</span>
          </a>
          .
        </span>
      </p>

      <p>
        <span>3rd Party Cookies - Additional Details&nbsp;</span>
      </p>

      <p>
        <span>
          This section enumerates the 3rd Party Cookies used on our site with
          links to more information including to third-party privacy and cookie
          policies.
        </span>
      </p>

      <p>
        <span>Advertising&nbsp;</span>
      </p>

      <p>
        {" "}
        Bing Ads
        <br />
        Bing Ads is a service that provides Pay Per Click advertising on both
        Bing and Yahoo! Search engines. A pixel is used to track conversions
        from Bing Ads.
        <br />
        <a href="https://privacy.microsoft.com/en-US/" target="externalWin">
          <span>Microsoft Privacy Policy</span>
        </a>
        &nbsp;&amp;&nbsp;
        <a
          href="https://account.microsoft.com/account/privacy?refd=privacy.microsoft.com&amp;ru=https%3A%2F%2Faccount.microsoft.com%2Fprivacy%3Frefd%3Dprivacy.microsoft.com&amp;destrt=privacy-dashboard"
          target="externalWin"
        >
          <span>Microsoft Privacy Dashboard</span>
        </a>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          DoubleClick (Double Click Ad Exchange, Double Click Bid Manager,
          Double Click Floodlight)
          <br />
          DoubleClick, owned by Google, develops and provides internet ad
          serving services. It allows clients to traffic, target, cross promote,
          deliver and report on their interactive advertising campaigns.
          <br />
          <a
            href="https://support.google.com/dfp_premium/answer/2839090?hl=en"
            target="externalWin"
          >
            <span>How DoubleClick Uses Cookies</span>
          </a>
          &nbsp;&amp;&nbsp;
          <a
            href="https://policies.google.com/technologies/cookies?gl=UK&amp;hl=en"
            target="externalWin"
          >
            <span>How Google Uses Cookies</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Google AdWords Conversion &amp; Google Dynamic Remarketing
          <br />
          Google AdWords is an online advertising service developed by Google,
          where advertisers pay to display brief advertising copy, product
          listings, and video content within the Google ad network to web users.
          Google AdWords' system is based partly on cookies and partly on
          keywords determined by advertisers
          <br />
          <a
            href="https://policies.google.com/technologies/cookies?gl=UK&amp;hl=en"
            target="externalWin"
          >
            <span>How Google Uses Cookies</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Yahoo Analytics &amp; Yahoo Dot Tag (In June 2017, Yahoo and AOL had
          joined to become Oath)
          <br />
          Dot Tags are Yahoo's universal tracking tag. Dot Tags are used to
          track performance and optimize search, native and display campaigns.
          Yahoo Analytics is a web analytics service offered by Yahoo that
          tracks and reports website traffic to inform decision making and
          strategy for Yahoo Native Ad Campaigns.
          <br />
          <a
            href="https://policies.oath.com/us/en/oath/privacy/topics/cookies/index.html"
            target="externalWin"
          >
            <span>Oath Cookies &amp; Similar Technologies</span>
          </a>
          <br />
          <a
            href="https://policies.oath.com/us/en/oath/privacy/topics/analytics/index.html"
            target="externalWin"
          >
            <span>Oath Analytics</span>
          </a>
        </span>
      </p>

      <p>
        <span>Analytics&nbsp;</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Google Analytics &amp; (GA Audience)
          <br />
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. We use Google Analytics to
          understand how our media campaigns work and how you interact with our
          website in order to improve the user experience.
          <br />
          <a
            href="https://www.google.co.uk/analytics/#?modal_active=none"
            target="externalWin"
          >
            <span>Google Analytics</span>
          </a>
          &nbsp;&amp;&nbsp;
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="externalWin"
          >
            <span>Google Analytics Opt out tool</span>
          </a>
          &nbsp;NB sets an Opted out cookie &amp;&nbsp;
          <a
            href="https://policies.google.com/technologies/cookies?gl=UK&amp;hl=en"
            target="externalWin"
          >
            <span>How Google Uses Cookies</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Sensors Data
          <br />
          Our website uses privately deployed Sensors Data SDKs to record
          information about which pages you have landed on and how you have
          navigated through the site. It is used to build up a profile of a
          user's website usage to establish common surfing patterns and to
          recognize returning visitors from information that is not personal
          data. Sensors Data as a tool provider cannot obtain any customer data.
          <br />
          <a
            href="https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_privacy_policy_english-22256010.html"
            target="externalWin"
          >
            <span>Sensors Data Privacy Policy</span>
          </a>
        </span>
      </p>

      <p>
        <span>Functional&nbsp;</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Certona
          <br />
          A small java script on our site places an anonymous cookie for
          tracking and passes visit data to Certona to generate personalized
          online and email recommendations.
          <br />
          <a href="http://www.certona.com/privacy/" target="externalWin">
            <span>Certona Privacy Policy</span>
          </a>
        </span>
      </p>

      <p>
        To opt-out of Certona Personalization, contact our&nbsp;
        <a href="mailto:support@inboxgroup.ai" target="externalWin">
          <span>Service Team</span>
        </a>
        .
      </p>

      <p>
        {" "}
        Google Tag Manager
        <br />
        Google Tag Manager is a tag management system that allows you to quickly
        and easily update tags and code snippets on a website or mobile app,
        such as those intended for traffic analysis and marketing optimization.
        <br />
        <a
          href="https://www.google.com/analytics/tag-manager/use-policy/"
          target="externalWin"
        >
          <span>Google Tag Manager</span>
        </a>
        &nbsp;&amp;&nbsp;
        <a
          href="https://policies.google.com/technologies/cookies?gl=UK&amp;hl=en"
          target="externalWin"
        >
          <span>How Google Uses Cookies</span>
        </a>
      </p>

      <p>
        {" "}
        Google maps
        <br />
        These cookies are used by Google to track how many people are using
        their maps.
        <br />
        <a
          href="https://policies.google.com/technologies/cookies?gl=UK&amp;hl=en"
          target="externalWin"
        >
          <span>How Google Uses Cookies</span>
        </a>
      </p>

      <p>
        <span>Social Media&nbsp;</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Facebook Pixel Code &amp; Facebook Custom Audience
          <br />
          The Facebook pixel is an analytics tool that helps us measure the
          effectiveness of our advertising on the Facebook, Instagram, and
          Audience Network. We use the Facebook pixel to understand what actions
          people take on our sites in order to generate audience lists using
          Facebook Custom Audience that are used to target adverts to those
          audiences.
          <br />
          <a
            href="https://www.facebook.com/business/learn/facebook-ads-pixel"
            target="externalWin"
          >
            <span>Facebook Pixel</span>
          </a>
          &nbsp;&amp;&nbsp;
          <a
            href="https://www.facebook.com/help/1075880512458213/?helpref=hc_fnav"
            target="externalWin"
          >
            <span>Facebook: Control the Ads you See</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          LinkedIn Insight Tag &amp; LinkedIn Marketing Solutions
          <br />
          The LinkedIn Insight Tag is a piece of lightweight JavaScript code
          that we add to our website. As a LinkedIn Marketing Solutions
          customer, we use the LinkedIn Insight Tag to track conversions,
          retarget website visitors, and unlock additional insights about
          members interacting with the ad campaigns we run on LinkedIn.
          <br />
          <a
            href="https://www.linkedin.com/help/linkedin/answer/67513/linkedin-conversion-tracking-overview?lang=en"
            target="externalWin"
          >
            <span>LinkedIn Conversion Tracking</span>
          </a>
          &nbsp;&amp;&nbsp;
          <a
            href="https://www.linkedin.com/legal/cookie-policy"
            target="externalWin"
          >
            <span>LinkedIn Cookie Policy</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          YouTube
          <br />
          We embed videos or insert links to videos from YouTube (a Google
          Company) on our website(s). As a result, when you visit a page with
          content embedded from or linked to YouTube, you may be presented with
          cookies from YouTube.
          <br />
          <a
            href="https://policies.google.com/technologies/cookies?gl=UK&amp;hl=en"
            target="externalWin"
          >
            <span>How Google Uses Cookies</span>
          </a>
        </span>
      </p>

      <p>
        <span>Other sites&nbsp;</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          PayPal
          <br />
          If within Supply Zone sites, a visitor selects PayPal for payment
          processing, Supply Zone will hand over the visitor into the PayPal
          system. All payment processing is outside the control of Supply Zone
          and cookies may be set by PayPal.
          <br />
          <a
            href="https://www.paypal.com/uk/webapps/mpp/ua/privacy-full"
            target="externalWin"
          >
            <span>https://www.paypal.com/uk/webapps/mpp/ua/privacy-full</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Escrow.com
          <br />
          If within Supply Zone sites, a visitor selects Escrow.com for payment
          processing, Supply Zone site will hand over the visitor into the
          Escrow.com system. All Payment processing is outside the control of
          Supply Zone and cookies may be set by Escrow.com.
          <br />
          <a href="https://www.escrow.com/legal" target="externalWin">
            <span>https://www.escrow.com/legal</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Tencent
          <br />
          Supply Zone provides a facility for visitors to Chat with advertisers.
          If visitor chooses to use this function, Supply Zone will hand over
          visitor into the Tencent system. All Chat processing is outside
          control of Supply Zone and cookies may be set by Tencent.
          <br />
          <a
            href="https://intl.cloud.tencent.com/document/product/301/9247"
            target="externalWin"
          >
            <span>
              https://intl.cloud.tencent.com/document/product/301/9247
            </span>
          </a>
        </span>
      </p>

      <p>
        <span>Further Reading&nbsp;</span>
      </p>

      <p>
        <span>
          To find out more about cookies, visit&nbsp;
          <a href="http://www.allaboutcookies.org/" target="externalWin">
            <span>All About Cookies.org</span>
          </a>
          &nbsp;and/or&nbsp;
          <a href="http://www.cookiecentral.com/" target="externalWin">
            <span>Cookie Central</span>
          </a>
          .
        </span>
      </p>

      <p>
        <span>
          For more information on the EU Cookie Directive, visit Cookiepedia
          at&nbsp;
          <a href="http://cookiepedia.co.uk/eu-cookie-law" target="externalWin">
            <span>http://cookiepedia.co.uk/eu-cookie-law.</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          A guide to behavioral advertising and online privacy has been produced
          by the internet advertising industry which can be found at&nbsp;
          <a href="http://www.youronlinechoices.eu/" target="externalWin">
            <span>http://www.youronlinechoices.eu</span>
          </a>
          .
        </span>
      </p>

      <p>
        <span>
          A number of third-party companies that use Targeting or Advertising
          Cookies are members of the Network Advertising Initiative (NAI).
          Individuals may opt out of targeted advertising delivered by NAI
          member ad networks by visiting&nbsp;
          <a href="http://optout.networkadvertising.org/" target="externalWin">
            <span>http://optout.networkadvertising.org</span>
          </a>
          .
        </span>
      </p>

      <p>
        <span>
          Also, there are industry self-regulatory programs to provide
          individuals with choices related to seeing interested-based ads from
          participating companies.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To learn more in the United States, please visit the Digital
          Advertising Alliance opt-out page.&nbsp;
          <a href="http://optout.aboutads.info/" target="externalWin">
            <span>http://optout.aboutads.info/</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To learn more in in Europe, please visit the European Interactive
          Digital Advertising Alliance choice page.&nbsp;
          <a href="http://www.youronlinechoices.eu/" target="externalWin">
            <span>http://www.youronlinechoices.eu/</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To learn more in Canada, please visit the Digital Advertising Alliance
          of Canada choice page.&nbsp;
          <a href="http://youradchoices.ca/choices/" target="externalWin">
            <span>http://youradchoices.ca/choices/</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          To learn more in Australia, please visit the Australian Digital
          Advertising Alliance choice page.&nbsp;
          <a
            href="http://www.youronlinechoices.com.au/opt-out"
            target="externalWin"
          >
            <span>http://www.youronlinechoices.com.au/opt-out</span>
          </a>
        </span>
      </p>

      <p>
        <span>Last updated date: 27 July 2023 version 0.9</span>
      </p>
    </>
  );
};
