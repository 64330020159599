import * as React from "react";
import ProductSimple from "./ProductSimple";
import Skeleton from "react-loading-skeleton";

const ProductSlider3UP:any = ({ title, products, slug, onSetProductPreviewHandler }:any) => {
  const [dummyArray, setDummyArray] = React.useState([1, 2, 3])
  return (
    <>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

          <div className="xcards" style={{ border: "1px solid rgb(245 245 245)", margin: "10px 0px", padding: "25px" }}>

            <div className="title-link-wrapper mb-3">
              <h2 className="title mb-0 pt-2 pb-2">{title}</h2>
              <a href={`/products/${slug}`} className="mb-0">More Products<i
                className="w-icon-long-arrow-right"></i></a>
            </div>
            <div className="swiper-container swiper-theme product-wrapper" data-swiper-options={`{ 'spaceBetween': 20, 'observer': true, 'observeParents': true, 'autoplay': { 'delay': 4000, 'disableOnInteraction': false }, 'loop': true, 'slidesPerView': 1, 'breakpoints': { '576': { 'slidesPerView': 3 }, '768': { 'slidesPerView': 3 }, '992': { 'slidesPerView': 3 }, '1200': { 'slidesPerView': 3 } } }`}>
              <div className="swiper-wrapper row cols-xl-3 cols-lg-3 cols-md-3 cols-sm-3 cols-4">

                {products.length > 0 ? (
                  <>
                    {products.map((product: any, index:number) => (
                      
                        <ProductSimple key={index} onSetProductPreview={onSetProductPreviewHandler} product={product} /> 

                    ))}
                  </>
                ) : (
                  <>
                    {dummyArray.map((item:any, index:number) => (
                      <div key={index} className="swiper-slide category-wrap mt-20" style={{ marginBottom: "20px" }}>
                        <div className="category category-classic category-absolute overlay-zoom br-sm">
                          {<Skeleton count={1} height={202} />}
                          {<Skeleton count={1} height={38} />}
                          {<Skeleton count={1} height={18} />}
                        </div>
                      </div>
                    ))}

                  </>
                )}

              </div>

              <div className="swiper-pagination"></div>

            </div>

          </div>

        </div>
    </>
  ); 
};

export default ProductSlider3UP;