import * as React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.scss";

// Import Page wrapper
import RegisterPage from "./components/pages/auth/RegisterPageV2";
import ShopFront from "./components/pages/shop/Landing/ShopFront";
import ProductDetails from "./components/pages/shop/Products/ProductDetails";
import PageWrapper from "./components/pages/layout/PageWrapper";
import BecomeAVendor from "./components/pages/auth/BecomeAVendor";
import RegisterPageV2 from "./components/pages/auth/RegisterPageV2";
import ProductSearchResults from "./components/pages/shop/Products/ProductSearchResults";
import RFQSearchResults from "./components/pages/shop/Products/RFQSearchResults";
import CategoryChildren from "./components/pages/shop/Products/CategoryChildren";
import Legal from "./components/pages/shop/Landing/Legal";
import { RFQAdvancedListViewPage } from "./components/pages/account/rfq/RFQAdvancedListViewPage";
import MessengerAdvancedInquiryPageWrapper from "./components/pages/account/messenger/MessengerAdvancedInquiryPageWrapper";
import MessengerAdvancedRFQPageWrapper from "./components/pages/account/messenger/MessengerAdvancedRFQPageWrapper";
import UserManagementPageWrapper from "./components/pages/account/users/UserManagementPageWrapper";
import ProductManagementPageWrapper from "./components/pages/account/product-management/ProductManagementPageWrapper";
import ProductCreatePageWrapper from "./components/pages/account/product-management/ProductCreatePageWrapper";
import ProfileWizardPageWrapper from "./components/pages/account/profile-wizard/ProfileWizardPageWrapper";
import { DataProvider, useAppDataContext } from "./data";
import { BuyerRFQCreatePage } from "./components/pages/account/rfq/BuyerRFQCreatePage";
import RewardZonePageWrapper from "./components/pages/account/rewards-zone/RewardZonePageWrapper";
import { MiniSite } from "./components/pages/mini-site/MiniSite";
import { ChatGPT } from "./components/pages/chat-gpt/ChatGPT";

import PersonalSettingsPage from "./components/pages/account/profile-wizard/PersonalSettingsPage";
import { CompanySettings } from "./components/pages/account/profile-wizard/accounts/CompanySettings";
import CompanySettingsPage from "./components/pages/account/profile-wizard/CompanySettingsPage";

import ProfileSettingsPageWrapper from "./components/pages/account/profile-wizard/ProfileSettingsPageWrapper";
import CompanySettingsPageWrapper from "./components/pages/account/profile-wizard/CompanySettingsPageWrapper";
import KommandPageWrapper from "./components/pages/account/profile-wizard/KommandPageWrapper";
import InvitesPageWrapper from "./components/pages/account/invites/InvitesPageWrapper";
import { SupplierActivation } from "./components/pages/account/supplier-activation/SupplierActivation";
import SupplierActivationWrapper from './components/pages/account/supplier-activation/SupplierActivationWrapper';
import RFQPageWrapper from "./components/pages/account/rfq-marketplace/RFQPageWrapper";

import PricingPageWrapper from "./components/pages/account/pricing/PricingPageWrapper";
import PricingPageWrapper2 from "./components/pages/account/pricing/PricingPageWrapper2";
import BuyerActivationWrapper from "./components/pages/account/buyer-activation/BuyerActivationWrapper";

export default function App() {
  const { isPageLoading } = useAppDataContext();

  return (
    <DataProvider>
      <Routes>
        <Route element={<PageWrapper />}>
          {/* Home */}
          <Route path="/" element={<ShopFront />} />

          <Route path="/pricing" element={<PricingPageWrapper />} />
          <Route path="/pricing2" element={<PricingPageWrapper2 />} />
          <Route path="/pricing/:rfqId" element={<PricingPageWrapper />} />
          {/* Messenger */}
          <Route
            path="/messenger/rfqs"
            element={<MessengerAdvancedRFQPageWrapper />}
          />
          <Route
            path="/messenger/rfqs/:rfqId"
            element={<MessengerAdvancedRFQPageWrapper />}
          />
          <Route
            path="/messenger/inquiries"
            element={<MessengerAdvancedInquiryPageWrapper />}
          />

          {/* RFQ Marketplace */}
          <Route path="/reward-zone" element={<RewardZonePageWrapper />} />
          <Route path="/buyer/invites" element={<InvitesPageWrapper />} />
          <Route
            path="/supplier/activation/:rfqId"
            element={<SupplierActivationWrapper />}
          />

          <Route
            path="/buyer/activation"
            element={<BuyerActivationWrapper />}
          />
          {/* RFQ Marketplace */}
          <Route path="/rfq/marketplace" element={<RFQPageWrapper />} />
          <Route
            path="/rfqs/marketplace"
            element={<RFQAdvancedListViewPage />}
          />

          <Route path="/rfqs/create" element={<BuyerRFQCreatePage />} />

          <Route
            path="/kommand-mobile"
            element={<KommandPageWrapper />}
          />

          {/* Users */}
          <Route path="/users/manage" element={<UserManagementPageWrapper />} />

          {/* Products Management */}
          <Route
            path="/products/manage"
            element={<ProductManagementPageWrapper />}
          />
          <Route
            path="/products/manage/:id"
            element={<ProductManagementPageWrapper />}
          />
          <Route
            path="/products/create"
            element={<ProductCreatePageWrapper />}
          />
          <Route
            path="/products/create/:product_id"
            element={<ProductCreatePageWrapper />}
          />

          {/* User Profile */}
          <Route path="/profile-wizard" element={<PersonalSettingsPage />} />

          <Route path="/company-profile" element={<CompanySettingsPage />} />
          {/* User Profile */}
          <Route
            path="/profile-settings"
            element={<ProfileSettingsPageWrapper />}
          />
          {/* User Profile */}
          <Route
            path="/company-settings"
            element={<CompanySettingsPageWrapper />}
          />

          {/* Legal Docs */}
          <Route path="/legal/:slug" element={<Legal />} />
          <Route path="/legal/" element={<Legal />} />

          {/* Other */}
          <Route
            path="/product/:categoryName/:productName/:id/"
            element={<ProductDetails />}
          />
          <Route
            path="/product/:categoryName/:productName/:id/:openChat"
            element={<ProductDetails />}
          />
          <Route
            path="/categories/:categoryId"
            element={<CategoryChildren />}
          />
          <Route path="/supplier/:supplierId" element={<MiniSite />} />
          <Route path="/chat" element={<ChatGPT />} />
          <Route path="/sign-up/:userType" element={<RegisterPage />} />
          <Route path="/become-a/:userType" element={<BecomeAVendor />} />
          <Route
            path="/become-a/:userType/register"
            element={<RegisterPageV2 />}
          />
          <Route path="/accept-invite" element={<RegisterPageV2 />} />
          <Route path="/search" element={<ProductSearchResults />} />

          <Route path="/search-rfqs" element={<RFQSearchResults />} />
        </Route>
      </Routes>
    </DataProvider>
  );
}
