import * as React from "react";

export default () => {
  return (
    <>
      <div>
        <p>
          <span>
            To report an Intellectual Property (IP) infringement, kindly read
            the following SUPPLY ZONE INTELLECTUAL PROPERTY RIGHTS PROTECTION
            POLICY and complete and sign the relevant form.
          </span>
        </p>
      </div>

      <p>
        <b>
          <span>
            SUPPLY ZONE INTELLECTUAL PROPERTY RIGHTS PROTECTION POLICY
          </span>
        </b>
      </p>

      <p>
        <span>
          1&nbsp;&nbsp;&nbsp;&nbsp;' Intellectual Property Rights Protection
          Policy and Alleged Infringements
        </span>
      </p>

      <p>
        <span>
          1.1&nbsp;&nbsp;&nbsp; This document outlines Supply Zone's policy ('
          <b>Policy</b>') for dealing with notifications of alleged
          infringements of intellectual property rights ('IPR'), in relation to
          materials available on Supply Zone's websites or servers (each such
          alleged infringement, an 'Alleged Infringement'), by visitors and
          users of this website ('Users').
        </span>
      </p>

      <p>
        <span>
          For the purposes of this Policy, '<b>Supply Zone</b>' means the
          publisher and operator of this website, Publishers Representatives
          Limited (a company incorporated in the Seychelles Special
          Administrative Region) and its parents, subsidiaries, related
          corporations and affiliates, collectively.
        </span>
      </p>

      <p>
        <span>
          1.2&nbsp;&nbsp;&nbsp; Supply Zone has established these procedures to
          facilitate the co-operation between Supply Zone and Users.
        </span>
      </p>

      <p>
        <span>
          1.3&nbsp;&nbsp;&nbsp; Supply Zone is not an arbiter or judge. In
          carrying out any action instigated by Users in respect of IPR, Supply
          Zone does not intend to make any decisions on whether such IPR are
          valid and subsisting. Conversely, should Supply Zone decide not to
          carry out any action in respect of an Alleged Infringement, Supply
          Zone is not endorsing the display of such materials on this website.
        </span>
      </p>

      <p>
        <span>
          2&nbsp;&nbsp;&nbsp;&nbsp;' Our Handling of Alleged Infringement
        </span>
      </p>

      <p>
        <span>
          2.1&nbsp;&nbsp;&nbsp; An IPR owner or the agent thereof (the '
          <b>Complainant</b>') may (and shall, if requested by Global Sources)
          complete and sign the IP Complaint Notice in the form attached
          in&nbsp;
          <a
            href="https://www.supplyzone.ai/ST/pdf/IPCOMPLAINT.PDF"
            target="_blank"
          >
            <span>Schedule 2</span>
          </a>
          &nbsp;to notify Supply Zone of the Alleged Infringement (the '
          <b>IP Complaint Notice</b>').
        </span>
      </p>

      <p>
        <span>
          2.2&nbsp;&nbsp;&nbsp; Supply Zone shall deal with the IP Complaint
          Notice in accordance with the procedures as set out in&nbsp;
          <a
            href="https://www.supplyzone.ai/STM/help-faq/en/ip-policy/schedule1/"
            target="_blank"
          >
            <span>Schedule 1</span>
          </a>
          &nbsp;hereto (the &quot;<b>Procedures</b>&quot;).
        </span>
      </p>

      <p>
        <span>
          2.3&nbsp;&nbsp;&nbsp; Supply Zone reserves the right to amend the
          Procedures from time to time.
        </span>
      </p>

      <p>
        <span>
          2.4&nbsp;&nbsp;&nbsp; Supply Zone reserves the right not to take any
          further action in respect of the Alleged Infringement if the IP
          Complaint Notice as prescribed in&nbsp;
          <a href="https://www.supplyzone.ai/ST/pdf/IPCOMPLAINT.PDF">
            <span>Schedule 2</span>
          </a>
          &nbsp;is not submitted, is insufficient or is incomplete, and/or the
          Relevant Information (as defined in Clause 3.1 below) is not provided
          as required or is insufficient or is incomplete, and/or any of the
          Procedures stipulated in&nbsp;
          <a
            href="https://www.supplyzone.ai/STM/help-faq/en/ip-policy/schedule1/"
            target="_blank"
          >
            <span>Schedule 1</span>
          </a>
          &nbsp;are not complied with by the Complainant.
        </span>
      </p>

      <p>
        <span>3&nbsp;&nbsp;&nbsp;&nbsp;' Relevant Information</span>
      </p>

      <p>
        <span>
          3.1&nbsp;&nbsp;&nbsp; The Complainant shall provide Supply Zone with
          all the necessary documentation and other relevant information in
          respect of the Alleged Infringement (the '<b>Relevant Information</b>
          '), including, inter alia:
        </span>
      </p>

      <p>
        <span>
          3.1.1&nbsp;' Evidence of the Complainant's ownership of the IPR which
          is being relied upon and alleged to have been infringed:
        </span>
      </p>

      <p>
        <span>
          a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>In the case of copyright</span>
        </b>
        <span>
          , this would include (where applicable): registration certificates,
          and evidence of the original creation, initial publication and the
          Complainant's ownership of the copyright concerned.
        </span>
      </p>

      <p>
        <span>
          b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>In the case of trademark rights</span>
        </b>
        <span>
          , this would include (where applicable): the certificate of trademark
          registration and other relevant documents issued by the applicable
          authorities in the Relevant Territory (as defined in Clause 4 below)
          relating to the trademark rights concerned.
        </span>
      </p>

      <p>
        <span>
          c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>In the case of patent rights</span>
        </b>
        <span>
          , this would include (where applicable): the certificate of patent
          grant (complete with all relevant claims, specifications and
          drawings), and other relevant reports, issued by the applicable
          authorities in the Relevant Territory (as defined in Clause 4 below)
          relating to the patent rights concerned.
        </span>
      </p>

      <p>
        <span>
          d.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>In the case of registered design rights</span>
        </b>
        <span>
          , this would include (where applicable): certificate of registration
          of design issued by the applicable authorities in the Relevant
          Territory (as defined in Clause 4 below) relating to the registered
          design rights concerned.
        </span>
      </p>

      <p>
        <span>
          e.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Any other evidence that Supply Zone may require depending on the
          specific facts and circumstances of the case. For the avoidance of
          doubt, Supply Zone shall have the sole and absolute discretion to
          determine what shall constitute appropriate evidence of the
          Complainant's ownership of the IPR concerned.
        </span>
      </p>

      <p>
        <span>
          3.1.2&nbsp;' Certificate of due incorporation or registration of the
          Complainant company (where applicable).
        </span>
      </p>

      <p>
        <span>
          3.1.3&nbsp;' Evidence of the infringement complained of in the IP
          Complaint Notice including, but not limited to, website
          locations/links, photographs, samples, brochures, sales contracts and
          invoices relating to the allegedly infringing material or activity
          concerned.
        </span>
      </p>

      <p>
        <span>
          3.1.4&nbsp;' Any final court or arbitration judgment, order, decision
          or award against or any binding settlement with the alleged infringer
          ('<b>Alleged Infringer</b>') in relation to the subject matter
          complained of, and/or (if applicable and available) any other legally
          valid and binding evidence, in each case either originating from or
          legally recognized in the Relevant Territory (as defined in Clause 4
          below), evidencing that the relevant material or activities concerned
          have infringed the Complainant's IPR.
        </span>
      </p>

      <p>
        <span>
          3.1.5&nbsp;' Such other relevant documents and information as Supply
          Zone may require.
        </span>
      </p>

      <p>
        <span>4&nbsp;&nbsp;&nbsp;&nbsp;' Relevant Territory</span>
      </p>

      <p>
        <span>
          4.1&nbsp;&nbsp;&nbsp; For the purpose of this Policy, &quot;
          <b>Relevant Territory</b>&quot; shall mean the country / region where
          (i) the Alleged Infringement takes place or is likely to take place;
          (ii) the allegedly infringing products are manufactured; or (iii) the
          allegedly infringing products are advertised, offered or sold.
        </span>
      </p>

      <p>
        <span>5&nbsp;&nbsp;&nbsp;&nbsp;' Indemnity</span>
      </p>

      <p>
        <span>
          5.1&nbsp;&nbsp;&nbsp; Supply Zone does not have the power or duty to
          verify the identities of each individual Complainant submitting an IP
          Complaint Notice, the subject matter complained of or the source of
          the allegedly infringing material.
        </span>
      </p>

      <p>
        <span>
          5.2&nbsp;&nbsp;&nbsp; By submitting an IP Complaint Notice and asking
          Supply Zone to take action in accordance with this Policy in respect
          of the Alleged Infringement, each individual Complainant submitting
          the IP Complaint Notice agrees:
        </span>
      </p>

      <p>
        <span>
          5.2.1&nbsp;' to hold harmless, defend and fully indemnify Supply Zone
          and its agents, representatives, contractors, directors, officers and
          employees (as well as their legal and other advisors and consultants)
          (collectively, the 'Supply Zone Indemnitees') from and against any and
          all claims, liabilities, losses, damages, suits, actions, proceedings,
          judgments, fines, penalties, costs and expenses (including legal fees
          and expenses), of any nature whatsoever, incurred or suffered by any
          of them however arising from or in connection with any action that the
          Supply Zone Indemnitees may take pursuant to the IP Complaint Notice;
        </span>
      </p>

      <p>
        <span>
          5.2.2&nbsp;' that such individual Complainant submitting the IP
          Complaint Notice will not take any legal action and/or make any claim
          against any of the Supply Zone Indemnitees in respect of any matters
          related to the IP Complaint Notice;
        </span>
      </p>

      <p>
        <span>
          5.2.3&nbsp;' that the IP Complaint Notice and all the supporting
          documents may be disclosed to the parties complained of and
          acknowledge that such individual Complainant submitting the IP
          Complaint Notice has read, understood, accepted and agreed to Supply
          Zone's Privacy Policy relating to this website (which is accessible
          through the 'Privacy Policy' link in the footer of this website) and
          where appropriate, has referred or will direct relevant third parties
          to such Privacy Policy; and
        </span>
      </p>

      <p>
        <span>
          5.2.4&nbsp;' that the IP Complaint Notice and any or all of the
          Relevant Information and other supporting documents and information
          provided (or any portions or extracts thereof) may be reproduced,
          used, disclosed and/or published by Supply Zone in the manner and to
          the extent deemed necessary or appropriate by Global Sources.
          Specifically (but without prejudice to the generality of the
          foregoing), Supply Zone reserves the right to disclose any information
          contained in the IP Complaint Notice, or any Relevant Information or
          other supporting documents and information provided, in circumstances
          where Supply Zone believes that disclosure is required under any law
          or regulation, or to cooperate with regulators or law enforcement
          authorities, or to protect or enforce Supply Zone's rights and/or
          interests.
        </span>
      </p>

      <p>
        <b>
          <span>Schedule1</span>
        </b>
      </p>

      <div>
        <b>
          <span>
            <hr />
          </span>
        </b>
      </div>

      <div>
        <p>
          <span>
            To report an Intellectual Property (IP) infringement, kindly read
            the following SUPPLY ZONE INTELLECTUAL PROPERTY RIGHTS PROTECTION
            POLICY and complete and sign the relevant form.
          </span>
        </p>
      </div>

      <p>
        <b>
          <span>SCHEDULE 1</span>
        </b>
      </p>

      <p>
        <b>
          <span>PROCEDURES</span>
        </b>
      </p>

      <p>
        <span>This Schedule 1 is applicable to all Complainants.</span>
      </p>

      <p>
        <b>
          <span>Notification of an Alleged Infringement</span>
        </b>
      </p>

      <p>
        <span>
          1&nbsp;&nbsp;&nbsp;&nbsp;' Those wishing to notify Supply Zone of an
          Alleged Infringement shall complete the IP Complaint Notice in the
          form as attached in&nbsp;
          <a
            href="https://www.supplyzone.ai/ST/pdf/IPCOMPLAINT.PDF"
            target="_blank"
          >
            <span>Schedule 2</span>
          </a>
          &nbsp;and send it (together with the Relevant Information) to Supply
          Zone's designated representative, whose details are as follows:
        </span>
      </p>

      <table width="100%">
        <tr>
          <td width={72}>
            <p>
              <span>To:</span>
            </p>
          </td>
          <td>
            <p>
              <span>IP Officer, Inbox Group Ltd</span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>Address:</span>
            </p>
          </td>
          <td>
            <p>
              <span>
                Suite 3, Global Village, Jivan's Complex, Mont Fleuri, Mahe,
                Seychelles
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>Email:</span>
            </p>
          </td>
          <td>
            <p>
              <span>
                <a href="mailto:support@inboxgroup.ai">
                  <span>Supply Zone IP Officer</span>
                </a>
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>&nbsp;</span>
            </p>
          </td>
          <td>
            <p>
              <span>&nbsp;</span>
            </p>
          </td>
        </tr>
      </table>

      <p>
        <span>
          2&nbsp;&nbsp;&nbsp;&nbsp;' Complainants should particularize the
          Alleged Infringement in as much detail as possible in order to help
          Supply Zone to evaluate the IP Complaint Notice as effectively as
          possible.
        </span>
      </p>

      <p>
        <span>
          3&nbsp;&nbsp;&nbsp;&nbsp;' The following are examples of how to
          present the Alleged Infringement to Global Sources:
        </span>
      </p>

      <p>
        <span>
          A.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          &quot;Model XYZ which can be found on the following hyperlink:&nbsp;'
        </span>
        <i>
          <span>&lt;hyperlink&gt;</span>
        </i>
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;
        </span>
        <span>
          contains handles of cookware which are identical to the novel design
          of Registered Patent Number{" "}
          <b>
            <i>&lt;reg_patent_number&gt;.</i>
          </b>{" "}
          See Figure 2 of the said registration for the layout of the patented
          cookware.&quot;
        </span>
      </p>

      <p>
        <span>
          B.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          &quot;Model XYZ which can be found on the following
          hyperlink:&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <i>
          <span>&lt;hyperlink&gt;</span>
        </i>
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;' &nbsp;contains a mark which is identical to the
          registered Trademark &quot;XYZ&quot; (Registration No.{" "}
          <b>
            <i>&lt;reg_patent_number&gt;</i>
          </b>
          <i>.</i>) covering cookware.
        </span>
      </p>

      <p>
        <span>
          4&nbsp;&nbsp;&nbsp;&nbsp;' If the Complainant is an agent or a parent,
          subsidiary, related corporation or affiliate of the owner of the IPR
          with which the Alleged Infringement is concerned, such Complainant
          must provide a certified copy of a power of attorney executed by the
          said owner of the IPR (or such other evidence of the Complainant's
          authority as may be deemed satisfactory by Supply Zone), in addition
          to the Relevant Information.
        </span>
      </p>

      <p>
        <b>
          <span>Investigations in respect of the Alleged Infringement</span>
        </b>
      </p>

      <p>
        <span>
          5&nbsp;&nbsp;&nbsp;&nbsp;' If the Complainant refuses or fails to
          satisfactorily complete and sign the IP Complaint Notice, or if, in
          Supply Zone's sole and absolute opinion, any of the Relevant
          Information provided to Supply Zone is or appears to be incorrect,
          incomplete, invalid, inapplicable, unsatisfactory or insufficient,
          Supply Zone reserves the right to refuse to handle the Alleged
          Infringement unless and until the IP Complaint Notice has been
          satisfactorily completed and signed and all further clarification,
          details, information or documents requested by Supply Zone are
          provided to Supply Zone by the Complainant.
        </span>
      </p>

      <p>
        <span>
          6&nbsp;&nbsp;&nbsp;&nbsp;' Supply Zone shall conduct investigations in
          relation to the Alleged Infringement to ascertain the merits of the IP
          Complaint Notice based upon the Relevant Information provided by the
          Complainant.
        </span>
      </p>

      <p>
        <span>
          7&nbsp;&nbsp;&nbsp;&nbsp;' Upon receipt of the IP Complaint Notice,
          Supply Zone will evaluate the IP Complaint Notice.
        </span>
      </p>

      <p>
        <span>
          8&nbsp;&nbsp;&nbsp;&nbsp;' Upon the provision of the IP Complaint
          Notice, the Relevant Information and all other information and
          documents which Supply Zone may require for the evaluation of the
          Alleged Infringement to Global Sources, Supply Zone shall serve the IP
          Complaint Notice to the Alleged Infringer.
        </span>
      </p>

      <p>
        <span>
          9&nbsp;&nbsp;&nbsp;&nbsp;' The Alleged Infringer will be given three
          full working days to rebut the claims of the Alleged Infringement in
          writing to Supply Zone's designated representative (whose details are
          set out in Section 1 of this Schedule 1 above).
        </span>
      </p>

      <p>
        <span>
          10&nbsp;&nbsp;&nbsp;' If, in Supply Zone's sole and absolute opinion,
          the basis, grounds or merits of the IP Complaint Notice have not been
          satisfactorily established, and/or the Alleged Infringer's rebuttal of
          the claims of the Alleged Infringement do not appear to be unfounded
          or groundless, then Supply Zone reserves the right not to take any
          further action in respect of the complaint.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <span>Action by Supply Zone</span>
        </b>
      </p>

      <p>
        <span>
          11&nbsp;&nbsp;&nbsp;' Should the Alleged Infringer fail to respond
          satisfactorily and rebut the claims of the Alleged Infringement within
          three working days, Supply Zone shall remove the alleged infringing
          material referred to in the IP Complaint Notice.
        </span>
      </p>

      <p>
        <span>
          12&nbsp;&nbsp;&nbsp;' Notwithstanding Section 9 of this Schedule 1
          above, Supply Zone may take any such action as it deems necessary,
          including inter alia removing any material relating to the Alleged
          Infringement from this website as it deems appropriate.
        </span>
      </p>

      <p>
        <span>
          13&nbsp;&nbsp;&nbsp;' Supply Zone reserves the right to terminate its
          relationship with any Alleged Infringer who is the subject of an IP
          Complaint Notice, regardless of the outcome of such IP Complaint
          Notice.
        </span>
      </p>

      <p>
        <b>
          <span>Appeals following action by Supply Zone</span>
        </b>
      </p>

      <p>
        <span>
          14&nbsp;&nbsp;&nbsp;' Where the Alleged Infringer rebuts the claims in
          the IP Complaint Notice, and provides appropriate grounds (with
          supporting evidence) for rejecting the claims contained in the Alleged
          Infringement, Supply Zone shall be entitled, at its sole and absolute
          discretion, to carry out the following:
        </span>
      </p>

      <p>
        <span>
          A.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Resume displaying material that has been removed pursuant to an IP
          Complaint Notice.
        </span>
      </p>

      <p>
        <span>
          B.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Where Supply Zone has taken any other action against the Alleged
          Infringer, Supply Zone may, at its sole and absolute discretion,
          determine the most appropriate recourse to be taken.
        </span>
      </p>

      <p>
        <span>
          15&nbsp;&nbsp;&nbsp;' Where a Complainant would like to persist in
          asserting the Complainant's claims based upon the IP Complaint Notice,
          Supply Zone requests that the Complainant show Supply Zone a relevant
          final court or arbitration judgment, order, decision or award in the
          Relevant Jurisdiction, before taking any further action.
        </span>
      </p>
    </>
  );
};
