import React, {FC} from 'react' 
import ProductManagementPage from './ProductManagementPage';
import Threads from '../chat/Threads';

const ProductManagementPageWrapper: FC = () => {
  return (
    <>
      <ProductManagementPage />
      <Threads/>
    </>
  )
}

export default ProductManagementPageWrapper;
