import React, { FunctionComponent, useEffect, useState } from "react";
import { Profile } from "../account/profile-wizard/accounts/model/Profile";
import { Col } from "antd";
import ProductSimple from "../shop/Products/Widgets/ProductSimple";
interface DocumentListItemProps {
    children?: React.ReactNode;
    profile: Profile;
    latest: any[];
    selling: any[];
}
const HomeTab: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        profile,
        latest,
        selling,
    }: any = props;

    const onSetProductPreviewHandler = () => {

    }
    const generateSliderOptions = (productsNumber: number) => {
        var strOptions = "{'spaceBetween': 20, 'observer': true,'observeParents': true,'autoplay': {'delay': 4000,'disableOnInteraction': false},";
        strOptions += "'loop': true,'breakpoints': {'576': {'slidesPerView': 1},'768': { 'slidesPerView': 2},'992': {";
    
        strOptions += productsNumber > 4 ? "'slidesPerView': 4 }}}" : "'slidesPerView': " + productsNumber + " }}}";
    
        return strOptions;
      }

    return (
        <>
            {profile.businessSummary !== null && (
                <>
                    <div className="card xcards mb-5">
                        <div className="collapse show">
                            <div className="card-body border-top p-9">
                                <Col span={24}><strong><h4 style={{ fontWeight: 700 }}>{profile.name}</h4></strong></Col>
                                <Col span={24}><hr /></Col>

                                <Col span={24}><div dangerouslySetInnerHTML={{ __html: profile.businessSummary }} /></Col>
                            </div>
                        </div>
                    </div>

                </>
            )}
            <div className="card xcards mb-5">
                <div className="collapse show">
                    <div className="card-body border-top p-9">
                        <Col span={24}><strong><h4 style={{ fontWeight: 700 }}>New Arrivals</h4></strong></Col>
                        <Col span={24}><hr /></Col>
                        <div className="swiper-container swiper-theme" data-swiper-options={`${generateSliderOptions(latest.length)}`} >
                            <div className="product-scroller swiper-wrapper row cols-lg-4 cols-md-4 cols-sm-4 cols-4">
                                {latest.map((product: any, swiperIndex: number) => (
                                    <ProductSimple key={swiperIndex} onSetProductPreview={onSetProductPreviewHandler} product={product} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card xcards mb-5">
                <div className="collapse show">
                    <div className="card-body border-top p-9">
                        <Col span={24}><strong><h4 style={{ fontWeight: 700 }}>Top Selling</h4></strong></Col>
                        <Col span={24}><hr /></Col>
                        <div className="swiper-container swiper-theme" data-swiper-options={`${generateSliderOptions(selling.length)}`} >
                            <div className="product-scroller swiper-wrapper row cols-lg-4 cols-md-4 cols-sm-4 cols-4">
                                {selling.map((product: any, swiperIndex: number) => (
                                    <ProductSimple key={swiperIndex} onSetProductPreview={onSetProductPreviewHandler} product={product} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { HomeTab };