import * as React from "react";
import { useState } from 'react';
import ProductsSearchAside from "./ProductsSearchAside";
import Snippets from "../../../../helpers/Snippets";
import axios from "axios";
import ProductSimple from "./Widgets/ProductSimple";
import { useParams } from "react-router-dom";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import CategoryChildrenAside from "./CategoryChildrenAside";
import ProductsSearchResultsList from "./ProductsSearchResultsList";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
export default () => {

  let { categoryId } = useParams()

  const [resultsFetched, setResultsFetched] = useState(false)
  const [categoriesList, setCategoriesList] = useState([])
  const [categoryHierachy, setCategoryHierachy] = useState([])
  const [category, setCategory] = useState({ id: 0, name: "", image: "" })
  const [productList, setProductsList] = React.useState([]);
  const [filteredProductList, setFilteredProductList] = React.useState([]);
  const [brandsList, setBrandsList] = useState(new Map<string, number>)
  const [attributesList, setAttributesList] = useState([])
  const [priceRangesList, setPriceRangesList] = useState([])

  const roundToTen = (number: number) => {
    return Math.ceil(number / 10) * 10
  }
  React.useEffect(() => {

    AxiosAPI.getCategoryChildren(parseInt(categoryId)).then(({ data: category }) => {
      setCategoriesList(category.children);
      setCategory(category)
    }).catch((error) => {

    });

    var config = {
      method: 'get',
      url: Snippets.konstants.BASE_URL_API + '/model/category-hierarchy/' + categoryId,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    };
    axios(config).then(({ data: hierarchy }) => {
      setCategoryHierachy(hierarchy)
    }).catch((error: any) => { });

    var configProducts = {
      method: 'get',
      url: Snippets.konstants.BASE_URL_API + '/marketplace/category/' + categoryId + "/products",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    };

    axios(configProducts).then(({ data: products }) => {
      setResultsFetched(true)
      if (products) {
        setProductsList(products);
        setFilteredProductList(products);
        let brandsMap = new Map()
        let minPrice = Number.MAX_VALUE;
        let maxPrice = 0;
        products.forEach((product: any) => {
          //brands
          if (product.brandName == "" || product.brandName == null) product.brandName = "No Brand";
          if (brandsMap.has(product.brandName))
            brandsMap.set(product.brandName, parseInt(brandsMap.get(product.brandName)) + 1)
          else
            brandsMap.set(product.brandName, 1)

          //pricing
          maxPrice = (product.price > maxPrice) ? product.price : maxPrice
          minPrice = (product.price < minPrice) ? product.price : minPrice

        });

        //create price ranges list
        if (minPrice != Number.MAX_VALUE) {
          let priceRanges_ = []
          let priceRangesCount = products.length > 5 ? 5 : products.length
          let rangeSize = (maxPrice - minPrice) / priceRangesCount
          for (let x = 0; x < priceRangesCount; x++) {
            let priceRange = {
              min: roundToTen(minPrice + (x * rangeSize)),
              max: roundToTen(minPrice + (x * rangeSize) + (rangeSize - 1))
            }
            priceRanges_.push(priceRange)
          }
          setPriceRangesList(priceRanges_)
        }

        setBrandsList(brandsMap)

        //fetch attributes
        var attributesConfig = {
          method: 'get',
          url: Snippets.konstants.BASE_URL_API + '/marketplace/category/' + categoryId + "/product-attributes",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        };

        axios(attributesConfig).then(({ data: attributes }) => {
          //console.log(attributes)
          setAttributesList(attributes)
        }).catch((error: any) => {

        })
      }
    }).catch((error: any) => { });

  }, []);

  const filterByAttribute = (id: number, value: string) => {
    //console.log(id, value)
    var newFilteredProducts: any[] = []
    productList.map((product) => {

      product.attributes.map((attribute: any) => {
        if (attribute.categoryAttribute.id == id && attribute.value.toUpperCase() == value.toUpperCase()) {
          newFilteredProducts.push(product)
        }
      })

    })
    setFilteredProductList(newFilteredProducts)
  }

  const baseURL = Snippets.konstants.BASE_URL_API;
  const [dummyArray, setDummyArray] = React.useState([1, 2, 3, 4])
  return (

    <>
      {/*    Start of Main    */}
      {resultsFetched ? (
        <>

          {/*    Start of Breadcrumb    */}

          <div className="breadcrumb-container container">


            <ul className="breadcrumb font-size-normal">

              {categoryHierachy.map((category, index) => (
                <>
                  {index > 0 && (
                    <li className="mr-1"><a href={`/categories/${category.id}`}>{category.name}</a></li>
                  )}
                </>
              ))}
            </ul>

          </div>


          {/*    End of Breadcrumb    */}
          <main id="main" className="main mb-10 pb-1" style={{
            backgroundColor: 'rgb(245,245,245)', padding: '20px 0'
          }}>

            {/*    Start of Page Content    */}
            <div className="page-content">
              <div className="container">
                <div className="row gutter-lg">
                  <CategoryChildrenAside
                    priceRanges={priceRangesList}
                    categories={categoriesList}
                    brands={brandsList}
                    attributes={attributesList}
                    filterByAttribute={filterByAttribute}></CategoryChildrenAside>
                  <div className="main-content">

                    <div className="intro-wrapper" style={{marginLeft:"0px"}}>
                      <div className="row mb-3"><div className="col-md-12"><h2>{category.name}</h2></div> </div>

                      {/* category children */}
                      <div className="row cols-xl-4 cols-lg-4 cols-md-4 cols-sm-4 cols-4">
                        {categoriesList.map((category: any) => (
                          <>
                            {category.productsCount > 0 && (
                              <div className="swiper-slide category-wrap mt-20" style={{ marginBottom: "20px" }}>
                                <div className="xcards category category-classic category-absolute overlay-zoom br-sm pt-3 mb-0" title={category.name} style={{ height: "220px" }}  >
                                  <a href={`/categories/${category.id}`}>
                                    <figure className="category-media">
                                      {category.image != null ? (
                                        <img src={`${baseURL}/home/download-attachment/${category.image}`} alt="Category" />
                                      ) : (
                                        <img src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png`} alt="Category" />
                                      )}

                                    </figure>
                                  </a>
                                  <div className="category-content">
                                    <h4 className="category-name ls-normal" style={{ whiteSpace: "unset" }}>{category.name} {`(${category.productsCount})`}</h4>
                                    <Link to={`/categories/${category.id}`} className="btn btn-primary btn-link btn-underline">View Products</Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>

                        ))}
                      </div>

                      {/* product results */}
                      {productList.length > 0 && (
                        <>
                          <div className="row mb-4 mt-4" style={{ borderBottom: "solid 1px #ddd", borderTop: "solid 1px #ddd" }}>
                            <div className="col-12 pt-2 pb-2">
                              <h4>{productList.length} Results Found</h4>
                            </div>
                          </div>
                          <ProductsSearchResultsList productList={filteredProductList}></ProductsSearchResultsList>
                        </>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/*    End of Page Content    */}
          </main>
        </>
      ) : (
        <>

          <div className="breadcrumb-container container">
            <div className="row">
              <div className="col-md-3 mt-3">
                {<Skeleton count={1} height={15} />}
              </div>
            </div>

          </div>

          <main id="main" className="main mb-10 pb-1" style={{
            backgroundColor: 'rgb(245,245,245)', padding: '20px 0'
          }}>

            <div className="page-content">
              <div className="container">
                <div className="row gutter-lg">
                  <aside
                    className="sidebar product-sidebar sidebar-fixed left-sidebar sticky-sidebar-wrapper"
                    id="product-sidebar"
                  >
                    <div className="sidebar-overlay"></div>
                    <a className="sidebar-close" href="#">
                      <i className="close-icon"></i>
                    </a>
                    <a href="#" className="sidebar-toggle d-flex d-lg-none">
                      <i className="fas fa-chevron-left"></i>
                    </a>
                    <div className="sidebar-content scrollable pr-3 pl-3 pt-3 pb-3">
                      {<Skeleton count={4} height={30} />}
                    </div>
                  </aside>

                  <div className="main-content">

                    <div className="row mb-3"><div className="col-md-4"> {<Skeleton count={1} height={20} />}</div> </div>


                    <div className="row cols-xl-4 cols-lg-4 cols-md-4 cols-sm-4 cols-4">
                      {dummyArray.map(() => (
                        <div className="swiper-slide category-wrap mt-20" style={{ marginBottom: "20px" }}>
                          <div className="category category-classic category-absolute overlay-zoom br-sm" style={{ padding: "10px" }}>
                            {<Skeleton count={1} height={98} />}
                            {<Skeleton count={1} height={32} />}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

        </>
      )}
      {/*    End of Main    */}
    </>
  );
};