import React, { FC, useState, ChangeEvent, useRef } from "react";
import { message, Tag, Tooltip, Input } from "antd";
import "./tag-input.scss";
import { CloseCircleFilled } from "@ant-design/icons";

type TagInputProps = {
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  value: string[];
  supplierAddresses: string[];
  onChange: (value: string[]) => void;
};

const TagInput: FC<TagInputProps> = ({
  value = [],
  onChange,
  placeholder,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [content, setContent] = useState<string>();
  const inputRef = useRef<typeof Input>(null);

  const handleDelete = (tag: string) => {
    const newArr = value.filter((i) => i !== tag);
    onChange(newArr);
    message.destroy();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  const handleBlur = () => {
    if (content) {
      const existedEmails: any = props.supplierAddresses.filter(
        (email: string) => email.toLowerCase().includes(content)
      );
      if (existedEmails.length > 0) {
        setErrorMessage(`Email '${content}' already exists in system `);
        return;
      } else {
        setErrorMessage(``);
      }
      if (value.includes(content)) {
        setErrorMessage(`Email '${content}' already entered`);
        return;
      } else {
        setErrorMessage(``);
      }
      onChange([...value, content]);
      setContent("");
    }
  };

  // 避免触发表单提交
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) =>
    (e.key === "Enter" || e.key === " " || e.key === ",") && e.preventDefault();

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      
        if (e.code === "Backspace") {
          setErrorMessage(``);
        }

      if ((e.key === "Enter" || e.key === " " || e.key === ",") && content) {
        
          if (content.length === 0) {
              setErrorMessage(``);
          }

      const existedEmails: any = props.supplierAddresses.filter(
        (email: string) => email.toLowerCase().includes(content)
      );
      if (existedEmails.length > 0) {
        setErrorMessage(`Email '${content}' already exists in system`);
        return;
      } else {
        setErrorMessage(``);
      }
      if (value.includes(content)) {
        setErrorMessage(`Email '${content}' already entered`);
        //message.warning(`[tag: ${content}] already exists`);
        return;
      } else {
        setErrorMessage(``);
      }
      onChange([...value, content]);
      setContent("");
    }
  };

  const handleClear = () => {
    onChange([]);
  };

  return (
    <div
      className={`tag-input ant-input`}
      onClick={() => inputRef.current?.focus()}
      {...props}
    >
      {value.map((item) =>
        item.length > 20 ? (
          <Tooltip title={item} key={item}>
            <Tag closable onClose={() => handleDelete(item)}>{`${item.slice(
              0,
              20
            )}...`}</Tag>
          </Tooltip>
        ) : (
          <Tag closable key={item} onClose={() => handleDelete(item)}>
            {item}
          </Tag>
        )
      )}
      <Input
        ref={inputRef}
        bordered={false}
        placeholder={!value.length ? placeholder : ""}
        className={`input`}
        value={content}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onKeyUp={handleKeyUp}
      />

      {/* TODO: handleClear */}
      <p>
        {errorMessage.length > 0 && (
          <span className="red-text">
            <CloseCircleFilled className="red-text" /> {errorMessage}
          </span>
        )}
      </p>
    </div>
  );
};

export default TagInput;
