import * as React from "react";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import ProductSimple from "./Widgets/ProductSimple";
import Snippets from "../../../../helpers/Snippets";

export default ({ productId, product, supplier, onSetProductPreviewHandler }) => {

  const [supplierProducts, setSupplierProducts] = React.useState([]);

  const loadSupplierProducts = () => {

    AxiosAPI.getSupplierProducts(supplier.id, 4).then(({ data: _products }) => {

      //console.warn(":::SUPPLIER PRODUCTS:::MORE_RELATED", _products);

      setSupplierProducts(_products.reverse());


    }).catch((error) => {

      //console.warn(":::MORE_RELATED PRODUCT ERROR:::", error);

    });

  }

  React.useEffect(() => {

    loadSupplierProducts();

  }, [supplier]);

  const generateSliderOptions = (productsNumber: number) => {
    var strOptions = "{'spaceBetween': 20, 'observer': true,'observeParents': true,'autoplay': {'delay': 4000,'disableOnInteraction': false},";
    strOptions += "'loop': true,'breakpoints': {'576': {'slidesPerView': 1},'768': { 'slidesPerView': 2},'992': {";

    strOptions += productsNumber > 4 ? "'slidesPerView': 4 }}}" : "'slidesPerView': " + productsNumber + " }}}";

    return strOptions;
  }
  return (
    <>

      <section className="vendor-product-section mt-5">
        <div className="title-link-wrapper mb-4">
          <h4 className="title text-left">More Products From This Supplier</h4>
          <a href={`supplier/${supplier.id}`} className="btn btn-dark btn-link btn-slide-right btn-icon-right">More
            Products<i className="w-icon-long-arrow-right"></i></a>
        </div>
        {supplierProducts.length > 0 && (
          <div className="swiper-container swiper-theme" data-swiper-options={`${generateSliderOptions(supplierProducts.length)}`} >
            <div className="product-scroller swiper-wrapper row cols-lg-4 cols-md-4 cols-sm-4 cols-4">

              {supplierProducts.map((product: any, swiperIndex: number) => (

                <ProductSimple key={swiperIndex} onSetProductPreview={onSetProductPreviewHandler} product={product} />

              ))}

            </div>
          </div>
        )}
      </section>

    </>
  );
};
