import { Userpilot } from "userpilot";
import Snippets from "./Snippets";
import axios from "axios";
import AxiosAPI from "./AxiosAPI";
export class UserPilotHelper {

    static initialized: boolean = false;

    static initialize() {
        Userpilot.initialize("NX-f7c6820c");
        let userData = JSON.parse( Snippets.user.getCurrentUserData() );
        if (userData) {
            Userpilot.identify(
                userData.id, // Used to identify users
                {
                    name: userData.person.firstName + " " + userData.person.lastName, // Full name
                    email: userData.email, // Email address
                    created_at: userData.createdAt, // ISO8601 Date
                    company: {
                        id: userData.profile.id, // Required, used to identify the company
                        name: userData.profile.name,
                        created_at: userData.profile.createdAt, // ISO8601 Date
                    },
                }
            );
        } else {//guest
            Userpilot.identify(
                "ANONYMOUS_USER",
                {
                    name: "Anonymous user",
                    email: "anonymous@supplyzone.ai"
                }
            );
        }
        this.initialized = true
    }

    static logEvent(event: string, meta: any) {
        AxiosAPI.apiRequest(`post`, `/rewards/v1/event`,{ event: event, meta: JSON.stringify(meta) })
        .then(()=>{
            console.log("TRACKED: ", event, meta)
        })
    }


    static triggerSellerOnBoardingCheckList() {
        let userData = JSON.parse( Snippets.user.getCurrentUserData() );
        //console.log("Seller checklist", userData)
        if (!userData) return;
        const profileId = userData.profile.id;

        var config = {
            method: "get",
            url: Snippets.urls.getAPIBaseURL() + `/profile/${profileId}/events`,
            headers: {
                Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        };
        axios(config).then(({ data: response }) => {
            const events = response as EventKeyValue[];

            let showCheckList = false;
            events.map(eventName => {
                if (eventName.key === UserPilotEvents.COMPLETE_PROFILE && !eventName.value) {
                    showCheckList = true;
                }
                if (eventName.key === UserPilotEvents.SAVE_PRODUCT && !eventName.value) {
                    showCheckList = true;
                }
            })

            if (showCheckList) {
                if (!this.initialized) {
                    this.initialize()
                }
                //console.log("TRIGGER: Seller checklist")
                Userpilot.trigger('checklist:l-71vD7faX');
            }
        });
    }

    static triggerSellerChecklist(){
        Userpilot.trigger('checklist:l-71vD7faX');
    }

}

interface EventKeyValue {
    key: UserPilotEvents | null,
    value: boolean | null
}

export enum UserPilotEvents {
    SAVE_PRODUCT = "SAVE_PRODUCT",
    SEARCH_PRODUCT = "SEARCH_PRODUCT",
    VIEW_PRODUCT = "VIEW_PRODUCT",
    LOGIN = "LOGIN",
    SIGN_UP = "SIGN_UP",
    INQUIRE_PRODUCT = "INQUIRE_PRODUCT",
    COMPLETE_PROFILE = "COMPLETE_PROFILE",
    RESPOND_TO_INQUIRY = "RESPOND_TO_INQUIRY",
    SEND_AN_RFQ = "SEND_AN_RFQ",
    SHARE_ITEMS = "SHARE_ITEMS",
    INVITE_SUPPLIER = "INVITE_SUPPLIER",
    GENERATE_PO = "GENERATE_PO",

}

export enum EventTypesButtonsInfo {
    COMPLETE_PROFILE = "Complete company profile",
    INQUIRE_PRODUCT = "When you submit an inquiry on any product, your points balance increases!",
    SHARE_ITEMS = "Share interesting products with colleagues and friends via email, WhatsApp, and social media to increase your points balance!",
    SEND_AN_RFQ = "When you submit an RFQ for any product, your points balance increases!",
    VIEW_PRODUCT = "View product",
    LOGIN = "Already logged in",
    INVITE_SUPPLIER = "When you invite a new supplier  and they successfully create an account, your points balance increases by 50 points.",
    GENERATE_PO = "When you send a purchase order (PO) to a supplier via Messenger, your points balance increases by 100 points.",
};

export enum EventTypesLabels {
    COMPLETE_PROFILE = "Complete company profile",
    INQUIRE_PRODUCT = "Go to Marketplace",
    SHARE_ITEMS = "Go to Marketplace",
    SEND_AN_RFQ = "Send an RFQ",
    VIEW_PRODUCT = "View product",
    LOGIN = "Already logged in",
    INVITE_SUPPLIER = "Invite a Supplier",
    GENERATE_PO = "Send PO",
};

export enum EventTypesButtons {
    COMPLETE_PROFILE = "Complete company profile",
    INQUIRE_PRODUCT = "Go to Marketplace",
    SHARE_ITEMS = "Go to Marketplace",
    SEND_AN_RFQ = "Send an RFQ",
    VIEW_PRODUCT = "View product",
    LOGIN = "Already logged in",
    INVITE_SUPPLIER = "Manage Invitations",
    GENERATE_PO = "Send PO",
};

export enum EventTypesButtonsUrls {
    COMPLETE_PROFILE = "/profile-wizard",
    INQUIRE_PRODUCT = "/rfq/marketplace",
    SHARE_ITEMS = "/rfq/marketplace",
    SEND_AN_RFQ =  "/rfqs/create",
    VIEW_PRODUCT = "/",
    LOGIN = "#",
    INVITE_SUPPLIER = "/supplier/invitations",
    GENERATE_PO = "/",
  };
