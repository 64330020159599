import { Button, Space } from "antd";
import React, { useState } from "react";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import { AnalyticsRFQINQ } from "./AnalyticsRFQINQ";
import { AnalyticsInvites } from "./AnalyticsInvites";
import { AnalyticsPOs } from "./AnalyticsPOs";
import { AnalyticsART } from "./AnalyticsART";

const AnalyticsSettings: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const changePassword: any = () => {
    if (newPassword !== confirmNewPassword) {
      return (window as any).Swal.fire({
        icon: "error",
        title: "Change password failed",
        text: "Your passwords do not match. Please try again",
      });
    }

    AxiosAPI.changePassword(currentPassword, newPassword)
      .then((res: any) => {
        const resultSuccess: any = res.data;

        return (window as any).Swal.fire({
          icon: "success",
          title: "Password changed",
          text: resultSuccess.message,
        });
      })
      .catch((error: any) => {
        //console.log(":: PASSWORD CHANGE ERROR ::", error);

        return (window as any).Swal.fire({
          icon: "error",
          title: "Password changing failed",
          text: "Error changing password. Check your connection",
        });
      });
  };

  return (
    <>
      <div className="card mb-5">
        <div className="card-header border-0 cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Analytics</h3>
          </div>
        </div>
        <div className="collapse show">
          <div id="kt_account_signin_method" className="collapse show">
            <div className="card-body border-top p-9">
              <div id="kt_signin_password_edit" className={"flex-row-fluid "}>
                <div className="row">
                  <div className="col-md-6">
                    <AnalyticsRFQINQ />
                  </div>

                  <div className="col-md-6">
                    <AnalyticsInvites />
                  </div>

                  <div className="col-md-6">
                    <AnalyticsPOs />
                  </div>

                  <div className="col-md-6">
                    <AnalyticsART />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AnalyticsSettings };
