import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Input,
  Row,
  Col,
  Select,
  Slider,
  InputNumber,
  SliderSingleProps,
  Radio,
  Dropdown,
  MenuProps,
  DropdownProps,
} from "antd";

import PhoneInput from "antd-phone-input";
import FormItem from "antd/es/form/FormItem";
function formatToDollar(value: any) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
}


const validator = (_:any, { valid }:any) => {
  // if (valid(true)) return Promise.resolve(); // strict validation
  if (valid()) return Promise.resolve(); // non-strict validation
  return Promise.reject("Invalid phone number");
};

const formatterDollar: NonNullable<
  SliderSingleProps["tooltip"]
>["formatter"] = (value) => `USD ${formatToDollar(value)}`;

interface BusinessProfileProps {
  isActivated: boolean;
  setCompanySize: (value: string) => void;
  companySize: string;
  setSalesRepresenative: (value: string) => void;
  salesRepresenative: string;
  setPhoneNumber: (value: string) => void;
  phoneNumber: string;
  setOrganization: (value: string) => void;
  organization: string;
  setDesignation: (value: string) => void;
  designation: string;
  setAnnualTurnOver: (value: string) => void;
  annualTurnOver: string;
}

const BusinessProfile: React.FC<BusinessProfileProps> = ({
  isActivated,
  setCompanySize,
  companySize,
  setSalesRepresenative,
  salesRepresenative,
  setPhoneNumber,
  phoneNumber,
  setOrganization,
  organization,
  setDesignation,
  designation,
  setAnnualTurnOver,
  annualTurnOver,
}) => {

  const [openx, setOpen] = useState(false);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '3') {
      setOpen(false);
    }
  };

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Clicking me will not close the menu.',
      key: '1',
    },
    {
      label: 'Clicking me will not close the menu also.',
      key: '2',
    },
    {
      label: 'Clicking me will close the menu.',
      key: '3',
    },
  ];

  return (
    <>
      <Row>
        <Col className="w-100">
          <h2 className="mt-5 mb-3">Step 2/2 : Set up a profile</h2>
          <h4 className="mt-1 mb-5">
            Showcase Your Brand with a Compelling Business Profile.
          </h4>
        </Col>
      </Row>
      <Row
        className="w-100 m-auto pt-5 pb-5"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="text-left text-align-left"
        >
          <div className="xcards category category-classic category-absolute w-100">
            <h3>Phone Number</h3>
            <p className="fs-16 mt-3">
              Enter your WhatsApp registered phone number
            </p>
            <FormItem className="mb-0" name="phone" rules={[{ validator }]}>
              <PhoneInput
                size="large"
                className="mt-4 w-100"
                placeholder="Phone Number"
                disabled={isActivated}
                onChange={(phoneValue) => {
                  setPhoneNumber(
                    `${phoneValue.countryCode}${phoneValue.areaCode}${phoneValue.phoneNumber}`
                  );
                  console.log(phoneValue);
                }}
                value={phoneNumber}
                enableSearch
                enableArrow
                disableParentheses
                searchPlaceholder="Search country"
                onlyCountries={["zw", "zm", "za", "mz", "mw"]}
                country="zw"
              />
            </FormItem>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="text-left text-align-left"
        >
          <div className="xcards category category-classic category-absolute w-100">
            <h3>Organization Name</h3>
            <p className="fs-16 mt-3">
              Enter the registered name of your organization
            </p>
            <Input
              size="large"
              className="mt-4 w-100"
              placeholder="Organization Name"
              disabled={isActivated}
              onChange={(e) => setOrganization(e.target.value)}
              value={organization}
            />
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="text-left text-align-left"
        >
          <div className="xcards category category-classic category-absolute w-100">
            <h3>Designation</h3>
            <p className="fs-16 mt-3">Select your title or designation below</p>
            <Radio.Group
              className="w-100"
              defaultValue="1-5"
              buttonStyle="solid"
              disabled={isActivated}
              onChange={(newValue: any) => {
                setDesignation(newValue.target.value);
              }}
              value={designation}
            >
              <Radio.Button value="Manager">Manager</Radio.Button>
              <Radio.Button value="Buyer">Buyer</Radio.Button>
              <Radio.Button value="Sales Rep">Sales Rep</Radio.Button>
              <Radio.Button value="Other">Other</Radio.Button>
            </Radio.Group>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="text-left text-align-left"
        >
          <div className="xcards category category-classic category-absolute w-100">
            <h3>How many employees are in your company?</h3>
            <p className="fs-16 mt-3">Select the company size below</p>
            <Radio.Group
              className="w-100"
              defaultValue="1-5"
              buttonStyle="solid"
              disabled={isActivated}
              onChange={(newValue: any) => {
                setCompanySize(newValue.target.value);
              }}
              value={companySize}
            >
              <Radio.Button value="0 - 100">0 - 100</Radio.Button>
              <Radio.Button value="100 - 500">100 - 500</Radio.Button>
              <Radio.Button value="500 - 1000">500 - 1000</Radio.Button>
              <Radio.Button value="1000+">1000+</Radio.Button>
            </Radio.Group>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="text-left text-align-left"
        >
          <div className="xcards category category-classic category-absolute w-100">
            <h3>How many sales reps in your team?</h3>
            <p className="fs-16 mt-3">Select the team size below</p>
            <Radio.Group
              className="w-100"
              defaultValue="1-5"
              buttonStyle="solid"
              disabled={isActivated}
              onChange={(newValue: any) => {
                setSalesRepresenative(newValue.target.value);
              }}
              value={salesRepresenative}
            >
              <Radio.Button value="1 - 5">1-5</Radio.Button>
              <Radio.Button value="6 - 10">6-10</Radio.Button>
              <Radio.Button value="11 - 20">11-20</Radio.Button>
              <Radio.Button value="20+">20+</Radio.Button>
            </Radio.Group>
            {/* 
            <Row className="w-100">
              <Col span={16}>
                <Slider
                  disabled={isActivated}
                  min={1}
                  step={1}
                  max={20}
                  onChange={(newValue: any) => {
                    setSalesRepresenative(newValue);
                  }}
                  value={parseInt(salesRepresenative)}
                />
              </Col>
              <Col span={8}>
                <InputNumber
                  className="w-100"
                  disabled={isActivated}
                  min={1}
                  max={20}
                  style={{ margin: "0 16px" }}
                  value={parseInt(salesRepresenative)}
                  onChange={(newValue: any) => {
                    setSalesRepresenative(newValue);
                  }}
                />
              </Col>
            </Row>
            */}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="text-left text-align-left"
        >
          <div className="xcards category category-classic category-absolute w-100">
            <h3>Annual Turnover</h3>
            <p className="fs-16 mt-3">Total revenue generated annually</p>

            <Radio.Group
              className="w-100"
              defaultValue="1-5"
              buttonStyle="solid"
              disabled={isActivated}
              onChange={(newValue: any) => {
                setAnnualTurnOver(newValue.target.value);
              }}
              value={annualTurnOver}
            >
              <Radio.Button value="$0K - $500K">$0K - $500K</Radio.Button>
              <Radio.Button value="$500K - $5M">$500K - $5M</Radio.Button>
              <Radio.Button value="$5M - $25K">$5M - $25K</Radio.Button>
              <Radio.Button value="$25M+">$25M+</Radio.Button>
            </Radio.Group>
          </div>
        </Col>
      </Row>
    </>
  );
};

export { BusinessProfile };
