import React, {FC} from 'react' 
import ProductCreatePage from './ProductCreatePage';

const ProductCreatePageWrapper: FC = () => {
  return (
    <>
      <ProductCreatePage />
    </>
  )
}

export default ProductCreatePageWrapper;
