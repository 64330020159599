import * as React from "react";
import Snippets from "../../../../../helpers/Snippets";
import { Link } from "react-router-dom";

export default ({ product, onSetProductPreview }): JSX.Element => {

  const [isECommerce, setisECommerce] = React.useState<boolean>(false);

  const [priceRange, setPriceRange] = React.useState<any>('0.00');

  React.useEffect(() => {

    //console.warn("::::: PRODUCTS PRICES ::::::", product.prices)

    if (product.prices !== null) {

      let _prices: any = product.prices.sort(Snippets.arrays.dynamicSort('-price'));

      let min = _prices[0].price;

      let max = _prices[_prices.length - 1].price;

      if (product.displayPrice === false) {
        setPriceRange("Call supplier")
      } else {
        if (min === max && parseFloat(max) > 0) {
          setPriceRange(`${min.toFixed(2)}`);
        } else {
          setPriceRange(`$${max.toFixed(2)} - $${min.toFixed(2)}`);
        }
      }
    }

  }, []);

  return (
    <>
      {/* Start of Footer */}
      <React.Fragment>

        <div className="product product-widget" >
          <figure className="product-media bg-white">
            <Link to={`/product/${Snippets.string.toSlug(product.category)}/${Snippets.string.toSlug(product.name)}/${product.id}`}>
              <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png";
              }}
                src={`https://api-live.supplyzone.ai//home/product-attachment/${product.imageUrl}`}
                className={`product-simple product-id-${product.id} ${product.imageUrl} max-height-100`}
                alt={product.name} width="185" style={{ minHeight: '100px !important' }} />
            </Link>
          </figure>
          <div className="product-details">
            <h4 className="product-name">
              <Link to={`/product/${Snippets.string.toSlug(product.category)}/${Snippets.string.toSlug(product.name)}/${product.id}`}>{product.name}</Link>
            </h4>
            <div className="sold-by">
              <a href={`/categories/${Snippets.string.toSlug(product.categoryId)}`}>{product.category}</a>
            </div>
            <div className="product-price">
              {isNaN(priceRange) ? (
                <>
                  <ins className="new-price price-range" style={{ display: 'inline-block' }}>
                    {priceRange}
                  </ins>
                </>
              ) : (
                <>
                  <ins className="new-price price-range" style={{ display: 'inline-block' }}>
                    ${priceRange}
                    <span className="new-price per-unit"> / {product.unitOfMeasurement}</span>
                  </ins>
                </>
              )}

            </div>
          </div>
        </div>

      </React.Fragment>
      {/* End of Footer */}
    </>
  );
};
