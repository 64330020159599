import { Button, Space } from "antd";
import React, { useState } from "react";
import AxiosAPI from "../../../../../helpers/AxiosAPI";


const ChangePassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState("");

    const [newPassword, setNewPassword] = useState("");

    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const changePassword: any = () => {

        if (newPassword !== confirmNewPassword) {
            return (window as any).Swal.fire({
                icon: "error",
                title: "Change password failed",
                text: "Your passwords do not match. Please try again",
            });
        }

        AxiosAPI.changePassword(currentPassword, newPassword).then((res: any) => {

            const resultSuccess: any = res.data;

            return (window as any).Swal.fire({
                icon: "success",
                title: "Password changed",
                text: resultSuccess.message,
            });

        }).catch((error: any) => {

            //console.log(":: PASSWORD CHANGE ERROR ::", error);

            return (window as any).Swal.fire({
                icon: "error",
                title: "Password changing failed",
                text: "Error changing password. Check your connection"
            });

        })

    }


    return (
        <>
            <div className="card mb-5">
                <div className="card-header border-0 cursor-pointer">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Change Your Password</h3>

                    </div>
                </div>
                <div className="collapse show">
                    <div id="kt_account_signin_method" className="collapse show">
                        <div className="card-body border-top p-9">
                            <div id="kt_signin_password_edit" className={"flex-row-fluid "}>
                                <form
                                    id="kt_signin_change_password"
                                    className="form"
                                    noValidate
                                >
                                    <div className="row mb-1">

                                        <div className="col-lg-4">
                                            <div className="fv-row mb-0">
                                                <label
                                                    htmlFor="currentpassword"
                                                    className="form-label fs-6 fw-bolder mb-3"
                                                >
                                                    Current Password
                                                </label>
                                                <input
                                                    value={currentPassword}
                                                    onChange={(e) => {
                                                        setCurrentPassword(e.target.value);
                                                    }}
                                                    type="password"
                                                    className="form-control form-control-lg form-control-solid "
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="fv-row mb-0">
                                                <label
                                                    htmlFor="currentpassword"
                                                    className="form-label fs-6 fw-bolder mb-3"
                                                >
                                                    New Password
                                                </label>
                                                <input
                                                    value={newPassword}
                                                    onChange={(e) => {
                                                        setNewPassword(e.target.value);
                                                    }}
                                                    type="password"
                                                    className="form-control form-control-lg form-control-solid "
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="fv-row mb-0">
                                                <label
                                                    htmlFor="currentpassword"
                                                    className="form-label fs-6 fw-bolder mb-3"
                                                >
                                                    Confirm New Password
                                                </label>
                                                <input
                                                    value={confirmNewPassword}
                                                    onChange={(e) => {
                                                        setConfirmNewPassword(e.target.value);
                                                    }}
                                                    type="password"
                                                    className="form-control form-control-lg form-control-solid "
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-text mb-5">
                                        Password must be at least 8 character and contain symbols
                                    </div>

                                    <Space direction="horizontal" size={20}>
                                        <Button type="primary" onClick={(e: any) => { changePassword(e); }}>
                                            Submit
                                        </Button>
                                        <Button  type="default" >Cancel</Button>
                                    </Space>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { ChangePassword };