/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import ProductDetailsMoreRelatedProducts from "./ProductDetailsMoreRelatedProducts";
import ProductDetailsFrequentlyBoughtTogether from "./ProductDetailsFrequentlyBoughtTogether";
import ProductDetailsInformationTabs from "./ProductDetailsInformationTabs";
import ProductsAside from "./ProductsAside";
import ProductDetailsGallery from "./ProductDetailsGallery";
import ProductDetailsCartActionPanel from "./ProductDetailsCartActionPanel";
import Confetti from 'react-confetti'
import {
  UserPilotEvents,
  UserPilotHelper,
} from "../../../../helpers/UserPilotHelper";
import { Button, Col, Dropdown, MenuProps, Modal, Row, Select, Space, Tooltip, message } from "antd";
import {
  CopyOutlined,
  FacebookFilled,
  FlagOutlined,
  InstagramOutlined,
  LikeFilled,
  LikeOutlined,
  LinkedinFilled,
  MailOutlined,
  PhoneFilled,
  PhoneOutlined,
  PrinterOutlined,
  ShareAltOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useLocalStorage } from "usehooks-ts";
import { useAppDataContext } from "../../../../data";



export default () => {
  const {
    userDisplayName,
    userEmailAddress,
    isLoggedIn,
    userData,
    setIsLoginModalOpen
  } = useAppDataContext();

  //update id on router link change
  const pageLocation = useLocation();

  let { id } = useParams();

  const cart_action_panel: any = React.useRef(null);

  const productPlaceHolder = {
    id: 0,
    name: "",
    optimisationKeywords: "",
    modelNumber: "",
    shortDescription: "",
    description: "",
    productFileAttachments: [{}],
    minimumOrderQuantity: 0,
    unitOfMeasurement: "KG",
    fobPort: "",
    orderLeadTimeRangeFrom: "",
    orderLeadTimeRangeTo: "",
    paymentMethod: "CASH",
    areSmallOrdersAccepted: true,
    dimensionPerUnit: {
      length: 0,
      width: 0,
      height: 0,
      unitOfMeasurement: "KG",
    },
    weightPerUnit: {
      size: 0,
      unitOfMeasurement: "KG",
    },
    exportCartonDimension: {
      length: 0,
      width: 0,
      height: 0,
      unitOfMeasurement: "KG",
    },
    exportCartonWeight: {
      size: 0,
      unitOfMeasurement: "KG",
    },
    usHtsCode: "",
    brandName: "",
    origin: "",
    exportMarket: "",
    category: {
      id: 0,
      name: "",
      icon: "",
      image: "",
    },
    status: "DRAFT",
    pricingOption: "LADDER_PRICE",
    productPrices: [
      {
        id: 0,
        minimumOrderQuantity: 0,
        maximumOrderQuantity: 0,
        price: 0,
      },
    ],
    supplierId: 0,
    likesCount: 0
  };

  const onSetProductPreviewHandler = (params: any) => {
    //console.warn(":::: onSetProductPreviewHandler ::::", params);
  };

  const [productId, setProductId] = useState(id);

  const [product, setProduct] = useState(productPlaceHolder);

  const [supplier, setSupplier] = useState({});

  const [productLiked, setProductLiked] = useState(false)


  const fetchIsLikedAndFollowed = () => {

    if (product.id && isLoggedIn) {
      AxiosAPI.publicApiRequest('GET', `/product-statistics/product-liked?productId=${product.id}&appUserId=${userData.id}`, null)
        .then(({ data: response }) => {
          setProductLiked(response.success)
        })

    }

  }

  const [confettiShown, setConfettiShown] = useState(false)
  const [likeAfterSignIn, setLikeAfterSignIn] = useState(false)
  const likeProduct = () => {
    if (product.id && isLoggedIn) {
      setLikeAfterSignIn(false)
      AxiosAPI.publicApiRequest('POST', `/product-statistics/like-product`,
        { appUserId: userData.id, productId: product.id }
      )
        .then(({ data: response }) => {
          setProductLiked(response.success)
          if (response.success) {

            const updatedProduct = product;
            updatedProduct.likesCount = updatedProduct.likesCount + 1
            setProduct(updatedProduct)
            setConfettiShown(true)

            setTimeout(function () {
              setConfettiShown(false)
            }, 5000)
          } else {
            const updatedProduct = product;
            updatedProduct.likesCount = updatedProduct.likesCount - 1
            setProduct(updatedProduct)
          }
        })

    } else {
      setIsLoginModalOpen(true)
      setLikeAfterSignIn(true)
    }
  }


  const loadProduct = () => {
    AxiosAPI.getProductDetails(productId)
      .then(({ data: productItem }) => {
        //console.warn(":::PRODUCT:::", productItem);

        setProduct(productItem);

        loadSupplierDetails(productItem.supplierId);

        UserPilotHelper.logEvent(UserPilotEvents.VIEW_PRODUCT, {
          product_id: productItem.id,
          product_name: productItem.name,
          product_model: productItem.modelNumber,
          product_category: productItem.category.name,
          product_description: productItem.description.substr(0, 20),
        });
        
      })
      .catch((error) => {
        //console.warn(":::PRODUCT ERROR:::", error);
      });
  };

  React.useEffect(() => {
    const pageParts = pageLocation.pathname.split("/");
    setProductId(pageParts[pageParts.length - 1])
  }, [pageLocation])
  React.useEffect(() => {
    console.log(`New product id ${productId}`)
    loadProduct()
  }, [productId])
  const loadSupplierDetails = (supplierId: number) => {
    AxiosAPI.getSupplierDetails(supplierId)
      .then(({ data: supplierDetails }) => {
        //console.warn(":::SUPPLIER DETAILS:::", supplierDetails);

        const _supplier = {
          country: "Zimbabwe",
          years: 2,
          rating: 4,
          response: "≤24 h",
          business:
            "Business Type: Agent, Buying Office, Consultant, Distributor ...",
        };

        setSupplier({ ..._supplier, ...supplierDetails });
      })
      .catch((error) => {
        //console.warn(":::PRODUCT ERROR:::", error);
      });
  };

  useEffect(() => {
    //console.log("::: PAGE ID :::", id);

    setProductId(id);

    setTimeout(() => {
      loadProduct();
    }, 100);
  }, [id]);

  const [categoryTree, setCategoryTree] = useState([])
  useEffect(() => {
    if (product.category.id > 0) {

      AxiosAPI.publicApiRequest('get', `/model/category-hierarchy/${product.category.id}`, {})
        .then(({ data: categories }) => {
          setCategoryTree(categories.filter((category: any) => { return category.id != 0 }))
        })
        .catch((error) => {

        });

    }

  }, [product])

  const [shareViaWhatsApp, setShareViaWhatsApp] = useState("");
  const [shareViaFacebook, setShareViaFacebook] = useState("");
  const [shareViaTwitter, setShareViaTwitter] = useState("");
  const [shareViaInstagram, setShareViaInstagram] = useState("");
  const [shareViaLinkedIn, setShareViaLinkedIn] = useState("");

  useEffect(() => {

    setShareViaWhatsApp(`https://api.whatsapp.com/send?text=${product.name} ${window.location.href}`);
    setShareViaFacebook(`https://www.facebook.com/sharer/sharer.php?app_id=1217981644879628&u=${window.location.href.replace('http://localhost:3000', 'https://commecial.supplyzone.ai')}&igshid=MDc3MzNhZDY0Ng==`);
    setShareViaTwitter(`https://twitter.com/share?url=${window.location.href}&text=${product.name}&via=#WebsiteBrowser&hashtags=#SupplyZone`);
    setShareViaInstagram(`https://www.instagram.com/sharer/sharer.php?app_id=1217981644879628&u=${window.location.href.replace('http://localhost:3000', 'https://commecial.supplyzone.ai')}&igshid=MDc3MzNhZDY0Ng==`);
    setShareViaLinkedIn(`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`);

    fetchIsLikedAndFollowed()

  }, [product]);

  useEffect(() => {
    fetchIsLikedAndFollowed()

    if (isLoggedIn && likeAfterSignIn) {
      likeProduct()
    }
  }, [isLoggedIn])

  const mailTo: any = () => {

    const email = 'example@example.com';
    const subject = 'Hey, check out this product';
    const body = product.name + ' from ' + window.location.href;

    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoUrl);

  }

  const [messageApi, contextHolder] = message.useMessage();

  const copyLink: any = () => {

    navigator.clipboard.writeText(window.location.href);

    messageApi.info("Link copied to clipboard");

  }

  const itemsShare: MenuProps["items"] = [
    {
      key: "0",
      icon: <WhatsAppOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={shareViaWhatsApp}
        >
          Share via WhatsApp
        </a>
      ),
    },
    {
      key: "1",
      icon: <FacebookFilled />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={shareViaFacebook}
        >
          Share via Facebook
        </a>
      ),
    },
    {
      key: "2",
      icon: <TwitterOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={shareViaTwitter}
        >
          Share via Twitter
        </a>
      ),
    },
    {
      key: "3",
      icon: <InstagramOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={shareViaInstagram}
        >
          Share via Instagram
        </a>
      ),
    },
    {
      key: "4",
      icon: <LinkedinFilled />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={shareViaLinkedIn}
        >
          Share via Linked In
        </a>
      ),
    },
    {
      key: "9A",
      icon: <CopyOutlined />,
      label: <a onClick={copyLink}>Copy Link</a>,
    },
  ];

  const itemsWhatsApp: MenuProps["items"] = [
    {
      key: "1",
      icon: <WhatsAppOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"https://wa.me/+263772128622?text=Test"}
        >
          WhatsApp us on +263 772 123 456
        </a>
      ),
    },
  ];

  const reportProblem: any = () => {

    setModal1Open(true);

  }

  const [modal1Open, setModal1Open] = useState(false);
  const [isFollowing, setIsFollowing] = useLocalStorage('liking-product', false);

  const [feedback, setFeedback] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const sendFeedback = () => {

    if (feedback === "" || feedbackMessage === "") {
      return
    }

    let senderEmail = "anonymous"
    let senderName = "Anonymous"
    if (isLoggedIn) {
      senderEmail = userEmailAddress
      senderName = userDisplayName
    }
    AxiosAPI.publicApiRequest('post', '/marketplace/send-feedback',
      {
        "senderEmail": senderEmail,
        "senderName": senderName,
        "feedback": feedback,
        "feedbackMessage": feedbackMessage + ' \n' + window.location.href
      }).then(() => {

        messageApi.open({
          type: "success",
          content: "Feedback sent!",
          duration: 2,
        })
        setModal1Open(false)
      })
  }

  return (
    <>
      {/*    Start of Main    */}
      {product.hasOwnProperty("name") && (
        <>
          {/*    Start of Breadcrumb    */}

          {contextHolder}

          <div className="breadcrumb-container container">
            <div className="product-navigation">
              <ul className="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="delimiter">&gt;</li>

                {categoryTree.map((category: any) => (
                  <>
                    <li>
                      <a
                        href={`/categories/${category.id}`}
                      >
                        {category.name}
                      </a>
                    </li>
                  </>
                ))}

                <li className="delimiter">&gt;</li>
                <li>{product.name}</li>
              </ul>
              <Space className="mt-2" direction="horizontal" size={5}>

                <Button className="ml-1" type="default" size="small" icon={productLiked ? <LikeFilled /> : <LikeOutlined />} onClick={likeProduct}>{productLiked ? "Liked" : "Like"}</Button>
                {confettiShown && (
                  <Confetti />
                )}
                <Dropdown menu={{ items: itemsShare }} arrow >
                  <Button className="ml-1" size="small" type="default">
                    <ShareAltOutlined /> Share
                  </Button>
                </Dropdown>

                {/*

                <Button className="ml-1" size="small" type="default" onClick={() => { mailTo(); }}>
                  <MailOutlined /> Email
                </Button>


                <Button className="ml-1" size="small" type="default" onClick={() => { window.print(); }}>
                  <PrinterOutlined /> Print
                </Button>

                <Button className="ml-1" size="small" type="default" onClick={reportProblem}>
                  <FlagOutlined /> Send Feedback
                </Button>

                */}

              </Space>
            </div>
          </div>

          {/*    End of Breadcrumb    */}
          <main
            id="main"
            className="main mb-10 pb-1"
            style={{ backgroundColor: "rgb(245,245,245)", padding: "20px 0" }}
          >
            {/*    Start of Page Content    */}
            <div className="page-content">
              <div className="container">
                <div className="row gutter-lg">
                  <div className="main-content">
                    <div className="product product-single row bg-white xcards pr-3 pl-3 pt-3 pb-0">
                      <ProductDetailsGallery product={product} />
                      <ProductDetailsCartActionPanel
                        ref={cart_action_panel}
                        product={product}
                        supplier={supplier}
                      />
                    </div>
                    <ProductDetailsInformationTabs
                      product={product}
                      supplier={supplier}
                    />
                    <ProductDetailsMoreRelatedProducts
                      onSetProductPreviewHandler={onSetProductPreviewHandler}
                      productId={product.id}
                      product={product}
                      supplier={supplier}
                    />
                    {/* <ProductDetailsFrequentlyBoughtTogether
                        productId={product.id}
                      /> */}
                  </div>
                  {/*    End of Main Content    */}
                  {supplier.hasOwnProperty("name") && (
                    <ProductsAside
                      onSetProductPreviewHandler={onSetProductPreviewHandler}
                      supplier={supplier}
                    />
                  )}
                  {/*    End of Sidebar    */}
                </div>
              </div>
            </div>
            {/*    End of Page Content    */}
          </main>
        </>
      )}
      {/*    End of Main    */}


      <Modal
        title="Send Feedback"
        okText="Send"
        style={{ top: 20 }}
        open={modal1Open}
        onOk={() => sendFeedback()}
        onCancel={() => setModal1Open(false)}
        centered
      >
        <p className="mt-3 mb-3">For immediate assistance, please visit the SZ Help Desk. </p>

        <p><Select
          defaultValue=""
          style={{ width: 220 }}
          allowClear
          options={[
            { value: '', label: 'Select option' },
            { value: 'Report a problem', label: 'Report a problem' },
            { value: 'Product image feedback', label: 'Product image feedback' },
            { value: 'Product description feedback', label: 'Product description feedback' },
            { value: 'Supplier information feedback', label: 'Supplier information feedback' },
          ]}
          onChange={(value) => {
            setFeedback(value);
          }}

        /></p>
        <p className="mt-3 mb-1">Please tell us about your experience.</p>
        <p><TextArea rows={5} onChange={(e: any) => { setFeedbackMessage(e.target.value) }} /></p>
      </Modal>
    </>
  );
};
