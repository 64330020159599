import React, { FC } from "react";
import { KommandView } from "./KommandView";

const KommandPageWrapper: any = () => {
  return (
    <>
      <div className="mr-3 ml-3 mt-0">
        <main className="main kommand">
          <div className="container pt-2">
            <div className="product-wrapper-1  mb-1">
              <div className="title-link-wrapper pb-2 mb-1">
                <h2 className="title ls-normal mb-0">Kommand</h2>
              </div>
            </div>
            <KommandView />
          </div>
        </main>
      </div>
    </>
  );
};

export default KommandPageWrapper;
