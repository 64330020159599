/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import "./search-card.scss";
import { useState, useEffect, useRef } from "react";
import { Input } from "antd";

export default ({ placeholder, data, width, zIndex, selectedItem, children }) => {

  console.log(":::: > SELECTED", data);


  const [currentListing, setCurrentListing] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentListing(data);
  }, [data]);

  const [currentPlaceholder, setCurrentPlaceholder] = useState();

  useEffect(() => {
    setCurrentPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectLineItem:any = (item:any)=>{

    setShowDropdown(false);

    selectedItem(item);

    if('label' in item){
      setCurrentPlaceholder(item.label);
    }else{
      setCurrentPlaceholder(item.name);
    }

  }

  return (
    <>
      <div className="search-card-wrapper">
        <div
          style={{
            zIndex: zIndex,
            maxWidth: width,
            width: "100%",
            left: "calc(50% + 55px)",
            position: "relative",
          }}
          className="AyKEed"
          ref={componentRef}
        >
          <div
            data-is-full-screen={false}
            data-is-fixed={false}
            data-left-icon={true}
            data-placeholder="What are you looking for?"
            data-show-custom-asset-options={false}
            className="M52nVb ytPNkd E6c19c"
          >
            <span className="eEMh0d" aria-hidden="true">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable={false}
                className=" NMm5M"
              >
                <path d="M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5A6.5 6.5 0 1 0 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z" />
              </svg>
            </span>
            <div
              className={`L6J0Pc ZB3Ebc nz7KN ${showDropdown ? "focused" : ""}`}
              data-close-on-blur={true}
              data-renderer="fZUdHf"
            >
              <div
                className="d1dlne"
                style={{ position: "relative" }}
                data-placeholder={currentPlaceholder}
                data-activedescendants={true}
                data-expanded={true}
              >
                <Input
                  className="Ax4B8 ZAGvjd"
                  type="text"
                  autoComplete="off"
                  aria-label={currentPlaceholder}
                  aria-autocomplete="list"
                  aria-haspopup="true"
                  aria-expanded="true"
                  role="combobox"
                  aria-controls="nngdp204"
                  placeholder={currentPlaceholder}
                  dir="ltr"
                  data-focusid={292}
                  readOnly
                  onClick={() => setShowDropdown(true)}
                />
              </div>
              <div className=" tWfTvb">
                <div
                  className={`u3WVdc jBmls ${showDropdown ? "" : "d-none"}`}
                  tabIndex={-1}
                  role="listbox"
                  data-expanded="true"
                  data-childcount={5}
                  id="nngdp204"
                  style={{ maxHeight: 1062 }}
                >
                  <div className="mLoZRc">
                    <div>
                      <div  style={{
                        maxHeight: 400,
                        overflowX: "hidden",
                        overflowY: "auto"
                      }}>
                        {currentListing.map((item: any, itemIndex: number) => (
                          <div
                            key={itemIndex}
                            role="option"
                            className="MkjOTb SAq8ff"
                            onClick={() => selectLineItem(item)}
                          >
                            <div>
                              <div className="onRPD p20">
                                <div>
                                  <div className="CrPloe">{item.label || item.name}</div>
                                  <div>
                                    <span className="S4qG1c">
                                      <span className="Npx9Xe">
                                        {item.subtitle}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                { 'productsCount' in item && (
                                <div className="ZilHG">
                                  <div className="lzO0Ed">{item.productsCount}</div>
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
