/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Button, Checkbox, Col, Input, Modal, Row, Form, Select } from "antd";

import {
  UserPilotHelper,
  UserPilotEvents,
} from "../../../helpers/UserPilotHelper";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useAppDataContext } from "../../../data";
import AxiosAPI from "../../../helpers/AxiosAPI";
import { useParams, useSearchParams } from "react-router-dom";
import { values } from "lodash";

const isValidEmailAddress = (email: any) => {
  var re = /\S+@\S+\.\S+/;
  return !re.test(email);
};

type WidgetRFCProps = {
  setEmailAddress: Function;
  validateEmailAddress: React.FocusEventHandler<any> | undefined;
  validationErrorEmailAddress: boolean;
  emailAddress: string;
  businessType: string;
  setBusinessType: Function;
  setInviteFromId: Function;
  password: string;
  setPassword: Function;
  validatePassword: React.FocusEventHandler<any> | undefined;
  validationErrorPassword: boolean;
  password2: string;
  setPassword2: Function;
  validatePassword2: any | undefined;
  validationErrorPassword2: boolean;
  children?: React.ReactNode;
  setFirstName: Function;
  setLastName: Function;
  firstName: string;
  lastName: string;
};

type FooterRFCProps = {
  CancelBtn?: any;
  agreeToTerms: boolean;
  setAgreeToTerms: Function;
  enableLoginButton?: boolean;
  onLoginButtonClick?: Function;
  attemptRegister: Function;
  ajaxBusy: boolean;
  emailAddress: string;
  businessType: string;
  password: string;
  password2: string;
  mfaCode: string | number;
  children?: React.ReactNode;
};

type BodyRFCProps = {
  onRegisterSuccess?: Function;
  onRegisterError?: Function;
  setInviteFromId?: Function;
  onLoginButtonClick?: Function;
  autoLogin?: boolean;
  enableLoginButton?: boolean;
  onClose: any;
  isOpened?: boolean;
  isModal?: boolean;
  modalTitle: string;
  profileType: string;
  children?: React.ReactNode;
};

const RegisterWidget: React.FC<WidgetRFCProps> = ({
  children,
  ...props
}): any | null => {
  const {
    setEmailAddress,
    validateEmailAddress,
    validationErrorEmailAddress,
    emailAddress,
    password,
    setPassword,
    validatePassword,
    validationErrorPassword,
    password2,
    setPassword2,
    validatePassword2,
    validationErrorPassword2,
    setInviteFromId,
    firstName,
    setFirstName,
    lastName,
    setLastName,
  } = props;
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(searchParams.get("token"));

  useEffect(() => {
    if (token) {
      AxiosAPI.publicApiRequest("GET", `/register/supplier-invite/${token}`, {})
        .then(({ data: invite }) => {
          setEmailAddress(invite.email);
          window.localStorage.setItem("inviteFromId", invite.inviteFromId);
          window.localStorage.setItem("isInternalInvite", invite.isInternalInvite ? "yes" : "no");
          window.localStorage.setItem("inviteProfileType", invite.profileType);
        })
        .catch((error) => {});
    }
  }, [token]);

  return (
    <Form layout="vertical" name="registration-form">
      <Row className="mt-4">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            name="emailAddressReadonly"
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Please enter your email address",
              },
            ]}
            initialValue={emailAddress}
          >
            <Input
              type="email"
              name="emailAddressReadonly"
              size="large"
              className="mb-0"
              placeholder={`${emailAddress}`}
              value={emailAddress}
              prefix={<UserOutlined className="site-form-item-icon"/>}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              disabled 
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="firstName"
            label="First name"
            rules={[
              {
                required: true,
                message: "Please enter your first name",
              },
            ]}
            initialValue={firstName}
          >
            <Input
              allowClear
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              type="text"
              placeholder="First name"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="lastName"
            label="Last name"
            rules={[
              {
                required: true,
                message: "Please enter your last name",
              },
            ]}
            initialValue={lastName}
          >
            <Input
              allowClear
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              type="text"
              placeholder="Last name"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
            hasFeedback={password.length > 1}
            validateStatus={validationErrorPassword ? "error" : "success"}
            initialValue={password}
          >
            <Input.Password
              allowClear
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              onBlur={validatePassword}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="password2"
            dependencies={["password"]}
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
            hasFeedback={password2.length > 1}
            validateStatus={validationErrorPassword2 ? "error" : "success"}
            initialValue={password2}
          >
            <Input.Password
              allowClear
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm password"
              onBlur={(e) => { validatePassword2(e) }}
              onChange={(e) => {
                setPassword2(e.target.value);
                setTimeout(() => {
                  validatePassword2(e);
                }, 500);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item className="mb-1" name="agreeToTerms">
            <p>
              Your personal data will be used to support your experience
              throughout this website, to manage access to your account, and for
              other purposes described in our{" "}
              <a href="/legal/privacy" target="_blank">
                privacy policy
              </a>
            </p>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const FooterArea: React.FC<FooterRFCProps> = ({
  children,
  ...props
}): any | null => {
  const {
    CancelBtn,
    agreeToTerms,
    setAgreeToTerms,
    enableLoginButton,
    onLoginButtonClick,
    attemptRegister,
    ajaxBusy,
    emailAddress,
    businessType,
    password,
    password2,
    mfaCode,
  } = props;
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(searchParams.get("token"));
  return (
    <>
      <Row className="i-agree">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            className="mb-1"
            name="agreeToTerms"
            rules={[
              {
                required: true,
                message: "You must accept our terms and conditions.",
              },
            ]}
          >
            <Checkbox
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
            >
              I agree to the{" "}
              <a href="/legal/privacy" target="_blank">
                privacy policy
              </a>
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item className="mb-1" name="agreeToTerms">
            {children}
          </Form.Item>
        </Col>
      </Row>
      <hr />
      {CancelBtn && <CancelBtn />}
      {enableLoginButton && (
        <Button
          type="default"
          size="large"
          onClick={() => onLoginButtonClick()}
        >
          Login
        </Button>
      )}
      <Button
        type="primary"
        htmlType="submit"
        disabled={
          !(
            emailAddress.length > 1 &&
            password.length > 3 &&
            password2.length > 3 &&
            password === password2 &&
            String(`${token}`).length > 6 &&
            agreeToTerms
          )
        }
        loading={ajaxBusy}
        size="large"
        onClick={attemptRegister}
      >
        Register and continue
      </Button>
    </>
  );
};

export const RegisterComponent: React.FC<BodyRFCProps> = ({
  children,
  ...props
}): any | null => {
  /// DATA CONTEXT

  const { registerUser, loginUser } = useAppDataContext();

  const {
    onRegisterSuccess,
    onRegisterError,
    setInviteFromId,
    onLoginButtonClick,
    enableLoginButton,
    onClose,
    isOpened,
    isModal,
    modalTitle,
    profileType,
    autoLogin,
  } = props;

  /// END DATA CONTEXT

  const [modalOpen, setModalOpen] = useState(false);
  const [ajaxBusy, setAjaxBusy] = React.useState(false);

  useEffect(() => {
    if (isOpened) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }

    return () => {
      setModalOpen(false);
    };
  }, [isOpened]);

  const [validationErrorEmailAddress, set_validationErrorEmailAddress] =
    React.useState(false);

  const validateEmailAddress = (e: any) => {
    set_validationErrorEmailAddress(isValidEmailAddress(e.target.value));
  };

  const [validationErrorPassword, set_validationErrorPassword] =
    React.useState(false);

  const validatePassword = (e: any) => {
    set_validationErrorPassword(password === "" || password.length < 4);
  };

  const [validationErrorPassword2, set_validationErrorPassword2] =
    React.useState(false);

  const validatePassword2 = (e: any) => {
    let isError: boolean = false;
    let _password2: string = e.target.value;
    if (_password2 === "" || _password2.length < 1) {
      isError = true;
    }
    if (_password2 !== password) {
      isError = true;
    }
    console.log("::isError::", isError);
    set_validationErrorPassword2(isError);
  };

  /////

  const [agreeToTerms, setAgreeToTerms] = React.useState(false);

  const [lastName, setLastName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [businessType, setBusinessType] = React.useState("businessType");
  const [mfaCode, setMfaCode] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(searchParams.get("token"));
  const emailValidatedSuccessfully: any = (): boolean => {
    if (emailAddress === "") {
      set_validationErrorEmailAddress(true);
      (window as any).Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Email address cannot be blank",
      });
      return false;
    } else {
      set_validationErrorEmailAddress(false);
    }

    if (isValidEmailAddress(emailAddress)) {
      set_validationErrorEmailAddress(true);
      (window as any).Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid email address!",
      });
      return false;
    } else {
      set_validationErrorEmailAddress(false);
    }

    if (validationErrorEmailAddress) {
      (window as any).Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid email address!",
      });
      return false;
    } else {
      set_validationErrorEmailAddress(false);
    }

    return true;
  };

  const attemptRegister = () => {
    if (!emailValidatedSuccessfully()) {
      return;
    }

    if (token) {
      if (password === "" || password.length < 4) {
        (window as any).Swal.fire({
          icon: "error",
          title: "Validation Error.",
          text: "Please enter your password",
        });
        return;
      }

      if (password !== password2) {
        (window as any).Swal.fire({
          icon: "error",
          title: "Validation Error.",
          text: "Passwords do not match",
        });
        return;
      }

      if (!agreeToTerms) {
        (window as any).Swal.fire({
          icon: "error",
          title: "Validation Error.",
          text: "You must accept terms and services",
        });
        return;
      }

      setAjaxBusy(true);

      const registrationRequest = {
        email: emailAddress,
        profileType: (window.localStorage.getItem("inviteProfileType")) ? window.localStorage.getItem("inviteProfileType") :  profileType.toUpperCase(),
        businessType: "COMPANY",
        token: token,
        password: password,
        firstName: firstName,
        lastName: lastName,
      };

      //console.log("ON REGISTER USER::::", registrationRequest);

      registerUser(
        registrationRequest,
        (result?: any) => {
          setAjaxBusy(false);

          setModalOpen(false);

          UserPilotHelper.logEvent(UserPilotEvents.SIGN_UP, {});

          if (autoLogin) {
            setAjaxBusy(true);

            loginUser(
              emailAddress,
              password,
              (result?: any) => {
                setAjaxBusy(false);

                UserPilotHelper.logEvent(UserPilotEvents.LOGIN, {});

                isOpened &&
                  typeof onRegisterSuccess === "function" &&
                  onRegisterSuccess(result.data);
              },
              (error: any, swalPayload: any) => {
                setAjaxBusy(false);

                isOpened &&
                  typeof onRegisterError === "function" &&
                  onRegisterError(error);

                //console.log("ERROR: attemptRegister :", error);

                swalPayload && (window as any).Swal.fire(swalPayload);
              }
            );
          } else {
            isOpened &&
              typeof onRegisterSuccess === "function" &&
              onRegisterSuccess(result.data);
          }
        },
        (error: any, swalPayload: any) => {
          setAjaxBusy(false);

          isOpened &&
            typeof onRegisterError === "function" &&
            onRegisterError(error);

          //console.log("ERROR: attemptRegister :", error);

          swalPayload && (window as any).Swal.fire(swalPayload);
        }
      );
    }
  };

  return (
    <>
      {isModal ? (
        <Modal
          open={modalOpen}
          onCancel={onClose}
          title={modalTitle || "Register to continue"}
          centered
          closable
          destroyOnClose
          className="antdx-register-modal"
          style={{ zIndex: 1999 }}
          cancelButtonProps={{ disabled: false, size: "large" }}
          footer={(_, { CancelBtn }) => (
            <>
              <FooterArea
                CancelBtn={CancelBtn}
                agreeToTerms={agreeToTerms}
                setAgreeToTerms={setAgreeToTerms}
                enableLoginButton={enableLoginButton}
                onLoginButtonClick={onLoginButtonClick}
                attemptRegister={attemptRegister}
                ajaxBusy={ajaxBusy}
                emailAddress={emailAddress}
                businessType={businessType}
                password={password}
                password2={password2}
                mfaCode={mfaCode}
              />
            </>
          )}
        >
          <RegisterWidget
            setEmailAddress={setEmailAddress}
            validateEmailAddress={validateEmailAddress}
            validationErrorEmailAddress={validationErrorEmailAddress}
            emailAddress={emailAddress}
            setInviteFromId={setInviteFromId}
            businessType={businessType}
            setBusinessType={setBusinessType}
            password={password}
            setPassword={setPassword}
            validatePassword={validatePassword}
            validationErrorPassword={validationErrorPassword}
            password2={password2}
            setPassword2={setPassword2}
            validatePassword2={validatePassword2}
            validationErrorPassword2={validationErrorPassword2}
            firstName={firstName}
            lastName={lastName}
            setFirstName={setFirstName}
            setLastName={setLastName}
          ></RegisterWidget>
        </Modal>
      ) : (
        <>
          <Row>
            <Col span={24} className="antdx-register-modal">
              <RegisterWidget
                setEmailAddress={setEmailAddress}
                validateEmailAddress={validateEmailAddress}
                validationErrorEmailAddress={validationErrorEmailAddress}
                emailAddress={emailAddress}
                businessType={businessType}
                setBusinessType={setBusinessType}
                password={password}
                setPassword={setPassword}
                validatePassword={validatePassword}
                validationErrorPassword={validationErrorPassword}
                password2={password2}
                setPassword2={setPassword2}
                validatePassword2={validatePassword2}
                validationErrorPassword2={validationErrorPassword2}
                firstName={firstName}
                lastName={lastName}
                setFirstName={setFirstName}
                setLastName={setLastName}
              ></RegisterWidget>
              <FooterArea
                agreeToTerms={agreeToTerms}
                setAgreeToTerms={setAgreeToTerms}
                enableLoginButton={enableLoginButton}
                onLoginButtonClick={onLoginButtonClick}
                attemptRegister={attemptRegister}
                ajaxBusy={ajaxBusy}
                emailAddress={emailAddress}
                businessType={businessType}
                password={password}
                password2={password2}
                mfaCode={mfaCode}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
