/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import queryString from 'query-string';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Snippets from "../../../../helpers/Snippets";
import { uomsList } from "../../../uoms/uom";
import fetchInstance from "../../../../helpers/AxiosAPI";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import Swal from "sweetalert2";
import {
  UserPilotHelper,
  UserPilotEvents,
} from "../../../../helpers/UserPilotHelper";
import { useAppDataContext } from "../../../../data";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Modal as ModalAntd,
  Row,
  Select,
} from "antd";
import { useState } from "react";
import { LoginComponent } from "../../layout/LoginComponent";
import { RegisterComponent } from "../../layout/RegisterComponent";
import { LikeFilled, LikeOutlined, EyeOutlined } from "@ant-design/icons";

import TextArea from "antd/es/input/TextArea";
import { InquirySubmitModal } from "./InquirySubmitModal";

export default ({ product, supplier }: any) => {
  const [form] = Form.useForm();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "96vh",
      width: "55%",
      overflow: "hidden",
    },
  };

  const inputFieldsStyle = {
    border: "1px solid #888",
    color: "#413f3f",
  };

  const [supplierCount, setSupplierCount] = React.useState(0);

  const [inquiryQuantity, setInquiryQuantity] = React.useState(1);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);

  const [isRegisterModalOpen, setIsRegisterModalOpen] = React.useState(false);

  const [emailAddress, setEmailAddress] = React.useState("");

  const [details, setDetails] = React.useState("");

  const {
    setIsLoggedIn: setIsLoggedInUser,
    isLoggedIn: isLoggedInUser,
    userType,
    loginUser,
    userData, userProfile,
    registerUser,
    userEmailAddress,
  } = useAppDataContext();

  React.useEffect(() => {
    setEmailAddress(userEmailAddress);
  }, [userEmailAddress]);

  const showLoginPopup: any = () => {
    //

    //console.log("Show Login Popup");
  };

  function openModal() {
    if (isLoggedInUser) {
      if (userType === "SELLER") {
        setIsModalOpen(false);
        sellerCannotSubmitEnquiry();
      } else {
        setIsModalOpen(true);
        document.getElementById("details") &&
          document.getElementById("details").focus();
      }
    } else {
      setIsModalOpen(true);
    }
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsModalOpen(false);
  }

  const [validationErrorEmailAddress, set_validationErrorEmailAddress] =
    useState(false);

  function openLoginModal(force:boolean=false) {

    if (force) {
      return setIsLoginModalOpen(true);
    }

    if (validationErrorEmailAddress) {
      return;
    }

    if (emailAddress === "") {
      set_validationErrorEmailAddress(true);
      return;
    } else {
      set_validationErrorEmailAddress(false);
    }

    if (!validateInquiryForm()) {
      return;
    }

    setIsLoginModalOpen(true);

  }

  function closeLoginModal() {
    setIsLoginModalOpen(false);
  }

  function dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const [ajaxBusy, setAjaxBusy] = React.useState(false);

  const [productPrice, setProductPrice] = React.useState(initialProductPrice);

  const [priceRangeIndex, setPriceRangeIndex] = React.useState(-1);

  const saveInquiry:any = (emailAddress:string, inquiryQuantity:number, details:string) => {

    if (!validateInquiryForm()) {
      return;
    }

    setSendingInquiry(true);

    Snippets.inquiries.save(
      AxiosAPI,
      product, 
      supplier, 
      productPrice,
      inquiryQuantity, 
      emailAddress,  
      details,
      ()=>{

        setSendingInquiry(false);
        setAjaxBusy(false);
        closeModal();
        closeLoginModal();
        
      },
      ()=>{

        setSendingInquiry(false);
        setAjaxBusy(false);

      }
    );

  }


  const onLoginSuccessHandler = (data: any) => {
    setIsLoginModalOpen(false);
    setAjaxBusy(false);
    setIsLoggedInUser(true);
    saveInquiry()

    //console.log("::::: REDUX LOGIN :::::", data);

  }; 

  const onLoginFailHandler: any = (error: any) => {
    setIsLoginModalOpen(true);
    setAjaxBusy(false);
    //setIsLoggedInUser(false);
    //console.log("::::: REDUX LOGIN :::::", error);
  };

  const loginAndSubmit = (email: any, password: any) => {
    setAjaxBusy(true);
    loginUser(
      email,
      password,
      (user: any) => {
        onLoginSuccessHandler(user);
      },
      (error: any) => {
        onLoginFailHandler(error);
      }
    );
  };

  const [sendingInquiry, setSendingInquiry] = React.useState(false);

  const saveRFQ = () => {
    if (!validateInquiryForm()) {
      return;
    }

    setSendingInquiry(true);

    var expiryDate = new Date();

    var expiryDateInOneMonth = new Date(
      expiryDate.setMonth(expiryDate.getMonth() + 1)
    );

    var expiryFullDate = expiryDateInOneMonth.toISOString();

    let productEstimatePrice: number = 0;

    if (product.pricingOption === "LADDER_PRICE") {
      productEstimatePrice = parseFloat(product.productPrices[0].price);
    }
    if (product.pricingOption === "PRICE_RANGE") {
      productEstimatePrice =
        (product.productPrices[0].price + product.productPrices[1].price) / 2;
    }
    if (product.pricingOption === "FIXED") {
      productEstimatePrice = productPrice;
    }

    const data = {
      id: 0,
      title: product.name,
      expirationDate: expiryFullDate,
      deliveryCity: "Harare",
      details: details,
      contactName: emailAddress.split("@")[0],
      contactEmail: emailAddress,
      isPublic: true,
      estimatedValue: productEstimatePrice * inquiryQuantity,
      status: "DRAFT",
      lineItems: [
        {
          id: 0,
          productId: product.id,
          quantity: inquiryQuantity,
        },
      ],
      attachments: [] as any,
    };

    setTimeout(() => {
      AxiosAPI.saveInquiry(data)
        .then((res: any) => {
          setSendingInquiry(false);

          setAjaxBusy(false);

          UserPilotHelper.logEvent(UserPilotEvents.INQUIRE_PRODUCT, res.data);
          const result = res.data;

          console.log(":: SAVED RFQ :::", result);

          (window as any).Swal.fire({
            icon: "success",
            title: "Submitted",
            html:
              "Your inquiry has been sent to " +
              supplier.name +
              " for the product <br /><b>" +
              product.name.substring(0, 32) +
              "...</b>",
            showCancelButton: true,
            confirmButtonText: "Show Inquiry",
            cancelButtonText: "Close",
            denyButtonText: `No`,
          }).then((result: any) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.href =
                Snippets.urls.getStoreFrontURL() + "/messenger/inquiries";
            } else if (result.isDenied) {
            }
          });

          closeModal();

          closeLoginModal();
        })
        .catch((error: any) => {
          setSendingInquiry(false);

          setAjaxBusy(false);

          console.log(":: ERROR RFQ :::", error);

          (window as any).Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was an error submitting your request. Please try again",
          });
        });
    }, 500);
  };

  const validateInquiryForm: any = () => {
    let _valid: boolean = false;
    if (details === "" || details.split("").length === 0) {
      (window as any).Swal.fire({
        icon: "error",
        title: "Validation Error",
        html: "Please enter your inquiry message",
      });
    } else {
      _valid = true;
    }
    return _valid;
  };

  var initialProductPrice = 0;

  try {
    initialProductPrice = product.productPrices.sort(dynamicSort("-price"))[0]
      .price;
  } catch (err) { }

  function calculatePrice() {
    try {
      let _prices: any = product.productPrices.sort(dynamicSort("-price"));
      let min = _prices[0].minimumOrderQuantity;
      let max = _prices[_prices.length - 1].maximumOrderQuantity;
      _prices.map((p: any, i: number) => {
        if (
          inquiryQuantity >= p.minimumOrderQuantity - 1 &&
          inquiryQuantity < p.maximumOrderQuantity + 4
        ) {
          //console.log("MIN AT", i, inquiryQuantity, p.minimumOrderQuantity);
          setProductPrice(parseFloat(_prices[i].price));
          setPriceRangeIndex(i);
        }
      });
      //console.log("RANGE", min, max);
    } catch (ex) { }
  }

  React.useEffect(() => {
    calculatePrice();
  }, [inquiryQuantity]);

  React.useEffect(() => {
    calculatePrice();
  }, [product.productPrices]);

  React.useEffect(() => {

    setDetails("I am interested in " + product.name);

    const parsed = queryString.parse(window.location.search);
    const paramValue = parsed.inquire;

    if (paramValue && product.name) {
      openModal();
    }
    fetchInstance
      .getCategorySupplierList(product.category.id)
      .then(({ data: _suppliers }) => {
        setSupplierCount(_suppliers.length - 1);
      });
  }, [product.category.id]);

  const sellerCannotSubmitEnquiry: any = () => {
    (window as any).Swal.fire({
      icon: "warning",
      title: "Warning",
      html: "You cannot submit an inquiry using a seller account. Please login or register as a buyer.",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Login as a Buyer",
      denyButtonText: "Register as a Buyer",
      cancelButtonText: "Close",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setTimeout(() => {
          let event = new Event("show-login-popup", { bubbles: true }); // (2)
          window.dispatchEvent(event);
        }, 10);
      } else if (result.isDenied) {
        window.location.href =
          Snippets.urls.getStoreFrontURL() + "/sign-up/buyer";
      }
    });
  };


  return (
    <>
      {product.name ? (
        <div className="col-md-6 mb-4">
          <div
            className="product-details"
            data-sticky-options="{'minWidth': 767}"
          >
            <h1 className="product-title">{product.name}</h1>

            <div className="product-bm-wrapper mb-1">
              <div className="product-meta">
                <div className="product-categories">
                  Lead Time:{" "}
                  <span>
                    {parseInt(product.orderLeadTimeRangeFrom) > 0
                      ? `${product.orderLeadTimeRangeFrom} - ${product.orderLeadTimeRangeTo} days.`
                      : `Ex Stock`}
                  </span>
                </div>
              </div>
            </div>

            <Button
              className="no-btn-effect"
              type="text"
              size="small"
              icon={<LikeOutlined />}
            >
              {product.likesCount ? product.likesCount : 0} likes
            </Button>

            <Button
              className="no-btn-effect"
              type="text"
              size="small"
              icon={<EyeOutlined />}
            >
              {product.viewsCount ? product.viewsCount : 0} views
            </Button>

            <hr />

            <div className="product-price bg-grey">
              {product.pricingOption == "LADDER_PRICE" && (
                <ins className="new-price amount">
                  {product.displayPrice === false
                    ? "Call supplier"
                    : `USD \$${parseFloat(
                        product.productPrices[0].price
                      ).toFixed(2)}`}
                </ins>
              )}
              {product.pricingOption == "PRICE_RANGE" && (
                <ins className="new-price amount">
                  {product.productPrices[0].price <
                  product.productPrices[0].price
                    ? product.displayPrice === false
                      ? "Call supplier"
                      : `USD \$${product.productPrices[0].price.toFixed(2)}`
                    : product.displayPrice === false
                    ? "Call supplier"
                    : `USD \$${product.productPrices[1].price.toFixed(2)}`}{" "}
                  -
                  {product.productPrices[0].price <
                  product.productPrices[0].price
                    ? product.displayPrice === false
                      ? "Call supplier"
                      : `USD \$${product.productPrices[1].price.toFixed(2)}`
                    : product.displayPrice === false
                    ? "Call supplier"
                    : `USD \$${product.productPrices[0].price.toFixed(2)}`}
                </ins>
              )}
              {product.pricingOption == "FIXED" && (
                <ins className="new-price amount">
                  {product.displayPrice === false
                    ? "Call supplier"
                    : `USD \$${productPrice.toFixed(2)}`}
                </ins>
              )}

              {product.displayPrice && product.unitOfMeasurement != null && (
                <>
                  <ins className="new-price si-unit">
                    / {product.minimumOrderQuantity} &nbsp;
                    {product.minimumOrderQuantity == 1
                      ? uomsList[product.unitOfMeasurement].singular
                      : uomsList[product.unitOfMeasurement].plural}
                  </ins>
                  <br />
                  <ins className="new-price min-order">
                    <strong>{product.minimumOrderQuantity}</strong>&nbsp;
                    {product.minimumOrderQuantity == 1
                      ? uomsList[product.unitOfMeasurement].singular
                      : uomsList[product.unitOfMeasurement].plural}{" "}
                    Minimum order
                  </ins>
                </>
              )}
            </div>

            {product.pricingOption == "LADDER_PRICE" && (
              <div className="product-price bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <ins className="new-price xsmall">Quantity</ins>
                  </div>
                  <div className="col-md-6">
                    <ins className="new-price xsmall">Price</ins>
                  </div>
                </div>

                {Array.from(
                  new Set(
                    product.productPrices.map(
                      (item: any) => item.maximumOrderQuantity
                    )
                  )
                )
                  .map((maximumOrderQuantity: number) => {
                    return product.productPrices.find(
                      (item: any) =>
                        item.maximumOrderQuantity === maximumOrderQuantity
                    ); //remove with same maximumOrderQuantity
                  })

                  .sort(dynamicSort("-price"))
                  .map((priceItem: any, pIndex: number) => (
                    <div className="row" key={pIndex}>
                      <div className="col-md-6">
                        <ins
                          className={`new-price xsmall ${
                            priceRangeIndex + "~" + pIndex
                          } ${priceRangeIndex === pIndex ? "red" : "dark"}`}
                        >
                          {priceItem.maximumOrderQuantity === 0 ? (
                            priceItem.minimumOrderQuantity
                          ) : (
                            <>
                              {priceItem.minimumOrderQuantity} -{" "}
                              {priceItem.maximumOrderQuantity}
                            </>
                          )}
                        </ins>
                      </div>
                      <div className="col-md-6">
                        <ins
                          className={`new-price xsmall ${
                            priceRangeIndex + "~" + pIndex
                          } ${priceRangeIndex === pIndex ? "red" : "dark"}`}
                        >
                          {product.displayPrice === false
                            ? "Call supplier"
                            : `\$${priceItem.price.toFixed(2)}|`}
                        </ins>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <hr className="product-divider m-30-0" />

            <Row>
              <Col span={12}>
                <div className="product-qty-form">
                  <div className="input-group">
                    <input
                      className="quantity form-control"
                      type="number"
                      min="1"
                      value={inquiryQuantity}
                      onChange={(e) => {
                        setInquiryQuantity(parseInt(e.target.value));
                      }}
                      max="10000000"
                    />
                    <button
                      className="quantity-plus w-icon-plus"
                      onClick={(e) => {
                        setInquiryQuantity(inquiryQuantity + 1);
                      }}
                    ></button>
                    <button
                      className="quantity-minus w-icon-minus"
                      onClick={(e) => {
                        if (inquiryQuantity > 1) {
                          setInquiryQuantity(inquiryQuantity - 1);
                        }
                      }}
                    ></button>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <button
                  className="btn btn-primary pl-3 pr-3"
                  onClick={openModal}
                >
                  <i className="w-icon-envelop-closed mr-1"></i>
                  <span> Inquire Now</span>
                </button>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <SkeletonTheme height="50">
          <div className="col-md-6 mb-4">
            {<Skeleton count={10} height={30} />}
          </div>
        </SkeletonTheme>
      )}

      <InquirySubmitModal
        inquiryQuantityOverride={inquiryQuantity}
        inquiryQuantityOverrideSet={(qty: string) =>
          setInquiryQuantity(parseInt(`${qty}`))
        }
        closeModal={closeModal}
        product={product}
        supplier={supplier}
        saveInquiry={saveInquiry}
        productPrice={productPrice}
        modalTitle="Submit Inquiry"
        isSubmitInquiryModalOpen={isModalOpen}
        sendingInquiry={sendingInquiry}
        openLoginModal={openLoginModal}
      />

      <LoginComponent
        isOpened={isLoginModalOpen}
        modalTitle={"Login"}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={onLoginSuccessHandler}
        onLoginError={onLoginFailHandler}
      ></LoginComponent>

      <RegisterComponent
        isOpened={isRegisterModalOpen}
        isModal={true}
        onClose={() => setIsRegisterModalOpen(false)}
        modalTitle={`Register as a Buyer to continue`}
        profileType={userType}
        enableLoginButton
        onLoginButtonClick={() => {
          setIsLoginModalOpen(true);
          setIsRegisterModalOpen(false);
        }}
      ></RegisterComponent>
    </>
  );
};
