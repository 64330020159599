import React, { useEffect, useState } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import {
  Badge,
  Button,
  Card,
  Drawer,
  Dropdown,
  Flex,
  MenuProps,
  message,
  Popconfirm,
  PopconfirmProps,
  Popover,
  Skeleton,
  Space,
  Tag,
} from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { useAppDataContext } from "../../../../data/context/AppDataContext";
import Snippets from "../../../../helpers/Snippets";
import {
  BankFilled,
  BankOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  DollarOutlined,
  FacebookFilled,
  FileSearchOutlined,
  HeartFilled,
  HeartOutlined,
  InstagramOutlined,
  LinkedinFilled,
  PaperClipOutlined,
  ShareAltOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import {
  BiSolidBadgeCheck,
  BiSolidBank,
  BiSolidBriefcase,
  BiSolidMap,
} from "react-icons/bi";
import { format } from "timeago.js";
import * as Icon from "react-feather";
import {
  MdMilitaryTech,
  MdOutlinePermPhoneMsg,
  MdOutlineScreenShare,
  MdOutlineStar,
  MdOutlineStarBorder,
  MdOutlineWatch,
  MdPermPhoneMsg,
} from "react-icons/md";
import { RFQBuyerRating } from "./RFQBuyerRating";
import { isMobile } from "react-device-detect";
const RFQListItem: any = ({
  item,
  supplierCategories,
  isPending,
  getListViewTrigger,
}: any) => {
  const {
    isLoginModalOpen,
    setIsLoginModalOpen,
    userAccessToken,
    setUserAccessToken,
    userDisplayName,
    setUserDisplayName,
    userEmailAddress,
    setUserEmailAddress,
    userType,
    setUserType,
    userData,
    setUserData,
    userProfile,
    setUserProfile,
    isLoggedIn,
    setIsLoggedIn,
    loginUser,
    logoutUser,
    profileCounters,
    getAllDocuments,
    selectedCategorriesForSupplier,
  } = useAppDataContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [open, setOpen] = useState<boolean>(false);

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const [isLiked, setIsLiked] = useState<boolean>(false);

  const [respondingRFQ, setRespondingRFQ] = useState<boolean>(false);

  const details = () => {
    console.log(":::DETAILS:::", item);
    setOpen(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const like = () => {
    setIsLiked(!isLiked);
  };

  const [openRespond, setOpenRespond] = useState(false);

  const [openUpgrade, setOpenUpgrade] = useState(false);

  const [openRespondx, setOpenRespondx] = useState(false);

  const [openUpgradex, setOpenUpgradex] = useState(false);

  const [isEligible, setIsEligible] = useState(false);

  useEffect(() => {
    if (supplierCategories.includes(item.category.id)) {
      console.log("::IS ELIGIBLE::Y", supplierCategories, item);
      setIsEligible(true);
    }
  }, [supplierCategories, item]);

  const copyLink: any = () => {
    navigator.clipboard.writeText(window.location.href);

    messageApi.info("Link copied to clipboard");
  };

  const confirmRespond: PopconfirmProps["onConfirm"] = (e: any) => {
    setRespondingRFQ(true);

    setTimeout(() => {
      setRespondingRFQ(false);
      setOpenRespond(false);

      messageApi.open({
        key: item.id,
        type: "loading",
        content: "Loading RFQ Number: " + Snippets.string.rfqNumber(item),
      });

      setTimeout(() => {
        window.location.href = "/messenger/rfqs/?rfq-id=" + item.id;
      }, 3000);
    }, 3000);
  };

  useEffect(() => {
    setRespondingRFQ(false);
    setOpenRespond(false);
    setOpenRespondx(false);
    setOpenUpgrade(false);
    setOpenUpgradex(false);
  }, [item]);

  const cancelRespond: PopconfirmProps["onCancel"] = (e: any) => {
    setRespondingRFQ(false);
    setOpenRespond(false);
    setOpenRespondx(false);
  };

  const confirmUpgrade: PopconfirmProps["onConfirm"] = (e: any) => {
    window.location.href = "/pricing";
  };

  const cancelUpgrade: PopconfirmProps["onCancel"] = (e: any) => {
    setOpenUpgrade(false);
    setOpenUpgradex(false);
  };

  const getDateTime: any = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp);

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    let t = `${day} ${month} ${year}, ${hours}:${minutes}`;

    console.log(":::T:::", t);

    return t;
  };

  function padNumber(num: any) {
    // Convert the number to a string and concatenate with '1000'
    let paddedString = "100" + num.toString();

    // Ensure it has at least 6 digits
    return paddedString.padStart(6, "0");
  }

  function formatToDollar(value: any) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }

  const [shareViaWhatsApp, setShareViaWhatsApp] = useState("");
  const [shareViaFacebook, setShareViaFacebook] = useState("");
  const [shareViaTwitter, setShareViaTwitter] = useState("");
  const [shareViaInstagram, setShareViaInstagram] = useState("");
  const [shareViaLinkedIn, setShareViaLinkedIn] = useState("");

  useEffect(() => {
    setShareViaWhatsApp(
      `https://api.whatsapp.com/send?text=${
        item.productName
      } ${window.location.href.replace(
        "http://localhost:3000",
        "https://commecial.supplyzone.ai"
      )}`
    );
    setShareViaFacebook(
      `https://www.facebook.com/sharer/sharer.php?app_id=1217981644879628&u=${window.location.href.replace(
        "http://localhost:3000",
        "https://commecial.supplyzone.ai"
      )}&igshid=MDc3MzNhZDY0Ng==`
    );
    setShareViaTwitter(
      `https://twitter.com/share?url=${window.location.href.replace(
        "http://localhost:3000",
        "https://commecial.supplyzone.ai"
      )}&text=${item.productName}&via=#WebsiteBrowser&hashtags=#SupplyZone`
    );
    setShareViaInstagram(
      `https://www.instagram.com/sharer/sharer.php?app_id=1217981644879628&u=${window.location.href.replace(
        "http://localhost:3000",
        "https://commecial.supplyzone.ai"
      )}&igshid=MDc3MzNhZDY0Ng==`
    );
    setShareViaLinkedIn(
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href.replace(
        "http://localhost:3000",
        "https://commecial.supplyzone.ai"
      )}`
    );
  }, [item]);

  const itemsShare: MenuProps["items"] = [
    {
      key: "0",
      icon: <WhatsAppOutlined />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={shareViaWhatsApp}>
          Share via WhatsApp
        </a>
      ),
    },
    {
      key: "1",
      icon: <FacebookFilled />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={shareViaFacebook}>
          Share via Facebook
        </a>
      ),
    },
    {
      key: "2",
      icon: <TwitterOutlined />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={shareViaTwitter}>
          Share via Twitter
        </a>
      ),
    },
    {
      key: "3",
      icon: <InstagramOutlined />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={shareViaInstagram}>
          Share via Instagram
        </a>
      ),
    },
    {
      key: "4",
      icon: <LinkedinFilled />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={shareViaLinkedIn}>
          Share via Linked In
        </a>
      ),
    },
    {
      key: "9A",
      icon: <CopyOutlined />,
      label: <a onClick={copyLink}>Copy Link</a>,
    },
  ];

  return (
    <>
      {contextHolder}
      <div key={item.id} className="card shadow-sm mb-5 pl-2 pr-2">
        <div className="card-body d-flex-invite pr-2 pl-2 pt-2 pb-2">
          <div className="row w-100">
            <div className="col-md-12 mobile-hide">
              <Flex className="w-100" gap={10} vertical={false}>
                <p className="w-100 mt-0 mb-1">
                  Posted {format(item.createdAt)}
                </p>
                <Flex
                  className="mt-0 mb-0"
                  gap={8}
                  style={{ float: "right" }}
                  vertical={false}
                >
                  <Button
                    style={{ width: 72 }}
                    size="large"
                    type="default"
                    onClick={() => like()}
                    icon={isLiked ? <HeartFilled /> : <HeartOutlined />}
                  ></Button>
                  <Dropdown menu={{ items: itemsShare }} arrow>
                    <Button
                      style={{ width: 72 }}
                      size="large"
                      type="default"
                      icon={<ShareAltOutlined />}
                    ></Button>
                  </Dropdown>
                  <Button
                    className="w-100"
                    size="large"
                    block
                    type="primary"
                    onClick={() => details()}
                  >
                    View Details
                  </Button>
                </Flex>
              </Flex>
            </div>
            <div className="col-md-12">
              <h3 className="mt-0 mb-3 one-liner-text">{item.productName}</h3>
              <p className="w-100 mt-0 mb-4 desktop-hide">
                Posted {format(item.createdAt)}
              </p>
            </div>
            <div className="col-md-4">
              <Flex className="mb-2" gap={8} vertical={false}>
                <Icon.Target
                  color="#552278"
                  size={16}
                  className="purple-icon"
                />
                <span className="rfq-mk-iconic-txt">
                  RFQ Number: {Snippets.string.rfqNumber(item)}
                </span>
              </Flex>
            </div>
            <div className="col-md-4">
              <Flex className="mb-2" gap={8} vertical={false}>
                <DollarOutlined size={16} className="purple-icon" />
                <span className="rfq-mk-iconic-txt">
                  RFQ Value:{" "}
                  {item.preferredUnitPrice.amount === 0
                    ? "Not stated"
                    : formatToDollar(
                        item.preferredUnitPrice.amount *
                          item.estimatedOrderQuantity.size
                      ).replace(".00", "")}
                </span>
              </Flex>
            </div>
            <div className="col-md-12">
              <span className="d-block mt-0 mb-3 rfq-mk-desc-txt truncate-3-lines">
                {item.productDetails}
              </span>
            </div>
            <div className="col-md-12">
              <span className="f-16">
                <Tag>Category: {item.category.name}</Tag>
              </span>
            </div>
            <div className="col-md-12 desktop-hide mt-5 mb-2">
              <Flex className="w-100" gap={10} vertical={true}>
                <p className="w-100 mt-0 mb-1 mobile-hide">
                  Posted {format(item.createdAt)}
                </p>
                <Flex
                  className="mt-0 mb-0"
                  gap={8}
                  style={{ float: "right" }}
                  vertical={false}
                >
                  <Button
                    style={{ width: 72 }}
                    size="large"
                    type="default"
                    onClick={() => like()}
                    icon={isLiked ? <HeartFilled /> : <HeartOutlined />}
                  ></Button>
                  <Dropdown menu={{ items: itemsShare }} arrow>
                    <Button
                      style={{ width: 72 }}
                      size="large"
                      type="default"
                      icon={<ShareAltOutlined />}
                    ></Button>
                  </Dropdown>
                  <Button
                    className="w-100"
                    size="large"
                    block
                    type="primary"
                    onClick={() => details()}
                  >
                    View Details
                  </Button>
                </Flex>
              </Flex>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title={`RFQ Details`}
        closable
        destroyOnClose
        placement="right"
        size="large"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="w-100 pt-5 pb-5 pl-5 pr-1">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <div className="row w-100">
                <div className="col-md-12">
                  <h3 className="mt-0 mb-3">{item.productName}</h3>
                  <Flex
                    className="mt-0 mb-5 w-100"
                    gap={8}
                    vertical={isMobile}
                    align="center"
                  >
                    <p className="w-100 mt-0 mb-0">
                      Posted {format(item.createdAt)}
                    </p>
                    <Flex
                      className="mt-0 mb-5 w-100"
                      gap={8}
                      vertical={false}
                      align="center"
                    >
                      <Button
                        style={{ width: 72 }}
                        size="large"
                        type="default"
                        onClick={() => like()}
                        icon={isLiked ? <HeartFilled /> : <HeartOutlined />}
                      ></Button>
                      <Dropdown menu={{ items: itemsShare }} arrow>
                        <Button
                          style={{ width: 72 }}
                          size="large"
                          type="default"
                          icon={<ShareAltOutlined />}
                        ></Button>
                      </Dropdown>
                      {isEligible ? (
                        <Popconfirm
                          open={openRespondx}
                          title="Respond to this RFQ?"
                          description={
                            <>
                              You are about to move{" "}
                              <b>
                                RFQ Number: {Snippets.string.rfqNumber(item)}
                              </b>{" "}
                              to the active folder. Do you want to continue? (
                              <em>This featurewill use 5 credits</em>)
                            </>
                          }
                          onConfirm={confirmRespond}
                          onCancel={cancelRespond}
                          okText="Yes"
                          cancelText="No"
                          okButtonProps={{ loading: respondingRFQ }}
                        >
                          <Button
                            size="large"
                            type="primary"
                            onClick={(e) => {
                              setOpenRespondx(!openRespondx);
                            }}
                          >
                            Respond to this RFQ
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          open={openUpgradex}
                          title="Upgrade Account"
                          description={
                            <>
                              You are not eligible to respond to this RFQ.
                              Please upgrade your account.
                            </>
                          }
                          onConfirm={confirmUpgrade}
                          onCancel={cancelUpgrade}
                          okText="Upgrade"
                          cancelText="Close"
                        >
                          <Button
                            size="large"
                            type="primary"
                            onClick={(e) => {
                              setOpenUpgradex(!openUpgradex);
                            }}
                          >
                            Respond to this RFQ
                          </Button>
                        </Popconfirm>
                      )}
                    </Flex>
                  </Flex>
                </div>
                <div className="col-md-12">
                  <h4>RFQ Description</h4>
                  <span className="d-block mt-1 mb-4 rfq-mk-desc-txt">
                    {item.productDetails}
                  </span>
                </div>

                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <Icon.Target
                      color="#552278"
                      size={16}
                      className="purple-icon"
                    />
                    <span className="rfq-mk-iconic-txt">
                      RFQ Number: {Snippets.string.rfqNumber(item)}
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <DollarOutlined size={16} className="purple-icon" />
                    <span className="rfq-mk-iconic-txt">
                      RFQ Value:{" "}
                      {item.preferredUnitPrice.amount === 0
                        ? "Not stated"
                        : formatToDollar(
                            item.preferredUnitPrice.amount *
                              item.estimatedOrderQuantity.size
                          ).replace(".00", "")}
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <FileSearchOutlined size={16} className="purple-icon" />
                    <span className="rfq-mk-iconic-txt f-16">
                      <Tag>Category: {item.category.name}</Tag>
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <ClockCircleOutlined size={16} className="purple-icon" />
                    <span className="rfq-mk-iconic-txt">
                      Expires in{" "}
                      {format(item.validTo)
                        .toString()
                        .replace("in", "")
                        .replace("ago", "")}
                    </span>
                  </Flex>
                </div>

                <div className="col-md-12">
                  <span className="d-block mt-4 mb-4">
                    <hr />
                  </span>
                </div>

                <div className="col-md-12">
                  <h4 className="mt-1 mb-5">About The Buyer</h4>
                </div>

                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <BankOutlined size={16} className="purple-icon" />
                    <span className="rfq-mk-iconic-txt">
                      Organization Size:{" "}
                      {item.organisation.companySize !== null
                        ? item.organisation.companySize
                        : "Not provided"}
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <BiSolidBadgeCheck
                      size={16}
                      className="verification-icon"
                    />
                    <span className="rfq-mk-iconic-txt">
                      {item.organisation.verified ? "Verified" : "Non-Verified"}{" "}
                      Buyer
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <BiSolidBriefcase size={16} className="verification-icon" />
                    <span className="rfq-mk-iconic-txt">
                      Industry Sector:{" "}
                      {item.organisation.industry !== null
                        ? item.organisation.industry
                        : "Not provided"}
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <BiSolidMap size={16} className="verification-icon" />
                    <span className="rfq-mk-iconic-txt">
                      Location:{" "}
                      {item.organisation.primaryContact.city !== null
                        ? item.organisation.primaryContact.city
                        : "Not provided"}
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <MdPermPhoneMsg size={16} className="verification-icon" />
                    <span className="rfq-mk-iconic-txt">
                      Average response time:{" "}
                      {item.organisation.averageResponseTime.replace(
                        "Hours",
                        " Hours"
                      )}
                    </span>
                  </Flex>
                </div>
                <div className="col-md-6">
                  <Flex className="mt-2 mb-5" gap={8} vertical={false}>
                    <Popover content={<RFQBuyerRating />}>
                      <span className="rfq-mk-iconic-txt rating-stars-wrapper">
                        <span className="stars-text text-black">4.2</span>{" "}{" "}
                        <span className="stars">★★★★☆</span>
                      </span>
                    </Popover>
                  </Flex>
                </div>
                {item.attachments.length > 0 && (
                  <>
                    <div className="col-md-12">
                      <span className="d-block mt-4 mb-4">
                        <hr />
                      </span>
                    </div>

                    <div className="col-md-12">
                      <h4>Attachments</h4>
                      <div className="row">
                        {item.attachments.map(
                          (attachment: any, attachmentIndex: number) => (
                            <div
                              key={attachmentIndex}
                              className="col-md-6 col-sm-6 col-xs-6"
                              style={{ width: "50%" }}
                            >
                              <a
                                className="f-16"
                                target="_blank"
                                href={`https://api-live.supplyzone.ai/home/download-attachment/${attachment.s3Key}`}
                              >
                                <Tag className="mt-2 one-liner-text">
                                  <PaperClipOutlined /> {attachment.s3Key}
                                </Tag>
                              </a>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-12">
                  <span className="d-block mt-4 mb-4">
                    <hr />
                  </span>
                </div>

                <div className="col-md-12">
                  <Flex
                    className={`mt-0 mb-10 ${isMobile ? "w-100x" : ""}`}
                    gap={8}
                    style={{ float: "right" }}
                    vertical={false}
                  >
                    {isEligible ? (
                      <Popconfirm
                        open={openRespond}
                        title="Respond to this RFQ?"
                        description={
                          <>
                            You are about to move{" "}
                            <b>RFQ Number: {Snippets.string.rfqNumber(item)}</b>{" "}
                            to the active folder. Do you want to continue? (
                            <em>This featurewill use 5 credits</em>)
                          </>
                        }
                        onConfirm={confirmRespond}
                        onCancel={cancelRespond}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ loading: respondingRFQ }}
                      >
                        <Button
                          className="mb-10"
                          size="large"
                          type="primary"
                          block={isMobile}
                          onClick={(e) => {
                            setOpenRespond(!openRespond);
                          }}
                        >
                          Respond to this RFQ
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Popconfirm
                        open={openUpgrade}
                        title="Upgrade Account"
                        description={
                          <>
                            You are not eligible to respond to this RFQ. Please
                            upgrade your account.
                          </>
                        }
                        onConfirm={confirmUpgrade}
                        onCancel={cancelUpgrade}
                        okText="Upgrade"
                        cancelText="Close"
                      >
                        <Button
                          className="mb-10"
                          size="large"
                          type="primary"
                          block={isMobile}
                          onClick={(e) => {
                            setOpenUpgrade(!openUpgrade);
                          }}
                        >
                          Respond to this RFQ
                        </Button>
                      </Popconfirm>
                    )}
                  </Flex>
                </div>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};
export { RFQListItem };
