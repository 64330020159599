import { Col, Image, Row } from "antd";
import React, { FunctionComponent, useState } from "react";
import { format, render, cancel, register } from "timeago.js";
import Snippets from "../../../../helpers/Snippets";

interface DocumentListItemProps {
  children?: React.ReactNode;
  isOut?: boolean;
  chat: any;
  user: any;
  getInitialsFromName: (name: string) => void;
  onDisplayName: any;
}

const ChatBubble: FunctionComponent<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  const [displayName, setDisplayName] = useState("");

  const { isOut, chat, getInitialsFromName, user, onDisplayName }: any = props;

  var attachmentIsImage = false;

  if (typeof chat !== "undefined" && chat.s3Key !== null) {
    attachmentIsImage = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(chat.s3Key);
  }

  const [themeColor, setThemeColor] = useState('');

  React.useEffect(() => {

    if (!isOut) {

      onDisplayName(displayName);

    }

  }, [displayName, isOut])

  React.useEffect(() => {

    if (Snippets.user.getCurrentUserType() === "SELLER") {
      setThemeColor('ad1e23');
    } else {
      setThemeColor('552277');
    }

    if (isOut) {
      setThemeColor('1677ff');
    }

    if (chat) {
      if (chat.hasOwnProperty("supplierCompanyName")) {
        setDisplayName(chat.supplierCompanyName);
      } else {
        setDisplayName(chat.senderName);
      }
    }

    //console.log("CHAT-BUBBLE-USER", user);
  }, []);

  return (
    <>
      <div className="clear-both"></div>

      <div className={`chat-bubble-item ${isOut ? "out-bound" : "in-bound"}`}>
        <Row>
          {!isOut && (
            <Col flex="48px">
              <img
                alt={""}
                src={`https://ui-avatars.com/api/?format=svg&background=${themeColor}&color=fff&size=36&bold=true&rounded=true&name=${displayName}`}
              />
            </Col>
          )}
          <Col flex="auto">
            {false && (
              <span className="message-sender">
                <strong>
                  {chat.hasOwnProperty("supplierCompanyName")
                    ? chat.supplierCompanyName
                    : chat.senderName}
                </strong>
              </span>
            )}

            <div className="speech-bubble">
              {chat.message !== " " && (
                <>
                  <div dangerouslySetInnerHTML={{ __html: chat.message }} />

                </>
              )}
              {typeof chat !== "undefined" && chat.s3Key !== null && (
                <>
                  <div className="chat-attachment">
                    {attachmentIsImage ? (
                      <Image
                        className="img-attachment"
                        src={`https://api-live.supplyzone.ai/home/download-attachment/${chat.s3Key}`}
                      />
                    ) : (
                      <Row>
                        <Col span={8}>
                          <a
                            rel="noreferrer"
                            href={`https://api-live.supplyzone.ai/home/download-attachment/${chat.s3Key}`}
                            target="_blank"
                          >
                            {chat.attachmentName.includes(".pdf") && (
                              <img src="/assets/images/docs/pdf.png" alt="" width="90" />
                            )}
                            {chat.attachmentName.includes(".doc") && (
                              <img src="/assets/images/docs/doc.png" alt="" width="90" />
                            )}
                            {chat.attachmentName.includes(".xls") && (
                              <img src="/assets/images/docs/xls.png" alt="" width="90" />
                            )}
                            {chat.attachmentName.includes(".csv") && (
                              <img src="/assets/images/docs/xls.png" alt="" width="90" />
                            )}
                            {chat.attachmentName.includes(".jpg") && (
                              <img src="/assets/images/docs/jpg.png" alt="" width="90" />
                            )}
                            {chat.attachmentName.includes(".jpeg") && (
                              <img src="/assets/images/docs/jpg.png" alt="" width="90" />
                            )}
                            {chat.attachmentName.includes(".png") && (
                              <img src="/assets/images/docs/jpg.png" alt="" width="90" />
                            )}
                            {chat.attachmentName.includes(".txt") && (
                              <img src="/assets/images/docs/txt.png" alt="" width="90" />
                            )}
                          </a>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={15}>
                          <strong>
                            <a
                              rel="noreferrer"
                              href={`https://api-live.supplyzone.ai/home/download-attachment/${chat.s3Key}`}
                              target="_blank"
                            >
                              {chat.attachmentName}
                            </a>
                          </strong>

                        </Col>
                      </Row>
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
          {isOut && (
            <Col flex="48px">
              <img
                alt={""}
                src={`https://ui-avatars.com/api/?format=svg&background=${themeColor}&color=fff&size=36&bold=true&rounded=true&name=${displayName}`}
              />
            </Col>
          )}
        </Row>
        <div className="time-ago">{format(chat.date)}</div>
      </div>
    </>
  );
};

export default ChatBubble;
