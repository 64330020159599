import React, {FC} from 'react' 
import MessengerAdvancedInquiryListViewPage from './MessengerAdvancedInquiryListViewPage'

const MessengerAdvancedInquiryPageWrapper: FC = () => {
  return (
    <>
      <MessengerAdvancedInquiryListViewPage />
    </>
  )
}

export default MessengerAdvancedInquiryPageWrapper;
