import { Col, Row } from "antd";
import React, { FunctionComponent, useState } from "react";
import ReactTyped from "react-typed";
import BarChart from "./BarChart";
interface DocumentListItemProps {
    children?: React.ReactNode;
    sender: string;
    content: string;
    isOut: boolean;
    scrollToBottom: any
}

const Chat: FunctionComponent<DocumentListItemProps> = ({
    children,
    ...props
}) => {
    const {
        sender,
        content,
        isOut,
        scrollToBottom,
    }: any = props;

    const [themeColor, setThemeColor] = useState('1677ff');
    const [hasJSON, setHasJSON] = useState(false)
    const [jsonSTR, setJsonSTR] = useState({ "columns": [], "rows": [] })
    const [htmlTable, setHtmlTable] = useState('');
    const [contentToDisplay, setContentToDisplay] = useState('')
    const [graphData, setGraphData] = useState({})

    const extractJSON = (str: string): any => {
        try {
            const result = str.split('```json')[1].split('```')[0];
            if (result) {
                return JSON.parse(result)
            }
        } catch (exception) {
            return null
        }
        return null;

    }

    React.useEffect(() => {

        const json = extractJSON(content);
        if (json) {
            //console.log("We have JSON:", content);
            setJsonSTR(json)
            setHasJSON(true)

            setContentToDisplay((content.split('```json')[0]))
            const labels: any[] = [];
            const prices: any[] = [];
            json.rows.map((row: any) => {
                const price = row.hasOwnProperty("price") ? row.price  : row.Price
                labels.push(row.supplierName)
                prices.push( row.price.replaceAll("$"))
            })
            //console.log(labels, prices)
            setGraphData({
                labels,
                datasets: [
                    {
                        label: 'Price ($)',
                        data: prices,
                        borderColor: 'rgb(40, 34, 140)',
                        backgroundColor: 'rgba(27, 0, 214, 0.5)',
                    }
                ],
            })


        } else {
            //console.log("We do not have JSON:", content.replaceAll("\n", "<br>"));
            setContentToDisplay(content.replaceAll("\n", "<br>"))
        }

    }, [content])

    React.useEffect(() => {
        //console.log("jsonSTR", jsonSTR)
        if (jsonSTR.hasOwnProperty("columns") && jsonSTR.columns.length > 0) {
            let tableHtml = `<table class = "table table-striped"><thead><tr>`;
            tableHtml += `<th>Supplier Name</th>`
            tableHtml += `<th>Price</th>`
            tableHtml += `</thead>`;

            jsonSTR.rows.map((row: any) => {
                //console.log(row);
                tableHtml += `<tr>`;
                tableHtml += `<td>${row.supplierName}</td>`
                tableHtml += `<td>${  row.hasOwnProperty("price") ? row.price  : row.Price}</td>`
                tableHtml += `</tr>`;
            })

            tableHtml += `</table>`;
            setHtmlTable(tableHtml);

            //console.log(tableHtml)

        }

    }, [jsonSTR])

    React.useEffect(() => {
        if (isOut) {
            setThemeColor('33495E')
        }
    }, [isOut])

    return (
        <>
            <div className="clear-both"></div>

            <div className={`chat-bubble-item in-bound ${isOut ? "bot-response" : ""}`} >
                <Row>
                    <Col span={1}>
                        <img
                            alt={""}
                            src={`https://ui-avatars.com/api/?format=svg&background=${themeColor}&color=fff&size=36&bold=true&rounded=true&name=${sender == 'user' ? 'your' : 'mi'}`}
                        />
                    </Col>
                    <Col span={20}>
                        <div style={{ marginLeft: "15px", marginBottom: "5px" }}><strong> {isOut ? "Market Intelligence" : "You"} </strong></div>
                        <div className="speech-bubble" >

                            {isOut && !hasJSON ? (
                                <ReactTyped strings={[content.replaceAll("\n", "<br>")]} typeSpeed={16} onComplete={(instance: any) => { instance.cursor.remove(); scrollToBottom() }} />
                            ) : (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: contentToDisplay }} />
                                    <div dangerouslySetInnerHTML={{ __html: htmlTable }} />
                                    {graphData.hasOwnProperty("datasets") && (
                                        <BarChart data={graphData} title="Price Distribution By Supplier"></BarChart>
                                    )}
                                </>
                            )}
                        </div>
                    </Col>

                </Row>
            </div>
        </>
    );
};

export default Chat;
