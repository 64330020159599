import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Button,
  Flex,
  Tabs,
  Input,
  Row,
  Col,
  Steps,
  theme,
  Space,
  Skeleton,
  message,
} from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { CategoryItem } from "./CategoryItem";
import { useAppDataContext } from "../../../../data/context/AppDataContext";

const CategorySelection = ({
  selectedCategories,
  setSelectedCategories,
}: any) => {
  const [categories, setCategories] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const getCategoryListView = () => {
    setIsPageLoading(true);
    AxiosAPI.publicApiRequest("get", "/model/group-categories/top", null)
      .then(({ data: categories }) => {
        setCategories(
          categories.map((cat: any) => {
            return {
              label: cat.name,
              name: cat.name,
              value: cat.id,
              id: cat.id,
              image: cat.image,
            };
          })
        );
        setIsPageLoading(false);
      })
      .catch((error) => {
        setIsPageLoading(false);
      });
  };

  const {
    addCategorriesForSupplier,
    removeCategorriesForSupplier,
    selectedCategorriesForSupplier,
    setSelectedCategorriesForSupplier,
  } = useAppDataContext();

  const updateCatSelectionHandler = (category: any, isSelected: boolean) => {
    if (isSelected) {
      addCategorriesForSupplier({ id: category.id });
    } else {
      removeCategorriesForSupplier({ id: category.id });
    }
  };

  useEffect(() => {
    if (selectedCategories.length === 0) {
      getCategoryListView();
    }
  }, []);

  return (
    <>
      {contextHolder}
      <Row>
        <Col className="w-100">
          <h2 className="mt-5 mb-3">
            Step 1/2 : Categorize Your Products and Services
          </h2>
          <h4 className="mt-1 mb-5">
            Select a maximum of 3 categories below to get started.
          </h4>
        </Col>
      </Row>
      {!isPageLoading ? (
        <Row
          className="w-100 m-auto pt-5 pb-5"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          {categories.map((category, categoryIndex) => (
            <Col key={categoryIndex} xs={24} sm={12} md={6} lg={4} xl={4}>
              <CategoryItem
                category={category}
                updateCatSelection={updateCatSelectionHandler}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Row
          className="w-100 m-auto pt-5 pb-5"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
            (_, categoryIndex) => (
              <Col key={categoryIndex} xs={24} sm={12} md={6} lg={4} xl={4}>
                <div className="xcards category category-classic category-absolute w-100">
                  <Flex className="w-100" vertical gap={5}>
                    <Skeleton.Button
                      active={true}
                      size={"small"}
                      shape={"default"}
                      block={true}
                      className={"lh-1 h-150"}
                    />
                    <Skeleton.Button
                      active={true}
                      size={"small"}
                      shape={"default"}
                      block={true}
                      className={"lh-1 h-20"}
                    />
                    <Skeleton.Button
                      active={true}
                      size={"small"}
                      shape={"default"}
                      block={true}
                      className={"lh-1 h-20"}
                    />
                  </Flex>
                </div>
              </Col>
            )
          )}
        </Row>
      )}
    </>
  );
};

export { CategorySelection };
