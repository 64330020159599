/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as Icon from "react-feather";
import Snippets from "../../../helpers/Snippets";
import { useAppDataContext } from "../../../data";
import { LoginComponent } from "./LoginComponent";
import axios from "axios";
import {
  AppstoreOutlined,
  CommentOutlined,
  GiftOutlined,
  HomeOutlined,
  LoadingOutlined,
  LockOutlined,
  MenuOutlined,
  PoweroffOutlined,
  RobotOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MobileView, isMobile, isMobileOnly } from "react-device-detect";
import { AutoComplete, Badge, Flex, Row, message } from "antd";
import { Button, Drawer, Menu, MenuProps } from "antd";
import AxiosAPI from "../../../helpers/AxiosAPI";
import SearchCard from "./SearchCard";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { LiaCommentsSolid } from "react-icons/lia";
export default () => {
  const [messageApi, contextHolder] = message.useMessage();

  // FFs, repeating code, find way to reuse
  var queryString = window.location.search.substring(1);
  var queryParts = queryString.split("&");
  var query = "";
  for (let x = 0; x < queryParts.length; x++) {
    let tmpArr = queryParts[x].split("=");
    if (tmpArr[0] === "search") {
      query = tmpArr[1].replace("+", " ");
    }
  }
  //end duplicating code

  const [isNotifying, setIsNotifying] = useState(false);

  const checkJWTToken: any = useCallback(() => {
    const token = Snippets.user.getCurrentUserAccessToken();
    if (!token || token.length < 20) {
      if (
        window.location.href
          .toString()
          .match(
            /(messenger|company-settings|users|profile-wizard|chat|reward-zone)/
          )
      ) {
        if (!isNotifying) {
          setIsNotifying(true);
          messageApi.open({
            type: "warning",
            content:
              "Your login session has expired due to non-activity. Please login again.",
            duration: 30,
          });
        }
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }
    }
  }, []);

  const boxStyle: React.CSSProperties = {
    width: "100%",
  };

  const boxIconStyle: React.CSSProperties = {
    fontSize: 36,
    position: "relative",
    top: -10,
  };

  const location = useLocation();

  /// DATA CONTEXT

  const {
    isLoginModalOpen,
    setIsLoginModalOpen,
    userAccessToken,
    setUserAccessToken,
    userDisplayName,
    setUserDisplayName,
    userEmailAddress,
    setUserEmailAddress,
    userType,
    setUserType,
    userRole,
    setUserRole,
    userData,
    setUserData,
    userProfile,
    setUserProfile,
    isLoggedIn,
    setIsLoggedIn,
    loginUser,
    logoutUser,
    profileCounters,
    getAllDocuments,
  } = useAppDataContext();

  /// END DATA CONTEXT

  const [showDropDownCategories, setShowDropDownCategories] = useState(true);
  const [dropDownCategoriesShown, setDropDownCategoriesShown] = useState(false);

  const [signInButton, setSignInButton] = React.useState<string>("Sign In");
  const [superCatsLoaded, setSuperCatsLoaded] = React.useState(false);
  const [productSuperCategories, setProductSuperCategories] =
    React.useState<any>([]);
  const [legalDocs, setLegalDocs] = React.useState<any>([]);
  const [productSuperCategoriesChunks1, setProductSuperCategoriesChunks1] =
    React.useState<any>([]);
  const [productSuperCategoriesChunks2, setProductSuperCategoriesChunks2] =
    React.useState<any>([]);
  const [productSuperCategoriesChunks3, setProductSuperCategoriesChunks3] =
    React.useState<any>([]);

  const [ajaxBusy, setAjaxBusy] = useState(false);

  const dontShowDropDownCategoriesOn = [
    "profile-settings",
    "company-settings",
    "profile-wizard",
    "products",
    "rfqs",
    "rfq",
    "sign-up",
    "sign-in",
    "product",
    "sellers",
    "seller",
    "become-a",
    "search",
    "categories",
    "messenger",
    "users",
    "reward-zone",
    "supplier",
    "chat",
    "company-profile",
    "buyer/invites",
    "buyer",
    "accept-invite",
    "pricing",
    "pricing2",
  ];
  const pageLocation = useLocation();
  React.useEffect(() => {
    // runs on location, i.e. route, change
    console.log("handle route change here", pageLocation);
    const page = pageLocation.pathname.split("/")[1];
    const hideOnPage = dontShowDropDownCategoriesOn.filter((hideOnPage) => {
      return hideOnPage == page;
    });
    setShowDropDownCategories(hideOnPage.length == 0);
  }, [pageLocation]);

  useEffect(() => {
    const endpoint_2 = location.pathname.split("/");

    setShowDropDownCategories(
      dontShowDropDownCategoriesOn.indexOf(endpoint_2[1]) === -1
    );

    setSignInButton("Sign In");

    AxiosAPI.publicApiRequest(
      "get",
      "/model/group-categories-with-children/top",
      null
    )
      .then(({ data: categories }) => {
        setProductSuperCategories(categories);
        setSuperCatsLoaded(true);

        let drawerCategories = [];

        for (let i = 0; i < categories.length; i++) {
          if (
            categories[i].children.length > 0 ||
            categories[i].productsCount > 0
          )
            drawerCategories.push(categories[i]);
        }

        const size = Math.ceil(drawerCategories.length / 3);
        setProductSuperCategoriesChunks1(drawerCategories.slice(0, size));
        setProductSuperCategoriesChunks2(
          drawerCategories.slice(size, size * 2)
        );
        setProductSuperCategoriesChunks3(drawerCategories.slice(size * 2));
      })
      .catch((error: any) => { });

    setLegalDocs([
      {
        slug: "terms",
        name: "Terms of Use",
      },
      {
        slug: "privacy",
        name: "Privacy Policy",
      },
      {
        slug: "security",
        name: "Security Measures",
      },
      {
        slug: "ip",
        name: "IP Policy",
      },
      {
        slug: "cookies",
        name: "Cookie Policy",
      },
    ]);
  }, []);

  function openLoginModal() {
    setIsLoginModalOpen(true);
  }

  const onLoginSuccessHandler = (data: any) => {
    setIsLoginModalOpen(false);

    //console.log("::::: REDUX LOGIN :::::", data);
  };

  const onLoginFailHandler: any = (error: any) => {
    //console.log("::::: REDUX LOGIN :::::", error);
  };

  const [isMobileMainMenuOpen, setMobileMainMenuOpen] = useState(false);

  const openMainMobileMenu: any = () => {
    setMobileMainMenuOpen(true);
  };

  const closeMainMobileMenu: any = () => {
    setMobileMainMenuOpen(false);
  };

  const [isMobileProfileMenuOpen, setMobileProfileMenuOpen] = useState(false);

  const openMobileUserProfile: any = () => {
    setMobileProfileMenuOpen(true);
  };

  const closeMobileUserProfile: any = () => {
    setMobileProfileMenuOpen(false);
  };

  const linkLogoutUser = () => {
    setAjaxBusy(true);

    logoutUser(
      () => {
        setAjaxBusy(false);

        setSignInButton("Sign In");

        closeMobileUserProfile();

        setTimeout(() => {
          (window as any).location.href = Snippets.urls.getStoreFrontURL();
        }, 1000);
      },
      (error: any, swalPayload: any) => {
        setAjaxBusy(false);

        //console.log("ERROR: linkLogoutUser :", error);

        swalPayload && (window as any).Swal.fire(swalPayload);
      }
    );
  };

  const linkRFQ = () => {
    if (isLoggedIn) {
      if (userType == "SELLER") {
        return "/rfq/marketplace";
      } else {
        return "/rfqs/create";
      }
    } else {
      return "/rfq/marketplace";
    }
  };
  const linkMessenger = () => {
    if (isLoggedIn) {
      if (userType == "SELLER") {
        return "/messenger/rfqs";
      } else {
        return "/messenger/rfqs";
      }
    } else {
      return "/messenger/rfqs";
    }
  };

  type MenuItem = Required<MenuProps>["items"][number];

  const [menuProfileItems, setMenuProfileItems] = useState<MenuItem[]>([
    {
      key: "1",
      icon: <UserOutlined />,
      label: (
        <Link
          to={"/profile-wizard"}
          onClick={() => {
            setMobileProfileMenuOpen(false);
          }}
        >
          My Profile
        </Link>
      ),
    },
    {
      key: "22",
      icon: <GiftOutlined />,
      label: (
        <Link
          to={"/reward-zone"}
          onClick={() => {
            setMobileProfileMenuOpen(false);
          }}
        >
          Reward Zone
        </Link>
      ),
    },
    {
      key: "3",
      icon: <LockOutlined />,
      label: (
        <Link
          to={"/profile-wizard#security"}
          onClick={() => {
            setMobileProfileMenuOpen(false);
          }}
        >
          Change Password
        </Link>
      ),
    },
    {
      key: "4",
      icon: (
        <PoweroffOutlined
          onClick={() => {
            setMobileProfileMenuOpen(false);
          }}
        />
      ),
      label: (
        <a href={"#"} onClick={linkLogoutUser}>
          Logout
        </a>
      ),
    },
  ]);

  const [menuMainItems, setMenuMainItems] = useState<MenuItem[]>([]);

  const [urlParams, setUrlParams] = useState({});

  useEffect(() => {
    const _params: any = Snippets.urls.getQueryParams();

    setUrlParams(_params);

    if ("category" in _params) {
      if (_params["category"] === "RFQS") {
        window.location.href = `/rfqs/marketplace?q=` + _params["search"];
      }
    }
  }, []);

  useEffect(() => {
    //console.warn("************** IS LOGGED IN", isLoggedIn);

    if (isLoggedIn) {
      //console.warn("************** USER TYPE", [userType, userData]);

      if (userType === "BUYER") {
        getAllDocuments();
      }

      setMenuMainItems([
        {
          key: "1",
          label: <Link to={"/"}>Home</Link>,
          children: [
            {
              key: "2.1",
              label: (
                <Link
                  to={
                    userType === "BUYER"
                      ? "/messenger/rfqs"
                      : "/rfq/marketplace"
                  }
                  className=" "
                >
                  <Badge
                    count={
                      profileCounters.unreadRFQMessages +
                      profileCounters.unreadInquiryMessages
                    }
                    size="small"
                    offset={[10, 2]}
                  >
                    Messenger
                  </Badge>
                </Link>
              ),
            },
            {
              key: "3",
              label: <Link to={"/become-a/buyer"}>For Buyers</Link>,
            },
            {
              key: "4",
              label: <Link to={"/company-settings"}>Company Settings</Link>,
            },
          ],
        },
        {
          key: "5",
          label: <Link to={"/become-a/seller"}>For Suppliers</Link>,
        },
        {
          key: "6",
          label: <Link to={"/become-a/buyer"}>For Buyers</Link>,
        },
      ]);

      if (userType != "BUYER") {
        setMenuProfileItems(
          menuProfileItems.filter((menuItem) => {
            return menuItem.key != "2";
          })
        );
      }
    } else {
      setMenuMainItems([
        {
          key: "1",
          label: <Link to={"/"}>Home</Link>,
        },
        {
          key: "2",
          label: <Link to={"/become-a/seller"}>For Suppliers</Link>,
        },
        {
          key: "3",
          label: <Link to={"/become-a/buyer"}>For Buyers</Link>,
        },
      ]);
    }
  }, [isLoggedIn]);

  const [searchCardData, setSearchCardData] = useState({
    tabs: [
      {
        label: "All",
        id: "all",
        items: [],
      },
      {
        label: "Top Products",
        id: "products",
        items: [],
      },
      {
        label: "Top Suppliers",
        id: "suppliers",
        items: [],
      },
      {
        label: "Recent RFQs",
        id: "rfqs",
        items: [],
      },
    ],
  });

  const updateTabItems = (tabId: string, newItems: any) => {
    setSearchCardData((prevData) => {
      const updatedTabs = prevData.tabs.map((tab) => {
        if (tab.id === tabId) {
          return { ...tab, items: newItems };
        }
        return tab;
      });
      return { ...prevData, tabs: updatedTabs };
    });
  };

  const formatCurrency3: any = (number: any) => {
    const formattedString = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
    return formattedString;
  };

  const resetSearchBox = () => {
    searchService.handleInput("RESET");
  };

  class SearchService {
    private readonly debounceTime = 300; // Adjust debounce time as per your requirement
    private searchQuery: string = "";

    constructor() {
      // Initialize debounce function with the search function
      this.debouncedSearch = debounce(this.debouncedSearch, this.debounceTime);
    }

    // Function to handle user input and trigger search after debounce
    handleInput(input: string) {
      this.searchQuery = input.trim();
      this.debouncedSearch();
    }

    // Debounced search function that actually makes the HTTP request
    private debouncedSearch() {
      if (this.searchQuery !== "") {
        // Perform HTTP request here using Angular's HttpClient or any other method
        console.log("Making HTTP request with query:", this.searchQuery);
        // Example: this.http.get(`/api/search?q=${this.searchQuery}`).subscribe(...);
        AxiosAPI.searchAllItems(this.searchQuery.replace("RESET", "")).then(
          (res: any) => {
            let searchResultsProducts: any = res.data.products;

            let searchResultsSuppliers: any = res.data.suppliers;

            let searchResultsRFQS: any = res.data.rfqs;

            // PRODUCTS

            let searchResultsProductsList: {
              id: any;
              title: string;
              subtitle: string;
              value: any;
              badge: any;
              product: any;
              type: string;
            }[] = [];

            //console.log("1.1 :: SEARCH PRODUCTS ::", searchResultsProducts);

            let searchResultsProductsFiltered: any = searchResultsProducts
              .filter((item: any, index: number, self: any) => {
                return (
                  self.findIndex(
                    (otherItem: any) => otherItem.name === item.name
                  ) === index
                );
              })
              .slice(-6);

            //console.log("1.2 :: SEARCH PRODUCTS ::", searchResultsProductsFiltered);

            searchResultsProductsFiltered.map((product: any) => {
              searchResultsProductsList.push({
                id: product.id,
                title: product.name,
                subtitle: product.category,
                value: (
                  <span className="new-price">
                    ${product.price}
                    <small className="per-unit">
                      /{product.unitOfMeasurement}
                    </small>
                  </span>
                ),
                badge: null,
                product: product,
                type: "product",
              });
            });
            updateTabItems("products", searchResultsProductsList);

            // SUPPLIERS

            let searchResultsSuppliersList: {
              id: any;
              title: string;
              subtitle: string;
              value: any;
              badge: any;
              product: any;
              type: string;
              rightLabel: string;
            }[] = [];

            //console.log("2.1 :: SEARCH SUPPLIERS ::", searchResultsSuppliers);

            let searchResultsSuppliersFiltered: any = searchResultsSuppliers
              .filter((item: any) => item.name !== "")
              .filter((item: any, index: number, self: any) => {
                return (
                  self.findIndex(
                    (otherItem: any) => otherItem.name === item.name
                  ) === index
                );
              })
              .slice(-6);

            //console.log("2.2 :: SEARCH SUPPLIERS ::", searchResultsSuppliersFiltered);

            searchResultsSuppliersFiltered.map((supplier: any) => {
              if (supplier.name.length > 2) {
                searchResultsSuppliersList.push({
                  id: supplier.id,
                  title: supplier.name,
                  subtitle: supplier.industry,
                  value: <span className="new-price">${supplier.city}</span>,
                  badge: null,
                  product: supplier,
                  type: "supplier",
                  rightLabel: "Call supplier",
                });
              }
            });
            updateTabItems("suppliers", searchResultsSuppliersList);

            // RFQS

            let searchResultsRFQSList: {
              id: any;
              title: string;
              subtitle: string;
              value: any;
              badge: any;
              product: any;
              type: string;
              rightLabel: string;
            }[] = [];

            //console.log("3.1 :: SEARCH RFQS ::", searchResultsRFQS);

            let searchResultsRFQSFiltered: any = searchResultsRFQS
              .filter((item: any, index: number, self: any) => {
                return (
                  self.findIndex(
                    (otherItem: any) =>
                      otherItem.productName === item.productName
                  ) === index
                );
              })
              .slice(-6);

            //console.log("3.2 :: SEARCH RFQS ::", searchResultsRFQSFiltered);

            const rfqLabel = ['2 days left', 'Closed', 'Closed']
            searchResultsRFQSFiltered.map((rfq: any) => {
              const title = rfq.productDetails.replace(/<\/?[^>]+(>|$)/g, "");
              if (rfq.preferredUnitPrice.amount > -1) {
                searchResultsRFQSList.push({
                  id: rfq.id,
                  title: rfq.productName,
                  subtitle: title.length > 60 ? title.substring(0, 60) + "..." : title,
                  value: (
                    <span className="new-price">
                      {rfq.preferredUnitPrice.currency}{" "}
                      {formatCurrency3(rfq.preferredUnitPrice.amount)}
                    </span>
                  ),
                  badge: null,
                  product: rfq,
                  type: "rfq",
                  rightLabel: rfqLabel[Math.floor(Math.random() * 2)],
                });
              }
            });

            updateTabItems("rfqs", searchResultsRFQSList);

            const all = [
              ...searchResultsProductsList.slice(-2),
              ...searchResultsSuppliersList.slice(-2),
              ...searchResultsRFQSList.slice(-2),
            ];

            //console.log("4.1 :: SEARCH ALL ::", all);

            updateTabItems("all", all);
          }
        );
      }
    }
  }
  const searchService = new SearchService();

  const filterSearchBox = (query: string = "") => {
    if (!query || query === "") {
      resetSearchBox();
      return;
    }
    searchService.handleInput(query);
  };

  useEffect(() => {
    resetSearchBox();
  }, []);

  const searchProducts = (str: string) => {
    return filterSearchBox(str);
  };

  const [value, setValue] = useState("");
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [anotherOptions, setAnotherOptions] = useState<{ value: string }[]>([]);

  const onSelect = (data: string) => {
    //console.log('onSelect', data);
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  //logout because of inactivity
  const minutesToExpireSession = 30;
  let timeout: NodeJS.Timeout | null = null;
  const goBackToHome = () => {
    linkLogoutUser();
  };
  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (isLoggedIn) {
        goBackToHome();
      }
    }, 60000 * minutesToExpireSession);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  useEffect(() => {
    if (isLoggedIn) {
      restartAutoReset();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setInterval(checkJWTToken, 5 * 60 * 1000);
    checkJWTToken();
    window.addEventListener("offline", function () {
      messageApi.open({
        type: "warning",
        content: "You have lost internet connection.",
        duration: 5,
      });
    });
    window.addEventListener("online", function () {

      messageApi.open({
        type: "success",
        content: "Internet connection has been restored",
        duration: 5,
      });

    });
  }, []);

  return (
    <>
      {contextHolder}
      {/* Start of Header */}
      <div className="wolmart">
        <header className="header">
          <div className="header-top">
            <div className="container">
              <div className="header-left">
                <Link to="/" className="logo  ml-lg-0">
                  <img
                    src={`${Snippets.urls.getStoreFrontURL()}/assets/images/logo-icon.png`}
                    className="bigger"
                    alt="logo"
                    width="200"
                  />
                  <img
                    src={`${Snippets.urls.getStoreFrontURL()}/favicon.ico`}
                    className="smaller"
                    alt="logo "
                  />
                </Link>
              </div>

              <div className="header-right menu">
                <div className="mobile-login">
                  <Link
                    className={`sign-in-pill ${
                      userType === "SELLER" ? "seller" : "buyer"
                    }`}
                    onClick={(e) => {
                      isLoggedIn ? openMobileUserProfile() : openLoginModal();
                    }}
                    to="#."
                  >
                    {isLoggedIn ? userDisplayName : signInButton}
                  </Link>
                </div>

                <nav className="main-nav">
                  <ul className="menu nav-menu">
                    <li className={`${isLoggedIn ? "has-submenu" : ""}`}>
                      <Link className="" to={isLoggedIn ? "#." : "/"}>
                        <Badge
                          dot={
                            isLoggedIn &&
                            profileCounters.unreadRFQMessages +
                              profileCounters.unreadInquiryMessages >
                              0
                          }
                          className="me-3"
                        ></Badge>
                        {isLoggedIn ? "Menu" : "Home"}
                      </Link>

                      {isLoggedIn && (
                        <ul className="submenu home">
                          {/* <li><a href={`${Snippets.urls.getStoreFrontURL()}/rfqs/marketplace`}>RFQ Marketplace</a></li> */}

                          <li>
                            <Link to={`${Snippets.urls.getStoreFrontURL()}`}>
                              <Icon.Home
                                color="#444444"
                                size={16}
                                className="mr-2"
                              />{" "}
                              Home
                            </Link>
                          </li>

                          <li>
                            <Link
                              to={`${Snippets.urls.getStoreFrontURL()}/messenger/rfqs`}
                            >
                              <LiaCommentsSolid
                                size={22}
                                color="#444444"
                                className="mr-2 ml--1"
                              />{" "}
                              <Badge
                                count={
                                  profileCounters.unreadRFQMessages +
                                  profileCounters.unreadInquiryMessages
                                }
                                size="small"
                                offset={[10, 2]}
                              >
                                Messenger
                              </Badge>
                            </Link>
                          </li>
                          {userType === "BUYER" && (
                            <li>
                              <a
                                href="#"
                                onClick={() => {
                                  window.location.href = `${Snippets.urls.getStoreFrontURL()}/buyer/invites`;
                                }}
                              >
                                <Icon.Users
                                  color="#444444"
                                  size={16}
                                  className="mr-2"
                                />{" "}
                                Manage Invitations
                              </a>
                            </li>
                          )}

                          {userRole === "ADMIN" && (
                            <li>
                              <Link
                                to={`${Snippets.urls.getStoreFrontURL()}/company-settings`}
                              >
                                <Icon.Settings
                                  color="#444444"
                                  size={16}
                                  className="mr-2"
                                />{" "}
                                Company Settings
                              </Link>
                            </li>
                          )}

                          {userType === "SELLER" && (
                            <li>
                              <Link
                                to={`${Snippets.urls.getStoreFrontURL()}/products/manage`}
                              >
                                <Icon.Box
                                  color="#444444"
                                  size={16}
                                  className="mr-2"
                                />{" "}
                                My Products
                              </Link>
                            </li>
                          )}
                        </ul>
                      )}
                    </li>
                    <li className="no-submenu">
                      <Link
                        className=""
                        to={`${Snippets.urls.getStoreFrontURL()}/become-a/seller`}
                      >
                        For Suppliers
                      </Link>
                    </li>
                    <li className="no-submenu">
                      <Link
                        className="tooltipx bottom"
                        to={`${Snippets.urls.getStoreFrontURL()}/become-a/buyer`}
                      >
                        For Buyers
                      </Link>
                    </li>
                    <li className={`${isLoggedIn ? "has-submenu" : ""}`}>
                      <a
                        className={`sign-in-pill ${
                          userType === "SELLER" ? "seller" : "buyer"
                        }`}
                        onClick={(e) => {
                          isLoggedIn ? "" : openLoginModal();
                        }}
                        href="#."
                      >
                        {isLoggedIn ? userDisplayName : signInButton}
                      </a>
                      {isLoggedIn && (
                        <ul className="submenu">
                          <li>
                            <Link
                              to={`${Snippets.urls.getStoreFrontURL()}/profile-wizard`}
                            >
                              {" "}
                              <Icon.User
                                color="#444444"
                                size={16}
                                className="mr-2"
                              />{" "}
                              My Profile
                            </Link>
                          </li>

                          {userType == "BUYER" && (
                            <>
                              <li>
                                <Link
                                  to={`${Snippets.urls.getStoreFrontURL()}/reward-zone`}
                                >
                                  {" "}
                                  <Icon.Gift
                                    color="#444444"
                                    size={16}
                                    className="mr-2"
                                  />{" "}
                                  Reward Zone
                                </Link>
                              </li>
                            </>
                          )}

                          <li>
                            <Link
                              to={`${Snippets.urls.getStoreFrontURL()}/profile-wizard?tab=security`}
                            >
                              <Icon.Lock
                                color="#444444"
                                size={16}
                                className="mr-2"
                              />{" "}
                              Change Password
                            </Link>
                          </li>

                          <li>
                            <a href="#" onClick={() => linkLogoutUser()}>
                              <Icon.Power
                                color="#444444"
                                size={16}
                                className="mr-2"
                              />{" "}
                              Logout
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          {/*  End of Header Top   */}

          <div className="header-middle">
            <div className="container">
              <Flex
                style={boxStyle}
                gap={32}
                justify={"flex-start"}
                align={"center"}
              >
                {isMobile ? (
                  <a
                    className={`text-white text-capitalize f-32`}
                    title="Home"
                    style={{
                      height: "32px",
                      lineHeight: "32px",
                      marginTop: "-11px",
                    }}
                  >
                    <Button
                      className="hamburger-menu hide-in-mobile"
                      type="text"
                      size="large"
                      onClick={openMainMobileMenu}
                    >
                      <span
                        style={boxIconStyle}
                        className="f-32 hide-in-mobile"
                      >
                        <MenuOutlined color="#ffffff" />
                      </span>
                    </Button>
                  </a>
                ) : (
                  <a
                    href="/"
                    className={`text-white text-capitalize f-32 hide-in-mobile`}
                    title="Home"
                    style={{ height: "32px", lineHeight: "32px" }}
                  >
                    <span style={boxIconStyle} className="f-32 hide-in-mobile">
                      <HomeOutlined />
                    </span>
                  </a>
                )}

                {!showDropDownCategories ? (
                  <a
                    href="#"
                    className={` ${
                      isMobile ? "mobile-menu-toggle" : ""
                    } category-toggle text-white text-capitalize hide-in-mobile`}
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    aria-label="menu-toggle"
                    data-display="static"
                    title="Browse Categories"
                    onMouseEnter={() => setDropDownCategoriesShown(true)}
                    onMouseLeave={() => setDropDownCategoriesShown(false)}
                    style={{ height: "42px", lineHeight: "42px" }}
                  >
                    <span style={boxIconStyle}>
                      <UnorderedListOutlined size={32} width={32} />
                    </span>
                  </a>
                ) : (
                  <a
                    href="#"
                    className={` ${
                      isMobile ? "mobile-menu-toggle" : ""
                    } category-toggle text-white text-capitalize hide-in-mobile`}
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    aria-label="menu-toggle"
                    data-display="static"
                    title="Browse Categories"
                  >
                    <span style={boxIconStyle}>
                      <UnorderedListOutlined size={32} width={32} />
                    </span>
                  </a>
                )}

                <a className={`text-white text-capitalize`} href={linkRFQ()}>
                  <img
                    width="32"
                    alt=" "
                    className="rfq-design-icon hide-in-mobile"
                    src="/assets/images/rfq.png"
                  />
                </a>

                <a
                  className={`text-white text-capitalize hide-in-mobile mt--3 ${
                    isLoggedIn ? "" : "tooltipx messenger bottom"
                  }`}
                  href={isLoggedIn ? linkMessenger() : "#"}
                >
                  <Badge
                    count={
                      isLoggedIn
                        ? profileCounters.unreadRFQMessages +
                          profileCounters.unreadInquiryMessages
                        : 0
                    }
                    size="default"
                    offset={[10, 2]}
                  >
                    <LiaCommentsSolid className="white-text" size={40} />
                  </Badge>
                </a>
                {/*       
                <Link
                  to="/messenger/rfqs"
                  className={` ${
                    isMobile ? "mobile-menu-toggle" : ""
                  } category-toggle text-white text-capitalize`}
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  aria-label="menu-toggle"
                  data-display="static"
                  title="Open Messenger"
                  style={{ height: "42px", lineHeight: "42px" }}
                >
                  <span style={boxIconStyle}>
                    <CommentOutlined size={32} width={32} />
                  </span>
                </Link>
                */}

                <SearchCard
                  onSearch={searchProducts}
                  placeholder={"What are you looking for?"}
                  data={searchCardData}
                  offset={964}
                  width={680}
                  zIndex={18}
                />
              </Flex>
            </div>
          </div>

          {/*  End of Header Middle   */}

          <div className="header-bottom sticky-content fix-top sticky-header has-dropdown">
            <div className="container">
              <div className="inner-wrap">
                <div className="header-left">
                  <div
                    className="dropdown category-dropdown has-border show-dropdown"
                    data-visible="true"
                  >
                    {String(window.location.href).includes("legal/") ? (
                      <>
                        <div
                          className="dropdown-box text-default"
                          style={{
                            marginTop: showDropDownCategories
                              ? "default"
                              : "-15px",
                          }}
                          onMouseEnter={() => setDropDownCategoriesShown(true)}
                          onMouseLeave={() => setDropDownCategoriesShown(false)}
                        >
                          <ul className="menu vertical-menu category-menu">
                            {legalDocs.map((legal: any, index: number) => (
                              <>
                                {index <= 7 && (
                                  <li key={index} className="all-categories-li">
                                    <Link to={`/legal/${legal.slug}`}>
                                      {legal.name}
                                    </Link>
                                  </li>
                                )}
                              </>
                            ))}
                          </ul>
                        </div>
                      </>
                    ) : (
                      <>
                        {(showDropDownCategories ||
                          dropDownCategoriesShown) && (
                          <div
                            className="dropdown-box text-default"
                            style={{
                              marginTop: showDropDownCategories
                                ? "default"
                                : "-15px",
                            }}
                            onMouseEnter={() =>
                              setDropDownCategoriesShown(true)
                            }
                            onMouseLeave={() =>
                              setDropDownCategoriesShown(false)
                            }
                          >
                            <ul className="menu vertical-menu category-menu">
                              {superCatsLoaded
                                ? productSuperCategories.map(
                                    (category: any, index: number) => (
                                      <>
                                        <li
                                          key={index}
                                          className="all-categories-li"
                                        >
                                          <Link
                                            to={`/categories/${category.id}`}
                                          >
                                            {category.name}
                                          </Link>
                                        </li>
                                      </>
                                    )
                                  )
                                : [...Array(8)].map((e, i) => (
                                    <li key={i}>
                                      <Link to="#">
                                        {<Skeleton count={1} height={20} />}
                                      </Link>
                                    </li>
                                  ))}

                              <li>
                                <Link
                                  to="/categories"
                                  className="font-weight-bold text-uppercase ls-25"
                                >
                                  View All Categories
                                </Link>

                                <ul className="megamenu">
                                  <li style={{ width: "33.33%" }}>
                                    {productSuperCategoriesChunks1.map(
                                      (category: any, index: number) => (
                                        <div key={index}>
                                          <h6 style={{ fontWeight: "600" }}>
                                            {category.name}
                                          </h6>
                                          <hr className="divider" />
                                          <ul>
                                            {category.children.map(
                                              (child: any, index2: number) => (
                                                <>
                                                  {index2 < 4 && (
                                                    <li key={index2}>
                                                      <Link
                                                        to={`categories/${child.id}`}
                                                        className="drawer-category"
                                                        style={{
                                                          padding: "5px 0px",
                                                          fontSize: "1.1rem",
                                                          color: "#666",
                                                          fontWeight: "500",
                                                          letterSpacing:
                                                            "-0.007em",
                                                        }}
                                                      >
                                                        {child.name}
                                                      </Link>
                                                    </li>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )
                                    )}
                                  </li>
                                  <li style={{ width: "33.33%" }}>
                                    {productSuperCategoriesChunks2.map(
                                      (category: any, index: number) => (
                                        <div key={index}>
                                          <h6 style={{ fontWeight: "600" }}>
                                            {category.name}
                                          </h6>
                                          <hr className="divider" />
                                          <ul>
                                            {category.children.map(
                                              (child: any, index2: number) => (
                                                <>
                                                  {index2 < 4 && (
                                                    <li key={index2}>
                                                      <Link
                                                        to={`categories/${child.id}`}
                                                        className="drawer-category"
                                                        style={{
                                                          padding: "5px 0px",
                                                          fontSize: "1.1rem",
                                                          color: "#666",
                                                          fontWeight: "500",
                                                          letterSpacing:
                                                            "-0.007em",
                                                        }}
                                                      >
                                                        {child.name}
                                                      </Link>
                                                    </li>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )
                                    )}
                                  </li>
                                  <li style={{ width: "33.33%" }}>
                                    {productSuperCategoriesChunks3.map(
                                      (category: any, index: number) => (
                                        <div key={index}>
                                          <h6 style={{ fontWeight: "600" }}>
                                            {category.name}
                                          </h6>
                                          <hr className="divider" />
                                          <ul>
                                            {category.children.map(
                                              (child: any, index2: number) => (
                                                <>
                                                  {index2 < 4 && (
                                                    <li key={index2}>
                                                      <Link
                                                        to={`categories/${child.id}`}
                                                        className="drawer-category"
                                                        style={{
                                                          padding: "5px 0px",
                                                          fontSize: "1.1rem",
                                                          color: "#666",
                                                          fontWeight: "500",
                                                          letterSpacing:
                                                            "-0.007em",
                                                        }}
                                                      >
                                                        {child.name}
                                                      </Link>
                                                    </li>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )
                                    )}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          id="tabbed-bar"
          className="sticky-footer sticky-content fix-bottom fixed tabbed-bar"
        >
          <a href="/" className="sticky-link active">
            <Icon.Home
              color="#444444"
              size={24}
              className="tabbed-bar-icon mb-3 mt-1"
            />
            <p>Home</p>
          </a>
          <a
            href={userType === "BUYER" ? "/rfqs/create" : "/rfq/marketplace"}
            className="sticky-link"
          >
            <img
              width="32"
              alt=" "
              className="rfq-design-iconx"
              src="/assets/images/rfq-black.png"
            />
            <p>{userType === "BUYER" ? "Create RFQ" : "Marketplace"}</p>
          </a>
          <a 
            onClick={(e) => {
              isLoggedIn ? (window.location.href="/kommand-mobile") : openLoginModal();
            }}
            className="sticky-link"
          >
            <Badge
              count={
                parseInt(profileCounters.unreadInquiryMessages) +
                parseInt(profileCounters.unreadRFQMessages)
              }
              style={{ backgroundColor: "#542278" }}
            >
              <Icon.Mail
                color="#444444"
                size={24}
                className="tabbed-bar-icon mb-3 mt-1"
              />
            </Badge>
            <p>My Messages</p>
          </a>
          <a 
          onClick={(e) => {
              isLoggedIn ? setMobileProfileMenuOpen(true) : openLoginModal();
            }}
            className="sticky-link">
            <Icon.User
              color="#444444"
              size={24}
              className="tabbed-bar-icon mb-3 mt-1"
            />
            <p>My Profile</p>
          </a>
        </div>
      </div>
      {/* End of Header */}

      <LoginComponent
        isOpened={isLoginModalOpen}
        modalTitle={"Login"}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={onLoginSuccessHandler}
        onLoginError={onLoginFailHandler}
      ></LoginComponent>

      <Drawer
        title={
          <>
            <UserOutlined /> {userDisplayName}
          </>
        }
        placement="bottom"
        width={500}
        onClose={closeMobileUserProfile}
        open={isMobileProfileMenuOpen}
      >
        <Menu
          style={{ width: "100%" }}
          mode="vertical"
          items={menuProfileItems}
          getPopupContainer={function test(node) {
            return node.parentNode as HTMLElement;
          }}
        />
      </Drawer>

      <Drawer
        title="Main navigation menu"
        placement="left"
        width={280}
        onClose={closeMainMobileMenu}
        open={isMobileMainMenuOpen}
      >
        <Menu
          style={{ width: "100%" }}
          mode="inline"
          items={menuMainItems}
          getPopupContainer={function test(node) {
            return node.parentNode as HTMLElement;
          }}
        />
      </Drawer>
    </>
  );
};
