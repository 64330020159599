import * as React from "react";
import { useState } from "react";
import ProductSimple from "./Widgets/ProductSimple";
export default ({ productList }:any) => {

  const productsWithRanking: any[] = [];

  productList.map((product: any) => {

    if (Array.isArray(product.prices)) {

      if (
        product.prices.length > 1 &&
        product.displayPrice != false &&
        product.prices[0].price != product.prices[1].price
      ) {
        product.ranking = 1;
      }

      if (
        !product.hasOwnProperty("ranking") &&
        product.displayPrice != false &&
        parseFloat(product.price) > 0
      ) {
        product.ranking = 2;
      }

      if (!product.hasOwnProperty("ranking")) {
        product.ranking = 3;
      }

      productsWithRanking.push(product);
    }

  })

  const sortedProducts = productsWithRanking.sort((a, b) => {

    return a.ranking - b.ranking;

  });


  const [resultsFetched, setResultsFetched] = useState(true);
  const onSetProductPreviewHandler = (params: any) => { };
  return (
    <>
      {/*    Start of Main    */}
      {resultsFetched && (
        <>
          <nav className="toolbox sticky-toolbox sticky-content fix-top">
            <div className="toolbox-left">
              <a
                href="#"
                className="btn btn-primary btn-outline btn-rounded left-sidebar-toggle 
                                        btn-icon-left d-block d-lg-none"
              >
                <i className="w-icon-category"></i>
                <span>Filters</span>
              </a>
              <div className="toolbox-item toolbox-sort select-box text-dark">
                <label>Sort By :</label>
                <select name="orderby" className="form-control">
                  <option value="default">Default sorting</option>
                  <option value="popularity">Sort by popularity</option>
                  <option value="rating">Sort by average rating</option>
                  <option value="date">Sort by latest</option>
                  <option value="price-low">Sort by price: low to high</option>
                  <option value="price-high">Sort by price: high to low</option>
                </select>
              </div>
            </div>
            <div className="toolbox-right">
              <div className="toolbox-item toolbox-show select-box">
                <select name="count" className="form-control">
                  <option value="9">Show 9</option>
                  <option value="12">Show 12</option>
                  <option value="24">Show 24</option>
                  <option value="36">Show 36</option>
                </select>
              </div>
              <div className="toolbox-item toolbox-layout">
                <a
                  href="vendor-wc-store-product-grid.html"
                  className="icon-mode-grid btn-layout active"
                >
                  <i className="w-icon-grid"></i>
                </a>
                <a
                  href="vendor-wc-store-product-list.html"
                  className="icon-mode-list btn-layout"
                >
                  <i className="w-icon-list"></i>
                </a>
              </div>
            </div>
          </nav>

          <div className="row search">
            {sortedProducts.map((product: any) => (
              <div className="product product-single col-md-3 col-sm-12  mb-3 product-single-pb-0">
                <ProductSimple
                  onSetProductPreview={onSetProductPreviewHandler}
                  product={product}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {/*    End of Main    */}
    </>
  );
};
