import React, { useEffect, useState } from "react";

import { Col, Row, Tabs, TabsProps } from "antd";
import { BusinessContactDetails } from "./BusinessContactDetails";
import { BusinessDetails } from "./BusinessDetails";
import { Profile } from "./model/Profile";
import AxiosAPI from "../../../../../helpers/AxiosAPI";
import { BusinessDocuments } from "./BusinessDocuments";
import UserManagementPage from "../../users/UserManagementPage";
import "../profile-wizard.scss";
const CompanySettings: React.FC = () => {
  const [profile, setProfile] = useState(new Profile());

  useEffect(() => {
    AxiosAPI.getProfileData().then((res: any) => {
      setProfile(res.data);
    });
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <BusinessContactDetails profile={profile}></BusinessContactDetails>
        </Col>

        <Col span={24}>
          <BusinessDetails profile={profile}></BusinessDetails>
        </Col>

        <Col span={24}>
          <BusinessDocuments profile={profile}></BusinessDocuments>
        </Col>

        <Col span={24}>
          <div className="card mb-5">
            <div className="collapse show">
              <div className="card-body border-top p-9">
                <UserManagementPage></UserManagementPage>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export { CompanySettings };
