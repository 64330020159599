import React, { useEffect, useState } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import { Button, Divider, Flex, message, Modal, Tag } from "antd";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import TagInput from "./TagInput";
import { CheckCircleFilled, MailOutlined } from "@ant-design/icons";

const InvitesModals: any = ({
  isModalOpen, setIsModalOpen,
  getListViewTrigger,
  rfqId,
}: any) => {

  const [modalSentInvitations, setModalSentInvitations] = useState(false);

  const [sendingInvites, setIsSendingInvites] = useState(false);

  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);

  const [supplierAddresses, setSupplierAddresses] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();

  const [addressList, setAddressList] = useState([]);

  const updateEmailAddresses: any = (emails: any) => {
    setEmailAddresses(emails);

    autoChanged(emails);

    console.log("::updateEmailAddresses::", emails);
  };
    
  const autoChanged: any = (values: Array<any>) => {
    const payload: Array<any> = values.map((email: string) => {
      return {
        email: email,
        inviteFrom: rfqId > 0 ? "RFQ":"OTHER",
        inviteFromId: rfqId,
      };
    });

    setAddressList(payload);

    console.log("TARGET::", values, payload);
  };

  const sendInvitations: any = () => {
    setIsSendingInvites(true);
    AxiosAPI.inviteMultipleSuppliers(addressList)
      .then((res) => {
        console.log("INVITE", res.data);
        setIsModalOpen(false);
        setIsSendingInvites(false);
        getListViewTrigger();
        
        AxiosAPI.setRewardsEvent("INVITE_SUPPLIER", {
          status: "PENDING",
          sent: new Date().getTime(),
          addresses: addressList,
        });
        setModalSentInvitations(true);
      })
      .catch((error: any) => {
        console.log("ERROR", error);
        setIsModalOpen(false);
        setIsSendingInvites(false);
        messageApi.open({
          type: "error",
          content: "An error occured while sending invites. Please try again.",
        });
      });
  };

  useEffect(() => {
    setIsModalOpen(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    
    AxiosAPI.getAllSellers()
      .then((res) => {
        const emails: any = res.data.map((supplier: any) => {
          return supplier.primaryContact.emailAddress;
        });
        setSupplierAddresses(emails);
      })
      .catch((error) => {
        console.log(":: SUPPLIERS ERROR::", error);
      });
      
  }, []);

  return (
    <>
      {contextHolder}
      <Modal
        title="Invite suppliers to Supply Zone"
        centered
        open={isModalOpen}
        closable
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={
          <>
            <Divider orientation="center" />
            <Flex justify={"flex-end"} align={"flex-end"}>
              <Button
                size="large"
                type="default"
                onClick={() => {
                  setIsModalOpen(false);
                }}
                style={{ marginRight: "20px" }}
              >
                Close
              </Button>
              <Button
                size="large"
                type="primary"
                loading={sendingInvites}
                onClick={() => {
                  sendInvitations();
                }}
              >
                Send Invitation
              </Button>
            </Flex>
          </>
        }
      >
        <p className="mt-4">To:</p>
        <p>
          <TagInput
            placeholder="Enter email addresses"
            value={emailAddresses}
            supplierAddresses={supplierAddresses}
            onChange={(value) => updateEmailAddresses(value)}
          />
        </p>
      </Modal>
      <Modal
        title="Invitations sent"
        centered
        closable
        onCancel={() => {
          setAddressList([]);
          setEmailAddresses([]);
          setModalSentInvitations(false);
        }}
        open={modalSentInvitations}
        footer={
          <>
            <Divider orientation="center" />
            <Flex justify={"flex-end"} align={"flex-end"}>
              <Button
                size="large"
                type="default"
                onClick={() => {
                  setAddressList([]);
                  setEmailAddresses([]);
                  setModalSentInvitations(false);
                }}
                style={{ marginRight: "20px" }}
              >
                Close
              </Button>
              <Button
                size="large"
                type="primary"
                loading={sendingInvites}
                onClick={() => {
                  setAddressList([]);
                  setEmailAddresses([]);
                  setIsModalOpen(true);
                  setModalSentInvitations(false);
                }}
              >
                Invite More People
              </Button>
            </Flex>
          </>
        }
      >
        <h3 className="mt-4">
          <CheckCircleFilled size={24} color="#00cc00" /> You have invited {addressList.length}{" "}
          {addressList.length < 2 ? "supplier" : "suppliers"}
        </h3>
        <hr />
        <p>
          {addressList.map((address: any, addressIndex: number) => (
            <Tag key={addressIndex}>{address.email}</Tag>
          ))}
        </p>
        <br />
        <p>
          <MailOutlined />{" "}
          <a
            href="#"
            onClick={() => {
              window.location.href = "/buyer/invites";
            }}
          >
            Manage invitations
          </a>
        </p>
      </Modal>
    </>
  );
};
export { InvitesModals };
