import React, { FunctionComponent, useState } from "react";
import { format } from "timeago.js";

interface DocumentListItemProps {
  children?: React.ReactNode;
  isSelected?: boolean;
  user?: any;
  selectedUser?: any;
  getInitialsFromName?: any;
  userIndex?: number;
  setSelectedUserHandler: (item: any, index: number) => void;
}

const isSelectedClass: string =
  "chat-list box cursor-pointer relative flex items-center px-5 py-3 bg-theme-1 dark:bg-theme-1 bg-[#dddddd]";

const isNotSelectedClass: string =
  "chat-list  cursor-pointer relative flex items-center px-5 py-3 mt-0 border-bottom border-gray-200";

const ChatUserListItem: FunctionComponent<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  const {
    isSelected,
    user,
    selectedUser,
    getInitialsFromName,
    userIndex,
    setSelectedUserHandler,
  }: any = props;

  const [timeAgo, setTimeAgo] = React.useState("");

  const [message, setMessage] = React.useState("");

  const [unread, setUnread] = React.useState(0);

  React.useEffect(() => {
    if (
      typeof user !== "undefined" &&
      user !== undefined &&
      user !== "undefined"
    ) {
      let chats: any = user.messageThread.thread.reverse();

      setMessage(chats[0].message);

      setTimeAgo(format(chats[0].date));

      let msgCounter = 0;

      chats.map((chat: any, chatIndex: number) => {
        if (chat.status === "UNREAD") {
          msgCounter++;

          //console.log("XXXAAAA", chat, chatIndex, chats.length - 1);

          //console.log("QQQ uSER @@@", user);

          if (chatIndex === chats.length - 1) {
            setTimeAgo(format(chat.date));

            setUnread(msgCounter);
          }
        }
      });
    }
  }, [user]);

  const [displayName, setDisplayName] = useState("John Doe");
  

  React.useEffect(() => {

    if (typeof user === "object") {

      if ("responseCompanyContactName" in user) {
        setDisplayName(user.responseCompanyContactName)
      } else {
        if ("messageThread" in user && "supplierCompanyName" in user.messageThread) {

          setDisplayName(user.messageThread.supplierCompanyName);

        } else {

          setDisplayName(user.messageThread.senderName);

        }

      }
    }

  }, []);

  return (
    <>
      {selectedUser !== null && user !== null && (
        <div
          className={`chat-list-user-item ${isSelected ? "active" : ""}`}
          onClick={(e) => {
            setSelectedUserHandler(user);
          }}
        >
          <div className="row">
            <div className="col-2">
              <img
                alt={""}
                src={`https://ui-avatars.com/api/?format=svg&background=552277&color=fff&size=48&bold=true&rounded=true&name=${displayName}`}
              />
            </div>
            <div className="col-7">
              <span className="display-name">{displayName}</span>

              <br />
              <span className="message-text">
                {message}
              </span>

            </div>
            <div className="col-3">
              <span className="text-right time-ago"> {timeAgo}</span>
              {unread > 0 && (
                <div className="badge-counter float-right">

                  <span className="badge-counter-item">{unread}</span>

                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatUserListItem;
