/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import * as Icon from "react-feather";
import Snippets from "../../../helpers/Snippets";
import { Button, Checkbox, Col, Input, Modal, Row, Form, Divider } from "antd";

import {
  UserPilotHelper,
  UserPilotEvents,
} from "../../../helpers/UserPilotHelper";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useAppDataContext } from "../../../data";

type BodyRFCProps = {
  onLoginSuccess?: Function;
  onLoginError?: Function;
  onClose: any;
  isOpened?: boolean;
  modalTitle: string;
  children?: React.ReactNode;
};

export const LoginComponent: React.FC<BodyRFCProps> = ({
  children,
  ...props
}): any | null => {
  /// DATA CONTEXT

  const {
    userAccessToken,
    setUserAccessToken,
    userDisplayName,
    setUserDisplayName,
    userEmailAddress,
    setUserEmailAddress,
    userType,
    setUserType,
    userData,
    setUserData,
    userProfile,
    setUserProfile,
    isLoggedIn,
    setIsLoggedIn,
    loginUser,
    logoutUser,
  } = useAppDataContext();

  const { onLoginSuccess, onLoginError, onClose, isOpened, modalTitle } = props;

  /// END DATA CONTEXT

  const [modalOpen, setModalOpen] = useState(false);
  const [ajaxBusy, setAjaxBusy] = React.useState(false);

  const [emailAddress, setEmailAddress] = React.useState("");
  const [password, setPassword] = React.useState("");

  useEffect(() => {
    if (isOpened) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }

    return () => {
      setModalOpen(false);
    };
  }, [isOpened]);

  const onFinish = (values: any) => {
    //console.log("Received values of form: ", values);
  };

  const attemptLogin = () => {
    setAjaxBusy(true);

    loginUser(
      emailAddress,
      password,
      (result?: any) => {

        setAjaxBusy(false);

        isOpened && typeof onLoginSuccess === "function" && onLoginSuccess(result);

        setModalOpen(false);

        UserPilotHelper.logEvent(UserPilotEvents.LOGIN, {});

      },
      (error: any, swalPayload: any) => {

        setAjaxBusy(false);

        isOpened && typeof onLoginError === "function" && onLoginError(error);

        //console.log("ERROR: attemptLogin :", error);

        swalPayload && (window as any).Swal.fire(swalPayload);
        
      }
    );
    
  };

  const [validationErrorEmailAddress, set_validationErrorEmailAddress] =
    React.useState(false);

  const validateEmailAddress = (e: any) => {
    var re = /\S+@\S+\.\S+/;
    set_validationErrorEmailAddress(!re.test(e.target.value));
  };

  const [validationErrorPassword, set_validationErrorPassword] =
    React.useState(false);

  const validatePassword = (e: any) => {
    set_validationErrorPassword(password === "" || password.length < 4);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onCancel={onClose}
        title={modalTitle || "Login"}
        centered
        closable
        destroyOnClose
        className="antdx-login-modal"
        footer={<></>}
      >
        <Row>
          <Col span={24}>
            <br />
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  {
                    required: true,
                    message: "Please input your email address",
                  },
                ]}
                hasFeedback={emailAddress.length > 1}
                validateStatus={
                  validationErrorEmailAddress ? "error" : "success"
                }
                help={
                  validationErrorEmailAddress
                    ? "Please enter a valid email address"
                    : ""
                }
              >
                <Input
                  size="large"
                  allowClear
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                  value={emailAddress}
                  onBlur={validateEmailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                className="mt-6"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
                hasFeedback={password.length > 1}
                validateStatus={validationErrorPassword ? "error" : "success"}
                help={
                  validationErrorPassword ? "Please enter your password" : ""
                }
              >
                <Input.Password
                  allowClear
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  value={password}
                  className={`${validationErrorPassword ? "error" : ""}`}
                  onBlur={validatePassword}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Item>

              {/* 
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Form.Item>
              */}

              <Form.Item>
                <Button
                  disabled={emailAddress === "" || password === ""}
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={attemptLogin}
                  loading={ajaxBusy}
                >
                  Log in
                </Button>
              </Form.Item>

              {React.Children.count(children) > 0 ? (
                <>{children}</>
              ) : (
                <>
                  <Divider plain>Not yet registered?</Divider>
                  <label htmlFor="remember-me">
                    <span style={{display: 'block', textAlign: 'center'}}>
                      Register as a <a className="tooltipx supplier bottom" href="#">Supplier</a> or{" "}
                      <a className="tooltipx bottom" href="#">
                        Buyer
                      </a>{" "}
                      to continue
                    </span>
                  </label>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
