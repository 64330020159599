import * as React from "react";

export default () => {

  const search:any = () => {

    const _formElm:any = document.getElementById("mobile-search");

    //console.log("Mobile search");

    _formElm.submit();

  }

  return (
    <>{" "}</>
  );
};
