import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Button, Flex, Tabs, Input, Row, Col, Steps, theme, Modal, message } from "antd";
import Skeleton from "react-loading-skeleton";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { CategorySelection } from "./CategorySelection";
import { PriceTables } from "./PriceTables";
import { BusinessProfile } from "./BusinessProfile";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";
import { BiSolidBadgeCheck } from 'react-icons/bi';
import { useAppDataContext } from '../../../../data/context/AppDataContext';
import { PriceTables2 } from "./PriceTables2";

const SupplierActivation: React.FC = () => {
  let { rfqId } = useParams();

  const {
    selectedCategorriesForSupplier,
  } = useAppDataContext();

  const { token } = theme.useToken();

  const [confettiShown, setConfettiShown] = useState(false);

  const [current, setCurrent] = useState(0);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const [companySize, setCompanySize] = useState("1");

  const [salesRepresenative, setSalesRepresenative] = useState("1");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [organization, setOrganization] = useState("");

  const [designation, setDesignation] = useState("");

  const [annualTurnOver, setAnnualTurnOver] = useState("100000");

  const [messageApi, contextHolder] = message.useMessage();

  const resetScroll: any = () => {
    const element = document.getElementById("top-anchor");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const next = () => {
    if (selectedCategorriesForSupplier.length === 0) {
      messageApi.warning(
        "Please select at least 1 category below"
      );
      return;
    }
    setCurrent(current + 1);
    resetScroll();
  };

  const prev = () => {
    setCurrent(current - 1);
    resetScroll();
  };

  const onSelectedCategories: any = (data: any) => {

    console.log(">>> DATA >>>", data);

    if (Array.isArray(data) && data.length > 0) {
      
  const uniqueData = data.filter(
    (item:any, index:number, self:any) => index === self.findIndex((t:any) => t.id === item.id)
    );
    
    setSelectedCategories(uniqueData);

    }

  };

  useEffect(() => {

    console.log(
      "::(selectedCategorriesForSupplier)::",
      selectedCategorriesForSupplier
    );

  }, [selectedCategorriesForSupplier]);

  const onCompanySize: any = (value: string) => {
    setCompanySize(value);
  };

  const onSalesRepresenative: any = (value: string) => {
    setSalesRepresenative(value);
  };

  const onPhoneNumber: any = (value: string) => {
    setPhoneNumber(value);
  };

  const onOrganization: any = (value: string) => {
    setOrganization(value);
  };

  const onDesignation: any = (value: string) => {
    setDesignation(value);
  };

  const onAnnualTurnOver: any = (value: string) => {
    setAnnualTurnOver(value);
  };

  const [isSavingProfile, setIsSavingProfile] = useState(false);

  const [openPricing, setOpenPricing] = useState(false);

  const [isActivated, setIsActivated] = useState(false);

  const saveSupplierCategories: any = (callBackFunction:any) => {

    const supplierData: any = {
      categories: selectedCategorriesForSupplier,
      companySize: companySize,
      industry: "",
      websiteUrl: "",
      salesRepresentatives: salesRepresenative,
    };

    console.log("::SUPPLIER_CATEGORIES::", supplierData);

    AxiosAPI.activateSupplier(supplierData)
      .then((res: any) => {
        console.log(":: SUPPLIER CATEGORIES SAVED!!! ::", res.data);
        callBackFunction();
      })
      .catch((error: any) => {
        console.log(":: SUPPLIER CATEGORIES ERROR ::", error);
      });
  };

  const saveSupplierData: any = () => {

    saveSupplierCategories(() => {

      if (current === 1) {
        setIsSavingProfile(true);
      } else {
        setIsSavingProfile(false);
      }

      const supplierData: any = {
        selectedCategories: selectedCategorriesForSupplier,
        totalEmployees: String(companySize),
        companySize: String(companySize),
        salesRepresentatives: String(salesRepresenative),
        primaryContact: { phoneNumber: phoneNumber },
        name: organization,
        primaryContactPerson: { designation: designation },
        totalAnnualSales: String(annualTurnOver),
      };

      console.log("::SUPPLIER_DATA::", supplierData);

      AxiosAPI.updateSupplierFields(supplierData)
        .then((res: any) => {
          console.log(":: SUPPLIER ACTIVATED!!! ::", res.data);
          if (current === 1) {
            setIsSavingProfile(false);
            setConfettiShown(true);
            setOpenPricing(true);
            setIsActivated(true);
            setTimeout(() => {
              setConfettiShown(false);
            }, 6000);
          }
        })
        .catch((error: any) => {
          setIsSavingProfile(false);
          console.log(":: SUPPLIER ACTIVATION ERROR ::", error);
        });
      
    });

  };

  const [proceedURL, setProceedURL] = useState("");

  const supplierActivationComplete: any = () => {
    if (rfqId === null) {
      return;
    }

    window.location.href = proceedURL;
  };

  const renderSteps: any = () => {
    setSteps([
      {
        title: "Categories",
        content: (
          <CategorySelection
            selectedCategories={selectedCategories}
            setSelectedCategories={onSelectedCategories}
          />
        ),
      },
      {
        title: "Business Profile",
        content: (
          <BusinessProfile
            isActivated={isActivated}
            setCompanySize={onCompanySize}
            companySize={companySize}
            setSalesRepresenative={onSalesRepresenative}
            salesRepresenative={salesRepresenative}
            setPhoneNumber={onPhoneNumber}
            phoneNumber={phoneNumber}
            setOrganization={onOrganization}
            organization={organization}
            setDesignation={onDesignation}
            designation={designation}
            setAnnualTurnOver={onAnnualTurnOver}
            annualTurnOver={annualTurnOver}
          />
        ),
      },
    ]);
  }

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    renderSteps();
  }, [isActivated]);

  useEffect(() => {
    console.log(":: PROFILE UPDATE ::");
  }, [selectedCategories]);

  useEffect(() => {
    renderSteps();
    console.log(":: PROFILE UPDATE ::", [
      setCompanySize,
      companySize,
      salesRepresenative,
      phoneNumber,
      organization,
      designation,
      annualTurnOver,
    ]);
  }, [
    setCompanySize,
    companySize,
    salesRepresenative,
    phoneNumber,
    organization,
    designation,
    annualTurnOver,
  ]);

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: `transparent /* token.colorFillAlter */`,
    borderRadius: token.borderRadiusLG,
    border: `none /*1px dashed ${token.colorBorder}*/`,
    margin: "auto",
    textAlign: "center",
  };

  useEffect(() => {
    console.log(":: CURRENT STEP ::", current);
  }, [current]);

  useEffect(() => {

    console.log(":: SELECTED CATEGORIES ::", selectedCategories);

  }, [selectedCategories]);

  useEffect(() => {
    if (`${rfqId}` === "0") {
      setProceedURL("/rfq/marketplace");
    } else {
      setProceedURL("/rfq/marketplace");
      //setProceedURL("/messenger/rfqs/?rfq-id=" + rfqId);
    }
  }, [rfqId]);

  return (
    <>
      {contextHolder}

      <main className="main">
        <div className="container pt-2">
          <div className="product-wrapper-1  mb-1">
            <div className="title-link-wrapper pb-2 mb-1 w-100">
              <Flex className="w-100" align="center" justify="space-between">
                <h2
                  className="title ls-normal mb-0 w-100 blackx"
                  id="top-anchor"
                >
                  Supplier Activation
                </h2>
                <Flex
                  className=""
                  align="center"
                  justify="space-between"
                  gap={24}
                >
                  {!isActivated && (
                    <>
                      {current > 0 && (
                        <Button
                          size="large"
                          type="default"
                          onClick={() => prev()}
                        >
                          Previous
                        </Button>
                      )}
                      {current > -1 && (
                        <Button
                          size="large"
                          type="primary"
                          onClick={() => {
                            current === 1 ? saveSupplierData() : next();
                          }}
                          loading={isSavingProfile}
                        >
                          {current === 1 ? "Save Profile" : "Next"}
                        </Button>
                      )}
                    </>
                  )}
                  {isActivated && (
                    <Button
                      size="large"
                      type="primary"
                      block
                      onClick={() => supplierActivationComplete()}
                    >
                      Continue
                    </Button>
                  )}
                </Flex>
              </Flex>
            </div>

            {/* 
            <Steps
              current={current}
              items={items}
              className={"mb-10"}
              labelPlacement="vertical"
            />
            */}
            {confettiShown && <Confetti />}
            <div style={contentStyle} className="m-auto mt-10 ">
              {steps.length > 0 && <>{steps[current].content}</>}
            </div>
            <div className="title-link-wrapper top pb-2 mt-5 w-100">
              <Flex className="w-100" align="center" justify="space-between">
                <h2 className="title ls-normal mb-0 w-100 blackx"> </h2>
                <Flex
                  className=""
                  align="center"
                  justify="space-between"
                  gap={24}
                >
                  {!isActivated && (
                    <>
                      {current > 0 && (
                        <Button
                          size="large"
                          type="default"
                          onClick={() => prev()}
                        >
                          Previous
                        </Button>
                      )}
                      {current > -1 && (
                        <Button
                          size="large"
                          type="primary"
                          onClick={() => {
                            current === 1 ? saveSupplierData() : next();
                          }}
                          loading={isSavingProfile}
                        >
                          {current === 1 ? "Save Profile" : "Next"}
                        </Button>
                      )}
                    </>
                  )}
                  {isActivated && (
                    <Button
                      size="large"
                      type="primary"
                      block
                      onClick={() => supplierActivationComplete()}
                    >
                      Continue
                    </Button>
                  )}
                </Flex>
              </Flex>
            </div>
          </div>
        </div>
      </main>
      <Modal
        title=""
        centered
        open={openPricing}
        onOk={() => {
          setOpenPricing(false);
          supplierActivationComplete();
        }}
        onCancel={() => setOpenPricing(false)}
        className="pricing-modal"
        footer={<></>}
      >
        <PriceTables2
          rfqId={rfqId}
          proceedURL={proceedURL}
          supplierActivationComplete={supplierActivationComplete}
        />
        {confettiShown && <Confetti />}
      </Modal>
    </>
  );
};
export { SupplierActivation };
