/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { Outlet } from "react-router-dom";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import StickyFooter from "./ExtraElements/StickyFooter";
import ScrollTop from "./ExtraElements/ScrollTop";
import MobileMenu from "./ExtraElements/MobileMenu";
import NewsletterPopup from "./ExtraElements/NewsletterPopup";
import QuickView from "./ExtraElements/QuickView";

export default () => {
  return (
    <>
      {/* End of Page-wrapper */}
      <div className="page-wrapper">
        <PageHeader />
        <Outlet />
        <PageFooter />
      </div>
      {/* End of Page-wrapper */}
      <StickyFooter />
      <ScrollTop />
      <MobileMenu />
      <NewsletterPopup />
      <QuickView />
    </>
  );
};
