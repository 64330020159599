import React, { FC } from "react";
import { CompanySettings } from "./accounts/CompanySettings";

const CompanySettingsPageWrapper: FC = () => {
  return (
    <>
      <div className="profile-wizard mr-3 ml-3 mt-0">
      <main className="main profile-wizard"><div className="container pt-2">
      <div className="product-wrapper-1  mb-1">
        <div className="title-link-wrapper pb-2 mb-1">
          <h2 className="title ls-normal mb-0">Company Settings</h2>
        </div>
      </div>
        <CompanySettings />
        </div></main>
      </div>
    </>
  );
};

export default CompanySettingsPageWrapper;
