import * as React from "react";
import Snippets from "../../../../helpers/Snippets";
import axios from 'axios'
import ChatComposer from "../messenger/ChatComposer";
import { Button, Col, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FiPhone } from "react-icons/fi";
import ChatHistory from "../messenger/ChatHistory";
import { he } from "date-fns/locale";
import AxiosAPI from "../../../../helpers/AxiosAPI";
export default ({ showChatWindow, threadId, setShowChatWindow }: any) => {
    function getInitialsFromName(name: string): string {
        if (typeof name === "undefined") {
            return name;
        }

        name = name.replace("(", "").replace(")", "").replace(".", "");
        const names = name.split(" ");
        if (names.length === 0) {
            return name;
        }
        if (names.length === 1) {
            return names[0].substring(0, 1).toUpperCase();
        }
        const initials =
            names[0].substring(0, 1).toUpperCase() +
            names[1].substring(0, 1).toUpperCase();
        return initials;
    }

    const [token, setToken] = React.useState("");
    const [messageText, setMessageText] = React.useState("");
    const [isSending, setIsSending] = React.useState(false);
    const [messageHeader, setMessageHeader] = React.useState({ id: 0, subject: "Product title", thread: [], senderName: "", recipientId: 0 });
    const [userProfile, SetUserProfile] = React.useState({ id: 0 });
    const [dummyChatsIsLoading, setDummyChatsIsLoading] = React.useState();
    const [displayName, setDisplayName] = React.useState("...");
    const onDisplayNameHandler: any = (name: any) => {
        setDisplayName(name);
    }

    const [recipient, setRecipient] = React.useState({
        "id": 0,
        "person": {
            "title": "",
            "designation": "",
            "firstName": "",
            "lastName": ""
        },
        "contactDetails": {
            "emailAddress": "",
            "addressLine1": "",
            "addressLine2": "",
            "city": "",
            "phoneNumber": ""
        }
    })

    const fetchMessages = (token: string) => {

        const baseURL = Snippets.urls.getAPIBaseURL();
        var config = {
            method: 'get',
            url: baseURL + '/messages/' + threadId,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        axios(config).then(({ data: messageHeader }) => {
            if (messageHeader.id > 0) {
                setMessageHeader(messageHeader)
                const recipientId = (messageHeader.recipientId == Snippets.user.getCurrentUserId()) ? messageHeader.senderId : messageHeader.recipientId;
                AxiosAPI.apiRequest('GET', `/marketplace/users/${recipientId}`, {})
                    .then(({ data: recipient }) => {
                        setRecipient(recipient)
                    })
            }

        }).catch((error) => { });

    }
    const formatPhoneNumber = (phoneNumber: string) => {
        let formattedPhoneNumber = phoneNumber.replace(/^0/, '');
        formattedPhoneNumber = formattedPhoneNumber.replace('+263', '');
        return "+263" + formattedPhoneNumber;
    }
    const sendMessages = () => {

        if (messageText == "") return
        //todo: the recipient id should be actual user and not profile id
        setIsSending(true)
        const data = { id: messageHeader.id, subject: messageHeader.subject, recipientId: messageHeader.recipientId, message: messageText }
        const baseURL = Snippets.urls.getAPIBaseURL();
        var config = {
            method: 'post',
            url: baseURL + '/messages/',
            headers: {
                'Authorization': 'Bearer ' + Snippets.user.getCurrentUserAccessToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config).then(({ data: messageHeader }) => {
            setMessageHeader(messageHeader)
            setMessageText("")
            setIsSending(false)
        }).catch((error) => {
            setIsSending(false)
        });

    }


    const formatDate = (date: Date) => {
        const now = new Date();
        const diff = Math.abs(now.getTime() - date.getTime()) / 1000;

        if (diff < 60) {
            return `${Math.floor(diff)} seconds ago`;
        } else if (diff < 3600) {
            return `${Math.floor(diff / 60)} minutes ago`;
        } else if (diff < 86400) {
            return `${Math.floor(diff / 3600)} hours ago`;
        } else {
            return `${Math.floor(diff / 86400)} days ago`;
        }
    }


    const [textWrapperBottom,setTextWrapperBottom] = React.useState("35px")
    React.useEffect(() => {
        const userProfile: any = Snippets.user.getCurrentUserProfile();
        SetUserProfile(userProfile);
        fetchMessages(Snippets.user.getCurrentUserAccessToken())
    }, [threadId])



    return (
        <>
            <div className="instant-messenger card-with-title" style={{ display: (showChatWindow) ? "block" : "none" }}>

                <div className="title-link-wrapper mb-3">
                    <h2 className="title mb-1 pt-0 pb-0 thread-title-btn">
                        <span>{messageHeader.subject.substring(0, 30)}</span>
                        <Button onClick={() => { setShowChatWindow(false) }} className="chat-btn-close" type="text" ><CloseOutlined size={48} /></Button>
                    </h2>
                </div>
                <div className="thread-wrapper">
                    <Row>
                        <Col flex={"56px"}>
                            <img
                                alt={""}
                                src={`https://ui-avatars.com/api/?format=svg&background=cccccc&color=777777&size=48&bold=true&rounded=true&name=${getInitialsFromName(recipient.person.firstName + ' ' + recipient.person.lastName)}`}
                            />
                        </Col>
                        <Col flex="auto">
                            <h6>
                                <strong>{recipient.person.firstName + ' ' + recipient.person.lastName}</strong>
                            </h6>
                            <span style={{ fontSize: "12px;" }}>{recipient.person.designation}</span>

                        </Col>
                        <Col flex={"24px"}>
                            <a className="float-right" target="_blank" href={`https://dialer.supplyzone.ai/?phoneNumber=${formatPhoneNumber(recipient.contactDetails.phoneNumber)}`}>
                                <FiPhone size={24} />
                            </a>
                        </Col>
                    </Row>

                    <div id="scroll-chats" className="row scroll-chats mt-2" style={{ height: '65vh', maxHeight: '65vh;' }}>
                        <div className="col">
                            <ChatHistory
                                dummyChatsIsLoading={dummyChatsIsLoading}
                                chatHistory={messageHeader.thread}
                                userProfile={userProfile}
                                getInitialsFromName={getInitialsFromName}
                                onDisplayName={onDisplayNameHandler}
                            />
                        </div>
                    </div>

                    <div className="row mt-3 messenger-text-wrapper eee" style={{bottom:textWrapperBottom}}>
                        <img
                            src="/assets/images/shadow.png"
                            alt=""
                            className="messenger-text-shadow"
                        />
                        <div className="col">
                            <ChatComposer
                                chatMessageText={messageText}
                                setChatMessageText={setMessageText}
                                replyMessage={sendMessages}
                                replyMessageAttachment={sendMessages}
                                isSending={isSending}
                            />
                        </div>
                    </div>

                </div >

            </div>
        </>
    );
}