import * as React from "react";

export default () => {
  return (
    <> 
      <p>
        <b>Introduction&nbsp;</b>
      </p>

      <p>
        <span>
          This Privacy Policy describes and gives you clear and comprehensive
          information about the data that Inbox Group Private Limited collects
          from you, and provides an explanation of how and why we collect, use
          and manage that data.
        </span>
      </p>

      <p>
        <span>
          For the purposes of the General Data Protection Regulation (EU
          2016/679), the data controller is Inbox Group Private Limited (see
          Contact Details). Inbox Group Private Limited is based in Seychelles
          and is responsible for data collected in relation to websites and
          tradeshows managed by Supply Zone. You should be aware that although
          Inbox Group Private Limited may be principally responsible for looking
          after your personal data, information may be held in databases which
          can be accessed by other affiliated entities of Inbox Group.
        </span>
      </p>

      <p>
        <span>
          We may make changes to this Policy from time to time in order to keep
          it up to date and compliant with legal requirements. Please refer to
          the &quot;Last Updated&quot; date at the bottom of this page to see
          when this Policy was last revised. We may also notify you about
          significant changes by email, using the address you last provided or
          by posting an alert which will be displayed when you access our
          systems. This Policy is not intended to override the terms of any
          contract that you have with us or between buyers and suppliers (or any
          rights you might have available under applicable data protection
          laws).
        </span>
      </p>

      <p>
        <span>Contact Details&nbsp;</span>
      </p>

      <p>
        <span>
          The primary point of contact for all issues arising from this Policy,
          including requests to exercise data subject rights, is our Data
          Protection Officer. If you have a complaint or concern about how we
          use your personal information, please contact us in the first instance
          and we will attempt to resolve the issue as soon as possible. The Data
          Protection Officer can be contacted by:
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>Email at&nbsp;</span>
        <span>
          <a href="mailto:support@inboxgroup.ai">
            <span>Data Protection Officer</span>
          </a>
        </span>
      </p>

      <p>
        <span>
          You also have a right to lodge a complaint with a data protection
          authority for your country or region or where an alleged infringement
          of applicable data protection laws occurs.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>A list of EU Regulators may be found here:&nbsp;</span>
        <span>
          <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
            <span>
              http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
            </span>
          </a>
        </span>
        <span>.</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          A list of authorities participating in the Global privacy Enforcement
          network may be found here:&nbsp;
        </span>
        <span>
          <a href="https://www.privacyenforcement.net/about_the_network#members">
            <span>
              https://www.privacyenforcement.net/about_the_network#members
            </span>
          </a>
        </span>
      </p>

      <p>
        <span>
          If you are located in Seychelles, you may lodge your complaint with us
          or to the Office of the Privacy Commissioner for Personal Data,
          Seychelles:&nbsp;
        </span>
        <span>
          <a href="https://www.pcpd.org.hk/english/complaints/how_complaint/complaint/complaint.html">
            <span>
              https://www.pcpd.org.hk/english/complaints/how_complaint/complaint/complaint.html
            </span>
          </a>
        </span>
      </p>

      <p>
        <span>Third-party links&nbsp;</span>
      </p>

      <p>
        <span>
          Our website may, from time to time, contain links to and from the
          websites of our partner networks, advertisers and affiliates. If you
          follow a link to any of these websites, please note that these
          websites have their own privacy notices and that we do not accept any
          responsibility or liability for these notices. Please check these
          notices before you submit any personal information to these websites.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>How Do We Collect Your Personal Data?&nbsp;</span>
      </p>

      <p>
        <span>We collect your data in a variety of ways:</span>
      </p>

      <p>
        <b>
          <span>At Trade Shows, Roadshows, Seminars and Conferences</span>
        </b>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Registration Forms</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          We ask visitors to register their attendance by completing a form
          which we then enter into our systems.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Check-In updates</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          We ask visitors to update/complete data retrieved from previous
          registrations held in our system.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Business Cards</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>We scan and store details of visitors' business cards.</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Booth Visit History</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          Exhibitors use the Supply Zone Supplier App to scan visitors' badges
          when visitors call at their booths. Visit data is then uploaded to our
          servers.
        </span>
      </p>

      <p>
        <b>
          <span>Through Website Forms</span>
        </b>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Registration &amp; Inquiry Forms</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          We ask visitors to our online sites to register in order to
          personalize and use the site's messaging functions. We support both a
          full and a lite registration mode that captures minimal data.
          Additionally, we have a self-registration function for suppliers who
          wish to register as an advertiser on our site.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Subscription Forms</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          We capture details of site visitors' subscriptions and preferences to
          services such as Notifications, Product/Supplier Alerts and
          eMagazines.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Direct Order Forms</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          We capture details of sample and direct orders placed by visitors to
          advertisers on the site.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>MATCH Service</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          We capture basic details of buyers interested in applying for our
          MATCH service.
        </span>
      </p>

      <p className="">
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <span>Call Center Activities</span>
        </b>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Telemarketing</span>
        </b>
      </p>

      <p className="">
        <span>
          <span> </span>
        </span>
        <span>
          We periodically contact people and seek information for market surveys
          and research questionnaires or to qualify prospects for our services
          or those of our advertiser community, e.g., to qualify potential:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>visitors to our shows and/or online sites, or</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>advertisers/exhibitors to our shows and/or online sites</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Customer Service</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          Online users, and Trade Show visitors and potential advertisers
          contact us for enquiries, complaints, suggestions or feedback through
          our Customer Service Team via online chat system, hotline, fax or
          email.
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          Additionally, there are small numbers of potential advertisers who
          call in. Their details are qualified and entered into our systems.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <span>Systems Tracking Functions</span>
        </b>
      </p>

      <p>
        <span>
          We use Cookies and other related technologies on our sites. To the
          extent that information collected through Cookies and similar
          technologies constitutes personal information, the provisions in
          our&nbsp;
        </span>
        <span>
          <a href="https://www.supplyzone.ai/STM/help-faq/en/cookie-policy/">
            <span>Cookie Policy</span>
          </a>
        </span>
        <span>&nbsp;apply and complement this Privacy Policy.</span>
      </p>

      <p>
        <b>
          <span>Other Sources</span>
        </b>
      </p>

      <p>
        <span>
          We collect data from other sources, such as public databases, joint
          marketing partners and other third parties including third-party
          software applications and social media platforms. The data collected
          through these third-party sources will be combined with information we
          collect through our services where this is necessary to help manage
          our relationship with you.
        </span>
      </p>

      <p>
        <span>What Personal Data Do We Collect?&nbsp;</span>
      </p>

      <p>
        <span>The personal information we collect include:</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Contact and Communication Details</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          This includes your name, addresses, telephone numbers, email
          addresses, and social media contact details.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Business Details</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          Job title, role, company name, and company demographics, i.e., number
          of employees, turnover, etc. Please note that contact details
          typically are work related since our mission is to facilitate B2B
          trade.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Preferences</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          Marketing preferences inferred from subscriptions to product/supplier
          alerts, eMagazines, Trade Show news and online behavioral data.
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>Display and language preferences</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Communications</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>Details of calls/messaging made between:</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>
          Customer service team members and visitors and advertisers/exhibitors
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Sales Team members and advertisers/exhibitors or prospects</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>
          Advertisers/exhibitors and online visitors sent through Message
          Center, e.g., RFI, RFQ, RFS.
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>
          Advertisers/Exhibitors and online visitors sent though Tencent chat
          facility
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Buying &amp; Payment History</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          What sample requests or other orders placed between advertisers and
          site visitors
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>What seminars/events booked at Trade Shows</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>What eMagazines subscribed to</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Device and Log Information</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          The internet domain and IP address from where you access our websites
          and apps.
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          The type of browser software and operating system used to access our
          websites and apps.
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>The date and time you access our websites and apps</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          If you linked into our websites and apps from another website, the
          address of that website (referrer URL), and:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>
          The pages you enter, visit and exit our websites and apps from
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Your account log-in/online identifier</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>
          Unique device identifiers (such as the address of the device's
          wireless network interface (MAC Address), or mobile phone number used
          by the device)
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Online Behavioral Data</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          We use Cookies and other tracking technologies to capture activity on
          our sites, such as:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Session frequency</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Search terms used</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>
          Pages visited and how they were accessed (for path analysis)
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Products /suppliers inquired on or profiles viewed</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Whitepapers downloaded</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Apps downloaded</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Products saved and suppliers followed</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Products ordered</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp;</span>
        </span>
        <span>Products / suppliers shared through social media</span>
      </p>

      <p className="">
        <span>
          <span> </span>
        </span>
        <span>
          While this information itself does not hold personal identifiers, it
          may be linked to profile data and used for behavioral profiling (see
          our&nbsp;
        </span>
        <span>
          <a href="https://www.supplyzone.ai/STM/help-faq/en/cookie-policy/">
            <span>Cookie Policy</span>
          </a>
        </span>
        <span>&nbsp;for further details about cookies).</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Offline Behavioral Data</span>
        </b>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>Trade shows, exhibitions, and conferences visitors attended</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>Exhibitors visited (captured through Supply Zone Buyer App)</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>Summits/seminars/events attended</span>
      </p>

      <p>
        <span>What Do We Use Your Personal Data For?&nbsp;</span>
      </p>

      <p>
        <span>
          We use the data to provide you with our products and services, to
          develop new offerings and to protect Supply Zone and our visitors,
          exhibitors, buyers, suppliers and advertisers as detailed below. In
          some instances, we will request that you provide data to us directly.
          If you do not provide the data that we request, or prohibit us from
          collecting such data, we may not be able to provide the requested
          products and services.
        </span>
      </p>

      <p>
        <span>
          We use the data for our legitimate business interests, including the
          following:
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To create personalized product recommendations on behalf of online
          advertisers to present to online visitors by email or phone.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To market relevant Supply Zone online services (e.g., Product/Supplier
          Alerts, Magazines) by print DMs, eDMs, telemarketing calls, SMS or
          other platforms including Facebook, LinkedIn or through third-party
          agencies.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To market relevant Supply Zone Tradeshows and services (e.g., Buyer
          apps, Summits, Start Up Launchpad) by print DMs, eDMs, telemarketing
          calls, SMS or through other platforms including Facebook, LinkedIn or
          through 3rd party agencies
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To create contact lists of Online and/or Trade Show visitors who have
          opted in to third-party marketing. These lists are segmented based on
          visitor preferences, behaviors or demographics and can be rented/sold
          to verified third-party vendor for direct marketing on behalf of their
          clients.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Using third-party marketing materials from our partners (advertisers
          or service providers) to market to Supply Zone Online and Trade Show
          visitors who have opted in to third-party marketing. These visitors
          are segmented based on preferences, behavior or demographics, by print
          DMs, eDMs, telemarketing calls, SMS or through other platforms
          including Facebook, LinkedIn or through third-party agencies.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Create contact lists for surveys and similar market research
          activities.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To facilitate review of visitor details for qualification purposes by
          Advertisers and Exhibitors who have received Requests for Information
          or similar from visitors to our Online site or Tradeshows, through our
          Messaging Service and to facilitate communications between parties by
          email/phone/chat in relation to such requests.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Our Customer Services team may use your personal data when responding
          to your comments, feedback, inquiries, questions, to give you
          appropriate support to fulfil your needs and requests
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Our Technical Support team may use your personal data to (i)
          facilitate internal purposes such as auditing, data analysis, and
          research to improve our products, services and improve your user
          experience, including providing more relevant customized content to
          you; (ii) detect, investigate and prevent fraudulent transactions and
          other illegal activities and protect your rights and property as well
          as ours.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Use the data for purposes associated with our legal and regulatory
          obligations.
        </span>
      </p>

      <p>
        <span>
          We will only use your personal data for those purposes set out above
          and only where we are satisfied that it is necessary and that its use
          falls under one or more of the following legal grounds, i.e.:
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To comply with a relevant legal or regulatory obligation that we are
          subject to
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To fulfill obligations under a contract signed between you and us, or
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          To support 'legitimate interests' that we have as a business provided
          it is always carried out in a way that is proportionate and that
          respects your privacy rights
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Or exceptionally for clearly defined and limited cases, where you have
          freely given consent
        </span>
      </p>

      <p>
        <span>How Do We Secure your Personal Data?&nbsp;</span>
      </p>

      <p>
        <span>
          Whilst we cannot guarantee to keep your data 100% secure, we are
          committed to protecting you (and us) from unauthorized access to, or
          unauthorized alteration, disclosure, destruction of personal
          information that we hold.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          We regularly review our information collection, storage and processing
          practices, including physical, logical and organizational security
          measures, to guard against unauthorized access to systems and data
          stores.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Our regular reviews include engaging external security consultants to
          test effectiveness of implemented security measures.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          We restrict access to personal information to our employees,
          contractors and agents who need access to the relevant personal
          information in order for them to process it for us and who are subject
          to strict contractual confidentiality obligations.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          We implement strong access control mechanisms, such as two-factor
          authentication, identity &amp; access management in addition to strong
          password policies.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Where appropriate, we use technical solutions such as encryption,
          pseudonymization and anonymization to further protect data at rest and
          in transit.
        </span>
      </p>

      <p>
        <span>
          On your part, we encourage you to use a unique and strong password to
          protect your account, activate the two-factor authentication (2FA)
          feature that we provide, limit access to your computer and log out
          after using our services. In addition, you should make sure your
          computer is updated with the latest security patches and protected
          with an anti-malware product at all times.
        </span>
      </p>

      <p>
        <span>
          Please note that you are responsible for the personal information that
          you choose to share, disclose or submit voluntarily while using our
          website and which can be viewed by members of third-party applications
          or messengers.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>How Long Do We Keep Your Personal Data?&nbsp;</span>
      </p>

      <p>
        <span>
          We will retain your personal data for as long as is reasonably
          necessary for the purposes listed in this Privacy Policy. When your
          personal information is no longer required, we will ensure it is
          either securely deleted or stored in a way which means it will no
          longer be used by the business.
        </span>
      </p>

      <p>
        <span>
          In some circumstances we may retain your personal information for
          longer periods of time, for example, where we are required to do so to
          meet legal, regulatory, tax or accounting requirements or in the event
          of any complaint or challenge, or if we reasonably believe there is a
          possibility of legal action relating to your personal information or
          dealings. Under normal circumstances this should not exceed 7 years
          from the date of the last engagement with you.
        </span>
      </p>

      <p>
        <span>Who Do We Share Your Personal Data With?&nbsp;</span>
      </p>

      <p>
        <span>
          We may share your personal information with third parties, to help
          manage our business and deliver services as outlined below:
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Service providers, who perform functions on our behalf such as
          fulfilling orders, who help manage our IT and back-office systems,
          delivering packages, sending postal mail and email, removing
          repetitive information from customer lists, analyzing data, providing
          marketing assistance, providing search results and links (including
          paid listings and links), and providing customer services.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Data protection authorities, regulatory authorities and law
          enforcement agencies, in Seychelles, EU and around the world.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Other third parties, for the purpose of detecting, preventing or
          otherwise addressing fraud, security or technical issues, protecting
          against harm to the rights, property or safety of our users or the
          public.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          We may disclose or transfer your data to a third-party in the event of
          any reorganization, merger, sale, joint venture, assignment, transfer
          or other disposition of all or any portion of Supply Zone business,
          assets or stock (including any bankruptcy or similar proceedings).
        </span>
      </p>

      <p>
        <span>Direct Marketing&nbsp;</span>
      </p>

      <p>
        <span>
          We may use your personal information to send you direct marketing
          communications about our products and services or our related services
          according to your preferences either with your consent or because we
          have a legitimate interest to do so, including our latest product
          announcements and upcoming events. This may be in the form of emails,
          SMS, telephone calls or targeted online advertisement. We limit direct
          marketing to a reasonable proportionate level, and to send you
          communications which we think will be interesting and relevant to you,
          based on the information we have about you.
        </span>
      </p>

      <p>
        <span>
          You have choices when it comes to how we use your data and we want to
          ensure you have the information to make the choices that are right for
          you.
        </span>
      </p>

      <p>
        <span>
          If you no longer want to receive marketing-related emails, SMS, or
          printed materials, you may opt out by following the instructions in
          any such emails, SMS or printed materials you receive from us.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>Profiling&nbsp;</span>
      </p>

      <p>
        <span>
          Based on information we have collected about you and your viewing
          habits and analytics about performance of our content, offers and ad,
          we personalize the content, offers and ads made available to you to
          make your experience more relevant.
        </span>
      </p>

      <p>
        <span>
          This constitutes profiling: We segment visitors by applying selection
          criteria and business rules using software code (and algorithms) to
          process relevant data semi-automatically to produce personalized
          content recommendations, e.g., recommended products which appear in
          marketing emails or online when you visit our sites. If you wish to
          opt-out of receiving personalized content, please contact the&nbsp;
        </span>
        <span>
          <a href="mailto:support@inboxgroup.ai">
            <span>Customer Services Team</span>
          </a>
        </span>
        <span>. </span>
      </p>

      <p>
        <span>
          Transfers of Personal Data to Non-EU Countries or International
          Organizations&nbsp;
        </span>
      </p>

      <p>
        <span>
          The data that we collect from you may be transferred to, and stored
          at, a location outside the European Economic Area (EEA). It may also
          be processed by staff operating outside the EEA who work for us or one
          of our service providers. We will take all steps reasonably necessary
          to ensure that your data is treated securely, in accordance with this
          privacy notice, and will only transfer your personal information to
          other countries that are either:
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Recognized by the European Union as providing an adequate level of
          data and legal protection, for example, to the US under the EU - U.S.
          Privacy Shield which covers the protection of personal information
          transferred to the US. (
        </span>
        <span>
          <a href="https://www.privacyshield.gov/article?id=OVERVIEW">
            <span>https://www.privacyshield.gov/article?id=OVERVIEW</span>
          </a>
        </span>
        <span>)</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Or by ensuring that transfers to other non-EU counties are protected
          by appropriate contractual commitments.
        </span>
      </p>

      <p>
        <span>
          You have the right to request more information about the safeguards we
          have put in place. Please contact the DPO.
        </span>
      </p>

      <p>
        <span>What Rights do you Have?&nbsp;</span>
      </p>

      <p>
        <span>
          You have a number of rights in relation to your personal information.
          Specifically, you have the right to request:
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Access to Your Data</span>
        </b>
      </p>

      <p className="">
        <span>You can ask us to:</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>Confirm whether we are processing your personal information</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>Give you a copy of that data</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          Provide you with other information about your personal information,
          such as what data we have, what we use it for, who we disclose it to,
          whether we transfer it abroad and how we protect it, how long we keep
          it for, what rights you have, how you can make a complaint, where we
          got your data from and whether we have carried out automated decision
          making or profiling, to the extent that information has not already
          been provided to you in this notice.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Rectification</span>
        </b>
      </p>

      <p className="">
        <span>
          You can ask us to rectify inaccurate personal information in our
          files. We may seek to verify the accuracy of the data before
          rectifying it.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Erasure (Right to be Forgotten)</span>
        </b>
      </p>

      <p className="">
        <span>
          You can ask us to erase your personal information, but only where:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          It is no longer needed for the purposes for which it was collected; or
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          You have withdrawn your consent (where the data processing was based
          on consent); or
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>It follows a successful right to object; or</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>It has been processed unlawfully; or</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>It is necessary to comply with a legal obligation.</span>
      </p>

      <p>
        <span>
          We are not required to comply with your request to erase your personal
          information if the processing of your personal information is
          necessary:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>For compliance with a legal obligation; or</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>For the establishment, exercise or defense of legal claims.</span>
      </p>

      <p>
        <span>
          In the context of marketing, please note that we will maintain a
          suppression list if you have opted out from receiving marketing
          content to ensure that you do not receive any further communications.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Restriction</span>
        </b>
      </p>

      <p className="">
        <span>
          You can ask us to restrict (i.e., keep but not use) your personal
          information, but only where:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          Its accuracy is contested, to allow us to verify its accuracy; or
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          The processing is unlawful, but you do not want it erased; or
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          It is no longer needed for the purposes for which it was collected,
          but we still need it to establish, exercise or defend legal claims; or
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          You have exercised the right to object, and verification of overriding
          grounds is pending.
        </span>
      </p>

      <p>
        <span>
          We can continue to use your personal information following a request
          for restriction, where:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>We have your consent; or</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>To establish, exercise or defend legal claims; or</span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>To protect the rights of another natural or legal person.</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Objection</span>
        </b>
      </p>

      <p className="">
        <span>
          You can object to any processing of your personal information which
          has our 'legitimate interests' as its legal basis, if you believe your
          fundamental rights and freedoms outweigh our Legitimate Interests.
          Once you have objected, we have an opportunity to demonstrate that we
          have compelling legitimate interests which override your rights,
          however, this does not apply as far as the objections refers to the
          use of personal information for direct marketing purposes.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Data Portability</span>
        </b>
      </p>

      <p className="">
        <span>
          You can ask us to provide your personal information to you in a
          structured, commonly used, machine-readable format, or you can ask to
          have it 'ported' directly to another Data Controller, but in each case
          only where:
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>
          The processing is based on your consent or the performance of a
          contract with you; and
        </span>
      </p>

      <p className="">
        <span>
          <span>&nbsp; </span>
        </span>
        <span>The processing is carried out by automated means.</span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Information about any automated processing</span>
        </b>
      </p>

      <p className="">
        <span>
          e.g., segmentation or profiling using your data. You can ask not to be
          subject to such processing.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Information about International Transfers.</span>
        </b>
      </p>

      <p className="">
        <span>
          You can request details about international transfers of your data
        </span>
      </p>

      <p>
        <span>
          To exercise your rights, you can contact us as set out above. Please
          note the following if you do wish to exercise these rights:
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Objection</span>
        </b>
      </p>

      <p className="">
        <span>
          We take the confidentiality of all records containing personal
          information seriously and reserve the right to ask you for proof of
          your identity if you make a request.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Fees</span>
        </b>
      </p>

      <p className="">
        <span>
          We will not ask for a fee to exercise any of your rights in relation
          to your personal information, unless your request for access to
          information is unfounded, repetitive or excessive, in which case we
          will charge a reasonable amount in the circumstances.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Timescales</span>
        </b>
      </p>

      <p className="">
        <span>
          We aim to respond to any valid requests within one month unless it is
          particularly complicated or you have made several requests, in which
          case we aim to respond within three months. We will let you know if we
          are going to take longer than one month. We might ask you if you can
          help by telling us what exactly you want to receive or are concerned
          about. This will help us to action your request more quickly.
        </span>
      </p>

      <p className="">
        <span>
          o<span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <b>
          <span>Exemptions</span>
        </b>
      </p>

      <p className="">
        <span>
          Local laws, including in the EU member states, provide for additional
          exemptions, in particular to the right of access, whereby personal
          information can be withheld from you in certain circumstances, for
          example where it is subject to legal privilege.
        </span>
      </p>

      <p>
        <span>
          You also have the right to complain to your supervisory authority (see
          'Contact Details').
        </span>
      </p>

      <p>
        <span>Last updated date: 27 July 2023 version 0.9</span>
      </p>

      <p>
        <b>
          <span>Appendix 1: Summary Data Used/Purpose/Legal Basis</span>
        </b>
      </p>

      <table width="100%">
        <tr>
          <td width={234}>
            <p>
              <b>
                <span>We use your personal data to...</span>
              </b>
            </p>
          </td>
          <td width={240}>
            <p>
              <b>
                <span>Data Types Used</span>
              </b>
            </p>
          </td>
          <td width={223}>
            <p>
              <b>
                <span>The applicable legal basis</span>
              </b>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                Create personalized product recommendations for you on behalf of
                online advertisers
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>Contact &amp; communications data</span>
            </p>
            <p>
              <span>Business details</span>
            </p>
            <p>
              <span>Preferences</span>
            </p>
            <p>
              <span>Buying &amp; payment history</span>
            </p>
            <p>
              <span>Online behavioral data</span>
            </p>
            <p>
              <span>Offline behavioral data</span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We have a &quot;Legitimate Interest&quot; to provide you with
                relevant and personalized content.
              </span>
            </p>
            <p>
              <span>
                If you live in a jurisdiction which requires opt in, we require
                your consent.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>Market relevant Supply Zone online services to you</span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>Contact &amp; communications data</span>
            </p>
            <p>
              <span>Business details</span>
            </p>
            <p>
              <span>Preferences</span>
            </p>
            <p>
              <span>Buying &amp; payment history</span>
            </p>
            <p>
              <span>Online behavioral data</span>
            </p>
            <p>
              <span>Offline behavioral data</span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We have a &quot;Legitimate Interest&quot; to provide you with
                information about our online services.
              </span>
            </p>
            <p>
              <span>
                <br />
                If you live in a jurisdiction which requires opt in, we require
                your consent.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                Market relevant Supply Zone Trade Shows and services to you
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>Contact &amp; communications data</span>
            </p>
            <p>
              <span>Business details</span>
            </p>
            <p>
              <span>Preferences</span>
            </p>
            <p>
              <span>Buying &amp; payment history</span>
            </p>
            <p>
              <span>Online behavioral data</span>
            </p>
            <p>
              <span>Offline behavioral data</span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We have a &quot;Legitimate Interest&quot; to provide you with
                information about our Trade Show services.
              </span>
            </p>
            <p>
              <span>
                If you live in a jurisdiction which requires opt in, we require
                your consent.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                Create contact lists of online and/or trade show visitors who
                have opted in to third-party marketing
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>Contact &amp; communications data</span>
            </p>
            <p>
              <span>Business details</span>
            </p>
            <p>
              <span>Preferences</span>
            </p>
            <p>
              <span>Buying &amp; payment history</span>
            </p>
            <p>
              <span>Online behavioral data</span>
            </p>
            <p>
              <span>Offline behavioral data</span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We require your Consent (opt in) to provide your details to 3rd
                parties for 3rd parties to market to you.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                To market on behalf our partners (advertisers or service
                providers) their products and services to online and trade show
                visitors who have opted in for 3rd party marketing
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>Contact &amp; communications data</span>
            </p>
            <p>
              <span>Business details</span>
            </p>
            <p>
              <span>Preferences</span>
            </p>
            <p>
              <span>Buying &amp; payment history</span>
            </p>
            <p>
              <span>Online behavioral data</span>
            </p>
            <p>
              <span>Offline behavioral data</span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We require your Consent (opt-in) to market to you on behalf of
                our partners and service providers (3rd party marketing).
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                To create contact lists for surveys and similar market research
                activities.
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>Contact &amp; communications data</span>
            </p>
            <p>
              <span>Business details</span>
            </p>
            <p>
              <span>Preferences</span>
            </p>
            <p>
              <span>Buying &amp; payment history</span>
            </p>
            <p>
              <span>Online behavioral data</span>
            </p>
            <p>
              <span>Offline behavioral data</span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We have a &quot;Legitimate Interest&quot; in using your data to
                conduct surveys and market research to improve our product
                offerings and to give anonymous insights to our user
                &quot;community&quot;.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                Facilitate review of visitor details for qualification purposes
                by Advertisers and Exhibitors who have received Requests for
                Information or similar from visitors to online site or trade
                shows, through our Messaging Service and to facilitate
                communication between parties in relation to such requests
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>Contact &amp; communications data</span>
            </p>
            <p>
              <span>Business details</span>
            </p>
            <p>
              <span>
                Advertisers/exhibitors and online visitors sent through Message
                Center, e.g., RFI, RFQ, RFS.
              </span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We have a &quot;Legitimate Interest&quot; in facilitating the
                use of data relevant to visitor enquiries that is pertinent to
                advertisers and exhibitors using our services, provided that it
                does not infringe the rights of other parties.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                To allow our Customer Services team to respond to your comments,
                feedback, inquiries, and questions
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>
                Any: Subject to internal controls to limit use to the minimum
                necessary on a case-by-case basis.
              </span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We have a &quot;Legitimate Interest&quot; in using data that is
                pertinent to respond to your comments, feedbacks, inquiries,
                etc., provided that it does not infringe the rights of other
                parties.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                To allow our Technical Support to improve our products, services
                and your user experience, and to detect, investigate and prevent
                fraudulent transactions and other illegal activities, thus
                protecting your rights and property as well as ours
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>
                Any: Subject to internal controls to limit use to the minimum
                necessary on a case-by-case basis.
              </span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                We have a &quot;Legitimate Interest&quot; in using data that is
                pertinent to improving and managing our services, provided that
                it does not infringe the rights of other parties.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width={234}>
            <p>
              <span>
                To use the data for purposes associated with our legal and
                regulatory obligations
              </span>
            </p>
          </td>
          <td width={240}>
            <p>
              <span>
                Any: Subject to internal controls to limit use to the minimum
                necessary on a case-by-case basis.
              </span>
            </p>
          </td>
          <td width={223}>
            <p>
              <span>
                The use of this data is necessary in order for us to comply with
                any legal or regulatory obligations.
              </span>
            </p>
          </td>
        </tr>
      </table>
    </>
  );
};
