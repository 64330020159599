import * as React from "react";
import ProductSimpleAside from "./Widgets/ProductSimpleAside";
import SearchCategory, { BrandFilter } from "./ProductSearchResults"

export default ({ categories, priceRanges, attributes, brands, query, categoryId,filterResults }) => {
  const widgetTitleStyle = {
    fontSize: "1.2rem",
    padding: "1.4rem 2.5rem 1.4rem 1rem",
    background: "#eee",
    textTransform: "uppercase"

  }
  let brandsArray: BrandFilter[] = []
  let brandsFilterList = [];
  const [brandsChecked, setBrandsChecked] = React.useState([]);
  brands.forEach((value, key) => {
    const brandLink = (key == "No Brand") ? "null" : key
    if (brandLink != "null") {
      brandsArray.push(new BrandFilter(value, key, brandLink))
      brandsFilterList.push(value) 
    }
  });

  const handleBrandsCheck = (event) => {
    var updatedList = [...brandsChecked];
    if (event.target.checked) {
      updatedList = [...brandsChecked, event.target.value];
    } else {
      updatedList.splice(brandsChecked.indexOf(event.target.value), 1);
    }
    setBrandsChecked(updatedList);
    filterResults(updatedList,atttributesChecked)
  };


  const attributesFilterList = [];
  attributes.forEach((attribute:any)=>{
    attribute.values.forEach((value:any)=>{
      attributesFilterList.push(value.value)
    })
  })
  const [atttributesChecked, setAtttributesChecked] = React.useState([]);
  const handleAttributesCheck = (event) => {
    var updatedList = [...atttributesChecked];
    if (event.target.checked) {
      updatedList = [...atttributesChecked, event.target.value];
    } else {
      updatedList.splice(atttributesChecked.indexOf(event.target.value), 1);
    }
    setAtttributesChecked(updatedList);
    filterResults(brandsChecked,updatedList)
  };

  return (
    <>
      <aside
        className="sidebar product-sidebar sidebar-fixed left-sidebar sticky-sidebar-wrapper"
        id="product-sidebar"
      >
        <div className="sidebar-overlay"></div>
        <a className="sidebar-close" href="#">
          <i className="close-icon"></i>
        </a>
        <a href="#" className="sidebar-toggle d-flex d-lg-none">
          <i className="fas fa-chevron-left"></i>
        </a>
        <div className="sidebar-content scrollable pr-3 pl-3 pt-3 pb-3">

          <div
            className="sticky-sidebar slider-relative"
            style={{
              borderBottom: "0px none rgb(153, 153, 153)",
              width: "100%",
              border: "solid 1px #eee"
            }}
          >

            <div className="widget widget-collapsible">
              <h3 className="widget-title" style={widgetTitleStyle}><span>Categories</span></h3>
              <ul className="widget-body filter-items search-ul" style={{ padding: "1rem" }}>
                {categories.map((category: SearchCategory) => (
                  <>
                    {category.count > 0 && (
                      <li>
                        <a href={`/search?category=PRODUCT&search=${query}&categoryId=${category.id}`}>
                          {category.name}
                          <span className="text-muted p-relative" style={{ color: "#29333b" }}> ({category.count})</span>
                        </a>
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </div>

            {brandsArray.length > 0 && (
              <>
                <div className="widget widget-collapsible">
                  <h3 className="widget-title" style={widgetTitleStyle}><span>Brand</span><span className="toggle-btn"></span></h3>
                  <div className="widget-body" style={{ padding: "1rem" }}>
                    <ul className="filter-items search-ul">
                      {brandsArray.map((brand: any, index: number) => (
                        <li key={`brand-${index}`}>
                          <div className="checkbox-toggle">
                            <input type="checkbox" value={brand.name} className="custom-checkbox" id={`brand-${index}`} onChange={handleBrandsCheck} />
                            <label style={{ fontSize: "12px" }} htmlFor={`brand-${index}`} >
                              {brand.name}
                              <span className="text-muted p-relative" style={{ color: "#29333b" }}> ({brand.count})</span>
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}

            <div className="widget widget-collapsible">
              <h3 className="widget-title" style={widgetTitleStyle}><span>Price</span><span className="toggle-btn"></span></h3>
              <div className="widget-body" style={{ padding: "1rem" }}>
                <ul className="filter-items search-ul">
                  {priceRanges.map((priceRange: any, index: number) => (
                    <>
                      <li key={`price-${index}`}>
                        <div className="checkbox-toggle">
                          <input type="checkbox" className="custom-checkbox" id={`price-${index}`} />
                          <label style={{ fontSize: "12px" }} htmlFor={`price-${index}`} >
                            ${Math.ceil(parseFloat(priceRange.min)).toFixed(2)} - ${Math.ceil(parseFloat(priceRange.max)).toFixed(2)}
                          </label>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>

            {attributes.map((attribute: any, attributeIndex: number) => (
              <>
              {attribute.values.length > 0 && (
              <div className={`widget widget-collapsible widget-attributes-${attributeIndex}`} key={`widget-attributes-${attributeIndex}`}>
                <h3 className="widget-title" style={widgetTitleStyle}><span>{attribute.name}</span><span className="toggle-btn"></span></h3>
                <div className="widget-body" style={{ padding: "1rem" }}>
                  <ul className="filter-items search-ul">
                    {attribute.values.map((value: any, index: number) => (
                      <>
                        {value.value.length > 0 && (
                          <li key={`attribute-${attributeIndex}-${index}`}>
                            <div className="checkbox-toggle">
                              <input type="checkbox" value={value.value} className="custom-checkbox" id={`attribute-${attributeIndex}-${index}`} onChange={handleAttributesCheck} />
                              <label style={{ fontSize: "12px" }} htmlFor={`attribute-${attributeIndex}-${index}`} >
                                {value.value} <span className="text-muted p-relative" style={{ fontWeight: "500" }}>({value.count})</span>
                              </label>
                            </div>
                          </li>
                        )}
                      </>

                    ))}
                  </ul>
                </div>
              </div>
              )}
              </>
            ))}



          </div>

        </div>
      </aside >
    </>
  );
};
