import { Button, Divider, Flex, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import { useAppDataContext } from "../../../../data";
import { useEffect } from "react";
import Snippets from "../../../../helpers/Snippets";

export const InquirySubmitModal: any = ({
  modalTitle,
  isSubmitInquiryModalOpen,
  supplier,
  product,
  saveInquiry,
  productPrice,
  sendingInquiry,
  openLoginModal,
  closeModal,
  inquiryQuantityOverride,
  inquiryQuantityOverrideSet,
}: any) => {
  const { isLoggedIn: isLoggedInUser, userEmailAddress } = useAppDataContext();

  const [isModalOpen, setIsModalOpen] = React.useState(
    isSubmitInquiryModalOpen
  );

  const [inquiryQuantity, setInquiryQuantity] = React.useState(String(inquiryQuantityOverride));

  const [details, setDetails] = React.useState("");

  const [emailAddress, setEmailAddress] = React.useState(userEmailAddress);

  const [productImage, setProductImage] = React.useState(
    `https://api-live.supplyzone.ai/home/product/${product.id}/image`
  );

  useEffect(() => {
    setIsModalOpen(isSubmitInquiryModalOpen);
  }, [isSubmitInquiryModalOpen]);

  useEffect(() => {
    setEmailAddress(userEmailAddress);
  }, [userEmailAddress]);

  useEffect(() => {
    setDetails("I am interested in " + product.name);
    setProductImage(
      `https://api-live.supplyzone.ai/home/product/${product.id}/image`
    );
  }, [product]);

  const setInquiryQuantityX: any = (val: any) => {
    inquiryQuantityOverrideSet(Number(val));
  };

  useEffect(() => {
    setInquiryQuantity(inquiryQuantityOverride);
  }, [inquiryQuantityOverride]);

  const onSendInquiry: any = () => {
    //console.log("%%%%%", isLoggedInUser);
    isLoggedInUser
      ? saveInquiry(emailAddress, inquiryQuantity, details)
      : openLoginModal(true);
  };

  function formatCurrency(product: any) {
    let price: any ="Call supplier"; // "$*****.**";

    if ("productPrices" in product && product.productPrices !== null) {
      
      let _prices: any = product.productPrices.sort(
        Snippets.arrays.dynamicSort("-price")
      );

      if (_prices.length > 0) {
        let min = _prices[0].price;

        let max = _prices[_prices.length - 1].price;

        if (product.displayPrice === false) {
          price = "Call supplier"; //"$*****.**";
          if (min === max) {
            price = `${min.toFixed(2)}`;
          } else {
            price = `${max.toFixed(2)} - ${min.toFixed(2)}`;
          }
        } else {
          if (min === max) {
            price = `${min.toFixed(2)}`;
          } else {
            price = `${max.toFixed(2)} - ${min.toFixed(2)}`;
          }
        }
      }
    } else {
      console.log(":::::::CLONE PRICE 0::", product);
    }

    return price;
  }

  return (
    <>
      <Modal
        title={modalTitle}
        centered
        className={`inquiry-composer`}
        open={isModalOpen}
        onCancel={closeModal}
        footer={
          <>
            <Divider orientation="center" />
            <Flex justify={"flex-end"} align={"flex-end"}>
              <Button
                size="large"
                type="default"
                onClick={closeModal}
                style={{ marginRight: "20px" }}
              >
                Close
              </Button>
              <Button
                size="large"
                type="primary"
                loading={sendingInquiry}
                onClick={()=>onSendInquiry()}
              >
                {modalTitle}
              </Button>
            </Flex>
          </>
        }
      >
        <>
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="review-form-wrapper mb-2">
                <hr />
                <div className="row ">
                  <div className="col-md-12">
                    <h4 className="icon-box-title ml-0">
                      <div className="d-flex x1">
                        <img
                          alt=""
                          src={`https://szbackend.eu-west-2.elasticbeanstalk.com/marketplace/supplier/${supplier.id}/document/LOGO`}
                          style={{ width: "30px" }}
                          onError={(event) =>
                            ((event.target as any).style.display = "none")
                          }
                        />
                        <h4 style={{ margin: "0 20px 0 0px" }}>
                          <strong>{supplier.name}</strong>
                        </h4>
                        <div className="popover__wrapper d-none">
                          {supplier.credentialsBadge
                            ? supplier.credentialsBadge
                            : "Self-registered"}

                          <div className="popover__content">
                            <div className="blue-header">Verified Supplier</div>
                            <div
                              className="pointCreators"
                              style={{ minWidth: "max-content" }}
                            >
                              <div className="d-flex o-flex-grid--item ">
                                <div className="verified-seller">
                                  This supplier has undergone a vetting process,
                                  which includes physical site inspection,
                                  verification of trade licenses, and
                                  verification of at least five commercial trade
                                  references.
                                </div>
                                <div
                                  className="tableCell"
                                  style={{
                                    paddingTop: "30px",
                                    display: "none",
                                  }}
                                >
                                  <div className="o-flex-grid">
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          Value Of Stock USD 24k
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          Professional Supply Chain
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="o-flex-grid">
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          Aftersales Capability
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          Background Check
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="o-flex-grid">
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          Trade References
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          CR14/Proprietorship License
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="o-flex-grid">
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                          style={{
                                            width: "20px",
                                            marginLeft: "15px !important",
                                          }}
                                        />
                                        <span className="cellText">
                                          ITF263 Certificate
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          VAT Registration Certificate
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="o-flex-grid">
                                    <div className="d-flex o-flex-grid--item ">
                                      <div className="tableCell">
                                        <img
                                          className="cellImage"
                                          src="https://www.pngkit.com/png/full/132-1328992_12-months-blue-check-mark-icon-png.png"
                                          alt=""
                                        />
                                        <span className="cellText">
                                          Physical Inspection
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          alt=""
                          src="https://cdn.countryflags.com/thumbs/zimbabwe/flag-button-round-250.png"
                          style={{ width: "28px" }}
                        />
                        <span className="xbadge">
                          <strong>{supplier.years}</strong> Years
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>

              <div className="inquiry-scrollable-div">
                <div className="product-widget widget widget-icon-box mb-3 gradient-top px-3">
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png`;
                    }}
                    src={`https://api-live.supplyzone.ai/home/product/${product.id}/image`}
                    className={`product-simple product-id-${product.id} ${productImage} ml-3 mr-3 `}
                    alt={product.name}
                    width="120"
                  />

                  <div className="wide-div w-100">
                    <div className="row">
                      <div className="col-md-7">
                        <h4 className="product-name">
                          <a href="#">
                            <strong>{product.name}</strong>
                          </a>
                        </h4>
                        <h6>{product.category.name}</h6>
                        <div className="product-price">
                          USD {formatCurrency(product)}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>
                          Quantity <span className="red-text">*</span>
                        </label>
                        <div className="product-qty-form">
                          <div
                            className="input-group"
                            style={{ height: "3.5rem" }}
                          >
                            <Input
                              className="fs-24"
                              type="number"
                              min="1"
                              size={"large"}
                              value={inquiryQuantity}
                              onChange={(e) => {
                                setInquiryQuantityX(
                                  e.target.value.toString()
                                );
                              }}
                              max="10000000"
                            />
                            <button
                              title="-"
                              type="button"
                              className="quantity-plus w-icon-plus"
                              onClick={(e) => {
                                setInquiryQuantityX(
                                  `${parseInt(inquiryQuantity) + 1}`
                                );
                              }}
                            ></button>
                            <button
                              title="+"
                              type="button"
                              className="quantity-minus w-icon-minus"
                              onClick={(e) => {
                                if (parseInt(inquiryQuantity) > 1) {
                                  setInquiryQuantityX(
                                    `${parseInt(inquiryQuantity) - 1}`
                                  );
                                }
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form action="#" method="POST" className="review-form w-100">
                  <div className="row gutter-md m-20-0 mt-40">
                    <div className="col-md-6 form-group pl-0">
                      <label>
                        Email Address<span className="red-text">*</span>
                      </label>
                      <Input
                        type="email"
                        className={`${isLoggedInUser ? "grey-bg" : ""}`}
                        placeholder="Your email address"
                        id="contactEmail"
                        value={emailAddress}
                        readOnly={isLoggedInUser}
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row gutter-md m-20-0 mt-40">
                    <div className="col-md-12 form-group pl-0">
                      <label>
                        Message<span className="red-text">*</span>
                      </label>
                      <TextArea
                        rows={3}
                        placeholder="Enter product details such as color, size, materials and other specific requirements"
                        maxLength={500}
                        id="details"
                        onChange={(e) => {
                          setDetails(e.target.value);
                        }}
                        value={details}
                      />
                      <label style={{ float: "right" }}>
                        {String(details).split("").length}/500
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
