import React, { useEffect, useState } from "react";
import { Badge, Button, Dropdown, Flex, Tabs } from "antd";
import type { MenuProps, TabsProps } from "antd";
import "./kommand.scss";
import { KommandListView } from "./KommandListView";
import { useAppDataContext } from "../../../../data/context/AppDataContext";
import { MoreVertical } from "react-feather";
import { FolderOpenOutlined } from "@ant-design/icons";

const KommandView: any = () => {
  const {
    currentlySelectedFolder,
    setCurrentlySelectedFolder,
    userDocumentsInquiries,
    userDocumentsRFQs,
    userDocumentsRFQsActive,
    userDocumentsRFQsCompleted,
    userDocumentsRFQsArchived,
    userDocumentsInquiriesActive,
    userDocumentsInquiriesCompleted,
    userDocumentsInquiriesArchived,
    profileCounters,
  } = useAppDataContext();

  const [currentFolderName, setCurrentFolderName] = useState("Active");

  const [currentFolderCounter, setCurrentFolderCounter] = useState(0);

  const [listViewLabel, setListViewLabel] = useState("RFQ");

  const [currentList, setCurrentList] = useState([]);

  const onChange = (key: string) => {
    if (key === "1") {
      setListViewLabel("RFQ");
    } else {
      setListViewLabel("INQ");
    }
  };

  const switchToFolder: any = (folder: string, counter: number) => {
    setCurrentlySelectedFolder(folder);
    setCurrentFolderCounter(counter);
    switch (folder) {
      case "ALL": {
        setCurrentFolderName("Active");
        setCurrentList(
          listViewLabel === "RFQ"
            ? userDocumentsRFQsActive
            : userDocumentsInquiriesActive
        );
        break;
      }
      case "ACTIVE": {
        setCurrentFolderName("Active");
        setCurrentList(
          listViewLabel === "RFQ"
            ? userDocumentsRFQsActive
            : userDocumentsInquiriesActive
        );
        break;
      }
      case "COMPLETED": {
        setCurrentFolderName("Completed");
        setCurrentList(
          listViewLabel === "RFQ"
            ? userDocumentsRFQsCompleted
            : userDocumentsInquiriesCompleted
        );
        break;
      }
      case "ARCHIVED": {
        setCurrentFolderName("Archived");
        setCurrentList(
          listViewLabel === "RFQ"
            ? userDocumentsRFQsArchived
            : userDocumentsInquiriesArchived
        );
        break;
      }
      default: {
        setCurrentFolderName("Active");
        setCurrentList(
          listViewLabel === "RFQ"
            ? userDocumentsRFQsActive
            : userDocumentsInquiriesActive
        );
        break;
      }
    }
  };

  const [items, setItems] = useState<TabsProps["items"]>([]);

  const itemsPhone: MenuProps["items"] = [
    {
      key: "0",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-all`}
          href={"#"}
          onClick={() => {
            switchToFolder(
              "ACTIVE",
              listViewLabel === "RFQ"
                ? userDocumentsRFQs.length
                : userDocumentsInquiries.length
            );
          }}
        >
          Active {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
          <span className="folder-badge">
            (
            {listViewLabel === "RFQ"
              ? userDocumentsRFQs.length
              : userDocumentsInquiries.length}
            )
          </span>
        </a>
      ),
    },
    {
      key: "1",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-completed`}
          href={"#"}
          onClick={() => {
            switchToFolder(
              "COMPLETED",
              listViewLabel === "RFQ"
                ? userDocumentsRFQsCompleted.length
                : userDocumentsInquiriesCompleted.length
            );
          }}
        >
          Completed {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
          <span className="folder-badge">
            (
            {listViewLabel === "RFQ"
              ? userDocumentsRFQsCompleted.length
              : userDocumentsInquiriesCompleted.length}
            )
          </span>
        </a>
      ),
    },
    {
      key: "2",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-archived`}
          href={"#"}
          onClick={() => {
            switchToFolder(
              "ARCHIVED",
              listViewLabel === "RFQ"
                ? userDocumentsRFQsArchived.length
                : userDocumentsInquiriesArchived.length
            );
          }}
        >
          Archived {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
          <span className="folder-badge">
            (
            {listViewLabel === "RFQ"
              ? userDocumentsRFQsArchived.length
              : userDocumentsInquiriesArchived.length}
            )
          </span>
        </a>
      ),
    },
  ];

  useEffect(() => {
    setItems([
      {
        key: "1",
        label: (
          <>
            {currentFolderName} RFQs{" "}
            <Badge
              count={profileCounters.unreadRFQMessages}
              style={{ backgroundColor: "#542278" }}
            />
          </>
        ),
        children: (
          <KommandListView currentList={currentList} listViewLabel="RFQ" />
        ),
      },
      {
        key: "2",
        label: (
          <>
            {currentFolderName} Inquiries{" "}
            <Badge
              count={profileCounters.unreadInquiryMessages}
              style={{ backgroundColor: "#542278" }}
            />
          </>
        ),
        children: (
          <KommandListView currentList={currentList} listViewLabel="INQ" />
        ),
      },
    ]);
  }, [currentList, currentFolderName, currentFolderCounter]);

  return (
    <>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      <Dropdown menu={{ items: itemsPhone }} arrow>
        <Button
          className="messenger-folders"
          type="text"
          icon={<MoreVertical />}
        />
      </Dropdown>
    </>
  );
};

export { KommandView };
