   import React, { FC, useEffect, useRef, useState } from "react";
import Snippets from "../../../../helpers/Snippets";
import {
  Badge,
  Button,
  Divider,
  Dropdown,
  Flex,
  Input,
  MenuProps,
  Modal,
  Select,
  Tooltip,
  message,
} from "antd";
import {
  CloseCircleOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  FolderFilled,
  FolderOpenOutlined,
  FolderOutlined,
  MoreOutlined,
  PaperClipOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppDataContext } from "../../../../data";
import TextArea from "antd/es/input/TextArea";
import { useLocalStorage } from "usehooks-ts";
import { InquirySubmitModal } from "../../shop/Products/InquirySubmitModal";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { format, render, cancel, register } from "timeago.js";
import { InvitesModals } from "../invites/InvitesModals";
interface DocumentListItemProps {
  children?: React.ReactNode;
  listItem?: any;
  listItemIndex: number;
  selectedRFQIndex: number;
  setSelectedRFQHandler: (item: any, index: number) => void;
  listViewLabel: string;
  isListView: boolean;
  userType: string;
  getTime: any;
  showViewDetails: boolean;
}

const MessengerAdvancedInquiryCol2: FC<DocumentListItemProps> = ({
  children,
  ...props
}) => {
  const {
    listItem,
    listItemIndex,
    selectedRFQIndex,
    setSelectedRFQHandler,
    listViewLabel,
  }: any = props;

  const targetRef: any = useRef(null);

  const [modal1Open, setModal1Open] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [invitingSupplier, setInvitingSupplier] = useState(false);

  const [supplierName, setSupplierName] = useState("");

  const [supplierEmailAddress, setSupplierEmailAddress] = useState("");

  const doInviteSupplier: any = () => {
    setInvitingSupplier(true);

    /*
    console.log([
      supplierEmailAddress,
      messageText
    ])
    */

    AxiosAPI.inviteSupplier(
      listViewLabel === "RFQ" ? "RFQ" : "INQUIRY",
      listItem.id,
      supplierEmailAddress,
      supplierName
    )
      .then((res: any) => {
        //console.log(res.data);

        messageApi.open({
          key,
          type: res.data.success ? "success" : "error",
          content: res.data.message,
          duration: 4,
        });

        setInvitingSupplier(false);

        setModal1Open(false);
      })
      .catch((error: any) => {
        //console.log(error);
      });
  };

  const isSelected: Function = (): boolean => {
    return parseInt(`${selectedRFQIndex}`) === parseInt(`${listItemIndex}`);
  };

  React.useEffect(() => {}, []);

  function dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const { confirm } = Modal;

  const [messageApi, contextHolder] = message.useMessage();

  const {
    addDocumentToFolder,
    getAllDocuments,
    userData,
    userProfile,
    currentlySelectedFolder,
    setUserDocumentsRFQs,
    setUserDocumentsRFQsActive,
    setUserDocumentsRFQsCompleted,
    setUserDocumentsRFQsArchived,
    setUserDocumentsInquiries,
    setUserDocumentsInquiriesActive,
    setUserDocumentsInquiriesCompleted,
    setUserDocumentsInquiriesArchived,
  } = useAppDataContext();

  const removeProduct = (id: number) => {
    const element = document.querySelector(".doc-id-" + id);
    if (element) {
      element.remove();
    }
  };

  const moveToFolder: any = (folder: string, isCancel:boolean = false) => {
    //listViewLabel

    confirm({
      title: `Move to ${folder.toLowerCase()} folder`,
      icon: <ExclamationCircleFilled />,
      content: isCancel
        ? `You are about to cancel ${listViewLabel} #1${listItem.id
            .toString()
            .padStart(4, "0")}. Are you sure?`
        : `You are about to move the ${listViewLabel} #1${listItem.id
            .toString()
            .padStart(4, "0")} to the ${folder} folder. Are you sure?`,
      centered: true,
      onOk() {
        return new Promise((resolve, reject) => {
          addDocumentToFolder(
            listViewLabel,
            folder,
            listItem.id,
            (res: any) => {
              removeProduct(listItem.id);
              //console.log("********Yeah yes!", res);
              messageApi.open({
                type: "success",
                content: isCancel
                  ? `Your ${listViewLabel} #1${listItem.id
                      .toString()
                      .padStart(
                        4,
                        "0"
                      )} has been cancelled.`
                  : `Your ${listViewLabel} #1${listItem.id
                      .toString()
                      .padStart(
                        4,
                        "0"
                      )} has been moved to the ${folder} folder.`,
              });
              setTimeout(() => {
                Modal.destroyAll();
                //resolve;
                getAllDocuments();
              }, 3000);
            },
            (error: any) => {
              /*
              console.log("*********Oops errors!!!", error, [
                userData,
                userProfile,
              ]);
              */
              messageApi.open({
                type: "error",
                content: error.response.data.message,
              });
              setTimeout(() => {
                Modal.destroyAll();
                //reject;
              }, 3000);
            }
          );

          setTimeout(resolve, 2000);
        }).catch((error: any) => {
          //console.log("*********Oops errors!", error);
          setTimeout(() => {
            Modal.destroyAll();
          }, 3000);
        });
      },
      onCancel() {},
    });
  };

  const [modal2Open, setModal2Open] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const key = "updatable";

  const itemsPhone: MenuProps["items"] = [
    {
      key: "0",
      icon: <CopyOutlined />,
      label: (
        <a
          href={"#"}
          onClick={() => {
            duplicateInquiry("ACTIVE");
          }}
        >
          Clone {listViewLabel === "RFQ" ? "RFQ" : "Inquiry"}
        </a>
      ),
    },
    {
      key: "1",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-all ${
            currentlySelectedFolder === "ALL" ||
            currentlySelectedFolder === "ACTIVE"
              ? "pd-none"
              : ""
          }`}
          href={"#"}
          onClick={() => {
            moveToFolder("ACTIVE");
          }}
        >
          Move to Active {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
        </a>
      ),
    },
    {
      key: "2",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-completed`}
          href={"#"}
          onClick={() => {
            moveToFolder("COMPLETED");
          }}
        >
          Move to Completed {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
        </a>
      ),
    },
    {
      key: "3",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-completed`}
          href={"#"}
          onClick={() => {
            moveToFolder("ARCHIVED");
          }}
        >
          Move to Archived {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
        </a>
      ),
    },
    {
      key: "4",
      icon: <CloseCircleOutlined />,
      label: (
        <a
          href={"#"}
          onClick={() => {
            moveToFolder("CANCELLED", true);
          }}
        >
          Cancel {listViewLabel === "RFQ" ? "RFQ" : "Inquiry"}
        </a>
      ),
    },
    {
      key: "5",
      icon: <UserOutlined />,
      label: (
        <a
          href={"#"}
          onClick={() => {
            inviteSupplier();
          }}
        >
          Invite Supplier
        </a>
      ),
    },
  ];

  function openModal() {
    setModal2Open(true);
  }

  function closeModal() {
    setModal2Open(false);
  }

  const duplicateInquiry: any = () => {
    if (listViewLabel === "RFQ") {
      window.location.href = "/rfqs/create/?new&rfq_id=" + listItem.id;
    } else {
      openModal();
    }
  };

  const inviteSupplier: any = () => {
    setModal1Open(true);
  };

  const [product, setProduct] = React.useState({
    productPrices: [],
    productFileAttachments: [],
    name: "",
    category: { name: "", id: 0 },
  });

  const [supplier, setSupplier] = React.useState({ name: "" });

  const [ajaxBusy, setAjaxBusy] = React.useState(false);

  const [sendingInquiry, setSendingInquiry] = React.useState(false);

  let initialProductPrice = 0;

  try {
    initialProductPrice = product.productPrices.sort(dynamicSort("-price"))[0]
      .price;
  } catch (err) {}

  const [productPrice, setProductPrice] = React.useState(initialProductPrice);

  const saveInquiry: any = (
    emailAddress: string,
    inquiryQuantity: number,
    details: string
  ) => {
    setSendingInquiry(true);

    Snippets.inquiries.save(
      AxiosAPI,
      product,
      supplier,
      productPrice,
      inquiryQuantity,
      emailAddress,
      details,
      () => {
        setSendingInquiry(false);
        setAjaxBusy(false);
        closeModal();
      },
      () => {
        setSendingInquiry(false);
        setAjaxBusy(false);
      }
    );
  };

  const [inquiryQuantity, setInquiryQuantity] = useState(1);

  useEffect(() => {
    if (isSelected) {
      if (targetRef.current !== null) {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (listItem.lineItems && listItem.lineItems.length > 0) {
      let _product: any = listItem.lineItems[0];

      setInquiryQuantity(_product.quantity);

      AxiosAPI.getProductDetails(_product.productId)
        .then((res: any) => {

          const _productDetails: any = res.data;

          _productDetails.title = listItem.title;

          _productDetails.details = listItem.details;

          _productDetails.productFileAttachments = [];

          setProduct(_productDetails);

          //console.log("2::::::::::::::::########", _productDetails);

        })
        .catch((error: any) => {

          console.log("ERROR GETTING INQ PRODUCT", error);
          
        });
    }
  }, [listItem]);

  function generateEllipsis(text: string): string {
    if (text.length > 17) {
      return text.slice(0, 14) + "...";
    } else {
      return text;
    }
  }

  return (
    <>
      <div
        id={`doc-id-${listItem.id}`}
        className={`doc-id-${listItem.id} list-view-document-item ${
          Snippets.user.getCurrentUserType() === "SELLER" ? "seller" : ""
        } ${isSelected() ? "active" : ""} zoom-animation`}
        onClick={(e) => {
          setSelectedRFQHandler(listItem, listItemIndex);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        {Snippets.user.getCurrentUserType() !== "SELLER" && (
          <Dropdown menu={{ items: itemsPhone }} arrow>
            <Button
              className="card-icon-ellipsis"
              type="text"
              icon={<MoreOutlined />}
            ></Button>
          </Dropdown>
        )}
        <h2 className={`list-view-document-title`}>
          {/* <Badge dot={listItem.unreadMessages} offset={[-3, 3]}></Badge>*/}
          <span className={`list-view-document-title-span`}>
            <strong>
              {listViewLabel === "RFQ" ? (
                <>
                  {listItem.productName.length > 42
                    ? listItem.productName.slice(0, 40) + "..."
                    : listItem.productName}
                </>
              ) : (
                <>
                  {listItem.title.length > 42
                    ? listItem.title.slice(0, 40) + "..."
                    : listItem.title}
                </>
              )}
            </strong>
          </span>
        </h2>
        <span className={`list-view-document-number`}>
          <strong>
            {listViewLabel} #1{listItem.id.toString().padStart(4, "0")}
          </strong>
        </span>
        <br />
        <span className={`list-view-document-category`}>
          <strong>Category: </strong>
          {listViewLabel === "RFQ"
            ? generateEllipsis(listItem.category.name)
            : generateEllipsis(listItem.lineItems[0].category.name)}
        </span>
        <span className="time-ago doc-listview-aging">
          {format(listItem.updatedAt).replace(" ago", "")}
        </span>
        <span className="listview-meta">
          {listViewLabel === "RFQ" ? (
            <>
              {listItem.attachments.length > 0 && (
                <>
                  <Tooltip
                    title={`${listItem.attachments.length} attachment${
                      listItem.attachments.length > 1 ? "s" : ""
                    }`}
                  >
                    <span className="listview-attachment">
                      <PaperClipOutlined size={24} color="#884422" />
                    </span>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              {(Number(listItem.messageAttachmentsCount) > 0 ||
                listItem.fileAttachments.length > 0) && (
                <>
                  <Tooltip
                    title={`${
                      listItem.messageAttachmentsCount +
                      listItem.fileAttachments.length
                    } attachment${
                      listItem.messageAttachmentsCount +
                        listItem.fileAttachments.length >
                      1
                        ? "s"
                        : ""
                    }`}
                  >
                    <span className="listview-attachment">
                      <PaperClipOutlined size={24} color="#884422" />
                    </span>
                  </Tooltip>
                </>
              )}
            </>
          )}

          {/* JSON.stringify(listItem.attachments) */}
          {listItem.unreadMessages > 0 && (
            <span className="listview-new">NEW</span>
          )}
        </span>
      </div>

      {contextHolder}

      <InquirySubmitModal
        inquiryQuantityOverride={inquiryQuantity}
        inquiryQuantityOverrideSet={() => {}}
        closeModal={closeModal}
        product={product}
        supplier={supplier}
        saveInquiry={saveInquiry}
        productPrice={productPrice}
        modalTitle="Clone Inquiry"
        isSubmitInquiryModalOpen={modal2Open}
        sendingInquiry={sendingInquiry}
        openLoginModal={() => {}}
      />

      <InvitesModals
        rfqId={listItem.id}
        isModalOpen={modal1Open}
        setIsModalOpen={setModal1Open}
        getListViewTrigger={() => {}}
      />
    </>
  );
};

export default MessengerAdvancedInquiryCol2;
