import React, { FC } from "react";
import { BuyerActivation } from "./BuyerActivation";

const BuyerActivationWrapper: FC = () => {
  return (
    <>
      <BuyerActivation />
    </>
  );
};

export default BuyerActivationWrapper;