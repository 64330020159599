/* eslint-disable import/no-anonymous-default-export */
import { UserOutlined, EnvironmentOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { Row, Col, Carousel, Tag } from "antd";
import * as React from "react";

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: 'auto',
  color: '#fff',
  lineHeight: 'inherit',
  textAlign: 'center',
  backgroundColor: '#364d79 !important',
  width: '100%'
};

const contentStyle2: React.CSSProperties = {
  margin: 0,
  height: 'auto',
  color: '#fff',
  lineHeight: 'inherit',
  textAlign: 'center',
  backgroundColor: '#364d79 !important',
  width: '100%'
};

export default ({ id, name, contact, address, phone, email, profile, banners }: any) => {


  React.useEffect(() => {

    //console.log(":::::::: XXXXXXX", profile)

  }, [profile]);

  return (
    <>
      {/* Start of Footer */}
      <Row>


        <Col flex="64px">
          <img src={`https://szbackend.eu-west-2.elasticbeanstalk.com/marketplace/supplier/${profile.id}/document/LOGO`} alt="" width={"100%"} className="mr-3 mt-1" />
        </Col>
        <Col flex="auto" style={{ width: "min-content" }} className="pl-5">
          <Row>
            <Col span={24}><strong><h4 style={{ fontWeight: 700 }}>{name}</h4></strong></Col>
            <hr />
            <Col span={10}><UserOutlined />&nbsp;&nbsp;&nbsp;{contact}</Col>
            <Col span={10}><EnvironmentOutlined />&nbsp;&nbsp;&nbsp;{address}</Col>
            <Col span={10}><PhoneOutlined />&nbsp;&nbsp;&nbsp;{phone}</Col>
            <Col span={10}><MailOutlined />&nbsp;&nbsp;&nbsp;{email}</Col>
          </Row>
        </Col>
        <Col span={24}><hr /></Col>
        <Col span={12}>
          <Carousel autoplay >
            {profile.documents.filter((item: any) => item.type === "SLIDE_IMAGE").map((banner: any, bannerIndex: number) => (
              <>

                <div key={bannerIndex} style={contentStyle2}>
                  <img src={`https://api-live.supplyzone.ai//home/product-attachment/${banner.s3Key}`} alt="" width={"100%"} />
                </div>

              </>
            ))}
          </Carousel>
        </Col>
        <Col span={1}></Col>
        <Col span={11}>
          {profile.businessType && (
            <Row>
              <Col span={10}><strong>Business Type:</strong></Col>
              <Col span={14}>
                {profile.businessType.split(",").map((tag: any, tagIndex: number) => (
                  <>
                    {tag !== "" && (
                     <Tag key={tagIndex} className="mt-1" style={{textTransform:"capitalize"}}>{tag.replace('_', ' ').toLowerCase()}</Tag>
                    )}
                  </>
                ))}
              </Col>
            </Row>
          )}
          <Row>
            <Col span={10}><strong>Year Established:</strong></Col>
            <Col span={14}>{profile.yearEstablished}</Col>
          </Row>
          {/* 
                    <Row>
                      <Col span={10}><strong>Total Capitalization:</strong></Col>
                      <Col span={14}>{profile.totalCapitalisation}</Col>
                    </Row>
                    */}
          <Row>
            <Col span={10}><strong>Total Employees:</strong></Col>
            <Col span={14}>{profile.totalEmployees}</Col>
          </Row>
          <Row>
            <Col span={10}><strong>Total Annual Sales:</strong></Col>
            <Col span={14}>{profile.totalAnnualSales}</Col>
          </Row>
          {/*
                    <Row>
                      <Col span={10}><strong>Terms and Conditions:</strong></Col>
                      <Col span={14}><div dangerouslySetInnerHTML={{__html: profile.termsAndConditions}} /></Col>
                    </Row>
                    <Row>
                      <Col span={10}><strong>Payment Terms:</strong></Col>
                      <Col span={14}>{profile.paymentTerms}</Col>
                    </Row>

                    */}
        </Col>
        {profile.businessSummary !== null && (
          <>
            <Col span={24}><hr /></Col>
            <Col span={24}><strong><h4 style={{ fontWeight: 700 }}>Business Summary</h4></strong></Col>
            <Col span={24}><div dangerouslySetInnerHTML={{ __html: profile.businessSummary }} /></Col>
          </>
        )}
      </Row>
      {/* End of Footer */}
    </>
  );
};
