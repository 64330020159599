import React, { FC } from "react";
import { SupplierActivation } from "./SupplierActivation";

const SupplierActivationWrapper: FC = () => {
  return (
    <>
      <SupplierActivation />
    </>
  );
};

export default SupplierActivationWrapper;
